import { Space } from "antd";
import React, { useState } from "react";
import { usePrefs } from "../../utils/Prefs";
import { useSession } from "../../utils/Session";
import { sumByKey } from "../../utils/Utils";

export default function TitleOrders(props) {

    const { isbn = "" } = props;
    const [session, setSession] = useSession();
    const [prefs, setPrefs] = usePrefs(); 

    let items = session.cart.find(item => item.isbn === isbn);

    if(prefs.hasOwnProperty("cart") && prefs.cart.length > 0){
        items = prefs.cart.find(item => item.isbn === isbn);
    }

    // No items - return nothing
    if (!items) { return (<></>); }

    // Join matching suppliers (regardless of group) and sum their quantities
    const unique = sumByKey(items.suppliers, "location", "quantity");

    // Green box with white text
    let _style = {
        backgroundColor: "#3c8617",
        color: "#fff",
        padding: "0px 3px",
        width: "60px",
        height: "22px",
        textAlign: "center",
        lineHeight: "20px"
    }

    // Draw as single box
    const drawOrder = (_location = "", _quantity = "") => {
        return (
            <div key={_location} style={{ ..._style }}><nobr><small>{_quantity} - {_location}</small></nobr></div>
        )
    }

    // Draw all boxes in a vertical stact
    return (
        <>
            <Space direction="vertical" size={0}>
                {unique.map(item => {
                    return drawOrder(item.location, item.quantity);
                })}
            </Space>
        </>
    )
}