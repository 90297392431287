import React, { useState, useEffect } from "react";
import { Typography, Divider, Button, Layout, Upload, Spin, Table, Form, Space, AutoComplete, Select, message, Input } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";
import { useSession } from "../../utils/Session";
import conditional from "../../utils/conditional";
import OrderSearchUser from "../../components/OrderSearchUser";

export default function CartImport(props) {


    const { close = () => { } } = props;
    const [session, setSession] = useSession();
    const [loading, setLoading] = useState();
    const [uploaded, setUploaded] = useState(false);
    const [needsSan, setNeedsSan] = useState(false);
    const [san, setSan] = useState("");
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState("");
    const [selectedGroup, setSelectedGroup] = useState(window.sitesettings.stores.findIndex(item => item.is_primary))
    const [file, setFile] = useState({});
    const [results, setResults] = useState({ total_rows: 0, processed_rows: 0, titles: [] });

    const uploadFile = (_file, _add) => {
        setFile(_file);
        setLoading(true);

        // edifact_san  

        let obj = {}

        obj.file = _file.file;
        obj.add_to_cart = _add;


        if (san) {
            obj.edifact_san = san;
        } else {
            obj.supplier_acct = selectedSupplier;
            obj.order_group = selectedGroup;
        }


        apiCall("cart/import", obj, (_status, _result) => {
            if (_status) {
                if (!_add) {
                    if (_result.needs_san) {
                        setNeedsSan(true);
                    }
                    setResults(_result);
                    setUploaded(true);
                } else {
                    if (_result.processed_rows) {
                        message.success(_result.processed_rows.toString() + " Titles added successfully.");
                        apiCall("session/get", {}, (_status, _result) => {
                            if (_status) {
                                setSession(_result);
                            }
                        })
                    } else {
                        message.error("No titles added.");
                    }
                    close();
                }
            } else {
                message.error("There was an error importing.");
                close();
            }
            setLoading(false);
        });
    }

    const fetchSuppliers = () => {
        apiCall("cart/getSupplierList", {}, (_status, _results) => {
            if (_status) {

                let _supp = _results.suppliers.map((item, index) => {
                    return { value: item.acct }
                })
                setSuppliers(_supp);
            }
        })
    }

    useEffect(fetchSuppliers, []);


    const columns = [

        {
            title: <small></small>,
            dataIndex: 'eisbn',
            key: 'eisbn',
            width: 50,
            render: (e) => {
                return <img className="lightshadow" onError={(e) => e.target.src = "/no-cover.png"} style={{ "maxHeight": "34px", "maxWidth": "30px" }} src={"https://bookmanager.com/i/slider_23.php?b=" + e} />
            }
        },
        {
            title: <small>ISBN</small>,
            dataIndex: 'isbn',
            key: 'isbn',
            width: 150
        },
        {
            title: <small>Qty</small>,
            dataIndex: 'qty',
            key: 'qty',
            width: 50
        },
        {
            title: <small>Title</small>,
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: <small></small>,
            dataIndex: 'success',
            key: 'success',
            render: (e) => {
                return (e === false) ? "Not found" : ""
            }
        },
    ];

    const stores = window.sitesettings.stores.map((item, index) => { return item.store_number.toString() })

    const groups = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];


    const updateSan = (_key, _field, _value) => {

        if (!_value) {
            setSan("");
            return;
        }
        if (_value.includes("(") && _value.includes(")")) {
            let _san = _value.match(/\((.*)\)/).pop();
            setSan(_san)
        } else {
            setSan("");
        }
        return;
    }


    const drawProgress = () => {

        if (uploaded) {

            return (
                <>
                    <Form layout="vertical">
                        <conditional.true value={(needsSan)}>
                            <Space align="start">
                                <div>
                                    <div className="shim" />
                                    <span style={{ "color": "#ff4d4f" }}>* </span>Choose customer for EDIFACT file:
                                </div>
                                <Form.Item required>
                                    {/* <OrderSearchUser width="190px" updateOrder={updateSan} placeholder="Customer name..." /> */}
                                    <Input onChange={(e) => setSan(e.target.value)} size="small" placeholder="SAN..." />
                                </Form.Item>
                                <div style={{ "marginTop": "4px" }}><Button loading={loading} onClick={() => uploadFile(file, true)} disabled={(!(san))} size="small" type="primary"><small>Add to cart</small></Button></div>
                            </Space>
                        </conditional.true>

                        <conditional.true value={(!needsSan)}>

                            <div>
                                <div className="shim" />
                                <span style={{ "color": "#ff4d4f" }}>* </span>Choose a supplier and group for this data:
                            </div>
                            <div className="shim" />
                            <Space align="start">

                                <Form.Item help={<small className="c">Supplier</small>}>
                                    <Select onChange={(e) => setSelectedSupplier(e)} showSearch style={{ "width": "85px" }} size="small">
                                        {suppliers.map(item => {
                                            return <Select.Option value={item.value}>{item.value}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item help={<small className="c">Group</small>}>
                                    <Select onChange={(e) => setSelectedGroup(e)} defaultValue={selectedGroup} style={{ "width": "60px" }} size="small">
                                        {stores.map(item => {
                                            return <Select.Option value={item}>{item}</Select.Option>
                                        })}
                                        <Select.Option disabled value=""></Select.Option>
                                        {groups.map(item => {
                                            return <Select.Option value={item}>{item}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item help={<small className="c">&nbsp;</small>}>
                                    <div style={{ "marginTop": "0px" }}><Button loading={loading} onClick={() => uploadFile(file, true)} disabled={(!(selectedSupplier && ((needsSan) ? (san) : (true))))} size="small" type="primary"><small>Add to cart</small></Button></div>
                                </Form.Item>
                            </Space>
                        </conditional.true>
                    </Form>
                    <Divider style={{ "marginTop": "10px" }} dashed />
                    <Table scroll={{ y: 255 }} className="bc" style={{ "border": "1px solid" }} pagination={{pageSize : 500, showSizeChanger : false}}  columns={columns} dataSource={results.titles} size="small"></Table>
                </>
            )
        }
        // draw Upload
        return (
            <>
                <div style={{ "height": "150px" }}>
                    <Upload.Dragger accept=".txt, .pdf, .xls, .xlsx, .html, .csv" showUploadList={false} customRequest={(e) => uploadFile(e, false)} style={{ "padding": "0px 20px", "height": "300px" }}>
                        <div >
                            {(loading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                        </div>

                        {(loading && <>
                            <p className="ant-upload-text">Loading, please wait.</p>
                        </>)}
                        {(!loading && <>
                            <p className="ant-upload-text">Click or drag to upload</p>
                            <p className="ant-upload-hint">
                                Upload an Excel file (.xls or .xlsx), EDIFACT file (.txt) or a tab-delimited file (.txt) with the headers "QTY" and "ISBN".
                            </p>
                        </>)}

                    </Upload.Dragger>
                </div>
            </>
        )
    }


    return (
        <>
            <Layout className="layout">
                <Layout.Content style={{ "padding": "0px", "width": "auto" }} >
                    <div style={{ "padding": "30px" }}>
                        <Typography.Title level={5}>Import to Order Processing </Typography.Title>
                        <Divider style={{ "margin": "15px 0px" }} />

                        {(!uploaded && <div>
                            ISBN header is required. If you do not include QTY header, ISBNs will be imported with a quantity of 1.
                            <br /><br />

                            You will be able to preview the list of titles before importing it into your web cart.<br />
                            <a target="_blank" href={process.env.PUBLIC_URL + "/example.txt"}>Click here to see an example text file.</a>
                            <div className="shim" /><div className="shim" /><div className="shim" /></div>)}


                        {drawProgress()}

                    </div>
                </Layout.Content>
            </Layout>


        </>
    )

}