import React, { useState } from "react";
import { Form, Typography, Divider, Row, Col, Space, Radio, Checkbox, Input } from "antd";
import WebstoreItem from "../WebstoreItem";
export default function SearchingSection(props) {
    const { ws } = props;

    const [enabled, setEnabled] = useState((ws["global:ws_enable_bm_class"]));


    return (
        <>
            <div id="searching" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Searching</Typography.Title>
                <Divider />

                {/* Title search results will sort by default */}
                <div id="default_ts_sort"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Title search results will sort by default</Typography.Title>
                        <Typography.Paragraph type="secondary">"Availability” refers to search results always showing your On Hand or On Order titles first. “Popularity” prioritizes the most popular/ranked results, provided your store participates in the weekly sales reporting. “Preferred Pubstock suppliers” sorts by available titles, followed by those from your Yes or Favourite vendors, as determined in your Bookmanager software’s Pubstock Format menu.</Typography.Paragraph>
                        <Form.Item initialValue={ws["global:default_ts_sort"]} noStyle name="global:default_ts_sort">
                            <Radio.Group>
                                <Radio value="onhand">Sort by availability, then...<br />
                                    <div className="shim"></div>
                                    <Form.Item initialValue={ws["global:ws_sort_pubstock"]} noStyle name="global:ws_sort_pubstock">
                                        <Radio.Group>
                                            <Radio value={0}>..sort by popularity.</Radio><br /><div className="shim"></div>
                                            <Radio value={1}>..sort by titles that are carried by my preferred pubstock suppliers.</Radio><br /><div className="shim"></div>
                                        </Radio.Group>
                                    </Form.Item>
                                </Radio><br /><div className="shim"></div>
                                <Radio value="demand">Sort by title popularity regardless of my current stock levels.</Radio>
                                <div className="shim"></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Space>

                <Divider dashed />
                {/* Title search results will sort by default */}
                <div id="ws_hide_no_stock"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Filter options</Typography.Title>
                        <Typography.Text level={5}><Form.Item valuePropName='checked' initialValue={(ws["global:ws_hide_no_stock"]) ? true : false} name="global:ws_hide_no_stock" noStyle><Checkbox /></Form.Item>&nbsp; <strong>Hide titles from search results that aren't carried by me or my preferred vendors.</strong>
                            <Typography.Paragraph type="secondary">"Preferred" vendors are ones that you denoted as Yes or Favourite in your Bookmanager software’s Pubstock Format menu.</Typography.Paragraph>
                        </Typography.Text>
                        <div className="shim"></div>
                        <Typography.Text level={5}><Form.Item valuePropName='checked' initialValue={(ws["global:ws_hide_ranks"]) ? true : false} name="global:ws_hide_ranks" noStyle><Checkbox /></Form.Item>&nbsp; <strong>Hide sales ranks from being displayed to customers.</strong>
                            <Typography.Paragraph type="secondary">Sales ranks are populated from weekly sales / daily On Hand feeds gathered from participating Bookmanager stores. Check this box if you wish to hide this information from customers.</Typography.Paragraph>
                        </Typography.Text>
                        <div className="shim"></div>
                        <Typography.Text level={5}><Form.Item valuePropName='checked' initialValue={(ws["global:ws_tb_show_only_stocked"]) ? true : false} name="global:ws_tb_show_only_stocked" noStyle><Checkbox /></Form.Item>&nbsp; <strong>Show customers only the titles I have On Hand or On Order</strong>
                            <Typography.Paragraph type="secondary">Check this box if you want your customers to only see search results that match your current On Hand, or On Order levels. Please note that when you are logged in with Store permissions, you will see all search results.</Typography.Paragraph>
                        </Typography.Text>
                    </div>
                </Space>

                <Divider dashed />
                {/* Enable BM Class for consumers */}
                <div id="ws_enable_bm_class" />
                <WebstoreItem
                    onChange={(e) => setEnabled(e)}
                    name="global:ws_enable_bm_class"
                    value={(ws["global:ws_enable_bm_class"]) ? true : false}
                    heading="Enable BM Class for consumers"
                    description={<div>After enabling this option, proper setup in the Bookmanager software, and the Bookmanager Scheduler routines "Send Onhands" and "Backup Bookmanager (option C)" have been run, customers will have access to your Bookmanager software's F4 Class list for title filtering. <a href="https://support.bookmanager.com/tutorials/webstore/biz-admin-site/enabling-your-f4-classes-for-consumer-use-online/" target="_blank">Learn more about setting up your classes for this purpose.</a></div>}
                />

                <Divider dashed />
                {/* Local delivery postal or ZIP codes */}
                <div id="ws_bm_class_heading" />
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Class filters header</Typography.Title>
                        <Typography.Paragraph type="secondary">Use this field to override the heading for the BM Class filters section. <strong>Categories</strong> is the default header.</Typography.Paragraph>
                        <Form.Item help={<small>Max 20 chars</small>} name="global:ws_bm_class_heading" initialValue={ws["global:ws_bm_class_heading"]}>
                            <Input placeholder="Filter name..." maxLength={20} style={{ "maxWidth": "500px" }}></Input>
                        </Form.Item>
                    </div>
                </Space>


                <Divider dashed />
                {/* Show/Hide Customer Subjects */}
                <div id="hide_customer_subject" />
                <div style={{ "opacity": (enabled) ? "1" : "0.5" }}>
                    <WebstoreItem
                        disabled={!(enabled)}
                        name="global:hide_customer_subject"
                        value={(ws["global:hide_customer_subject"]) ? true : false}
                        heading="Hide Subjects/BISAC Browse Filter section for consumers"
                        description={<div>By default, consumers can filter their searches or browse by BISAC subject. Some specialty stores, like those that only carry children's books, may find that BISAC subjects do not adequately filter or represent their stock, where their internal F4 Classes would provide better categorization or shelving reference. Enable this option to hide the default <strong>Subjects</strong> list of Browse filters to instead allow filtering and browsing solely through curated F4 Classes. <br /><br />
                        <strong>Note:</strong> Hiding Subjects / BISAC filters is only available if you have enabled the previous option Enable BM Class for consumers and have linked your F4 Classes to BISAC subjects. <a target="_blank" href="https://support.bookmanager.com/tutorials/inventory/linking-classes-to-bisac/linking-your-classes-to-bisacs">Click here for instructions on linking BISACs to Classes.</a><div className="shim" /><div className="shim" /> The BM Class filter section only pulls titles that you have in stock or on order. Titles that are forthcoming that you do not have on order, or released titles that you do not stock, will not be displayed when a Class filter is selected. Disable the default Subjects filter section at your discretion and with these limitations in mind.</div>}
                    />
                </div>



            </div>
        </>
    )
}