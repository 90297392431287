import { Divider, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { CloseOutlined, CheckSquareFilled, CloseSquareFilled } from '@ant-design/icons';
import { successColor, bestsellerColor, objectToPath, pathToObject, ucfirst } from "../utils/Utils";
export default function Metafilters(props) {

    const { metaSet = [], setMetaSet = () => { }, filter = {} } = props;
    const { meta_filters = [] } = filter;

    if (meta_filters.length < 1) {
        return <></>;
    }

    const toggle = (_filter, _overwrite = false, _negate = false) => {

        let _arr = [...metaSet];
        let _found = _arr.findIndex(item => item.key === _filter.key);

        if(_found > -1){
            // Exists
            // set negate if not already set
            if(!_arr[_found].hasOwnProperty("negate")){
                _arr[_found].negate = false; 
            }

            if(_arr[_found].negate === _negate){
                // not swapping negate, remove
                _arr.splice(_found, 1);
            } else {
                // swapping, just update
                _arr[_found].negate = _negate; 
            }

        } else {

            if(_overwrite){
                // Overwrite
                _arr = [_filter];
            } else {
                // Add 
                _arr.push(_filter);
            }
        }

        _filter.negate = _negate;

        setMetaSet(_arr);
    }

    const drawFilter = (_filter) => {

        let { name = "", key = "", tooltip = "" } = _filter;

        if(!_filter.hasOwnProperty("negate")){
            _filter.negate = false; 
        }

        let selected = (metaSet.find(item => item.key === key));

        return (
            <div  key={key}>
                {/* <Tooltip mouseEnterDelay={0.5} title={<small>{tooltip}<span style={{ "color": "#999", "fontSize": "9px" }}></span></small>}> */}
                    <div style={{ "float": "right", "marginRight": "12px" }}>
                        <Space size={2}>
                            <div><CloseSquareFilled onClick={() => toggle(_filter, false, true)} key={"exclude"} style={{ "color": (selected && _filter.negate) ? bestsellerColor : "#bbb" }} /></div>
                            <div><CheckSquareFilled onClick={() => toggle(_filter, false)} key={"include"} style={{ "color": (selected && !_filter.negate) ? successColor : "#bbb"}} /></div>
                        </Space>
                    </div>
                    {/* <Tooltip mouseEnterDelay={0.5} title={<small>{name} <span style={{ "color": "#999", "fontSize": "9px" }}>{formatCount(count)}</span></small>}> */}
                    <div onClick={() => toggle(_filter, true)} className="c" style={{ "overflow": "hidden", "textOverflow": "ellipsis", "maxWidth": "150px", "whiteSpace": "nowrap", "cursor": "pointer" }}>
                        <small><div style={{ "color": "#999", "fontSize": "9px", "width": "25px", "display": "inline-block" }}>({_filter.filters.length})</div> <span className="c">{(selected) ? <strong>{name}</strong> : name}</span> </small>
                    </div>
                {/* </Tooltip> */}
            </div>
        )
    }

    return (<>
        <Divider style={{ "margin": "0px", "pointerEvents": "none" }} plain><small style={{ "fontSize": "8px", "color": "#777", "letterSpacing": "1.5px" }}>FILTER GROUP</small></Divider>
        <div style={{"maxHeight": "16vh", "width": "100%",  "overflow": "auto"}} >
            {meta_filters.map(item => {
                return drawFilter(item);
            })}
        </div>
         <Divider style={{ "margin": "5px 0px", "pointerEvents": "none" }}  />
        {/* <pre>{JSON.stringify(metaSet, null, 2)}</pre> */}
        </>)
}