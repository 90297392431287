import React, {useState} from "react";
import { Input, Popover, Button, Modal, message } from "antd";
import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";
import { FormOutlined} from '@ant-design/icons';
export default function Notes(props) {

    const [note, setNote] = useState(props.note);
    // const [visible, setVisible] = useState(false);
    const [visible, setVisible] = useState(false)
    const {bookorder_id} = props; 
    
    const saveNote = () =>{
        setVisible(false)
        apiCall("orders/setNote", { bookorder_id : bookorder_id, note : note }, (_status, _result) =>{
            if(_status){
                setNote(_result.note);
                message.success("Note Saved"); 
            }
        })
    }


    const drawEditor = () => {
        return (
            <>
                <Input.TextArea 
                    onChange={(e) => setNote(e.target.value)} 
                    maxLength={50} 
                    showCount 
                    defaultValue={note}></Input.TextArea>
                <div className="shim" />
                <Button 
                    onClick={() => saveNote()} 
                    type="primary" 
                    size="small">
                    <small>Save</small>
                </Button>
            </>
        )
    }

    return (
        <>
            <Popover 
                destroyTooltipOnHide 
                onVisibleChange={(e) => setVisible(e)} 
                visible={visible} 
                content={drawEditor()} 
                title={<small>Notes</small>} 
                trigger="click">
                <div onClick={() => setVisible(!visible)} style={{"width" : "100%", "display" : "block", "cursor" : "pointer", "overflow" : "hidden"}}>
                    <conditional.true value={(note)}>
                        {note}
                    </conditional.true>

                    <conditional.true value={(!note)}>
                       <div style={{"color" : "#aaa"}}>&nbsp;&nbsp;<FormOutlined /></div>
                    </conditional.true>
                </div>
            </Popover>
        </>
    )
}