import React, { useEffect, useState } from "react";
import { Checkbox, Layout, Row, Col, Typography, Divider, Radio, Table, Space, Card, message } from "antd";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { apiCall } from "../../utils/Api";

export default function StoreStockSharing() {

    const [stores, setStores] = useState([]);
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(true);

    const [settings, setSettings] = useState({
        retail_price : true,
        quantity_mode : 1,
        filter_type : 0
    });

    const getSettings = () => {
        apiCall("stock_sharing/get", {}, (_status, _result) => {
            if (_status) {
                setSettings(_result.settings);
                setStores(_result.stores);
                setInit(true);
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }
    useEffect(getSettings, []);

    const updateSettings = () => {
        if(!init){
            return false; 
        }
        setLoading(true);
        apiCall("stock_sharing/setSettings", settings, (_status, _result) => {
            if(_status){
                message.success("Setting updated");
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }
    useEffect(updateSettings, [settings]);

    const updateFiltered = (_stores) => {
        let filter_ids = _stores.filter(item => item.is_filtered).map(item => item.id);
        apiCall("stock_sharing/setFilterIds", {filter_ids : JSON.stringify(filter_ids)}, (_status, _result) =>{
            if(_status){
                // nothing to do here...
            } else {
                message.error(_result.error);
            }
        })
    }

    const toggleFiltered = (_obj) => {
        let _stores = [...stores];
        let _ind = _stores.findIndex(item => item.id === _obj.id);
        if(_ind > -1){
            // found
            _stores[_ind].is_filtered = !_stores[_ind].is_filtered; 
            
        }
        setStores(_stores);
        updateFiltered(_stores);
    }

    const columns = [
        { title: <small>Store</small>, dataIndex: 'name', key: 'name' },
        { title: <small>Region</small>, dataIndex: 'city', key: 'city' },
        { title: <small>Province</small>, dataIndex: 'province', key: 'province' }
    ];

    if(settings.filter_type > 1){
        columns.unshift({ title: <small></small>, width : "22px", dataIndex: 'is_filtered', key: 'is_filtered', render : (e, f) => {
            return <Checkbox onChange={(_e) => toggleFiltered(f)} checked={e} />
        } })
    }

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Stock Sharing"
                    description="Use this page configure store sharing and settings."
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <Card loading={(loading && !init)} bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                            <div style={{ "padding": "30px" }}>
                                {/* Sharing filter rules */}
                                <Row gutter={[30, 30]}>
                                    <Col md={24} xl={24}>
                                        <Space>
                                            <div>
                                                <Typography.Title level={5}>Sharing filter rules:</Typography.Title>
                                                <Typography.Paragraph type="secondary"></Typography.Paragraph>
                                                <Radio.Group onChange={(e) => setSettings({...settings, "filter_type" : e.target.value})} value={settings.filter_type}>
                                                    <Radio value={0}>Disable sharing.</Radio>
                                                    <div className="shim"></div>
                                                    <Radio value={1}>Share with everyone who shares with me.</Radio>
                                                    <div className="shim"></div>
                                                    <Radio value={3}>Share <strong>only with the stores I have checked.</strong></Radio>
                                                    <div className="shim"></div>
                                                    <Radio value={2}>Share with <strong>everyone except</strong> the stores I have checked.</Radio>
                                                </Radio.Group>
                                                <div className="shim"></div> <div className="shim"></div>
                                            </div>
                                        </Space>
                                    </Col>
                                    <Col md={24} xl={24}>
                                        <Space>
                                            <div>
                                                <Typography.Title level={5}>Preferred quantity display mode:</Typography.Title>
                                                <Typography.Paragraph type="secondary"></Typography.Paragraph>
                                                <Radio.Group onChange={(e) => setSettings({...settings, "quantity_mode" : e.target.value})} value={settings.quantity_mode}>
                                                    <Radio value={1}>Show actual quantity</Radio>
                                                    <div className="shim"></div>
                                                    <Radio value={2}>Show 5+ when quantity exceeds 5</Radio>
                                                    <div className="shim"></div>
                                                    <Radio value={3}>Show "Yes" if quantity is at least one</Radio>
                                                </Radio.Group>
                                                <div className="shim"></div>
                                                    <Checkbox checked={settings.retail_price} onChange={(e) => setSettings({...settings, "retail_price" : e.target.checked})}>Show retail price</Checkbox>
                                                <div className="shim"></div> <div className="shim"></div>
                                            </div>
                                        </Space>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <div className="shim"></div> <div className="shim"></div><div className="shim"></div> <div className="shim"></div>
                        <Typography.Title level={5}>These stores are sharing:</Typography.Title>
                        <Table bordered size="small" pagination={{defaultPageSize : 20}}
                            columns={columns}
                            rowKey="id"
                            loading={loading}
                            dataSource={stores.filter(item => item.is_sharing)}
                        />
                        <div className="shim"></div><div className="shim"></div>
                        <div className="shim"></div><div className="shim"></div>
                        <Typography.Title level={5}>These stores have not enabled sharing:</Typography.Title>

                        <Table bordered size="small" pagination={{defaultPageSize : 20}}
                            columns={columns}
                            rowKey="id"
                            loading={loading}
                            dataSource={stores.filter(item => !item.is_sharing)}
                        />
                        <div className="shim"></div><div className="shim"></div>
                        <Divider dashed />
                        <Typography.Title level={5}>About Sharing</Typography.Title>
                        <Typography.Paragraph type="secondary">
                            <em>These settings allow you to share your stock information with other booksellers that send inventory data to Bookmanager. Stores cannot see your information unless they have allowed you to see theirs. Once sharing is enabled, to view shared information, click the Pubstock button for a particular title and then click Stores. When you change a sharing option you must wait one hour before making further changes. This is done to deter booksellers from temporarily enabling sharing their data, which would defeat the purpose of sharing data.</em>
                        </Typography.Paragraph>
                        <Typography.Paragraph type="secondary">
                            <em>Stores that appear in grey are not currently sharing information with you (or perhaps eveyone, or they are only sharing with a few select stores).</em>
                        </Typography.Paragraph>
                        <Typography.Paragraph type="secondary">
                            <em>If you have the "Share with everyone" filter rule selected, the checkboxes beside each store do not affect anything. They are just there in case you later select a different filter rule.</em>
                        </Typography.Paragraph>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    );
}