import React, { useState } from "react";
import Condition from "./Condition";
import { Menu, Dropdown } from "antd";

export default function Conditions(props) {

    const { isbn = "", data = { suggested_bm_data : {}} } = props
    const [conditions, setConditions] = useState((props.conditions) ? props.conditions : []);

    const addCondition = (_code) => {
        let _orig = [...conditions];
        _orig.push({
            code: _code.code,
            code_text: _code.name,
        })
        setConditions(_orig)
    }


    const drawSelector = () => {

        let _current_codes = conditions.map(item => item.code_text);

        return (
            <Dropdown overlay={<Menu size="small">
                {window.sitesettings.condition_codes.filter(item => !_current_codes.includes(item.name)).map(code => {
                    return <Menu.Item onClick={(e) => addCondition(code)} key={code.name}><small>{code.name}</small></Menu.Item>
                })}
            </Menu>}>
                <div style={{ "float": "right", "position": "relative", "zIndex": "2" }}><small><a>+ Add stock condition</a></small></div>
            </Dropdown>
        )
    }


    return (
        <>
            <div style={{}}>{drawSelector()}</div>
            {conditions.map((item, _index) => {
                return (
                    <div key={_index}>
                        <Condition suggested_bm_data={data.suggested_bm_data} isbn={isbn} data={item} />
                        <div className="shim" /><div className="shim" />
                    </div>
                )
            })}
        </>
    )




}