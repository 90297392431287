import { Button, Layout, message, Radio, Table } from "antd";
import moment from 'moment-timezone';
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import { pathToObject } from "../utils/Utils";

export default function SuggestedCartsPage() {

    const location = useLocation();
    const [searchFilters, setSearchFilters] = useState(pathToObject(location.pathname))
    const [initLoaded, setInitLoaded] = useState(false);
    const [suggestedCarts, setSuggestedCarts] = useState([]);
    const [sharedView, setSharedView] = useState(false);
    const [session, setSession] = useSession();
    
    function getAllSuggestedCarts(hide_completed = false, sf = searchFilters){
        apiCall("suggested_cart/getAll", {hide_completed : hide_completed, limit: sf.l, offset: sf.o}, (_status, _result) => {
            if (_status) {
                setSuggestedCarts(_result);
                setInitLoaded(true);
            } 
        })
    }

    function completeSuggestedCart(f){
        let args = {
            store_id: session.corp_id,
            cart_id: f.id,
            done: true,
        };
        apiCall("suggested_cart/toggleDone", args, (_status, _result) => {
            if(_status){
                message.success(f.name + " Completed!")
            }
        })
    }

    function viewInBrowse(sc_id){
        window.open("/purchasing/browse/filter/x/" + sc_id + "/v/sequence", "_blank");

    }

    //To grab all suggested carts for the list on page load, no matter the status
    useEffect(() => {getAllSuggestedCarts(true)}, []);
    
    const columns = [
        {
            title: <small style={{"paddingLeft": "10px"}}>ISBN Count</small>, 
            width: 100, 
            dataIndex: 'count', 
            key: 'count' ,
            sorter: (a,b) => a.count - b.count
        },
        {
            title: <small>Name</small>,  
            dataIndex: 'name', 
            key: 'name' ,
            render: (e, f) => {
                return (e) ? <a onClick={() => viewInBrowse(f.id)}>{e}</a> : "";
            }
        },
        {
            title: <small>Rep Name</small>,  
            dataIndex: 'rep_info', 
            key: 'rep_info', 
            render: (e,f) =>{
                return (e.name)
            } ,
        },
        {
            title: <small>Shared Date</small>, 
            width: 150, 
            dataIndex: 'shared_date', 
            key: 'shared_date', 
            render: (e,f) => {
                return ( moment(f.shared_date * 1000).format("MMMM D YYYY") )
            }
        },
    ]
    //for displaying a button to allow a store to complete working with a suggested cart, or to show the date they completed it on.
    if(!sharedView){
        columns.push(
            {
                title: <small>Mark Complete</small>, 
                dataIndex: 'name', 
                width: '200px',
                key: 'name', 
                render: (e,f) => {
                    if(f.marked_done_date > 0 ){
                        return (
                            <Button type='text' size="small">
                                Cart Completed
                            </Button>
                        )
                    }
                    return (
                        <Button type='primary' size="small" onClick={(e) => {completeSuggestedCart(f)}}>
                            Complete this Cart
                        </Button>
                    )
                }
            },
        )
    }else{
        columns.push(
            {
                title: <small>Completed Date</small>, 
                width: 150, 
                dataIndex: 'marked_done_date', 
                key: 'marked_done_date', 
                render: (e,f) => {
                    if(f.marked_done_date > 0 ){
                        return ( moment(f.marked_done_date * 1000).format("MMMM D YYYY") )
                    }
                    return ( "Not yet complete" )
                }
            },
        )
    }

    return (
        ((initLoaded) && <>
            <Layout className="layout" style={{ "minHeight": "200px" }}>
                <LayoutHeader
                    title={<>Suggested Carts</>}
                    description={
                        <>
                            <div>
                                <Radio.Group 
                                    size='small'
                                    defaultValue={sharedView} 
                                    buttonStyle="solid" 
                                    onChange={(e) => { 
                                        setSharedView(e.target.value)
                                        getAllSuggestedCarts(!e.target.value)
                                        }
                                    }>
                                    <Radio.Button value={false}>Incomplete Carts</Radio.Button>
                                    <Radio.Button value={true}>Completed Carts</Radio.Button>
                                </Radio.Group>
                            </div>
                        </>
                    }
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <Table
                        size='small'
                        pagination
                        columns={columns}
                        dataSource={suggestedCarts}
                        />
                </Layout.Content>
            </Layout>
        </>)
    )
}