import React, { useState } from "react";
import { Form, Typography, Divider, Input, Col, Space, Checkbox, Radio, Tabs, Select } from "antd";
import WebstoreItem from "../WebstoreItem";
import conditional from "../../../utils/conditional";
import MSWrap from "./MSWrap";
import { drawMSFooter, drawMSTitleBar } from "../../../utils/Utils";
import MultistoreItem from "../MultistoreItem";
export default function PaymentsSection(props) {

    const { ws, form, setChanged = () => { } } = props;

    const [method, setMethod] = useState(ws["global:ws_credit_card_method"]);

    const primary = (window.sitesettings.stores[0]);
    const stores = (primary.is_primary) ? window.sitesettings.stores : [window.sitesettings.stores.find(item => item.is_primary)];


    const setCreditCardMethod = (_method) => {

        setChanged(true);

        switch (_method) {
            case "none":
                form.setFieldsValue({
                    "global:ws_credit_card_in_store": false,
                    "global:ws_credit_card_clearent": false,
                    "global:ws_credit_card_global": false
                });
                setMethod("none");
                break;
            case "in_store":
                form.setFieldsValue({
                    "global:ws_credit_card_in_store": true,
                    "global:ws_credit_card_clearent": false,
                    "global:ws_credit_card_global": false
                });
                setMethod("in_store");
                break;
            case "clearent":
                form.setFieldsValue({
                    "global:ws_credit_card_in_store": false,
                    "global:ws_credit_card_clearent": true,
                    "global:ws_credit_card_global": false
                });
                setMethod("clearent");
                break;
            case "global":
                form.setFieldsValue({
                    "global:ws_credit_card_in_store": false,
                    "global:ws_credit_card_clearent": false,
                    "global:ws_credit_card_global": true
                });
                setMethod("global");
                break;
            default:
                form.setFieldsValue({
                    "global:ws_credit_card_in_store": false,
                    "global:ws_credit_card_clearent": false,
                    "global:ws_credit_card_global": false
                });
                setMethod("none")
                break;
        }


    }

    const [l, setL] = useState((ws["global:ws_custom_contact_payment_text"].length) ? ws["global:ws_custom_contact_payment_text"].length : 22);

    return (
        <>
            <div id="payments" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Payments</Typography.Title>
                <Divider />



                {/* Pay at pick up */}
                <div id="ws_accepts_payment_pickup" />
                <MultistoreItem
                    stores={stores}
                    ws={ws}
                    name="ws_accepts_payment_pickup"
                    heading="Enable pay at pick up"
                    description={<div>Allow customers to choose "I will pay when I pick up the order" on the "Pick up at store" cart checkout page. We highly recommend enabling this option, as it gives a convenience and competitive edge that larger online retailers cannot offer. In our experience, the vast majority of online customers choose this option when checking out, and 99% of these orders are picked up and paid for with no issue. It is very rare for a customer to place an order for pickup and not actually follow through.</div>}
                />
                <Divider dashed />

                {/* Contact for payment */}
                <div id="ws_accepts_payment_contact" />
                <MultistoreItem
                    stores={stores}
                    ws={ws}
                    name="ws_accepts_payment_contact"
                    heading="Enable contact for payment"
                    description={<div>Allow customers to choose "Contact me for payment" on the cart checkout page. This payment option does not require the customer to prepay at checkout. This option is available for both in-store pickup and shipped orders.</div>}
                />
                <Divider dashed />

                <Space align="baseline">
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <h5 class="ant-typography">Override "Contact for payment" wording</h5>
                        <Typography.Paragraph type="secondary">You can allow customers to choose "Contact me for payment" during checkout. This payment option does not require the customer to prepay at checkout. This option is available for in-store pick up, Guest Checkout, and shipped orders (whichever applicable). If you would like to customize the text of this payment option, use the field below.</Typography.Paragraph>
                        <Space>
                            <div style={{ "width": "400px" }}>
                                <Form.Item noStyle initialValue={(ws["global:ws_custom_contact_payment_text"]) ? ws["global:ws_custom_contact_payment_text"] : "Contact me for payment"} name="global:ws_custom_contact_payment_text">
                                    <Input onChange={(e) => setL(e.target.value.length)} maxLength={40} style={{ "maxWidth": "500px" }}></Input>
                                </Form.Item>
                            </div>
                            <Typography.Paragraph type="secondary">{l} / 40</Typography.Paragraph>
                        </Space>
                        <div className="shim"></div>
                        <Typography.Paragraph type="secondary">Default Value of "Contact me for payment". Limit of 40 characters.</Typography.Paragraph>
                    </div>
                </Space>

                <Divider dashed />
                {/* Pay at pick up */}
                <div id="ws_accepts_paypal"></div>
                <WebstoreItem
                    name="global:ws_accepts_paypal"
                    value={(ws["global:ws_accepts_paypal"]) ? true : false}
                    heading="PayPal setup"
                />
                <div className="shim" />
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <conditional.true value={(ws["global:ws_paypal_email"] && !ws["global:ws_paypal_client_id"])}>
                            <Typography.Text><strong>PayPal email address </strong>
                                <Typography.Paragraph type="secondary">Email address your business' PayPal account was set up with.</Typography.Paragraph>
                                <Form.Item help={<small></small>} rules={[{ type: "email", message: "Must be a valid email." }]} initialValue={ws["global:ws_paypal_email"]} name="global:ws_paypal_email">
                                    <Input disabled size="small" style={{ "maxWidth": "300px" }}></Input>
                                </Form.Item>
                                <div className="shim" />
                            </Typography.Text>
                        </conditional.true>
                        <Typography.Paragraph><strong>Paypal client id </strong>
                            <div className="shim"></div>
                            <Form.Item initialValue={ws["global:ws_paypal_client_id"]} name="global:ws_paypal_client_id" noStyle>
                                <Input.TextArea maxLength={80} showCount size="small" style={{ "maxWidth": "500px" }}></Input.TextArea>
                            </Form.Item>
                        </Typography.Paragraph>
                        <Typography.Paragraph><strong>Paypal secret </strong>
                            <div className="shim"></div>
                            <Form.Item initialValue={ws["global:ws_paypal_secret"]} name="global:ws_paypal_secret" noStyle>
                                <Input.TextArea maxLength={80} showCount size="small" style={{ "maxWidth": "500px" }}></Input.TextArea>
                            </Form.Item>
                        </Typography.Paragraph>
                        <Typography.Paragraph type="secondary">For instructions on where to find the Client ID and Secret, <a target={"_blank"} href="https://cdn1.bookmanager.com/i/tbm/www/New_PayPal_Setup.html">click here.</a></Typography.Paragraph>
                    </div>
                </Space>

                <Divider dashed />

                <div style={{ "marginLeft": "40px" }}>
                    <h5 class="ant-typography">Webstore credit card collection</h5>
                    <Select onChange={(e) => setCreditCardMethod(e)} value={method} style={{ "width": "400px" }} >
                        <Select.Option key={"none"} value={"none"} >Don't Collect <em><small>(remove credit card option from webstore)</small></em></Select.Option>
                        <Select.Option key={"in_store"} value={"in_store"} >Collect Credit Card Number <em><small>(for in-store processing)</small></em></Select.Option>
                        {(!window.sitesettings.is_canadian && <Select.Option key={"clearent"} value={"clearent"} >Collect Clearent Token <em><small>(for integrated in-store processing)</small></em></Select.Option>)}
                        {(window.sitesettings.is_canadian && <Select.Option key={"global"} value={"global"} >Collect Global Payments Token <em><small>(integrated)</small></em></Select.Option>)}
                    </Select>
                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                    <Form.Item hidden name={"global:ws_credit_card_in_store"} />
                    <Form.Item hidden name={"global:ws_credit_card_clearent"} />
                    <Form.Item hidden name={"global:ws_credit_card_global"} />
                </div>

                <conditional.true value={(method === "in_store")}>
                    {/* Enable in-store credit card processing */}
                    <div id="ws_credit_card_in_store"></div>
                    <WebstoreItem
                        name="global:ws_credit_card_in_store"
                        has_switch={false}
                        value={(ws["global:ws_credit_card_in_store"]) ? true : false}
                        heading="In-store credit card processing setup"
                        notice="IMPORTANT: You must enable the option ‘Submitted orders will automatically generate a Held Sale in my Bookmanager system’ (in the Ordering section) to use this option."
                        description={<div>Enabling in-store credit card processing will allow your customers to provide credit card details for a transaction. Those details will be encrypted and passed to your Bookmanager software, where a non-recoverable password is required to decrypt the credit card information. It is then up to you to process the transaction manually at your store with the details provided. Credit card details will not be stored on Bookmanager servers or sent by email. Your local copy of Bookmanager is the only place credit cards are stored, with details securely encrypted.</div>}
                    />
                    <Space>
                        <div style={{ "width": "35px" }}></div>
                        <div>
                            <Typography.Text ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc_shipping"]) ? true : false} name="global:ws_accepts_cc_shipping" noStyle><Checkbox disabled={(!props.autoHeldSale || !ws["global:can_credit_card_in_store"])} /></Form.Item>&nbsp; Enable Credit Card payments on orders to be <strong>Shipped</strong></Typography.Text><br />
                            <conditional.true value={((!ws["global:can_credit_card_in_store"]))}><Typography.Text type="danger">Some additional setup in BM is required to enable this setting online. Please call Bookmanager support for help with this.</Typography.Text><br /></conditional.true>
                            <conditional.true value={((!props.autoHeldSale))}><Typography.Text type="danger">"Submitted orders will auto generate a Held Sale in Bookmanager" must be enabled.</Typography.Text><br /></conditional.true>
                            <div className="shim"></div><div className="shim"></div>
                            <Typography.Text ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc_pickup"]) ? true : false} name="global:ws_accepts_cc_pickup" noStyle><Checkbox disabled={(!props.autoHeldSale || !ws["global:can_credit_card_in_store"])} /></Form.Item>&nbsp; Enable Credit Card payments on orders to be <strong>Picked Up</strong></Typography.Text><br />
                            <conditional.true value={((!ws["global:can_credit_card_in_store"]))}><Typography.Text type="danger">Some additional setup in BM is required to enable this setting online. Please call Bookmanager support for help with this.</Typography.Text><br /></conditional.true>
                            <conditional.true value={((!props.autoHeldSale))}><Typography.Text type="danger">"Submitted orders will auto generate a Held Sale in Bookmanager" must be enabled.</Typography.Text><br /></conditional.true>
                            <div className="shim"></div><div className="shim"></div>
                            <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_disallow_cc_debit"]) ? true : false} name={"global:ws_disallow_cc_debit"} noStyle><Checkbox /></Form.Item>&nbsp; Disallow debit credit cards <div className="shim" /><small>(Checking will disallow debit credit cards transactions for your customers.)</small></Typography.Paragraph>
                            <Divider style={{ "margin": "10px 0px" }} dashed />
                            <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc"].visa) ? true : false} name={["global:ws_accepts_cc", "visa"]} noStyle><Checkbox /></Form.Item>&nbsp; VISA</Typography.Paragraph>
                            <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc"].mastercard) ? true : false} name={["global:ws_accepts_cc", "mastercard"]} noStyle><Checkbox /></Form.Item>&nbsp; MasterCard</Typography.Paragraph>
                            <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc"].amex) ? true : false} name={["global:ws_accepts_cc", "amex"]} noStyle><Checkbox /></Form.Item>&nbsp; American Express</Typography.Paragraph>
                            <Typography.Paragraph ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc"].discover) ? true : false} name={["global:ws_accepts_cc", "discover"]} noStyle><Checkbox /></Form.Item>&nbsp; Discover</Typography.Paragraph>
                        </div>
                    </Space>
                </conditional.true>

                {/* Disabled for now */}
                <conditional.true value={(method === "clearent")}>
                    <conditional.true value={(!window.sitesettings.is_canadian)}>
                        <div>
                            {/* Pay at pick up */}
                            <div id="ws_credit_card_clearent"></div>
                            <WebstoreItem
                                has_switch={false}
                                name="global:ws_credit_card_clearent"
                                value={(ws["global:ws_credit_card_clearent"]) ? true : false}
                                heading="Clearent"
                                description={<>If you use Clearent in-store to process payments, you can also enable them here to facilitate payments on your consumer Webstore. Clearent integration will not send actual credit card numbers into your Bookmanager software, but instead pass along tokens that can only be used by your store. Tokens hugely reduce liability, and are useless to anyone but your store's specific merchant account with Clearent. A token-based system means staff will never see customer credit card details, giving both merchants and customers superior security and peace of mind.
                                    <br /> <br />
                                    <strong>Payment is NOT processed automatically at the time the customer checks out. Tokens are used in your in-store copy of Bookmanager to process the final payment, and must be manually processed by staff.</strong>
                                    <br /> <br />
                                    <strong><a href="mailto:mail@bookmanager.com"> If you would like to enable tokens, please contact Bookmanager so they can register your domain name through Clearent. </a></strong>
                                    <div className="shim" /><div className="shim" />
                                </>}
                            />
                            {/* Tokens can be authorized for a one-time use, or for multiple uses if the customer wishes to keep their credit card on file for future purchases. */}
                            <div className="shim" />
                            <Space>
                                <div style={{ "width": "35px" }}></div>
                                <div style={{ "width": "430px" }}>
                                    <Typography.Paragraph><strong>Public Key </strong>
                                        <div className="shim"></div>
                                        {/* <ValidateClearent key={ws["ws_clearent_public_key"]} /> */}
                                        <Form.Item initialValue={ws["global:ws_clearent_public_key"]} name="global:ws_clearent_public_key" noStyle>
                                            <Input.TextArea placeholder="Public Key..." size="small" rows={6} style={{ "maxWidth": "500px" }}></Input.TextArea>
                                        </Form.Item>
                                    </Typography.Paragraph>
                                    <div className="shim"></div>
                                    <Typography.Text ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc_shipping"]) ? true : false} name="global:ws_accepts_cc_shipping" noStyle><Checkbox disabled={(!props.autoHeldSale)} /></Form.Item>&nbsp; Enable Credit Card payments on orders to be <strong>Shipped</strong></Typography.Text><br />
                                    <conditional.true value={((!props.autoHeldSale))}><Typography.Text type="danger">"Submitted orders will auto generate a Held Sale in Bookmanager" must be enabled.</Typography.Text><br /></conditional.true>
                                    <div className="shim"></div><div className="shim"></div>
                                    <Typography.Text ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc_pickup"]) ? true : false} name="global:ws_accepts_cc_pickup" noStyle><Checkbox disabled={(!props.autoHeldSale)} /></Form.Item>&nbsp; Enable Credit Card payments on orders to be <strong>Picked Up</strong></Typography.Text><br />
                                    <conditional.true value={((!props.autoHeldSale))}><Typography.Text type="danger">"Submitted orders will auto generate a Held Sale in Bookmanager" must be enabled.</Typography.Text><br /></conditional.true>
                                    <div className="shim"></div><div className="shim"></div>
                                    <div className="shim"></div>
                                    <span style={{ "color": "#f00" }}>Once your Public Key is set, we highly recommend submitting a test order online, then processing that transaction fully in the Bookmanager software to ensure everything is working properly. This process will also make sure you know how to process a refund / void.</span>
                                    <div className="shim"></div>
                                    <div className="shim"></div> <div className="shim"></div>
                                </div>
                            </Space>
                        </div>
                    </conditional.true>
                </conditional.true>
                <conditional.true value={(window.sitesettings.is_canadian)}>
                    <conditional.true value={(method === "global")}>
                        <div>
                            {/* Pay at pick up */}
                            <div id="ws_credit_card_global"></div>
                            <WebstoreItem
                                name="global:ws_credit_card_global"
                                has_switch={false}
                                value={(ws["global:ws_credit_card_global"]) ? true : false}
                                heading="Global Payments"
                                description={<>
                                    If you use Global Payments in-store to process payments, you can also enable them here to facilitate payments on your consumer Webstore. Global Payments integration will not send actual credit card numbers into your Bookmanager software, but instead pass along tokens that can only be used by your store. Tokens hugely reduce liability, and are useless to anyone but your store's specific merchant account with Global Payments. A token-based system means staff will never see customer credit card details, giving both merchants and customers superior security and peace of mind.
                                    <br /><br />
                                    <strong>Payment is NOT processed automatically at the time the customer checks out. Tokens are used in your in-store copy of Bookmanager to process the final payment, and must be manually processed by staff.</strong>
                                    <br /><br />
                                    <div><strong><a style={{ "color": "#f00" }} href="mailto:mail@bookmanager.com">Contact Bookmanager to obtain your public and private keys. </a></strong></div>

                                </>}
                            />
                            {/* Tokens can be authorized for a one-time use, or for multiple uses if the customer wishes to keep their credit card on file for future purchases. */}
                            <div className="shim" />
                            <Space>
                                <div style={{ "width": "35px" }}></div>
                                <div style={{ "width": "430px" }}>
                                    <Typography.Paragraph><strong>Public Key </strong>
                                        <div className="shim"></div>
                                        {stores.map(item => {
                                            let _key = "ms" + item.store_number + ":ws_global_pubkey";
                                            return (
                                                <div>
                                                    <em style={{ "opacity": "0.7" }}>{item.name}</em>
                                                    <Form.Item initialValue={ws[_key]} name={_key} noStyle>
                                                        <Input placeholder="Public Key..." size="middle" style={{ "width": "530px" }}></Input>
                                                    </Form.Item>
                                                    <div className="shim" /><div className="shim" />
                                                </div>
                                            )
                                        })}

                                    </Typography.Paragraph>
                                    <Typography.Paragraph><strong>Private Key </strong>
                                        <div className="shim"></div>
                                        {stores.map(item => {
                                            let _key = "ms" + item.store_number + ":ws_global_privkey";
                                            return (
                                                <div>
                                                    <em style={{ "opacity": "0.7" }}>{item.name}</em>
                                                    <Form.Item initialValue={ws[_key]} name={_key} noStyle>
                                                        <Input placeholder="Private Key..." size="middle" style={{ "width": "530px" }}></Input>
                                                    </Form.Item>
                                                    <div className="shim" /><div className="shim" />
                                                </div>
                                            )
                                        })}

                                    </Typography.Paragraph>
                                    <div className="shim"></div>
                                    <Typography.Text ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc_shipping"]) ? true : false} name="global:ws_accepts_cc_shipping" noStyle><Checkbox disabled={(!props.autoHeldSale)} /></Form.Item>&nbsp; Enable Credit Card payments on orders to be <strong>Shipped</strong></Typography.Text><br />
                                    <conditional.true value={((!props.autoHeldSale))}><Typography.Text type="danger">"Submitted orders will auto generate a Held Sale in Bookmanager" must be enabled.</Typography.Text><br /></conditional.true>
                                    <div className="shim"></div><div className="shim"></div>
                                    <Typography.Text ><Form.Item valuePropName='checked' initialValue={(ws["global:ws_accepts_cc_pickup"]) ? true : false} name="global:ws_accepts_cc_pickup" noStyle><Checkbox disabled={(!props.autoHeldSale)} /></Form.Item>&nbsp; Enable Credit Card payments on orders to be <strong>Picked Up</strong></Typography.Text><br />
                                    <conditional.true value={((!props.autoHeldSale))}><Typography.Text type="danger">"Submitted orders will auto generate a Held Sale in Bookmanager" must be enabled.</Typography.Text><br /></conditional.true>
                                    <div className="shim"></div><div className="shim"></div>
                                    <div className="shim"></div>

                                    <span style={{ "color": "#f00" }}>Once your Public and Private Keys are set, we highly recommend submitting a test order online, then processing that transaction fully in the Bookmanager software to ensure everything is working properly. This process will also make sure you know how to process a refund / void.</span>
                                    <div className="shim"></div>
                                   
                                    <div className="shim"></div> <div className="shim"></div>
                                    {/* <Typography.Paragraph type="secondary">For instructions on where to find the API Key and Merchant ID, <a target={"_blank"} href="https://clearent.com/getting-started/">click here.</a></Typography.Paragraph> */}
                                </div>
                            </Space>
                        </div>
                    </conditional.true>
                </conditional.true>
            </div>
        </>
    )
}