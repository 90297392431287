import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { apiCall } from "../utils/Api";
import { getMemory, saveMemory } from "../utils/Utils";

export default function OrderSearchUser(props) {

    const [options, setOptions] = useState([]);
    const { defaultValue = "", placeholder = "", saveOrder = () => {}, updateOrder = () => { }, recordKey = "", width = "100%", disabled = false } = props;
    const [loading, setLoading] = useState(false)


    const remembered = getMemory("customer_name").map(item => {
        return {
            value: item,
            label: <small>{item}</small>
        }
    })

    const searchUsers = (_term = " ") => {

        if (!_term) {
            return;
        }

        setLoading(true);

        apiCall("cart/searchUser", { term: _term }, (_status, _result) => {
            setLoading(false);
            if (_status) {
                setOptions(_result.customers.slice(0, 20).map((item, index) => {
                    return {
                        value: "(" + item.shipto + ") " + item.name,
                        label: <small>{item.name}</small>
                    }
                }));
            } else {
                setOptions([]);
            }
            
        })
    }

    //useEffect(searchUsers, []);




    const delaySearch = (term) => {

      

        if(!term){
           setOptions([]);
           return; 
        }

        setLoading(true);
        
        if (window.saveTimout) {
            window.clearTimeout(window.saveTimout);
            
        }
       
       
        window.saveTimout = window.setTimeout(() => searchUsers(term), 400);

    }

    
    return (
        <>
            <Select
                showSearch
                allowClear={true}
                disabled={disabled}
                notFoundContent={<div style={{ "fontSize": "12px", "opacity": "0.55" }}>{(loading) ? "Searching..." : "No users found..."}</div>}
                style={{ width: width, "fontSize": "12px", "textAlign": "left" }}
                placeholder={placeholder}
                size="small"
                defaultValue={(defaultValue) ? defaultValue : undefined}
                onSearch={(e) => delaySearch(e)}
                onBlur={() => setLoading(false)}
                onChange={(e) => {
                    if(e){
                        // only remember if it's set to a value
                        saveMemory("customer_name", e);
                    }
                    updateOrder(recordKey, "customer_name", e, true);
                }}
                // Disable default string search
                filterOption={(input, option) => { return true; }}
                loading={loading}
                options={(options.length>0) ? options : remembered}
             />

        </>
    )
}
