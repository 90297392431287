import React, { useEffect, useState } from "react";
import { Space, Button, Table, Card, Typography, Row, Col, Divider, message, Spin, Form, Input, Select, DatePicker } from "antd";
import { apiCall } from "../utils/Api";
import { PlusSquareFilled, MinusSquareFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import conditional from "../utils/conditional";
import moment from "moment";
import GiftRegistryImport from "./GiftRegistryImport";
export default function GiftRegistyDetails(props) {

    const { updateRow = () => { }, close = () => { }, data = {} } = props;
    const { gift_registry_id = "" } = data;

    const [isbn, setIsbn] = useState("")



    const [results, setResults] = useState({
        owner: { name: "", email: "", phone: "", is_you: "" },
        shipping_id: null,
        shipping_address: null,
        shipping_encouraged: false,
        purchase_deadline: null,
        store_can_edit: false,
        occasion: "",
        title: "",
        description: "",
        items: [],
        date_created_ts: null
    });

    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState({ key: "title", asc: true });

    const getRegistry = () => {
        setLoading(true)
        apiCall("giftregistry/get", { eid: gift_registry_id }, (_status, _result) => {
            if (_status) {
                setResults(_result);
            }
            setLoading(false);
        })
    }

    useEffect(getRegistry, []);


    const updateTitle = (_isbn, _qty, _inc = false) => {

        if (_inc) {
            _qty = _qty + 1;
        } else {
            _qty = _qty - 1;
            if (_qty < 0) {
                _qty = 0;
            }
        }

        let _obj = {
            gift_registry_id: gift_registry_id,
            isbn: _isbn,
            qty: _qty,
        }

        setLoading(true);

        apiCall("giftregistry/updateItem", _obj, (_status, _result) => {
            if (_status) {
                setResults(_result);
                updateRow(_result);
                setIsbn("");
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })

    }


    const addTitle = (_isbn, _qty) => {

        let _obj = {
            gift_registry_id: gift_registry_id,
            isbn: _isbn,
            qty: 1,
        }

        setLoading(true);

        apiCall("giftregistry/addItem", _obj, (_status, _result) => {
            if (_status) {
                setResults(_result);
                updateRow(_result);
                setIsbn("");
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })

    }

    const removeTitle = (_isbn) => {

        let _obj = {
            gift_registry_id: gift_registry_id,
            isbn: _isbn,
        }
        setLoading(true);
        apiCall("giftregistry/deleteItem", _obj, (_status, _result) => {
            if (_status) {
                setResults(_result);
                updateRow(_result);
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })

    }

    const updateRegistry = (_f) => {

        setLoading(true);
        _f.gift_registry_id = gift_registry_id;
        if (_f.purchase_deadline_ts) {
            _f.purchase_deadline_ts = moment(_f.purchase_deadline_ts).endOf('day').unix();
        } else {
            delete _f.purchase_deadline_ts;
        }

        apiCall("giftregistry/update", _f, (_status, _result) => {
            if (_status) {
                setResults(_result);
                updateRow(_result);
                message.success("Registry updated.")
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }



    const sortItems = (_arr) => {

        let _items = [..._arr];


        switch (sort.key) {
            case "title":
                _items = _items.sort((a, b) => {
                    return a.title.title.localeCompare(b.title.title);
                })
                break;
            case "author":
                _items = _items.sort((a, b) => {
                    return a.title.authors[0].name.localeCompare(b.title.authors[0].name);
                })
                break;
            case "isbn":
                _items = _items.sort((a, b) => {
                    return a.title.isbn.localeCompare(b.title.isbn);
                })
                break;
        }

        if (!sort.asc) {
            return _items.reverse();
        }

        return _items;
    }

    const markPurchased = (_registry_id, _eisbn, _quantity, _inc = true) => {

        if (_inc) {
            _quantity = _quantity + 1;
        } else {
            _quantity = _quantity - 1;
            if (_quantity < 0) {
                _quantity = 0;
            }
        }
        setLoading(true);
        apiCall("giftregistry/markPurchased", { eid: _registry_id, eisbn: _eisbn, qty_purchased: _quantity }, (_status, _result) => {
            if (_status) {
                setResults(_result);
                updateRow(_result);
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }

    const drawSort = (key = false) => {

        return (
            <span style={{ "position": "relative", "top": "-3px" }} class="ant-table-column-sorter-inner">
                <span role="img" aria-label="caret-up" class="anticon anticon-caret-up ant-table-column-sorter-up">
                    <small><CaretUpOutlined style={{ "color": ((sort.key === key) && sort.asc) ? "#1e90ff" : "#777" }} /></small>
                </span><span role="img" aria-label="caret-down" class="anticon anticon-caret-down ant-table-column-sorter-down">
                    <small><CaretDownOutlined style={{ "color": ((sort.key === key) && !sort.asc) ? "#1e90ff" : "#777" }} /></small>
                </span>
            </span>
        )
    }



    const titleColumns = [
        { key: "blank", width: "10px" },

        {
            title: <small></small>, width: "40px", dataIndex: 'title', key: 'title', render: (e, f) => {
                return <div style={{ "textAlign": "center" }}><img className="lightshadow" onError={(e) => e.target.src = "/no-cover.png"} style={{ "maxHeight": "34px", "maxWidth": "30px" }} src={"https://bookmanager.com/i/slider_23.php?b=" + f.title.eisbn} /></div>
            }
        },
        {
            title: <small style={{ "cursor": "pointer" }} onClick={() => setSort({ key: "title", asc: (sort.key === "title") ? !sort.asc : true })}>Title &nbsp;{drawSort("title")}</small>, dataIndex: 'title', key: 'title', render: (e, f) => {
                return (
                    <div className="nowrap150">
                        <a href={"/purchasing/browse/filter/t/" + f.title.isbn} target="blank" style={{ "whiteSpace": "nowrap", "maxWidth": "150px", "overflow": "hidden", "textOverflow": "ellipsis" }}>
                            {f.title.title}
                        </a>
                    </div>
                )
            }
        },
        {
            title: <small style={{ "cursor": "pointer" }} onClick={() => setSort({ key: "author", asc: (sort.key === "author") ? !sort.asc : true })}>Author &nbsp;{drawSort("author")}</small>, dataIndex: 'author', key: 'author', render: (e, f) => {
                return f.title.authors.map(item => item.name).join(", ");
            }
        },
        {
            title: <small>Binding</small>, width: "80px", dataIndex: 'binding', key: 'binding', render: (e, f) => {
                return f.title.binding;
            }
        },
        {
            title: <small>Price</small>, width: "60px", dataIndex: 'price', key: 'price', render: (e, f) => {
                return <>${(f.title.available.length>0) ? f.title.available[0].row_price : ""}</>;
            }
        },
        {
            title: <small style={{ "cursor": "pointer" }} onClick={() => setSort({ key: "isbn", asc: (sort.key === "isbn") ? !sort.asc : true })}>ISBN &nbsp;{drawSort("isbn")}</small>, width: "100px", dataIndex: 'isbn', key: 'isbn', render: (e, f) => {
                return <nobr>{f.title.isbn}</nobr>;
            }
        },
        {
            title: <small>aOH</small>, width: "40px", dataIndex: 'aoh', key: 'aoh', render: (e, f) => {
                return <>{(f.title.available.length>0) ? f.title.available[0].onhand : ""}</>;
            }
        },
        {
            title: <small>aOO </small>, width: "40px", dataIndex: 'aoo', key: 'aoo', render: (e, f) => {
               
                return <>{(f.title.available.length>0) ? f.title.available[0].onorder : ""}</>;
            }
        },

    ]


    const col_requested = {
        title: <small>Requested</small>, width: "70px", dataIndex: 'gift_registry_id', key: 'gift_registry_id', render: (e, f) => {
            return (
                <Space size={0}>
                    <Button disabled={(f.qty_requested < 2)} onClick={() => updateTitle(f.title.isbn, f.qty_requested, false)} style={{ "padding": "0px 3px" }} size="small" type="link"><MinusSquareFilled /></Button>
                    <div style={{ "padding": "5px" }}>{f.qty_requested}</div>
                    <Button onClick={() => updateTitle(f.title.isbn, f.qty_requested, true)} style={{ "padding": "0px 3px" }} size="small" type="link"><PlusSquareFilled /></Button>
                </Space>
            )
        }
    };

    const col_status = {
        title: <small>Status</small>, width: "60px", dataIndex: 'status', key: 'status', render: (e, f) => {
            return <>{f.qty_purchased} of {f.qty_requested}</>
        }
    };

    const col_purchased = {
        title: <small>Purchased</small>, width: "70px", dataIndex: 'gift_registry_id', key: 'gift_registry_id', render: (e, f) => {
            return (
                <Space size={0}>
                    <Button disabled={(f.qty_purchased < 1)} onClick={() => markPurchased(e, f.title.eisbn, f.qty_purchased, false)} style={{ "padding": "0px 3px" }} size="small" type="link"><MinusSquareFilled /></Button>
                    <div style={{ "padding": "5px" }}>{f.qty_purchased}</div>
                    <Button onClick={() => markPurchased(e, f.title.eisbn, f.qty_purchased, true)} style={{ "padding": "0px 3px" }} size="small" type="link"><PlusSquareFilled /></Button>
                </Space>
            )
        }
    };

    const col_remove = {
        title: <small>Remove</small>, width: "65px", dataIndex: 'remove', key: 'remove', render: (e, f) => {
            return <Button onClick={() => removeTitle(f.title.isbn)} danger size="small"><small>Remove</small></Button>
        }
    };

    const col_blank = { key: "blank", width: "10px" };

    if (results.store_can_edit) {
        titleColumns.push(col_requested, col_purchased, col_remove, col_blank)
    } else {
        titleColumns.push(col_status, col_purchased, col_blank)
    }


    const occasions = ["Birthday", "Wedding", "Baby", "School", "Holiday", "Bookfair", "Other"];

    const {
        owner = { name: "", email: "", phone: "", is_you: "" },
        shipping_id = null,

        shipping_address = null,
        shipping_encouraged = false,
        purchase_deadline = null,
        occasion = "",
        title = "",
        description = "",
        items = [],
        date_created_ts = null
    } = results;

    return (
        <div style={{ "padding": "20px" }}>
            <Spin spinning={loading}>
                <Form onFinish={(_f) => updateRegistry(_f)} layout="vertical">
                    <Typography.Title level={5}>Registry details</Typography.Title>
                    <Divider dashed style={{ "margin": "15px 0px" }} />
                    <Row gutter={20}>
                        <Col span={14}>

                            <conditional.true value={results.store_can_edit}>


                                <Form.Item initialValue={title} name="title" label="Title" rules={[{ required: true, message: 'Title is required.' }]}>
                                    <Input placeholder="Title..." />
                                </Form.Item>

                                <Form.Item initialValue={description} name="description" label="Description" rules={[{ required: true, message: 'Description is required.' }]}>
                                    <Input.TextArea maxLength={2000} placeholder="Description..." />
                                </Form.Item>

                                <Space>
                                    <div style={{ "width": "200px" }}>
                                        <Form.Item initialValue={occasion} name="occasion" label="Occasion" rules={[{ required: true, message: 'Occasion is required.' }]}>
                                            <Select>
                                                {occasions.map((item, index) => {
                                                    return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <Form.Item initialValue={(purchase_deadline) ? moment(purchase_deadline * 1000) : null} name="purchase_deadline_ts" label="Purchase deadline">
                                        <DatePicker dropdownClassName="interact" />
                                    </Form.Item>

                                    {(results.store_can_edit && <>
                                        <div style={{"marginTop" : "5px", "paddingLeft" : "5px"}}><Button htmlType="submit" type="primary">Save</Button></div>
                                    </>)}

                                </Space>

                            </conditional.true>

                            <conditional.true value={!results.store_can_edit}>
                                <strong><small>Registry:</small></strong>
                                <div className="shim" />
                                <Card>
                                    <Card.Meta
                                        title={<span className="c">{title}</span>}
                                        description={<div className="c">
                                            <div>Occasion: <em>{occasion}</em></div>
                                            <div>{description}</div>
                                        </div>}
                                    />
                                </Card>
                            </conditional.true>

                        </Col>
                        <Col span={10}>
                            <strong><small>Creator:</small></strong>
                            <div className="shim" />
                            <Card>
                                <Card.Meta
                                    title={<span className="c">{owner.name}</span>}
                                    description={<div className="c">
                                        <a href={"mailto:" + owner.email}>{owner.email}</a>
                                        <div>{owner.phone}</div>
                                    </div>}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <div className="shim" /><div className="shim" />
                    <strong><small>Titles:</small></strong>
                    <div className="shim" />
                    <Table
                        title={() => {
                            if (!results.store_can_edit) {
                                return <></>;
                            }
                            return (<div style={{ "padding": "8px 8px" }}><Space><Input.Search value={isbn} maxLength={13} onChange={(e) => setIsbn(e.target.value)} onSearch={(e) => addTitle(e)} size="small" style={{ "width": "330px" }} placeholder="Add ISBN" enterButton={<Button disabled={(!isbn)} type="primary"><small>Add ISBN</small></Button>} /><GiftRegistryImport gift_registry_id={gift_registry_id} getRegistry={getRegistry} /></Space></div>)
                        }}
                        locale={{ emptyText: "No titles" }}
                        scroll={{ y: 255 }}
                        className={(results.store_can_edit) ? "tborder2" : "tborder3"}
                        pagination={false}
                        columns={titleColumns}
                        dataSource={sortItems(items)}
                        size="small">
                    </Table>
                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => close()}>Close</Button>

                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </Spin>
        </div>
    )
}