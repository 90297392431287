import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';

// Helper function to generate time options in 15-minute increments
const generateTimes = () => {
    const times = [];
    const periods = ['AM', 'PM'];
    for (let i = 1; i < 25; i++) {
        const hour = i % 12 === 0 ? 12 : i % 12;
        const period = periods[Math.floor((i - 1) / 12)];
        times.push(`${hour}:00 ${period}`);
        // times.push(`${hour}:15 ${period}`);
        times.push(`${hour}:30 ${period}`);
        // times.push(`${hour}:45 ${period}`);
    }
    return times;
};

const times = [
    "12:00 AM",
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  
]



const formatTime = (input) => {

    if (!input) {
        return "";
    }

    let hours, minutes;
    // Remove invalid characters
    let time = input.replace(/[^0-9APMapm]/gi, '');

    // Store AM/PM
    let period = "PM";
    if (time.toUpperCase().includes('AM')) {
        period = "AM"
    }
    if (time.toUpperCase().includes('PM')) {
        period = "PM"
    }

    // Strip AM/PM
    time = time.replace(/[^0-9]/gi, '');

    // Handle various input lengths
    if (time.length <= 2) {
        hours = parseInt(time, 10);
        minutes = '00';
    } else if (time.length === 3) {
        hours = parseInt(time[0], 10);
        minutes = time.slice(1);
    } else {
        hours = parseInt(time.slice(0, time.length - 2), 10);
        minutes = time.slice(time.length - 2);
    }
    // Convert hours to number and back to string to remove zero padding
    hours = parseInt(hours, 10).toString();
    if (minutes.length === 1) {
        minutes = `0${minutes}`;
    }
    // Clamp hours, minutes
    if (hours === 0) { hours = 1; }
    if (hours > 12) { hours = 12; }
    if (minutes > 59) { minutes = 59; }

    let _ret = `${hours}:${minutes} ${period}`

    return _ret;
};

const TimeAutoComplete = (props) => {
    

    const {
        disabled = false,
        defaultValue = "",
        onChange = () => {}
    } = props;

    const [inputValue, setInputValue] = useState(defaultValue);
    const [options, setOptions] = useState(times);
    const handleSearch = (value) => {
        if (value) {
            setOptions(times.filter(time => time.startsWith(value)));
        } else {
            setOptions(times);
        }
    };

    const handleChange = (value) => { setInputValue(value); onChange(formatTime(value)) };
    const handleSelect = (value) => { setInputValue(value); onChange(formatTime(value)) };
    const handleBlur = () => { setInputValue(formatTime(inputValue)); };

    const handleKeyPress = (event) => {
        const charCode = event.which ? event.which : event.keyCode;
        const char = String.fromCharCode(charCode);
        // Set on enter as well
        if (event.key === "Enter") {
            setInputValue(formatTime(event.target.value));
            return;
        }
        const validChars = /[0-9:APMapm]/;
        if (!validChars.test(char)) {
            event.preventDefault();
        }
    };

    return (
        <AutoComplete
            disabled={disabled}
            value={inputValue}
            allowClear
            options={options.map(time => ({ value: time }))}
            onSelect={handleSelect}
            onSearch={handleSearch}
            onChange={handleChange}
            style={{ width: 145 }}
            maxLength={8}
            dropdownMatchSelectWidth={false}
            listHeight={256} // Sets the height of the dropdown for scrolling
        >
            <Input
                defaultValue={defaultValue}
                suffix={<small>&nbsp;<DownOutlined style={{ "opacity": "0.5" }} /></small>}
                onBlur={handleBlur}
                onFocus={(e) => e.target.select()}
                placeholder="Select Time"
                onKeyPress={handleKeyPress}
            />
        </AutoComplete>
    );
};

export default TimeAutoComplete;
