import React, { useState, useEffect, useRef } from "react";
import { Layout, Typography, Divider, Button, Spin, Card, Row, Col, Result, Alert, Space, Table } from "antd";
import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional";
import { Link, useParams } from "react-router-dom";
import Html from "../utils/Html";
import moment from "moment";
import nocover from "../media/no-cover.png";
import { PrinterOutlined } from '@ant-design/icons';
import { convertLineBreaks, parseAuthors, parseAuthorsPlain } from "../utils/Utils.js";


export default function PreviousOrder(props) {


    const { order_id = 0 } = useParams();
    const bookorder_id = (props.bookorder_id) ? props.bookorder_id : order_id;
    const can_print = (order_id) ? true : false;
    const [data, setData] = useState({ items: [] });
    const [customer, setCustomer] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [versionWarning, setVersionWarning] = useState("");

    const {
        name = "",
        email = "",
        addr1 = "",
        addr2 = "",
        city = "",
        prov = "",
        postal = "",
        country = "",
        phone = "",
        provider = "",
        orgname = ""
    } = (data.shipping) ? data.shipping : {}


    const { custom_form = {} } = data;
    const { delivery_choice_label = "", form_header = "", values = [] } = custom_form;

    const { summary = {}, order_number = 0, order_date = 0, communication_preference = "phone", delivery_type = "", payment_type = "", payment_message = "" } = data;
    const { promo_codes = [], discounts = "0.00", total_gift_card_amount_used = "0.00", total_owing = "0.00", item_count = 0, taxes = [], subtotal = "0.00", total = "0.00", shipping = "0.00" } = summary;

    const getOrder = () => {
        apiCall("order/get", { bookorder_id: bookorder_id }, (_status, _result) => {
            if (_status) {
                if ("online_order_version" in _result && _result.online_order_version.localeCompare('V3') !== 0) {
                    setVersionWarning(
                        <>
                            <Alert
                                message="Error"
                                description="This order was completed using an outdated webstore version.
                                This is likely due to the customer having bookmarked an old URL for your site.
                                Some order details may be missing or incorrect.
                                Please refer to your bookmanager software's F3 log to review order details."
                                type="error"
                                className="no-print"
                                showIcon
                            />
                            <div className="shim no-print"></div><div className="shim no-print"></div>
                        </>
                    );
                } else {
                    setVersionWarning("");
                }
                setCustomer(_result.user_info)
                setData(_result.order_info);
                setLoading(false);
                if (can_print) {
                    window.print();
                }
            } else {
                setLoading(false);
                setErrorMsg(_result.error)

            }
        })
    }
    useEffect(getOrder, []);

    const ucfirst = (string) => {
        return (string) ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }

    const drawTaxes = (_arr) => {
        return _arr.map((item, index) => {
            return (<span key={index} style={{ "textAlign": "right", "display": "block" }} className="print-align-text-left">{(item.type) ? <>{item.type} </> : ""}${item.amount}</span>)
        })
    }

    const pluralize = (count, noun, suffix = 's') => {
        return (count === 1) ? noun : noun + suffix;
    }

    const colorMessage = (_available) => {
        let messageColor = (_available.availability === "available") ? "#4dac1e" : "#e77e21";
        return (<span style={{ "color": messageColor }}>{ucfirst(_available.message)}</span>)
    }


    const drawItem = (_item, _data) => {

        let { gift_card_info = false } = _item.cart_info.cart_notes;
        let {
            original_price = "",
            sold_price = "",
            discount = 0,
        } = _item.cart_info;

        let { shipping_batches = [] } = _data;

        let shipment = [];
        if (shipping_batches.length > 1) {
            shipping_batches.forEach((bat, index) => {
                if (bat.filter(item => item.eisbn === _item.title_info.eisbn && item.condition === _item.title_info.available.code).length > 0) {
                    shipment.push("Shipment #" + (index + 1));
                }
            });
        }


        return (
            <div className="avoid-cut">
                <Card className="c bc print-pad-thin" bodyStyle={{ "padding": "15px", "border": "none", "fontSize": "12px" }} key={_item.title_info.eisbn}>
                    <Row>
                        <Col span={3}>
                            <img onClick={() => window.open("/purchasing/browse/filter/t/" + _item.title_info.isbn, "_blank")} onError={(e) => e.target.src = nocover} className="lightshadow" style={{ "maxHeight": "100px", "maxWidth": "70px", "cursor": "pointer" }} src={"https://bookmanager.com/i/slider_23.php?b=" + _item.title_info.eisbn} />
                        </Col>
                        <Col span={21}>
                            <Row gutter={10}>
                                <Col span={7}>
                                    <a onClick={() => window.open("/purchasing/browse/filter/t/" + _item.title_info.isbn, "_blank")}>{_item.title_info.title}</a>
                                    <div style={{ "fontSize": "12px" }}><span style={{ "fontFamily": "monospace" }}>{_item.title_info.isbn}</span> <span style={{ "fontSize": "10px", "fontWeight": "600" }}>{(_item.title_info.available.text) ? " - " + _item.title_info.available.text : ""}</span></div>
                                    {((shipment.length > 0) && <div style={{ "fontSize": "12px" }}><em>{shipment.join(", ")}</em></div>)}
                                </Col>
                                <Col span={5}>
                                    {parseAuthorsPlain(_item.title_info.authors)}
                                </Col>
                                <Col span={5}>
                                    {(_item.cart_info.is_giftcard) ? <>{_item.cart_info.quantity} x ${_item.cart_info.cart_notes.giftcard_price}</> : <>{_item.cart_info.quantity} x ${sold_price}</>}
                                    {((discount > 0) && <div style={{ "color": "red" }}><em >{discount}% off ${original_price}</em></div>)}
                                </Col>
                                <Col span={7}>
                                    {colorMessage(_item.title_info.available)}
                                    <conditional.true value={(_item.cart_info.backorder_quantity)}>
                                        <div className="shim" /><div style={{ "color": "#BD5800", "display": "block" }}>{_item.cart_info.backorder_quantity} to be ordered.</div>
                                    </conditional.true>
                                    <conditional.true value={(_item.cart_info.backorder_quantity && _item.cart_info.backorder_sub_message)}>
                                        <div className="shim" />
                                        <small style={{ "color": "#BD5800", "lineHeight": "10px" }}><em>{_item.cart_info.backorder_sub_message}</em></small>
                                    </conditional.true>
                                    <conditional.true value={((!_item.title_info.available.onhand) && (!_item.title_info.is_forthcoming) && (!_item.cart_info.is_giftcard))}>
                                        <div className="shim" />
                                        <small style={{ "color": "#BD5800" }}><em>{_item.title_info.available.sub_message}</em></small>
                                    </conditional.true>
                                    <conditional.true value={((!_item.title_info.available.onhand) && (_item.title_info.is_forthcoming))}>
                                        <div className="shim" />
                                        <small><em style={{ "color": "#BD5800" }}>Releases: {moment(_item.title_info.release_date * 1000).format('MMM DD, YYYY')}</em></small>
                                    </conditional.true>
                                </Col>
                            </Row>

                            {((gift_card_info && gift_card_info.personalize) && <>
                                <div style={{ "fontSize": "12px" }}>
                                    <div>From : <em>{gift_card_info.from}</em> &nbsp; - &nbsp; To : <em>{gift_card_info.to}</em></div>
                                    <div>Message : <em>{gift_card_info.message}</em></div>
                                </div>
                            </>)}

                            {(_item.cart_info.gift_registry_info && <>
                                <div>{_item.cart_info.gift_registry_info.occasion} gift registry : <em>{_item.cart_info.gift_registry_info.name}</em></div>
                            </>)}
                        </Col>
                    </Row>
                </Card>
                <div className="shim" /><div className="shim" />
            </div>
        )
    }





    const drawCustomer = (_cust) => {






        // Don't draw card if customer object is null/false
        if (!_cust) {
            return (<></>)
        }

        return (
            <>
                <Card className="bc c flex-card print-pad-thin" >

                    <div className="flex-child-50">
                        <strong>Sold to</strong>

                        <div style={{ "paddingLeft": "10px" }}>
                            {(customer.is_guest && <div><em>Guest Checkout</em></div>)}
                            {customer.first_name} {customer.last_name}
                            <conditional.true value={(customer.company)}>
                                <br />{customer.company}
                            </conditional.true>
                            <conditional.true value={(customer.email_address)}>
                                <br /><a href={"mailto:" + customer.email_address}>{customer.email_address}</a>
                            </conditional.true>
                            <conditional.true value={(customer.phone_number)}>
                                <br /><>{customer.phone_number}</>
                            </conditional.true>
                            <conditional.true value={(customer.hasOwnProperty("address") && (customer.address))}>
                                <br />
                                {(customer.hasOwnProperty("address") && customer.address)}
                                <br />
                                {(customer.hasOwnProperty("city") && customer.city)}, {(customer.hasOwnProperty("prov") && customer.prov)} {(customer.hasOwnProperty("country") && customer.country)}
                                <br />
                                {(customer.hasOwnProperty("postal") && customer.postal)}
                                <br />

                            </conditional.true>
                            <br />

                        </div>
                    </div>
                    <div className="flex-child-50">
                        <conditional.true value={(data.delivery_key === "ship")}>
                            <Divider dashed className="no-print" style={{ "marginTop": "10px", "marginBottom": "10px" }} />

                            {/* SHIPPING */}
                            <strong>Shipping</strong>
                        </conditional.true>

                        <div style={{ "paddingLeft": "10px" }}>
                            <conditional.true value={(data.delivery_key === "ship")}>
                                <conditional.true value={(orgname)}>
                                    {orgname}<br />
                                </conditional.true>
                                <conditional.true value={(name)}>
                                    {name}
                                </conditional.true>
                                <conditional.true value={(email)}>
                                    <br /><a href={"mailto:" + email}>{email}</a>
                                </conditional.true>
                                <conditional.true value={(phone)}>
                                    <br />{phone}
                                </conditional.true>
                                <conditional.true value={(addr1)}>
                                    <br />{addr1}
                                </conditional.true>
                                <conditional.true value={(addr2)}>
                                    <br />{addr2}
                                </conditional.true>
                                <conditional.true value={(city)}>
                                    <br />{city}, {prov} {country}
                                </conditional.true>
                                <conditional.true value={(postal)}>
                                    <br />{postal}
                                </conditional.true>
                                <conditional.true value={(provider)}>
                                    <br />{provider}
                                </conditional.true>

                            </conditional.true>
                        </div>
                    </div>


                </Card>
            </>
        )
    }

    const WrapSider = (props) => {

        if (props.can_print) {
            return (<>{props.children}<br /></>)
        } else {
            return (
                <Layout.Sider className="bcg3" width={(can_print) ? "100%" : 300} style={{ "marginRight": "10px" }} theme="light">
                    {props.children}
                </Layout.Sider>
            )
        }

    }

    const drawFormFields = () => {

        // const { custom_form = {} } = data;
        // const { delivery_choice_label = "", form_header = "", values = [] } = custom_form;


        if(!delivery_choice_label){
            return(<></>)
        }

        const columns = [
            {
                title : <small>Field</small>,
                dataIndex : "label", 
                render : (e) => {
                    return(<em>{e}</em>)
                }
            },
            {
                title : <small>Value</small>,
                dataIndex : "value"
            }
        ]
        return (
            <div>
                <div className="shim"></div><div className="shim"></div>
                <Card className="bc c avoid-cut print-pad-thin" >
                    <div><strong>{form_header}</strong></div>
                    <Divider dashed style={{"margin" : "10px 0px"}} />
                    <Table showHeader={false} className="mediumTable" pagination={false} columns={columns} dataSource={values} />
                </Card>
                <div className="shim"></div><div className="shim"></div>
            </div>

        )
    }


    return (
        <>
            <div>
                <Layout className="layout">
                    <Layout.Content className="layoutHeader" style={{ "padding": "0px", "width": "auto" }} >
                        <div style={{ "padding": (can_print) ? "10px" : "30px" }}>
                            <div style={{ "float": "right", "paddingRight": "30px", "marginTop": "-10px" }}>
                                <Button onClick={() => (can_print) ? window.print() : window.open("/previous_order/" + bookorder_id, "_blank")} className="no-print" icon={<PrinterOutlined />} type="primary">Print</Button>
                            </div>
                            <Typography.Title level={4}>Order #{bookorder_id}</Typography.Title>
                            <em>Stock statuses and order timeframes are as of the date the order was placed.</em>
                            <Divider className="no-print" />
                            <conditional.true value={loading}>
                                <div style={{ "height": "calc(100vh - 200px)", "display": "flex", "alignItems": "center", "justifyContent": "center" }}><Spin size="large" /></div>
                            </conditional.true>
                            <conditional.true value={(!loading)}>
                                <Layout className="bcg3"  >
                                    {(errorMsg && <Result status="error" title={errorMsg} />)}
                                    <conditional.true value={(!errorMsg)}>
                                        <WrapSider can_print={can_print}>
                                            <div>
                                                <conditional.true value={can_print}>
                                                    {versionWarning}
                                                </conditional.true>
                                                <Card className="bc c avoid-cut print-pad-thin" >
                                                    <Row>
                                                        <Col flex="75px"><strong>Date</strong></Col>
                                                        <Col flex="auto">{moment(order_date * 1000).format('MMM DD, YYYY h:mma')}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col flex="75px"><strong>Delivery</strong></Col>
                                                        <Col flex="auto">{delivery_type}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col flex="75px"><strong>Payment</strong></Col>
                                                        <Col flex="auto">{payment_message}</Col>
                                                    </Row>
                                                </Card>
                                                <div className="shim"></div><div className="shim"></div>
                                                {drawCustomer(customer)}

                                                {drawFormFields()}



                                               
                                                <conditional.true value={((data.notes) || (data.purchaseorder))}>
                                                    <Card className="bc c avoid-cut print-pad-thin" >
                                                        {((data.purchaseorder) && <div><strong>P/O </strong><span style={{ "display": "block" }}>{data.purchaseorder}</span></div>)}
                                                        {((data.notes) && <div><strong>Notes</strong><span style={{ "display": "block" }}>{<Html html={convertLineBreaks(data.notes)} />}</span></div>)}
                                                    </Card>
                                                </conditional.true>



                                                <div className="shim"></div><div className="shim"></div>
                                                <Card className="bc c avoid-cut print-pad-thin" >
                                                    <div><strong>Order Summary </strong><span style={{ "display": "block" }}>{item_count} {pluralize(item_count, "item", "s")}</span></div>
                                                    <Divider style={{ "margin": "10px 0px" }} />

                                                    <Row>
                                                        <Col flex="90px">Price</Col>
                                                        <Col style={{ "textAlign": "right" }} className="print-align-text-left" flex="auto">${summary.original_price}</Col>
                                                    </Row>
                                                    <conditional.true value={(promo_codes.length > 0)}>
                                                        {promo_codes.filter(item => item.type !== "freeship").map(item => {

                                                            return (
                                                                <Row>
                                                                    <Col flex="90px"><span style={{ "color": "#f00" }}>{item.description}</span></Col>
                                                                    <Col style={{ "textAlign": "right" }} className="print-align-text-left" flex="auto"><div style={{ "color": "#f00" }}>-${item.amount}</div></Col>
                                                                </Row>
                                                            )
                                                        })}
                                                    </conditional.true>
                                                    <conditional.true value={(total_gift_card_amount_used !== "0.00")}>
                                                        <Row>
                                                            <Col span={17}><span style={{ "color": "#f5222d" }}>Gift Cards</span></Col>
                                                            <Col span={7}><span style={{ "textAlign": "right", "display": "block", "color": "#f5222d" }} className="print-align-text-left">-${total_gift_card_amount_used}</span></Col>
                                                        </Row>
                                                    </conditional.true>
                                                    <conditional.true value={discounts !== "0.00"}>
                                                        <Row>
                                                            <Col flex="90px"><span style={{ "color": "#f5222d" }}>Discounts</span></Col>
                                                            <Col style={{ "textAlign": "right" }} className="print-align-text-left" flex="auto"><span style={{ "color": "#f5222d" }}>- ${discounts}</span></Col>
                                                        </Row>
                                                    </conditional.true>
                                                    <Row>
                                                        <Col flex="90px">Subtotal</Col>
                                                        <Col style={{ "textAlign": "right" }} className="print-align-text-left" flex="auto">${subtotal}</Col>
                                                    </Row>
                                                    <conditional.true value={shipping !== "0.00"}>
                                                        <Row>
                                                            <Col flex="90px">Shipping</Col>
                                                            <Col style={{ "textAlign": "right" }} className="print-align-text-left" flex="auto">${shipping}</Col>
                                                        </Row>
                                                    </conditional.true>

                                                    <conditional.true value={(promo_codes.length > 0)}>
                                                        {promo_codes.filter(item => item.type === "freeship").map(item => {

                                                            return (
                                                                <Row>
                                                                    <Col flex="90px"><span style={{ "color": "#f00" }}>{item.description}</span></Col>
                                                                    <Col style={{ "textAlign": "right" }} className="print-align-text-left" flex="auto"><div style={{ "color": "#f00" }}>-${item.amount}</div></Col>
                                                                </Row>
                                                            )
                                                        })}
                                                    </conditional.true>


                                                    <Row>
                                                        <Col flex="90px">Taxes</Col>
                                                        <Col style={{ "textAlign": "right" }} className="print-align-text-left" flex="auto">{drawTaxes(taxes)}</Col>
                                                    </Row>
                                                    <Divider className="no-print" />
                                                    <Row>
                                                        <Col flex="90px"><strong>Total Owing</strong></Col>
                                                        <Col style={{ "textAlign": "right" }} className="print-align-text-left" flex="auto"><strong>${total_owing}</strong></Col>
                                                    </Row>
                                                </Card>
                                                <div className="shim"></div><div className="shim"></div>
                                            </div>
                                        </WrapSider>
                                        <Layout.Content>
                                            <conditional.true value={!can_print}>
                                                {versionWarning}
                                            </conditional.true>
                                            {data.items.map(item => {
                                                return drawItem(item, data);
                                            })}
                                            {/* Add buffer to the bottom to prevent products being cut off when printing to PDF on firefox */}
                                            <div className="shim print-visibile" style={{ "visibility": "hidden", "padding": "60px" }} />
                                        </Layout.Content>
                                    </conditional.true>
                                </Layout>
                            </conditional.true>
                        </div>
                    </Layout.Content>
                </Layout>
            </div>
        </>
    )
}