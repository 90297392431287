import { Button, Checkbox, Form, Input, InputNumber, Radio, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { makeKey, slugify } from "../../utils/Utils";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

export default function RadioField(props) {

    const { data = {}, setSelected = () => { }, render = false, update = () => { } } = props;

    const [d, setD] = useState(data);

    const {
        id = "",
        label = "Select",
        type = "select",
        description = "",
        required = false,
        required_message = "This field is required",
        options = [{ label: "Option 1" }]
    } = d;


    useEffect(() => {
        if (!render) {
            update(d);
        }

    }, [d])


    const addOption = () => {
        let _arr = [...options];
        _arr.push({ label: "", id: makeKey() })
        setD({ ...d, options: _arr });
    }

    const removeOption = (_id) => {
        setD({ ...d, options: options.filter((item, index) => item.id !== _id) });
    }

    const canAdd = () => {
        let _ret = true;
        options.forEach(element => {
            if (element.label === "") {
                _ret = false;
            }
        });
        return _ret;
    }

    const updateOption = (_option) => {
        let _arr = [...options];
        let _f = _arr.findIndex(item => item.id === _option.id);
        if (_f > -1) {
            _arr[_f] = _option
        }
        setD({ ...d, "options": _arr });

    }

    const drawOptions = () => {

        return (
            <>
                <label>Options</label>
                <div className="shim" />
                <Space direction="vertical" >
                    {options.map((item, index) => {
                        return (
                            <Space key={item.id}>
                                <Input style={{ "width": "251px" }} onChange={(e) => updateOption({ ...item, "label": e.target.value })} defaultValue={item.label} placeholder="Label..." />
                                <Button onClick={() => removeOption(item.id)} disabled={(index === 0)} danger><small><DeleteOutlined /></small></Button>
                            </Space>
                        )
                    })}
                </Space>
                <div className="shim" /><div className="shim" />
                <div style={{ "marginRight": "10px" }}>
                    <Button disabled={(!canAdd())} onClick={() => addOption()} type="primary" size="small" block><small><PlusOutlined /> Add option</small></Button>
                </div>
            </>
        )

    }


    if (render) {
        return (
            <>
                <Form.Item extra={props.data.description} rules={[{ required: props.data.required, message: props.data.required_message }]} label={props.data.label} name={id} >
                    <Radio.Group>
                        <Space direction="vertical">
                            {props.data.options.map(item => {
                                return (
                                    <Radio value={item.label}>{item.label}</Radio>
                                )
                            })}
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <div className="shim" /><div className="shim" />
            </>
        )
    }

    return (
        <>

            {/* LABEL */}
            <label >Label</label><div className="shim" />
            <Input value={label} onChange={(e) => setD({ ...d, "label": e.target.value })} placeholder="Label..." />
            <div className="shim" /><div className="shim" />

            

            {/* LABEL */}
            <label >Description / Instructions</label><div className="shim" />
            <Input value={description} onChange={(e) => setD({ ...d, "description": e.target.value })} placeholder="Description..." />
            <div className="shim" /><div className="shim" />

             {/* REQUIRED */}
             <div className="float-flex">
                <div>&nbsp;</div>
                <Checkbox onChange={(e) => setD({ ...d, "required": e.target.checked })} checked={required} >Required?</Checkbox>
            </div>

            

            {(required &&
                <>
                    {/* REQUIRED MESSAGE */}
                    <label>Required? Error Message</label ><div className="shim" />
                    <Input value={required_message} onChange={(e) => setD({ ...d, "required_message": e.target.value })} placeholder="Message..." />
                    <div className="shim" /><div className="shim" /> <div className="shim" /><div className="shim" />
                </>
            )}


            {drawOptions()}
            <div className="shim" /><div className="shim" />


        </>
    )
}