import React, { useEffect, useState } from "react";
import { Checkbox, Layout, Form, Row, Col, Card, Typography, Divider, Input, Space, Descriptions, Button, Switch, InputNumber, message, Tabs, Modal, Spin, Alert } from "antd";
import { EditOutlined, EllipsisOutlined, DeleteOutlined } from '@ant-design/icons';
import WebstoreItem from "../../layouts/Webstore/WebstoreItem";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import GiftCard from "./GiftCard";
import { apiCall } from "../../utils/Api";
import { getSan } from "../../utils/Utils";
import { useSession } from "../../utils/Session";
import EmailPreview from "./EmailPreview";

export default function GiftCardsPage() {


    const [session, setSession] = useSession();

    const [results, setResults] = useState(
        {
            check_balance: false,
            minimum: "0.00",
            purchasable: false,
            emailed_message: "",
            emailed_label: "",

            emailed_rounded_corners: true,
            emailed_show_balance_link: true,
            emailed_show_store_name: true,
            emailed_disclaimer: "",
            livesync_enabled: false,
            giftcard_remote_tracking: false,

        }
    )

    const [min, setMin] = useState(results.minimum);
    const [cards, setCards] = useState([])

    const getNextGiftcardId = (_arr = []) => {

        let inc = _arr.length + 1;
        if (inc < 10) {
            inc = "0" + inc.toString();
        } else {
            inc = inc.toString();
        }
        if (inc > 99) {
            // too many cards...
            return "";
        }
        let prefix = "GCD";
        let san = getSan(session.corp_id, window.sitesettings.stores);
        san = san.toUpperCase();

        let ret = prefix + san + "T" + inc;

        return ret;
    }

    const [createISBN, setCreateISBN] = useState(getNextGiftcardId(results.cards));


    const fetchSettings = () => {
        apiCall("giftcard/getSettings", {}, (_status, _result) => {
            if (_status) {
                setResults({
                    purchasable: _result.purchasable,
                    minimum: _result.minimum,
                    check_balance: _result.check_balance,
                    emailed_message: _result.emailed_message,
                    emailed_label: _result.emailed_label,
                    emailed_rounded_corners: _result.emailed_rounded_corners,
                    emailed_show_balance_link: _result.emailed_show_balance_link,
                    emailed_show_store_name: _result.emailed_show_store_name,
                    emailed_disclaimer: _result.emailed_disclaimer,
                    livesync_enabled: _result.livesync_enabled,
                    giftcard_remote_tracking: _result.giftcard_remote_tracking,
                });
                setMin(_result.minimum)
                setCards(_result.cards)
                setCreateISBN(getNextGiftcardId(_result.cards))
            }
        })
    }

    useEffect(fetchSettings, []);

    const setSettings = (_obj) => {


        apiCall("giftcard/setSettings", _obj, (_status, _result) => {
            if (_status) {
                setResults({
                    purchasable: _result.purchasable,
                    minimum: _result.minimum,
                    check_balance: _result.check_balance,
                    emailed_message: _result.emailed_message,
                    emailed_label: _result.emailed_label,
                    emailed_rounded_corners: _result.emailed_rounded_corners,
                    emailed_show_balance_link: _result.emailed_show_balance_link,
                    emailed_show_store_name: _result.emailed_show_store_name,
                    emailed_disclaimer: _result.emailed_disclaimer,
                    livesync_enabled: _result.livesync_enabled,
                    giftcard_remote_tracking: _result.giftcard_remote_tracking,
                });
                message.success("Gift card settings updated")
            }
        })
    }


    const drawPhysical = () => {
        return (
            <div>
                <div style={{ "float": "right" }}>
                    <GiftCard fetchSettings={fetchSettings} create data={{ code: createISBN }} />
                </div>
                <Typography.Title level={5}>Cards</Typography.Title>
                <Divider style={{ "marginTop": "5px" }} dashed />
                <div className="gcardgrid">
                    {cards.filter(item => !item.emailed).map(item => {
                        return (<GiftCard fetchSettings={fetchSettings} data={item} />)
                    })}
                </div>
            </div>

        )
    }

    const [preview, setPreview] = useState(false)
    const drawEmail = () => {
        return (
            <Modal footer={null} title="Preview" width={630} onCancel={() => setPreview(!preview)} visible={preview}>
                <EmailPreview cards={cards.filter(item => item.emailed)} results={results} />
            </Modal>
        )
    }



    const drawEmailed = () => {

        return (
            <>
                <Alert type="warning" style={{ "marginBottom": "20px", "padding": "5px 10px" }} description={<>If you are enabling the new <strong>Emailed Gift Cards</strong>, go to the <strong>Physical Gift Cards</strong> tab, <strong>edit</strong> your existing or old <strong>'emailed gift cards,'</strong> and toggle off the <strong>'Enable for purchasing' option.</strong></>} />
                {(!results.giftcard_remote_tracking && <Alert style={{ "marginBottom": "20px" }} description={<><strong>Warning!</strong> Your Bookmanager software does not have <strong>Remote Gift Card Balance Tracking</strong> enabled. This is required in order to allow customers to check balances and redeem giftcards online.</>} />)}
                {(!results.livesync_enabled && <Alert style={{ "marginBottom": "20px" }} type="error" description={<><strong>Warning!</strong> Your Bookmanager software has not been configured for <strong>Real-Time Updates to your Webstore</strong> (what we call LiveSync). <div className="shim" /><div className="shim" />This is required in order for emailed gift cards to be delivered to customers. If you proceed with enabling emailed gift cards on your consumer Webstore without enabling this, <strong>customers will NOT receive emailed gift cards</strong> despite being able to purchase them online. <div className="shim" /><div className="shim" />Please contact Bookmanager support for assistance, or check out our <a href="https://support.bookmanager.com/tutorials/point-of-sale/using-bookmanager-gift-cards/emailed-digital-gift-cards/" target="_blank">Help Centre Tutorial</a> going over setup and enabling must-have settings.</>} />)}
                <Spin spinning={!results.livesync_enabled} indicator={<></>}>
                    <Typography.Title level={5}>Text</Typography.Title>
                    <Divider style={{ "marginTop": "5px" }} dashed />
                    <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                        <div style={{ "padding": "30px", "paddingBottom": "0px" }}>
                            <Row gutter={[30, 30]}>
                                <Col md={24} xl={7}>
                                    <Typography.Title level={5}>Label</Typography.Title>
                                    <Typography.Paragraph type="secondary">Eg. Emailed Gift Card, E-Gift Card...</Typography.Paragraph>
                                    <Input placeholder="Card label..." onBlur={(e) => setSettings({ ...results, "emailed_label": e.target.value })} defaultValue={results.emailed_label} />
                                </Col>
                                <Col md={24} xl={17}>
                                    <Typography.Title level={5}>Emailed Gift Card Checkout Messaging</Typography.Title>
                                    <Typography.Paragraph type="secondary">Set the message customers will see during checkout. <br /><div className="shim" />
                                    <em style={{"color" : "#f12a46"}}>Please note that these Emailed GC's do require a PIN for online redemption. (The PIN is emailed with the GC# to the recipient)</em>
                                    </Typography.Paragraph>
                                    <Input.TextArea onBlur={(e) => setSettings({ ...results, "emailed_message": e.target.value })} rows={4} defaultValue={results.emailed_message} />
                                    <div className="shim" /><div className="shim" />
                                </Col>
                            </Row>
                            <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                        </div>
                    </Card>
                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                    <div className="shim" /><div className="shim" />
                    <div style={{ "float": "right" }}>
                        <GiftCard emailed={true} fetchSettings={fetchSettings} create data={{ code: createISBN }} />
                    </div>
                    <Typography.Title level={5}>Cards</Typography.Title>
                    <Divider style={{ "marginTop": "5px" }} dashed />
                    <div className="gcardgrid">
                        {cards.filter(item => item.emailed).map(item => {
                            return (<GiftCard emailed={true} results={results} fetchSettings={fetchSettings} data={item} />)
                        })}
                    </div>
                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                    <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                        <div style={{ "padding": "30px", "paddingBottom": "0px" }}>
                            <Typography.Title level={5}>Recipient Email Setup</Typography.Title>
                            <Divider style={{ "marginTop": "5px" }} dashed />
                            <Row gutter={[30, 30]}>
                                <Col md={24} xl={8}>
                                    <Space size={15} align="start">
                                        <Switch size="small" onChange={(e) => setSettings({ ...results, "emailed_show_store_name": e })} checked={results.emailed_show_store_name}></Switch>
                                        <div>
                                            <Typography.Title level={5}>Display store name?</Typography.Title>
                                            <Typography.Paragraph type="secondary">Display store name below email logo graphic. Usefull if you don't have your store name in the logo.</Typography.Paragraph>
                                        </div>
                                    </Space>
                                </Col>
                                <Col md={24} xl={8}>
                                    <Space size={15} align="start">
                                        <Switch size="small" onChange={(e) => setSettings({ ...results, "emailed_rounded_corners": e })} checked={results.emailed_rounded_corners}></Switch>
                                        <div>
                                            <Typography.Title level={5}>Round corners?</Typography.Title>
                                            <Typography.Paragraph type="secondary">Display the gift card image with rounded corners in emails.</Typography.Paragraph>
                                        </div>
                                    </Space>
                                </Col>
                                <Col md={24} xl={8}>
                                    <Space size={15} align="start">
                                        <Switch size="small" onChange={(e) => setSettings({ ...results, "emailed_show_balance_link": e })} checked={results.emailed_show_balance_link}></Switch>
                                        <div>
                                            <Typography.Title level={5}>Enable link to check balance online</Typography.Title>
                                            <Typography.Paragraph type="secondary">You must be using the default Gift Card menu and have placed the gift card balance checker in order to enable this option. Otherwise, customers will be brought to the home page, or to a gift card page where they cannot check the balance.</Typography.Paragraph>
                                        </div>
                                    </Space>
                                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                                </Col>
                            </Row>
                            <Divider dashed />
                            <Row gutter={[30, 30]}>
                                <Col md={24} xl={17}>
                                    <Typography.Title level={5}>Gift card disclaimer text</Typography.Title>
                                    {/* <Typography.Paragraph type="secondary">...</Typography.Paragraph> */}
                                    <Input.TextArea onBlur={(e) => setSettings({ ...results, "emailed_disclaimer": e.target.value })} rows={4} defaultValue={results.emailed_disclaimer} />
                                    <div className="shim" /><div className="shim" />
                                </Col>
                                <Col md={24} xl={7}>
                                    <Typography.Title level={5}>Email Preview</Typography.Title>
                                    {((cards.filter(item => item.emailed).length > 0) && drawEmail())}
                                    {/* <Typography.Paragraph type="secondary">...</Typography.Paragraph> */}
                                    <Button disabled={!(cards.filter(item => item.emailed).length > 0)} onClick={() => setPreview(!preview)} block type="primary">Preview Email</Button>
                                    <div className="shim" /><div className="shim" />
                                </Col>
                            </Row>
                            <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                        </div>
                    </Card>
                </Spin>
            </>
        )
    }

    return (
        <>
            <Layout className="layout">

                <LayoutHeader
                    title="Gift Cards"
                    description="Use this page to manage giftcards and giftcard settings."
                />


                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <Typography.Title level={5}>Settings</Typography.Title>
                        <Divider style={{ "marginTop": "5px" }} dashed />
                        <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                            <div style={{ "padding": "30px", "paddingBottom": "0px" }}>
                                <Row gutter={[30, 30]}>
                                    <Col md={24} xl={7}>
                                        <Space size={15} align="start">
                                            <Switch size="small" onChange={(e) => setSettings({ ...results, "purchasable": e })} checked={results.purchasable}></Switch>
                                            <div>
                                                <Typography.Title level={5}>Enable Gift Cards</Typography.Title>
                                                <Typography.Paragraph type="secondary">Enable Gift Cards to be purchased online.</Typography.Paragraph>
                                            </div>
                                        </Space>
                                    </Col>
                                    <Col md={24} xl={9}>
                                        <Space size={15} align="start">
                                            <Switch size="small" onChange={(e) => setSettings({ ...results, "check_balance": e })} checked={results.check_balance}></Switch>
                                            <div>
                                                <Typography.Title level={5}>Enable Balance Checker</Typography.Title>
                                                <Typography.Paragraph type="secondary">Enable a "Check your Gift Card balance" option on the Gift Card Purchase page. This option only works if you are using Bookmanager's gift card numbering system, and have enabled the Remote Gift Card Tracking option in your Bookmanager software.</Typography.Paragraph>
                                            </div>
                                        </Space>
                                    </Col>
                                    <Col md={24} xl={8}>
                                        <Space>
                                            <div style={{ "width": "35px" }}></div>
                                            <div>
                                                <Typography.Title level={5}>Minimum Value</Typography.Title>
                                                <Typography.Paragraph type="secondary">Set the lowest value a customer can load onto a gift card. $5.00 is the most common.</Typography.Paragraph>
                                                <InputNumber min="0.00" step="0.5" stringMode onChange={(e) => setMin(e)} value={min} onBlur={(e) => setSettings({ ...results, "minimum": parseInt(e.target.value).toFixed(2).toString() })} precision={2} style={{ "maxWidth": "100px" }} placeholder="0.00" />
                                            </div>
                                        </Space>
                                        <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <br />
                        <Tabs defaultActiveKey="physical" type="card">
                            <Tabs.TabPane tab="Physical Gift Cards" key="physical">
                                {drawPhysical()}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Emailed Gift Cards" key="emailed">
                                {drawEmailed()}
                            </Tabs.TabPane>
                        </Tabs>

                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}