import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export default function ResizeText(props){

    const [inc, setInc] = useState(0);
    const history = useHistory(); 
    

    let arr = ["Nicola Valley Institute Of Technology (Merritt)",
    "School House Teaching Supplies Inc.",
    "Bainton's Tannery Outlet (Mad Hatter Bookstore)",
    "The Well Read Raccoon Books & Curiosities",
    "Variant Edition Graphic Novels + Comics"]
   
    //let text = arr[inc];

    const {text = ""}  = props; 

    

    let fontSize = 19; 
    if(text.length > 18){
        let per = 18 / text.length;
        //fontSize = fontSize * per; 
    }

    return(<div onMouseDown={(e) => {
        if(e.button === 1 ){
            window.open("/", "_blank");
        }
    }} onClick={() => history.push("/")}  style={{"maxWidth" : "230px", "cursor" : "pointer", "lineHeight" : "18px", "textAlign" : (text.length < 8) ? "center" : "left", "fontWeight" : "600", "color" : "#fff", "fontSize" : fontSize + "px"}}>{text}</div>)
}