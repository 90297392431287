import React from "react";
import { Layout, Typography, Divider, Button, Alert } from "antd";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiDownload } from "../utils/Api";
export default function RemoteSupportPage() {


    const getVncExe = () => {
        apiDownload({}, "support/getVncExe", true);
    }

    return (
        <>
            <Layout className="layout">

                <LayoutHeader
                    title="Remote Support"
                    description="Use this page to remotely connect with support."
                    button={<Button size="small" onClick={() => getVncExe()} type="primary"><small>Download remote support aid</small></Button>}
                />

                <Layout.Content style={{"padding": "0px" }} >
                    <div style={{ "padding": "30px" }}>
                      
                        <Typography.Paragraph type="secondary">
                            Bookmanager Remote Support is an app that will allow you to give a specified Bookmanager support person permission to connect to your computer. Once connected, both you and one of our wizards can work interactively and assist you with technical support and advice. You must first contact our office by phone to ensure that someone is available to connect to your computer.
                        </Typography.Paragraph>
                        <Alert type="info" description={<>NOTE: We can only access your computer when you launch Remote Support and then double-click on the person who will be assisting you. At any time during a session you can disconnect us from your computer.</>} />
                        <br />
                        <Typography.Paragraph type="secondary">
                            Remote Support is based on UltraVNC SingleClick, which is licensed under the <a target="_blank" href="https://bookmanager.com/license/gpl-2.0.txt">GPLv2</a>. The source code is available <a target="_blank" href="https://bookmanager.com/download/SCII.zip">here</a>, and is not required to run the program.
                        </Typography.Paragraph>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )

}