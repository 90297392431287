import React from "react";
import { Typography, Space } from "antd";
import PubSub from "../PubSub";

export default function AccountCard(props) {

    const { store_name = "", contact = { first_name: "", last_name: "" } } = props;

    return (<div className="accountCard" >
        <div>
            <Space size={0}>
                <div style={{ "textAlign": "right", "paddingLeft": "3px" }}>
                    <Typography.Title style={{ "paddingLeft": "3px", "overflow": "hidden" }} level={5}>
                        <div className="shim"></div>
                        <div style={{"overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap", "maxWidth": "150px", "marginBottom" : "-9px" }}>
                            <small>
                            <a>{contact.first_name} {contact.last_name}</a>
                            </small>
                            &nbsp;</div>
                        <div style={{ "display": "block", "textOverflow": "ellipsis", "overflow": "hidden", "whiteSpace": "nowrap", "color": "#fff", "fontSize": "11px", "fontWeight": "normal", "marginTop": "-6px", "opacity": "0.7", "maxWidth": "150px" }}>
                            <small><PubSub /> {store_name}</small>
                        </div>
                    </Typography.Title>
                </div>
            </Space>
        </div>
    </div>)
}