/*
    ---- Api ----
    Provides structure to all the rest requests
*/
import Cookies from 'js-cookie';

// Default path used if there is no cookie and no ENV variable
// npm start & npm build do not set ENV variables
const defaultApi = "https://ericapi.bookmanager.com/";

const mode = process.env.REACT_APP_API || 'inherit';
const live = Cookies.get("l") || "false";


const getApiPath = () => {
    // Get ENV variable from start and build scripts and set the path. 
    
    let _path = "";
    switch (mode) {
        case "dev":
            _path = "https://devapi.bookmanager.com/"
            break;
        case "staging":
            _path = "https://stagingapi.bookmanager.com/"
            break;
        case "live":
            _path = "https://api.bookmanager.com/"
            break;
        default:
            _path = defaultApi;
            break;
    }
    return _path; 
}

const apiPath = (Cookies.get('api')) ? Cookies.get('api') : getApiPath();


export const apiGetPath = (_obj, _api) => {

    let path =  apiPath + "store/" + _api; 
    let _session_id = (Cookies.get("session_store_id")) ? Cookies.get("session_store_id") : "";
    let params = "&store_id=" + window.store_id + "&session_id=" + _session_id;
    Object.keys(_obj).forEach(element => {
        params += "&" + element + "=" + _obj[element];
    });
    path = path + "?" + params.substring(1, params.length);

    return path; 
}


export const apiDownload = (_obj, _api, _blank = true) => {

    let path = "/download/store/" + _api;

    let params = "&store_id=" + window.store_id;
    Object.keys(_obj).forEach(element => {
        params += "&" + element + "=" + _obj[element];
    });

    path = path + "?" + params.substring(1, params.length);

    console.log(path);

    if (_blank) {
        window.open(path, "_blank");
    } else {
        window.open(path);
    }

}



export const apiCall = (_methodName, _args, _callback, api = "store", use_session = true, session_id = false, store_id = false) => {

    // put all the data into a form to post 
    var form_data = new FormData();

    // TEST MULTISTORE 0

    if (store_id) {
        form_data.append("store_id", store_id);
    } else {
        if (window.store_id) {
            form_data.append("store_id", window.store_id);
        }
    }

    if (window.wamp_session_id) {
        form_data.append("wamp_session_id", window.wamp_session_id);
    }

    if (session_id) {
        form_data.append("session_id", session_id);
    } else {

        if (use_session) {
            form_data.append("session_id", (Cookies.get("session_store_id")) ? Cookies.get("session_store_id") : "");
        } else {

            if (window.session_store_id) {
                form_data.append("session_id", window.session_store_id);
            } else {
                form_data.append("session_id", "");
            }

        }

    }

    form_data.append("log_url", window.location.pathname);
    form_data.append("cts", Date.now().toString());

    // MOSAIC
    //  form_data.append("store_id", 11);
    //  if (use_session) {
    //      form_data.append("session_id", "as_DE6pPYy_CUdSHETySoVORzJ7e");
    //  } else {
    //      form_data.append("session_id", "");
    //  }


    if (_args) {
        for (var key in _args) {
            form_data.append(key, _args[key]);
        }
    }


    let _params = {
        method: 'POST',
        body: form_data
    }

    if(mode !== "live" && mode !=="staging" && live === "true"){
        _params.headers = {"x-tbm-data": "l"} 
    }

    fetch((apiPath + api + "/" + _methodName), _params)
        .then(response => response.json())
        .then((r) => {
            // request is formatted, but there is an error
            if (r.error) {
                // is error
                if (r.error === "reload") {
                    window.location.reload();
                } else {
                    _callback(false, r)
                }

            } else {
                // is success
                _callback("success", r)
            }

        }).catch((e) => {
            _callback(false, e)
            // is error
        });

}


