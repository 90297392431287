import { useEffect, useState } from "react";
import { Slider } from "antd";
import { addCommas } from "../../utils/Utils";

export default function MultiPointSlider(props) {


  const { steps = [], onChange = () => { }, max = 100, unit = "price" } = props;
  const [sliderValue, setSliderValue] = useState(steps); // Initial values for the slider


  useEffect(() => {
    setSliderValue(steps);
  }, [steps])


  // Throttled change handler
  const handleChange = (value) => {
    setSliderValue(value);
    onChange(value);
  }



  return (
    <div style={{ width: '700px', margin: 'auto' }}>
      <Slider
        range
        min={0}
        max={max}
        className="custom-slider"
        step={1}
        onAfterChange={handleChange}
        onChange={(v) => setSliderValue(v)}
        value={sliderValue}
      // value={sliderValue}
      // onChange={handleChange}
      // marks={{
      //   0: <small>0</small>,
      //   [max]: (unit === "price") ? "$" + addCommas(max) : addCommas(max)
      // }}
      />
    </div>
  );
};