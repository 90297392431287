import { Button, DatePicker, Divider, Form, InputNumber, Radio, Select, Space, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { numSuffix, pluralize, rruleToObj } from "../../utils/Utils";
import { RRule } from 'rrule';
export default function CustomRecurrence(props) {


    const { customFormat = () => { }, onChange = () => { }, setVisible = () => { }, date = moment(), rule = "", isLastWeekOfMonth = () => {} } = props;

    const rruleObj = rruleToObj(rule)

    const [period, setPeriod] = useState((rruleObj.freq) ? rruleObj.freq : "WEEKLY");
    const [ending, setEnding] = useState((rruleObj.count || rruleObj.until) ? (rruleObj.count) ? "occurances" : "date" : "never");
    const [endDate, setEndDate] = useState(moment((rruleObj.until) ? rruleObj.until : date));
    const [occurances, setOccurances] = useState((rruleObj.count) ? rruleObj.count : 1);
    const [repeat, setRepeat] = useState((rruleObj.interval) ? rruleObj.interval : 1);
    const [daydate, setDayDate] = useState((rruleObj.bymonthday) ? "date" : "day");


   
    let _m = date.format("dddd");
    let _weekdays = [
        { label: "Sunday", value: (_m === "Sunday") },
        { label: "Monday", value: (_m === "Monday") },
        { label: "Tuesday", value: (_m === "Tuesday") },
        { label: "Wednesday", value: (_m === "Wednesday") },
        { label: "Thursday", value: (_m === "Thursday") },
        { label: "Friday", value: (_m === "Friday") },
        { label: "Saturday", value: (_m === "Saturday") },
    ];

    if(rruleObj.freq === "WEEKLY" && rruleObj.byday){
        let _arr = rruleObj.byday.split(",");
            _weekdays.forEach((item, index) => {
                if(_arr.includes(_weekdays[index].label.substr(0, 2).toUpperCase())){
                    _weekdays[index].value = true; 
                }
            })
    }


    const [weekdays, setWeekdays] = useState(_weekdays);


    const ToggleButton = (props) => {
        const { index = 0 } = props;
        return (<Button onClick={() => {
            let _wd = [...weekdays];
            let _count = _wd.filter(item => item.value);
            // don't change if there are fewer than 1
            if (!(_count.length === 1 && _wd[index].value === true)) {
                _wd[index].value = !_wd[index].value;
                setWeekdays(_wd);
            }
        }} type={(weekdays[index].value) ? "primary" : "default"} shape="circle" size="small"><div style={{ "marginTop": "-2px" }}><small>{props.children}</small></div></Button>)
    }

    const close = () => {
        setVisible(false);
    }

    const getByDay = () => {
        let _str = "BYDAY="
        let ret = weekdays.filter(item => item.value).map(item => {
            return item.label.substring(0, 2).toUpperCase();
        })

        return _str + ret.join(",");
    }

    const save = () => {
        // Calculate RRule

        let _freq = "";
        let _byday = "";
        let _until = "";
        let _count = "";
        let _interval = `INTERVAL=${repeat}`;

        let _weeknum = Math.ceil(date.date() / 7);
        let _daylabel = date.format("dd").toUpperCase();
        let _daynum = date.date();
        let _month = date.month()
        switch (period) {
            case "DAILY":
                _freq = "FREQ=DAILY";
                break;
            case "WEEKLY":
                _freq = "FREQ=WEEKLY";
                _byday = getByDay()
                break;
            case "MONTHLY":
                _freq = "FREQ=MONTHLY";
                if (daydate === "day") {
                    _byday = `BYDAY=${_weeknum}${_daylabel}`
                    
                } else if(daydate === "date") {
                    _byday = `BYMONTHDAY=${_daynum}`
                } else {
                    _byday = `BYDAY=-1${_daylabel}`
                }
                break;
            case "YEARLY":
                _freq = "FREQ=YEARLY";
                _byday = `BYMONTH=${_month};BYMONTHDAY=${_daynum}`
                break;
        }

        switch (ending) {
            case "never":
                break;
            case "date":
                _until = "UNTIL=" + endDate.utc().format('YYYYMMDDTHHmmss');
                break;
            case "occurances":
                _count = "COUNT=" + occurances;
                break;
        }


        let arr = [_freq, _interval, _byday, _until, _count];

        let _rule = "";
        arr.forEach(item => {
            if (item) {
                _rule += item + ";";
            }
        });

        onChange(_rule);
        setVisible(false)
    }



    const drawMonthPicker = () => {


        let _daynum = date.date();
        let _weeknum = Math.ceil(date.date() / 7);
        let _today_daylabel = date.format("ddd");

        return (
            <>
                <div className="shim" />
                <Select value={daydate} onChange={(e) => setDayDate(e)} style={{ width: 247 }}>
                    <Select.Option value="date">Monthly on the {numSuffix(_daynum)} </Select.Option>
                    <Select.Option value="day">Monthly on the {numSuffix(_weeknum)} {_today_daylabel}</Select.Option>
                    {(isLastWeekOfMonth(date) && 
                    <Select.Option value="last">Monthly on the last {_today_daylabel}</Select.Option>
                    )}
                </Select>
                <div className="shim" /><div className="shim" /><div className="shim" />
            </>
        )

    }

    return (
        <div>
            <Form layout="vertical">

                <Typography.Title level={5}>Custom repeat</Typography.Title>
                {/* <em>{rule}</em> */}
                <Divider dashed style={{ "margin": "15px 0px" }} />

                <Space>
                    <Form.Item initialValue={1} label="Repeat every">
                        <InputNumber value={repeat} onChange={(e) => setRepeat(e)} style={{ "width": "90px" }} min={1} max={30} />
                    </Form.Item>
                    <Form.Item initialValue={period} label={<>&nbsp;</>} name="period">
                        <Select onChange={(e) => setPeriod(e)} style={{ width: 150 }}>
                            <Select.Option value="DAILY">{pluralize(repeat, "Day", "s")}</Select.Option>
                            <Select.Option value="WEEKLY">{pluralize(repeat, "Week", "s")}</Select.Option>
                            <Select.Option value="MONTHLY">{pluralize(repeat, "Month", "s")}</Select.Option>
                            <Select.Option value="YEARLY">{pluralize(repeat, "Year", "s")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Space>


                {(period === "WEEKLY" &&
                    <>
                        <div className="shim" />
                        <Space size={13}>
                            <ToggleButton index={0}><small>S</small></ToggleButton>
                            <ToggleButton index={1}><small>M</small></ToggleButton>
                            <ToggleButton index={2}><small>T</small></ToggleButton>
                            <ToggleButton index={3}><small>W</small></ToggleButton>
                            <ToggleButton index={4}><small>T</small></ToggleButton>
                            <ToggleButton index={5}><small>F</small></ToggleButton>
                            <ToggleButton index={6}><small>S</small></ToggleButton>
                        </Space>
                        <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                    </>
                )}

                {(period === "MONTHLY" && <>{drawMonthPicker()}</>)}

                <Form.Item initialValue={ending} label="Ending" name="ending">
                    <Radio.Group onChange={(e) => setEnding(e.target.value)} size="small" defaultValue="a" buttonStyle="solid">
                        <Radio.Button value="never"><small>&nbsp;&nbsp;&nbsp;Never&nbsp;&nbsp;&nbsp;</small></Radio.Button>
                        <Radio.Button value="date"><small>&nbsp;&nbsp;&nbsp;On Date&nbsp;&nbsp;&nbsp;</small></Radio.Button>
                        <Radio.Button value="occurances"><small>&nbsp;&nbsp;&nbsp;# Occurances&nbsp;&nbsp;&nbsp;</small></Radio.Button>
                    </Radio.Group>
                </Form.Item>

                {(ending === "date" &&
                    <DatePicker value={endDate} onChange={(e) => setEndDate(e)} style={{ "width": "250px" }} inputReadOnly allowClear={false} format={customFormat} />
                )}

                {(ending === "occurances" &&
                    <Space><InputNumber value={occurances} onChange={(e) => setOccurances(e)} style={{ "width": "90px" }} min={1} max={30} /> {pluralize(occurances, "Occurance", "s")}</Space>
                )}


            </Form>
            <Divider style={{ "margin": "15px 0px" }} dashed />
            <div className="float-flex">
                <div>&nbsp;</div>
                <Space>
                    <Button onClick={() => close()}>Cancel</Button>
                    <Button type="primary" onClick={() => save()}>Save</Button>
                </Space>
            </div>
        </div>
    )
}