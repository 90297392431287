import { Space } from "antd";
import React from "react";

export default function TitleRank(props) {

    const { rank = {}, number_only = false, highlight = false } = props;

    let color = "";
    let place = 0;
    let r = 0;
    let is_future = rank.hasOwnProperty('future') ? true : false; 

    if (is_future) {
        color = "#2c7eb5";
        place = rank.future;
        if (rank.future <= 100) { 
            r = 1 
        } else if (rank.future <= 1000) { 
            r = 2 
        } else if (rank.future <= 3000) { 
            r = 3 
        } else if (rank.future <= 10000) { 
            r = 4; 
        }
    } else {
        color = "#2cb58d";
        place = rank.bestseller;
        if (rank.bestseller <= 1000) { 
            r = 1; 
            color = "#b52c2c"; 
        } else if (rank.bestseller <= 10000) { 
            r = 2; 
            color = "#d26a16" 
        } else if (rank.bestseller <= 30000) { 
            r = 3; 
            color = "#c1aa2d" 
        } else if (rank.bestseller <= 100000) { 
            r = 4; 
            color = "#73b52c"; 
        } else {
            r = 5;
        }
    }

    const lit = {
        height: "10px",
        width: "3px",
        backgroundColor: color,
    }

    const unlit = {
        height: "10px",
        width: "3px",
        backgroundColor: "#eee",
    }

    const drawIncs = (_r) =>{
        let ret = [5,4,3,2,1];
        return ret.map((item, index) =>{
            let _lit = ((item) >=_r);
            return <div key={index} style={(_lit) ? lit : unlit}></div>
        })
    }

    if(!place){
        return "";
    }

    if(number_only){
        if(highlight){
            color = "inherit";
        }
        return(<span style={{ "color": color }}>{place}</span>)
    }

    return (
        <div style={{ "marginTop": "-3px" }}>
            <Space align="center" style={{ "fontSize": "14px" }} size={2}>
                {drawIncs(r)}
                <div style={{ "marginTop": "-3px" }}>&nbsp;<span style={{ "color": color }}>#{place} {(is_future) ? "in demand" : "in bestsellers"}</span></div>
            </Space>
        </div>
    )
}