import React from "react";
import { Typography, Divider, Form, Input, Space, Button, message } from "antd";
import { apiCall } from "../../utils/Api";
export default function ListsCopy(props) {

    const { results = {}, setResults=() => { }, list_id = "", name = "", close = () => {} } = props;

    const onFinishForm = (_f) => {

        _f["list_id"] = list_id;

        apiCall("titlelist/copy", _f, (_status, _result) => {
            if (_status) {
                let res = {...results};
                let ind = res.rows.findIndex(item => item.list_id === list_id);
                res.rows.splice((ind+1), 0, _result)
                setResults(res);
                close(); 
            } else {
                message.error(_result.error);
                close(); 
            }
        })
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title style={{ "marginRight": "20px" }} level={5}>Copy {name}</Typography.Title>
                <Divider />
                <Form onFinish={(_f) => onFinishForm(_f)} layout="vertical">
                    <Form.Item name="new_name" rules={[{ required: true }]} initialValue={"(Copy) " + name} label="New name for list" >
                        <Input  />
                    </Form.Item>
                

                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={(e) => close()}>Cancel</Button>
                        <Button type="primary" htmlType="submit">Copy List</Button>
                    </Space>
                </div>
                <br clear="all" />
                </Form>

            </div>
        </>
    )
}