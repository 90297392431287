import React from "react";
import { Layout, Typography, Divider, Space, Button, Table } from "antd";
import LayoutHeader from "../../layouts/partials/LayoutHeader";

export default function CalandarReportsPage() {

    const summary_columns = [
        { title: <small>Totals</small>, dataIndex: 'totals', key: 'totals' },
        { title: <small>sld_isbns</small>, dataIndex: 'sld_isbns', key: 'sld_isbns' },
        { title: <small>sld_units</small>, dataIndex: 'sld_units', key: 'sld_units' },
        { title: <small>sld_retail</small>, dataIndex: 'sld_retail', key: 'sld_retail' },
        { title: <small>sld_pre_xmas</small>, dataIndex: 'sld_pre_xmas', key: 'sld_pre_xmas' },
        { title: <small>sld_jan_feb</small>, dataIndex: 'sld_jan_feb', key: 'sld_jan_feb' },
        { title: <small>cart_isbns</small>, dataIndex: 'cart_isbns', key: 'cart_isbns' },
        { title: <small>oo_isbns</small>, dataIndex: 'oo_isbns', key: 'oo_isbns' },
        { title: <small>ttloo_units</small>, dataIndex: 'ttloo_units', key: 'ttloo_units' },
        {
            title: <small>ttloo_retail</small>, dataIndex: 'ttloo_retail', key: 'ttloo_retail', render: (e) => {
                return (e) ? "$" + e.toFixed(2) : "";
            }
        },
        { title: <small>avail_titles</small>, dataIndex: 'avail_titles', key: 'avail_titles' }
    ]

    const columns = [
        {
            title: <small>prefix</small>, dataIndex: 'prefix', key: 'prefix', render: (e) => {
                return <a>{e}</a>;
            }
        },
        { title: <small>publisher</small>, dataIndex: 'publisher', key: 'publisher' },
        { title: <small>sld_isbns</small>, dataIndex: 'sld_isbns', key: 'sld_isbns' },
        { title: <small>sld_units</small>, dataIndex: 'sld_units', key: 'sld_units' },
        { title: <small>sld_retail</small>, dataIndex: 'sld_retail', key: 'sld_retail' },
        { title: <small>sld_pre_xmas</small>, dataIndex: 'sld_pre_xmas', key: 'sld_pre_xmas' },
        { title: <small>sld_jan_feb</small>, dataIndex: 'sld_jan_feb', key: 'sld_jan_feb' },
        { title: <small>cart_isbns</small>, dataIndex: 'cart_isbns', key: 'cart_isbns' },
        { title: <small>oo_isbns</small>, dataIndex: 'oo_isbns', key: 'oo_isbns' },
        { title: <small>ttloo_units</small>, dataIndex: 'ttloo_units', key: 'ttloo_units' },
        {
            title: <small>ttloo_retail</small>, dataIndex: 'ttloo_retail', key: 'ttloo_retail', render: (e) => {
                return (e) ? "$" + e.toFixed(2) : "";
            }
        },
        { title: <small>avail_titles</small>, dataIndex: 'avail_titles', key: 'avail_titles' }
    ]



    const summary_data = [

        {
            "totals": "557 rows",
            "sld_isbns": "",
            "sld_units": "",
            "sld_retail": "",
            "sld_pre_xmas": "",
            "sld_jan_feb": "",
            "cart_isbns": 1,
            "oo_isbns": 1,
            "ttloo_units": 4,
            "ttloo_retail": 59.96,
            "avail_titles": 53935
        }
    ];

    const columns_data = [

        {
            "prefix": "97814267",
            "publisher": "Abingdon Press",
            "sld_isbns": "",
            "sld_units": "",
            "sld_retail": "",
            "sld_pre_xmas": "",
            "sld_jan_feb": "",
            "cart_isbns": "",
            "oo_isbns": "",
            "ttloo_units": "",
            "ttloo_retail": "",
            "avail_titles": 10
        },


        {
            "prefix": "97815256",
            "publisher": "Wyman Publishing",
            "sld_isbns": "",
            "sld_units": "",
            "sld_retail": "",
            "sld_pre_xmas": "",
            "sld_jan_feb": "",
            "cart_isbns": "",
            "oo_isbns": 1,
            "ttloo_units": 4,
            "ttloo_retail": 59.96,
            "avail_titles": 616
        }
    ];


    return (
        <>
            <Layout className="layout">

                <LayoutHeader
                    todo
                    title="Calandar Reports"
                    description="Use this page to view calandar reports."
                    button={<Button size="small" type="primary"><small> Generate PDF</small></Button>}
                />

                <Layout.Content style={{"padding": "0px" }} >
                    <div style={{ "padding": "30px" }}>
                      
                        <Typography.Title level={5}>Summary:</Typography.Title>
                        <Table size="small" bordered pagination={false}
                            columns={summary_columns}
                            dataSource={summary_data}
                        />
                        <br />
                        <Typography.Title level={5}>Activity:</Typography.Title>
                        <Table expandable expandIcon={<></>} size="small" bordered pagination={false}
                            columns={columns}
                            dataSource={columns_data}
                        />

                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}