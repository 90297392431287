import React, { useEffect, useState } from "react";
import { Layout, Typography, Divider, Table, Button, Space, DatePicker, Menu, Dropdown, Tabs, Col, Statistic, Card } from "antd";
import { apiCall } from "../../utils/Api";
import Html from "../../utils/Html";
import { Link } from "react-router-dom";
import moment from "moment";
import { CloseOutlined } from '@ant-design/icons';
import CustomersTable from "./CustomersTable";
import TitlesTable from "./TitlesTable";
import OrdersTable from "./OrdersTable";
import { pluralize, addCommas } from "../../utils/Utils";
import conditional from "../../utils/conditional";
import Paginate from "../Paginate";
export default function CustomerPOTable(props) {

    const { filters, border = true } = props;
    const [loading, setLoading] = useState(false)
    const [table, setTable] = useState("table");
    const [expanded, setExpanded] = useState([]);
    const [results, setResults] = useState({ rows: [], row_count: 0 });
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 20, offset: 0 });

    const expandCell = (_data, _table) => {
        if (!expanded.includes(_data.key)) {
            setTable(_table);
            setExpanded([_data.key]);
        } else {
            if (_table === table) {
                setExpanded([]);
            } else {
                setTable(_table);
            }
        }
    }

    // Fetch Data if not set
    const getSummary = () => {
        setLoading(true);
        let _filters = { ...filters }
        _filters.limit = paginate.pagesize;
        _filters.offset = paginate.offset;
        apiCall("orders/getPurchase", _filters, (_status, _results) => {
            if (_status) {
                addKeys(_results.rows);
                setResults(_results)
                setLoading(false);
            }
        })
    }
    useEffect(getSummary, [filters, paginate]);

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const customer_pos_columns = [
        { key : "blank", width : "10px"},
        {
            title: <small>Ordered</small>, dataIndex: 'date_ordered', key: 'date_ordered', render: (e) => {
                return moment(e * 1000).format("MMM Do YYYY - h:mma");
            },
        },
        { title: <small>Cust PO#</small>, dataIndex: 'customer_purchase_order', key: 'customer_purchase_order' },

        { title: <small>Quantity</small>, dataIndex: 'quantity', key: 'quantity' },
        {
            title: <small>Price</small>, dataIndex: 'price', key: 'price', render: (e) => {
                return (e) ? "$" + addCommas(e) : "";
            }
        },
        {
            title: <small>Titles</small>, dataIndex: 'number_of_titles', key: 'number_of_titles', render: (e, f) => {
                return <a onClick={() => expandCell(f, "titles")} type="link">{e} {pluralize(e, "Title", "s")}</a>
            }
        },
        {
            title: <small>Orders</small>, dataIndex: 'number_of_orders', key: 'number_of_orders', render: (e, f) => {
                return <a onClick={() => expandCell(f, "orders")} type="link">{e}</a>
            }
        },
        {
            title: <small>Customers</small>, dataIndex: 'number_of_customers', key: 'number_of_customers', render: (e, f) => {
                return <Button size="small" onClick={() => expandCell(f, "customers")} type="link">{e}</Button>
            }
        }
    ]



    const getTable = (_record) => {


        switch (table) {
            case "titles":
                let title_fltrs = { ...filters }
                title_fltrs["purchase_order"] = _record.customer_purchase_order;
                title_fltrs["nesting"] = "purchase_order";
                return <TitlesTable pagination={false} border={false} data={results} filters={title_fltrs} />
                break;

            case "orders":
                let order_fltrs = { ...filters }
                order_fltrs["purchase_order"] = _record.customer_purchase_order;
                order_fltrs["nesting"] = "purchase_order";
                return <OrdersTable pagination={false} border={false} data={results} filters={order_fltrs} />
                break;

            case "customers":
                let purchase_fltrs = { ...filters }
                purchase_fltrs["purchase_order"] = _record.customer_purchase_order;
                purchase_fltrs["nesting"] = "purchase_order";
                return <CustomersTable pagination={false} border={false} filters={purchase_fltrs} />
                break;
        }

    }

    const drawChildTable = (_record, _another) => {


        return (
            <div className="hideHover" style={{ "margin": "-7px" }}>
                <div style={{ "padding": "0 20px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none", "marginBottom": "0px" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                </div>
                <div style={{ "padding": "0px 0px" }}>
                    {getTable(_record)}
                </div>
            </div>
        )





    }




    return (
        <>

            <Paginate paginate={paginate} setPaginate={setPaginate} count={results.row_count} />
            <Table
                expandable expandIcon={<></>} size="small"
                columns={customer_pos_columns}
                loading={loading}
                className={"tableNoBorder"}
                dataSource={results.rows}
                pagination={false}
                expandIconColumnIndex={-1}
                expandedRowKeys={expanded}
                onExpand={(e) => { return false; }}
                expandedRowRender={(e) => { return drawChildTable(e) }}
            />
            <Paginate paginate={paginate} setPaginate={setPaginate} count={results.row_count} />

        </>);


}