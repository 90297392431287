import { Button, Modal, message } from "antd";
import React from "react";
import { apiCall } from "../../utils/Api";

export default function UpgradeEvents(props) {


    const { upgrade = true, show_revert = false, show_upgrade = true } = props;
    // /store/events/v2/convertFromV1   
    // /store/events/v2/backToV1

    const convertFromV1 = () => {
        Modal.confirm({
            icon: <></>,
            title: "Upgrade to the new events system",
            content: <>Upgrading to the new events system will bring several new features: recurring events, event summaries in list views, event categories, and event RSVPs. <div className="shim"/><div className="shim"/>All current features will also be retained.</>,
            okText: "Upgrade",
            onOk: () => {
                apiCall("events/v2/convertFromV1", {}, (_status, _result) => {
                    if (_status) {
                        window.location.reload();
                    } else {
                        message.error(_result.error);
                    }
                })
            }
        })
    }


    const backToV1 = () => {

        Modal.confirm({
            icon: <></>,
            title: "Revert to legacy events system",
            content: <>Revert to the previous version of the events system. <br /><span style={{"color" : "#f00"}}>Events created with the new system will be deleted.</span></>,
            okText: "Revert",
            onOk: () => {
                apiCall("events/v2/backToV1", {}, (_status, _result) => {
                    if (_status) {
                        window.location.reload();
                    } else {
                        message.error(_result.error)
                    }
                })
            }
        })

       
    }

    if(!window.sitesettings.is_wd_events){
        return(<></>)
    }

    if (upgrade) {
        if(show_upgrade){
            return (<Button onClick={() => convertFromV1()} type="primary" size="small"><small>Upgrade Events</small></Button>)
        } else {
            return(<></>);
        }
       
    } else {

        if(show_revert){
            return (<Button className="mini-btn" onClick={() => backToV1()} type="default" size="small"><small>Revert Events</small></Button>)
        } else {
            return (<></>)
        }
        
    }



}