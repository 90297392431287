
import React from "react";
import { Card, Divider, Button, Form, Typography, Row, Col, Input, Switch, Space, message } from "antd";
import Overlay from "../../components/Overlay";
import ImageSelect from "../../utils/ImageSelect";
import { apiCall } from "../../utils/Api";
import CoverImage from "../../components/title/CoverImage";
import { cacheBuster } from "../../utils/Utils";
import { useState } from "react";

export default function GiftCard(props) {

    const { create = false, data = {}, fetchSettings = () => { }, emailed = false, disabled = false } = props;
    const { code = "", encrypted_code = "", active = false, description = "" } = props.data;
    const [cache, setCache] = useState(cacheBuster());


    const GiftCardEdit = (props) => {


        const [cache, setCache] = useState(cacheBuster());
        const { fetchSettings = () => { }, close = () => { } } = props;
        const { code = "", encrypted_code = "", active = false, description = "" } = props.data;
        const [form] = Form.useForm();


        const saveCard = (_f) => {

            let obj = {
                description: _f.description,
                active: _f.active
            }

            if (emailed) {
                obj.emailed = true;
            } else {
                obj.emailed = false;
            }



            obj.isbn = _f.code;

            // if(_f.code){
            //     obj.isbn = _f.code; 
            // }

            apiCall("giftcard/setGiftcard", obj, (_status, _result) => {
                if (_status) {
                    message.success("Giftcard updated successfully.");
                    fetchSettings();
                    close();
                } else {
                    message.error(_result.error)
                }
            })

        }




        return (
            <>

                <div style={{ "padding": "20px" }}>
                    <Typography.Title level={5}>{(create) ? "Create new gift card" : "Edit gift card"}</Typography.Title>
                    <Divider />
                    <Form form={form} onFinish={(_f) => saveCard(_f)} layout="vertical">



                        <Row gutter={20}>
                            <Col span={15}>

                                <Form.Item initialValue={description} name="description" label="Gift card title">
                                    <Input maxLength={30} showCount placeholder="Purple Gift Card, Emailed Card..." />
                                </Form.Item>


                            </Col>
                            <Col span={9}>
                                <Form.Item initialValue={code} label={<>Gift card ISBN <small style={{"opacity" : "0.6"}}>&nbsp; <em>(Auto generated)</em></small></>} name="code">
                                    <Input style={{ "fontFamily": "monospace" }} disabled placeholder="Will be automatically created." />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row gutter={20}>
                            <Col span={15}>
                                <label>Card Image</label>
                                <div className="shim" />
                                <div style={{ "max-width": "350px" }}>
                                    <CoverImage edit={{can_upload_image : true}} cover_path="/no-cover-wide.jpg" cache={cache} editing={true} eisbn={encrypted_code} isbn={code} />
                                </div>
                            </Col>
                            <Col span={9}>
                                <Form.Item initialValue={active} valuePropName="checked" name="active" label="Enable for purchasing?">
                                    <Switch size="small" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="shim" /><div className="shim" />

                        <div>We recommend uploading a PNG or JPG image that is 1200px in its largest dimension. <div className="shim" /><div className="shim" /><strong>The standard gift card aspect ratio is 1200px wide x 756px tall.</strong> <div className="shim" /><div className="shim" />The minimum size required is 400px wide. Do not include corner rounding in the design. Changing the card image will take a few minutes to process and refresh. You may need to refresh your browser to see the changes.</div>
                        <Divider />
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => close()}>Cancel</Button>
                                <Button htmlType="submit" type="primary">Save</Button>
                            </Space>
                        </div>
                        <br clear="all" />
                    </Form>
                </div>

            </>
        )
    }

    if (create) {
        return (<Overlay width={600} component={<GiftCardEdit fetchSettings={fetchSettings} data={data} />}><Button disabled={disabled} size="small" type="primary"><small>Add new card</small></Button></Overlay>)
    }

    return (
        <>

            <Card cover={<div className="bc" style={{ "width": "365px", "height": "230px", "backgroundRepeat": "no-repeat", "backgroundPosition": "center", "backgroundColor": "rgba(0,0,0,0.2)", "backgroundSize": "contain", "border": "1px solid", "backgroundImage": "url('https://bookmanager.com/i/m.php?b=" + encrypted_code + "&cb=" + cache + "')" }}></div>}>
                <Card.Meta title={<div className="c">{code} <div><small style={{ "color": (active) ? "#389e0d" : "#888" }}><em>{(active) ? "Giftcard is currently active" : "Giftcard is currently inactive"}</em></small></div></div>} description={<>
                    <div className="c">{description}</div>
                    <Divider style={{ "margin": "15px 0px" }} dashed />
                    <div className="shim" />
                    <div style={{ "float": "right" }}>
                        <Overlay width={600} component={<GiftCardEdit fetchSettings={fetchSettings} data={data} />}><Button size="small" type="primary"><small>Edit Card</small></Button></Overlay>
                    </div>
                </>} />
            </Card>
        </>)
}