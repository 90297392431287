import { Badge, Button, Modal, Tooltip, Space, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useSession } from "./utils/Session";
import { apiCall } from "./utils/Api";
import { SelectOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";

export default function PubSub(props) {



    // Publishes can be thought about as trying to communicate to different sets of subscribers.
	// The sets we use are:
	// 	a specific browser (only things that share the same session cookie)
	// 		use \context()->getSession()->getChannelId()
	// 		this is for syncing session level info, like changing corp/logging out
	// 	a specific user (channel specific to a contact row, uses contact)
	// 		use \context()->getContact()->getChannelId()
	// 		this is for syncing account level info, like filter preset default
	// 		works across multiple devices
	// 	a specific store (channel specific to a contact row, uses corp id)
	// 		use \context()->getCorp()->getId()
	// 		this is for syncing store level info, like store cart
	// 		works across multiple devices
	// NOTE: it is possible  to do the intersect of the sets by using each bit of info in the uri
	// Uris can be of two different types:
	// 	com.[store_id].*
	// 	com.bookmanager.*
	// The main difference is security. To use a com.[store_id].*, authentication has to happen
	// to prove you use it. Everyone can use com.bookmanager.* so sensitive info should not be
	// sent through this.

    
    const mode = process.env.REACT_APP_API || 'inherit';
    const live_cookie = Cookies.get("l") || "false";

    const is_live = ((live_cookie==="true") || (mode === "live")) ? true : false;
        
   

    const [live, setLive] = useState(false);
    const [session, setSession] = useSession();


    // session context isn't updating inside the pubsub event methods - using a window var
    useEffect(() => {
        window.pubsub_session = session;
    }, [session])

    const autobahn = window.autobahn;
    const principal = session.store_id;
    const ticket = session.session_id;
    const channel_id = session.contact.channel_id;
    const [ping, setPing] = useState(100);


    var msgpser = new autobahn.serializer.MsgpackSerializer();
    var jsonser = new autobahn.serializer.JSONSerializer();

    const toURI = (_string) => {
        let no_whitespace = _string.replace(/\s+/g, '-');
        let sanitized = no_whitespace.replace(/[^A-Za-z0-9-]+/g, '', no_whitespace);
        return sanitized;
    }



    const openModal = (_obj) => {
        // Default structure from V1 - all keys except data are expected
        const {
            action = "",
            ee_def_id = "",
            name = "",
            from_pubsubid = "",
            data = {},
        } = _obj;

        let msg = {
            from : name,
            title : "Message from BM Station " + from_pubsubid,
            request : "You have been asked to view the list:",
            footer : "Regardless of your choice, the list has been saved in My Lists.",
            button : "Open in Picklist view",
            link : "/picklist/" + action + "/" + ee_def_id
        }

        
        switch(action){

            case "view_list":
                msg.link = "/purchasing/browse/filter/v/sequence/x/"+ee_def_id;
                msg.button = "Open in Browse";
            break;

            case "view_route":
                msg.from = data.from || "";
                msg.title = "Message from " + msg.from;
                msg.request = "Another user would like you to view a Biz page, list, or title:";
                msg.link = data.route || "";
                msg.button = "Open in a new tab";
                msg.footer = "";
            break;

            case "view_picklist": break;
            default: break;
                
        }

        
        Modal.confirm({
            title: <div style={{"textAlign" : "center"}}>{msg.title}</div>,
            icon: null,
            cancelText : "Close",
            content: <>
                <p style={{ "fontSize": "14px", "textAlign" : "center" }}>{msg.request} <br />
                    <div className="shim" />
                    <div><strong className="c">{name}</strong></div>    
                </p>
                <div style={{"textAlign" : "center"}}>
                    <Space>
                        <Button onClick={() =>  window.open(msg.link)} type="primary">{msg.button} <SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "12px" }} /></Button>
                    </Space>
                </div>
                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                <div className="c" style={{"textAlign" : "center"}}><em>{msg.footer}</em></div>
                <Divider style={{"marginBottom" : "0px"}} dashed />
            </>,
           
           okButtonProps : {style : {"display" : "none"}}

        })


    }


    const onPicklist = (_args) => {
        // not an array
        if (!Array.isArray(_args)) { return; }
        // nothing inside
        if (_args.length < 1) { return; }
        
        openModal(_args[0]);
    }


    const onStation = (_args) => {
        setSession({ ...window.pubsub_session, "station_id": _args[0].station_id });
    }

    const onCart = (_args) => {
        setSession({ ...window.pubsub_session, "cart": _args });
    }

    const onQuickPick = (_args) => {
        setSession({ ...window.pubsub_session, "quick_picks": _args })
    }

    const onBookmark = (_args) => {
        setSession({ ...window.pubsub_session, "titlelist_bookmarks": _args })
    }

    const onPreset = (_args) => {
        if (Array.isArray(_args) && _args.length > 0) {
            setSession({ ...window.pubsub_session, "filter_preset_defaults": _args[0] })
        }
    }

    const onCorp = (_args) => {
        if (Array.isArray(_args) && _args.length > 0 && _args[0] === "reset") {
            apiCall("session/get", {}, (_status, _result) => {
                if (_status) {
                    setSession(_result);
                    window.store_id = _result.store_id;
                    apiCall("store/getSettings", {}, (_s, _r) => {
                        window.sitesettings = _r;
                    })
                }
            })
        }
        return;
    }

    const onContact = (_args) => {
        if (Array.isArray(_args) && _args.length > 0 && _args[0] === "reset") {
            apiCall("session/get", {}, (_status, _result) => {
                if (_status) {
                    setSession(_result);
                    window.store_id = _result.store_id;
                    apiCall("store/getSettings", {}, (_s, _r) => {
                        window.sitesettings = _r;
                    })
                }
            })
        }
        return;
    }


    const onPing = (_session = "") => {
        _session.call('com.bookmanager.ping', [Date.now()]).then((e) => {
            setPing(Date.now() - e)
        });
        setTimeout(() => onPing(_session), 10000);
    }

    const onChallenge = (_session, _method, _extra) => {
        if (_method === "ticket") { return ticket; }
        console.log("Can't authenticate using '" + _method + "'");
    }

    const connection = new autobahn.Connection({
        url: 'wss://bookmanager.com/ws/',
        realm: 'bookmanager',
        authmethods: ["ticket"],
        // authid: principal.toString(),
        authid: principal.toString() + "-" + session.picklist_identity,
        onchallenge: onChallenge,
        serializers: [msgpser, jsonser]
    });


    connection.onopen = function (_session) {

        

        window.ses = _session;
        window.wamp_session_id = _session.id;

        toURI(session.picklist_identity)

        setLive(true);
        onPing(_session);


        // USER STORE
        let picklist_path = "com." + principal.toString() + ".picklist." + toURI(session.picklist_identity);
        // USER BROWSER LEVEL (TABS) 
        let corp_path = "com.bookmanager." + session.session_channel_id + ".corp";

        // USER BROWSER LEVEL (TABS) 
        let contact_path = "com.bookmanager." + channel_id + ".corp";

        // STORE
        let cart_path = "com." + principal.toString() + ".cart";
        // USER STORE
        let quick_pick_path = "com." + principal.toString() + ".device.quick_pick." + channel_id;
    
        let station_path = "com.bookmanager." + session.session_channel_id + ".station";
        // USER STORE
        let bookmark_path = "com." + principal.toString() + ".device.titlelist_bookmark." + channel_id;
        let preset_path = "com.bookmanager." + channel_id + ".filter_preset_defaults";



        window.ses.subscribe(picklist_path, onPicklist).then((subscription) => {
            window.picklist_sub = subscription;
        });

        window.ses.subscribe(corp_path, onCorp);
        window.ses.subscribe(contact_path, onContact);
        window.ses.subscribe(bookmark_path, onBookmark);
        window.ses.subscribe(cart_path, onCart);
        window.ses.subscribe(quick_pick_path, onQuickPick);
        window.ses.subscribe(station_path, onStation);
        window.ses.subscribe(preset_path, onPreset);

    };

    connection.onclose = function (reason, details) {
        setLive(false);
        console.log("disconnected", reason, details.reason, details);
    }

    useEffect(() => {

        if(is_live){
              connection.open();
        }
      
        return () => connection.close();
    }, [session.picklist_identity]);





    return (
        <Tooltip title={(live) ? <>Syncing is on<br /><small>Last ping {ping.toString()}ms</small></> : "Cart sync is off"}>
            {/* <span onClick={() => openModal({ action: "view_list", ee_def_id: "qDS_aSepKbM", def_id: 484410, name: "Picklist Inv G54916 Clark, Mark", from_pubsubid: "24 Tobias" })}> */}
            {((live) && <Badge color={"#48ef00"} dot />)}
            {((!live) && <Badge color={"#999999"} dot />)}
            {/* </span> */}
        </Tooltip>
    );

}