import React, { useState, useEffect } from "react";
import { Layout, Typography, Divider, Table, Popconfirm, Button, Popover, Tag, Pagination, Card, message, Skeleton } from "antd";
import { CommentOutlined, DeleteOutlined, ImportOutlined, CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import Comment from "../../components/Comment";
import { apiCall } from "../../utils/Api";
import { parceAuthors } from "../../utils/Utils";
import conditional from "../../tools/conditional";
import Title from "../../components/title/Title";
import ImportComments from "../../components/ImportComments";
import { adjustColor } from "../../utils/Utils";
import Html from "../../utils/Html";
import Paginate from "../../components/Paginate";
import Renderer from "../../components/Renderer";
import ItemLayouts from "../../components/ItemLayouts";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
export default function ManageTitleComments() {

    const [results, setResults] = useState({ rows: [], total: 0 });
    const [expanded, setExpanded] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 20, offset: 0 });


    const getComments = () => {

        setLoading(true);
        apiCall("comment/getAll", { limit: paginate.pagesize, offset: paginate.offset }, (_status, _result) => {
            if (_status) {
                _result.rows = addKeys(_result.rows);
                setResults(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getComments, [paginate]);


    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const colors = {
        "personal": "#d1b3e5",
        "webstore": "#f47ddc",
        "clients": "#fa9050",
        "booksellers": "#90EE90",
        "staff": "#ffef00",
        "visitors": "#70e2ff",
        "everyone": "#def1f6",
        "unknown": "#bad4e1",
    }


    const drawIcon = (e) => {

        return (
            <>
                <Comment callback={getComments} data={e} isbn={e.isbn}>
                    <Popover overlayStyle={{ "maxWidth": "500px" }} placement="right" title={
                        <small>
                            <div style={{ "float": "right" }}>
                                &nbsp; &nbsp; &nbsp;
                                <Tag style={{ "lineHeight": "15px", "marginTop": "2px", "marginRight": "-8px" }} color={adjustColor(colors[e.shared_with], -20)}>
                                    <small>{e.shared_with}</small>
                                </Tag>
                            </div>{e.author}
                        </small>} content={<><Html html={e.text} />
                            <div className="shim"></div>
                            <small>{moment(e.date * 1000).format("MMMM Do, YYYY")}</small>
                        </>} trigger="hover">
                        <Button style={{ "background": adjustColor(colors[e.shared_with], -20), border: adjustColor(colors[e.shared_with], -60) }} icon={<CommentOutlined />} type="primary" size="small"></Button>
                    </Popover>
                </Comment>
            </>
        );
    }


    const columns = [
        {
            title: <small></small>, className: "cellBorder", dataIndex: 'comment_action', width: "40px", key: 'comment_action', render: (e, f) => {
                return drawIcon(f);
            }
        },
        {
            title: <small>Posted by</small>, dataIndex: 'author', key: 'author', render: (e) => {
                return <div style={{ "maxWidth": "120px" }} className="ellipsis">{e}</div>
            }
        },
        {
            title: <small>List</small>, dataIndex: 'list_name', key: 'list_name', render: (e) => {
                return <div style={{ "maxWidth": "100px" }} className="ellipsis">{e}</div>
            }
        },
        {
            title: <small style={{ "minWidth": "100px" }}>Modified</small>, dataIndex: 'modified', key: 'modified', render: (e) => {


                return (e) ? moment(e * 1000).format("MMM D, YYYY") : "";
            }
        },
        {
            title: <small>Expiry</small>, dataIndex: 'expiry', key: 'expiry', width: "73px", render: (e, f) => {
                if (e) {
                    return <div style={{ "maxWidth": "100px" }} className="ellipsis">{moment(e * 1000).format("MMM D, YYYY")}</div>
                } else {
                    return (f.list_name) ? "List" : "Never";
                }

            }
        },
        {
            title: <small>Title</small>, dataIndex: 'title', key: 'title', render: (e, f) => {
                return <a onClick={() => expandCell(f)}>{f.title_info.title}</a>
            }
        },
        // { title: <small>Author</small>, dataIndex: 'author', key: 'author', ellipsis: true, render: (e, f) => {
        //     return <a>{parceAuthors(f.title_info.authors)}</a>;
        // } },
        {
            title: <small>Price</small>, dataIndex: 'price', key: 'price', render: (e, f) => {
                return (f.title_info.price) ? "$" + f.title_info.price : "";
            }
        },
        {
            title: <small>Bind</small>, dataIndex: 'binding', key: 'binding', render: (e, f) => {
                return (f.title_info.binding) ? f.title_info.binding : "";
            }
        },
        {
            title: <small>Pubdate</small>, dataIndex: 'pubdate', key: 'pubdate', render: (e, f) => {
                return <div style={{ "maxWidth": "120px" }} className="ellipsis">{moment(f.title_info.release_date * 1000).format("MMM D, YYYY")}</div>
            }
        },
        {
            title: <small>Publisher</small>, className: "cellBorder", dataIndex: 'publisher', key: 'publisher', render: (e, f) => {
                return <div style={{ "maxWidth": "140px" }} className="ellipsis">{f.title_info.publisher}</div>
            }
        },
        /* tobias (15jun2023): removing as it is same behaviour as header import button - see web/issues#1910
        {
            title: <small>Import</small>, dataIndex: 'import', className: "cellBorder", width: "40px", key: 'import', render: (e, f) => {
                return drawImport(f);
            }
        },
        */
        {
            title: <small></small>, dataIndex: 'actions', width: "40px", key: 'actions', render: (e, f) => {
                return drawRemove(f.id);
            }
        }
    ]


    const deleteComment = (_id) => {

        apiCall("comment/delete", { comment_id: _id }, (_status, _results) => {
            if (_status) {
                message.success("Comment removed successfully.")
                getComments();
            }
        })
    }

    const drawImport = (_obj) => {

        return (
            <ImportComments comment_id={_obj.id} isbn={_obj.isbn} callback={getComments} >
                <Button type="link" size="small"><ImportOutlined /></Button>
            </ImportComments>

        )

    }

    const drawRemove = (_id) => {

        return (
            <Popconfirm
                title="Are you sure?"
                onConfirm={() => deleteComment(_id)}
                okText="Remove"
                okType="danger"
                cancelText="Cancel"
            >
                <Button danger type="link" size="small"><DeleteOutlined /></Button>
            </Popconfirm>
        )

    }


    const expandCell = (_data) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }

    const drawChildTable = (_record) => {

        return (
            <div className="hideHover" style={{ "margin": "-7px" }}>
                <div style={{ "padding": "0 20px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none", "marginBottom": "0px" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                </div>
                <div style={{ "padding": "20px 20px" }}>
                    <Renderer close={false}><ItemLayouts isbn={_record.isbn} display="details2" /></Renderer>
                </div>
            </div>
        )
    }



    return (
        <>

            <Layout className="layout" >

                <LayoutHeader
                    title="Manage Comments"
                    description="Use this page to import and manage comments."
                    button={<>
                        <ImportComments callback={getComments} >
                            <Button size="small" type="primary"><small><ImportOutlined /> Import</small></Button>
                        </ImportComments>
                    </>}
                />

                <Layout.Content style={{"padding": "0px" }} >

                    <Paginate paginate={paginate} setPaginate={setPaginate} count={results.total} />
                    <Table loading={loading} size="small" pagination={false}
                        columns={columns}
                        dataSource={results.rows}
                        expandable
                        expandedRowKeys={expanded}
                        expandIconColumnIndex={-1}
                        onExpand={(e) => { return false; }}
                        expandIcon={() => { return (<></>) }}
                        expandedRowRender={((record) => drawChildTable(record))}
                    />
                    <Paginate paginate={paginate} setPaginate={setPaginate} count={results.total} />

                </Layout.Content>
            </Layout>
        </>
    )
}