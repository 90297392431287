import React, { useEffect, useState } from "react";
import { apiCall } from "../utils/Api";
import moment from "moment";
import { Table } from "antd";


export default function EDILog(props) {

    const { doc_id = 0 } = props;
    const [results, setResults] = useState({ log_list: [] })
    const [loading, setLoading] = useState(false);
    // "action": "upload",
    // "done_by_name": "HarperCollins Canada",
    // "done_by_san": "115026X",
    // "path": "\/home\/pubstock\/client_ftp\/115026X\/outbox\/16770042X85601082022_030030.856",
    // "time": "2022-01-08 00:01:03",
    // "ip_addr": "199.201.3.18",
    // "error": 0


    const columns = [
        {key : "blank", width : "10px"},
        { title: <small>Action</small>, dataIndex: 'action', key: 'action' },
        {
            title: <small>Done by</small>, dataIndex: 'done_by_name', key: 'done_by_name', render: (e, f) => {
                return (
                    <>
                        <div>{e}</div>
                        <div>{f.done_by_san}</div>
                    </>
                )
            }
        },
        { title: <small>Path</small>, dataIndex: 'path', key: 'path' },
        {
            title: <small>Time</small>, dataIndex: 'time', key: 'time', render: (e) => {
                return (e) ? moment(e).format("MMM DD, YYYY") : ""
            }
        },
        { title: <small>IP</small>, dataIndex: 'ip_addr', key: 'ip_addr' },
        {
            title: <small>Error</small>, dataIndex: 'error', key: 'error', render: (e) => {
                return (e) ? e.toString() : "";
            }
        },
    ]



    const getLog = () => {
        setLoading(true)
        apiCall("edi/getLog", { doc_id: doc_id }, (_status, _results) => {
            if (_status) {
                setResults(_results);
                setLoading(false);
            }
        })
    }

    useEffect(getLog, []);




    return (
        <>
            <div style={{ "margin": "-20px" }}>
                <Table
                    size="small"
                    pagination={false}
                    loading={loading}
                    columns={columns}
                    dataSource={results.log_list}
                />
            </div>
        </>
    );

}