import React, { useEffect, useState } from "react";
import { Layout, Popconfirm, Badge, Spin, Typography, Divider, Button, Table, Space, Alert } from "antd";
import Promo from "../../components/Promo";
import { EditOutlined } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";
import moment from "moment";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { useHistory } from "react-router-dom";
import Overlay from "../../components/Overlay";
import PromoManageCustomers from "../../components/PromoManageCustomers";
import EnableSetting from "./EnableSetting";

export default function SalesPromotionsPage(props) {

    const history = useHistory();
    const { promocodes = false } = props;
    const [enabled, setEnabled] = useState(false)
    const meta_title = (promocodes) ? "Promo Codes" : "Sales Promotions";
    const meta_recent = (promocodes) ? "Promo codes" : "Promotions";
    const meta_button = (promocodes) ? "Create promo code" : "Create promotion";

    const [canFreeShip, setCanFreeShip] = useState(false);


    const meta_description = (promocodes) ?
        <>

        </>
        :
        <>
            Sales Promotions are automatically applied to all customers. To avoid conflicts, only one type of Sales promotion can be applied within the specified date range.
        </>;

    const [promos, setPromos] = useState([]);
    const [loading, setLoading] = useState(true);


    

    const ucfirst = (string) => {
        return (string) ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }

    const setResults = (_results) => {

        if (promocodes) {
            setPromos(_results.filter(item => !item.automatic));
        } else {
            setPromos(_results.filter(item => item.automatic));
        }
    }

    // fetch promos
    const getPromos = () => {
        apiCall("promos/listAll", {}, (_status, _results) => {
            if (_status) {
                setLoading(false);
                setResults(_results);
            }
        })
    }
    useEffect(getPromos, [promocodes]);

    // const delete promo
    const removePromo = (_id) => {
        apiCall("promos/delete", { id: _id }, (_status, _results) => {
            if (_status) {
                setResults(_results);
            }
        })
    }

    const columns = [
        {
            title: "", dataIndex: 'edit', key: 'edit', "width": "40px", render: (e, f) => {
                return drawEditButton(f);
            }
        },

        {
            title: "", dataIndex: 'status', key: 'status', "width": "5px", render: (e, f) => {
                if (moment().unix() >= f.start && ((moment().unix() < f.end) || f.end === 0)) {

                    if (promocodes) {
                        if (!f.status) {
                            return (<Badge style={{ "paddingLeft": "7px" }} status="success" />);
                        } else {
                            return (<Badge style={{ "paddingLeft": "7px" }} status="default" />);
                        }
                    }
                    return (<Badge style={{ "paddingLeft": "7px" }} status="success" />);
                } else {
                    return (<Badge style={{ "paddingLeft": "7px" }} status="default" />);
                }
            }
        },
        {
            title: <small>Code</small>, dataIndex: 'code', key: 'code', width: "5px", render: (e, f) => {
                return <div>{e}</div>;
            }
        },
        { title: <small>Title</small>, dataIndex: 'description', key: 'description' },
        {
            title: <small>Type</small>, dataIndex: 'type', key: 'type', render: (e) => {
                return (e) ? ucfirst(e) : "";
            }
        },
        {
            title: <small>Discount</small>, dataIndex: 'discount', key: 'discount', render: (e, f) => {

                switch (f.type) {
                    case "dollar": return (e) ? "$" + e : "N/A";
                    case "percentage": return (e) ? e + "%" : "N/A";
                    case "bogo": return (<>Buy {f.bogo_buy}, get {f.bogo_free} free</>);
                    case "freeship": return ("Free Shipping")
                    default:
                        break;
                }

                return (e) ? (f.type === "dollar") ? "$" + e : e + "%" : "N/A";
            }
        },
        {
            title: <small>Start Date</small>, dataIndex: 'start', key: 'start', render: (e) => {
                return (e) ? moment(e * 1000).format("MMM Do YYYY - h:mma") : "Unset";
            }
        },
        {
            title: <small>End Date</small>, dataIndex: 'end', key: 'end', render: (e) => {
                return (e) ? moment(e * 1000).format("MMM Do YYYY - h:mma") : "Does not expire";
            }
        },
        {
            title: <small>Customers</small>, "width": "40px", dataIndex: 'manage', key: 'manage', render: (e, f) => {
                return drawManageButton(f);
            }
        },
        {
            title: "", dataIndex: 'id', key: 'id', "width": "40px", render: (e,) => {
                return drawRemoveButton(e);
            }
        },
    ];

    const drawRemoveButton = (_id) => {


        return (
            <Popconfirm
                title="Are you sure?"
                onConfirm={() => removePromo(_id)}
                okText="Remove"
                okType="danger"
                cancelText="Cancel"
            >
                <Button danger size="small"><small>Remove</small></Button>
            </Popconfirm>
        );
    }


    const drawManageButton = (_data) => {


        if (!_data.use_once_per_cust) {
            return <></>;
        }

        return (<Overlay width={750} component={<PromoManageCustomers data={_data} />}><Button size="small"><small>Customers</small></Button></Overlay>)

    }


    const drawEditButton = (_data) => {

        return (<Overlay width={700} component={<Promo canFreeShip={canFreeShip} promocodes={promocodes} promos={promos} setPromos={setResults} data={_data} />}><Button type="primary" icon={<EditOutlined />} size="small" /></Overlay>)
        // return (<Promo promocodes={promocodes} promos={promos} setPromos={setResults} data={_data}><Button type="primary" icon={<EditOutlined />} size="small" /></Promo>)
    }


    if (loading) {
        return (<> <div style={{ "height": "100vh", "display": "flex", "alignItems": "center", "justifyContent": "center" }}><Spin size="large" /></div></>);
    }


    return (
        <>
            <Layout className="layout">

                <LayoutHeader
                    title={meta_title}
                    description={<>Use this page to create and manage {meta_title.toLowerCase()}.</>}
                    button={
                        <>
                        <EnableSetting setCanFreeShip={setCanFreeShip} callback={setEnabled} label="Enable sales promotions & promo codes" setting_key="global:using_promo_codes" />
                        </>
                    }
                />

                <Layout.Content style={{ "padding": "0px" }} >

                    {((!enabled) && <div style={{"padding": "30px", "paddingBottom" : "0px"}}><Alert message="Sales promotions & promo codes are not enabled for this webstore." type="warning" /></div>)}
                    <Spin indicator={<></>} spinning={!(enabled)}>
                    <div style={{ "padding": "30px" }}>
                        <Typography.Title level={5}>
                            <div className="float-flex">
                                <div>{meta_recent}</div>
                                <Overlay width={700} component={<Promo canFreeShip={canFreeShip} promocodes={promocodes} promos={promos} create_new setPromos={setResults}></Promo>}><Button size="small" type="primary"><small>{meta_button}</small></Button></Overlay>
                            </div>
                           
                        </Typography.Title>
                        <Typography.Paragraph type="secondary">{meta_description}</Typography.Paragraph>
                        <div className="shim" />
                        <Table size="small" bordered pagination={false}
                            columns={columns}
                            dataSource={promos}
                        />
                    </div>
                    </Spin>
                </Layout.Content>
            </Layout>
        </>
    )
}