/*
    ---- Html ----
    Displays HTML
*/

import React from "react";

function Html(props) {

  const { html, allowbreaks = false, className="html htmlcolor" } = props;

  const display = () => {
    return { __html: html };
  }

  return (<><div style={{ "overflow": "hidden", "white-space-collapse" : (allowbreaks) ? "preserve-breaks" : "initial"}} className={className} dangerouslySetInnerHTML={display()}></div></>);

}

export default Html;