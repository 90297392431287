import React, { useEffect, useState } from "react";
import { Cascader, TreeSelect, message } from 'antd';
import { apiCall } from "../../utils/Api";


export default function BisacSelector(props) {

  const { value, size = "middle", setValue = () => { }, strToArr, multiple = true } = props;
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([])

  const markNonBooks = (_arr) => {

    return _arr.map(item => {
      if(!item.hasOwnProperty("id")){
        return { ...item, "label": item.label + " (can't select)", "selectable" : false, id : item.label, "children": markNonBooks(item.children) }
      }
      if (item.hasOwnProperty("id") && item.id.substr(0, 1) === "_") {
        return { ...item, "label": item.label + " (nonbook)", "selectable" : true, "children": markNonBooks(item.children) }
      } else {
        return {...item, "selectable" : true}
      }
    })
  }


  // const markNonBooks = (_arr) => {
  //   return _arr.map(item => {
  //     if (item.id.substr(0, 1) === "_") {
  //       if (item.hasOwnProperty("children")) {
  //         return { ...item, "label": item.label + " (nonbook)", "children": markNonBooks(item.children) }
  //       } else {
  //         return { ...item, "label": item.label + " (nonbook)" }
  //       }
  //     } else {
  //       return item;
  //     }
  //   })
  // }


  const getBisacs = () => {

    apiCall("title/getBisacs", {}, (_status, _result) => {
      if (_status) {
        setResults(markNonBooks(_result));
        setLoading(false);
      }
    })
  }

  useEffect(getBisacs, []);





  const onChange = (_v) => {
    console.log(_v);
  }

  const loadData = selectedOptions => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

  }



  const getOptions = (_results) => {

    return _results.map((item, index) => {
      return {
        value: item.id,
        key: item.id,
        title: (<div>{item.label} <small> - <em>({item.id})</em></small></div>),
        selectable : item.selectable,
        children: getOptions(item.children)
      }
    })
  }


  return (
    <>
    <TreeSelect  size={size} style={{ "width": "100%", "fontSize": (size === "small") ? "12px" : "14px" }} multiple={multiple} loading={loading} onChange={(e) => {if(e.length <= 3){ setValue(e);} else {message.warn("Max 3 Bisacs")} }} value={(value) ? strToArr(value) : undefined} showSearch placeholder={(loading) ? "Loading..." : "Select Bisac"} treeData={getOptions(results)} />
    </>
  );
}