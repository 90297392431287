
import input from "./media/form_icons/input.png";
import checkbox from "./media/form_icons/checkbox.png";
import radio from "./media/form_icons/radio.png";
import selectbox from "./media/form_icons/selectbox.png";
import textarea from "./media/form_icons/textarea.png";


import text from "./media/material_icons/text.svg";

export const elements = [

    {
        label: "Text",
        description: "Single line text input",
        icon: "text",
        data: {
            label : "Text",
            type : "text",
            description : "",
            placeholder : "Enter text...",
            max_length : 50,
            required : false,
            required_message : "This field is required"
        }
    },

    {
        label: "Text Area",
        description: "Multi line text input",
        icon: "textarea",
        data: {
            label : "Text Area",
            type : "textarea",
            description : "",
            placeholder : "Enter text...",
            max_length : 500,
            rows : 3,
            required : false,
            required_message : "This field is required"
        }
    },

    {
        label: "Email Address",
        description: "Validates email address",
        icon: "email",
        data: {
            label : "Email address",
            type : "email",
            description : "",
            placeholder : "Enter email...",
            max_length : 50,
            required : false,
            required_message : "This field is required"
        }
    },

    {
        label: "Phone Number",
        description: "10 digit numeric",
        icon: "phone",
        data: {
            label : "Phone number",
            type : "phone",
            description : "",
            placeholder : "Enter phone number...",
            max_length : 50,
            required : false,
            required_message : "This field is required"
        }
    },

    {
        label: "Date",
        description: "Year, Month, Day",
        icon: "date",
        data: {
            label : "Date",
            type : "date",
            description : "",
            max_length : 50,
            required : false,
            required_message : "This field is required"
        }
    },

    {
        label: "Day & Month",
        description: "Display day and month",
        icon: "date",
        data: {
            label : "Day & Month",
            type : "daymonth",
            description : "",
            required : false,
            required_message : "This field is required"
        }
    },

    {
        label: "Selection",
        description: "Dropdown select box",
        icon: "select",
        data: {
            label : "Select",
            type : "select",
            description : "",
            options : [{id : "default", label : "Option 1"}]
        }
    },

    {
        label: "Checkbox",
        description: "Single checkbox",
        icon: "checkbox",
        data: {
            label : "Checkbox",
            type : "checkbox",
            description : "",
        }
    },

    {
        label: "Radio",
        description: "1 or more radio buttons",
        icon: "radio",
        data: {
            label : "Choose",
            type : "radio",
            description : "",
            options : [{id : "default", label : "Option 1"}]
        }
    },


];