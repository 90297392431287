import React, { useState, useRef, useEffect } from 'react';
import { Jodit } from 'jodit-react';
import { Modal } from 'antd';
import FileBrowser from "../../utils/FileBrowser";
import { useSession } from '../../utils/Session';

export default function TextEditor(props) {

    const { value = "", onChange = () => { }, bold = true, italic = true, underline = true, ul = true, ol = true, brush = true, link = true, gallery = true, height = 300 } = props;
    const editorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [joditInsance, setJoditInstance] = useState();
    const [session, setSession] = useSession();
    const { webstore_name = "" } = session;

    let buttons = [];

    if (bold) { buttons.push({ name: "bold" }); }
    if (italic) { buttons.push({ name: "italic" }); }
    if (underline) { buttons.push({ name: "underline" }); }
    if (ul) { buttons.push({ name: "ul", list: false }); }
    if (ol) { buttons.push({ name: "ol", list: false }); }
    if (brush) { buttons.push({ name: "brush", foreColor: false }); }
    if (link) { buttons.push({ name: "link", noFollowCheckbox: false }) }
    if (gallery) { buttons.push({ name: "gallery", iconURL: '/media/image.svg', exec: function (editor) { return setOpen(true); } }) }

    useEffect(() => {
        if (editorRef.current) {
            const jodit = new Jodit(editorRef.current, {
                placeholder: "Type your text...",
                toolbarButtonSize: "small",
                buttons: buttons,
                toolbarAdaptive: false,
                disablePlugins: ["imageProperties", "paste"],
                height: height
            });

            jodit.events.on('paste', function (event) {
                event.preventDefault(); // Prevent the default paste action
                const clipboardData = event.clipboardData || window.clipboardData;
                const plainText = clipboardData.getData('text/plain');
                // Insert the plain text into the editor
                jodit.selection.insertHTML(plainText);
            });

            jodit.events.on('dblclick', function (event) {
                var target = event.target;
                // Check if the double-clicked element is an image
                if (target && target.tagName === 'IMG') {
                    // Prevent the default context menu
                    event.preventDefault();
                }
            });

            jodit.setEditorValue(value);
            jodit.events.on('change', (e) => {
                onChange(e)
            });
            setJoditInstance(jodit)
        }
    }, []);

    const callBack = (_img) => {
        setOpen(false)
        joditInsance.selection.insertHTML("<img class='bm-img' src='https://bookmanager.com/i/" + webstore_name + _img.path + "' />")
    }

    return (
        <div>
            <Modal title="Media Gallery" footer={null} width={1000} visible={open} onCancel={() => setOpen(false)}>
                <FileBrowser selectImage={callBack} />
            </Modal>
            <textarea ref={editorRef} />

        </div>
    );
};

