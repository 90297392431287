import React, { useState } from "react";
import { Layout, Upload, Typography, Row, Col, Button, Divider, message } from "antd";
import 'rc-color-picker/assets/index.css';
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useSession } from "../../utils/Session";
import { cacheBuster } from "../../utils/Utils";
import { apiCall } from "../../utils/Api";

export default function BrandingPage() {

    const [session, setSesion] = useSession();
    const favicon_path = "https://bookmanager.com/i/" + session.webstore_name + "/favicon.png"
    const letterhead_path = "https://bookmanager.com/i/" + session.webstore_name + "/letterhead_logo.jpg"

    const UploadDirect = (props) => {

        const {name = "", accept = ""} = props; 
        const [path, setPath] = useState(props.path + cacheBuster(true));
        const [fileList, setFileList] = useState([]);
        const [loading, setLoading] = useState(false);
     
        const handleUpload = (_f) => {
            let _location = "/" + name; 
            setLoading(true)
            apiCall("files/upload", { file: fileList[0], location: _location }, (_status, _result) => {
                if (_status) {
                    message.success("File uploaded successfully.")
                } else {
                    message.error("Error: " + _result.error);
                }
                setFileList([]);
                setPath(props.path + cacheBuster(true));
                setLoading(false);
            })
        }

        const onRemove = (_file) => {
            setFileList([]);
        }

        const beforeUpload = (_file) => {
            setFileList([_file]);
            return false;
        }

        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <Upload accept={accept} showUploadList={false} multiple={false} listType="picture-card" onChange={(e) => handleUpload(e)} onRemove={(_file) => onRemove(_file)} beforeUpload={(_file) => beforeUpload(_file)}>
                {path ? <img onError={() => setPath("")} src={path} alt="avatar" style={{ maxWidth: '100%' }} /> : uploadButton}
            </Upload>
        )
    }

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Branding"
                    description="Use this page configure branding images."
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "minHeight": "calc(100vh - 250px)" }}>
                        <div style={{ "padding": "20px" }}>
                            <Row gutter={[30, 30]}>
                                <Col span={16}>
                                    <Typography.Title level={5}>Emailed Order Confirmation Header Graphic</Typography.Title>
                                    <Typography.Paragraph type="secondary">
                                        Upload an image that will display at the top of emailed online order confirmations. This is typically your store logo. <br />Recommended width of 500px. Only JPG files are accepted, with a maximum size of 1MB.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={8}>
                                    <UploadDirect accept=".jpg" name="letterhead_logo.jpg" path={letterhead_path} />
                                </Col>
                            </Row>
                            <Divider dashed />
                            <Row gutter={[30, 30]}>
                                <Col span={16}>
                                    <Typography.Title level={5}>Favicon Image</Typography.Title>
                                    <Typography.Paragraph type="secondary">
                                        Upload an image to appear in the tabs of web browsers. The image will be automatically resized when it is displayed, but, depending on the browser and device, to varying dimensions. We recommend 32px by 32px. Only PNG files are accepted.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={8}>
                                    <UploadDirect accept=".png" name="favicon.png" path={favicon_path} />
                                </Col>
                            </Row>
                            {/* <Divider dashed />
                            <Row gutter={[30, 30]}>
                                <Col span={16}>
                                    <Typography.Title level={5}>PDF covers logo</Typography.Title>
                                    <Typography.Paragraph type="secondary">
                                        Upload an image that will display on the cover page of PDFs generated on Biz. This is typically your store logo. Upload an image that is ????px wide, with a maximum height of ????px. PNG or JPG files are accepted, with a maximum size of 1MB.
                                    </Typography.Paragraph>
                                </Col>
                                <Col span={8}>
                                    <UploadDirect path={""} />
                                </Col>
                            </Row> */}
                        </div>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}