import React, { useEffect, useState } from "react";
import { Button, Divider, Empty, Form, Layout, Radio, Select, Space, Spin, Typography, Table, Input, Pagination } from "antd";
import { CloseOutlined, PicCenterOutlined, MenuOutlined, BookFilled, BookOutlined, StarOutlined, StarFilled, DeleteFilled, DownSquareFilled, DownSquareOutlined } from '@ant-design/icons';
import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional";
import moment from "moment";
import ListFav from "../components/lists/ListFav";
import Renderer from "../components/Renderer";
import ListTrash from "../components/lists/ListTrash";
import ListArchive from "../components/lists/ListArchive";
import ListFolder from "../components/lists/ListFolder";
import ListItem from "../components/lists/ListItem";
import { disabledColor, successColor, warningColor, pathToObject, objectToPath } from "../utils/Utils";
import BrowseFilters5 from "../components/BrowseFilters5";
import Paginate from "../components/Paginate";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Bookmark from "../components/Bookmark";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import Cookies from "js-cookie";
import { useSession } from "../utils/Session";


export default function CataloguesPage(props) {

    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState({ filters: {}, rows: [], toolboxes: {} });
    const [expanded, setExpanded] = useState([]);
    const [showDetails, setShowDetails] = useState((Cookies.get("categories_show_list") === "true") ? false : true);
    const path = "/purchasing/catalogues";
    const [searchFilters, setSearchFilters] = useState(pathToObject(location.pathname, "catalogs_limit"))
    const [current, setCurrent] = useState(parseInt(searchFilters.o / searchFilters.l) + 1);
    const [filters, setFilters] = useState({});
    const [initLoaded, setInitLoaded] = useState(false);
    const [session, setSession] = useSession();
    const [listLabel, setListLabel] = useState({ key: "", value: "" })


    const getLists = () => { }


    const getCatalogues = (_change = false, _obj = searchFilters) => {
        setLoading(true);
        let _filters = { ..._obj };
        _filters.filters = JSON.stringify(_filters.filters);
        apiCall("titlelist/getCatalogues", _filters, (_status, _result) => {
            if (_status) {
                if (_result.row_count === 1) {
                    if (_obj.hasOwnProperty("t")) {
                        if (!isNaN(_obj.t)) {
                            setListLabel({ key: _result.rows[0].actual_id, value: _result.rows[0].name })
                        }
                    }
                }
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                setFilters(_result.filter_info);
                setResults(_result);
                setLoading(false);
                setInitLoaded(true);
            }

        })
    }

    const headerSearch = () => {
        setLoading(true);
        let obj = pathToObject(location.pathname, "catalogs_limit");
        setSearchFilters(obj);
        getCatalogues(false, obj);

    }

    useEffect(headerSearch, [])


    const deleteList = (_list_id) => {
        apiCall("titlelist/delete", { list_id: _list_id }, (_status, _result) => {
            if (_status) {
                // getLists();
                //let res = { ...results };
                //setResults({ ...results, "rows": res.rows.filter(item => item.list_id !== _list_id) })
            }
        })
    }

    const expandCell = (_data) => {
        if (!expanded.includes(_data.list_id)) {
            setExpanded([_data.list_id]);
        } else {
            setExpanded([]);
        }
    }

    const drawChildTable = (_record) => {
        return (
            <>
                <div className="hideHover" style={{ "marginTop": "-4px", "marginLeft": "-8px" }}>
                    <div style={{ "padding": "0 20px" }}>
                        <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                            <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                            <br clear="both" />
                        </div>
                    </div>
                    <div style={{ "padding": "0px 20px" }}>
                        <Renderer key={_record.list_id}>
                            <ListItem is_catalogue={true} getLists={getLists} setResults={setResults} results={results} data={_record} />
                        </Renderer>
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </>
        )
    }

    // const drawChildTable = (_record) => {
    //     return (
    //         <>

    //             <div className="hideHover" style={{ "marginTop": "-4px", "marginLeft" : "-8px" }}>
    //                 <div style={{ "padding": "0 20px" }}>
    //                     <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
    //                         <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
    //                         <br clear="both" />
    //                     </div>
    //                 </div>
    //                 <div style={{ "padding": "0px 20px" }}>
    //                     <Renderer key={_record.list_id}>
    //                         <ListItem getLists={getLists} setResults={setResults} results={results} data={_record} />
    //                     </Renderer>
    //                 </div>
    //                 <div className="shim" /><div className="shim" />
    //             </div>
    //         </>
    //     )
    // }



    const columns = [
        {
            title: <small>&nbsp;&nbsp;Fav</small>, dataIndex: 'is_starred', key: 'is_starred', render: (e, f) => {

                return (<ListFav list={f} results={results} setResults={setResults} getLists={getCatalogues} />);
            }
        },

        {
            title: <small>Vendor</small>, dataIndex: 'vendor', key: 'vendor', render: (e) => {
                return (e) ? e.name : "";
            }
        },

        {
            title: <small>List name</small>, dataIndex: 'name', key: 'name', render: (e, f) => {
                return (e) ? <a onClick={() => expandCell(f)}>{e}</a> : "";
            }
        },
        {
            title: <small>Titles</small>, dataIndex: 'isbns_count', key: 'isbns_count'
        },
        {
            title: <small>OH</small>, dataIndex: 'onhand', key: 'onhand', render: (e) => {
                return <span style={{ "color": successColor, "fontWeight": "500" }}>{e}</span>
            }
        },
        {
            title: <small>OO</small>, dataIndex: 'onorder', key: 'onorder', render: (e) => {
                return <span style={{ "color": warningColor, "fontWeight": "500" }}>{e}</span>
            }
        },
        { title: <small>Cart</small>, dataIndex: 'cart_count', key: 'cart_count' },
        {
            title: <small>Posted</small>, dataIndex: 'date_posted', key: 'date_posted', render: (e, f) => {
                return (
                    <>
                        <div><nobr>{moment(e * 1000).format("MMMDD-YY")}</nobr></div><div style={{ "color": disabledColor }}>
                            {/* <em>{f.views} views</em> */}
                        </div>
                    </>
                )
            }
        },
        {
            title: <small>Category</small>, dataIndex: 'category', key: 'category', render: (e, f) => {
                return <><div style={{ "color": disabledColor }}><em>{e}</em></div><div style={{ "fontWeight": "500", "maxWidth": "100px", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis" }}>{f.owner_name}</div></>
            }
        },
        {
            title: <small>Season</small>, dataIndex: 'season', key: 'season', render: (e) => {
                return (<nobr>{(e) ? e : ""}</nobr>);
            }
        },

        {
            title: <small>Folder</small>, width: "160px", dataIndex: 'folder', key: 'folder', render: (e, f) => {
                return (
                    <Space>
                        <Bookmark link list_id={f.list_id} />
                        <ListTrash list={f} results={results} setResults={setResults} />
                        <ListArchive list={f} results={results} setResults={setResults} />
                        <ListFolder getLists={getLists} results={results.rows} setResults={setResults} folder={e} list_id={f.list_id} />
                    </Space>
                )
            }
        }

    ]

    const displayGroup = () => {
        return (
            <>
                <Radio.Group size="small" value={showDetails.toString()} onChange={(e) => {
                    Cookies.set("categories_show_list", showDetails);
                    setShowDetails(!showDetails);
                }} buttonStyle="solid">
                    <Radio.Button value="true"><small><PicCenterOutlined /></small></Radio.Button>
                    <Radio.Button value="false"><small><MenuOutlined /></small></Radio.Button>
                </Radio.Group>
            </>
        )
    }


    const SearchWithin = (props) => {

        const [v, setV] = useState(props.term);
        const searchWithin = (e, sw = true) => {
            setCurrent(1);
            let sf
            if (sw) {
                // Augment Search Filters
                sf = { ...searchFilters, "t": e, "o": 0 }
            } else {
                // Clear Search Filters
                sf = { "t": e, "o": 0 }
            }
            getCatalogues(false, sf);
            setSearchFilters(sf);
            history.push({
                pathname: path + objectToPath(sf)
            })
        }


        return (
            <Space size={2}>
                <Input value={v} onChange={(e) => {
                    if (e.target.value === "" && props.term !== "") {
                        // clear button
                        searchWithin("")
                    } else {
                        setV(e.target.value);
                    }
                }} size="small" onKeyDown={(e) => (e.key === "Enter" && searchWithin(e.target.value))} allowClear placeholder="Enter ISBN, list id or keyword within a list's name" style={{ "width": "400px" }} />
                <Button onClick={() => searchWithin(v)} size="small" type="primary"><small>Search within</small></Button>
                <Button onClick={() => searchWithin(v, false)} size="small" type="primary"><small>Search all</small></Button>
            </Space>
        )
    }



    const drawFilters = () => {
        return (
            <div style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "10px 20px", "paddingTop": "0px" }}>
                <Form layout="vertical">
                    <Space wrap size={[5, 0]}>

                        <Form.Item noStyle initialValue={(searchFilters.hasOwnProperty("t") ? searchFilters.t : "")} label={<small>Find a list</small>} name="customer_search">
                            <SearchWithin term={(searchFilters.hasOwnProperty("t") ? searchFilters.t : "")} />
                        </Form.Item>

                        {/* <Form.Item noStyle label={<small>Find a list</small>} name="customer_search">

                            
                            <Input.Search size="small" onSearch={(e) => { 

                                let sf = { ...searchFilters, "t": e, "o" : 0 }
                                setCurrent(1);
                                getCatalogues(false, sf);
                                history.push({ pathname: path + objectToPath(sf) })
                                
                                }} allowClear enterButton placeholder="Enter ISBN, list id or keyword within a list's name" style={{ "width": "407px" }} />
                        </Form.Item> */}
                        <Form.Item noStyle label={<small>Sort</small>}>
                            <Select size="small" defaultValue={searchFilters.v} onChange={(e) => {
                                let sf = { ...searchFilters, "v": e }
                                getCatalogues(false, sf);
                                history.push({
                                    pathname: path + objectToPath(sf)
                                })
                            }} style={{ width: 130 }}>
                                <Select.Option value=""><small>Date posted</small></Select.Option>
                                <Select.Option value="name"><small>Alphabetical</small></Select.Option>
                                <Select.Option value="date_updated" ><small>Last updated</small></Select.Option>
                            </Select>
                        </Form.Item>
                    </Space>
                    <br clear="both" />
                </Form>
            </div>)
    }



    return (
        <>
            <Layout className="layout" style={{ "minHeight": "500px" }}>

                <LayoutHeader
                    title={<>
                        <div style={{ "float": "right", "marginTop": "-7px" }}>{displayGroup()}</div>
                        Catalogues

                    </>}
                    filters={
                        <div style={{ "margin": "0px -20px", "marginTop": "-15px" }}>
                            {(initLoaded && <>
                                <BrowseFilters5 listLabel={listLabel} getBrowse={getCatalogues} loading={loading} toolboxes={results.toolboxes} header="listcat" path={path} setCurrent={setCurrent} filters={filters} setFilters={setFilters} searchFilters={searchFilters} setSearchFilters={setSearchFilters} />
                                {drawFilters()}
                            </>)}
                        </div>
                    }
                />


                <Layout.Content style={{ "padding": "0px" }} >

                    <Paginate save="catalogs_limit" loading={loading} paginate={{ current: current, offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0, pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 20 }} setPaginate={(e) => {
                        setCurrent(e.current);
                        let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                        setSearchFilters(sf);
                        getCatalogues(false, sf);
                        history.push({ pathname: path + objectToPath(sf) });
                    }} count={results.row_count} />




                    <div className="bc bcg2">
                        <conditional.true value={(!showDetails)}>

                            <Table
                                rowKey="list_id"
                                size="small"
                                columns={columns}
                                loading={loading}
                                className={"fullTable"}
                                dataSource={results.rows}
                                pagination={false}
                                expandable
                                expandedRowKeys={expanded}
                                expandIconColumnIndex={-1}
                                onExpand={(e) => { return false; }}
                                expandIcon={() => { return (<></>) }}
                                expandedRowRender={((record) => drawChildTable(record))}
                            />

                        </conditional.true>
                        <conditional.true value={(showDetails)}>
                            <Spin spinning={loading}>
                                <div style={{ "padding": "20px" }}>
                                    <conditional.true value={(results.rows.length < 1)}>
                                        <Empty description="No results." image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </conditional.true>
                                    {results.rows.map((item) => {
                                        return <div key={item.list_id}><Renderer><ListItem is_catalogue={true} getLists={getCatalogues} setResults={setResults} results={results} data={item} /></Renderer><div className="borderBottom shim" style={{ "margin": "35px -20px", "borderBottomWidth": "6px" }} /></div>
                                    })}
                                </div>
                            </Spin>
                        </conditional.true>
                    </div>

                    <Paginate save="catalogs_limit" loading={loading} paginate={{ current: current, offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0, pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 20 }} setPaginate={(e) => {
                        setCurrent(e.current);
                        let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                        setSearchFilters(sf);
                        getCatalogues(false, sf);
                        history.push({ pathname: path + objectToPath(sf) });
                    }} count={results.row_count} />

                </Layout.Content>
            </Layout>



        </>
    )

}