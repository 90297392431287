import React, { useEffect, useState } from "react";
import { Layout, Typography, Divider, Form, Radio, Select, Switch, Input, Space, DatePicker, Row, Col, Pagination, Table, Button } from "antd";
import { RedoOutlined, ProfileOutlined, ShareAltOutlined, MenuOutlined, PicLeftOutlined, AppstoreOutlined, CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import EDILog from "../../components/EDILog.js";
import { apiCall, apiDownload } from "../../utils/Api";
import { addKeys, bytesToSize, addCommas } from "../../utils/Utils";
import Paginate from "../../components/Paginate.js";

export default function EdiActivityPage() {

    const [results, setResults] = useState({
        total_rows: 0,
        rows: [],
        filters: {
            contacts: [], // {name : "", san : "", id : ""}
            file_types: []
        },
        edi_users: [] // {name : "", san : "", id : ""}
    })

    const initFilters = {
        show_which: "all",
        start_date: moment().startOf("day").subtract(7, "day").unix(),
        end_date: moment().startOf("day").add(1, "day").unix(),
        offset: 0,
        limit: 20,
        filename: "",
        trading_with: 0,
        trading_as: 0,
        file_type: "",
        hide_env: false,
        display_details: true,
    };

    const [filters, setFilters] = useState(initFilters);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState([]);

    const downloadFile = (_doc_id) => {
        let obj = {
            doc_id: _doc_id
        }
        apiDownload(obj, "edi/download");
    }

    const getActivity = () => {
        setLoading(true);
        apiCall("edi/getActivity", filters, (_status, _results) => {
            if (_status) {
                addKeys(_results.rows);
                setResults(_results);
                setLoading(false);
            }
        })
    }

    useEffect(getActivity, [filters]);

    const pageChange = (_page, _limit) => {
        setPageSize(_limit);
        setCurrent(_page);
        let _offset = (_page - 1) * _limit;
        setFilters({ ...filters, "offset": _offset, "limit": _limit });
    }

    const drawShowing = () => {
        let end = current * pageSize;
        let start = (current * pageSize) - (pageSize - 1);
        if (end > results.total_rows) {
            end = results.total_rows;
        }
        if (results.total_rows === 0) {
            return "0 of ";
        }
        return addCommas(start) + " to " + addCommas(end);
    }

    const drawChildTable = (_record) => {
        return (
            <>
                <div className="hideHover" style={{ "margin": "-7px" }}>
                    <div style={{ "padding": "0 20px" }}>
                        <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                            <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                            <br clear="both" />
                        </div>
                    </div>
                    <div style={{ "padding": "0px 20px" }}>
                        <EDILog doc_id={_record.doc_id} />
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </>
        )
    }

    const expandCell = (_data, _type) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }

    const setTrading = (_san) => {
        let obj = results.edi_users.find(item => item.san === _san);
        if (obj) {
            // change edi user
            setFilters({ ...filters, "trading_as": obj.id });
        } else {
            obj = results.filters.contacts.find(item => item.san === _san);
            if (obj) {
                // change trading with
                setFilters({ ...filters, "trading_with": obj.id });
            } else {
                // gracefully fail
            }
        }
    }

    const columns = [

        { key: "blank", width: "10px" },
        {
            title: <small>To</small>, dataIndex: 'to_name', key: 'to_name', render: (e, f) => {
                return (
                    <>
                        <div>{e}</div>
                        <a onClick={() => setTrading(f.to_san)}>{f.to_san}</a>
                    </>
                )
            }
        },
        {
            title: <small>From</small>, dataIndex: 'from_name', key: 'from_name', render: (e, f) => {
                return (
                    <>
                        <div>{e}</div>
                        <a onClick={() => setTrading(f.from_san)} >{f.from_san}</a>
                    </>
                )
            }
        },

        {
            title: <small>Type</small>, dataIndex: 'doctype', key: 'doctype', render: (e, f) => {
                return (<nobr>{f.format} {e}</nobr>)
            }
        },
        {
            title: <small>Sent</small>, dataIndex: 'sent', key: 'sent', render: (e) => {
                return (e) ? <nobr>{moment(e).format("MMM DD, YYYY")}</nobr> : ""
            }
        },
        {
            title: <small>Received</small>, dataIndex: 'received', key: 'received', render: (e) => {
                return (e) ? <nobr>{moment(e).format("MMM DD, YYYY")}</nobr> : ""
            }
        },
        {
            title: <small>Bytes</small>, dataIndex: 'size', key: 'size', render: (e) => {
                return bytesToSize(e);
            }
        },
        {
            title: <small>Filename</small>, dataIndex: 'filename', key: 'filename', render: (e, f) => {
                return (filters.display_details) ? <a onClick={() => downloadFile(f.doc_id)}>{e}</a> : <>{e} files</>
            }
        },
        { title: <small>ISA</small>, dataIndex: 'isa', key: 'isa' },
        {
            title: <small>{(filters.display_details && "Log")}</small>, dataIndex: 'doc_id', key: 'doc_id', render: (e, f) => {
                return (filters.display_details) ? <a onClick={() => expandCell(f)}>[Log]</a> : "";
            }
        },
        { title: "", dataIndex: 'space', key: 'space' },
    ]

    const drawFilters = () => {

        return (
            <div style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "20px 20px 0px 20px" }}>
                <Form size="small" layout="vertical">

                    <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                        <div>EDI Activity</div>
                    </Typography.Title>

                    <Divider dashed style={{ "marginTop": "10px", "marginBottom": "15px" }} />
                    <Space wrap size={[10, 0]}>
                        <Form.Item label={<small>Date range</small>} >
                            <DatePicker.RangePicker defaultValue={[moment(filters.start_date * 1000), moment(filters.end_date * 1000)]} style={{ "width": "250px" }} onChange={(e) => setFilters({ ...filters, "start_date": e[0].startOf('day').unix(), "end_date": e[1].endOf('day').unix() })} format="MMM DD, YYYY" />
                        </Form.Item>
                        <Form.Item label={<small>Display</small>}>
                            <Radio.Group value={filters.display_details} onChange={(e) => setFilters({ ...filters, "display_details": e.target.value })} buttonStyle="solid">
                                <Radio.Button value={true}><small>Detail</small></Radio.Button>
                                <Radio.Button value={false}><small>Summary</small></Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label={<small>Status</small>} >
                            <Select value={filters.show_which} onChange={(e) => setFilters({ ...filters, "show_which": e })} style={{ width: 90 }}>
                                <Select.Option value="all"><small>Any</small></Select.Option>
                                <Select.Option value="waiting"><small>Waiting</small></Select.Option>
                                <Select.Option value="sent" ><small>Sent</small></Select.Option>
                                <Select.Option value="incoming" ><small>Incoming</small></Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={<small>File type</small>}>
                            <Select value={filters.file_type} onChange={(e) => setFilters({ ...filters, "file_type": e })} style={{ width: 90 }}>
                                <Select.Option key="any" value=""><small>Any</small></Select.Option>
                                {results.filters.file_types.map((item, index) => {
                                    return (<Select.Option key={index} value={item}><small>{item}</small></Select.Option>)
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={<small>Filename Search</small>}>
                            <Input.Search onSearch={(e) => setFilters({ ...filters, "filename": e })} allowClear enterButton placeholder="Search filename..." style={{ "width": "260px", "fontSize": "12px" }} />
                        </Form.Item>

                        <Form.Item label={<small>EDI User (Name)</small>}>
                            <Select value={filters.trading_as} onChange={(e) => setFilters({ ...filters, "trading_as": e })} style={{ width: 150 }}>
                                <Select.Option key="all" value={0}><small>All</small></Select.Option>
                                {results.edi_users.map((item, index) => {
                                    return (<Select.Option key={index} value={item.id}><small>{item.name}</small></Select.Option>)
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label={<small>Hide 997s / Inv Reqs</small>}>
                            <Switch checked={filters.hide_env} onChange={(e) => setFilters({ ...filters, "hide_env": e })} size="small" />
                        </Form.Item>

                        <Form.Item label={<small>Trading with (Name): </small>}>
                            <Select value={filters.trading_with} onChange={(e) => setFilters({ ...filters, "trading_with": e })} style={{ width: 150 }}>
                                <Select.Option key="any" value={0}><small>Any</small></Select.Option>
                                {results.filters.contacts.map((item, index) => {
                                    return (<Select.Option key={index} value={item.id}><small>{item.name}</small></Select.Option>)
                                })}
                            </Select>
                        </Form.Item>
                        <Divider plain orientation="vertical"><small>or</small></Divider>
                        <Form.Item label={<small>EDI User (SAN)</small>}>
                            <Select value={filters.trading_as} onChange={(e) => setFilters({ ...filters, "trading_as": e })} style={{ width: 110 }}>
                                <Select.Option key="all" value={0}><small>All</small></Select.Option>
                                {results.edi_users.map((item, index) => {
                                    return (<Select.Option key={index} value={item.id}><small>{item.san}</small></Select.Option>)
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={<small>Trading with (SAN): </small>} >
                            <Select value={filters.trading_with} onChange={(e) => setFilters({ ...filters, "trading_with": e })} style={{ width: 110 }}>
                                <Select.Option key="any" value={0}><small>Any</small></Select.Option>
                                {results.filters.contacts.map((item, index) => {
                                    return (<Select.Option key={index} value={item.id}><small>{item.san}</small></Select.Option>)
                                })}
                            </Select>
                        </Form.Item>
                    </Space>
                </Form>
            </div>
        )

    }



    const setPaginate = (_obj) => {
        setCurrent(_obj.current);
        setFilters({ ...filters, "offset": _obj.start, "limit": _obj.limit });
    }

    return (
        <>
            <Layout className="layout">
                {/* <Layout.Sider theme="light" >
                    <div style={{ "margin": "17px 13px" }}>
                        <Typography.Title style={{ "textAlign": "center", "fontSize": "15px" }} level={5}>
                            Actions
                        </Typography.Title>
                        <Space size={5} style={{ "width": "100%" }} direction="vertical">
                            <div onClick={() => setFilters(initFilters)} style={{ "backgroundColor": "#0842a0", "color": "#fff", "textAlign": "center", "fontWeight": "500", "cursor": "pointer" }}><small>Reset</small></div>
                        </Space>
                        <Divider style={{ "margin": "8px 0px" }} dashed />
                        <div onClick={() => getActivity()} style={{ "backgroundColor": "#78c042", "color": "#fff", "textAlign": "center", "fontWeight": "500", "cursor": "pointer" }}><small>Refresh <RedoOutlined /></small></div>
                        <Divider style={{ "margin": "8px 0px" }} />
                    </div>
                </Layout.Sider> */}



                <Layout.Content className="layoutHeader" style={{ "padding": "0px", "borderBottom": "0px" }} >
                    <div style={{ "padding": "0px 0px" }}>
                        {drawFilters()}
                    </div></Layout.Content>
                <Divider style={{ "margin": "0px" }} />
                <Layout.Content className="layoutHeader" style={{ "padding": "0px" }} >
                    <div style={{ "padding": "0px 0px" }}>
                        <Paginate setPaginate={(e) => setPaginate({ current: e.current, start: e.offset, limit: e.pagesize })} count={results.total_rows} paginate={{ current: current, pagesize: filters.limit, offset: filters.start }} />

                        <div style={{ "margin": "0 0px" }}>
                            <Table
                                size="small"
                                pagination={false}
                                loading={loading}
                                columns={columns}
                                dataSource={results.rows}
                                expandable
                                expandedRowKeys={expanded}
                                expandIconColumnIndex={-1}
                                onExpand={(e) => { return false; }}
                                expandIcon={() => { return (<></>) }}
                                expandedRowRender={((record) => drawChildTable(record))}
                            />
                        </div>
                        <Paginate setPaginate={(e) => setPaginate({ current: e.current, start: e.offset, limit: e.pagesize })} count={results.total_rows} paginate={{ current: current, pagesize: filters.limit, offset: filters.start }} />
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}