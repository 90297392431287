/*
    ---- Html ----
    Displays HTML
*/

import React, { useEffect, useRef, useState } from "react";

function Expand(props) {

    
  const { html, color="#000", allowbreaks = false, className="clamp3" } = props;

  const [expand, setExpand] = useState(true)
  const ref = useRef();

  const getLineCount = (element) => {
    const lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
    const height = element.clientHeight;
    const lineCount = Math.round(height / lineHeight);
    return lineCount;
  }


  useEffect(() => {

    let _count = getLineCount(ref.current);
    if(_count > 3){
        setExpand(false);
    }
  }, [])

  
  
  const display = () => {
    return { __html: html };
  }

  return (<>
  <div ref={ref} style={{ "overflow": "hidden", "white-space-collapse" : (allowbreaks) ? "preserve-breaks" : "initial", "-webkit-box-orient" : (expand) ? "initial" : "vertical"}} className={className} dangerouslySetInnerHTML={display()}></div>
{(!expand && <div><a style={{"color" : color}} onClick={() => setExpand(!expand)}><small>[ Read More ]</small></a></div>)}
  </>);

}

export default Expand;