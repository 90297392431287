import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Row, Col, Space, Divider, Typography, Button, Spin } from "antd";
import { parseAuthorsPlain, parseSubjectsPlain } from "../../utils/Utils";
import moment from "moment";
import { PrinterOutlined } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";
import nocover from "../../media/no-cover.png";
import { useSession } from "../../utils/Session";
export default function ListPrint(props) {

    const [session, setSession] = useSession();
    const { list_id = "" } = useParams();
    const [imagesLoaded, setImagesLoaded] = useState(0);
    const [isPrintEnabled, setIsPrintEnabled] = useState(false);


    useEffect(() => {
        // Remove dark mode
        document.body.classList.remove('dark');
        return () => {
            document.body.classList.remove('dark');
        };
    }, []);


    const [results, setResults] = useState(
        {
            rows: []
        }
    );

    const [list, setList] = useState({
        name: "",
        isbns_count: 0,
        owner_name: ""
    })


    const [loading, setLoading] = useState(true);
    const [error, setErrror] = useState(false);


    useEffect(() => {
        // Enable print button once all images have loaded
        const totalImages = results.rows.length;
        if (imagesLoaded === totalImages && totalImages > 0) {
            setIsPrintEnabled(true);
        }
    }, [imagesLoaded, results.rows.length]);

    const handleImageLoad = () => {
        setImagesLoaded(prevCount => prevCount + 1);
    };


    const getList = () => {

        document.title = "Print Preview";

        apiCall("titlelist/getItems", { list_id: list_id, limit: 500 }, (_status, _result) => {
            if (_status) {
                setResults(_result)
            } else {
                setErrror(_result.error);
            }
        })

        apiCall("titlelist/get", { list_id: list_id }, (_status, _result) => {
            if (_status) {
                setList(_result);
            }
        })
    }

    useEffect(getList, []);




    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }


    const drawRow = (_item) => {


        const {
            title = "",
            authors = [],
            isbn = "",
            eisbn = "",
            publisher = "",
            binding = "",
            release_date = "",
            series = "",
            subjects = [],
            price = ""
        } = _item;


        return (
            <div style={{ "width": "295px", "height": "225px", "overflow": "hidden" }}>

                <Row gutter={15} key={isbn}>
                    <Col flex={"95px"}>
                        <Space direction="vertical">
                            <img onLoad={handleImageLoad} className="shadow-large" style={{ "maxWidth": "95px" }} onError={(e) => e.target.src = "/no-cover.png"} src={"https://bookmanager.com/i/m?b=" + eisbn} />
                        </Space>
                    </Col>
                    <Col flex={"200px"}>
                        <div style={{ "lineHeight": "14px" }}><strong>{title}</strong></div>
                        <div style={{ "marginTop": "-5px" }}><small>{parseAuthorsPlain(authors)}</small></div>
                        <div style={{ "marginTop": "-10px" }}><small><em>{binding}</em></small></div>
                        <div className="shim" />
                        {((series) && <div style={{ "marginTop": "-5px" }}><small><em>Series: </em>{series}</small></div>)}
                        <div style={{ "marginTop": "-5px", "lineHeight": "12px" }}><small>{parseSubjectsPlain(subjects)}</small></div>
                        <div className="shim" />
                        <div style={{ "marginTop": "-5px" }}><small><em>ISBN: </em>{isbn}</small></div>
                        <div style={{ "marginTop": "-5px" }}><small><em>Published: </em>{moment(release_date * 1000).format("MMM Do, YYYY")}</small></div>
                        <div style={{ "marginTop": "-5px" }}><small><strong>${price}</strong></small></div>




                    </Col>
                </Row>
                <Divider dashed />
            </div>
        )
    }

    if (error) {
        return (<div style={{ "margin": "0 auto", "width": "350px", "marginTop": "calc(20vh)" }}><Alert type="error" description={error} /></div>)
    }


    const printPage = () => {
        window.print();
    }


    return (<>
        <Spin tip={<>Loading Images {imagesLoaded} of {results.rows.length}</>} spinning={!isPrintEnabled}>
            <div id="content">
                <div id="print" style={{ "padding": "0px 20px" }}>

                    <div style={{ "height": "250px", "overflow": "hidden" }}>
                        <div className="shim" style={{ "height": "20px" }} />
                        <div style={{ "float": "right", "padding": "20px" }}><Button disabled={!isPrintEnabled} className="no-print" onClick={() => printPage()} icon={<PrinterOutlined />} type="primary">Print</Button></div>
                        <Typography.Title level={2}>{session.store_name}</Typography.Title>
                        <div style={{ "marginTop": "-10px" }}>
                            <div><strong>{list.name}</strong> </div>
                            <div>{(list.owner_name && <>Managed by: {list.owner_name} {((list.isbns_count) && <> | <em>({Math.min(list.isbns_count, 500)} titles)</em></>)}</>)}</div>
                            {(results.purchase_deadline && <><div>Purchase deadline : <strong>{moment(results.purchase_deadline * 1000).format("MMM Do, YYYY")}</strong></div></>)}
                            <div>&nbsp;</div>

                        </div>
                        <Divider />
                    </div>
                    <div className="grgrid">
                        {results.rows.map(item => { return drawRow(item) })}
                    </div>
                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                </div>
            </div>
        </Spin>
    </>
    )
}