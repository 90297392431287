import React, { useEffect, useState } from "react";
import { Layout, Form, Row, Col, DatePicker, Space, Radio, message, Card, Result, Button, Checkbox, Divider, Tooltip, Select, Modal } from "antd";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import moment from "moment";
import { apiCall, apiDownload } from "../../utils/Api";
import AnalyticsFilters from "../analytics/AnalyticsFilters";
import FormItem from "antd/lib/form/FormItem";
import DropMenu from "../../components/DropMenu";
import AnaylticsGroup from "../analytics/AnalyticsGroup";
import conditional from "../../utils/conditional";
import Paginate from "../../components/Paginate";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fromUriHash, pathToObj, pathToObject } from "../../utils/Utils";
import { WarningOutlined, FileExcelOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";
import { useSession } from "../../utils/Session";
export default function AnalyticsPage() {


    const history = useHistory();
    const [session, setSession] = useSession();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 50, offset: 0 });
    const [change, setChange] = useState(false);
    const [resetScale, setResetScale] = useState(false)

    // Default range is start-of month
    const browse_filters = pathToObject(location.pathname);


    const initFilters = {
        start_date: moment().add(-1, "day").startOf("month").startOf("day").unix(),
        end_date: moment().add(-1, "day").endOf("day").unix(),
        show_which: "dollars",
        year_alignment: "weekday",
        period_spacing: "",
        date_period: "Month to date (Calendar month)",
        sort_by: "sandr_total",
        browse_filters: ""
    }
    const [filters, setFilters] = useState({ ...initFilters, "browse_filters": browse_filters.v });

    const [grouping, setGroup] = useState("");

    // overide for setting group to unset period spacing...
    const setGrouping = (_g) => {
        setGroup(_g);
        setFilters({ ...filters, "period_spacing": "" })
    }
    // Separated (so state changes don't trigger updates)

    const initIncludes = {
        sales: true,
        inventory: true,
        receiving_returns: true,
        details: true
    }

    const [includes, setIncludes] = useState(initIncludes);



    // Add an extra day to the count (startOf, endOf doesn't inc the day count)
    const range = moment(filters.end_date * 1000).endOf("day").diff(moment(filters.start_date * 1000).add(-1, "day").startOf("day"), "days");

    // Show yesterday, today in leau of date
    const customFormat = (value) => {
        let _diff = moment().startOf("day").diff(value.startOf("day"), "days");
        if (_diff === 1) { return "Yesterday"; }
        if (_diff === 1) { return "Today"; }
        return value.format("ddd MMMD-YY");
    }

    // Dropdown presets (pre calculated ranges)
    const presets = [
        { label: "1 Day", value: 0 },
        { label: "7 Days", value: 6 },
        { label: "30 Days", value: 29 },
        { label: "60 Days", value: 59 },
        { label: "90 Days", value: 89 },
        { label: "180 Days", value: 179 },
        { label: "365 Days", value: 364 },
        { label: "Month to date (Calendar month)", value: "Month to date (Calendar month)" },
        { label: "Quarter to date (Calendar quarter)", value: "Quarter to date (Calendar quarter)" },
        { label: "Year to date (Calendar year)", value: "Year to date (Calendar year)" },
    ]



    const drawPreset = (_str) => {
        // Check if the first character is a number
        if (!isNaN(_str.charAt(0))) {
            return "";
        }
        // Remove text within brackets and the brackets
        const resultString = _str.replace(/\([^)]*\)/g, '');

        if (resultString) {
            return <>(<em>{resultString}</em>)</>
        } else {
            return ""
        }

    }

    const setPreset = (_preset) => {


        let sd;
        let date_period;
        // weird logic 
        switch (_preset.label) {
            case "Month to date (Calendar month)":
                date_period = _preset.label;
                sd = moment(filters.end_date * 1000).diff(moment(filters.end_date * 1000).startOf("month"), "days");
                break;

            case "Quarter to date (Calendar quarter)":
                date_period = _preset.label;
                sd = moment(filters.end_date * 1000).diff(moment(filters.end_date * 1000).startOf("quarter"), "days")
                break;

            case "Year to date (Calendar year)":
                date_period = _preset.label;
                sd = moment(filters.end_date * 1000).diff(moment(filters.end_date * 1000).startOf("year"), "days")
                break;

            default:
                date_period = ""
                sd = _preset.value
                break;
        }

        setFilters({ ...filters, "date_period": date_period, "start_date": moment(filters.end_date * 1000).add(-sd, "days").startOf("day").unix() })
    }

    const [searchFilters, setSearchFilters] = useState({})

    const [results, setResults] = useState({
        headers: {},
        turnreport: {},
        results: {
            row_count: 0,
            total_count: 0,
            rows: []
        },
        datstock_timestamp: moment().unix()
    })

    const parseSearchFilters = (_f) => {

        let ret = { ..._f };

        Object.keys(ret).map(key => {
            let arr = ret[key];
            let _obj = {
                inc: [],
                exc: []
            }
            arr.forEach(element => {
                if (element.substr(0, 1) === "-") {
                    // add to exclude
                    _obj.exc.push(element.substr(1, element.length));
                } else {
                    // add to include
                    _obj.inc.push(element);
                }
            });

            if (_obj.exc.length > 0 || _obj.inc.length > 0) {
                ret[key] = _obj;
            } else {
                delete ret[key];
            }

        })

        return ret;

    }

    const getIterations = (_days) => {

        if (_days < 91) {
            return 30;
        }
        if (_days < 182) {
            return 24;
        }
        if (_days < 365) {
            return 12;
        }
        if (_days >= 365) {
            return 6;
        }

    }


    const exportToExcel = () => {
        getAnalytics(searchFilters, grouping, filters, 0, true);
    }


    const getAnalytics = (_sf = searchFilters, _grping = grouping, _fltrs = filters, _offset = 0, _export_excel = false) => {


        if (_sf !== searchFilters || _grping !== grouping || _fltrs !== filters) {
            // search stuff changed, reset offset
            _offset = 0;
        } else {
            _offset = paginate.offset;
        }

        let obj = {}
        obj.start_date = moment(_fltrs.start_date * 1000).format("YYYY-MM-DD");
        obj.end_date = moment(_fltrs.end_date * 1000).format("YYYY-MM-DD");
        obj.date_period = _fltrs.date_period;
        obj.group_by = JSON.stringify(parseSearchFilters(_sf));
        obj.grouping = (_grping) ? JSON.stringify([_grping]) : JSON.stringify([]); // separate rows for field or amalgamate is empty array


        obj.year_alignment = _fltrs.year_alignment;


        // For some reason labels are incorrect if you pass consecutive period spacing - select periods -> select class
        if (_grping) {
            obj.period_spacing = ""
        } else {
            obj.period_spacing = _fltrs.period_spacing; //consecutive, yearly, weekly
        }


        if (_fltrs.browse_filters) {
            obj.browse_filters = fromUriHash(_fltrs.browse_filters);
        }
        obj.sort_by = _fltrs.sort_by;


        // If there is period spacing or date_period, we don't use the start date , but use the date period strings instead
        if (obj.period_spacing || obj.date_period) {
            delete obj.start_date;

            // If there is period_spacing we want to show interative rows 
            if (obj.period_spacing === "weekly") {
                obj.date_period = getIterations(range) + " " + range + "day";
            }

            if (obj.period_spacing === "consecutive") {

                switch (obj.date_period) {

                    case "Month to date (Calendar month)":
                        obj.date_period = "30 Month";
                        break;

                    case "Quarter to date (Calendar quarter)":
                        obj.date_period = "30 Quarter";
                        break;

                    case "Year to date (Calendar year)":
                        obj.date_period = "6 Year";
                        break;

                    default:
                        obj.date_period = getIterations(range) + " " + range + "day";
                        break;
                }

            }

            if (obj.period_spacing === "yearly") {
                obj.date_period = "6 " + range + "day";
            }

        }

        obj.limit = paginate.pagesize;
        obj.offset = _offset;



        if (_export_excel) {
            apiDownload(obj, "reports/analytics/turnReportToExcel", true);
            return;
        }

        setLoading(true);
        apiCall("reports/analytics/turnReport", obj, (_status, _result) => {
            if (_status) {

                setChange(!change);
                if (!_offset) {
                    setPaginate({ ...paginate, offset: _offset, current: 1 });
                }
                setResults(_result);
            } else {
                message.error(_result.error);
            }
            setLoading(false);
        })
    }

    useEffect(getAnalytics, [filters.start_date, filters.end_date, filters.year_alignment, filters.date_period, filters.sort_by, paginate.offset, paginate.pagesize]);


    // const updateOffset = () => {
    //     getAnalytics(searchFilters, grouping, filters, paginate.offset)
    // }

    // useEffect(updateOffset, [paginate.offset])

    // fetch the data based on the filter (just makes things easier...)
    const getSet = (_data, _key, _prev = false, sw = filters.show_which) => {
        let _set = "price";
        switch (sw) {
            case "dollars":
                _set = "dollars";
                break;
            case "unit_copies":
                _set = "units";
                break;
            case "unique_isbns":
                _set = "titles";
                break;
        }
        if (_prev) { return _data.prev[_set][_key]; }
        return _data[_set][_key];
    }

    const getHigh = () => {

        let ret = Math.max(...results.results.rows.map(i => {
            let sales_total = getSet(i, "sales_total");
            let sandr_total = getSet(i, "rcvd");
            let onhand_total = getSet(i, "onhand_total");
            onhand_total = (onhand_total > 0) ? onhand_total / (365 / range) : 0;
            return Math.max(0, sales_total, sandr_total, onhand_total);
        }))

        return (ret > 0) ? ret : 0;

    }


    const resetAllButton = () => {

        return (
            <Button
                disabled={(JSON.stringify(includes) === JSON.stringify(initIncludes) && grouping === "" && JSON.stringify({}) === JSON.stringify(searchFilters) && JSON.stringify(initFilters) === JSON.stringify(filters))}
                onClick={() => {
                    setGrouping("");
                    setSearchFilters({});
                    setFilters({ ...initFilters, "browse_filters": "" });
                    setIncludes(initIncludes)
                    getAnalytics({}, grouping, { ...initFilters, "browse_filters": "" });
                    history.push("/reports/analytics");
                }} type="link" size="small"><small>Reset</small></Button>
        )
    }



    const drawSort = () => {




        let sortOptions = [
            { label: "None", value: "none" },
            { label: "Sales Change", value: "sandr_change" },
            { label: "Sales", value: "sandr_total" },
            { label: "Profit", value: "profit" },
            { label: "Turns", value: "turns" },
            { label: "Onhand", value: "onhand_total" },
            { label: "Onhand - Older", value: "onhand_mid" },
            { label: "Onhand - Old", value: "onhand_old" },
            { label: "Received", value: "rcvd" },
            { label: "Returned", value: "rtrn" },
            { label: "Invoice", value: "invoice" },
        ]



        return (
            <Select onChange={(e) => setFilters({ ...filters, "sort_by": e })} defaultValue={filters.sort_by} style={{ "width": "130px" }} size="small">
                {sortOptions.map(item => {
                    return (<Select.Option key={item.value} value={item.value}><small>{item.label}</small></Select.Option>)
                })}
            </Select>
        )
    }

    const resetButton = () => {

        return (
            <div>
                <div className="shim" />
                <div className="shim" />
                <div style={{ "display": "flex", "justifyContent": "space-between" }}>

                    {(!(grouping === "" && JSON.stringify({}) === JSON.stringify(searchFilters) && (results.results.row_count < 2)) &&
                        <Button
                            disabled={(grouping === "" && JSON.stringify({}) === JSON.stringify(searchFilters))}
                            onClick={() => {
                                setGrouping("");
                                setSearchFilters({});
                                getAnalytics({}, "");
                            }} type="primary" danger size="small"><small>Clear all filters</small>
                        </Button>
                    )}


                    {/* Only show sort if there are Multiple rows */}
                    {((results.results.row_count > 1) &&
                        <Space>
                            <Checkbox checked={!resetScale} onClick={() => setResetScale(!resetScale)} size="small"><small>Relative scale</small></Checkbox>

                            <div><small>Sort:</small></div>
                            {drawSort()}
                        </Space>
                    )}

                </div>
            </div>
        )
    }


    const openWarning = (_auto = true) => {

        if (!browse_filters.v) {
            return;
        }

        let remember = Cookies.get("analytics_warning");
        if (_auto && remember === "true") {
            return;
        }

        Modal.warn({
            icon: <></>,
            width: "600px",
            title: "Analytics accuracy warning",
            content: <>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <strong>Be aware that previous period comparisons may be misleading when ISBNs are loaded from Browse.</strong>
                <p>Browse Analytics is designed to draw on the activity from the <strong>specific set of ISBNs</strong> you have selected. If you choose to click on the option to compare previous periods, that comparison is still going to draw data from the exact same set of ISBNs. It is possible to run into inaccuracies here, because suppliers are always adding new ISBNs, changing the ISBNs of current product, or dropping old ISBNs.</p>
                <p><em><strong>For example:</strong> If the list of ISBNs you are looking at are all new releases, then the sales in the previous period will be zero because those ISBNs did not exist in the previous period.</em></p>
                <p><strong>For this reason, it is important to not gauge changes in the volume of sales and/or inventory by using Analytics with specific lists of ISBNs.</strong> The regular Analytics compares previous periods of data that has been selected via dynamic filters, and not a finite set of ISBNs. Because of that, previous period comparison within regular Analytics can contain ISBNs that are not part of the current period, and are therefore a more accurate representations of changes.</p>
                <div className="shim" />
                {(_auto && <>
                    <div>
                        <Checkbox onChange={(e) => { let v = e.target.checked.toString(); Cookies.set("analytics_warning", v, { expires: 365 }); }}>
                            <small>Don't show this message again</small>
                        </Checkbox>
                    </div>
                </>)}
            </>
        })
    }

    useEffect(openWarning, [])

    const BrowseWarning = () => {

        return (<WarningOutlined onClick={() => openWarning(false)} style={{ "color": "#ed2a45", "fontSize": "20px", "marginRight": "4px", "cursor": "pointer" }} />)

    }

    const disabledEnding = (current) => {
        return current && current < moment(filters.start_date * 1000).add(-1, "day").endOf('day');
    };

    const disabledBeginning = (current) => {
        return current && current > moment(filters.end_date * 1000).endOf('day');
    };


    const getPresetToDate = (_date, _preset) => {

        switch (_preset) {
            case "Month to date (Calendar month)":
                const startOfMonth = moment(_date).startOf('month');
                return _date.diff(startOfMonth, 'days');
                break;

            case "Quarter to date (Calendar quarter)":
                const startOfQuarter = moment(_date).startOf('quarter');
                return _date.diff(startOfQuarter, 'days');
                break;

            case "Year to date (Calendar year)":
                const startOfYear = moment(_date).startOf('year');
                return _date.diff(startOfQuarter, 'days');
                break;

            default:
                return range - 1;
                break;
        }
    }



    const stripTextBetweenParentheses = (inputString) => {
        // strip the content between the parens
        const regex = /\([^)]*\)/g;

        let str = inputString.replace(regex, '').trim();
        if (!str) {
            return "";
        }
        return "(" + str + ")"


    };



    const onPanelChange = (_e, _f) => {

        switch (filters.date_period) {
            case "Month to date (Calendar month)":
                setFilters({ ...filters, "end_date": _e.startOf("day").unix(), "start_date" : _e.startOf("month").startOf("day").unix() })
            return;
                break;

            case "Quarter to date (Calendar quarter)":
                setFilters({ ...filters, "end_date": _e.startOf("day").unix(), "start_date" : _e.startOf("quarter").startOf("day").unix() })
                return;
                break;

            case "Year to date (Calendar year)":
                setFilters({ ...filters, "end_date": _e.startOf("day").unix(), "start_date" : _e.startOf("year").startOf("day").unix() })
                return;
                break;

            default:
                setFilters({ ...filters, "end_date": _e.startOf("day").unix(), "start_date" : _e.add(-(range-1), "days").startOf("day").unix()  })
                return;
                break;
        }
    }


    const drawFilters = () => {

        return (
            <Form layout="vertical" size="small">
                <Space size={3}>

                    <FormItem label={<small>Ending <span>({range.toString()} days)</span></small>}>
                        <DatePicker onPanelChange={(e, f) => onPanelChange(e, f)} format={customFormat} onChange={(mom) => {
                            console.log(disabledEnding(mom));
                            // If ending is less that starting, shift the start-date by the range

                            if (disabledEnding(mom)) {

                                let _days = getPresetToDate(mom, filters.date_period);
                                setFilters({ ...filters, "end_date": mom.startOf("day").unix(), "start_date": mom.add(-_days, "days").endOf("day").unix() })
                            } else {

                                // Only shift beginning date if date_period is not month, quarter, year
                                if(filters.date_period){
                                    setFilters({ ...filters, "end_date": mom.startOf("day").unix() })
                                }
                                else {
                                    setFilters({ ...filters, "end_date": mom.startOf("day").unix(), "start_date": mom.add(-(range-1), "days").endOf("day").unix() })
                                }
                                }
                               



                        }} value={moment(filters.end_date * 1000)} />
                    </FormItem>
                    <div>&nbsp;</div>
                    <FormItem value={moment(filters.start_date * 1000)} label={<small>Beginning <span style={{ "fontSize": "9px" }}>{stripTextBetweenParentheses(filters.date_period)}</span></small>}>
                        <DropMenu custom hover title={
                            <DatePicker disabledDate={disabledBeginning} style={{ "fontSize": "10px" }} format={customFormat} onChange={(mom) => setFilters({ ...filters, "date_period": "", "start_date": mom.endOf("day").unix() })} value={moment(filters.start_date * 1000)} />
                        }>
                            <Card style={{ "width": "220px", "marginTop": "2px" }} className="custom-shadow">
                                {presets.map((item, index) => {
                                    return (<div key={index} onClick={() => setPreset(item)}><a><small>{item.label}</small></a></div>)
                                })}
                            </Card>
                        </DropMenu>
                    </FormItem>
                    <Divider dashed style={{ "height": "60px", "marginBottom": "20px" }} type="vertical" />
                    <Form.Item label={<small>Graph using:</small>} >

                        <Space size={0}>
                            <Radio onClick={() => setFilters({ ...filters, "show_which": "dollars" })} checked={(filters.show_which === "dollars")} ><small>Dollars</small></Radio>
                            <Radio onClick={() => setFilters({ ...filters, "show_which": "unit_copies" })} checked={(filters.show_which === "unit_copies")} ><small>Units</small></Radio>
                            <Radio onClick={() => setFilters({ ...filters, "show_which": "unique_isbns" })} checked={(filters.show_which === "unique_isbns")} ><small>ISBNs</small></Radio>
                        </Space>

                    </Form.Item>
                    <Divider dashed style={{ "height": "60px", "marginBottom": "20px" }} type="vertical" />
                    <Form.Item label={<small>Graph to include:</small>}>
                        {/* ant-radio-group-solid */}
                        <Space size={0}>
                            <Checkbox type="primary" onClick={() => setIncludes({ ...includes, "sales": !includes.sales })} checked={(includes.sales)} ><small>Sales </small></Checkbox>
                            <Checkbox onClick={() => setIncludes({ ...includes, "inventory": !includes.inventory })} checked={(includes.inventory)} ><small>Onhand</small></Checkbox>
                            <Checkbox onClick={() => setIncludes({ ...includes, "receiving_returns": !includes.receiving_returns })} checked={(includes.receiving_returns)} ><small>Received</small></Checkbox>
                            <Checkbox onClick={() => setIncludes({ ...includes, "details": !includes.details })} checked={(includes.details)} ><small>Details</small></Checkbox>
                        </Space>
                    </Form.Item>
                    <Divider dashed style={{ "height": "60px", "marginBottom": "20px" }} type="vertical" />
                    <Form.Item label={<small>Show previous:</small>}>
                        <Space size={0}>
                            <Radio onClick={() => { setGrouping(""); setFilters({ ...filters, "period_spacing": "" }); getAnalytics(searchFilters, "", { ...filters, "period_spacing": "" }); }} checked={(filters.period_spacing === "")} ><small>None</small></Radio>
                            <Tooltip title={<small>Multiple graphs showing the same number of days immediately preceding each ending date.</small>}><Radio onClick={() => { setGrouping(""); setFilters({ ...filters, "period_spacing": "yearly" }); getAnalytics(searchFilters, "", { ...filters, "period_spacing": "yearly" }); }} checked={(filters.period_spacing === "yearly")} ><small>Years</small></Radio></Tooltip>
                            <Tooltip title={<small>Multiple graphs showing the same date range in the preceding year.</small>}><Radio onClick={() => { setGrouping(""); setFilters({ ...filters, "period_spacing": "consecutive" }); getAnalytics(searchFilters, "", { ...filters, "period_spacing": "consecutive" }); }} checked={(filters.period_spacing === "consecutive")} ><small>Periods</small></Radio></Tooltip>
                            <Tooltip title={<small>Multiple graphs showing the same days of the week in each previous week. <br />(e.g. A Friday through Sunday sales history, week by week)</small>}><Radio onClick={() => { setGrouping(""); setFilters({ ...filters, "period_spacing": "weekly" }); getAnalytics(searchFilters, "", { ...filters, "period_spacing": "weekly" }); }} checked={(filters.period_spacing === "weekly")} disabled={!(range < 8)} ><small>Weeks</small></Radio></Tooltip>
                        </Space>
                    </Form.Item>
                    <Divider dashed style={{ "height": "60px", "marginBottom": "20px" }} type="vertical" />
                    <Form.Item label={<small>Using same day of:</small>}>
                        <Space size={0}>
                            <Tooltip title={<small>The date range for each previous period can be either the same day(s) of the month or the same weekday(s)</small>}><Radio onClick={() => setFilters({ ...filters, "year_alignment": "monthday" })} checked={(filters.year_alignment === "monthday")} ><small>Month </small></Radio></Tooltip>
                            <Tooltip title={<small>The date range for each previous period can be either the same day(s) of the month or the same weekday(s)</small>}><Radio onClick={() => setFilters({ ...filters, "year_alignment": "weekday" })} checked={(filters.year_alignment === "weekday")} ><small>Week</small></Radio></Tooltip>
                        </Space>
                    </Form.Item>
                </Space>
            </Form>
        )
    }

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title={
                        <>
                            <Row>
                                <Col flex="250px">
                                    {(browse_filters.v) ? <><BrowseWarning /><Tooltip title={<small>Viewing analytics on titles from browse filters - Click reset to remove browse filters</small>}> Browse Analytics</Tooltip></> : "Analytics"}
                                    {resetAllButton()}
                                </Col>
                                <Col flex="auto">
                                    <div style={{ "textAlign": "center", }}>
                                        <div style={{ "display": "inline", "fontSize": "12px", "fontWeight": "normal", "border": "1px solid #eee", "padding": "5px 10px", "marginTop": "-5px" }}>
                                            <strong> As of {moment(results.datstock_timestamp * 1000).format("h:mma dddd MMM Do, YYYY")}. </strong> <em>Updated via your most recent <a onClick={() => window.open("/reports/backups", "_blank")}>backup.</a></em>
                                        </div>
                                    </div>
                                </Col>
                                <Col flex="250px">
                                    <div style={{ "textAlign": "right" }}>

                                        {((session.store_id === 182320) &&
                                            <Button type="primary" onClick={() => exportToExcel()} size="small"><small> <FileExcelOutlined /> Export to Excel</small></Button>
                                        )}

                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                    filters={<>
                        {drawFilters()}
                        <Divider dashed style={{ "marginTop": "-10px" }} />
                        <AnalyticsFilters resetButton={resetButton} row_count={results.results.rows.length} getAnalytics={getAnalytics} grouping={grouping} setGrouping={setGrouping} searchFilters={searchFilters} setSearchFilters={setSearchFilters} headers={results.turnreport} />
                        <div className="shim" />
                    </>}
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <conditional.true value={(!loading && results.results.rows.length < 1)}>
                        <div style={{ "padding": "20px" }}>
                            <Card>
                                <Result
                                    status="warning"
                                    title="No data available."
                                />
                            </Card>
                        </div>
                    </conditional.true>

                    <conditional.true value={(results.results.row_count > paginate.pagesize)}>
                        <Paginate pageSizeOptions={[20, 50, 100]} showSizeChanger={true} paginate={paginate} setPaginate={setPaginate} count={results.results.row_count} />
                    </conditional.true>

                    <div style={{ "minHeight": "calc(100vh - 250px)", "padding": "20px" }}>
                        {results.results.rows.map((item, index) => {
                            return <AnaylticsGroup scale={resetScale} change={change} index={index} parseSearchFilters={parseSearchFilters} searchFilters={searchFilters} grouping={grouping} headers={results.turnreport} loading={loading} includes={includes} row_count={results.results.row_count} range={range} getSet={getSet} high={getHigh()} filters={filters} setFilters={setFilters} data={item} />
                        })}
                    </div>

                    <conditional.true value={(results.results.row_count > paginate.pagesize)}>
                        <div className="bc" style={{ "borderTop": "1px solid" }}>
                            <Paginate pageSizeOptions={[20, 50, 100]} showSizeChanger={true} paginate={paginate} setPaginate={setPaginate} count={results.results.row_count} />
                        </div>
                    </conditional.true>


                </Layout.Content>
            </Layout>
        </>
    )
}