import { Divider, Space, Statistic } from "antd";
import React from "react";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { getPercentageIncrease } from "../../utils/Utils";
import moment from "moment";
export default function SalesSummary(props) {

    const { sales = [], avgsales = [], title = "Summary" } = props;

    const {
        then = 0,
        now = 0,
        date = ""
    } = sales[sales.length - 1] || {};

    const avg = avgsales[avgsales.length - 1] || { now: 0, then: 0, date: "" };

    const diff = getPercentageIncrease(now, then, 1);
    const avg_diff = getPercentageIncrease(avg.now, avg.then, 1);

    return (
        <div>
            <strong style={{ "fontSize": "13px" }}>{title}</strong>
            <Divider dashed style={{ "margin": "10px 0px" }} />
            <div>
                <Space size={50} align="center">

                    <Statistic
                        title={<>Week ending on {moment(date).format("MMM, Do, YYYY")}</>}
                        value={now}
                        precision={0}
                        prefix="$"
                    />

                    <Statistic
                        title="YOY % change"
                        value={diff}
                        precision={1}
                        valueStyle={{ color: (diff < 0) ? '#e33535' : '#3f8600' }}
                        prefix={(diff < 0) ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                        suffix="%"
                    />

                    <Statistic
                        title="Other stores average YOY"
                        value={avg_diff}
                        precision={1}
                        valueStyle={{ color: (avg_diff < 0) ? '#e33535' : '#3f8600' }}
                        prefix={(avg_diff < 0) ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                        suffix="%"
                    />

                </Space>
            </div>
            <br />
        </div>
    )

}