import { Table } from "antd";
import React, { useRef, useCallback, useState } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MenuOutlined } from '@ant-design/icons';
export default function DragTable(props) {


  const {size = "small", rowKey="", className = "", style = {}, scroll = {}, pagination = false, columns = [], data = [], setData = () => {}, callback = () => {} } = props; 
  
  const type = 'DraggableBodyRow';

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <tr
        ref={ref}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{
          ...style,
        }}
        {...restProps}
      />
    );
  };


  const updateData = (_d, _h) => {

    let _arr = [...data];
    let _c; //clone
    if (_d < _h) {
      _c = _arr[_d];
      _arr.splice((_h + 1), 0, _c);
      _arr.splice(_d, 1);
    }

    if (_d > _h) {
      _c = _arr[_d];
      _arr.splice(_d, 1);
      _arr.splice((_h), 0, _c);
    }

    setData(_arr);

    return _arr; 


  }

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];

      let _arr = updateData(dragIndex, hoverIndex);

      if(JSON.stringify(_arr) !== JSON.stringify(data)){  
        callback(_arr); 
      }
      
      //   setData(
      //     update(data, {
      //       $splice: [
      //         [dragIndex, 1],
      //         [hoverIndex, 0, dragRow],
      //       ],
      //     }),
      //   );
    },
    [data],
  );

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  return (
    <>


      <DndProvider backend={HTML5Backend}>
        <Table
          size={size}
          style={style}
          rowKey={rowKey}
          pagination={pagination}
          className={className}
          scroll={scroll}
          columns={columns}
          dataSource={data}
          components={components}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow,
            };
            return attr;
          }}
        />
      </DndProvider>

    </>
  );
}