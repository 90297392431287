import React, { useState } from "react";
import { Table, Row, Col, Tooltip } from "antd";
import { addKeys, ucfirst } from "../utils/Utils";
import moment from "moment";
import SalesActivity from "./SalesActivity";
import conditional from "../tools/conditional";
export default function ItemSales2(props) {



    const { condition = "", isbn = "", data = {}, summary = false, row = false } = props;

    const [showActivity, setShowActivity] = useState(false);


    const {
        draw_months = 14,
        is_forthcoming = false,
        extras = {
            total_onhand: 0,
            minimum_onhand: 0,
            backroom: 0,
            held: 0,
            available_onhand: 0,
            total_onorder: 0,
            pending_return: 0,
            special_order: 0,
            pending_onorder: 0
        },
        rows = []
    } = data;


    const abriviateClass = (_class) => {

        let ret = "";
        switch (_class) {
            case "adjusted": ret = "Adj"; break;
            case "returns": ret = "Retn"; break;
            case "received": ret = "Rcvd"; break;
            case "sold": ret = "Sold"; break;
            case "total_sold": ret = "Ttl"; break;
            case "peer": ret = "Peer"; break;
        }

        return ret;
    }




    const abriviateExtra = (_key) => {
        let ret = "";
        switch (_key) {
            case "total_onhand": ret = "TtlOH"; break;
            case "minimum_onhand": ret = "MinOH"; break;
            case "backroom": ret = "BackRm"; break;
            case "held": ret = "Held"; break;
            case "pending_return": ret = "PndRtn"; break;
            case "special_order": ret = "S/O"; break;
            case "pending_onorder": ret = "PndOO"; break;
        }
        return ret;
    }

    const parseData = () => {

        let is_multistore = false;
        if (window.sitesettings.stores.length > 1) {
            is_multistore = true;
        }
        let _store = 1;



        let _return = rows.map(item => {


            let _ret =
            {
                label: (item.class === "sold" && is_multistore) ? "  #" + item.store_number : abriviateClass(item.class),
                ttl: (item.class === "peer") ? item.total_stores : item.total,
                class: item.class,
                wk: item.week,
                oh: item.onhand,
                oo: item.onorder,
                ohl: item.onhandlocs,
                ool: item.onorderlocs
            }

            if (item.class === "sold") {
                _store++;
            }

            item.months.forEach((item, index) => {
                _ret["s" + (index + 1)] = (item) ? item : "";
            })

            return _ret;
        })

        if (row) {
            _return = _return.filter(itm => itm.class === "received" || itm.class === "peer" || itm.class === "sold" || itm.class === "total_sold");
            if (is_multistore) {
                return _return.filter(itm => itm.class !== "sold");
            } else {
                return _return.filter(itm => itm.class !== "total_sold");
            }

        }
        return _return;
    }



    const getSummary = () => {
        let _summary = {}
        _summary.soldOH = [];
        _summary.soldOO = [];
        _summary.peerOH = 0;
        _summary.peerOO = 0;
        _summary.peerOHL = 0;
        _summary.peerOOL = 0;

        data.rows.forEach(item => {

            if (item.class === "total_sold") {
                _summary.soldOH.push(item.onhand)
                _summary.soldOO.push(item.onorder)
            }

            if (item.class === "peer") {
                _summary.peerOH = item.onhand;
                _summary.peerOO = item.onorder;
                _summary.peerOHL = item.onhandlocs;
                _summary.peerOOL = item.onorderlocs;
            }
        })

        return _summary;
    }


    const checkBold = (e, f) => {

        if (!e) {
            return "";
        }
        if (f.label !== "Adj" && f.label !== "Retn" && f.label !== "Peer") {
            return (<strong>{e}</strong>);
        } else {
            return e;
        }

    }


    const getClass = (_r) => { return ("rowHover row" + _r.label) }

    const columns = [
        {
            title: <small></small>, width: "28px", className: "cellBorder", dataIndex: 'label', key: 'label', render: (e) => {
                return <div style={{ "textAlign": "right" }}>{e.substr(0, 4)}</div>
            }
        },
        {
            title: <small className="c">Ttl</small>, width: "25px", className: (row) ? "cellBorder" : "cellBorder", dataIndex: 'ttl', key: 'ttl', render: (e, f) => { return (e) ? e : "" }
        },
        { title: <small>{moment().startOf("month").add(-13, "month").format("MMM")}</small>, dataIndex: 's1', key: 's1', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-12, "month").format("MMM")}</small>, dataIndex: 's2', key: 's2', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-11, "month").format("MMM")}</small>, dataIndex: 's3', key: 's3', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-10, "month").format("MMM")}</small>, dataIndex: 's4', key: 's4', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-9, "month").format("MMM")}</small>, dataIndex: 's5', key: 's5', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-8, "month").format("MMM")}</small>, dataIndex: 's6', key: 's6', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-7, "month").format("MMM")}</small>, dataIndex: 's7', key: 's7', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-6, "month").format("MMM")}</small>, dataIndex: 's8', key: 's8', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-5, "month").format("MMM")}</small>, dataIndex: 's9', key: 's9', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-4, "month").format("MMM")}</small>, dataIndex: 's10', key: 's10', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-3, "month").format("MMM")}</small>, dataIndex: 's11', key: 's11', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-2, "month").format("MMM")}</small>, dataIndex: 's12', key: 's12', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-1, "month").format("MMM")}</small>, dataIndex: 's13', key: 's13', render: (e, f) => { return checkBold(e, f) } },
        { className: (row) ? "cellBorder" : "cellBorder", title: <small>{moment().startOf("month").format("MMM")}</small>, dataIndex: 's14', key: 's14', render: (e, f) => { return checkBold(e, f) } },
        {
            title: <small>Wk</small>,  width: "27px", dataIndex: 'wk', key: 'wk', render: (e, f) => {
                return (e) ? <em>{e}</em> : "";
            }
        },

        // {
        //     title: <small style={{ "padding": "0px 3px" }}>OH</small>, className: "cellBorder", dataIndex: 'oh', key: 'oh', render: (e, f) => {
        //         if (f.label === "Peer") {
        //             return <div style={{ "backgroundColor": "#fff", "color": "#A52A2A", "fontWeight": "500", "margin": "0 -2px" }}>{e}<sup>{f.ohl}</sup></div>
        //         }
        //         return <div style={{ "backgroundColor": "#78c042", "color": "#fff", "fontWeight": "500", "margin": "0 -2px" }}>{e}</div>
        //     }
        // },
        // {
        //     title: <small>OO</small>, dataIndex: 'oo', key: 'oo', render: (e, f) => {
        //         if (f.label === "Peer") {
        //             return <div style={{ "backgroundColor": "#fff", "color": "#A52A2A", "fontWeight": "500", "margin": "0 -2px" }}>{e}<sup>{f.ool}</sup></div>
        //         }
        //         return <div style={{ "backgroundColor": "#e77e21", "color": "#fff", "fontWeight": "500", "margin": "0 -2px" }}>{e}</div>
        //     }
        // },
    ]






    const drawCol = (_lbl, _val, _bg = "#fafafa", _color = "#212121", _border = true) => {
        if (!_val) {
            _val = <>&nbsp;</>;
            _bg = "#fafafa";
            _color = "#212121"
        }
        return (
            <>
                {(_lbl && <small style={{ "fontSize": "11px", "color": "#888", "fontStyle": "italic" }}>{_lbl}</small>)}
                <div className="bc" style={{ "backgroundColor": _bg, "color": _color, "fontWeight": "500", "borderRight": (_border) ? "1px solid " : "none" }}><small>{_val}</small></div>
            </>
        )
    }


    const drawSummary = () => {




        let _summary = {}
        _summary.soldOH = [];
        _summary.soldOO = [];
        _summary.peerOH = 0;
        _summary.peerOO = 0;
        _summary.peerOHL = 0;
        _summary.peerOOL = 0;

        let show_peer = false;
        rows.forEach(item => {

            if (item.class === "sold") {
                _summary.soldOH.push(item.onhand)
                _summary.soldOO.push(item.onorder)
            }

            if (item.class === "peer") {
                show_peer = true;
                _summary.peerOH = item.onhand;
                _summary.peerOO = item.onorder;
                _summary.peerOHL = item.onhandlocs;
                _summary.peerOOL = item.onorderlocs;
            }
        })

        const drawExtras = () => {


            return Object.keys(extras).filter(item => item !== "total_onhand").map((key, index) => {

                if (key === "minimum_onhand" && extras[key] > 0) {

                    if (extras.minimum_onhand > extras.available_onhand) {
                        return (<div key={index} className={(extras[key]) ? "bc extra-" + key : "bc"} style={{ "borderBottom": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "11px", "paddingRight": "2px", "color": "#f00" }}>{(extras[key] || "")}</small></div>)
                    }
                }

                if (key === "pending_onorder" && is_forthcoming) {
                    return (<div key={index} className={(extras[key]) ? "bc extra-" + key : "bc"} style={{ "borderBottom": "1px solid", "height": "18px", "overflow": "hidden"}}><small style={{ "fontSize": "11px", "paddingRight": "2px" }}>{(extras[key] || "")}</small></div>)
                }

                if (key !== "available_onhand" && key !== "total_onorder" && extras[key] > 0) {
                    return (<div key={index} className={(extras[key]) ? "bc extra-" + key : "bc"} style={{ "borderBottom": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "11px", "paddingRight": "2px" }}>{(extras[key] || "")}</small></div>)
                }
            })
        }

        const drawExtrasLabel = () => {
            return Object.keys(extras).filter(item => item !== "total_onhand").map((key, index) => {

                if (key === "minimum_onhand" && extras[key] > 0) {

                    if (extras.minimum_onhand > extras.available_onhand) {
                        return (<div key={index} className={(extras[key]) ? "bc extra-lbl-" + key : "bc"} style={{ "borderBottom": "1px solid", "borderRight": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "10px", "paddingRight": "2px", "fontStyle": "italic", "color": "#f00" }}>{abriviateExtra(key)}</small></div>)
                    }
                }

                if (key === "pending_onorder" && is_forthcoming) {
                    return (<div key={index} className={(extras[key]) ? "bc extra-lbl-" + key : "bc"} style={{ "borderBottom": "1px solid", "borderRight": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "10px", "paddingRight": "2px", "fontStyle": "italic", "color": "#007EF5" }}>{abriviateExtra(key)}</small></div>)
                }


                if (key !== "available_onhand" && key !== "total_onorder" && extras[key] > 0) {
                    return (<div key={index} className={(extras[key]) ? "bc extra-lbl-" + key : "bc"} style={{ "borderBottom": "1px solid", "borderRight": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "10px", "paddingRight": "2px", "fontStyle": "italic" }}>{abriviateExtra(key)}</small></div>)
                }
            })
        }


        return (
            <>
                <div onClick={() => props.setAct(!props.act)}>
                    <div className="bc" style={{ "border": "1px solid", "marginBottom": (window.sitesettings.stores.length > 1) ? "0px" : "0px" }}>

                        <Row style={{ "textAlign": "right", "fontSize": "12px", "lineHeight": "16px" }}>
                            <Col span={12}>
                                {drawExtrasLabel()}
                                <div className="bc" style={{ "borderRight": "1px solid", "borderBottom": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "11px", "color": "#3C8617", "fontStyle": "italic", "paddingRight": "2px" }}>aOH</small></div>
                                {_summary.soldOH.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="bc" style={{ "backgroundColor": "#3C8617", "color": "#fff", "fontSize": "14px", "fontWeight": "bold", "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px", "height": "18px", "overflow": "hidden" }}><small>{(item) ? item : ""}</small></div>
                                        </div>
                                    )
                                })}

                                {((window.sitesettings.stores.length > 1) && <> <div className="bc" style={{ "backgroundColor": "#3C8617", "color": "#fff", "fontSize": "14px", "fontWeight": "bold", "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px", "height": "18px", "overflow": "hidden" }}><small>{_summary.soldOH.reduce((partialSum, a) => partialSum + a, 0) || ""}</small></div></>)}

                                {(show_peer && <><div className="bc extra-peer" style={{"fontWeight": "500", "borderRight": "1px solid", "cursor": "pointer", "paddingRight": "2px" }}><Tooltip title={_summary.peerOHL + " stores currently have a combined " + _summary.peerOH + " copies of this title on hand"}><small>{_summary.peerOH}<sup> {_summary.peerOHL}</sup></small></Tooltip></div></>)}

                            </Col>
                            <Col span={12}>
                                {drawExtras()}
                                <div className="bc" style={{ "borderBottom": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "11px", "color": "#e77e21", "fontStyle": "italic", "paddingRight": "2px" }}>aOO</small></div>
                                {_summary.soldOO.map((item, index) => {
                                    return (
                                        <div key={index}>

                                            <div style={{ "backgroundColor": "#e77e21", "color": "#fff", "fontSize": "14px", "fontWeight": "bold", "textAlign": "right", "paddingRight": "2px", "height": "18px", "overflow": "hidden" }}><small>{(item) ? item : ""}</small></div>
                                        </div>
                                    )
                                })}

                                {((window.sitesettings.stores.length > 1) && <><div style={{ "backgroundColor": "#e77e21", "color": "#fff", "fontSize": "14px", "fontWeight": "bold", "textAlign": "right", "paddingRight": "2px", "height": "18px", "overflow": "hidden" }}><small>{_summary.soldOO.reduce((partialSum, a) => partialSum + a, 0) || ""}</small></div></>)}

                                {(show_peer && <><div className="extra-peer" style={{"fontWeight": "500", "borderRight": "none", "cursor": "pointer", "paddingRight": "2px" }}><Tooltip title={_summary.peerOOL + " stores currently have a combined " + _summary.peerOO + " copies of this title on order"}><small>{_summary.peerOO}<sup> {_summary.peerOOL}</sup></small></Tooltip></div></>)}
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )

    }









    if (row) {
        return (
            <>
                <Table
                    rowClassName={(record, index) => {
                        return getClass(record);
                    }}
                    size="small"
                   
                    onRow={(record, index) => {
                        return {
                            onClick: event => {
                                setShowActivity(!showActivity);
                            }
                        }
                    }}
                    className="miniHistory columnHeaders"
                    style={{ "marginRight": "1px", "borderTop": "none", "width": "0px" }}
                    dataSource={parseData()}
                    columns={(row) ? columns.slice(1, 2).concat(columns.slice(10, 17)) : columns}
                    pagination={false}
                />
            </>

        );
    }




    if (summary) {

        return drawSummary()
    }




    return (
        <>

            <Row align="bottom" gutter={2}>
                <Col flex="auto">
                    <div className="bc" style={{"borderLeft" : "5px solid"}}>
                    <Table
                        rowClassName={(record, index) => {
                            return getClass(record);
                        }}
                        size="small"

                        onRow={(record, index) => {
                            return {
                                onClick: event => {
                                    setShowActivity(!showActivity);
                                }
                            }
                        }}
                        className="itemHistory bc"
                        style={{ "marginRight": "1px", "borderTop": "1px solid", "marginTop" : "-1px" }}
                        dataSource={addKeys(parseData())}
                        columns={columns}
                        pagination={false}
                    />
                    </div>
                </Col>
                <Col flex="78px">

                    <div style={{"width" : "75px"}}>
                        <ItemSales2 act={showActivity} setAct={setShowActivity} summary data={data} />
                    </div>


                </Col>
            </Row>





            <conditional.true value={(showActivity)}>

                <div style={{ "marginTop": "5px" }}>
                    <div><SalesActivity condition={condition} setShowActivity={setShowActivity} isbn={isbn} /></div>
                </div>

            </conditional.true>




        </>
    );
}