import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import SiteRouter from './utils/SiteRouter';
import Cookies from 'js-cookie';
import './antd.css';
import './chrome.scrollbar.css';
import './App.css';
import './Dark.css';
import "medium-editor/dist/css/medium-editor.css";
import "./medium.css";
import Session from './utils/Session';
import { apiCall } from './utils/Api';
import { Spin, Tooltip } from 'antd';

import { BulbOutlined, BulbFilled } from '@ant-design/icons';
import Prefs from './utils/Prefs';

/*
- Entry point into store site. 
- Fetches and sets settings & session data.
- Saves a session id into a cookie (session_store_id)
- Draws session context & routes
*/

function App() {

  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState({});


  const swapFavicon = (_webstore_name) => {

    const head = document.querySelector('head');
    const existingFavicon = document.querySelector('link[rel="shortcut icon"]');
    const newFavicon = document.createElement('link');
    
    newFavicon.rel = 'shortcut icon';
    newFavicon.href = "https://cdn1.bookmanager.com/i/"+_webstore_name+"/favicon.png";
    
    if (existingFavicon) {
      head.removeChild(existingFavicon);
    }
    
    head.appendChild(newFavicon);
  }


  const [prefs, setPrefs] = useState({
    store: "",
    pendingOO: false,
    bm_data: false,
    last_used : "",
    selected : Cookies.get("selected") || "comments"
  });

  window.beta = false;

  const getSettings = () => {
    apiCall("store/getSettings", {}, (_status, _result) => {
      if (_status) {
        window.sitesettings = _result;
        setLoading(false);
      }
    })
  }

  const getSession = () => {
    apiCall("session/get", {}, (_status, _result) => {
      if (_status) {
        Cookies.set("session_store_id", _result.session_id, { expires: 365 });
        if (_result.logged_in) {
          window.store_id = _result.store_id;
          setSession(_result);
          swapFavicon(_result.webstore_name); 
          getSettings();
        } else {
          setSession(_result);
          setLoading(false);
        }
      }
    })
  }

  useEffect(() => {
    getSession();
    setShareCookie();
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    }
  }, []);

  const onFocus = (e) => {
    setShareCookie();
  }

  const setShareCookie = () => {
    // set this cookie so share.bookmanager.com can read and any other subdomains can overwrite
    Cookies.set("last_domain", "biz", { expires: 365, domain: ".bookmanager.com" });
  }

 
 


  



  let _init_dark = ((Cookies.get("darkmode") === "true") ? true : false);
  const [dark, setDark] = useState(_init_dark);
  document.body.classList.toggle('dark', dark);

  const toggleDark = () => {
    document.body.classList.toggle('dark', !dark);
    Cookies.set("darkmode", !dark, { expires: 365 });
    setDark(!dark);
  }


  if (loading) {
    return (
      <Spin spinning={loading}>
        <div style={{ "width": "1310px", "margin": "0 auto", "height": "800px" }}></div>
      </Spin>
    );
  }



  return (
    <Prefs value={[prefs, setPrefs]}>
      <Session value={[session, setSession]}>
        <Router>
          <SiteRouter dark_mode={<Tooltip title={"Toggle dark/light display mode"}><div className="no-print" onClick={() => toggleDark(!dark)} style={{ "position": "fixed", "bottom": "25px", "left": "25px", "cursor": "pointer" }}>{(dark) ? <small><BulbFilled style={{ "color": "#666" }} /></small> : <small><BulbOutlined style={{ "color": "#666" }} /></small>}</div></Tooltip>} dark={dark} basename="/" />
        </Router>
      </Session>
      <div id="printArea" />
    </Prefs>
  );
}

export default App;
