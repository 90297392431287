import React, { useEffect, useState } from "react";
import { Column } from '@ant-design/charts';
import { Divider, message, Space } from "antd";
import moment from "moment-timezone";
import { apiCall } from "../../utils/Api";
export default function Engagement(props) {

    const { filters = { start_date: moment(), end_date: moment() } } = props;

    const [results, setResults] = useState();

    const fetchEngagement = () => {
        let obj = {
            table: "engagement",
            chart_type: "bar",
            include_keys: JSON.stringify(["n_0_10", "n_10_30", "n_30_60", "n_60_180", "n_180_600", "n_600_1800", "n_1800_Infinity"]),
            start_ts: filters.start_date.unix(),
            end_ts: filters.end_date.unix()
        }

        apiCall("site_analytics/get", obj, (_status, _result) => {
            if (_status) {
                setResults(_result);
            } else {
                message.error(_result.error)
            }
        })

    }

    useEffect(fetchEngagement, [filters]);

    const parseEngagement = (_data = { labels: [], datasets: [] }) => {

        if (_data.datasets.length < 1) {
            return [];
        }

        let arr = [];
        _data.datasets[0].data.forEach((_item, _index) => {
            arr.push({
                x: _data.labels[_index].replace("Sessions Between", "").replace("Sessions Over", ">"),
                Sessions: parseInt(_item),
                inc: _index,
            })
        });

        return arr;
    }

    const config = {
        data: parseEngagement(results),
        xField: 'x',
        yField: 'Sessions',
        height: 250,
        color: "#64a6da",

    };

    return (
        <>

            <div style={{ "float": "right", "marginRight": "0px" }}>
                <Space>
                    <Space>
                        <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#64a6da" }}></div>
                        <div style={{ "opacity": "0.7" }}><small>Sessions</small></div>
                    </Space>
                </Space>
            </div>
            <strong className="c" style={{ "fontSize": "13px" }}>Engagement</strong>
            <div style={{ "fontSize": "12px" }}><small>Website visit duration by time segment.</small></div>
            <Divider dashed style={{ "margin": "10px 0px" }} />
            <Column {...config} />

        </>
    )

}