import { Spin, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";
import Html from "../../utils/Html";
import moment from "moment";
import {parseRank, parseSubjectsList} from "../../utils/Utils";

export default function TitleField(props) {

    const { isbn = "", field = "", data = {} } = props;
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [empty, setEmpty] = useState(false);

    const drawProductDetails = (_data) => {

        let
        {
            rank = {
                bestseller: null,
            },
            binding = "",
            release_date = null,
            is_forthcoming = false,
            height = null,
            width = null,
            pages = null,
            publisher = "",
            audience = null,
            edition_type = null,
            series = "",
            series_num = "",
            weight = null,
            carton_qty = null,
            subjects = [],
            language = "",

        } = _data;


        return (
            <div className="summary-box">
                <conditional.true value={(binding)}>
                    <div key="0"><strong>Binding </strong><span>{binding}</span></div>
                </conditional.true>
                <conditional.true value={(pages)}>
                    <div key="1"><strong>Pages: </strong> <span>{pages}</span></div>
                </conditional.true>
                <conditional.true value={(series)}>
                    <div key="2"><strong>Series: </strong><span>{series}</span></div>
                </conditional.true>
                <conditional.true value={(series_num)}>
                    <div key="2_series_number"><strong>Series Number: </strong> <span>series_num</span></div>
                </conditional.true>
                <conditional.true value={(edition_type)}>
                    <div key="2_edition_type"><strong>Edition Type: </strong><span>{edition_type}</span></div>
                </conditional.true>
                <conditional.true value={(publisher)}>
                    <div key="3"><strong>Publisher: </strong> <span>{publisher}</span> </div>
                </conditional.true>
                <conditional.true value={(language)}>
                    <div key="12"><strong>Language: </strong> <span>{language}</span> </div>
                </conditional.true>
                <conditional.true value={(release_date)}>
                    <div key="4"><strong>{(is_forthcoming) ? "Releases:" : "Published:"} </strong> <span>{(release_date) ? moment(release_date * 1000).format("MMMM D, YYYY") : ""}</span> </div>
                </conditional.true>
                <conditional.true value={(audience)}>
                    <div key="5"><strong>Audience: </strong> {audience} </div>
                </conditional.true>
                <conditional.true value={(subjects.length > 0)}>
                    <div key="6"><strong>Subjects: </strong><span style={{ "display": "inline", "padding": "10px 0px" }}>{parseSubjectsList(subjects)}</span></div>
                </conditional.true>
                <conditional.true value={(isbn)}>
                    <div key="7"><strong>EAN: </strong> <span>{isbn}</span> </div>
                </conditional.true>
                <conditional.true value={(width && height)}>
                    <div key="8"><strong>Physical Dimensions: </strong> <span>{width}" x {height}"</span> </div>
                </conditional.true>
                <conditional.true value={(weight)}>
                    <div key="9"><strong>Shipping Weight: </strong> <span>{(weight) ? weight.toString() + "lbs." : ""}</span></div>
                </conditional.true>
                <conditional.true value={(carton_qty)}>
                    <div key="10"><strong>Carton Quantity: </strong> <span>{(carton_qty) ? carton_qty.toString() : ""}</span></div>
                </conditional.true>
                <conditional.true value={(rank.bestseller)}>
                    <div key="11"> <strong>Sales Rank: </strong> {parseRank(rank)} </div>
                </conditional.true>
            </div>
        )
    }

    const parseReviews = (_reviews) => {
        if (!_reviews || _reviews.length === 0) {
            return ("");
        }
        return _reviews.map((_review, index) => {
            return (<span key={index}><Html html={_review.content} /></span>);
        });
    }



    const draw = (_res) => {


        if(field === ""){
            return drawProductDetails(_res);
        }

        if (!_res.hasOwnProperty(field)) {
            return <></> //<Empty imageStyle={{ height: 20}} className="small-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
        }
        let _arr = _res[field];

        if (!_arr || _arr.length === 0) {
            return <></> //<Empty imageStyle={{ height: 20}} className="small-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }

        if(field === "reviews"){
            return parseReviews(_arr);
        }

        if (field === "description") {
            return (<Html key="description" html={_arr} />);
        }

        if (field === "marketing") {
            let inc = 0;

            
            
            let _ret =  Object.keys(_arr).map(_key => {
                if (_key === "extras") {

                    return Object.keys(_arr[_key]).map(_k => {
                        
                        
                        return _arr[_key][_k].map((_itm, index) => {
                                if(_itm){
                                    inc++; 
                                }
                                if(_itm.hasOwnProperty("url") && _itm.hasOwnProperty("desc")){
                                    return <a target={"_blank"} href={_itm.url}>{_itm.desc}</a>
                                }
                                return (<Html key={index} html={_itm} />);
                        });
                    })
                } else {
                    return _arr[_key].map((_itm, index) => {
                        if(_itm){
                            inc++; 
                        }
                            return (<Html key={index} html={_itm} />);
                    });
                }
            })

            if(inc>0){
                return _ret;
            } else {
                return <></>// <Empty imageStyle={{ height: 20}} className="small-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
        }

        return _arr.map((_itm, index) => {
            return (<Html key={index} html={_itm} />);
        });
    }



    const getItemField = () => {
        setLoading(true);
        let _field = field;

        if(field === "reviews"){
            _field = "";
        }
        apiCall("title/getItem", { isbn: isbn, want_keys_csv: _field }, (_status, _result) => {

            if(_status){
                setResult(_result)
            } else {
                setResult(data);
            }
           
            setLoading(false);
        })
    }

    useEffect(getItemField, [field])

    return (
        <Spin spinning={(loading)} >
            <div style={{ "fontSize": "12px" }}>
                {draw(result)}
            </div>
            <div className="shim" />
        </Spin>
    )
}