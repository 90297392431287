import { AutoComplete, Input } from "antd";
import React, { useState } from "react";
import { webstore_search } from "../Webstore/webstore.js";
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
export default function WebstoreSearch(props) {

    const {setCurrent = () => {}, is_multistore = false} = props; 

    const history = useHistory(); 
    const [term, setTerm] = useState("");
    const [key, setKey] = useState("");
    const [results, setResults] = useState([]);

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const onSearch = (value) => {


        setKey(value);
        let _result = webstore_search.filter(item => item.heading.toLowerCase().includes(value.toLowerCase()));
        if(!is_multistore){
            _result = _result.filter(item => item.section !== "multistore");
        }
        let _results = _result.filter(item => item.heading.toLowerCase().includes(value.toLowerCase())).map(item => {
            let _sn = capitalizeFirstLetter(item.section);
            if(_sn === "Pricing"){
                _sn = "Pricing & Stock";
            }
            return { value: item.key, label: <>{item.heading} <div><small style={{ "opacity": "0.5" }}>({_sn})</small></div></> }
        });
        setResults(_results);
    };


    const onSelect = (e) => {

        setKey("");
       let _find = webstore_search.find(item => item.key === e);
       if(_find){
        setCurrent(_find.section + "_section");
       }

        setTimeout(() => {
            let element = document.getElementById(e);
            if(element){
                let m = element.getBoundingClientRect();
                window.scrollTo({
                    top: m.y - 95,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }, 10)
       
    }



    return (
        <>
            {/* <div style={{"pointerEvents" : "none", "opacity" : "0.4"}}> */}
            <AutoComplete
                suffixIcon={<><SearchOutlined /></>}
                onSelect={(e) => onSelect(e)}
                options={results}
                value={key}
                style={{ "width": "400px" }}
                size="small"
                onChange={(e) => onSearch(e)} 
                >

                <Input.Search size="small" placeholder="Search settings..." enterButton />

            </AutoComplete>
            {/* </div> */}


            {/* <AutoComplete
                dropdownClassName="tinyDropdown"
                placeholder="Search settings..."
                style={{ "width": "200px" }}
                size="small"
                options={[{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }]}
            /> */}
        </>
    )
}