import { Checkbox, Form, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { slugify } from "../../utils/Utils";

export default function TextEmailField(props) {

    const { data = {}, setSelected = () => { }, render = false, update = () => { } } = props;

    const [d, setD] = useState(data);

    const {
        id = "",
        label = "Text",
        type = "text",
        description = "",
        placeholder = "Enter text...",
        max_length = 50,
        required = false,
        required_message = "This field is required"
    } = d;


    useEffect(() => {
        if (!render) {
            update(d);
        }

    }, [d])



    if (render) {
        return (
            <Form.Item extra={props.data.description} rules={[{ required: props.data.required, message: props.data.required_message }, {type: 'email', message: 'Please enter a valid email'}]} label={props.data.label} name={id} >
                <Input maxLength={props.data.max_length} placeholder={props.data.placeholder} />
            </Form.Item>
        )
    }

    return (
        <>

            {/* LABEL */}
            <label >Label</label><div className="shim" />
            <Input value={label} onChange={(e) => setD({ ...d, "label": e.target.value })} placeholder="Label..." />
            <div className="shim" /><div className="shim" />

            {/* LABEL */}
            <label >Description / Instructions</label><div className="shim" />
            <Input value={description} onChange={(e) => setD({ ...d, "description": e.target.value })} placeholder="Description..." />
            <div className="shim" /><div className="shim" />

            {/* PLACEHOLDER */}
            <label >Placeholder</label><div className="shim" />
            <Input value={placeholder} onChange={(e) => setD({ ...d, "placeholder": e.target.value })} placeholder="Placeholder..." />
            <div className="shim" /><div className="shim" />

            {/* REQUIRED */}
            <div className="float-flex">
                <div>&nbsp;</div>
                <Checkbox onChange={(e) => setD({ ...d, "required": e.target.checked })} checked={required} >Required?</Checkbox>
            </div>

            {(required &&
                <>
                    {/* REQUIRED MESSAGE */}
                    <label>Required? Error Message</label ><div className="shim" />
                    <Input value={required_message} onChange={(e) => setD({ ...d, "required_message": e.target.value })} placeholder="Message..." />
                    <div className="shim" /><div className="shim" /> <div className="shim" /><div className="shim" />
                </>
            )}

            {/* MAX LENGTH */}
            <label>Max Characters</label><div className="shim" />
            <InputNumber value={max_length} onChange={(e) => setD({ ...d, "max_length": e })} placeholder="Max characters..." />
           

        </>
    )
}