import React, { useEffect, useState } from 'react';
import { Form, Button, Select, DatePicker, Space, Switch, Modal, Divider, Input } from 'antd';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../utils/Utils';
import TimeAutoComplete from './TimeAutocomplete';
import CustomRecurrence from './CustomRecurrence';
import { rrulestr } from 'rrule'

const RRule = (props) => {

  const { onChange = () => { }, form, start_time, end_time, all_day, recurring_as_single_row = 0 } = props;


  const [date, setDate] = useState((props.date) ? moment(props.date) : "");
  const [rule, setRule] = useState(props.rule)
  const [allDay, setAllDay] = useState(false);
  const [visible, setVisible] = useState(false);
  const customFormat = (value) => `${moment(value).format("dddd, MMM Do")}`;

  // Update the rule 
  useEffect(() => onChange(rule), [rule])

  // Rrules need to be structured exactly...
  function removeLastSemicolon(str) {
    if (str.charAt(str.length - 1) === ';') {
      return str.slice(0, -1);
    }
    return str;
  }

  function isLastWeekOfMonth(date) {
    const inputDate = date;
    const endOfMonth = inputDate.clone().endOf('month');
    const startOfLastWeek = endOfMonth.clone().subtract(6, 'days');
    return inputDate.isSameOrAfter(startOfLastWeek, 'day') && inputDate.isSameOrBefore(endOfMonth, 'day');
  }

  // List of common presets based on today's date
  const getPresets = () => {
    let _arr = [];
    let _today = (date) ? date : moment();
    let _today_code = _today.format("dd").toUpperCase();
    let _daynum = _today.date();
    let _weeknum = Math.ceil(_today.date() / 7);
    let _monthnum = _today.month();

    // Daily
    let _day = { value: "FREQ=DAILY;INTERVAL=1;", label: "Daily" }
    // Weekly from today
    let _week = { value: `FREQ=WEEKLY;INTERVAL=1;BYDAY=${_today_code};` }
    // Monthly from today
    let _month = { value: `FREQ=MONTHLY;INTERVAL=1;BYDAY=${_weeknum}${_today_code};` }
    // Yearly from today
    let _year = { value: `FREQ=YEARLY;INTERVAL=1;BYMONTH=${_monthnum + 1};BYMONTHDAY=${_daynum};` }

    _arr.push(_day);
    _arr.push(_week);
    _arr.push(_month);
    if (isLastWeekOfMonth(_today)) {
      _arr.push({ value: `FREQ=MONTHLY;INTERVAL=1;BYDAY=-1${_today_code};` });
    }
    _arr.push(_year);

    return _arr;
  }

  // Draw presets
  const drawPresets = () => {
    let _arr = getPresets();
    return _arr.map(item => {
      let _label = rrulestr(removeLastSemicolon(item.value)).toText() || "None";
      return (<Select.Option value={item.value} >{capitalizeFirstLetter(_label)}</Select.Option>)
    })
  }

  // If a rule exists that isn't in the preset, assume it's custom and add to the top of the selection box
  const drawCustom = () => {
    if (!rule) {
      return (<></>)
    }
    let _p = getPresets();
    let _f = _p.find(item => item.value === rule);
    if (!_f) {
      let _label = rrulestr(removeLastSemicolon(rule)).toText() || "None";
      return (<Select.Option value={rule} >{capitalizeFirstLetter(_label)}</Select.Option>)
    } else {
      return (<></>)
    }
  }

  return (
    <div>
      <div className='shim' /><div className='shim' />
      <Form.Item initialValue={end_time} hidden name="end_time">
        <Input />
      </Form.Item>
      <Form.Item initialValue={start_time} hidden name="start_time">
        <Input />
      </Form.Item>
      <Space>
        <Form.Item rules={[{ required: true, message: "Date required." }]} initialValue={(date) ? date : ""} name="date" label="Date">
          <DatePicker style={{ "width": "205px" }} value={(date) ? date : ""} onChange={(e) => { setDate(e); }} inputReadOnly allowClear={false} format={customFormat} />
        </Form.Item>
        <Form.Item label="Start">
          <TimeAutoComplete allowClear defaultValue={start_time} onChange={(e) => form.setFieldsValue({ "start_time": e })} />
        </Form.Item>
        <Form.Item label="End">
          <TimeAutoComplete defaultValue={end_time} onChange={(e) => form.setFieldsValue({ "end_time": e })} />
        </Form.Item>
        <Space>
          <div style={{ "width": "15px" }}>&nbsp;</div>
          <Form.Item valuePropName="checked" initialValue={all_day} name="all_day" label={"All day?"}>
            <Switch onChange={(e) => setAllDay(e)} size="small" />
          </Form.Item>
        </Space>
      </Space>
      <div className='shim' />
      <Space>
        <Form.Item label="Repeat">
          <Select dropdownRender={menu => (
            <>
              {menu}
            </>
          )}
            value={rule} style={{ width: 303 }} onChange={(e) => {
              if (e === "custom") {
                setVisible(true)
              } else {
                setRule(e)

              }
            }} placeholder="Select Time">
            <Select.Option value="">Does not repeat</Select.Option>
            {drawCustom()}
            {drawPresets()}
          </Select>
        </Form.Item>
        <div style={{ "marginTop": "4px", "padding": "0px" }}><Button style={{ "padding": "0px 15px", "lineHeight": "20px", "height": "31px" }} onClick={() => setVisible(true)} type='primary'><small>Customize...</small></Button></div>
        <div style={{ "width": "5px" }}>&nbsp;</div>
        <Form.Item initialValue={recurring_as_single_row} name="recurring_as_single_row" label={"Display"}>
          <Select disabled={!rule} value={0} style={{ "width": "210px" }}>
            <Select.Option key={0} value={0}>All occurrences</Select.Option>
            <Select.Option key={1} value={1}>Single event</Select.Option>
            <Select.Option key={2} value={2}>Upcoming occurrence</Select.Option>
          </Select>
        </Form.Item>
      </Space>
      <Modal destroyOnClose width={300} footer={null} onCancel={() => setVisible(false)} visible={visible}>
        <CustomRecurrence isLastWeekOfMonth={isLastWeekOfMonth} rule={rule} onChange={(_r) => setRule(_r)} date={(date) ? date : moment()} setVisible={setVisible} customFormat={customFormat} />
      </Modal>

    </div >
  );
};

export default RRule;
