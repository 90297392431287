import React, { useEffect, useState } from "react";
import { Layout, Space, Form, Button, Table, Card, Input, Checkbox } from "antd";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiCall, apiDownload } from "../utils/Api";
import moment from "moment";
import Paginate from "../components/Paginate";
import GiftRegistyDetails from "./GiftRegistryDetails";
import Overlay from "../components/Overlay";
import GiftRegistryShare from "./GiftRegistryShare";

export default function ManageGiftRegistriesPage() {

    const [results, setResults] = useState({ rows: [] });
    const [filters, setFilters] = useState({ current: 1, start: 0, limit: 20, include_expired: false });
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);

    const downloadExcel = (_eid) => {
        apiDownload({ eid: _eid }, "giftregistry/export");
    }

    const getAll = (_search = search) => {

        setLoading(true)
        let obj = {
            offset: filters.start,
            count: filters.limit,
            include_expired: filters.include_expired
        }

        if (search) {
            obj.search = _search;
        }

        apiCall("giftregistry/list", obj, (_status, _result) => {
            if (_status) {
                setResults(_result);
                console.log(_result)
            }
            setLoading(false);
        })
    }

    useEffect(getAll, [filters]);

    const updateRow = (_item) => {
        let _update = [...results.rows];
        let _index = _update.findIndex(itm => itm.gift_registry_id === _item.gift_registry_id);
        _update[_index] = _item;
        setResults({ results, "rows": _update });
    }

    const columns = [
        {},
        { title: <small>Registry name</small>, dataIndex: 'title', key: 'title' },
       
        {
            title: <small>Creator name</small>, dataIndex: 'owner', key: 'owner', render: (e) => {

                let { name = "" } = e;
                return name;
            }
        },

        
        {
            title: <small>Creator email</small>, dataIndex: 'owner', key: 'owner', render: (e) => {

                let { email = "" } = e;
                return email;
            }
        },
        {
            title: <small>Shipping</small>, dataIndex: 'shipping_address', key: 'shipping_address', render: (e) => {
                return (e.hasOwnProperty("address_id") && (e.address_id)) ? "Yes" : "No"
            }
        },
        {
            title: <small>Shipping Encouraged</small>, dataIndex: 'shipping_encouraged', key: 'shipping_encouraged', render: (e) => {
                return (e) ? "Yes" : "No"
            }
        },

        {
            title: <small>Date created</small>, dataIndex: 'date_created_ts', key: 'date_created_ts', render: (e) => {
                return (e) ? moment(e * 1000).format("MMM Do, YYYY") : "No deadline";
            }
        },

        {
            title: <small>Purchase Deadline</small>, dataIndex: 'purchase_deadline', key: 'purchase_deadline', render: (e) => {
                return (e) ? moment(e * 1000).format("MMM Do, YYYY") : "No deadline";
            }
        },
        {
            title: <small>Expired</small>, dataIndex: 'expired', key: 'expired', render: (e, f) => {

                if (!f.purchase_deadline) {
                    return "-";
                }
                return (moment().unix() > moment(f.purchase_deadline * 1000).unix()) ? "Yes" : "No";
            }
        },
        {
            title: <small>Progress</small>, dataIndex: 'items', key: 'items', render: (e, f) => {

                return (<>{f.total_purchased} of {f.total_requested} purchased</>)

            }
        },
        { title: <small><nobr>Can manage?</nobr></small>, dataIndex: 'store_can_edit', key: 'store_can_edit', render : (e) => {
            return (e) ? "Yes" : "No"
        } },
        {
            title: <small>Actions</small>, width: "100px", dataIndex: 'items', key: 'items', render: (e, f) => {
                return (
                    <Space>
                        <Overlay component={<GiftRegistyDetails updateRow={updateRow} data={f} />}><Button type="primary" size="small"><small>Edit</small></Button></Overlay>
                        <Button onClick={() => window.open("/registry/" + f.gift_registry_id, "_blank")} size="small"><small>Print</small></Button>
                        <Overlay width={450} component={<GiftRegistryShare gift_registry_id={f.gift_registry_id} />}><Button size="small"><small>Share</small></Button></Overlay>
                        <Button onClick={() => downloadExcel(f.gift_registry_id)} type="ghost" size="small"><small>XLS</small></Button>
                    </Space>
                )
            }
        },
    ]


    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Manage Gift Registries"
                    filters={
                        <Form size="small" layout="vertical">
                            <Space>
                                <Form.Item label={<small>Search</small>} name="keyword_search">
                                    <Input.Search onSearch={(e) => getAll(e)} onChange={(e) => setSearch(e.target.value)} allowClear enterButton placeholder="Creator name, email, registry name, description..." style={{ "width": "380px" }} />
                                </Form.Item>
                                <Form.Item label={<small>Include expired</small>}>
                                    <Checkbox checked={filters.include_expired} onChange={(e) => setFilters({ ...filters, "include_expired": e.target.checked })} />
                                </Form.Item>
                            </Space>
                            <br clear="both" />
                        </Form>
                    }
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                            <Paginate setPaginate={(e) => setFilters({ ...filters, current: e.current, start: e.offset, limit: e.pagesize })} count={results.row_count} paginate={{ current: filters.current, pagesize: filters.limit, offset: filters.start }} />
                            <Table
                                loading={loading}
                                size="small"
                                header={<>Header</>}
                                pagination={false}
                                columns={columns}
                                dataSource={results.rows}
                            />
                            <Paginate setPaginate={(e) => setFilters({ ...filters, current: e.current, start: e.offset, limit: e.pagesize })} count={results.row_count} paginate={{ current: filters.current, pagesize: filters.limit, offset: filters.start }} />
                        </Card>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )

}