import { message, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiCall } from "../../utils/Api";
import { addCommas } from "../../utils/Utils";

export default function AnalyticsTableHtml(){

    const loc = useLocation(); 
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({ rows: [], row_count: 0, temp_list_id: "" });


    const URLToObject = (_url) => {
        const object = {};
        const pairs = _url.split('&');
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split('=');
            object[pair[0]] = decodeURIComponent(pair[1]);
          }
        return object;
    }


    const params = URLToObject(loc.search.replace("?", ""));


    const fetchTable = () => {
        setLoading(true)
        let obj = URLToObject(loc.search.replace("?", ""));
        if(obj.isbns === "false"){
            obj.limit = 1000;
        } else {
            obj.limit = 10000;
        }
        
        delete obj.offset; 
        apiCall("reports/analytics/getTitles", obj, (_status, _result) => {
            if(_status){
                setData(_result)
            } else {
                message.error(_result.data);
            }
            setLoading(false)
        })
    }
    useEffect(fetchTable, [])

    const columns = [
        { title: <small>Sold</small>, dataIndex: 'sold', key: 'sold' },
        { title: <small>aOH</small>, dataIndex: 'cur_oh', key: 'cur_oh' },
        { title: <small>aOO</small>, dataIndex: 'cur_oo', key: 'cur_oo' },
        { title: <small>Price</small>, dataIndex: 'price', key: 'price' },
        { title: <small>Title</small>, dataIndex: 'title', key: 'title' },
        { title: <small>Author</small>, dataIndex: 'author', key: 'author' },
        { title: <small>Cat</small>, dataIndex: 'cat', key: 'cat' },
        { title: <small>Cat2</small>, dataIndex: 'cat2', key: 'cat2' },       
        { title: <small>Supl</small>, dataIndex: 'scode', key: 'scode' },
        { title: <small>TtlRcv</small>, dataIndex: 'ttlrcvd', key: 'ttlrcvd' },
        { title: <small>Rtn</small>, dataIndex: 'rtrn', key: 'rtrn' },
        { title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn' },
    ]


   

    if(loading){
        return(
            <Spin spinning={loading}>
                <div style={{"height" : "100vh"}}>&nbsp;</div>
            </Spin>
        )
    }


    
    if(params.isbns === "true"){
        return(
            <>
            ISBN results: {(data.row_count < 10000) ? addCommas(data.row_count) : addCommas(10000)}<br />
            ----<br />
            {data.rows.map(item => {
                return(<>{item.isbn}<br /></>)
            })}
            </>
        )
    }

    return(
        <>
            <Table
                size="small"
                pagination={false}
                columns={columns}
                dataSource={data.rows}
            />
        </>
        )
}