import { Select, Space, Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiCall } from "../utils/Api";

export default function BroadcastMessage(props) {

    const { trigger = false, title = "Share current page", path = "" } = props;

    const toURI = (_string) => {
        let no_whitespace = _string.replace(/\s+/g, '-');
        let sanitized = no_whitespace.replace(/[^A-Za-z0-9-]+/g, '', no_whitespace);
        return sanitized;
    }

    const location = useLocation();
    const [names, setNames] = useState([]);
    const [selected, setSelected] = useState("")
    const getPubsubNames = () => {
        apiCall("session/getPubsubNames", {}, (_status, _result) => {
            if (_status) {
                setNames(_result.names);
            }
        })
    }

    useEffect(getPubsubNames, [trigger]);

    const shareRoute = () => {

        let obj = {
            to_channel: toURI(selected),
           
        }

        if(path){
            obj.route = path
        } else {
            obj.route = location.pathname; 
        }
        apiCall("publish/shareRoute", obj, (_status, _result) => {
            if (_status) {
                Modal.success({ title: "Page Sent!" });
            } else {
                message.error(_result.error);
            }
        })

    }

    return (
        <>
            <label>{title}</label>
            <br />
            <Space>
                <Select onChange={(e) => setSelected(e)} placeholder="Select user..." style={{ width: 200 }}>
                    {names.map((item, index) => {
                        return <Select.Option key={index} value={item}>{item}</Select.Option>
                    })}
                </Select>
                <Button onClick={() => shareRoute()} disabled={(!selected)} type="primary"><small>Send</small></Button>
            </Space>
        </>
    );
}