import React from "react";

export default function BusinessDirectoryHelp() {

    return (<></>)


    // return(
    //     <div style={{"maxHeight" : "500px", "overflow" : "auto"}}>
           
    //          <p className="content">
    //             <a name="Top"></a>
    //         </p><ol>
    //             <li><a href="#Search">Searching</a>
    //                 <ol style={{"listStyleType" : "upper-alpha"}}>
    //                     <li><a href="#Search_Textfield">Textfield Input</a></li>
    //                     <li><a href="#Search_Webstores">Webstores Checkbox</a></li>
    //                     <li><a href="#Search_Pubstock">Pubstock Checkbox</a></li>
    //                     <li><a href="#Search_Menus">Scrollable Menus</a>
    //                         <ol style={{"listStyleType": "upper-roman"}}>
    //                             <li><a href="#Search_Menus_Category">Category</a></li>
    //                             <li><a href="#Search_Menus_Specialty">Specialty</a></li>
    //                             <li><a href="#Search_Menus_City">City</a></li>
    //                             <li><a href="#Search_Menus_Prov">Prov/State</a></li>
    //                             <li><a href="#Search_Menus_Country">Country</a></li>
    //                         </ol>
    //                     </li>
    //                 </ol>
    //             </li>
    //             <li><a href="#Browse">Browsing</a></li>
    //             <li><a href="#View">Viewing</a></li>
    //             <li><a href="#Edit">Editing</a>
    //                 <ol style={{"listStyleType" : "upper-alpha"}}>
    //                     <li><a href="#Edit_Add">Adding Your Company</a>
    //                         <ol style={{"listStyleType": "upper-roman"}}>
    //                             <li><a href="#Edit_Add_Create">Creating an Account</a></li>
    //                             <li><a href="#Edit_Add_List">Listing Your Company</a></li>
    //                         </ol>
    //                     </li>
    //                     <li><a href="#Edit_Info_Change">Changing Contact Info</a></li>
    //                     <li><a href="#Edit_Info_Images">Uploading Images/Logo</a></li>
    //                     <li><a href="#Edit_Info_Category">Selecting Categories</a></li>
    //                     <li><a href="#Edit_Info_Specialty">Selecting Specialties</a></li>
    //                     <li><a href="#Edit_Remove">Removing Your Company</a></li>
    //                 </ol>
    //             </li>
    //         </ol> 

    //         <hr />
    //         <p></p>
    //         <p class="content">
    //             <a name="Search"></a>
    //             <b>Searching</b>
    //             <br />
    //             The TBM Bookmanager Book Business Directory is an advanced utility to search for businesses in the book industry.
    //             This utility is absolutely free to browse, and free to list your company in!
    //             <br />
    //             <br />
    //             In order to add your company you must have registered an account with TBM Bookmanager.
    //             Once you have signed up an account you must then list your company and enter in all of the required information for the directory.
    //             <br />
    //             <br />
    //             Your information will not be visible to the public until it has been approved by TBM Bookmanager staff.
    //             Once your info has been approved you will be listed in our directory for everyone to find.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Search_Textfield"></a>
    //             <b>Searching by Textfield Input</b>
    //             <br />
    //             At the top of the Business Directory page is a simple textfield input for searching.
    //             Here you may type in anything (company name, category, specialty, city, province/state, country) to search by.
    //             <br />
    //             <br />
    //             The search engine will try to match up your search query with the best available results.
    //             If you misspell a word the engine will try to match similar results, but may not always return exactly what you searched for.
    //             <br />
    //             <br />
    //             A better and more specific way to search would be to use the advanced scrollable menus.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Search_Webstores"></a>
    //             <b>Searching for Webstores</b>
    //             <br />
    //             At the top of the Business Directory page, next to the textfield search, you will see a checkbox labeled "is Webstore".
    //             If you check this box, it will return only the stores that have webstores with TBM Bookmanager.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Search_Pubstock"></a>
    //             <b>Searching Pubstock</b>
    //             <br />
    //             At the top of the Business Directory page, next to the textfield search and the Webstore checkbox, you will see another checkbox labeled "on Pubstock".
    //             If you check this box, it will return only the businesses that provide their stock data to the Pubstock service at TBM Bookmanager.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Search_Menus"></a>
    //             <b>Searching by Scrollable Menus</b>
    //             <br />
    //             Below the search textfield you will see 5 scrollable menus (Category, Specialty, City, Prov/State, Country).
    //             By clicking on any of the values in these menus, you will narrow your search based on the criteria selected.
    //             You may select an item in any or every menu.
    //             <br />
    //             <br />
    //             When you select an item in a menu, you will see the number next to each item change.
    //             This number is the total count of businesses in the directory that are associated with that menu item.
    //             When you select multiple menu items, you will see that number drop drastically as your search is narrowed down.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Search_Menus_Category"></a>
    //             <b>Searching by Category</b>
    //             <br />
    //             The category field defines what a business is.
    //             Some of the most common categories are <i>Bookstore</i>, <i>Publisher</i>, <i>Wholesaler</i> and <i>Distributor</i>.
    //             The list of categories will continue to expand as more businesses are listed in the directory.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Search_Menus_Specialty"></a>
    //             <b>Searching by Specialty</b>
    //             <br />
    //             The specialty field explicitly defines a division (specialty) of a category.
    //             Many categories may share a similar specialty (ie a <i>Bookstore</i> can be <i>General</i> or <i>Mystery</i>, and a <i>Publisher</i> can be <i>General</i> or <i>Mystery</i>).
    //             You do not need to have a category selected to choose a specialty, however it makes your search results more specific if you choose an item from both menus.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Search_Menus_City"></a>
    //             <b>Searching by City</b>
    //             <br />
    //             You may search for a business by city.
    //             Only cities with businesses listed in the directory will be available to search by.
    //             If your city is not listed in the directory, then no business from your city has enrolled in the directory.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Search_Menus_Prov"></a>
    //             <b>Searching by Province/State</b>
    //             <br />
    //             You may search for a business by province or state.
    //             Only provinces or states with businesses listed in the directory will be available to search by.
    //             If your province or state is not listed in the directory, then no business from that province or state has enrolled in the directory.
    //             <br />
    //             <br />
    //             Because not all countries have provinces or states, TBM Bookmanager staff have tried to make all entries in the directory as comprehensible as possible.
    //             Therefor in a country that has no provinces or states, but instead has geopolitical regions, territories or other, TBM Bookmanager has elected to use those in this field.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Search_Menus_Country"></a>
    //             <b>Searching by Country</b>
    //             <br />
    //             You may search for a business by country.
    //             Only countries with businesses listed in the directory will be available to search by.
    //             If your country is not listed in the directory, then no business from that country has enrolled in the directory.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Browse"></a>
    //             <b>Browsing the Book Business Directory</b>
    //             <br />
    //             Once you have performed a search, or narrowed your choices in the scrollable menus, you will be presented with a list of bookstores below the scrollable menus.
    //             <br />
    //             <br />
    //             The default number of companies displayed per page is 25. You can traverse through pages using the pagination drop-down, or the "Prev. page" and "Next page" links.
    //             <br />
    //             <br />
    //             To view a company's information just click on a company name.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="View"></a>
    //             <b>Viewing a Company's Information</b>
    //             <br />
    //             When you click on a company name in the Book Business Directory, you will see an inner tab open within the list of company names.
    //             This inner tab actually pushes all content below it down, therefor keeping everything visible on-screen. You may open as many company tabs as you wish.
    //             <br />
    //             <br />
    //             Inside of the company tab the company logo (if available) is displayed at the top, with their website, email and webstore (if available) displayed on the right of the logo.
    //             The company's contact information is listed in the left column under the logo, and a description of the company (if available) is shown in the main part of the tab.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Edit"></a>
    //             <b>Editing Your Company's Information</b>
    //             <br />
    //             After you have successfully signed up for an account and listed your company in the directory, you may want to correct or update some of your information.
    //             You must be logged in to your company's account in order to edit your information. When you are logged in, you will see 2 buttons above the search textfield:
    //             <br />
    //             <br />
    //             <i><b><u>VIEW MY COMPANY</u></b></i>
    //             <br />
    //             Pressing this button will automatically display your company's contact information as anyone in the directory would see it.
    //             From this page you can press the "Edit" button at the bottom-left corner to display the edit page.
    //             <br />
    //             <br />
    //             <i><b><u>EDIT MY COMPANY</u></b></i>
    //             <br />
    //             Pressing this button will automatically display the inner tab where you can update your company's information.
    //             <br />
    //             <br />
    //             Please note, any changes made to your account will require an approval from TBM Bookmanager.
    //             You will be able to see your own changes to your company when you are logged in, however those changes will not be available to the public.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Edit_Add"></a>
    //             <b>Adding Your Company</b>
    //             <br />
    //             To add your company to the Book Business Directory, you must first create an account with TBM Bookmanager.
    //             Once you have created an account you must then list your company in the directory.
    //             Once you have listed your company, it must be approved by TBM Bookmanager before it is displayed in the directory.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Edit_Add_Create"></a>
    //             <b>Creating an Account</b>
    //             <br />
    //             To create an account you must signup with a valid email address, a company name and a password.
    //             After submitting your request to signup you will receive an activation email to the address you provided.
    //             You must visit the activation url in order to be allowed to login to the TBM Bookmanager website.
    //             Once activated and logged in, you may then list your company in the Book Business Directory.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Edit_Add_List"></a>
    //             <b>Listing Your Company</b>
    //             <br />
    //             After you have successfully created an account with TBM Bookmanager, you must list your company in the Book Business Directory.
    //             Go to the directory page and click on the "LIST MY COMPANY" button at the top of the page.
    //             You will be presented with a pop-up window where you will be able to enter in all of your company's information.
    //             When you are done, simply press the "Save Changes" button.
    //             <br />
    //             <br />
    //             Please note, any changes made to your account will requre an approval from TBM Bookmanager.
    //             You will be able to see your own changes to your company when you are logged in, however those changes will not be available to the public.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Edit_Info_Change"></a>
    //             <b>Changing Contact Info</b>
    //             <br />
    //             When you arrive at the Edit tab, you will notice the same screen that you used to list your company when you first signed up, only the screen is embedded in the page.
    //             Make you changes to any of the fields that you want. If you delete the value of a field previously filled, it will delete that field from your information.
    //             <br />
    //             <br />
    //             All fields marked in red are mandatory, please fill them appropriately. If you have not entered valid data into a field, you will receive a warning when you save your changes.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Edit_Info_Images"></a>
    //             <b>Uploading Images/Logo</b>
    //             <br />
    //             You are able to upload a company logo and a store image if you so wish.
    //             Simply click on the Edit/Upload Images button. You will be presented with a popup window (please be aware some popup blockers may prevent this, adjust your settings to allow).
    //             <br />
    //             <br />
    //             In the popup window you may select a file (only valid image files less than 150kb please) and define whether it is your logo or store image.
    //             The logo image is displayed at the top of your contact information, and your store image is displayed in the top-right corner of your store description area.
    //             <br />
    //             <br />
    //             You are able to view the images within the popup window and delete them if you are not happy with them.
    //             Please note, any changes to images will also need to be approved by TBM Bookmanager.
    //             Those images will not be visible to the public until they have been approved.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Edit_Info_Category"></a>
    //             <b>Selecting a Category</b>
    //             <br />
    //             In order for your company to be listed in the Book Business Directory, you must select at least 1 category/specialty.
    //             This category defines the nature of your company's business. Simply click the "Add Another Specialty" button to select your first (and next 2 if you wish) category.
    //             <br />
    //             <br />
    //             You are allowed a <b>maximum of 3</b> categories. You may select any of the categories in the drop-down menus.
    //             You may also define your own custom category by typing it in to the textfield that rests above the drop-down menu.
    //             Please note, all new categories are invisible to the public until explicitly approved by TBM Bookmanager staff.
    //             <br />
    //             <br />
    //             Please note, if you do not select a category, your company WILL NOT be listed in the Book Business Directory.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Edit_Info_Specialty"></a>
    //             <b>Selecting a Specialty</b>
    //             <br />
    //             In order for your company to be listed in the Book Business Directory, you must select at least 1 category/specialty.
    //             This specialty will describe a specific division of the category that you have selected.
    //             For most companies, the "General" specialty is most appropriate unless they are targeting a very specific market of the book industry.
    //             <br />
    //             <br />
    //             You are allow a <b>maximum of 3</b> specialties. You may select any of the specialties in the drop-down menus.
    //             You may also define your own custom specialty by typing it in to the textfield that rests above the drop-down menu.
    //             Please note, all new specialties are invisible to the public until explicitly approved by TBM Bookmanager staff.
    //             <br />
    //             <br />
    //             Please note, if you do not select a specialty, your company WILL NOT be listed in the Book Business Directory.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         <hr style={{backgroundColor : "#ccc"}} />
    //         <p class="content">
    //             <a name="Edit_Remove"></a>
    //             <b>Removing Your Company</b>
    //             <br />
    //             To remove your company from the Book Business Directory, open up the tab to edit your company's information.
    //             DELETE ALL OF THE CATEGORIES/SPECIALTIES. When you do not belong to any category or specialty, your company will not be listed in the Book Business Directory.
    //             <br />
    //             <br />
    //             Your company's information will still be stored in your account, however it will not be displayed to the public.
    //             If you want to relist your company again in the future, simply click the "LIST MY COMPANY" button from the Book Business Directory page.
    //             Follow the steps to list your company as you did in the first place.
    //         </p>
    //         <p class="content">
    //             [<a href="#Top">Return to Help Menu</a>] [<a href="javascript: window.close();">Close this window</a>]
    //         </p>
    //         {/* <div id="popupdiv" style="z-index:1000; visibility: hidden; position: absolute; border-style: solid; border-width: 3px; border-color: #698da7; background-color: white; padding: 6px;"></div>
    //         <div id="popupdiv2" style="z-index:1000; visibility: hidden; position: absolute; border-style: solid; border-width: 1px; border-color: #6f6f6f; background-color: white; padding: 6px;"></div>
    //         <div id="popupdiv_edit" style="z-index:10; visibility: hidden; position: absolute; border-style: solid; border-width: 3px; border-color: #698da7; background-color: white; padding: 6px;"></div>
    //         <div id="tooltip" style="z-index:1000; visibility: hidden; position: absolute; border-style: solid; border-width: 1px; border-color: #6f6f6f; color: #c5c5c5; background-color: #1190bc; padding: 6px;"></div> */}




    //     </div>
    // )

    // return (
    //     <>

    //         <p className="title">Book Business Directory - Help</p>
    //         <p className="content">
    //             <a name="Top"></a>
    //         </p><ol>
    //             <li><a href="#Search">Searching</a>
    //                 <ol style={{"listStyleType" : "upper-alpha"}}>
    //                     <li><a href="#Search_Textfield">Textfield Input</a></li>
    //                     <li><a href="#Search_Webstores">Webstores Checkbox</a></li>
    //                     <li><a href="#Search_Pubstock">Pubstock Checkbox</a></li>
    //                     <li><a href="#Search_Menus">Scrollable Menus</a>
    //                         <ol style={{"listStyleType": "upper-roman"}}>
    //                             <li><a href="#Search_Menus_Category">Category</a></li>
    //                             <li><a href="#Search_Menus_Specialty">Specialty</a></li>
    //                             <li><a href="#Search_Menus_City">City</a></li>
    //                             <li><a href="#Search_Menus_Prov">Prov/State</a></li>
    //                             <li><a href="#Search_Menus_Country">Country</a></li>
    //                         </ol>
    //                     </li>
    //                 </ol>
    //             </li>
    //             <li><a href="#Browse">Browsing</a></li>
    //             <li><a href="#View">Viewing</a></li>
    //             <li><a href="#Edit">Editing</a>
    //                 <ol style={{"listStyleType" : "upper-alpha"}}>
    //                     <li><a href="#Edit_Add">Adding Your Company</a>
    //                         <ol style={{"listStyleType": "upper-roman"}}>
    //                             <li><a href="#Edit_Add_Create">Creating an Account</a></li>
    //                             <li><a href="#Edit_Add_List">Listing Your Company</a></li>
    //                         </ol>
    //                     </li>
    //                     <li><a href="#Edit_Info_Change">Changing Contact Info</a></li>
    //                     <li><a href="#Edit_Info_Images">Uploading Images/Logo</a></li>
    //                     <li><a href="#Edit_Info_Category">Selecting Categories</a></li>
    //                     <li><a href="#Edit_Info_Specialty">Selecting Specialties</a></li>
    //                     <li><a href="#Edit_Remove">Removing Your Company</a></li>
    //                 </ol>
    //             </li>
    //         </ol>
            

    //     </>
    // )
}