import React, { useEffect, useState } from "react";
import { Layout, Space, Radio, Select, Form, Row, Col, Spin, Affix } from "antd";
import { apiCall } from "../../utils/Api";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import SalesComparisonsChart from "./SalesComparisonsChart";
import SalesRelativeChart from "./SalesRelativeChart";
import VolumeBySubject from "./VolumeBySubject";
import YearOverYear from "./YearOverYear";
import SalesSummary from "./SalesSummary";

export default function SalesComparisonsPage() {

    const [subjects, setSubjects] = useState([]);
    const [avgSales, setAvgSales] = useState({ sales: [] });
    const [relativeSales, setRelativeSales] = useState({ sales: [] });
    const [sales, setSales] = useState({ sales: [] });
    const [vbs, setVbs] = useState({ volume: [] });
    const [yoy, setYoy] = useState({ yoy: [], overall_change: 0 });
    const [loading, setLoading] = useState(true);

    const regions = [
        { key: "", value: "All Regions" },
        { key: "US", value: "USA" },
        { key: "CA", value: "Canada" },
        { key: "CA-W", value: "Canada West" },
        { key: "CA-E", value: "Canada East" },
    ]

    const [validDates, setValidDates] = useState([]);

    const [filters, setFilters] = useState({
        show_actual: true,
        region: (window.sitesettings.is_canadian) ? "CA" : "US",
        subject_code: "all", 
        time_weeks: 1,
        scfilt: "",
        start_date: false,
        end_date: false,
    })

    const subject = subjects.find(item => item.code === filters.subject_code) || { name: "All Subjects" }
    const date = validDates.find(item => (item.start === filters.start_date)) || { name: "1 Week" }
    const reg = regions.find(item => item.key === filters.region) || "All Regions";
    const weekly_title = <>Your store's weekly sales for the previous two years <em>({subject.name})</em></>;
    const summary_title = <>{subject.name}</>;
    const average_title = <>Average sales at {avgSales.store_count} stores <em>({reg.value || "All Regions"}, {subject.name})</em></>;
    const yoy_title = <>Each store's change in sales over {date.name} <em>({reg.value}, {yoy.yoy.length} stores, {subject.name})</em></>;
    const sales_title = <> Each store's sales over {date.name} <em>({reg.value}, {avgSales.store_count} stores, {subject.name})</em></>;
    const volume_title = <>Volume and Change in Volume by Subject <em>({reg.value || "All Regions"}, {date.name})</em></>;

    const getSales = (_start = false, _end = false) => {
        setLoading(true);
        let _filters = { ...filters }
        if (_start || _end) {
            _filters.start_date = _start;
            _filters.end_date = _end;
        }

        apiCall("reports/avgSales", {..._filters, subject_code: _filters.subject_code}, (_status, _result) => {
            if (_status) {
                setAvgSales(_result);
            }
        })

        apiCall("reports/sales", { show_actual: _filters.show_actual, subject_code: _filters.subject_code }, (_status, _result) => {
            if (_status) {
                setSales(_result);
            }
        })

        apiCall("reports/relativeSales", _filters, (_status, _result) => {
            if (_status) {
                setRelativeSales(_result)
            }
        })

        apiCall("reports/volumeBySubject", _filters, (_status, _result) => {
            if (_status) {
                setVbs(_result)
            }
        })

        apiCall("reports/yoy", _filters, (_status, _result) => {
            if (_status) {
                setYoy(_result);
            }
            setLoading(false);
        })
    }

    const getValidDates = () => {
        // had dates, no need to refetch
        if (validDates.length > 0) {
            getSales();
            return;
        }

        apiCall("reports/getValidDates", {}, (_status, _result) => {
            if (_status) {
                setFilters({ ...filters, "start_date": _result[0].start, "end_date": _result[0].end })
                setValidDates(_result.slice(0,7));
            }
            apiCall("data/getSubjectCodes", {}, (_status, _result) => {
                if (_status) {
                    setSubjects(_result.subjects);
                }
            })
        })

    }

    useEffect(getValidDates, [filters]);

    const drawFilters = () => {
        return (
            <Space direction="vertical">
                <strong style={{ "fontSize": "13px" }}>Filters</strong>
                <Space>
                    <div>
                        <div><label><small>Sales volume</small></label></div>
                        <Radio.Group value={filters.show_actual} onChange={(e) => setFilters({ ...filters, "show_actual": (e.target.value) ? true : false })} buttonStyle="solid">
                            <Radio.Button value={true}><small>Actual</small></Radio.Button>
                            <Radio.Button value={false}><small>Weighted</small></Radio.Button>
                        </Radio.Group>
                    </div>
                    <div>
                        <div><label><small>Type</small></label></div>
                        <div className="ant-radio-group-solid">
                            <Radio.Group value={filters.scfilt} onChange={(e) => setFilters({ ...filters, "scfilt": e.target.value })} buttonStyle="solid">
                                <Radio.Button value=""><small>All</small></Radio.Button>
                                <Radio.Button value="CHR"><small>Christian</small></Radio.Button>
                                <Radio.Button value="SEC"><small>Secular</small></Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                </Space>
                {((!filters.show_actual) && <div style={{"width" : "270px", "lineHeight" : "14px"}}>
                   <small>Weighted sales reduces the number of copies sold of individual titles  when only one store is selling a large number of copies of a title  relative to all stores. Examples are class sets or an author event. </small>
                </div>)}
                <div>
                    <div><label><small>Subject</small></label></div>
                    <Select showSearch value={filters.subject_code} onChange={(e) => setFilters({ ...filters, "subject_code": e })} style={{ width: "100%" }}>
                        <Select.Option value="all"><small>All</small></Select.Option>
                        {subjects.map((item, index) => {
                            return (<Select.Option key={item.code} value={item.code}><small>{item.name}</small></Select.Option>)
                        })}
                    </Select>
                </div>
                <Space>
                    <div>
                        <div><label><small>Date Range</small></label></div>
                        <Select onChange={(e) => setFilters({ ...filters, "start_date": validDates[e].start, "end_date": validDates[e].end })} defaultValue={0} style={{ width: 150 }}>
                            {validDates.map((item, index) => {
                                return (<Select.Option key={item.name} value={index}><small>{item.name}</small></Select.Option>)
                            })}
                        </Select>
                    </div>
                    <div>
                        <div><label><small>Region</small></label></div>
                        <Select value={filters.region} onChange={(e) => setFilters({ ...filters, "region": e })} style={{ width: 110 }}>
                            {regions.map((item, index) => {
                                return (<Select.Option key={index} value={item.key}><small>{item.value}</small></Select.Option>)
                            })}
                        </Select>
                    </div>
                </Space>
            </Space>
        )
    }

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Industry Sales Comparisons"
                    description={<div style={{ "lineHeight": "14px" }}>Your store's sales compared to other independents.  Sales of new books (978,979 EANs) only (no remainders or used books)</div>}
                />
                <Layout.Content style={{ "padding": "20px", "fontSize": "10px" }} >
                    <Row gutter={15}>
                        <Col flex={"885px"}>
                            <Spin spinning={loading}>
                                <SalesSummary title={summary_title} avgsales={avgSales.sales} sales={sales.sales} />
                                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                                <SalesComparisonsChart title={weekly_title} color={"#64a6da"} sales={sales.sales} />
                                <br clear="both" />
                                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                                <SalesComparisonsChart title={average_title} color={"#4fac60"} sales={avgSales.sales} />
                                <br clear="both" />
                                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                                <YearOverYear title={yoy_title} overall_change={yoy.overall_change} volume={yoy.yoy} />
                                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                                <SalesRelativeChart title={sales_title} sales={relativeSales.sales} />
                                <br clear="both" />
                                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                                <VolumeBySubject title={volume_title} all={vbs.all_subjects} volume={vbs.rows} />
                            </Spin>
                        </Col>
                        <Col flex={"auto"}>
                            <Affix offsetTop={100}>
                                <div className="bc" style={{ "padding": "15px", "border": "1px solid", "borderRadius": "3px" }}>
                                    <Form size="small" layout="vertical">{drawFilters()}</Form>
                                </div>
                            </Affix>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        </>
    )
}