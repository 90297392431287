import React, { useState } from "react";
import { Button, Input, Modal, Space, Typography, Tag, message, Divider } from "antd";
import { ShareAltOutlined, TwitterSquareFilled, FacebookFilled, TwitterOutlined, FacebookOutlined } from '@ant-design/icons'
import BroadcastMessage from "../BroadcastMessage";
import { XIcon } from "../icons/XIcon";
export default function Share(props) {

    const { store_path = false, customer_path = false } = props;


    const customer_url = window.sitesettings.url + customer_path
    const store_url = "https://share.bookmanager.com/" + store_path; 

    const CopyButton = (props) => {
        const { path = "" } = props
        const [copied, setCopied] = useState(false);

        const copyToClipboard = (_str) => {
            if (!navigator.clipboard) {
                message.error("Can't copy");
                return;
            }
            navigator.clipboard.writeText(path);
            setCopied(true);
            setTimeout(() => {
                setCopied(false)
            }, 1000);
        }

        return (<Button onClick={(e) => copyToClipboard(e)} type={(copied ? "default" : "primary")}>{(copied) ? "Copied" : "Copy"}</Button>);

    }

    const [visible, setVisible] = useState(false);
    const drawModal = () => {
        return (
            <>
                <Modal destroyOnClose footer={null} onCancel={() => setVisible(false)} visible={visible}>
                    <Typography.Title level={5}>Share</Typography.Title>

                    {(customer_path && <>
                        <Typography.Paragraph>To share this with <strong>customers</strong>, copy this link (e.g. Ctrl-C) and then paste it into an email.</Typography.Paragraph>
                        <Space>
                            <Input style={{ "width": "400px" }} defaultValue={customer_url} />
                            <CopyButton path={customer_url} />
                        </Space>
                    </>)}

                    {((customer_path && store_path) && <><div className="shim" /><div className="shim" /><div className="shim" /></>)}

                    {(store_path && <>
                        <Typography.Paragraph>To share this with other <strong>bookmanager stores</strong>, copy this link (e.g. Ctrl-C) and then paste it into an email.</Typography.Paragraph>
                        <Space>
                            <Input style={{ "width": "400px" }} defaultValue={store_url} />
                            <CopyButton path={store_url} />
                        </Space>

                        <div className="shim" /><div className="shim" /><div className="shim" />
                        <BroadcastMessage title="Send this title to staff member screen" path={store_url} />
                        <div className="shim" /><div className="shim" /><div className="shim" />
                    </>)}

                    
                    {(customer_path && <>
                        <div className="shim" /><div className="shim" /><div className="shim" />
                        <Typography.Paragraph>Share on social media</Typography.Paragraph>
                        <Space size={5}>
                            <a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/share?url=" + customer_url}><Button type="text" icon={<XIcon style={{ "fontSize": "32px", "color": "#000" }} />} /></a>
                            <a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + customer_url}><Button type="text" icon={<FacebookFilled style={{ "fontSize": "32px", "color": "#4267B2" }} />} /></a>
                        </Space>
                    </>)}

                    <Divider style={{ "margin": "15px 0px" }} />

                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => setVisible(false)}>Cancel</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Modal>
            </>
        )
    }


    return (
        <>
            {drawModal()}
            <div onClick={() => setVisible(true)}>{props.children}</div>
        </>
    )
}