import React, { useState } from "react";
import { Form, Layout, Table, Typography, Divider, Input, Button, Row, Col, Pagination, Modal, Space, Collapse, Checkbox, Switch, Card } from "antd";
import { FilterFilled, FilterOutlined, MenuOutlined, PicCenterOutlined, InfoCircleOutlined } from '@ant-design/icons';



import conditional from "../tools/conditional";
import BusinessDirectoryHelp from "./misc/BusinessDirectroyHelp";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import Paginate from "../components/Paginate";
export default function BusinessDirectoryPage(props) {

    const [isFiltering, setIsFiltering] = useState(false);

    const [results, setResults] = useState({ rows: [], row_count: 0 });
    const [pageSize, setPageSize] = useState(20);
    const [current, setCurrent] = useState(1)


    const pageChange = () => {


    }

    const [filters, setFilters] = useState({
        term: "",
        list: true,
        sort: "date",
        show_details: true
    })

    const columns = [
        { key: "blank", width: "10px" },
        { title: <small>Name</small>, dataIndex: 'name', key: 'name' },
        { title: <small>SAN</small>, dataIndex: 'san', key: 'san' },
        { title: <small>City</small>, dataIndex: 'city', key: 'city' },
        { title: <small>Province</small>, dataIndex: 'province', key: 'province' },
        { title: <small>Country</small>, dataIndex: 'country', key: 'country' },
        { title: <small>Category / Specialty</small>, dataIndex: 'category', key: 'category' },
    ]

    const data = [
        {
            "name": "Coho Books",
            "san": "8101078",
            "city": "Campbell River",
            "province": "BC",
            "country": "Canada",
            "category": "Bookstore / General"
        },
        {
            "name": "Coho Books",
            "san": "8101078",
            "city": "Campbell River",
            "province": "BC",
            "country": "Canada",
            "category": "Bookstore / General"
        },
        {
            "name": "Coho Books",
            "san": "8101078",
            "city": "Campbell River",
            "province": "BC",
            "country": "Canada",
            "category": "Bookstore / General"
        },
        {
            "name": "Coho Books",
            "san": "8101078",
            "city": "Campbell River",
            "province": "BC",
            "country": "Canada",
            "category": "Bookstore / General"
        },
        {
            "name": "Coho Books",
            "san": "8101078",
            "city": "Campbell River",
            "province": "BC",
            "country": "Canada",
            "category": "Bookstore / General"
        },
        {
            "name": "Coho Books",
            "san": "8101078",
            "city": "Campbell River",
            "province": "BC",
            "country": "Canada",
            "category": "Bookstore / General"
        },
        {
            "name": "Coho Books",
            "san": "8101078",
            "city": "Campbell River",
            "province": "BC",
            "country": "Canada",
            "category": "Bookstore / General"
        }
    ]


    const catfilters = [

        {
            label: "Categories",
            data: [
                {
                    count: "596",
                    name: "Bookstore"
                },
                {
                    count: "297",
                    name: "Distributor"
                },
                {
                    count: "10",
                    name: "Non-Profit"
                },
                {
                    count: "64",
                    name: "Other Retail"
                },
                {
                    count: "425",
                    name: "Parent Company"
                },
                {
                    count: "578",
                    name: "Publisher"
                },
                {
                    count: "23",
                    name: "Sales &amp; Marketing"
                },
                {
                    count: "67",
                    name: "Small Press"
                },
                {
                    count: "66",
                    name: "Wholesaler"
                },
            ]
        },
        {
            label: "Specialty",
            data: [
                { name: "Country", count: "1" }
            ]
        },
        {
            label: "Prov / State",
            data: [
                { name: "AB", count: "97" }
            ]
        },
        {
            label: "City",
            data: [
                { name: "Airdrie", count: "3" }
            ]
        },
        {
            label: "Country",
            data: [
                { name: "Canada", count: "968" }
            ]
        },
    ];


    const drawItems = (_arr) => {
        return _arr.map((item, index) => {
            return (
                <>
                    <div key={index} style={{ "paddingTop": "10px" }} >
                        <Checkbox />
                        &nbsp;&nbsp;
                        <span>{item.name}</span><span className="count">({item.count})</span>
                    </div>
                </>
            )
        })



    }
    const drawPanels = () => {

        return catfilters.map((item, index) => {
            return (
                <>
                    <Collapse.Panel header={item.label} key={index}>
                        {drawItems(item.data)}
                        <div className="shim"></div><div className="shim"></div>
                    </Collapse.Panel>
                </>
            )
        })

    }

    const drawPagination = () => {

        return (
            <div style={{ "backgroundColor": "#fcfcfc", "margin": "0px -20px", "marginTop": "0px", "borderBottom": '1px solid #eee' }}>
                <Row>
                    <Col span={12}>
                        <div style={{ "padding": "10px 20px" }}>
                            Showing <em style={{ "fontWeight": "500" }}>1-20 </em> of <em style={{ "fontWeight": "500" }}>10000</em> results
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{ "paddingRight": "20px", "textAlign": "right" }}>
                            <div className="shim" /><div className="shim" />
                            <Pagination size="small" current={current} pageSize={pageSize} total={results.row_count} onChange={(_page, _limit) => pageChange(_page, _limit)} />
                            <div className="shim" /><div className="shim" />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }


    return (
        <Layout className="layout">
            <LayoutHeader
                todo
                title="Business Directory"
                filters={
                    <Form size="small" layout="vertical">
                        <Space>
                            <Form.Item label={<><small>Search <InfoCircleOutlined onClick={() => Modal.info({ content: <BusinessDirectoryHelp />, icon: null, title: "Book Business Directory - Help" })} style={{ color: 'rgba(0,0,0,.45)' }} /> </small></>} name="customer_search">
                                <Input.Search allowClear enterButton="Search" placeholder="Search business directory..." style={{ "width": "400px" }} />
                            </Form.Item>
                            <div />
                            <Form.Item label={<small>On Pubstock</small>}>
                                <Switch size="small" />
                            </Form.Item>
                        </Space>
                        <br clear="both" />
                    </Form>
                }
            />

            <Layout.Content style={{ "padding": "0px" }} >
                <div style={{ "padding": "0px 20px" }}>
                    <div style={{ "padding": "20px 0px" }}>
                        {/* <Typography.Title level={5}>Employees</Typography.Title> */}
                        <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                        <Paginate />
                            <div style={{ "margin": "0 20px" }}>
                                <div style={{ "margin": "0 -20px" }}>
                                    <Table size="small" pagination={false}
                                        columns={columns}
                                        dataSource={data}
                                    />
                                </div>
                            </div>
                            <Paginate />
                        </Card>
                    </div>
                </div>
            </Layout.Content>
        </Layout>

    )



}