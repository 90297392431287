import React, { useState } from "react";
import { Select, Checkbox, Space, Button, Form, Typography, Divider } from "antd";
import { apiCall } from "../../utils/Api";

export default function ListCreatePdf(props) {

    const { 
        is_catalogue = false,
        count = 0, 
        list_id = "lWdPKvwwjI8", 
        close = () => { } 
    } = props;

    const layout = [
        {
            label: "15 per page (3 columns)",
            key: "threebyfive",
            value: "15"
        },

        {
            label: "8 per page (2 columns)",
            key: "twobyfour",
            value: "8"
        },

        {
            label: "5 per page (1 column)",
            key: "onebyfive",
            value: "5"
        },

        {
            label: "2 per page (1 column)",
            key: "onebytwo",
            value: "2"
        },

        {
            label: "1 per page (1 column)",
            key: "onebyone",
            value: "15"
        }
    ];

    const [options, setOptions] = useState(
        [
            // [!NEW]
            {
                label: "Use Sans Serif?",
                value: "is_sans_serif",
                checked: true,
            },
            {
                label: "Print Quality Images?",
                value: "is_print_quality",
                checked: false,
            },
            {
                label: "Include Onhand and Onorder",
                value: "show_ohoo",
                checked: false,
            },
            {
                label: "Include Carton Quantity",
                value: "show_ctn_qty",
                checked: true,
            },
            {
                label: "Barcodes",
                value: "show_barcodes",
                checked: false,
            },
            {
                label: "Show My Price (if in stock)",
                value: "show_storeprice",
                checked: true,
            },
            {
                label: "Fallback to List Price if not in stock",
                value: "show_listprices",
                checked: true,
            },
            {
                label: "Include Cover Page",
                value: "include_cover",
                checked: false,
            },
            {
                label: "Include Public Comments (layouts: 1 per, 2 per, 5 per)",
                value: "show_public_comments",
                checked: false,
            },
            {
                label: "Include Private Comments (layouts: 1 per, 2 per, 5 per)",
                value: "show_private_comments",
                checked: false,
            },
            {
                label: "Include Reviews (layouts: 1 per, 2 per)",
                value: "show_reviews",
                checked: false,
            },
            {
                label: "Include Biographies (layouts: 1 per, 2 per)",
                value: "show_biographies",
                checked: false,
            },
            {
                label: "Marketing (for internal use) (layouts: 1 per)",
                value: "show_marketing",
                checked: false,
            }
        ]
    )
    
    const [selectedLayout, setSelectedLayout] = useState(layout[0].key);
    const [form] = Form.useForm();

    const onFormFinish = (_f) => {
        _f["list_id"] = list_id;

        // [!NEW]
        _f["is_catalogue"] = is_catalogue; 
        _f["source"] = "store"; 

        // [!NEW]
        apiCall("titlelist/pdf/build", _f, (_status, _result) =>{
            if(_status){
                window.open("/pdf/" + _result.progress_report_id, "_blank")
            }
        })

        // apiCall("titlelist/getPdf", _f, (_status, _result) =>{
        //     if(_status){
        //         window.open("/pdf/" + _result.progress_report_id, "_blank")
        //     }
        // })
    }

   

    const isDisabled = (_item) => {
        let a = (selectedLayout === "threebyfive" || selectedLayout === "twobyfour");
        let b = (selectedLayout === "onebyfive");
        let c = (selectedLayout === "onebytwo");
        switch (_item.value) {
            case "show_public_comments":
                return a;
            case "show_private_comments":
                return a;
            case "show_reviews":
                return (a || b);
            case "show_biographies":
                if (selectedLayout === "onebyone") {
                    return true;
                }
                return (a || b);
            case "show_marketing":
                return (a || b || c);
            default:
                return false;
        }
    }

    const onChange = (_val, _ind) => {
        let _options = [...options];
        if (_ind === 5 && !_val) {
            console.log("called")
            _options[6].label = "Show List Price";
        }
        if (_ind === 5 && _val) {
            _options[6].label = "Fallback to List Price if not in stock";
        }
        _options[_ind].checked = _val;
        setOptions(_options);
    }

    const onSelectionChange = (_sel) => {
        switch (_sel) {
            case "onebyone":
                form.setFieldsValue({ show_biographies: true });
                break;
            case "onebytwo":
                form.setFieldsValue({ show_marketing: "" });
                break;
            case "onebyfive":
                form.setFieldsValue({ show_reviews: "" });
                form.setFieldsValue({ show_biographies: "" });
                form.setFieldsValue({ show_marketing: "" });
                break;
            default:
                form.setFieldsValue({ show_public_comments: "" });
                form.setFieldsValue({ show_private_comments: "" });
                form.setFieldsValue({ show_reviews: "" });
                form.setFieldsValue({ show_biographies: "" });
                form.setFieldsValue({ show_marketing: "" });
                break
        }
        setSelectedLayout(_sel)
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Create PDF ({count.toString()} titles)</Typography.Title>
                <Divider />
                <Form form={form} onFinish={(f) => onFormFinish(f)}>
                    <Form.Item name="layout" label="Layout Option" initialValue={selectedLayout}>
                        <Select onChange={(e) => onSelectionChange(e)} style={{ "width": "200px" }}>
                            {layout.map(item => {
                                return (<Select.Option value={item.key}>{item.label}</Select.Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Space direction="vertical">
                        {options.map((item, index) => {
                            return (
                                <Form.Item valuePropName="checked" initialValue={item.checked} name={item.value} noStyle >
                                    <Checkbox disabled={isDisabled(item)} onChange={(e) => onChange(!item.checked, index)}  >&nbsp; 
                                    <span className={(item.value === "show_biographies" && selectedLayout === "onebyone") ? "c" : ""}>{item.label}</span>
                                    
                                    </Checkbox>
                                </Form.Item>
                            )
                        })}
                    </Space>
                    <Divider />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => close()}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Create PDF</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </div>
        </>
    )
}