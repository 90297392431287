import react, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { apiCall } from "../../utils/Api";
import { Button, message, Result, Skeleton } from "antd";

export default function QuickbooksIntegration() {

    const { search } = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get("code");
    const realm = searchParams.get("realmId");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(""); 
    

    const getKeys = () => {
        setLoading(true);
        apiCall("quickbooks/getKeys", {}, (_status, _result) => {
            if (_status) {
                setError("Already connected.");
                setLoading(false);
            } else {
                processAuthToken(); 
            }
        })
    }


    const processAuthToken = () => {
        window.history.replaceState({}, '');

        const _obj = {
            code: code,
            realmId: realm
        }
        apiCall("quickbooks/processAuthToken", _obj, (_status, _result) => {
            if (_status) {
               
            } else {
                setError(_result.error)
            }
            setLoading(false);
        })
    }

    useEffect(getKeys, [])

    if(loading){
        return <Skeleton />
    }


    if(error){
        return(
            <Result
            status="error"
            title="There was an error"
            subTitle={<>{error}</>}
            extra={[
                <Button onClick={() => history.push("/settings/web-store-settings/app_integrations")} type="primary" key="console">
                    Back to App Integrations
                </Button>
            ]}
        />
        )
    }

    return (
        <>
            <Result
                status="success"
                title="Bookmanager is Now Connected to QuickBooks"
                subTitle={<>The connection between Bookmanager and QuickBooks has been successfully established. <br />From now on, your journal entries, bills, and vendor credits will be seamlessly sent directly to your QuickBooks account.</>}
                extra={[
                    <Button onClick={() => history.push("/settings/web-store-settings/app_integrations")} type="primary" key="console">
                        Back to App Integrations
                    </Button>
                ]}
            />
        </>
    )
}