import React, { useState, useEffect } from "react";
import { Tag, Pagination, Space, Checkbox, Typography, Table, Button } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";
import moment from "moment";
import conditional from "../../tools/conditional";
import Title from "../title/Title";
import InvoiceActivity from "./InvoiceActivity";
import { bmDate } from "../../utils/Utils";

export default function PurchaseActivity(props) {

    const { supplier, purchase_order, close = () =>{} } = props;
    const [results, setResults] = useState({ customer_info: {}, total: 0, rows: [] });
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState([]);
    const [drawType, setDrawType] = useState("")

    const [filters, setFilters] = useState({
        supplier: supplier,
        purchase_order: purchase_order,
        limit: 10,
        offset: 0,
    });

    const getCustomerActivity = () => {
        apiCall("activity/getByPurchaseOrder", filters, (_status, _result) => {
            if (_status) {
                _result.rows = addKeys(_result.rows);
                setResults(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getCustomerActivity, [filters]);

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }



    const columns = [
        {
            title: <small>Date</small>, dataIndex: "date", key: "date", width: "60px", render: (e) => {
                return (e) ? bmDate(e * 1000) : "";
            }
        },
        { title: <small>Status</small>, dataIndex: "status", key: "status" },
        { title: <small>Qty</small>, dataIndex: "quantity", key: "quantity" },
        { title: <small>Inv.</small>, dataIndex: "invoice_price", key: "invoice_price", render: (e) => { return (e) ? "$" + e.toString() : "" } },
        { title: <small>Discount</small>, dataIndex: "invoice_discount", key: "invoice_discount", render: (e) => { return (e) ? e.toString() + "%" : "" } },
        { title: <small>Profit</small>, dataIndex: "profit_margin", key: "profit_margin", render: (e) => { return (e) ? e.toString() + "%" : "" } },
        { title: <small>Sell</small>, dataIndex: "sell_price", key: "sell_price", render: (e) => { return (e) ? "$" + e.toString() : "" } },
        {
            title: <small>Title</small>, className: "nowrap", dataIndex: "title", key: "title"
        },
        {
            title: <small>Invoice</small>, dataIndex: "invoice_display", key: "invoice_display", render: (e, f) => {
                return <a onClick={() => expandCell(f, "invoice")}>{e}</a>
            }
        },
        { title: <small>Clrk</small>, dataIndex: "cashier", key: "cashier" },
        { title: <small>Notes</small>, width: "100px", dataIndex: "notes", key: "notes" }
    ]



    const drawHeader = (_info = {}) => {


        const {
            is_bo = false,
            code_type = "",
            cancel_date = null,
            first_received = null,
            order = "",
            list_total = "",
            net_total = "",
            supplier_info = {
                supplier: "",
                created: null,
                name: "",
                address_1: "",
                address_2: "",
                city: "",
                province: "",
                country: "",
                email: "",
                phone: "",
                fax: ""
            }
        } = _info;



        return (

            <>

                <div className="bc" style={{ "borderBottom": "1px solid", "textAlign": "left", "fontSize": "12px", "padding": "0px 8px", "height": "22px", "lineHeight": "20px" }}>
                    <strong className="c"><small>Purchase Activity</small></strong>
                    <div style={{ "float": "right" }}><Button onClick={() => close([])} className="tiny-btn" size="small" type="text"><small style={{ "fontSize": "10px" }}><CloseOutlined /></small></Button></div>
                </div>


                <div style={{ "padding": "8px", "paddingBottom": "0px", "margin": "0px 0px" }}>
                    <div style={{ "float": "right", "textAlign": "right" }}>
                        <Typography.Paragraph style={{ "fontSize": "12px" }}>

                            <conditional.true value={(list_total)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>Created: </strong>{bmDate(supplier_info.created * 1000)}</span>
                            </conditional.true>
                            <conditional.true value={(first_received)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>1st Received: </strong>{bmDate(first_received * 1000)}</span>
                            </conditional.true>
                            <conditional.true value={(cancel_date)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>Cancel: </strong>{bmDate(cancel_date * 1000)}</span>
                            </conditional.true>
                            <conditional.true value={(list_total)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>List: </strong>${list_total}</span>
                            </conditional.true>
                            <conditional.true value={(net_total)}>
                                <span style={{ "fontSize": "11px", "display": "block" }}><strong>Net: </strong>${net_total}</span>
                            </conditional.true>

                        </Typography.Paragraph>
                    </div>
                    <Typography.Paragraph style={{ "fontSize": "12px" }}>
                        <conditional.true value={(order)}>
                            <span style={{ "color": "#069", "fontSize": "12px", "display": "block" }}><strong>Order {order}</strong></span>
                        </conditional.true>
                        <conditional.true value={(supplier_info.address_1 || supplier_info.address_2)}>
                            {supplier_info.address_1} {supplier_info.address_2} <br />
                        </conditional.true>
                        <conditional.true value={(supplier_info.city)}>
                            {supplier_info.city}, {supplier_info.province} {supplier_info.country} <br />
                        </conditional.true>
                        <conditional.true value={(supplier_info.phone)}>
                            <conditional.true value={(supplier_info.phone)}><strong>{supplier_info.phone}</strong></conditional.true>
                        </conditional.true>
                        <span style={{ "fontSize": "11px", "display": "block" }}><strong>B/O: </strong>{(is_bo) ? "Yes" : "No"}</span>
                        <span style={{ "fontSize": "11px", "display": "block" }}><strong>Send: </strong>{code_type.toUpperCase()}</span>
                    </Typography.Paragraph>
                </div>
            </>
        )
    }

    const drawChildTable = (_record) => {
        if (drawType === "title") {
            return <Title isbn={_record.isbn} />;
        }
        if (drawType === "invoice") {
            return <InvoiceActivity invoice_is_supplier={_record.invoice_is_supplier} invoice={_record.invoice_key} />
        }
        return (<></>);
    }

    const expandCell = (_data, _type) => {
        setDrawType(_type);
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            if (_type === drawType) {
                setExpanded([]);
            } else {
                setExpanded([_data.key]);
            }
        }
    }


    if (loading) {
        return (<div style={{ "margin": "-8px" }}></div>);
    }

    return (
        <div style={{ "margin": "0px", backgroundColor: "#fff" }}>
            <Table
                title={() =>
                    <>
                        {drawHeader(results.header_info)}
                        <div className="shim" />
                    </>
                }
                size="small"
                columns={columns}
                className={"tableNoBorder"}
                dataSource={results.rows}
                pagination={false}
                expandable
                expandedRowKeys={expanded}
                expandIconColumnIndex={-1}
                onExpand={(e) => { return false; }}
                expandIcon={() => { return (<></>) }}
                expandedRowRender={((record) => drawChildTable(record))}
                footer={
                    () =>
                        <>&nbsp;</>
                    // <conditional.true value={(results.total > 10)}>
                    //     <div style={{ "margin": "-8px", "backgroundColor": "#fff", "padding": "8px 8px", "textAlign": "right" }}>
                    //         <Pagination size="small" current={((filters.offset / filters.limit) + 1)} onChange={(e, f) => setFilters({ ...filters, "offset": (e - 1) * f, "limit": f })} total={results.total} showSizeChanger />
                    //     </div>
                    // </conditional.true>
                }
            />
        </div>
    )
}