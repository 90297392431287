import React from "react";
import moment from "moment";
import { Table } from "antd";
export default function SalesSummary(props) {

    const { conditions = [{}] } = props;

    const parseData = () => {
        let c = conditions[0];
        let ret = []
        if (c.hasOwnProperty("received")) {
            if (c.received) {
                if(!c.received.hasOwnProperty("months")){
                    c.received.months = [0,0,0,0,0,0,0,0,0,0,0,0,0,0];
                }
                ret.push({
                    className: "rowRcvd",
                    s1: c.received.months[11],
                    s2: c.received.months[12],
                    s3: c.received.months[13],
                    total: c.received.total,
                    week: c.received.week
                })
            }
        }
        if (c.hasOwnProperty("sold")) {
            if (c.sold.hasOwnProperty("months")) {

                if(!c.sold.hasOwnProperty("months")){
                    c.sold.months = [0,0,0,0,0,0,0,0,0,0,0,0,0,0];
                }

                ret.push({
                    className: "rowSold",
                    s1: c.sold.months[11],
                    s2: c.sold.months[12],
                    s3: c.sold.months[13],
                    total: c.sold.total,
                    week: c.sold.week
                })
            }
        }
        if (c.hasOwnProperty("peer")) {
            if (c.peer.hasOwnProperty("months")) {
                ret.push({
                    className: "rowPeer",
                    s1: c.peer.months[11],
                    s2: c.peer.months[12],
                    s3: c.peer.months[13],
                    // don't display total on peer data
                    total: c.peer.total_stores + c.peer.total_stores_region,
                    week: c.peer.week
                })
            }
        }
        // fill out the table if it's missing rows
        let diff = 3 - ret.length; 
        if(diff){
            for(let i = 0; i<diff; i++){
            ret.push({total : <>&nbsp;</>})
            }
        }
        return ret;
    }
    const checkBold = (e, f) => {
        if (!e) {
            return "";
        }
        if (f.label !== "Adj" && f.label !== "Retn" && f.label !== "Peer") {
            return (<strong>{e}</strong>);
        } else {
            return e;
        }
    }

    const columns = [
        {
            title: <small className="c">Ttl</small>, width: "25px", className: "cellBorder", dataIndex: 'total', key: 'total', render: (e, f) => { return (e) ? e : "" }
        },
        { title: <small>{moment().startOf("month").add(-2, "month").format("MMM")}</small>, dataIndex: 's1', key: 's1', render: (e, f) => { return checkBold(e, f) } },
        { title: <small>{moment().startOf("month").add(-1, "month").format("MMM")}</small>, dataIndex: 's2', key: 's2', render: (e, f) => { return checkBold(e, f) } },
        { className: "cellBorder", title: <small>{moment().startOf("month").format("MMM")}</small>, dataIndex: 's3', key: 's3', render: (e, f) => { return checkBold(e, f) } },
        {
            title: <small>Wk</small>, width: "27px", dataIndex: 'week', key: 'week', render: (e, f) => {
                return (e) ? <em>{e}</em> : "";
            }
        },
    ]
    return (
        <>
            <Table
                rowClassName={(record, index) => {
                    return record.className;
                }}
                size="small"
                className="miniHistory columnHeaders"
                style={{ "marginRight": "1px", "borderTop": "none", "width": "0px" }}
                dataSource={parseData()}
                columns={columns}
                pagination={false}
            />
        </>
    )
}