import React, { useState } from "react";
import { Layout, Space, Form, Button, Table, Input, message, Popconfirm, Tooltip } from "antd";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiCall } from "../utils/Api";
import moment from "moment";
import { parseAuthorsPlain } from "../utils/Utils";
import conditional from "../tools/conditional";
import { WarningOutlined } from '@ant-design/icons';

export default function ManageCustomers() {

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [results, setResults] = useState({ carts: [] })

    const searchUser = (_email) => {
        if (!_email) {
            setResults({ carts: [] });
            return;
        }
        setLoading(true);
        apiCall("cart/getCustomerCart", { customer_email: _email }, (_status, _result) => {
            if (_status) {
                if (_result.carts.length === 0) {
                    message.error("Customer cart not found")
                }
                setResults(_result);
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }

    const submitCustomerCart = (_customer_san, _bookorder_id) => {
        apiCall("cart/submitCustomerCart", { customer_san: _customer_san, bookorder_id: _bookorder_id }, (_status, _result) => {
            if (_status) {
                // clear
                message.success("Cart submitted successfully.");
                setResults({ carts: [] });
            } else {
                message.error(_result.error);
            }
        })
    }

    const columns = [
        { title: <small></small>, width: "10px", dataIndex: 'blank', key: 'blank' },
        {
            title: <small></small>, width: "50px", dataIndex: 'eisbn', key: 'eisbn', render: (e, f) => {
                return <img className="shadow-large" style={{ "maxHeight": "50px", "maxWidth": "50px" }} onError={(e) => e.target.src = "/no-cover.png"} src={"https://bookmanager.com/i/slider_23.php?b=" + f.title_info.eisbn} />
            }
        },
        { title: <small></small>, width: "10px", dataIndex: 'blank', key: 'blank' },
        { title: <small>Title</small>, dataIndex: 'title', key: 'title', render: (e, f) => { return (f.title_info.title) } },
        {
            title: <small>Authors</small>, dataIndex: 'authors', key: 'authors', render: (e, f) => {
                return parseAuthorsPlain(f.title_info.authors)
            }
        },
        { title: <small>Binding</small>, dataIndex: 'binding', key: 'binding', render: (e, f) => { return (f.title_info.binding) } },
        { title: <small>Release Date</small>, dataIndex: 'release_date', key: 'release_date', render: (e, f) => { return (moment(f.title_info.release_date * 1000).format("MMMM Do, YYYY")) } },
        { title: <small>Qty</small>, dataIndex: 'quantity', key: 'quantity', render: (e, f) => { return (f.cart_info.quantity) } },
        {
            title: <small></small>, dataIndex: 'quantity', key: 'quantity', render: (e, f) => {
                // Draw a warning icon and label if the item is unavailable
                return (!f.cart_info.cartable) ? <div style={{ "textAlign": "center" }}><WarningOutlined style={{ "color": "#e77b21" }} /><br /> Unavailable</div> : ""
            }
        },

    ];

    // Warn if cart contains unavailable items 
    const confirmText = (_cart) => {
        if (_cart.contains_unavailable) {
            return (<><strong>This cart contains unavailable items.</strong> <br /> Are you sure you wish to submit?</>)
        } else {
            return (<>Are you sure?</>)
        }
    }

    const drawCart = (_customer, _order_id, _san, _cart) => {
        const { items = [], summary = {}} = _cart;
        const {
            item_count = "",
            total_quantity = ""
        } = summary;

        const qty_unavailable = items.filter(item => !item.cart_info.cartable).length; 

        return (
            <>
                <Table
                    loading={loading}
                    className="customerCart bc"
                    size="small"
                    style={{ "border": "1px solid" }}
                    scroll={{ y: 330 }}
                    locale={{ emptyText: "Cart is empty." }}
                    title={() => <>
                        <div>
                            <div style={{ "float": "right" }}><Popconfirm disabled={(_cart.items.length === 0)} onConfirm={() => submitCustomerCart(_san, _order_id)} title={confirmText(_cart)}><Button disabled={(_cart.items.length === 0)} size="small" type="primary"><small>Submit Cart</small></Button></Popconfirm></div>
                            <div style={{ "padding": "4px", "fontSize": "14px" }}>
                                <Space>
                                    <span>{_customer}'s cart  ({item_count} titles, {total_quantity} items)</span>
                                    {(_cart.contains_unavailable && <Space>
                                        <strong style={{ "cursor": "pointer" }}><WarningOutlined style={{ "color": "#e77b21" }} /> 
                                        <small> Contains {qty_unavailable} unavailable item(s)</small>
                                        </strong>
                                        <strong style={{ "cursor": "pointer" }}><WarningOutlined style={{ "color": "#f00" }} /> 
                                        <small> <span style={{"color" : "#f00"}}>Unavailable items will be removed upon submit.</span></small>
                                        </strong>
                                       
                                    </Space>)}
                                </Space>
                            </div>
                        </div>

                    </>}
                    pagination={false}
                    columns={columns}
                    // If there are uncartable items, sort to the top (easier to see)
                    dataSource={(_cart.contains_unavailable) ? items.sort((a, b) => {
                        return (a.cart_info.cartable === b.cart_info.cartable) ? 0 : a ? -1 : 1;
                    }) : items}
                />
                <div className="shim" /><div className="shim" /><div className="shim" />
            </>
        )
    }

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Manage Customer Carts"
                    filters={
                        <Form size="small" layout="vertical">
                            <Space>
                                <Form.Item label={<small>Customer email address</small>} name="keyword_search">
                                    <Input.Search type="email" onSearch={(e) => searchUser(e)} onChange={(e) => setSearch(e.target.value)} allowClear enterButton placeholder="Email address..." style={{ "width": "380px" }} />
                                </Form.Item>
                            </Space>
                            <br clear="both" />
                        </Form>
                    }
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px", "minHeight": "calc(100vh - 400px)" }}>
                        <conditional.true value={(results.carts.length > 0)}>
                            {results.carts.map(item => {
                                return drawCart(item.customer, item.order_id, item.customer_san, item.cart);
                            })}
                        </conditional.true>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )

}