import React from "react";
import { InputNumber, Typography, Divider, Table, Card } from "antd";
import ETARangePicker from "./ETARangePicker";
import MSWrap from "../layouts/Webstore/sections/MSWrap";
import { drawMSTitleBar } from "../utils/Utils";

export default function VendorETA(props) {

    const { changed = false, setChanged = () => { }, suppliers = {}, setSuppliers = () => { } } = props;

    const primary = (window.sitesettings.stores[0]);
    const stores = (primary.is_primary) ? window.sitesettings.stores : [window.sitesettings.stores.find(item => item.is_primary)];

    const updateETA = (_min, _max, _obj, _store) => {
        let res = [...suppliers[_store]];
        let find = res.findIndex(item => item.acct === _obj.acct);
        if (!Number.isInteger(_min)) { _min = 0; }
        if (!Number.isInteger(_max)) { _max = 0; }

        if (_max <= _min) {
            _max = _min;
        }

        if (find > -1) {
            _obj.estimate_delivery = _min;
            _obj.estimate_delivery_max = _max;
            res[find] = _obj;
            setSuppliers({...suppliers, [_store] : res});
            setChanged(true);
        }
    }


    return (
        <div id="pubstocksetup" style={{ "padding": "10px 30px" }}>
            <Typography.Title level={4}>Vendor ETAs</Typography.Title>
            <Divider />
            <Typography.Paragraph type="secondary" >
                These Pubstock suppliers are possible sources for consumer special orders. Configuration for these suppliers is done within the Bookmanager software (<strong>3 Suppliers</strong> and the <strong>Format</strong> option when viewing Pubstock). Only suppliers marked with <strong>Favourite</strong> or <strong>Yes</strong> in the Bookmanager Software will be listed here and considered as sources of supply for customer special orders. <br /><br />Use the <strong>Order Time-frame</strong> field to specify how long it takes in business days to receive product <em>currently stocked</em> from each supplier, from the time a customer places their order until it is ready for in-store pick up. We recommended erring on the side of longer, as customers will always be happy to receive their order earlier than quoted.
            </Typography.Paragraph>

            <MSWrap padding={0} stores={stores}>
                {(stores.map((item, index) => {

                    const columns = [
                        {},
                        {
                            title: <small>Pubstock Code</small>, dataIndex: 'acct', key: 'acct', render: (e) => {
                                return (e) ? e.toUpperCase() : "";
                            }
                        },
                        { title: <small>Supplier Name</small>, dataIndex: 'name', key: 'name' },
                        {
                            title: <small>Order Time-frame <small> (business days)</small></small>, dataIndex: 'estimate_delivery', key: 'estimate_delivery', render: (e, f) => {
                                return <ETARangePicker obj={f} updateETA={(a, b, c) => updateETA(a, b, c, "ms" + item.store_number)} estimate_delivery={e} estimate_delivery_max={f.estimate_delivery_max} />
                            }
                        },
                        { title: <small>Exchange</small>, dataIndex: 'exchange', key: 'exchange' },
                        { title: <small>Markup</small>, dataIndex: 'markup', key: 'markup' },
                        { title: <small>SD</small>, dataIndex: 'min_margin', key: 'min_margin' },
                    ]


                    return (
                        <>
                            <div style={{ "padding": "10px 23px" }}><div className="shim" />{drawMSTitleBar(stores, item.name, index, false)}</div>
                            <Table
                                size="small"
                                rowKey={"acct"}
                                className={(stores.length > 1) ? "" : "bdr"}
                                pagination={false}
                                columns={columns}
                                dataSource={suppliers["ms" + item.store_number]}
                            />

                        </>
                    )
                }))}
            </MSWrap>

            <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>



            </Card>
            <div className="shim" /><div className="shim" /><div className="shim" />
        </div>
    )


}