import { CloseOutlined, ShareAltOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Divider, Form, Input, Layout, message, Modal, Radio, Row, Space, Table, Tag, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ClockCircleFilled, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import BusinessContactInfo from "../../components/BusinessContactInfo";
import ListCategories from "../../components/lists/ListCategories";
import Lists from "../../components/lists/Lists";
import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import Overlay from '../../components/Overlay';

export default function Rep(props) {


    const [reason, setReason] = useState("");
    const [expanded, setExpanded] = useState([]);
    const { updateRow = () => { }, data = {}, index = 0 } = props;

    // const {
    //     relationship_id = "",
    //     contact_id = "",
    //     name = "",
    //     email = "",
    //     city = "",
    //     province = "",
    //     status = "",
    //     is_my_rep = false,
    //     declined_reason = null,
    //     companies_represented = []
    // } = data;


    const {
        contact_id = "",
        name = "",
        san = "",
        email = "",
        city = "",
        province = "",
        has_basic_link = false,
        basic_link_status = false,
        my_account_relionship_info = {
            relationship_id: "",
            status: "",
            declined_reason: null
        },
        share_relationship_info = {
            relationship_id: "",
            status: "",
            declined_reason: null
        },
        companies_represented = [
            {
                contact_id: "",
                name: "",
                country: "",
                province: "",
                city: "",
                email: "",
                category: ""
            }
        ]
    } = data;

    const columns = [
        { title: <small></small>, dataIndex: 'space', key: 'space', width: "177px" },
        {
            title: <small>Name</small>, dataIndex: 'name', key: 'name', render: (e, f) => {
                return (<strong><a onClick={() => expandCell(f)}>{e}</a></strong>)
            }
        },
        { title: <small>Category</small>, dataIndex: 'category', key: 'category', width: "200px" },
        { title: <small>Email</small>, dataIndex: 'email', key: 'email', width: "300px" },
        { title: <small>City</small>, dataIndex: 'city', key: 'city', width: "100px" },
        { title: <small>Province</small>, dataIndex: 'province', key: 'province', width: "30px" },
        { title: <small>Country</small>, dataIndex: 'country', key: 'country', width: "80px" },

    ]


    const getIcon = (e, _is_account) => {

        let tip = "";
        if (_is_account) {
           tip = <>A <strong style={{ "color": "#78c042" }}>green</strong> checkmark here indicates you've confirmed this is one of your reps, which means they can share their titles, catalogues and comments with you. An <strong style={{ "color": "#e77e21" }}>orange</strong> 'requested' means you have not yet accepted the rep request, and a <strong style={{ "color": "#A52A2A" }}>red</strong> X means that you have declined the rep request. To approve, or decline, or disable a current rep link, click <strong>edit settings</strong>.</>
        } else {
           tip = <>A <strong style={{ "color": "#78c042" }}>green</strong> checkmark here indicates you are sharing sales, stock and order data with your rep (for the titles they sell). An <strong style={{ "color": "#e77e21" }}>orange</strong> 'requested' means you have not yet approved the data sharing with them, and a <strong style={{ "color": "#A52A2A" }}>red</strong> X means you have declined the data share. To approve, or decline, or disable an existing data share, click <strong>edit settings</strong>. If you decline a new data share request, or disable an existing one, you will be given the option to provide a reason. (Removing the check on "My Rep" will disable the rep from being able to see your data, AND from sharing lists or comments with you).</>;
        }

        if (e === "approved" || e === "approved_inactive") {
            return (<Tooltip title={<small>{tip}</small>}><CheckCircleFilled style={{ "fontSize": "20px", "color": "#78c042", "cursor": "pointer" }} /></Tooltip>)
        } else if (e === "requested" || e === "rerequested") {
            return (<Tooltip title={<small>{tip}</small>}><ClockCircleFilled style={{ "fontSize": "20px", "color": "#e77e21", "cursor": "pointer" }} /></Tooltip>)
        } else if (e === "declined" || e === "removed") {
            return (<Tooltip title={<small>{tip}</small>}><CloseCircleFilled style={{ "fontSize": "20px", "color": "#A52A2A" }} /></Tooltip>)
        } else {
            return (<><CloseCircleFilled style={{ "fontSize": "20px", "color": "#A52A2A", "opacity" : "0" }} /></>)
        }

    }

    const drawChildTable = (_record) => {
        return (
            <>
                <div className="hideHover" style={{ "margin": "-8px -7px" }}>
                    <div style={{ "padding": "0 20px" }}>
                        <div style={{ "marginTop": "0px", "borderTop": "1px solid #eee", "marginBottom": "0px" }} className="closebar">

                            {/* <div style={{"fontWeight" : "600", "display" : "inline", "lineHeight" : "24px", "marginLeft" : "20px", "fontSize" : "11px"}} >{_record.name}</div> */}
                            <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                            <br clear="both" />
                        </div>
                    </div>
                    <div style={{ "padding": "0px 12px" }}>
                        <BusinessContactInfo id={_record.contact_id} />
                    </div>
                </div>
            </>
        );
    }

    const expandCell = (_data) => {
        if (!expanded.includes(_data.contact_id)) {
            setExpanded([_data.contact_id]);
        } else {
            setExpanded([]);
        }
    }

    const sendStoreConnectionRequest = () => {
        apiCall("rep_control/answerRepRequest", { contact_id: contact_id, approved: true }, (_status, _result) => {
            if (_status) {
                updateRow(_result);
            }
            setReason("");
        });
    }

    const removeStoreConnectionRequest = (_remove_share = false) => {

        window.reason = "";
        Modal.confirm({
            icon: <></>,
            okText: "Decline",
            onOk: () => {
                apiCall("rep_control/answerRepRequest", { contact_id: contact_id, reason: window.reason, approved: false }, (_status, _result) => {
                    if (_status) {
                        updateRow(_result);
                        if(_remove_share){
                            declineRequest(true); 
                        }
                        window.reason = "";
                    }
                    setReason("");
                });
            },
            okButtonProps: { danger: true },
            title: "Attention!",
            content: <>
                <Divider style={{ "margin": "15px 0px" }} />
                You have chosen to decline <strong>{name}'s</strong> request to connect with you
                <br /><br />
                Declining this request will also disable their ability to see your data.
                <br /><br />
                Please leave a comment below as to why you are declining this share request: <small><em style={{ "color": "#666" }}> (not mandatory, but recommended)</em></small>
                <br /><br />
                <Input.TextArea placeholder="Type your comment here" onChange={(e) => window.reason = e.target.value} />
            </>
        })




    }

    const approveRequest = () => {
        apiCall("rep_control/answerRequest", { contact_id: contact_id, approved: true }, (_status, _result) => {
            if (_status) {
                updateRow(_result);
            }
            setReason("");
        });
    }

    const confirmDeclineRequest = () => {
        apiCall("rep_control/answerRequest", { contact_id: contact_id, reason: window.reason, approved: false }, (_status, _result) => {
            if (_status) {
                updateRow(_result);
                window.reason = "";
            }
            setReason("");
        });
    }


    const declineRequest = (_skip = false) => {

        if(_skip){

            confirmDeclineRequest(); 
            return; 
        }

        window.reason = "";
        Modal.confirm({
            icon: <></>,
            okText: "Decline",
            onOk: () => confirmDeclineRequest(),
            okButtonProps: { danger: true },
            title: "Attention!",
            content: <>
                <Divider style={{ "margin": "15px 0px" }} />
                You are choosing to decline sharing your data with the rep <strong>{name}</strong>.
                <br /><br />
                Please leave a comment below as to why you are declining this share request: <small><em style={{ "color": "#666" }}> (not mandatory, but recommended)</em></small>
                <br /><br />
                <Input.TextArea placeholder="Type your comment here" onChange={(e) => window.reason = e.target.value} />
            </>
        })
    }


    const EditRepSettings = (props) => {

        let _account_checked = false;
        let _share_checked = false;

        if (my_account_relionship_info.status === "approved" || my_account_relionship_info.status === "approved_inactive") {
            _account_checked = true;
        }
        if (share_relationship_info.status === "approved" || share_relationship_info.status === "approved_inactive") {
            _share_checked = true;
        }

        return (
            <div style={{ "padding": "20px" }}>
                <strong>Edit setttings</strong>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <Checkbox checked={_account_checked} onChange={(e) => {
                    if(e.target.checked){
                        sendStoreConnectionRequest(); 
                    } else {
                        removeStoreConnectionRequest(_share_checked);

                    }
                }} >My Rep?</Checkbox>
                <div className='shim' />
                <Checkbox checked={_share_checked} onChange={(e) => {
                    if(e.target.checked){
                        approveRequest();
                    } else {
                        declineRequest();
                    }
                }} >Sharing data?</Checkbox>
                <div className='shim' /><div className='shim' />
                <div className='float-flex'>
                    <div>&nbsp;</div>
                    <Space>
                        <Button onClick={() => props.close()}>Close</Button>
                    </Space>
                </div>
            </div>
        )
    }


    const drawStatus = () => {
        let ret = "";
        if (has_basic_link && basic_link_status === "approved") {
            switch (share_relationship_info.status) {
                case "approved":
                    ret = <Tooltip title="Remove as rep"><Tag className="tag-btn" onClick={() => declineRequest()} color="#78c042"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
                    break;
                case "requested":
                    ret = <Space size={0}>
                        <Tooltip title="Approve the request for you to share your data with the rep"><Tag className="tag-btn" onClick={() => approveRequest()} color="#e77e21"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
                        <Tooltip title="Decline the request for you to share your data with the rep"><Tag className="tag-btn" onClick={() => declineRequest()} color="#f12a46"><strong><CloseOutlined /></strong></Tag></Tooltip>
                    </Space>
                    break;
                case "rerequested":
                    ret = <Space size={0}>
                        <Tooltip title="Approve the request for you to share your data with the rep"><Tag className="tag-btn" onClick={() => approveRequest()} color="#8f3fe4"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
                        <Tooltip title="Decline the request for you to share your data with the rep"><Tag className="tag-btn" onClick={() => declineRequest()} color="#f12a46"><strong><CloseOutlined /></strong></Tag></Tooltip>
                    </Space>
                    break;
                case "declined":
                    ret = <Tooltip title="Approve the request for you to share your data with the rep"><Tag className="tag-btn" onClick={() => approveRequest()} color="#f12a46"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
                    break;
                default:
                    ret = <Tooltip title="Rep has not yet requested to see your data"><Tag className="tag-btn" color="gray"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
            }
        } else {
            ret = <Tooltip title="Not able to share data until you are working with the rep"><Tag className="tag-btn" color="gray"><strong><ShareAltOutlined /></strong></Tag></Tooltip>
        }
        return ret;
    }

    const drawIfAccountCheckbox = () => {
        let ret = "";
        switch (basic_link_status) {
            case "approved":
                ret = <Tooltip title="You are connected, click to remove them as your rep"><Tag className="tag-btn" onClick={() => removeStoreConnectionRequest()} color="#78c042"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                break;
            case "requested":
                ret = <Space size={0}>
                    <Tooltip title="Approve the rep request to connect with you"><Tag className="tag-btn" onClick={() => sendStoreConnectionRequest()} color="#e77e21"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                    <Tooltip title="Decline the rep request to connect with you"><Tag className="tag-btn" onClick={() => removeStoreConnectionRequest()} color="#f12a46"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                </Space>
                break;
            case "rerequested":
                ret = <Space size={0}>
                    <Tooltip title="Approve the rep request to connect with you"><Tag className="tag-btn" onClick={() => sendStoreConnectionRequest()} color="#8f3fe4"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                    <Tooltip title="Decline the rep request to connect with you"><Tag className="tag-btn" onClick={() => removeStoreConnectionRequest()} color="#f12a46"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                </Space>
                break;
            case "declined":
                ret = <Tooltip title="Approve the rep request to connect with you"><Tag className="tag-btn" onClick={() => sendStoreConnectionRequest()} color="#f12a46"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>
                break;
            default:
                ret = <Tooltip title="Not working with this rep"><Tag className="tag-btn" onClick={() => sendStoreConnectionRequest()} color="#8f3fe4"><strong><UsergroupAddOutlined /></strong></Tag></Tooltip>

                break;
        }
        // switch (_status) {
        //     case true:
        //         ret = <> <Tooltip title="Click to stop working with this rep"><Checkbox defaultChecked={true} onChange={()=>removeStoreConnectionRequest()}/></Tooltip></>
        //         break;
        //     default:
        //         ret = <> <Tooltip title="You are not working with this rep"><Checkbox disabled defaultChecked={false} onChange={()=>sendStoreConnectionRequest()} /></Tooltip></>
        //         break;
        // }
        return ret;
    }

    return (
        <div style={{ "margin": "5px -20px" }}>
            <Card className="bc bcg c rep" size="small" headStyle={{ "border": "none" }} style={{ "padding": "10px 0px", "border": "none" }} title={<>
                <Space size={15}>
                    <div style={{ "width": "130px" }}>
                        <div style={{ "textAlign": "center" }}>
                            <Overlay width={300} component={<EditRepSettings data={data} />}>
                                <Button block size="small" type="primary"><small>Edit settings</small></Button>
                            </Overlay>

                            <Divider dashed style={{ "margin": "10px 0px", "marginTop": "15px" }} />
                        </div>
                        <div style={{ "width": "120px", "margin": "0 auto" }}>
                            <Space size={18}>
                                <div style={{ "textAlign": "center" }}>
                                    <div><small className='c'>My Rep</small></div>
                                    <div className='shim' />
                                    {getIcon(my_account_relionship_info.status, true)}
                                    {/* <ClockCircleFilled style={{ "fontSize": "20px" }} /> */}
                                </div>
                                <div style={{ "textAlign": "center" }}>
                                    <div><small className='c'>Sharing data</small></div>
                                    <div className='shim' />
                                    {getIcon(share_relationship_info.status, false)}
                                    {/* <ClockCircleFilled style={{ "fontSize": "20px" }} /> */}
                                </div>
                            </Space>
                        </div>
                    </div>

                    <div>&nbsp;</div><div>&nbsp;</div>

                    {/* <div style={{ "width": "100px" }}>
                        <div style={{ "width": "35px", "paddingBottom": "10px" }}>
                            {drawIfAccountCheckbox()}
                        </div>
                        <div style={{ "width": "35px" }}>
                            {drawStatus()}
                        </div>
                    </div> */}
                </Space>
                <div style={{ "float": "right", "fontSize": "11px", "fontWeight": "normal", "width": "210px", "marginRight": "-15px" }}>
                    <Row>
                        <Col flex={"100px"}>
                            <span className="c">{city}</span>
                        </Col>
                        <Col flex={"30px"}>
                            <span className="c">{province}</span>
                        </Col>
                        <Col flex={"80px"}>
                        </Col>
                    </Row>
                </div>
                <span className="c">{name} - <a href={"mailto:" + email} >{email}</a></span>
            </>}>
                <div style={{ "margin": "-8px -12px", "marginTop": "-20px" }}>
                    <Row>

                        <Col flex={"auto"}>
                            <conditional.true value={(companies_represented.length > 0)}>
                                <Table
                                    className="transparentTable"
                                    rowClassName={"hideHover"}
                                    size="small"
                                    pagination={false}
                                    dataSource={companies_represented}
                                    showHeader={false}
                                    columns={columns}
                                    rowKey="contact_id"
                                    expandable
                                    expandedRowKeys={expanded}
                                    expandIconColumnIndex={-1}
                                    onExpand={(e) => { return false; }}
                                    expandIcon={() => { return (<></>) }}
                                    expandedRowRender={((record) => drawChildTable(record))}
                                />
                            </conditional.true>
                        </Col>
                    </Row>
                </div>
            </Card>
        </div>
    )
}