import React, { useEffect, useState } from "react";
import { Layout, Typography, Divider, Button, Space, DatePicker, Statistic, Input, Form, Table, Modal, Popconfirm, message, Tooltip, Alert } from "antd";
import moment from "moment";
import { apiCall } from "../../utils/Api";
import { RollbackOutlined, EditOutlined } from '@ant-design/icons';
import { addCommas, pathToObj } from "../../utils/Utils";
import { useHistory, useParams } from "react-router-dom";
import Paginate from "../../components/Paginate";
import PreviousOrder from "../../components/PreviousOrder";
import Overlay from "../../components/Overlay";
import { useSession } from "../../utils/Session";

export default function EmailedGiftCards() {

    const [results, setResults] = useState({ row_count: 0, rows: [], issued: 0, sent: 0, unsent: 0, giftcard_remote_tracking : "" });
    const [loading, setLoading] = useState(false);
    const [session, setSession] = useSession(); 
    const init = {
        current: 1,
        pagesize: 20,
        offset: 0,
        start_date: moment().tz('America/Los_Angeles').add(-1, "year").unix(),
        end_date: moment().tz('America/Los_Angeles').unix(),
        search: ""
    };

    const [filters, setFilters] = useState({ ...init })



    const EditEmailAddress = (props) => {

    }



   

    const fetchEmailedGiftCards = () => {

        let _filters = { ...filters };
        _filters.limit = _filters.pagesize;
        delete _filters.pagesize;
        delete _filters.current;

        setLoading(true)
        apiCall("giftcard/getEmailedGiftcards", _filters, (_status, _result) => {
            if (_status) {
                setResults(_result);
            } else {
                message.error(_result.error);
            }
            setLoading(false)
        })
    }

    useEffect(fetchEmailedGiftCards, [filters.start_date, filters.end_date, filters.current, filters.pagesize, filters.offset, filters.search])

    const drawSummary = () => {

        return (
            <div className="bcg3 bc" style={{ "paddingBottom": "0px", "marginTop": "-10px", "padding": "10px 20px" }}>
                <Space>
                    <Statistic valueStyle={{ "fontSize": "16px", "fontWeight": "600", "width": "110px" }} loading={loading} title={<small>Issued</small>} value={results.issued} />
                    <Statistic valueStyle={{ "fontSize": "16px", "fontWeight": "600", "width": "110px" }} loading={loading} title={<small>Emails Sent</small>} value={results.sent} />
                    <Statistic valueStyle={{ "fontSize": "16px", "fontWeight": "bold", "color": "#f00", "width": "110px" }} loading={loading} title={<small>Emails Unsent</small>} value={results.unsent} />
                </Space>
                <div className="shim" />
            </div>

        )
    }


    const resendEmail = (_id) => {
        setLoading(true)
        apiCall("giftcard/emailGCNumToRecipient", { id: _id }, (_status, _result) => {
            if (_status) {
                message.success("Email sent successfully!")
                fetchEmailedGiftCards()
            } else {
                message.error(_result.error);
                setLoading(false);
            }
        })
    }

    const voidGiftCard = (_id) => {
        setLoading(true)
        apiCall("giftcard/emailGCNumVoidToRecipient", { id: _id }, (_status, _result) => {
            if (_status) {
                message.success("Void sent");
                fetchEmailedGiftCards()
            } else {
                message.error(_result.error);
                setLoading(false)
            }

        })

    }


    const drawFilters = () => {
        return (
            <div style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "20px 20px 0px 20px" }}>
                <Form size="small" layout="vertical">
                    <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                        <div>Emailed Gift Cards</div>
                    </Typography.Title>
                    <Divider dashed style={{ "marginTop": "10px", "marginBottom": "15px" }} />
                    <div className="float-flex">
                        <Space>
                            <Form.Item label={<small>Date Range</small>}>
                                <DatePicker.RangePicker allowClear={false} style={{ "fontSize": "10px", "width": "230px" }} value={[moment(filters.start_date * 1000), (filters.end_date) ? moment(filters.end_date * 1000) : ""]} onChange={(e) => setFilters({ ...filters, "start_date": e[0].tz('America/Los_Angeles').startOf("day").unix(), "end_date": e[1].tz('America/Los_Angeles').endOf("day").unix() })} format="MMM D, YYYY" />
                            </Form.Item>
                            <Form.Item initialValue={filters.search} label={<small>Order Search</small>} name="customer_search">
                                <Input.Search allowClear enterButton onSearch={(e) => setFilters({ ...filters, "search": e })} placeholder="Customer, ref #, email, promo" style={{ "width": "350px" }} />
                            </Form.Item>
                            <Form.Item label={<small>&nbsp;</small>}>
                                <Button disabled={(JSON.stringify(filters) === JSON.stringify(init))} onClick={() => setFilters(init)} type="primary"><small><RollbackOutlined />&nbsp;Reset</small></Button>
                            </Form.Item>
                        </Space>
                        {drawSummary()}
                    </div>
                </Form>
            </div>
        )
    }

    const drawStatus = (_status) => {

        let _ret = ""
        switch (_status) {
            case "pending_payment":
                _ret = <>Pending Payment</>
                break;
            case "payment_received":
                _ret = <>Confirmation Not sent</>
                break;
            case "assignment_sent":
                _ret = <>Confirmation Sent</>
                break;
            case "void_sent":
                _ret = <>Void Email Sent</>
                break;
            case "void":
                _ret = <>Void Email Pending</>
                break;
            default:
                _ret = <></>
                break;
        }

        return _ret;
    }


    const drawBalance = (_amt, _status) => {


        if(_amt || _amt === 0) {
            return "$" + addCommas(_amt);
        }


        let _ret = ""
        switch (_status) {
            case "pending_payment":
            case "payment_received":
            case "assignment_sent":
                _ret = <span style={{"opacity" : "0.5"}}>Pending</span>
                break;
            case "void_sent":
            case "void":
                _ret = <span style={{"opacity" : "0.5"}}>Void</span>
                break;
            default:
                _ret = <></>
                break;
        }

        return _ret;
    }


    const EditGCEmailAddress = (props) => {

        const { email = "", id = "" } = props;
        const [e, setE] = useState(email);
        const [open, setOpen] = useState(false);

        const saveEmail = (_f) => {

            apiCall("giftcard/editEmailedGiftCard", { recipient_email: _f.email, id: id }, (_status, _result) => {
                if (_status) {
                    setE(_f.email);
                    message.success("Email updated successfully!");
                    setOpen(false)
                } else {
                    message.error(_result.error);
                }
            })
        }

        const drawEdit = () => {
            return (
                <>
                    <Typography.Title level={5}>Edit Recipient Email</Typography.Title>
                    <Divider dashed style={{ "margin": "15px 0px" }} />
                    <Form layout="vertical" onFinish={(_f) => saveEmail(_f)}>
                        <Form.Item rules={[
                            {
                                required: true,
                                type: 'email',
                            },
                        ]}
                            initialValue={e} label="Email address" name="email">
                            <Input type="email" />
                        </Form.Item>
                        <div className="float-flex">
                            <div>&nbsp;</div>
                            <Space>
                                <Button onClick={() => setOpen(false)}>Cancel</Button>
                                <Button htmlType="submit" type="primary">Save</Button>
                            </Space>
                        </div>
                    </Form>
                </>
            )
        }

        return (
            <>
                <Modal width={350} destroyOnClose onCancel={() => setOpen(false)} visible={open} footer={null}>{drawEdit()}</Modal>
                <div className="nowrap250"><Tooltip title={<small>{e}</small>}><a onClick={() => setOpen(!open)}><EditOutlined /> {e}</a></Tooltip></div>
            </>
        )

    }



    const viewPersonalized = (_obj) => {

        Modal.success({
            title: "Personalized",
            icon: null,
            closable: true,
            okType: "default",
            okText: "Close",
            content: <>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <div style={{ "opacity": "0.6" }}><small><em>From Name:</em></small></div>
                <div>{_obj.from}</div>
                <div className="shim" /><div className="shim" />
                <div style={{ "opacity": "0.6" }}><small><em>To Name:</em></small></div>
                <div>{_obj.to}</div>
                <div className="shim" /><div className="shim" />
                <div style={{ "opacity": "0.6" }}><small><em>To Email:</em></small></div>
                <div>{_obj.recipient_email}</div>
                <div className="shim" /><div className="shim" />
                <div style={{ "opacity": "0.6" }}><small><em>Message:</em></small></div>
                <div>{_obj.message}</div>
            </>
        })
    }

    const columns = [

        { "width": "10px", key: 'blank' },
        {
            title: <small>&nbsp;Ordered</small>, width: "140px", ellipsis: true, dataIndex: 'ordered', key: 'ordered',
        
            render: (e) => {
                return moment(e * 1000).tz('America/Los_Angeles').format("MMM Do YYYY - h:mma");
            }
        },

        {
            title: <small>Status</small>, key: "sent", width: "125px", dataIndex: "status", render: (e) => {
                return drawStatus(e)
            }
        },
        {
            title: <small>Amount</small>, align:"right", key: "purchase_value", width: "65px", dataIndex: "purchase_value", render: (e) => {
                return (e) ? "$" + addCommas(e) : "";
            }
        },
        {
            title: <small>Balance</small>, align:"right", key: "current_balance", width: "65px", dataIndex: "current_balance", render: (e, f) => {
                return drawBalance(e, f.status);
            }
        },
        {
            title: <small>GC Code</small>, key: "code", width: "110px", dataIndex: "code", render: (e) => {
                return e;
            }
        },
        {
            title: <small>Ref#</small>, key: "bookorder_id", width: "80px", dataIndex: "bookorder_id", render: (e) => {
                return <Overlay component={<PreviousOrder bookorder_id={e} />}><a>{e}</a></Overlay>
            }
        },
        {
            title: <small>Purchaser</small>, key: "purchaser_name", dataIndex: "purchaser_name", render: (e) => {
                return (<div className="nowrap">{e}</div>)
            }
        },
        {
            title: <small>Purchaser Email</small>, key: "purchaser_email", dataIndex: "purchaser_email", render: (e) => {
                return (<Tooltip title={<small>{e}</small>}><div className="nowrap250"><a href={"mailto:" + e}>{e}</a></div></Tooltip>)
            }
        },
        {
            title: <small>Recipient Email</small>, key: "recipient_email", dataIndex: "recipient_email", render: (e, _f) => {
                return (<EditGCEmailAddress id={_f.id} email={e} />)
                // return (<div className="nowrap250"><a href={"mailto:" + e}>{e}</a></div>)
            }
        },
        {
            title: <small>Personalized?</small>, key: "personalized", width: "90px", dataIndex: "personalized", render: (e, f) => {
                return (e) ? <div><a onClick={() => viewPersonalized({ ...e, "recipient_email": f.recipient_email })}>View Details</a></div> : "No"
            }
        },
        {
            title: <small>Actions</small>, width: 185, key: "actions", dataIndex: "actions", render: (e, f) => {

                let void_text = "Email Void";
                let can_void = true;
                let can_email = true;
                let void_tooltip = <div style={{ "lineHeight": "16px" }}><small>The balance at {session.store_name} is not zero/void, cannot send a void email. Voiding the transaction within the Bookmanager point-of-sale will automatically send the customer a void email notifying them that the gift card number is no longer valid. <br /><br />Use this function only if you could not void the original transaction (it is historical), and instead issued a refund/zero'd the gift card on a subsequent transaction.</small></div>
                let email_tooltip = <div style={{ "lineHeight": "16px" }}><small>This gift card has no balance according to your point-of-sale records, and therefore a gift card code email cannot be resent. <br /><br />This could mean that the transaction has yet to be finalized in the Bookmanager point-of-sale, or the transaction was deleted or voided.</small></div>

                if (f.current_balance || f.status === "pending_payment") {
                    can_void = false;
                }

                if (f.status === "void" || f.status === "void_sent") {
                    void_text = "Resend Void"
                }

                if (f.status === "pending_payment" || f.status === "void" || f.status === "void_sent") {
                    can_email = false;
                }

                if (f.status === "void" || f.status === "void_sent") {
                    void_tooltip = <div style={{ "lineHeight": "16px" }}><small>A void email was already sent when the original issuing transaction was voided in the Bookmanager point-of-sale.</small></div>
                }

                if((f.status === "void" || f.status === "void_sent") && f.current_balance > 0){
                    can_email = true; 
                }

                //pending_payment   payment_received   assignment_sent    void_sent   void
                return (
                    <Space>
                        <Tooltip title={(!can_email) ? email_tooltip : ""}>
                            <Popconfirm okText="Resend" disabled={!can_email} title={<div style={{ "maxWidth": "300px", "lineHeight": "16px" }}><small>Resend the recipient a gift card code email containing the issued GC number, along with a code delivery confirmation email to the purchaser. <br /><br />This action is normally not needed, as finalizing the online order in the Bookmanager point-of-sale will automatically send the recipient and purchaser emails two minutes after processing.</small></div>} onConfirm={() => resendEmail(f.id)}>
                                <Button disabled={!can_email} size="small" type="primary"><small>Resend Code</small></Button>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title={(!can_void) ? void_tooltip : ""}>
                            <Popconfirm disabled={!can_void} title={<div style={{ "maxWidth": "300px", "lineHeight": "16px" }}><small>This function is very specific, and only needed when this gift card balance has been zeroed in a separate transaction from the original issuing transaction (for example, if a gift card balance was refunded after the original issuing transaction was finalized / totalled). <br /><br /> This will send the customer an email letting them know the previously sent gift card code has been voided due to a payment processing error or refund/exchange. This will NOT void the original gift card issuing transaction in Bookmanager. It is simply a means to email the customer about the zero balance, and mark it as void online.</small></div>} onConfirm={() => voidGiftCard(f.id)}>
                                <Button style={{ 'width': "82px" }} disabled={!can_void} size="small" type="primary" danger ><small>{void_text}</small></Button>
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                )
            }
        },
        { "width": "10px", key: 'blank' },

    ]



    return (
        <>
            {/* <pre>{JSON.stringify(filters, null, 2)}</pre>

            <pre>{JSON.stringify({...results, "rows" : []}, null, 2)}</pre> */}
            <Layout className="layout">
                <Layout.Content className="layoutHeader" style={{ "padding": "0px" }} >
                    {drawFilters()}
                    <Divider style={{ "margin": "0px 0px" }} />
                    
                    {(!results.giftcard_remote_tracking && <div style={{"padding" : "15px 20px", "paddingBottom" : "0px"}}><Alert style={{ "marginBottom": "20px" }} description={<><strong>Warning!</strong> Your Bookmanager software does not have <strong>Remote Gift Card Balance Tracking</strong> enabled. This is required in order to allow customers to check balances and redeem giftcards online.</>} /></div>)}

                    <Paginate paginate={filters} setPaginate={(_e) => setFilters({ ...filters, "current": _e.current, "pagesize": _e.pagesize, "offset": _e.offset })} count={results.row_count} />
                    <Table
                        rowKey={"id"}
                        columns={columns}
                        className={"tableNoBorder largeTable"}
                        dataSource={results.rows}
                        loading={loading}
                        pagination={false}
                    />
                    <Paginate paginate={filters} setPaginate={(_e) => setFilters({ ...filters, "current": _e.current, "pagesize": _e.pagesize, "offset": _e.offset })} count={results.row_count} />
                </Layout.Content>
            </Layout>
        </>
    )
}