import React from 'react';


const Icon = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="9"
        viewBox="0 0 14 9"
        className={className}
        {...props}
    >
        <path d="M-499.906-1275.208a.5.5,0,0,1,0-.581c.122-.172,3.036-4.21,6.908-4.21s6.786,4.038,6.908,4.21a.5.5,0,0,1,0,.581c-.124.172-3.069,4.208-6.907,4.208S-499.783-1275.036-499.906-1275.208Zm1.039-.293c.725.887,3.1,3.5,5.866,3.5s5.144-2.614,5.867-3.5c-.718-.887-3.07-3.5-5.867-3.5S-498.148-1276.39-498.866-1275.5Zm3.366,1a2.5,2.5,0,0,1,2.5-2.5,2.481,2.481,0,0,1,2,1h-1v1h1v-1a2.467,2.467,0,0,1,.5,1.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-495.5-1274.5Z" transform="translate(500 1280)" />

        {/* <path d="M-499.906-1275.208a.5.5,0,0,1,0-.581c.122-.172,3.036-4.21,6.908-4.21s6.786,4.038,6.908,4.21a.5.5,0,0,1,0,.581c-.124.172-3.069,4.208-6.907,4.208S-499.783-1275.036-499.906-1275.208Zm1.039-.293c.725.887,3.1,3.5,5.866,3.5s5.144-2.614,5.867-3.5c-.718-.887-3.07-3.5-5.867-3.5S-498.148-1276.39-498.866-1275.5Zm3.366,0a2.5,2.5,0,0,1,2.5-2.5,2.481,2.481,0,0,1,2,1h-1v1h1v-1a2.467,2.467,0,0,1,.5,1.5,2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1-495.5-1275.5Z" transform="translate(500 1280)"/> */}
    </svg>
);


const EyeIcon = ({ color, ...props }) => (
    <Icon
        style={{ fill: 'currentColor' }}
        {...props}
    />
);

export default EyeIcon;