import { Badge, Button, Card } from "antd"
import React, { useState, useRef, useEffect } from "react"
import { useHistory } from "react-router-dom";

import conditional from "../utils/conditional";

export default function DropMenu(props) {

    const history = useHistory();
    const { block=false, onOpen = () => {}, alignLeft=false, vistoggle = "", setVistoggle = () => { }, selected = false, hover = false, title = "", label = "", count = 1, active = false, custom = false, custom_button=false, onClose = () => { }, callback = () => { return false } } = props;
    const [open, setOpen] = useState(false);


    const closeMenu = () => {
        if (open) {
            setOpen()
        }
    }

    const outsideToggle = () => {
        if (vistoggle === "close") {
            setVistoggle("");
            onClose();
            closeMenu();
        }
    }

    useEffect(closeMenu, [history.location.pathname])


    useEffect(outsideToggle, [vistoggle])


    // useEffect(() => {
    //     function handleClickOutside(e) {
    //         if (open) {
    //             console.log("CALLED!!")
    //             setOpen(false);
    //             onClose();
    //         }
    //         console.log("click");

    //     }
    //     document.addEventListener("click", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("click", handleClickOutside);
    //     };
    // }, []);




    return (
        <div style={{"width" : (block) ? "100%" : "auto"}} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (callback()) {
                setOpen(false);
            }
        }} onMouseLeave={() => {
            if (hover) {
                onClose();
                setOpen(false)
            }
        }}>

            <conditional.true value={custom && hover}>
                <div onClick={() => setOpen(false)} onMouseEnter={() => setOpen(true)}>{title}</div>
                <div style={{"maxHeight": "calc(75vh)", "overflow": "auto", "zIndex": "19", "position": "absolute", "display": (open) ? "inherit" : "none" }} >
                    {props.children}
                </div>
            </conditional.true>

            <conditional.true value={custom && !hover}>
                <span onClick={() => setOpen(!open)}>{title}</span>
                <div style={{"maxHeight": "calc(75vh)", "zIndex": "19", "position": "absolute", "display": (open) ? "inherit" : "none" }} >
                    {props.children}
                </div>
            </conditional.true>
            <conditional.true value={!custom}>

                {/* <Button style={{ "padding": "0px 12px", "lineHeight": "10px", "height": "30px" }} onClick={() => setOpen(!open)}>
                    <span style={{ "fontSize": "12px" }}>{title}</span>
                </Button> */}

                <conditional.true value={!custom_button}>
                    <Button block={block} type={(selected) ? "primary" : "default"} className={(!(count)) ? "ant-btn-fake-disabled" : ""} style={{ "padding": "0px 10px", "lineHeight": "10px", "height": "25px" }} onClick={() => setOpen(!open)}>{(active) ? <span style={{ "color": "#007EF5" }}><small><strong>{active}</strong>&nbsp;</small>&nbsp;</span> : ""}
                        <span style={{ "fontSize": "12px" }}>{title}</span>
                    </Button>
                </conditional.true>

                <conditional.true value={custom_button}>
                    <div className={(!(count)) ? "ant-btn-fake-disabled" : ""} style={{ "padding": "0px 8px"}} onClick={() => {setOpen(!open); onOpen(); }}>{(active) ? <span style={{ "color": "#007EF5" }}><small><strong>{active}</strong>&nbsp;</small>&nbsp;</span> : ""}
                        <span style={{ "fontSize": "12px" }}>{title}</span>
                    </div>
                </conditional.true>


                <div style={{"width": "270px", "zIndex": "9", "position": "absolute", "display": (open) ? "flex" : "none",  marginLeft : (alignLeft) ? "-165px" : null }} >
                    <div onClick={() => {onClose(); setOpen(false)}} className="mask-overlay" style={{ "zIndex": "-1" }}>mask</div>
                    <div className="shim" /><div className="shim" />
                    <Card bodyStyle={{ "padding": "10px", "boxShadow": "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)" }}>
                        <conditional.true value={(label)}>
                            <div>
                                {label}
                            </div>
                            <div className="shim" />
                        </conditional.true>
                        <div style={{ "overflow": "auto"}}>
                            {(open) ? props.children : ""}
                        </div>
                    </Card>

                </div>
            </conditional.true>


        </div>
    )
}