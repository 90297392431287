import { Input } from "antd";
import React, { useState } from "react";
import { apiCall } from "../utils/Api";
import { filterUnique, sortAlpha } from "../utils/Utils";

export default function FilterAutocomplete(props) {

    const { searchFilters = {}, filters = [], setFilters, name = "" } = props;
    const [initFilters, setInitFilters] = useState([]);


    const local = (name === "publisher_imprint") ? false : true;

   


    const searchLocal = (_search, ifilters) => {

        if(!_search){
            setFilters(ifilters);
            return; 
        }
        
        let index = ifilters.findIndex(item => item.name === name);

        if (index > -1) {
            let _f = ifilters.map(item => {
                return {...item}
            });

            
            _f[index].rows = sortAlpha(filterUnique(_f[index].rows.filter(item => item.name.toUpperCase().includes(_search.toUpperCase()) && item.key !== "--"), "name"), "name")
            
            console.log(_f[index].rows);
            setFilters(_f);           
        }


    }

    
    const onChange = (_search) => {

        // ensure init is set
        let ifilters = initFilters;
        if (filters.length > initFilters.length) {
            ifilters = filters.map(item => {
                return {...item}
            });
            setInitFilters(ifilters);
        }


        if (!_search) {
            setFilters(ifilters);
        }

        if (local) {
            return searchLocal(_search, ifilters);
        }


        apiCall("browse/getAutocompleteFacets", { filter_name: "publisher_imprint", search_term: _search, search_filters : JSON.stringify(searchFilters)}, (_status, _result) => {
            if (_status) {
                let index = ifilters.findIndex(item => item.name === name);
                if (index > -1) {
                    let _f = [...ifilters]
                    _f[index].rows = _result.rows;
                    setFilters(_f);
                }

            }
        })
    }


    return (
        <>
            <div className="shim" />
            <Input onChange={(e) => onChange(e.target.value)} allowClear onKeyDown={(e) => {
                if (e.key === "Enter") {
                   // onChange(e.target.value);
                }
            }} style={{ "fontSize": "12px" }} size="small" placeholder="Refine..." />

        
        </>
    )
}