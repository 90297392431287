
import React, { useState } from "react";
import { Upload, message, Button, Modal, Table, Space, Layout, Typography, Divider } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional";
import Overlay from "../components/Overlay";


export default function GiftRegistryImport(props) {



    const { gift_registry_id = "", getRegistry = () => { } } = props;

    const [uploading, setUploading] = useState(false);
    const [list, setList] = useState([]);
    const [parsed, setParsed] = useState(false)
    const [visible, setVisible] = useState(false);

    const close = () => {
        setList([]);
        setVisible(false);
        setParsed(false);
    }

    const addTitles = () => {

        let isbn_map = list.map(item => {
            return {
                isbn: item.isbn,
                quantity: item.quantity || 0
            }
        })

        let obj = {
            gift_registry_id: gift_registry_id,
            isbn_hash: JSON.stringify(isbn_map)
        }

        apiCall("giftregistry/addItems", obj, (_status, _result) => {
            if (_status) {
                message.success("Titles added successfully!");
                getRegistry();
                close();
            } else {
                message.error(_result.error)
                close();
            }
        })


    }

    const uploadFile = (_file) => {
        setUploading(true);
        apiCall("file/parseIsbnsQuantity", { file: _file.file }, (_status, _result) => {
            if (_status) {
                setList(_result)
                setParsed(true);
                setVisible(true)
            } else {
                message.error(_result.error)
            }
            setUploading(false);
        });
    }

    const columns = [

        { title: <small>Import?</small>, width: "70px", dataIndex: 'exists', key: 'exists', render : (e) =>{
            return (e) ? "Yes" : <strong style={{"color" : "#f00"}}>No</strong>
        } },
        {
            title: <small></small>, width: "40px", dataIndex: 'eisbn', key: 'eisbn', render: (e, f) => {
                return <div style={{ "textAlign": "center" }}><img className="lightshadow" onError={(e) => e.target.src = "/no-cover.png"} style={{ "maxHeight": "34px", "maxWidth": "30px" }} src={"https://bookmanager.com/i/slider_23.php?b=" + e} /></div>
            }
        },
        { title: <small>Title</small>, dataIndex: 'title', key: 'title' },
        { title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn' },
        { title: <small>Qty</small>, width: "40px", dataIndex: 'quantity', key: 'quantity' },
        
    ]



    return (
        <>
            <Modal
                onCancel={() => close()}
                footer={null}
                destroyOnClose={true}
                visible={visible}>


                <Layout className="layout">
                    <Layout.Content style={{ "padding": "0px", "width": "auto" }} >

                        <Typography.Title level={5}>Import to Registry </Typography.Title>
                        <Typography.Paragraph>Review titles below to ensure they can be imported.</Typography.Paragraph>
                        <Divider style={{ "margin": "15px 0px" }} />

                        {/* START */}
                        <conditional.true value={(visible && !parsed)}>
                            <div>You will be able to preview the list of titles before importing it into the registry.  <a target="_blank" href={process.env.PUBLIC_URL + "/example.txt"}> Click here to see an example text file.</a>
                                <div className="shim" /><div className="shim" /><div className="shim" />
                            </div>

                            <Upload.Dragger className="hide-upload" showUploadList={false} customRequest={(e) => uploadFile(e)} accept=".txt, .xls, .xlsx, .tsv, .doc, .docx, .csv"  >
                                <UploadOutlined disabled={uploading} style={{ "fontSize": "30px", height: "35px" }} />
                                <div style={{"padding" : "0px 20px"}}>
                                    <p className="ant-upload-text">{(uploading) ? "Uploading..." : "Click or drag to upload"}</p>
                                    <p className="ant-upload-hint">
                                        Upload an Excel file (.xls or .xlsx), EDIFACT file (.txt) or a tab-delimited file (.txt) with the headers "QTY" and "ISBN".
                                    </p>
                                </div>
                            </Upload.Dragger>

                        </conditional.true>

                        {/* UPLOADING */}
                        {/* <conditional.true value={(visible && uploading)}>
                            <p className="ant-upload-text">Loading, please wait.</p>
                        </conditional.true> */}


                        {/* UPLOADING COMPLETE, IMPORT */}
                        <conditional.true value={(visible && !uploading && parsed)}>
                            <Table
                                columns={columns}
                                dataSource={list}
                                className={"tableBorder"}
                                scroll={{ y: 300 }}
                                pagination={false}
                                size="small"
                            />
                            <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                            <div style={{ "float": "right" }}>
                                <Space>
                                    <Button onClick={() => close()}>Cancel</Button>
                                    <Button onClick={() => addTitles()} type="primary">Import Titles</Button>
                                </Space>
                            </div>
                            <br clear="all" />
                        </conditional.true>


                    </Layout.Content>
                </Layout>



            </Modal>


            {/* <Overlay width={500} component={<>

                <Layout className="layout">
                    <Layout.Content style={{ "padding": "0px", "width": "auto" }} >
                        <div style={{ "padding": "30px" }}>
                            <Typography.Title level={5}>Import to Registry </Typography.Title>
                            <Divider style={{ "margin": "15px 0px" }} />

                            







                        </div>
                    </Layout.Content>
                </Layout>




            </>} >
            
            </Overlay> */}

            <Button type="primary" onClick={() => setVisible(true)} size="small"><small><UploadOutlined /> Upload</small></Button>

        </>
    )






    // <Upload customRequest={(e) => uploadFile(e)} accept=".jpg, .jpeg, .png"  >
    //                 <span style={{ "color": "#fff" }}><UploadOutlined /> <small>Upload</small></span>
    //             </Upload>

}