import React, { useState } from "react";
import { Popover, Button, Table, Badge, Input, Form, Space, message } from "antd";
import { EditOutlined, StarFilled, FormOutlined } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";
import { ucfirst } from "../../utils/Utils";
import moment from "moment";
import TitleRenderers from "./TitleRenderers";
import conditional from "../../tools/conditional";
import BisacSelector from "./BisacSelector";
import Modal from "antd/lib/modal/Modal";
export default function TitleEditField(props) {

    const { editing, field, isbn, annotation = false, edit = {} } = props;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({ rows: [], type: "", can_use_pick: false });
    const [value, setValue] = useState("");
    const [visible, setVisible] = useState(false);
    const [row, setRow] = useState(null);
    const [valid, setValid] = useState(true);
    const [type, setType] = useState("title_heap");
    const [title, setTitle] = useState("Edit");


    // title heap
    // annotaton heap
    // 

    const close = () => {

        setVisible(false);
        setValid(true);
        setRow(null);
    }

    const addRowKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = item.row_id;
            return item;
        }))
    }


    const getEdit = (_visible, _title = "Edit") => {
        if (visible) {
            close();
            return;
        }
        setTitle(_title);
        setValue("");
        setLoading(true);

        let _obj = {}
        _obj.isbn = isbn;
        _obj.field = field;


        switch(_obj.field){

            case "main_description":
                _obj.field = "annotation_1"
            break; 

            case "short_description":
                _obj.field = "annotation_2"
            break; 

            case "long_description":
                _obj.field = "annotation_3"
            break; 

            case "review_quote":
                _obj.field = "annotation_8"
            break; 

            case "reader_description":
                _obj.field = "annotation_12"
            break; 

            case "biographical_note":
                _obj.field = "annotation_13"
            break; 

            

            
        }


        // overwrite field with id if it's an annotation
        if (annotation) {
            _obj.field = "annotation_" + annotation.id.toString();
        }

        if (_visible) {

            console.log(_obj);
            
            apiCall("title/getSpecificEdit", _obj, (_status, _result) => {

                if (_status) {
                    addRowKeys(_result.rows)
                    setType(_result.type);
                    setData(_result);
                    setVisible(true);
                    setLoading(false);
                } else {
                    message.error("Not available.")
                }


            })
        }
    }


    const saveEdit = () => {

        if (!value) {
            setValid(false);
            return;
        }

        let obj = {}

        obj.value = value;
        obj.isbn = isbn;


        if (annotation) {


            obj.note_id = annotation.id;
            obj.field = field;

            if (row) {
                obj.row_id = row;
            }

            apiCall("title/saveAnnotation", obj, (_status, _result) => {
                if (_status) {
                    message.success("Update requested");
                } else {
                    message.error(_result.error);
                }
                close()
            })

        } else {

            obj.type = type;
            obj.field = field;

            if (row) {
                obj.row = row;
            }

            apiCall("title/save", obj, (_status, _result) => {
                if (_status) {
                    message.success("Update requested");
                } else {
                    message.error(_result.error)
                }
                close()
            })

        }


    }

    const deleteEdit = () => {

        let obj = {}
        obj.type = type;
        obj.isbn = isbn;


        if (annotation) {

            if (row) {
                obj.row_id = row;
            }
            obj.note_id = annotation.id;

            apiCall("title/deleteAnnotation", obj, (_status, _result) => {
                if (_status) {
                    message.success("Record deleted");
                } else {
                    message.error(_result.error)
                }
                close()
            })

        } else {

            obj.field = field;
            if (row) {
                obj.row = row;
            }

            apiCall("title/delete", obj, (_status, _result) => {
                if (_status) {
                    message.success("Record deleted");
                } else {
                    message.error(_result.error)
                }
                close()
            })

        }


    }




    const columns = [
        {
            title: <small></small>, width: "20px", style: { "padding": "0px" }, dataIndex: 'fs', key: 'fs', render: (e, f) => {
                return (<Badge style={{ "paddingLeft": "12px" }} color={(f.is_editable) ? "#87ceeb" : "#d3d3d3"} />)
            }
        },
        {
            title: <small>Value</small>, dataIndex: 'data', key: 'data', render: (e) => {
                return (e) ? <div>
                    {e}
                    </div> : "";
            }
        },
        { title: <small>Source</small>, dataIndex: 'source_display', key: 'source_display' },
        {
            title: <small>Created</small>, dataIndex: 'date_created', key: 'date_created', render: (e) => {
                return (e) ? <span>{moment(e * 1000).format("MMM Do, YYYY")}</span> : "";
            }
        },
        {
            title: <small>Modified</small>, dataIndex: 'date_changed', key: 'date_changed', render: (e) => {
                return (e) ? <span>{moment(e * 1000).format("MMM Do, YYYY")}</span> : "";
            }
        },
        { title: <small></small>, width: "0x", style: { "padding": "0px" }, dataIndex: 'bs', key: 'bs' },
        { title: <small>Priority</small>, dataIndex: 'title_weight', key: 'title_weight' },
        { title: <small></small>, width: "0x", style: { "padding": "0px" }, dataIndex: 'bs', key: 'bs' },
    ]

    // "title_weight": 1001,
    // "data": "I Love Corgis! : this is the subtitle by bookmanager",
    // "source_display": "",
    // "date_created": 1628183900,
    // "date_changed": 1628183900,
    // "row_id": 76076143,
    // "isbn": "ATEST00000000",
    // "eisbn": "Ryan_Y-Z0UVpa4PqhAdL4w",
    // "banned_bisacs": null,
    // "is_editable": false




    const onRowClick = (_record) => {

        if (_record.is_editable) {

            if (row === _record.row_id) {
                setRow(null);
            } else {
                setRow(_record.row_id);
            }

        } else {
            setRow(null);
        }
        setValue(_record.data);
    }


    const drawEditForm = () => {

        return (
            <Modal title={title} width={1000} onCancel={() => setVisible(false)} footer={null} visible={visible}>
                <div style={{ "margin": "-12px -16px" }}>
                    <conditional.true value={data.rows.length > 0}>
                        <Table
                            size="small"
                            rowClassName={(record) => { return (record.row_id === row) ? "ant-table-row-selected" : "" }}
                            loading={loading}
                            columns={columns}
                            dataSource={data.rows}
                            pagination={false}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => { onRowClick(record) }, // click row
                                };
                            }}
                        />
                    </conditional.true>
                    <div className="shim"></div>
                    <div style={{ "padding": "10px 20px" }}>

                        <TitleRenderers isbn={isbn} can_use_pick={data.can_use_pick} valid={valid} edit={edit} value={value} setValue={setValue} field={field} />


                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button size="small" onClick={() => close()} ><small>Cancel</small></Button>
                                <conditional.true value={(row)}>
                                    <Button danger size="small" onClick={() => deleteEdit()} ><small>Delete</small></Button>
                                </conditional.true>

                                <Button size="small" onClick={() => saveEdit()} type="primary"><small>{(row) ? "Update" : "Save Changes"}</small></Button>
                            </Space>
                        </div>
                        <br clear="all" />
                        <div className="shim"></div>
                    </div>
                </div>
            </Modal>
        )
    }

    if (!editing) {
        return props.children;
    }



    // return (
    //     <>
    //         {props.children}
    //         {drawEditForm()}
    //         <Button onClick={() => getEdit(true)} size="small"><small>hi</small></Button>
    //     </>
    // )


    return (
        <>

            {props.children}
            {drawEditForm()}
                <conditional.true value={(!props.children)}>
                    <conditional.true value={(!edit[field]) && (!annotation)}>
                        <span className="c" style={{ "opacity": "0.5" }}>[ {field} ]</span>
                    </conditional.true>
                    <conditional.true value={(edit[field])}>
                        {edit[field]}
                    </conditional.true>

                </conditional.true>
                <a onClick={() => getEdit(true, "Edit " + ucfirst(field.replace("_", " ")))} > {(annotation) ? <small>{"Edit " + ucfirst(field.replace("_", " ")) + " "}</small> : <>&nbsp;</>}<FormOutlined style={{ "fontSize": "11px", "lineHeight": "0px" }} /></a>
            
        </>);
}