import { Divider, Row, Col, Space, Tooltip } from "antd";
import React from "react";
import { adjustOpacity } from "../../utils/Utils";
import Candle from "./Candle";

export default function VolumeBySubject(props) {

    const { title = "", volume = [], all = {} } = props;

    const avgHigh = Math.max(...volume.map(i => {
        if (!i.hasOwnProperty("all_volume_then") || !i.hasOwnProperty("all_volume_then")) {
            return 0;
        }
        return (i.all_volume_now > i.all_volume_then) ? i.all_volume_now : i.all_volume_then
    }));
    const storeHigh = Math.max(...volume.map(i => (i.me_volume_now > i.me_volume_then) ? i.me_volume_now : i.me_volume_then))

    function scale(x) {
        return x;
        return Math.pow(x, 1);
    }



    const drawRow = (item) => {

        let {
            all_volume_then = 0,
            all_volume_now = 0,
            me_volume_then = 0,
            me_volume_now = 0,
        } = item;

        // Calculate percent of sales & YOY change
        let percent_volume = me_volume_now / all.me_volume_now || 0;
        let average_percent_volume = all_volume_now / all.all_volume_now || 0;

        let percent_change = (me_volume_now - me_volume_then) / me_volume_then || 0;
        let average_percent_change = (all_volume_now - all_volume_then) / all_volume_then || 0;




        return (
            <div className="bc lighten" style={{ "fontSize": "10px" }}>
                <Row align="middle">
                    <Col flex={"70px"}>
                        <div style={{ "color": "#64a6da" }}>{(percent_volume * 100).toFixed(0)}% ({(percent_change * 100).toFixed(0)}%)</div>
                        <div style={{ "color": "#4fac60" }}>{(average_percent_volume * 100).toFixed(0)}% ({(average_percent_change * 100).toFixed(0)}%)</div>
                    </Col>
                    <Col flex={"200px"}>
                        <div style={{ "color": (average_percent_change > percent_change) ? "#e33535" : "inherit" }}>{item.subject}</div>
                    </Col>
                    <Col flex={"auto"}>
                        <div>
                            <Space size={1} direction="vertical">
                                <Candle height={590} bar={true} color={"#64a6da"} high={scale(storeHigh)} data={{ now: scale(me_volume_now.toFixed(0)), then: scale(me_volume_then.toFixed(0)) }} />
                                <Candle height={590} bar={true} color={"#4fac60"} high={scale(avgHigh / volume.length)} data={{ now: scale((all_volume_now / volume.length).toFixed(0)), then: scale((all_volume_then / volume.length).toFixed(0)) }} />
                            </Space>
                        </div>
                    </Col>
                </Row>
                <Divider style={{ "margin": "5px 0px" }} />
            </div>
        )
    }

    return (
        <>
            <div style={{ "width": "870px" }}>
                <strong style={{ "fontSize": "13px" }}>{title}</strong>
                <Divider dashed style={{ "margin": "10px 0px" }} />
                <div style={{ "cursor": "pointer" }}>
                    <Space>
                        <Tooltip title={<small>Increase compared to previous year.</small>}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#64a6da" }}></div>
                                <div style={{ "opacity": "0.7" }}>Increase (You)</div>
                            </Space>
                        </Tooltip>
                        <Tooltip title={<small>Increase compared to previous year.</small>}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#4fac60" }}></div>
                                <div style={{ "opacity": "0.7" }}>Increase (Others)</div>
                            </Space>
                        </Tooltip>
                        <Tooltip title={<small>Decrease compared to previous year.</small>}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#ea8282" }}></div>
                                <div style={{ "opacity": "0.7" }}>Decrease</div>
                            </Space>
                        </Tooltip>
                    </Space>
                </div>
                <div className="shim" /><div className="shim" /><div className="shim" />
                {volume.map(item => drawRow(item))}
            </div>
        </>
    )
}