import { Button, Checkbox, Form, Input, InputNumber, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { makeKey, slugify } from "../../utils/Utils";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

export default function SelectBoxField(props) {

    const { data = {}, setSelected = () => { }, render = false, update = () => { } } = props;

    const [d, setD] = useState(data);

    const {
        id = "",
        label = "Select",
        type = "select",
        description = "",
        options = [{ label: "Option 1" }]
    } = d;


    useEffect(() => {
        if (!render) {
            update(d);
        }

    }, [d])


    const addOption = () => {
        let _arr = [...options];
        _arr.push({ label: "", id : makeKey() })
        setD({ ...d, options: _arr });
    }

    const removeOption = (_id) => {
        setD({ ...d, options: options.filter((item, index) => item.id !== _id) });
    }

    const canAdd = () => {
        let _ret = true;
        options.forEach(element => {
            if(element.label === ""){
                _ret = false;
            }
        });
        return _ret; 
    }

    const updateOption = (_option) => {
        let _arr = [...options];
        let _f = _arr.findIndex(item => item.id === _option.id);
        if(_f > -1){
            _arr[_f] = _option
        }
        setD({...d, "options" : _arr});
        
    }

    const drawOptions = () => {

        return (
            <>
                <label>Options</label>
                <div className="shim" />
                <Space direction="vertical" >
                    {options.map((item, index) => {
                        return (
                            <Space key={item.id}>
                                <Input style={{"width" : "251px"}} onChange={(e) => updateOption({...item, "label" : e.target.value})} defaultValue={item.label} placeholder="Label..." />
                                <Button onClick={() => removeOption(item.id)} disabled={(index === 0)} danger><small><DeleteOutlined /></small></Button>
                            </Space>
                        )
                    })}
                </Space>
                <div className="shim" /><div className="shim" />
                <div style={{"marginRight" : "10px"}}>
                <Button disabled={(!canAdd())} onClick={() => addOption()} type="primary" size="small" block><small><PlusOutlined /> Add option</small></Button>
                </div>
            </>
        )

    }


    if (render) {
        return (
            <Form.Item initialValue={props.data.options[0].label} extra={props.data.description} label={props.data.label} name={id} >
                <Select
                    
                    dropdownMatchSelectWidth
                    options={props.data.options}
                />
            </Form.Item>
        )
    }

    return (
        <>

            {/* LABEL */}
            <label >Label</label><div className="shim" />
            <Input value={label} onChange={(e) => setD({ ...d, "label": e.target.value })} placeholder="Label..." />
            <div className="shim" /><div className="shim" />

            {/* LABEL */}
            <label >Description / Instructions</label><div className="shim" />
            <Input value={description} onChange={(e) => setD({ ...d, "description": e.target.value })} placeholder="Description..." />
            <div className="shim" /><div className="shim" />
            {drawOptions()}
            <div className="shim" /><div className="shim" />
        

        </>
    )
}