import React, { useRef, useState} from "react";
import { motion } from "framer-motion"

export default function Slider(props) {

    /*
        Wrapper fills 100% available width, calculates the visible width to determine how to offset the scroll distance. 
    */

    const wrapper = useRef(0);
    const slider = useRef(0);
    const [left, setLeft] = useState(false);
    const [offset, setOffset] = useState(0);

    const dragStart = () => {
        if (slider.current.offsetWidth > (wrapper.current.offsetWidth)) {
            setOffset(slider.current.offsetWidth - (wrapper.current.offsetWidth))
        } else {
            setOffset(0);
        }
    }

    const checkDrag = () => {
        if (!left) {
            setLeft(true);
        }
    }

    return (
        <>
            <div ref={wrapper} style={{ "position": "relative" }} className="hide-block slider-container">
                <div style={{ "overflowX": "hidden", "width": "100%" }}>
                    <motion.div
                        drag="x"
                        onDragStart={() => dragStart()}
                        onDrag={() => checkDrag()}
                        style={{ cursor: "pointer" }}
                        whileTap={{ cursor: "grabbing" }}
                        dragElastic={0.1}
                        dragConstraints={{
                            top: 0,
                            left: (offset) * -1,
                            right: 0,
                            bottom: 0,
                        }}
                    >
                        <div ref={slider} className="slider-wrapper">
                           {props.children}
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    )
}