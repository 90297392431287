import { Button, Divider, Space, Row, Col, Card, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AnalyticsDetails from "./AnalyticsDetails";
import AnalyticsGraph from "./AnalyticsGraph";
import AnalyticsSales from "./AnalyticsSales";
import AnalyticsTable from "./AnaylticsTable";

export default function AnaylticsGroup(props) {

    const { scale = false,  change="", index = "", parseSearchFilters = () => { }, searchFilters = {}, grouping = "", headers = {}, loading = false, getSet = () => { }, filters = {}, setFilters = () => { }, high = 0, data = {}, range = 0, row_count = 1, includes = {} } = props;
    const [resetScale, setResetScale] = useState(scale);
    const [view, setView] = useState("");



    const toggleView = (_view) => {
        if(view === _view){
            setView("")
        } else {
            setView(_view);
        }
    }

    useEffect(() => {
        setResetScale(scale);
    },[scale]);

    useEffect(() => {
        setView("");
    },[change])

    const cycleValues = () => {
        let _set = "";
        switch (filters.show_which) {
            case "dollars": _set = "unit_copies"; break;
            case "unit_copies": _set = "unique_isbns"; break;
            case "unique_isbns": _set = "dollars"; break;
        }
        setFilters({ ...filters, "show_which": _set })
    }

    const findHeader = (_header) => {
        if (headers.groupby_labels.hasOwnProperty(_header)) {
            return headers.groupby_labels[_header];
        } else {
            return _header;
        }
    }

    const findLabel = (_key, _value) => {
        // Remove the fist char if it's a dash - (excluded)


        if (_value[0] === '-') {
            _value = _value.slice(1);
        }

        let ret = headers.seen[_key].find(item => item.v === _value);
        if (ret) {

            if (ret.hasOwnProperty("e")) {
                return ret.e;
            } else {
                return _value;
            }
        } else {
            return _value;
        }
    }


    const drawGroupTokens = (_token = []) => {


        let _label = _token.filter(item => item.grouping.length > 0).map(item => {
            return (<div style={{ "color": "#177ddc" }}><strong>{findHeader(item.group)} : {item.grouping.map(i => findLabel(item.group, i)).join(", ")}</strong></div>);
        })

        let _includes = _token.filter(item => item.includes.length > 0).map(item => {
            return (<div style={{ "color": "green" }}><strong>{findHeader(item.group)}</strong> : <small>{item.includes.map(i => findLabel(item.group, i)).join(", ")}</small></div>)
        })

        let _excludes = _token.filter(item => item.excludes.length > 0).map(item => {
            return (<div style={{ "color": "#c52738" }}><strong>{findHeader(item.group)}</strong> : <small>{item.excludes.map(i => findLabel(item.group, i)).join(", ")}</small></div>)
        })

        return (
            <div>
                {(_label.length > 0 && <div><Space>{_label}</Space></div>)}
                {(_includes.length > 0 && <div><Space>{_includes}</Space></div>)}
                {(_excludes.length > 0 && <div><Space>{_excludes}</Space></div>)}
            </div>
        )


    }

    const getWhichName = () => {
        let _set = "";
        switch (filters.show_which) {
            case "dollars": _set = "Dollars"; break;
            case "unit_copies": _set = "Units"; break;
            case "unique_isbns": _set = "ISBNs"; break;
        }
        return _set;
    }

  

    // if (data.hasOwnProperty("grouptokens")) {
    //     Object.keys(data.grouptokens).map(key => {
    //         tokenKey = headers.groupby_labels[key][0]
    //         tokenValue = data.grouptokens[key];
    //     })
    // }



    return (
        <div index={index}>
                <Spin spinning={loading}>
                    <div>
                        <div>{drawGroupTokens(data.grouptokens)}</div>
                        <Space>
                            <strong className="c">{moment(data.slice_datehigh * 1000).endOf("day").diff(moment(data.slice_datelow * 1000).startOf("day").add(-1, "day"), "days")} days {moment(data.slice_datelow * 1000).format("ddd MMM D-YY")} to {moment(data.slice_datehigh * 1000).format("ddd MMM D-YY")}</strong>
                            <Button className="mini-btn" onClick={() => cycleValues()} size="small" ><small>Values : {getWhichName()}</small></Button>
                            {((row_count > 1) && <Button className="mini-btn" onClick={() => setResetScale(!resetScale)} size="small" ><small>{(resetScale) ? "Cancel rescale" : "Rescale"}</small></Button>)}
                        </Space>
                    </div>
                    <Divider style={{ "margin": "15px 0px" }} dashed />
                    <AnalyticsSales range={range} setView={toggleView} includes={includes} resetScale={resetScale} getSet={getSet} high={high} filters={filters} data={data} />
                    {/* Details */}
                    {(includes.details &&
                        <Row>
                            <Col flex={"200px"}></Col>
                            <Col flex={"auto"}>
                                <div className="shim" /><div className="shim" />
                                <AnalyticsDetails filters={filters} data={data} />
                            </Col>
                        </Row>
                    )}

                    {/* synthetic view: {view} */}
                    {(view && <>
                        <AnalyticsTable grouptokens={data.grouptokens} parseSearchFilters={parseSearchFilters} searchFilters={searchFilters} grouping={grouping} item={data} setView={toggleView} drilldown={view} filters={filters} />
                    </>)}
                </Spin>
            
            <Divider />
        </div>

    )
}