import React, { useEffect, useState } from "react";
import { Typography, Divider, Row, Col, Space, Form, Input, message, Spin } from "antd";
import TitleManager from "../../../pages/settings/TitleManager";
import { apiCall } from "../../../utils/Api";
export default function TitlesSection(props) {


    const { ws, form, setChanged = () => { } } = props;

    const [presets, setPresets] = useState([]);
    const [loading, setLoading] = useState(false)

    const getPresets = () => {
        setLoading(true)
        apiCall("toolbox/get", { header: "status" }, (_status, _result) => {
            if (_status) {
                setPresets(_result.store_specific.concat(_result.bookmanager));
            } else {
                message.error(_result.error)
            }
            setLoading(false)
        })
    }

    useEffect(getPresets, [])




    return (
        <>

            <Spin spinning={loading}>
                <div id="titles" style={{ "padding": "10px 30px" }}>
                    <Typography.Title level={4}>Title Manager</Typography.Title>
                </div>
                <Divider style={{ "marginTop": "0px" }} />

               


                <div style={{ "padding": "10px 30px" }}>
                    <div className="shim" style={{ "height": "20px" }} />
                    <Space>
                        <div style={{ "width": "35px" }}></div>
                        <div id="blocklist_for_searching_and_browsing">
                            <TitleManager allowPresets={true} presets={presets} setChanged={setChanged} title="Blocklist for Searching and Browsing" subtitle="Add ISBN's to exclude them from customer searching and browsing. This would be items that are available to order from a preferred Pubstock source, or items you stock in store, that you do not want customers to find or purchase online." titles={ws["global:search_blacklist"]} filters={ws["global:search_blacklist_preset"]} preset_key="global:search_blacklist_preset" form_key="global:search_blacklist" form={form} />
                        </div>
                    </Space>
                </div>
                
                <div style={{ "padding": "10px 30px" }}>
                    <div className="shim" style={{ "height": "20px" }} />
                    <Space>
                        <div style={{ "width": "35px" }}></div>
                        <div id="allowlist_for_searching_and_browsing">
                            <TitleManager allowPresets={false} presets={presets} setChanged={setChanged} title="Allowlist for Searching and Browsing" subtitle={<>Add ISBN's to include them in customer searching and browsing. This would be items that are not available to order from a preferred Pubstock source, and that you do not stock in store, but that you want customers to find or purchase online regardless.<br /><div className="shim" /> <em>This list has very specific use cases, so please consult Bookmanager support to see if this option is required, or another setting is better suited.</em></>} filters={ws["global:search_whitelist_preset"]} titles={ws["global:search_whitelist"]} preset_key="global:search_whitelist_preset" form_key="global:search_whitelist" form={form} />
                        </div>
                    </Space>
                </div>
                
                <div style={{ "padding": "10px 30px" }}>
                    <div className="shim" style={{ "height": "20px" }} />

                    <Space>
                        <div style={{ "width": "35px" }}></div>
                        <div id="zero_price_list">
                            <TitleManager allowPresets={false} presets={presets} setChanged={setChanged} title="Zero price list" subtitle={<>Add ISBN's that can be purchased for $0. For example, an ASAN ISBN for a ticket, but the ticket is free and just being used for RSVP/attendance management purposes.<br /><div className="shim" /><em>By default, the webstore will not allow customers to check out for zero dollar items, so they must be added here as exceptions.</em></>} filters={ws["global:sell_zero_price_preset"]} titles={ws["global:sell_zero_price"]} preset_key="global:sell_zero_price_preset" form_key="global:sell_zero_price" form={form} />
                        </div>
                    </Space>
                </div>
                
                <div style={{ "padding": "10px 30px" }}>
                    <div className="shim" style={{ "height": "20px" }} />
                    
                    <Space>
                        <div style={{ "width": "35px" }}></div>
                        <div id="pay_at_pick_up_blocklist">
                            <TitleManager allowPresets={false} presets={presets} setChanged={setChanged} title="Pay at Pick Up Blocklist" subtitle={<>When a title from this blocklist has been added to a customer's cart, they won't be able to choose the 'Pay at Pick Up' option during checkout.</>} filters={ws["global:pickup_blacklist_preset"]} titles={ws["global:pickup_blacklist"]} preset_key="global:pickup_blacklist_preset" form_key="global:pickup_blacklist" form={form} />
                        </div>
                    </Space>

                </div>

                <div style={{ "padding": "10px 30px" }}>
                    <div className="shim" style={{ "height": "20px" }} />
                    <Space>
                        <div style={{ "width": "35px" }}></div>
                        <div id="blocklist_for_shipping">
                            <TitleManager allowPresets={true} presets={presets} setChanged={setChanged} title="Blocklist for Shipping" subtitle="Add ISBN's to exclude them from customer shipping. This would be items that are available to order from a preferred Pubstock source, or items you stock in store, that you do not want to ship to customers that purchase online." titles={ws["global:ship_blacklist"]} filters={ws["global:ship_blacklist_preset"]} preset_key="global:ship_blacklist_preset" form_key="global:ship_blacklist" form={form} />
                        </div>
                    </Space>
                </div>

                
                <br />
            </Spin>
        </>
    )
}