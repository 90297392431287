import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Divider, Input } from "antd";
export default function Iscroller(props) {

    const { opened = "", item_key="", check= () => {}, refine="", data = [], stepSize = 100, interator = () => { } } = props;
    const [current, setCurrent] = useState(stepSize);
    const [more, setMore] = useState(data.length > current);

    // synthetic - not a fan of this... 
    const [filters, setFilters] = useState(data);

    const sortFilters = () =>{

        let f = [...data].sort((a, b) => {
            return (check(a.v, item_key) === check(b.v, item_key))? 0 : check(a.v, item_key)? -1 : 1;
        });

        setFilters(f)
    }

    useEffect(sortFilters, [opened])


    const filterData = () => {

        let _refine = refine.toUpperCase(); 
        
        if(_refine){
            return filters.filter(item => {
                let {e = "", v = ""} = item; 
                v = v.toUpperCase(); 
                e = e.toUpperCase(); 
                
                if(v.substr(0, _refine.length) === _refine || e.substr(0, _refine.length) === _refine){
                    return true;
                }
                if(v.includes(" " + _refine) || e.includes(" " + _refine)){
                    return true; 
                }
                return false;
            })
        }
        return filters; 
    }

    return (<>
    
        <InfiniteScroll
            dataLength={filterData().slice(0, current).length}
            next={() => {
                console.log("called");
                setCurrent(current + stepSize);
            }}
            hasMore={true}
            height={(filterData().length>13) ? 300 : false}
            style={{"maxHeight" : "300px"}}
        >
           
            {filterData().slice(0, current).map(item => {
                return interator(item);
            })}
        </InfiniteScroll>
        </>
    )


}