import { Divider, Space } from "antd";
import moment from "moment-timezone";
import React from "react";
import { addCommas } from "../../utils/Utils";
import Candle from "./Candle";

export default function SalesComparisonsChart(props) {

    const { color = "#64a6da", sales = [], title = "Average Sales" } = props;
    const high = Math.max(...sales.map(i => (i.now > i.then) ? i.now : i.then))

    // Draw unique months - left as an object in case we want to be super acurate with days later...
    const drawMonths = (_arr = []) => {
        if (!_arr.length > 2) {
            return (<></>)
        }
        let _months = [];
        let _p = "";
        _arr.forEach(week => {
            let d = moment(week.date);
            if (d.format("MMYY") !== _p) {
                _months.push(
                    {
                        days: d.daysInMonth(),
                        month: d.format("MMM"),
                        year: d.format("YYYY"),
                    }
                )
                _p = d.format("MMYY");
            }
        })
        return (
            <div style={{ "display": "flex", "justifyContent": "space-between", "fontSize": "10px", "opacity": "0.8" }}>
                {_months.map((item, index) => {
                    return (
                        <div style={{ "marginTop": "-5px", "textAlign": "center" }}>
                            <div className="shim" />
                            {item.month}
                            {((item.month === "Jan" || index === 0) &&
                                <div style={{ "position": "absolute", "marginTop": "-5px" }}>{item.year}</div>
                            )}
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <>

            <div style={{ "width": "840px" }}>
                <strong className="c" style={{ "fontSize": "13px" }}>{title}</strong>
                <Divider dashed style={{ "margin": "10px 0px" }} />
                <div className="shim" />
                <div style={{ "float": "right" }}>
                    <Space>
                        <small style={{ "opacity": "0.7" }}>High : <strong>${addCommas(high)}</strong></small>
                        <div></div>
                    </Space>
                </div>
                <div>
                    <Space>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": color }}></div>
                                <div style={{ "opacity": "0.7" }}>Increase Year-over-year</div>
                            </Space>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#ea8282" }}></div>
                                <div style={{ "opacity": "0.7" }}>Decrease Year-over-year</div>
                            </Space>
                    </Space>
                </div>
                <div className="shim" /><div className="shim" />
                <Space size={0}>
                    {sales.map(item => {
                        return <Candle color={color} high={high} data={item} />
                    })}
                </Space>
                <div className="shim" />
                <div style={{ "width": "830px" }}>
                    {drawMonths(sales)}
                </div>
            </div>
        </>
    )
}