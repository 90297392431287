import { CloseOutlined, FileExcelOutlined, FilterFilled, FilterOutlined, MessageFilled, RollbackOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Checkbox, DatePicker, Divider, Dropdown, Form, Input, Layout, message, Modal, Popconfirm, Radio, Select, Space, Statistic, Table, Typography } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useState } from "react";

import Overlay from "../components/Overlay";
import Peek from "../components/Peek";
import Renderer from "../components/Renderer";
import Title from "../components/title/Title";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiCall, apiDownload } from "../utils/Api";
import conditional from "../utils/conditional";
import { useSession } from "../utils/Session";
import { addKeys, getGroups } from "../utils/Utils";
import CartImport from "./cart/CartImport";
import CartIsbns from "./cart/CartIsbns";
import CartNote from "./cart/CartNote";
import CartSubmitBtn from "./cart/CartSubmitBtn";
import InputConfirm from "./cart/InputConfirm";

export default function CartPage() {

    const [session, setSession] = useSession();
    const [expanded, setExpanded] = useState([]);
    const [isFiltering, setIsFiltering] = useState(true);
    const [loading, setLoading] = useState(false);
    const [skipConfirm, setSkipConfirm] = useState((Cookies.get("submit_confirmation") === "true") ? true : false)

    const is_multistore = (window.sitesettings.stores.length>1);

    const toggleSubmitConfirmations = () => {

        if (Cookies.get("submit_confirmation") === "true") {
            Cookies.set("submit_confirmation", "false");
            setSkipConfirm(false);
        } else {
            Cookies.set("submit_confirmation", "true");
            setSkipConfirm(true);
        }

    }

    const getSession = () => {
        setLoading(true);
        apiCall("session/get", {}, (_status, _result) => {
            if (_status) {
                setSession(_result);
            } else {
                message.error(_result.error);
            }
            setLoading(false)
        })
    }

    useEffect(getSession, [])

    const initFilters = {
        supplier: "",
        group: "",
        user: "",
        group_by: "location",
        group_bys: { location: true, order_grp: true, owner_id: false, store: false },
        sort_by: "location",
        skip: false,
        store: ""
    }

    const reset = () => {
        setFilters(initFilters);
    }

    const [filters, setFilters] = useState(
        initFilters
    );


    const actions_disabled = (filters.group_bys.store || filters.store);

    const downloadExcel = (_row) => {

        let obj = {}
        obj.supplier = filters.supplier || (filters.group_bys.location) ? _row.location : false;
        if (!obj.supplier) { delete obj.supplier }
        obj.order_group = filters.group || (filters.group_bys.order_grp) ? _row.order_grp : false;
        if (!obj.order_group) { delete obj.order_group }
        obj.user_id = filters.user || (filters.group_bys.owner_id) ? _row.owner_id : false;
        if (!obj.user_id) { delete obj.user_id }

        apiDownload(obj, "cart/getExcel", true);
    }


    // "id": 13799815,
    // "isbn": "9781571313560",
    // "quantity": 1,
    // "location": "QTA",
    // "supplier_price": "26.95",
    // "sell_price": "26.95",
    // "discount": "RG",
    // "exchange": "1",
    // "order_grp": "A",
    // "owner_id": 554904,
    // "multistore_quantity": [
    //   1
    // ],
    // "shipto_name": "",
    // "cust_po": "",
    // "as_of": 1633041091,
    // "order_notes": ""

    const arrayToKeyString = (_arr) => {

        let a = [];
        _arr.forEach(item => {
            Object.keys(item).forEach(_key => {
                if (item[_key]) {
                    a.push(_key);
                }
            })
        })

        return a.join("-");
    }

    const calculateProfitMargin = (_sell_price, _publish_price) => {
        let ret = (1 - (_publish_price / _sell_price)) * 100;
        if (ret > 100 || ret < 0 || isNaN(ret)) {
            return ""
        }
        return ret.toFixed(0);;
    }

    const convertDiscountToInt = (_discount, _profit_margin = 40) => {

        if (_discount == 'SH') {
            _discount = 40;
        }

        if(_discount == 'RG'){
            _discount = _profit_margin;
        }

        if (_discount == 'BX' ||  _discount == 'HI' || _discount == '') {
            _discount = 40;
        }

        if (_discount == 'NT') {
            _discount = 0;
        }
        _discount -= 0;
        return _discount;
    }

    const deleteRow = (_row) => {
        let obj = {}

        obj.supplier = filters.supplier || (filters.group_bys.location) ? _row.location : false;
        if (!obj.supplier) { 
            delete obj.supplier 
        }

        // obj.order_group = filters.group || (filters.group_bys.order_grp) ? _row.order_grp : false;

        // if (!obj.order_group ) { 
        //     delete obj.order_group 
        // }
        if(filters.group || filters.group_bys.order_grp){
            obj.order_group = _row.order_grp;
        }

        obj.user_id = filters.user || (filters.group_bys.owner_id) ? _row.owner_id : false;
        if (!obj.user_id) { 
            delete obj.user_id 
        }

        setLoading(true);
        apiCall("cart/delete", obj, (_status, _result) => {
            if (_status) {
                setSession({ ...session, "cart": _result })
            } else {
                // refetch the session
                message.error(_result.error)
            }
            setLoading(false);
        })

    }

    const filterData = () => {

        // filter by supplier, group and user or multiples 

        let _supp = [];

        session.cart.forEach((item => {
            item.suppliers.forEach(supp => {

                // Testing code - some stores set carts on v1 and don't set the multistore value (get tobias to do this on the server)
                // if(supp.multistore_quantity.length === 0){
                //     supp.multistore_quantity = [{
                //         "1158481": supp.quantity
                //     }]
                // }


                // Adding more synthetic rows for mulitistore location sorting/filtering

               

                supp.multistore_quantity.forEach(store => {
                    // only one ideration
                    Object.keys(store).forEach(_key => {
                        if (store[_key]) {
                            supp.store = _key;
                            supp.store_qty = store[_key];

                            _supp.push({ ...supp });
                        }
                    })
                })
                //_supp.push(supp);
                // OG way - no synthetic rows for multistore sorting/filtering
                //_supp.push(supp);
            })
        }))

      

        const filteredCart = _supp.filter(item => {

            let _rtrn = true;

            if (filters.supplier) {
                _rtrn = (item.location === filters.supplier);
            }

            if (filters.group && _rtrn) {
                _rtrn = (item.order_grp === filters.group);
            }

            if (filters.user && _rtrn) {
                _rtrn = (item.owner_id === filters.user);
            }

            if (filters.store) {
                _rtrn = (item.store === filters.store);
            }


            return _rtrn;

        });


        const groupMultiple = (_arr) => {
            return [..._arr.reduce((r, o) => {
                let _key = [];
                // add em if checked... 
                Object.keys(filters.group_bys).map(item => {
                    if (filters.group_bys[item]) {
                        _key.push(o[item]);
                    }
                })




                // if (_key.length === 0) {
                //     // default owner
                //     _key.push(o.owner_id);
                // }

                // convert to unique string
                _key = _key.join("-");

                const item = r.get(_key) || Object.assign({}, o, {
                    key: _key,
                    suppliers: [],
                    stores: [],
                    supplier_names: [],
                    groups: [],
                    isbns: [],
                    items: [],
                    cart: 0,
                    supplier_price: 0,
                    sell_price: 0,
                    discounts: [],
                    margins: 0,
                    by: [],
                    age: moment().unix(),
                });

                // group unique suppliers
                if (!item.suppliers.includes(o.location)) {
                    item.suppliers.push(o.location);
                }

                // group unique suppliers
                if (!item.supplier_names.includes(o.location_name)) {
                    item.supplier_names.push(o.location_name);
                }

                // group unique groups
                if (!item.groups.includes(o.order_grp)) {

                    item.groups.push(o.order_grp);
                }

                // group unique stores

                if (!item.stores.includes(o.store)) {

                    item.stores.push(o.store);
                }


                // o.multistore_quantity.forEach(_item => {    
                //     Object.keys(_item).forEach(key => {
                //         if ((_item[key]) && !item.stores.includes(key)) {
                //             item.stores.push(key);
                //         }
                //     })
                // })

                // group unique isbns 
                if (!item.isbns.includes(o.isbn)) {
                    item.isbns.push(o.isbn);
                }

                // group cart items
                
                item.items.push(o);
              
                // group unique owners 
                if (!item.by.includes(o.owner_name)) {
                    item.by.push(o.owner_name);
                }

                // add up cart

                if(is_multistore){
                    item.cart += o.store_qty;
                } else {
                    item.cart += o.quantity;
                }
                

               

               
                

                // add up supplier price
                item.supplier_price += parseFloat(parseFloat(o.supplier_price) * o.store_qty);

                // add up sell price
                item.sell_price += (parseFloat(o.sell_price) * o.store_qty);

                // push margins
                item.margins += parseFloat((1 - (convertDiscountToInt(o.discount) / 100)) * parseFloat(o.supplier_price)) * o.store_qty;

                // push discounts
                item.discounts.push(convertDiscountToInt(o.discount, o.profit_margin));

                item.age = (o.as_of < item.age) ? o.as_of : item.age;

                return r.set(_key, item);
            }, new Map).values()];
        }

        return groupMultiple(filteredCart).sort((a, b) => {
            let _compare = a[filters.sort_by].localeCompare(b[filters.sort_by]);


            if (_compare !== 0) {
                return _compare;
            } else {

                if (filters.sort_by === "order_grp") {
                    if (a.location_name && b.location_name) {
                        return a.location_name.localeCompare(b.location_name);
                    } else {
                        return 1;
                    }

                } else {
                    return a.order_grp.localeCompare(b.order_grp);
                }

            }

        });


        return groupMultiple(filteredCart).sort((a, b) => a[filters.sort_by].localeCompare(b[filters.sort_by]));
    }

    const saveNote = (_note, _row) => {


        let obj = {}
        obj.supplier = filters.supplier || _row.location;
        obj.order_group = filters.group || _row.order_grp;
        obj.note = _note;


        apiCall("cart/setNote", obj, (_status, _result) => {
            if (_status) {
                setSession({ ...session, "cart": _result });
            }
        })

    }


    const checkModify = (_key, _value, _row, _msg) => {

        Modal.confirm({ title: "Please confirm", content: <div style={{ "opacity": "0.6" }}>{_msg}</div>, onOk: () => modify(_key, _value, _row) });

    }

    const modify = (_key, _value, _row, e) => {
        let obj = {}
        obj.supplier = filters.supplier || (filters.group_bys.location) ? _row.location : false;
        if (!obj.supplier) { delete obj.supplier }
        obj.order_group = filters.group || (filters.group_bys.order_grp) ? _row.order_grp : false;
        if (!obj.order_group) { delete obj.order_group }
        obj.user_id = filters.user || (filters.group_bys.owner_id) ? _row.owner_id : false;
        if (!obj.user_id) { delete obj.user_id }
        obj[_key] = _value;

        setLoading(true);

        apiCall("cart/edit", obj, (_status, _result) => {
            if (_status) {
                setSession({ ...session, "cart": _result });
            } else {
                message.error(_result.error)
            }
            setLoading(false)
        })
    }

    const modifyInput = (_key, _value, _row) => {
        // eslint-disable-next-line default-case
        switch (_key) {
            case "suppliers":
                return <InputConfirm disabled={(actions_disabled)} callback={modify} title="Changing a supplier may result in a merge." flag={"new_supplier"} row={_row} className="tinyInput" defaultValue={_value} style={{ "width": "58px" }} />
            case "discounts":
                return <InputConfirm disabled={(actions_disabled)} callback={modify} title="Are you sure you wish to change the discount?" flag={"discount"} row={_row} className="tinyInput" defaultValue={_value} style={{ "width": "58px" }} />
            case "groups":
                // return (
                //     <AutoComplete
                //         value={_value}
                //         className="tinyAutocomplete" 
                //         dropdownClassName="tinyDropdown"
                //         onChange={(e) => checkModify("new_order_group", e, _row, "Changing a group may result in a merge.")}
                //         style={{ "fontSize": "11px", "width": "45px" }}
                //         options={getGroups().map(item => {
                //             return {
                //                 value: item
                //             }
                //         })}
                //     />
                // )
                return (
                    <Select disabled={(actions_disabled)} showSearch onChange={(e) => checkModify("new_order_group", e, _row, "Changing a group may result in a merge.")} value={_value} style={{ "fontSize": "11px", "width": "45px" }} showArrow={false} className="tinyAutocomplete" dropdownClassName="tinyDropdown">
                        {getGroups().map((item) => {
                            return <Select.Option key={item} value={item}>{item}</Select.Option>
                        })}
                    </Select>
                )
        }

    }


    // suppliers: [],
    // groups: [],
    // isbns: [],
    // cart: 0,
    // supplier_price: 0,
    // sell_price: 0,
    // discounts : [],
    // margins : [],
    // age : 0,

    const mergeNote = (_sup, _group, _notes, _check = false) => {

        if (_sup.length === 1 && _group.length === 1) {
            if (_check) { return true; }
            if (_notes) {
                return _notes.split("\n").map((note, index) => {
                    return (<div key={index} style={{ "fontStyle": "italic", "color": "#aaa", "lineHeight": "12px" }}>{note}</div>)
                });
            } else {
                return "";
            }
        }
        return (_check) ? false : "";
    }

    const mergeData = (_arr, average = false) => {

        if (_arr.length === 0) {
            return "";
        }
        // average
        if (average) {
            return parseInt(_arr.reduce((total, obj) => (parseFloat(obj)) + total, 0) / _arr.length);
        }

        if (_arr.length === 1) {
            return _arr[0];
        } else {
            return "(" + _arr.length.toString() + ")";
        }
    }

    const columns = [

        { key: "blank", width: "10px" },
        {
            title: <small>Supplier</small>, className: "cellBorder", key: 'suppliers', dataIndex: 'suppliers', render: (e, f) => {
                return (e.length > 1) ? mergeData(e) : modifyInput("suppliers", mergeData(e), f);
            }
        },
        {
            title: <small>Group</small>, className: "cellBorder", key: 'groups', dataIndex: 'groups', render: (e, f) => {
                return (e.length > 1) ? mergeData(e) : modifyInput("groups", mergeData(e), f);
            }
        },
        {
            title: <small>Supplier Name</small>, className: "cellBorder", key: 'supplier_names', dataIndex: 'supplier_names', render: (e, f) => {
                return <><a onClick={() => expandCell(f)}>{mergeData(e)}{mergeNote(f.suppliers, f.groups, f.note)}</a></>;
            }
        },

        {
            title: <small>Store</small>, key: 'stores', dataIndex: 'stores', render: (e, f) => {
                // return <pre>{JSON.stringify(e, null, 2)}</pre>;
                return (e.length > 1) ? mergeData(e) : e;
            }
        },

        {
            title: <small>Note</small>, key: 'note', dataIndex: 'note', render: (e, f) => {
                return (mergeNote(f.suppliers, f.groups, f.note, true)) ? <><CartNote disabled={actions_disabled} saveNote={saveNote} row={f} note={e} /></> : "";
            }
        },

        {
            title: <small>ISBN's</small>, key: 'isbns', dataIndex: 'isbns', render: (e, f) => {
              
                return <Button className="bc" type="link" size="small" onClick={() => expandCell(f)}><small>{e.length}</small></Button>
            }
        },
        { title: <small>Cart</small>, key: 'cart', dataIndex: 'cart' },
        {
            title: <small><nobr>Supplier Price</nobr></small>, key: 'supplier_price', dataIndex: 'supplier_price', render: (e) => {
                return (e) ? "$" + e.toFixed(2).toString() : "";
            }
        },
        {
            title: <small>Discount</small>, key: 'discounts', dataIndex: 'discounts', render: (e, f) => {
                return modifyInput("discounts", mergeData(e, true), f);
            }
        },
        {
            title: <small><nobr>Sell</nobr></small>, key: 'sell_price', dataIndex: 'sell_price', render: (e) => {
                return (e) ? "$" + e.toFixed(2).toString() : "";
            }
        },
        {
            title: <small>Margin</small>, key: 'margins', dataIndex: 'margins', render: (e, f) => {

                return calculateProfitMargin(f.sell_price, e);

            }
        },
        { title: <small>Age</small>, key: 'age', dataIndex: 'age', render: (e) => { return moment(e * 1000).fromNow() } },
        {
            title: <small>By</small>, className: "cellBorder", key: 'by', dataIndex: 'by', render: (e) => {
                return mergeData(e);
            }
        },
        {
            title: <small>XLS</small>, width: "20px", className: "cellBorder", key: "excel", dataIndex: "excel", render: (e, f) => {
                return <Button disabled={(actions_disabled)} onClick={() => downloadExcel(f)} type="link"><FileExcelOutlined style={{ "fontSize": "16px" }} /></Button>
            }
        },
        {
            title: <small>Actions</small>, width: "100px", key: 'supplier', dataIndex: 'supplier', render: (e, f) => {
                return (
                    <Space>
                        <Popconfirm disabled={(actions_disabled)} title="Remove these titles from your cart?" onConfirm={() => deleteRow(f)}>
                            <Button disabled={(actions_disabled)} danger size="small"><small>Remove</small></Button>
                        </Popconfirm>
                        <CartSubmitBtn setExpanded={setExpanded} disabled={(actions_disabled)} skipConfirm={skipConfirm} data={f} filters={filters} group_bys={filters.group_bys} />
                    </Space>
                )
            }
        }
    ]

    // "id": 13799815,
    // "isbn": "9781571313560",
    // "quantity": 1,
    // "location": "QTA",
    // "supplier_price": "26.95",
    // "sell_price": "26.95",
    // "discount": "RG",
    // "exchange": "1",
    // "order_grp": "A",
    // "owner_id": 554904,
    // "multistore_quantity": [
    //   1
    // ],
    // "shipto_name": "",
    // "cust_po": "",
    // "as_of": 1633041091,
    // "order_notes": ""

    const expandCell = (_data) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }

    const drawChildTable = (_record) => {
        return (
            <div className="hideHover" style={{ "margin": "-8px -7px" }}>
                <div style={{ "padding": "0 20px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none", "marginBottom": "0px" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                </div>
                <div style={{ "padding": "0px 20px" }}>
                    <Renderer close={false}>
                        <CartIsbns record={_record} items={_record.items} />
                    </Renderer>
                </div>
            </div>
        )
    }

    const alternateRowColours = (_record, _index) => {
        if(_index%2 === 1){
            return "evenRowColour"
        }
    }

    const group_set = [];
    const owner_set = [];
    const unique_ownerset = [];
    const location_set = [];


    if (session.hasOwnProperty("cart")) {
        session.cart.forEach(item => {
            item.suppliers.forEach((supp => {
                group_set.push(supp.order_grp);
                let find = owner_set.find(item => item.id == supp.owner_id);
                if (!find) {
                    unique_ownerset.push({ id: supp.owner_id, name: supp.owner_name });
                }
                owner_set.push({ id: supp.owner_id, name: supp.owner_name });
                location_set.push(supp.location);

            }))
        })
    }


    return (
        <Layout className="layout">
            <LayoutHeader
                title="Cart"
                filters={
                    <Form size="small" layout="vertical">
                        <Space size={10}>
                            <Form.Item label={<small>Sort by</small>}>
                                <Radio.Group value={filters.sort_by} onChange={(e) => setFilters({ ...filters, "sort_by": e.target.value })}>
                                    <Radio.Button value="location"><small>Supplier</small></Radio.Button>
                                    <Radio.Button value="order_grp"><small>Group</small></Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label={<small>Group by</small>}>
                                <div className="ant-radio-group ant-radio-group-small">
                                    <Radio.Button onClick={() => setFilters({ ...filters, "group_bys": { ...filters.group_bys, "location": !filters.group_bys.location } })} checked={(filters.group_bys.location)} ><small>Supplier</small></Radio.Button>
                                    <Radio.Button onClick={() => setFilters({ ...filters, "group_bys": { ...filters.group_bys, "order_grp": !filters.group_bys.order_grp } })} checked={(filters.group_bys.order_grp)}><small>Group</small></Radio.Button>
                                    <Radio.Button onClick={() => setFilters({ ...filters, "group_bys": { ...filters.group_bys, "owner_id": !filters.group_bys.owner_id } })} checked={(filters.group_bys.owner_id)} ><small>User</small></Radio.Button>
                                    {((window.sitesettings.stores.length > 1) && <Radio.Button onClick={() => setFilters({ ...filters, "group_bys": { ...filters.group_bys, "store": !filters.group_bys.store } })} checked={(filters.group_bys.store)} ><small>Location</small></Radio.Button>)}
                                </div>
                            </Form.Item>
                            <Form.Item label={<small>Supplier</small>}>
                                <Select placeholder={<small>Select...</small>} value={(filters.supplier) ? filters.supplier : null} showSearch onChange={(e) => setFilters({ ...filters, "supplier": e })} style={{ width: 90 }}>
                                    {(filters.supplier && <Select.Option key="" value=""><small>- none -</small></Select.Option>)}
                                    {[... new Set(location_set)].map((_location) => {
                                        return (<Select.Option key={_location} value={_location}><small>{_location}</small></Select.Option>)
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label={<small>Group</small>}>
                                <Select placeholder={<small>Select...</small>} value={(filters.group) ? filters.group : null} showSearch onChange={(e) => setFilters({ ...filters, "group": e })} style={{ width: 90 }}>
                                    {(filters.group && <Select.Option value=""><small>- none -</small></Select.Option>)}
                                    {[... new Set(group_set)].map((_order_grp) => {
                                        return (<Select.Option key={_order_grp} value={_order_grp}><small>{_order_grp}</small></Select.Option>)
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label={<small>User</small>} >
                                <Select placeholder={<small>Select...</small>} value={(filters.user) ? filters.user : null} onChange={(e) => setFilters({ ...filters, "user": e })} style={{ width: 160 }}>
                                    {(filters.user && <Select.Option value=""><small>- none -</small></Select.Option>)}
                                    {unique_ownerset.map((_owner) => {
                                        return (<Select.Option key={_owner.id} value={_owner.id}><small>{_owner.name}</small></Select.Option>)
                                    })}
                                </Select>
                            </Form.Item>        

                            <conditional.true value={(window.sitesettings.stores.length > 1)}>
                                <Form.Item label={<small>Location</small>} >
                                    <Select placeholder={<small>Select...</small>} value={(filters.store) ? filters.store : null} onChange={(e) => setFilters({ ...filters, "store": e })} style={{ width: 160 }}>

                                        {(filters.store && <Select.Option value=""><small>- none -</small></Select.Option>)}
                                        {window.sitesettings.stores.map((_store) => {
                                            return (<Select.Option key={_store.san} value={_store.san}><small>{_store.name}</small></Select.Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                            </conditional.true>


                            <Form.Item label={<small>Import</small>}>
                                <Overlay width={565} component={<CartImport />}><Button type="primary"><small><FileExcelOutlined />&nbsp;Import</small></Button></Overlay>
                            </Form.Item>

                            <Form.Item label={<small>&nbsp;</small>}>
                                <Button disabled={(JSON.stringify(initFilters) === JSON.stringify(filters))} onClick={() => reset()} type="secondary"><small><RollbackOutlined />&nbsp;Reset</small></Button>
                            </Form.Item>

                            <Form.Item label={<small>&nbsp;</small>}>
                                <Checkbox onChange={() => toggleSubmitConfirmations()} checked={skipConfirm}><small>Skip submit confirmation</small></Checkbox>
                            </Form.Item>
                        </Space>
                        <br clear="both" />
                        <div className="borderTop" style={{ "margin": "0 -20px" }}>
                            <Table
                                loading={loading}
                                size="small"
                                columns={columns}
                                dataSource={filterData()}
                                pagination={false}
                                expandable
                                rowClassName={(record, index) => alternateRowColours(record, index)}
                                expandedRowKeys={expanded}
                                expandIconColumnIndex={-1}
                                onExpand={(e) => { return false; }}
                                expandIcon={() => { return (<></>) }}
                                expandedRowRender={((record) => drawChildTable(record))}
                            />
                        </div>
                    </Form>
                }
            />
        </Layout>
    )

}