import React, { useState, useEffect } from "react";
import { Input, Popover, Button, Modal, message, Space } from "antd";
import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";
import { MessageFilled } from '@ant-design/icons';

export default function CartNote(props) {

    const { disabled=false, row = {}, saveNote = () => { } } = props;
    const [note, setNote] = useState(props.note);
    const [visible, setVisible] = useState(false);

    const resetNote = () =>{
        setNote(props.note);
    }

    useEffect(resetNote, [props.note]);


    const save = (_delete = false) => {
        setVisible(false);

        if(_delete){
            saveNote("", row);
           
        } else {
            saveNote(note, row);
        }
        
    }

    const drawEditior = () => {
        return (
            <>
                <Input.TextArea onChange={(e) => setNote(e.target.value)} maxLength={1000} showCount defaultValue={note}></Input.TextArea>
                <div className="shim" />
                <Space>
                    <Button onClick={() => setVisible(false)} type="secondary" size="small"><small>Cancel</small></Button>
                    <Button onClick={() => save(true)} type="primary" danger size="small"><small>Delete</small></Button>
                    <Button onClick={() => save()} type="primary" size="small"><small>Save</small></Button>
                    
                </Space>
            </>
        )
    }

    if(disabled){
       return <div style={{ "color": (props.note) ? "#edd23a" : "#aaa", "opacity" : "0.5" }}>&nbsp;&nbsp;<MessageFilled /></div>
    }

    return (
        <>
            <Popover 
                destroyTooltipOnHide 
                onVisibleChange={(e) => setVisible(e)} 
                visible={visible} 
                content={drawEditior()} 
                title={<small>Notes</small>} 
                trigger="click">
                <div onClick={() => setVisible(!visible)} style={{ "width": "100%", "display": "block", "cursor": "pointer", "overflow": "hidden" }}>
                    <div style={{ "color": (props.note) ? "#edd23a" : "#aaa" }}>&nbsp;&nbsp;<MessageFilled /></div>
                </div>
            </Popover>
        </>
    )
}