import React, { useState, useEffect } from "react";
import { Col, message, Row, Space, Tooltip, Spin, Tag } from "antd";
import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";
import nocover from "../../media/no-cover.png";
import moment from "moment";
import { bestsellerColor, bmDate, parseAuthors, parseSubjects } from "../../utils/Utils";
import conditional from "../../tools/conditional";
import { ucfirst } from "../../utils/Utils";
import ItemHistory2 from "../ItemHistory2";
import SalesActivity from "../SalesActivity";
import Overlay from "../Overlay";
import PeerSales from "../PeerSales";
import Html from "../../utils/Html";
import { itemdata } from "../../data";
import { useSession } from "../../utils/Session";
import { LoadingOutlined } from '@ant-design/icons';
import logosmall from "../../media/b.png"
import BMNoteCats from "./BMNoteCats";
import { usePrefs } from "../../utils/Prefs";

export default function BMSummary(props) {

    const { data = {}, series = "", suggested_bm_data = {}, condition = "", condition_code = "", priority = true, isbn = "" } = props;
    const [session, setSession] = useSession();
    const [station, setStation] = useState((session.station_id) ? session.station_id : "-");
    const [stationLoading, setStationLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [prefs, setPrefs] = usePrefs(); 

    message.config({
        top: 50,
        duration: 2,
        maxCount: 1
    })

    // auth, pubd


    const spinIcon = <LoadingOutlined style={{ "fontSize": 14, "color": "#fff" }} spin />;


    const changeStation = (_station) => {


        if (session.station_id.toString() === _station.toString()) {
            return;
        }

        setStationLoading(true);
        apiCall("bookmanager/setStationId", { station_id: (station && station !== "-") ? station : -1 }, (_status, _result) => {
            if (_status) {
                setStation("-");
                setSession(_result);
                openTitleInStation();
                setStationLoading(false);
            }
        })
    }

    const openTitleInStation = (_isbn, _condition = "") => {



        if (!station) {
           
            return;
            
        }

        if (isNaN(station)) {
          
            return;
        }


        if (!stationLoading) {
            setStationLoading(true);
        }

        setSent(true);

        setTimeout(() => {
            setSent(false)
        }, 1000);

     
        apiCall("bookmanager/sendIsbn", { isbn: primary_key }, (_status, _result) => {
            if (_status) {
                setStationLoading(false);
            }
        })

    }




    const {
        primary_key = "",
        secondary_key = "",
        discount = "",
        used_condition = "",
        title = "",
        pubdate = "",
        author = "",
        binding = "",
        price = "",
        cost = "",
        sell_price = "",
        cost_locked = false,
        carton_quantity = 0,
        profit_cost_string = "",
        supplier_1 = "",
        supplier_2 = "",
        supplier_3 = "",
        class_1 = "",
        class_2 = "",
        section = "",
        out_of_stock_status = "",
        returnable = "",
        entered = "",
        received = "",
        onhand = 3,
        available_on_hand = 0,
        pending_on_order = 0,
        on_order = 0,
        special_order = 0,
        held = 0,
        pending_return = 0,
        backroom = 0,
        minimum_on_hand = 0,
        original_publisher = "",
        row_id = 0,
        notes = "",
        row_status = ""
    } = data;



    const conversion = {
        A: 'AVAI',
        B1: 'BOS',
        B2: 'BNYP',
        B3: 'BRP',
        B4: 'BITO',
        C1: 'COS',
        C2: 'CNYP',
        C3: 'CNOP',
        C4: 'CNR',
        C5: 'COP',
        C6: 'CNL',
        C7: 'CNW',
        C8: 'COSI',
        C9: 'CPXL',
        CA: 'CRP',
        CB: 'CITO',
        CC: 'CBU',
        CD: 'CBC'
    }

    const convertOSStat = (_osstat = "") => {
        if(conversion.hasOwnProperty(_osstat.toUpperCase())){
            return conversion[_osstat.toUpperCase()];  
        } else {
            return _osstat;
        }
    }


    const converted_out_of_stock_status = convertOSStat(out_of_stock_status);


    const parseUsedCondition = (_cond) => {
        if (!_cond) {
            return <>&nbsp;</>
        }
        if (_cond.includes("(")) {
            let split = _cond.split("(");
            return split[0];
        } else {
            return _cond;
        }
    }

    const drawPC = (_cost, _net) => {
        _cost = parseFloat(_cost);
        _net = parseFloat(_net);
        let _per = Math.round(100 - ((_cost / _net) * 100));
        let _prof = Math.round((_net - _cost) * 100);
        return "PC" + _per.toString() + _prof.toString().padStart(5, '0');
    }

    const drawCol = (_lbl, _val, _bg = "#fafafa", _color = "#212121", _border = true, bold = false, position = "right") => {
        if (!_val) {
            _val = <>&nbsp;</>;
            _bg = "#fafafa";
            _color = "#212121"
        }
        return (
            <>

                <conditional.true value={(_lbl)}>
                    <div className="bc" style={{ "paddingLeft": (position === "left") ? "2px" : "0px", "paddingRight": (position === "right") ? "2px" : "0px", "borderRight": (_border) ? "1px solid" : "none", "borderBottom": "1px solid", "lineHeight": "16px", "textAlign": position }}><small style={{ "fontSize": "11px", "color": "#888", "fontStyle": "italic" }}>{_lbl}</small></div>
                </conditional.true>

                <div className="bc" style={{ "paddingLeft": (position === "left") ? "2px" : "0px", "paddingRight": (position === "right") ? "2px" : "0px", "backgroundColor": _bg, "color": _color, "fontWeight": (bold) ? "500" : "normal", "textAlign": position, "fontSize": "14px", "lineHeight": "16px", "borderRight": (_border) ? "1px solid" : "none" }}><small>{_val}</small></div>
            </>
        )
    }


    const drawNotes = (_notes = "") => {


        if (!_notes) {
            return "";
        }

        let flags = ["^^", "//%", "//$", "//@", "//?", "//O"];

        let _split = _notes.split(" ");


        for (let i = 0; i < _split.length; i++) {
            flags.forEach(flag => {
                if (_split[i].substring(0, flag.length) == flag) {
                    _split[i] = "<span style='color:#f12a46;'>" + _split[i] + "</span>"
                }
            })
        }

        _notes = _split.join(" ");

        return <div style={{ "lineHeight": "20px" }}><Html html={_notes} /></div>;

    }


    const drawSales = (_prev = false) => {

        if (sell_price && _prev) {

            if (discount) {
                return drawValue(<><span style={{ "color": "#f12a46" }}></span><div><small>({discount}% off ${price})</small></div></>, "center")
            }
            return drawValue(<><div style={{ "marginTop": "-5px" }}><small>(reg. ${price})</small></div></>, "center")
        }

        if (_prev) {
            return "";
        }

        return drawValue(<div style={{ "fontSize": "13px", "color": (sell_price) ? "#f12a46" : "inherit", "fontWeight": "600", "lineHeight": "20px" }}>{(sell_price) ? "$" + sell_price.toString() : "$" + price.toString()}</div>, "center");
    }

    const drawLabel = (_val, _align = "left", _weight = "normal", _border = false) => {
        return (<div className="bc bmLabel" style={{ "paddingLeft": (_align === "left") ? "4px" : "0px", "paddingRight": (_align === "right") ? "4px" : "0px", "borderRight": (_border) ? "1px solid" : "none", "lineHeight": "18px", "textAlign": _align }}><small style={{ "fontSize": "11px", "fontStyle": "italic" }}>{_val}:</small></div>)
    }

    const drawValue = (_val, _align = "left", _weight = "normal", _border = false) => {
        return (<div className="bc bmValue" style={{ "paddingLeft": (_align === "left") ? "4px" : "0px", "paddingRight": (_align === "right") ? "4px" : "0px", "borderRight": (_border) ? "1px solid" : "none", "lineHeight": "18px", "textAlign": _align }}><small style={{ "fontSize": "11px", "fontWeight": _weight }}>{(_val) ? _val : <>&nbsp;</>} </small></div>)
    }



    return (
        <>
            <conditional.true value={(priority)}>

                <div className="bcg4" style={{ "fontWeight": "bold", "fontSize": "11px", "display": "inline", "padding": "3px 8px", "borderRadius": "2px 2px 0 0" }}>{condition}</div>


            </conditional.true>
            <div className="bc" style={{ "border": "1px solid", "width": "589px", "borderTop": (priority) ? "1px solid" : "none", "borderBottom": (priority) ? "none" : (prefs.bm_notes) ? "1px solid" : "none", "borderLeft": "6px solid" }}>

                <conditional.true value={(priority)}>
                    <Row className="bc" style={{ "textAlign": "center", "fontSize": "12px" }}>

                        {/* {(condition !== "New") && <Col style={{ "borderRight": "1px solid #eee" }} flex={"auto"}>{drawValue(condition, "left", "bold")}</Col>} */}

                        <Col flex={"52px"}>
                            <Row>

                                <Spin indicator={spinIcon} size="small" spinning={(stationLoading)}>
                                    <Tag style={{ "border": "none", "paddingRight": "0px", "borderRadius": "0px", "marginRight": "0px" }} color="blue">
                                        <conditional.true value={(!sent)}>

                                            <Space size={2}>

                                                <Tooltip title="View this title in Bookmanager at the specified station">
                                                    <div style={{ "width": "18px" }}>
                                                        <img onClick={() => openTitleInStation()} alt="bookmanager" style={{ "marginLeft": "-5px", "filter": (session.station_id || session.station_id === 0) ? "grayscale(0%)" : "grayscale(100%)", "cursor": "pointer", "marginTop": "-4px", "height": "17px", "width": "19px" }} width={32} src={logosmall} />
                                                    </div>
                                                </Tooltip>

                                                <div className="bminput" style={{ "textAlign": "center", "fontWeight": "strong", "fontSize": "11px", "lineHeight": "18px" }}>

                                                    <Tooltip title="Bookmanager workstation # to show this title on">
                                                        <div className="bc" style={{ "width": "24px", "height": "23px" }}>
                                                            <div style={{ "marginTop": "5px" }}>
                                                                <input onFocus={(e) => e.target.select()} onBlur={(e) => changeStation(e.target.value)} onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        e.currentTarget.blur();
                                                                    }
                                                                }} value={(station !== "-") ? station : session.station_id} onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                        return;
                                                                    } else {
                                                                        if (e.target.value.length > 2) {
                                                                            return;
                                                                        } else {
                                                                            setStation(e.target.value)
                                                                        }
                                                                    }

                                                                }} style={{ "width": "23px", "textAlign": "center", "fontWeight": "bold" }} className="bm-input" />
                                                            </div>
                                                        </div>

                                                    </Tooltip>
                                                </div>
                                            </Space>
                                        </conditional.true>

                                        <conditional.true value={(sent)}>
                                            <div className="bc" style={{ "width": "46px", "borderRight": "1px solid", "fontWeight": "bold" }}><div style={{ "marginLeft": "-3px", "fontSize": "11px" }}>Sent</div></div>
                                        </conditional.true>

                                    </Tag>

                                </Spin>

                                {/* <Col flex={"28px"}>
                                    <div onClick={() => openTitleInStation()} style={{ "color": (station) ? "#1b1b1b" : "#999", "cursor": (station) ? "pointer" : "inherit", "fontWeight": "600", "backgroundColor": "#eee", "fontSize": "11px", "lineHeight": "20px" }}>
                                        <Tooltip title="View this title in Bookmanager at the specified station"><Spin indicator={spinIcon} size="small" spinning={(stationLoading)}>BM</Spin></Tooltip>
                                    </div>
                                </Col> */}
                                <Col flex={"24"}>

                                </Col>
                            </Row>
                        </Col>


                        <Col className="bc bcg5" style={{ "borderLeft": "1px solid" }} flex={"424px"}><div style={{ "padding": "3px", "height": "30px" }}><BMNoteCats suggested_bm_data={suggested_bm_data} isbn={isbn} series={series} condition_code={condition_code} condition={condition} data={data} /></div></Col>

                        <Col className="bc" style={{ "borderLeft": "1px solid" }} flex={"28px"}><div className="bc" style={{ "height": "30px", "lineHeight": "28px" }}>{(section && <small>{section}</small>)}</div></Col>

                        {/* <Col flex={"auto"}>{drawValue(drawNotes(notes))}</Col> */}
                        <Col className="bc" style={{ "borderLeft": "1px solid" }} flex={"77px"}>
                            <div style={{ "textAlign": "center", "width": "77px" }}>

                                <div style={{ "marginTop": (sell_price) ? "0px" : "5px" }}><nobr>{drawSales()}</nobr></div>
                                <div style={{ "marginTop": "-7px", "marginBottom": "-5px" }}>{drawSales(true)}</div>

                            </div>
                        </Col>





                        {/* <Col flex={"65px"}>{drawValue((received) ? moment(received).format("MMM D-YY") : "")}</Col> */}
                    </Row>
                    <Row>
                        <Col flex={"504px"}>

                        </Col>
                        <Col className="bc" style={{ "borderLeft": "1px solid" }} flex={"77px"}>
                            <div className="bc" style={{ "textAlign": "center", "width": "77px", "borderBottom": "1px solid" }}>

                            </div>
                        </Col>
                    </Row>

                    {/* drawLabel("1st Rcvd") */}


                </conditional.true>


                <conditional.true value={(!priority && prefs.bm_notes)}>
                    <Row className="bc" style={{ "textAlign": "center", "fontSize": "12px", "borderBottom": "1px solid" }}>
                        <Col flex={"33px"}>{drawLabel("ISBN")}</Col>
                        <Col className="bc" style={{ "borderRight": "1px solid" }} flex={"116px"}>{drawValue(primary_key)}</Col>
                        <Col className="bc" style={{ "borderRight": "1px solid" }} flex={"279px"}>{drawValue(secondary_key)}</Col>
                        <Col flex={"20px"}>{drawLabel("PC")}</Col>
                        <Col flex={"auto"}>{drawValue(<>{profit_cost_string}&nbsp;&nbsp;</>)}</Col>

                    </Row>

                    <Row className="bc" style={{ "textAlign": "center", "fontSize": "12px", "borderBottom": "1px solid" }}>
                        <Col flex={"33px"}>{drawLabel("Title")}</Col>
                        <Col className="bc" style={{ "borderRight": "1px solid" }} flex={"auto"}>{drawValue(title)}</Col>
                        <Col flex={"15px"}>{drawLabel("Bd")}</Col>
                        <Col className="bc" style={{ "borderRight": "1px solid" }} flex={"25px"}>{drawValue(binding, "right")}</Col>
                        <Col flex={"50px"}>{drawLabel("Entered")}</Col>
                        <Col flex={"65px"}>{drawValue((entered) ? bmDate(entered) : "")}</Col>
                    </Row>

                    <Row className="bc" style={{ "textAlign": "center", "fontSize": "12px", "borderBottom": "1px solid" }}>

                        <Col flex={"33px"}>{drawLabel("Auth")}</Col>
                        <Col className="bc" style={{ "borderRight": "1px solid" }} flex={"auto"}>{drawValue(author)}</Col>
                        <Col flex={"50px"}>{drawLabel("Pubdate")}</Col>
                        <Col className="bc" style={{ "borderRight": "1px solid" }} flex={"65px"}>{drawValue(pubdate)}</Col>
                        <Col flex={"50px"}>{drawLabel("O/S Stat")}</Col>
                        <Col flex={"65px"}>{drawValue(converted_out_of_stock_status)}</Col>
                    </Row>

                    <Row style={{ "textAlign": "center", "fontSize": "12px", "borderBottom": "none" }}>
                        <Col flex={"33px"}>{drawLabel("Supl")}</Col>
                        <Col className="bc" style={{ "borderRight": "1px solid" }} flex={"193px"}>{drawValue((<>{(supplier_1 || "")}&nbsp;&nbsp;&nbsp;{(supplier_2 || "")}&nbsp;&nbsp;&nbsp;{(supplier_3 || "")}&nbsp;&nbsp;&nbsp;</>))}</Col>
                        <Col flex={"30px"}>{drawLabel("Publ")}</Col>
                        <Col className="bc" style={{ "borderRight": "1px solid" }} flex={"auto"}>{drawValue(original_publisher)}</Col>
                        <Col flex={"25px"}>{drawLabel("Rtnbl")}</Col>
                        <Col flex={"17px"}>{drawValue(returnable)}</Col>
                        <Col flex={"52px"}>{drawLabel("Crtn Qty")}</Col>
                        <Col flex={"15px"}>{drawValue(<nobr>{(carton_quantity) ? carton_quantity : ""}</nobr>)}</Col>
                    </Row>

                </conditional.true>
            </div>




        </>
    )




}

