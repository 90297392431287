import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { apiCall } from "../../utils/Api";

export default function FilterPresetSelect(props) {

    const { width = 300, onChange = () => {}, value = "", setPresets = () => {}} = props; 
    const [results, setResults] = useState({store_specific : []});
    const [loading, setLoading] = useState(true);

    const fetchLists = () => {
        apiCall("toolbox/get", { header : "status"}, (_status, _result) => {
            if (_status) {
                setPresets(_result);
                setResults(_result);
            }
            setLoading(false);
        })
    }
    useEffect(fetchLists, []);

    const getOptions = () => {
        return results.store_specific.map(item => {
            let _itm = item;
            _itm.label = item.name;
            _itm.value = item.id;
            return _itm; 
        })
    }

    if(loading){
        return(<></>)
    }

    return (
        <>
            <Select loading={loading} showSearch style={{"width" : width + "px"}} defaultValue={(value) ? value : null} placeholder="Select filter preset..." onChange={(e) => onChange(e)} options={getOptions()} />
        </>
    )
}