import { Affix, Button, Divider, Drawer, Form, Layout, Select, Space, Spin, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import LayoutHeader from "./partials/LayoutHeader";
import { elements } from "../formelements";
import { LeftOutlined, EditOutlined } from '@ant-design/icons';

import FormElement from "../components/formbuilder/FormElement";
import { makeKey } from "../utils/Utils";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { apiCall } from "../utils/Api";
import { BMIconCheckbox, BMIconDate, BMIconDrag, BMIconEmail, BMIconPhone, BMIconRadio, BMIconSelect, BMIconText } from "../components/icons/FormIcons";
import { Container, Draggable } from "react-smooth-dnd";
import TextInputField from "../components/formbuilder/TextInputField";
import TextAreaField from "../components/formbuilder/TextAreaField";
import TextPhoneField from "../components/formbuilder/TextPhoneField";
import TextEmailField from "../components/formbuilder/TextEmailField";
import { useForm } from "antd/lib/form/Form";
import TextDateField from "../components/formbuilder/TextDateField";
import SelectBoxField from "../components/formbuilder/SelectBoxField";
import CheckBoxField from "../components/formbuilder/CheckBoxField";
import RadioField from "../components/formbuilder/RadioField";
import TextDayMonthField from "../components/formbuilder/TextDayMonth";

export default function FormBuilder() {

    const [form_obj] = useForm();
    const { form_id = "" } = useParams();
    const [canAccept, setCanAccept] = useState(false);
    const [form, setForm] = useState([]);
    const [revert, setRevert] = useState([]);
    const [selected, setSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formdata, setFormData] = useState({ title: "", description: "", override_po_id: "" });
    const history = useHistory();

    // FETCH FORM
    const getForm = () => {
        setLoading(true)
        apiCall("settings/custom_forms/get", { form_id: form_id }, (_status, _result) => {
            if (_status) {
                let _f = (_result.elements) ? _result.elements : [];
                setFormData(_result);
                setForm(_f);
                setRevert(_f);
            } else {
                message.error(_result.error)
            }
            setLoading(false)
        });
    }
    useEffect(getForm, []);

    // SAVE CHANGES
    const saveChanges = (_form = form, _override_po_id = formdata.override_po_id) => {

        let _opo = _form.find(item => item.id === _override_po_id);
        if (!_opo) {
            _opo = "";
        } else {
            _opo = _override_po_id;
        }

        setLoading(true)
        apiCall("settings/custom_forms/set", { form_id: form_id, override_po_id: _opo, elements_json: JSON.stringify(_form) }, (_status, _result) => {
            if (_status) {
                message.success("Form saved successfully");
                let _f = (_result.elements) ? _result.elements : [];
                setFormData(_result);
                setForm(_f);
                setRevert(_f);
            } else {
                message.error(_result.error)
            }
            setLoading(false)
        })
    }

    const drawForm = () => {
        return form.map(item => {
            return <FormElement form_obj={form_obj} update={update} removeItem={removeItem} selected={selected} setSelected={setSelected} data={item} />
        })
    }

    const update = (_data) => {
        let _f = [...form];
        let _ind = _f.findIndex(item => item.id === _data.id);
        if (_ind > -1) {
            _f[_ind] = _data;
            setForm(_f);
        }
    }

    const removeItem = (_item) => {
        let _f = form.filter(item => item.id !== _item.id);
        setForm(_f);
        saveChanges(_f)
        setSelected(false);
    }

    const drawSelected = () => {
        switch (selected.type) {
            case "text":
                return (<TextInputField data={selected} update={update} />)
            case "textarea":
                return (<TextAreaField data={selected} update={update} />)
            case "email":
                return (<TextEmailField data={selected} update={update} />)
            case "phone":
                return (<TextPhoneField form_obj={form_obj} data={selected} update={update} />)
            case "date":
                return (<TextDateField form_obj={form_obj} data={selected} update={update} />)
            case "daymonth":
                return (<TextDayMonthField form_obj={form_obj} data={selected} update={update} />)
            case "select":
                return (<SelectBoxField form_obj={form_obj} data={selected} update={update} />)
            case "checkbox":
                return (<CheckBoxField form_obj={form_obj} data={selected} update={update} />)
            case "radio":
                return (<RadioField form_obj={form_obj} data={selected} update={update} />)
                break;
        }
    }

    const getIcon = (_type) => {

        switch (_type) {
            case "text":
                return <BMIconText style={{ fill: "#888" }} />
            case "textarea":
                return <BMIconText style={{ fill: "#888" }} />
            case "email":
                return <BMIconEmail style={{ fill: "#888" }} />
            case "phone":
                return <BMIconPhone style={{ fill: "#888" }} />
            case "date":
                return <BMIconDate style={{ fill: "#888" }} />
            case "daymonth":
                return <BMIconDate style={{ fill: "#888" }} />
            case "select":
                return <BMIconSelect style={{ fill: "#888" }} />
            case "checkbox":
                return <BMIconCheckbox style={{ fill: "#888" }} />
            case "radio":
                return <BMIconRadio style={{ fill: "#888" }} />
        }
    }

    const drawDragElement = (item, index) => {
        const {
            label = "Text",
            description = "Single line text input",
            icon = "text",
        } = item;
        return (
            <div>
                <div className="bm-form-element">
                    <Space align="center" size={15}>
                        {getIcon(icon)}
                        <div style={{ "width": "200px" }}>
                            <div>{item.label}</div>
                            <div style={{ "opacity": "0.7", "marginTop": "-5px" }}><small>{item.description}</small></div>
                        </div>
                        <BMIconDrag style={{ fill: "#aaa" }} />
                    </Space>
                </div>
                <div className="shim" /><div className="shim" />
            </div>
        )
    }

    const getPayload = (_index) => {
        return elements[_index];
    }

    const onDrop = (e) => {
        if (!canAccept) {
            return false;
        }
        let { removedIndex, addedIndex, payload } = e
        if (payload) {
            // add
            let _f = [...form];
            _f.splice(addedIndex, 0, { ...payload.data, "id": makeKey() })
            setForm(_f);
            saveChanges(_f);


        } else {
            let tmp = [...form];
            let moved = tmp[removedIndex];
            tmp = tmp.filter((i, ind) => ind !== removedIndex);
            let _save = [...tmp.slice(0, addedIndex), moved, ...tmp.slice(addedIndex)];
            setForm(_save)
            saveChanges(_save)
        }
    }

    const drawComponents = () => {
        return elements.map((item, index) => {
            return (
                <Draggable group={"1"} key={index}>
                    {drawDragElement(item)}
                </Draggable>
            )
        })
    }

    let _style = {
        border: (form.length === 0) ? "1px dashed #4184f3" : "none",
        marginBottom: "0px",
        minHeight: "100px",
        backgroundColor: (canAccept && form.length === 0) ? "#e2f1fd" : "transparent",
        borderRadius: "5px"
    }

    const [affix, setAffix] = useState(false);

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title={<div className="float-flex"><div>Form Builder</div><Button onClick={() => history.push("/content/custom_forms")} type="primary" size="small"><small><LeftOutlined /> Back to Forms</small></Button></div>}
                    description={<div className="float-flex">
                        <div>Use this page to add additional form field to the customer checkout.</div>
                        <div>
                            <label>Override PO#</label>
                            <div className="shim" />
                            <Select onChange={(e) => {

                                saveChanges(form, e);

                            }} value={formdata.override_po_id} style={{ "width": "200px" }} size="small" options={[{ label: "Don't overide", value: "" }].concat(form.map(item => {
                                return { label: item.label, value: item.id }
                            }))} />
                        </div>

                    </div>}
                />
                <Spin spinning={loading}>
                    <Layout.Content style={{ "padding": "0px", "minHeight": "calc(100vh - 200px)" }} >
                        <Layout>
                            <Layout.Sider width={350} theme="light" style={{ "padding": "20px", "pointerEvents": (selected) ? "none" : "inherit" }}>
                                <Affix offsetTop={100}>
                                    <Spin indicator={null} spinning={(selected)}>
                                        <div>Form Elements</div>
                                        <Divider dashed style={{ "margin": "10px 0px" }} />
                                        <div className="shim" />
                                        <Container getChildPayload={(_index) => { return getPayload(_index) }} groupName="1" behaviour="copy">
                                            {drawComponents()}
                                        </Container>
                                    </Spin>
                                </Affix>
                            </Layout.Sider>
                            <Layout.Content className="fe-bg">
                                <div style={{ "position": "relative", "minHeight": "calc(100vh - 200px)", "overflow": "hidden" }}>
                                    <div style={{ "padding": "20px", "paddingBottom": "0px" }}>
                                        <Typography.Title level={5}>{formdata.header}</Typography.Title>
                                        <div style={{ "opacity": "0.6", "marginTop": "-10px" }}>{formdata.description}</div>
                                    </div>
                                    <Space>
                                        <div className={(form.length === 0) ? "drop-area fe-bg-int" : "fe-bg-int"} style={{ "margin": "20px", "padding": "10px 15px", "width": "500px", "paddingTop": "15px" }}>
                                            <Form form={form_obj} layout="vertical">
                                                <Container
                                                    onDragEnter={() => setCanAccept(true)}
                                                    onDragLeave={() => setCanAccept(false)}
                                                    onDragEnd={() => setTimeout(() => { setCanAccept(false) }, 1)}
                                                    style={_style}
                                                    dropPlaceholder={{ animationDuration: 0, showOnTop: true, className: 'drop-preview' }}
                                                    dragHandleSelector=".dragHandle" lockAxis="y"
                                                    groupName={"1"}
                                                    onDrop={onDrop}>
                                                    {drawForm()}
                                                </Container>
                                            </Form>
                                        </div>
                                    </Space>

                                    <Drawer

                                        zIndex={1}
                                        placement="right"
                                        closable={false}
                                        width={350}
                                        visible={(selected)}
                                        getContainer={false}
                                        style={{ position: 'absolute' }}
                                        title={null}
                                    >

                                        <Affix onChange={(e) => setAffix(e)} offsetTop={100} >
                                            <div style={{ "overflow": "scroll", "width": "326px", "height": (affix) ? "calc(100vh - 100px)" : "calc(100vh - 300px)" }}>
                                                <div style={{ "paddingRight": "25px" }}>
                                                    <Space>
                                                        <Button size="small" style={{ "width": "180px" }} type="primary" onClick={() => { saveChanges(); setSelected(false) }}><small>Save Changes</small></Button>
                                                        <Button size="small" onClick={() => { setForm([...revert]); setSelected(false) }}><small>Cancel</small></Button>
                                                        <Button size="small" type="primary" danger onClick={() => removeItem(selected)}><small>Delete</small></Button>
                                                    </Space>
                                                    <Divider />
                                                    {drawSelected()}
                                                </div>
                                            </div>
                                        </Affix>
                                    </Drawer>
                                </div>
                            </Layout.Content>
                        </Layout>
                    </Layout.Content>
                </Spin>
            </Layout >
        </>
    )
}