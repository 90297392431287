import { Space, InputNumber } from "antd";
import React, { useState } from "react"

export default function ETARangePicker(props) {

    const {
        estimate_delivery = 0, 
        estimate_delivery_max = 0,
        updateETA = () => {},
        obj = {}
    } = props;

    const [min, setMin] = useState(estimate_delivery);
    const [max, setMax] = useState(estimate_delivery_max);

    // Two inputs for min and max.  max is disabled until min is set - max must be > min.  both must be in 1-99 range

    // <InputNumber onChange={(e) => updateETA(e, f)} type="number" style={{ "width": "60px" }} defaultValue={(Number.isInteger(e)) ? e : 0} size="small" />
    return (
        <>
            <Space>
                <small>Between:</small>
                <InputNumber min={1} max={99} onChange={(e) => {setMin(e); updateETA(e, max, obj);}} type="number" style={{ "width": "60px" }} value={(Number.isInteger(min)) ? min : 0} size="small" />
                <small>and</small>
                <InputNumber max={99}  onChange={(e) => {setMax(e); updateETA(min, e, obj);}} disabled={(min<1)} type="number" style={{ "width": "60px", color : (max>0 && max < (min)) ? "#f00" : "inherit" }} value={(Number.isInteger(max)) ? max : 0} size="small" />
                <small>days</small>
            </Space>
        </>
    )

}