import React, { useEffect, useState } from "react";
import { Pie } from '@ant-design/charts';
import { Divider, message, Row, Col, Space } from "antd";
import { sum, addCommas } from "../../utils/Utils";
import { apiCall } from "../../utils/Api";
import moment from "moment";
export default function UsageDevices(props) {

    const { filters = { start_date: moment(), end_date: moment() } } = props;
    const [results, setResults] = useState({ labels: [], datasets: [{ data: [] }] });

    const [hover, setHover] = useState({ key: "", value: 0, opacity: 0 })
    const fetchDevices = () => {
        let obj = {
            table: "devices",
            chart_type: "pie",
            include_keys: JSON.stringify(["linux", "mac", "windows", "android", "ios", "ipad"]),
            start_ts : filters.start_date.unix(), 
            end_ts : filters.end_date.unix()
        }

        apiCall("site_analytics/get", obj, (_status, _result) => {
            if (_status) {
                setResults(_result);
            } else {
                message.error(_result.error)
            }
        })
    }

    useEffect(fetchDevices, [filters]);

    const parseData = () => {
        let arr = [];
        
        results.labels.forEach((_label, _index) => {

            // let f = arr.find(item => item.label === _label)

            // if(f){
            //    f.value += parseInt(results.datasets[0].data[_index]);
            // } else {
            //     arr.push({
            //         label: _label,
            //         value: parseInt(results.datasets[0].data[_index])
            //     })
            // }

            arr.push({
                label: _label,
                value: parseInt(results.datasets[0].data[_index])
            })
            
        });
        return arr;
    }

    const getColor = (_t) => {
        let ret = "#6895c7";
        switch (_t.label) {
            case "iOS": ret = "#6895c7"; break;
            case "Android": ret = "#6082a1"; break;
            case "Windows": ret = "#b0cc98"; break;
            case "Mac": ret = "#78b283"; break;
            case "Linux": ret = "#5b8363"; break;
            case "iPad": ret = "#8cbbc4"; break;
            case "Unknown": ret = "#64a6da"; break;
        }
        return ret;
    }


    const config = {
        appendPadding: 10,
        data: parseData(),
        angleField: 'value',
        colorField: 'label',
        color: (t) => getColor(t),
        radius: 1,
        height: 245,
        innerRadius: 0.7,
        legend: false,
        label: {
            type: 'inner',
            offset: '-50%',
            content: "",
        },
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
                customHtml: (_container, _view, _datum, _data) => {
                    return (
                        <>
                            <div style={{ "opacity": hover.opacity }}>
                                <div  className="c"><small>{addCommas(hover.value)}</small></div>
                                <div className="c2" style={{ "fontWeight": "normal", "fontSize": "18px", "opacity": 0.7 }}><small>{hover.key}</small></div>
                            </div>
                        </>
                    )
                }
            },
        },
    };



    const updateHover = (_label) => {

        if (!_label) {
            setHover({ ...hover, opacity: 0 });
            return;
        }

        let _data = parseData();
        let _find = _data.find(item => item.label === _label);
        if (_find) {
            setHover({ key: _label, value: _find.value, opacity: 1 });
        } else {
            setHover({ ...hover, opacity: 0 });
        }

    }


    return (
        <>
            <strong className="c" style={{ "fontSize": "13px" }}>Devices</strong>
            <div style={{ "fontSize": "12px" }}><small>Distribution of website visits by device / operating system.</small></div>
            <Divider dashed style={{ "margin": "10px 0px" }} />
            <Row gutter={15}>
                <Col flex={"220px"}><Pie animation={null} {...config} /></Col>
                <Col flex={"80px"}>
                    <br /><br />
                    <Space size={0} direction="vertical">
                        <div style={{ "cursor": "pointer" }} onMouseLeave={() => updateHover(false)} onMouseEnter={() => updateHover("iOS")}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#6895c7" }}></div>
                                <div style={{ "opacity": "0.7" }}><small>iOS</small></div>
                            </Space>
                        </div>
                        <div style={{ "cursor": "pointer" }} onMouseLeave={() => updateHover(false)} onMouseEnter={() => updateHover("iPad")}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#8cbbc4" }}></div>
                                <div style={{ "opacity": "0.7" }}><small>iPad</small></div>
                            </Space>
                        </div>
                        <div style={{ "cursor": "pointer" }} onMouseLeave={() => updateHover(false)} onMouseEnter={() => updateHover("Linux")}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#5b8363" }}></div>
                                <div style={{ "opacity": "0.7" }}><small>Linux</small></div>
                            </Space>
                        </div>
                        <div style={{ "cursor": "pointer" }} onMouseLeave={() => updateHover(false)} onMouseEnter={() => updateHover("Mac")}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#78b283" }}></div>
                                <div style={{ "opacity": "0.7" }}><small>Mac</small></div>
                            </Space>
                        </div>
                        <div style={{ "cursor": "pointer" }} onMouseLeave={() => updateHover(false)} onMouseEnter={() => updateHover("Windows")}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#b0cc98" }}></div>
                                <div style={{ "opacity": "0.7" }}><small>Windows</small></div>
                            </Space>
                        </div>
                        <div style={{ "cursor": "pointer" }} onMouseLeave={() => updateHover(false)} onMouseEnter={() => updateHover("Android")}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#6082a1" }}></div>
                                <div style={{ "opacity": "0.7" }}><small>Android</small></div>
                            </Space>
                        </div>
                    </Space>
                </Col>
            </Row>

        </>
    )



}