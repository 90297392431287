import React, { useState } from "react";
import { Map, TileLayer, Marker } from 'react-leaflet';
import { Icon } from 'leaflet'
import marker from "../../media/marker-icon.png"
import { Button, Form, Input, Modal, Space, Typography } from "antd";
import { AimOutlined } from '@ant-design/icons';
export default function MapLocationPicker(props) {

    const [open, setOpen] = useState(false);
    const[zoom, setZoom] = useState(14)
    const {
        changed = false, 
        setChanged = () => {},
        label = "",
        form,
        name = "",
        value = {
            lat: 49.88609,
            lng: -119.4941869
        }
    } = props;

    const [position, setPosition] = useState([value.lat, value.lng]); // Initial position

    const icon = new Icon({
        iconUrl: marker,
        iconSize: [60, 60], // size of the icon
        iconAnchor: [30, 45], // point of the icon which will correspond to marker's location
        popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
    })

    const handleMarkerDrag = (e) => {
        let latlng = e.target.getLatLng();
        setPosition([latlng.lat, latlng.lng]); // Update marker position
    };

    const close = () => {
        setOpen(false);
        setPosition([value.lat, value.lng]);
    }

    const save = () =>{
       
        form.setFieldsValue({ [name]: {
            lat: position[0],
            lng: position[1]
        }});
        if(!changed){
            setChanged(true);
        }
        setOpen(false);
    }


    return (<>
        <Modal footer={null} onCancel={() => close()} visible={open}>
            <>
                <Typography.Title level={5}>Adjust Location</Typography.Title>
                <Typography.Paragraph>Double click to zoom-in, Shift + Double click to zoom-out.</Typography.Paragraph>
                <div style={{ "height": "400px", "marginBottom": "-20px", "position": "relative", "overflow": "hidden" }}>
                    <Map whenReady={(map) => {
                        
                        map.target.on('moveend', () => {
                           setZoom(map.target.getZoom());
                          });

                    }}scrollWheelZoom={false} style={{ "height": "380px", "overflow": "hidden" }} center={{ lat: position[0], lng: position[1] }} zoom={zoom}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
                        <Marker icon={icon} draggable={true} onDragEnd={handleMarkerDrag} position={[position[0], position[1]]} />
                    </Map>
                </div>
                <div className="shim" /><div className="shim" /><div className="shim" />
                <div className="float-flex">
                    <div>
                        <Space>
                            <div style={{"opacity" : "0.6"}}><small>Lat: {position[0]}</small></div>
                            <div style={{"opacity" : "0.6"}}><small>Lng: {position[1]}</small></div>
                        </Space>
                    </div>
                    <div>
                        <Space>
                            <Button onClick={() => close()}>Cancel</Button>
                            <Button type="primary" onClick={() => save()}>Save</Button>
                        </Space>
                    </div>
                </div>
            </>
        </Modal>

        <label>{label}</label>
        <div className="shim" />
        <Space>
            <Form.Item label="Lat" initialValue={value.lat} name={[name, "lat"]} noStyle>
                <Input disabled />
            </Form.Item>

            <Form.Item label="Lng" initialValue={value.lng} name={[name, "lng"]} noStyle>
                <Input disabled />
            </Form.Item>
            <Button onClick={() => setOpen(true)} type="primary"><AimOutlined /> Adjust </Button>
        </Space>
        <div className="shim" />

    </>)
}

