import { Space } from "antd";
import React from "react";

export default function TitleOhOo(props) {

    // destructure data and available array
    const { data = {} } = props;
    const { available = [] } = data;

    // style boxes
    let _style = {
        backgroundColor: "#ccf2cc",
        padding: "0px 5px",
        width: "30px",
        textAlign: "right",
        height: "22px",
        color: "#000"
    }

    // draw onhand and onorder (hide if zero)
    const drawOhOo = (_available = {}) => {
        // destructure onhand and onorder (may not exist)
        const { onhand = 0, onorder = 0 } = _available;
        return (
            <Space size={0}>
                <div style={{ ..._style }}><small>{(onhand) ? onhand : ""}</small></div>
                <div style={{ ..._style, "backgroundColor": "#ffe8bf" }}><small>{(onorder) ? onorder : ""}</small></div>
            </Space>
        )
    }

    return (
        <Space direction="vertical" size={0}>
            {available.map(item => {
                return drawOhOo(item)
            })}
        </Space>
    )
}