import React, { useState, useEffect, useRef } from "react";
import { Space, Button } from "antd";
import conditional from "../tools/conditional";
import { CloseOutlined } from '@ant-design/icons';
import { makeKey } from "../utils/Utils";


export default function Renderer(props) {


    const ref = useRef();
    const [startY, setStartY] = useState(0);
    const { key, close = false } = props;
    const [showClose, setShowClose] = useState(close);
    const [component, setComponent] = useState();

    const [hash, setHash] = useState(makeKey());


    const getLoadPosition = () => {
        setComponent(null);
        setStartY(window.scrollY);
    }

    useEffect(getLoadPosition, []);

    const drawComponent = (_component, _hide_close = false, _scroll = false) => {


        if (_hide_close) {
            setShowClose(false);
        }

        if (JSON.stringify(component) === JSON.stringify(_component)) {
            setComponent(null);


        } else {


            setComponent(_component);

            // if(_scroll){
            //     window.scrollTo({
            //         top: document.getElementById(hash).offsetTop + 212,
            //         left: 0,
            //         behavior: 'smooth'
            //     });
            // }   
        }
    }

    const drawClose = () => {

        if (!showClose) {
            return (<></>);
        }

        return (
            <conditional.true value={(component)}>
                <div className="closebar"><Button style={{ "float": "right" }} onClick={() => setComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} /><br clear="both" /></div>
            </conditional.true>
        )
    }

    const drawBreak = () => {
        return (
            <conditional.true value={(component)}>
                <div className="endbreak" />
            </conditional.true>
        )
    }

    const style = {
        "marginLeft": "-44px", "paddingLeft": "20px", "borderLeft": "24px solid rgb(23, 125, 220, 0.3)"
    }
    const nostyle = {
    }


    const scrollIntoViewWithOffset = (selector, offset) => {
        window.scrollTo({
          behavior: 'smooth',
          top:
          selector.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        })
      }

 

    const barClose = (e) => {
        // stop prop
        e.stopPropagation();
        e.preventDefault();
        // console.log(startY);
        // window.scrollTo(0, startY);
        setComponent(null);

        scrollIntoViewWithOffset(ref.current, 280)


    }

    return (
        <div ref={ref} key={key}>


            {React.cloneElement(props.children, { drawComponent: drawComponent })}
            {drawBreak()}
            {drawClose()}
            <div onClick={(e) => {
                if (e.nativeEvent.offsetX < 0) {
                    barClose(e)
                }
            }} style={(component) ? style : nostyle}>

                <div id={hash}>
                    {((component) && <div style={{ "position": "absolute", "marginLeft": "-44px" }}>
                        <Button onClick={(e) => barClose(e)} size="small" type="link" icon={<small><CloseOutlined className="anticon-close2" style={{ "fontSize": "10px" }} /></small>} />
                        <div className="shim invert" style={{ "height": "1px", "opacity": "0.1" }} />
                    </div>
                    )}
                    {component}
                </div>
                {(component && <div id={hash} onClick={(e) => barClose(e)} className="shim" style={{ "backgroundColor": "rgb(23, 125, 220, 0.3)", "height": "24px", "margin": "0px -20px" }}>
                    <div style={{ "marginLeft": "-24px" }}>
                        <div className="shim invert" style={{ "height": "1px", "opacity": "0.1" }} />
                        <Button onClick={(e) => barClose(e)} size="small" type="text" icon={<small><CloseOutlined className="anticon-close2" style={{ "fontSize": "10px", "fontWeight" : "bold" }} /></small>} />
                    </div>

                </div>)}
            </div>




        </div>
    );
}