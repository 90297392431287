import { Form, Input, Space, Tabs, Tooltip, Typography } from "antd";
import React, { useState } from "react";

export default function SuggestedCartsOnBrowse(props){
    const {
        data = {},
        contextValues = [],
    } = props;
    const [active, setActive] = useState("total");
    const stores = window.sitesettings.stores

    const toggleActive = (e) => {
        if(active === e){
            setActive("total")
        } else {
            setActive(e);
        }
    }

    function drawTotalQuantity(){
        const quantity = data.suggested_cart_info.quantity;
        return(
            <>
                <div className="bc" style={{ "width": "110px" }}>
                    <Tooltip title="Total quantity to be split among all your locations">
                        <div 
                            className="bc" 
                            style={{ 
                                "border": "1px solid",
                                "fontWeight": "800", 
                                "padding": "2px 8px", 
                                "fontSize": "12px" }}
                            ><strong className="c">Total Quantity:</strong>
                        </div>
                    </Tooltip>
                    <div className="bc bcg2" style={{  "border": "1px solid", "fontWeight": "500", "padding": "5px 8px" }}>
                        <Input defaultValue={quantity} style={{ "fontSize": "10px", "height": "20px" }} disabled={true} />
                    </div>
                </div>
            </>
        )
    }

    function drawMultiStoreSplitQuantities(){
        const ms_quantity = data.suggested_cart_info.ms_quantity;
        return (
            <>
                <div className="bc">
                    <Space size={0}>
                        {stores.map((item, index) => {
                            return (
                                <div key={index} style={{ "width": "54px" }}>
                                    <Tooltip title={item.name + " (" + item.altname + ")"}>
                                        <div 
                                            className="bc" 
                                            style={{ 
                                                "borderLeft": "1px solid", 
                                                "borderBottom" : "1px solid", 
                                                "borderTop": "1px solid", 
                                                "borderRight": ((index + 1) === stores.length && stores.length === 10) ? "none" : "1px solid", 
                                                "fontWeight": "800", 
                                                "padding": "2px 8px", 
                                                "fontSize": "12px" }}
                                            ><strong className="c">#{(item.store_number).toString()}</strong>
                                        </div>
                                    </Tooltip>
                                    <div className="bc bcg2" style={{  "border": "1px solid", "fontWeight": "500", "padding": "5px 8px" }}>
                                        <Input defaultValue={ms_quantity[index]} style={{ "fontSize": "10px", "height": "20px" }} disabled={true} />
                                    </div>
                                </div>
                            )
                        })}
                    </Space>
                </div>
            </>
        );
    }

    function drawNotesTab(){
        const notes = data.suggested_cart_info.note !== null ? data.suggested_cart_info.note : "No note provided";
        return (
            <div className="bc">
                <Tooltip title="Notes written in by the Rep to give you greater insight into their suggestions.">
                    <div className="bc bcg2" style={{  "border": "1px solid", "fontWeight": "500", "padding": "5px 8px" }}>
                        <Input.TextArea defaultValue={notes} style={{ "fontSize": "10px", "height": "20px" }} disabled={true} />
                    </div>
                </Tooltip>
            </div>
        );
    }

    function singleStoreDisplay(){
        let title_text = "Suggestions from " + contextValues.rep_name + " (" + contextValues.rep_acct + "): ";
        return (
            <div>
                <Typography.Title level={5}>{title_text}</Typography.Title>
                <Tabs className="extraTabs itemTabs" onChange={(e) => toggleActive(e)} tabBarStyle={{ "padding": "0px", "marginBottom": "0px", "borderBottom": "0px" }} size="small" type="card" activeKey={active}>
                    <Tabs.TabPane tab={<small>Total</small>} key="total">
                        <div className="bc" style={{transitionProperty: "border", "transitionDuration": "0.2s",  "borderLeft": "5px solid" }}>
                            {drawTotalQuantity()}
                        </div>
                        <div className="shim"/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<small>Notes</small>} key="notes">
                        <div className="bc" style={{transitionProperty: "border", "transitionDuration": "0.2s",  "borderLeft": "5px solid" }}>
                            {drawNotesTab()}
                        </div>
                        <div className="shim"/>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }

    function multiStoreDisplay(){
        let title_text = "Suggestions from " + contextValues.rep_name + " (" + contextValues.rep_acct + "): ";
        return (
            <div>
                <Typography.Title level={5}>{title_text}</Typography.Title>
                <Tabs className="extraTabs itemTabs" onChange={(e) => toggleActive(e)} tabBarStyle={{ "padding": "0px", "marginBottom": "0px", "borderBottom": "0px" }} size="small" type="card" activeKey={active}>
                    <Tabs.TabPane tab={<small>Total</small>} key="total">
                        <div className="bc" style={{transitionProperty: "border", "transitionDuration": "0.2s",  "borderLeft": "5px solid" }}>
                            {drawTotalQuantity()}
                        </div>
                        <div className="shim"/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<small>Split by Store</small>} key="multistore">
                        <div className="bc" style={{stransitionProperty: "border", "transitionDuration": "0.2s",  "borderLeft": "5px solid" }}>
                            {drawMultiStoreSplitQuantities()}
                        </div>
                        <div className="shim" />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<small>Notes</small>} key="notes">
                        <div className="bc" style={{transitionProperty: "border", "transitionDuration": "0.2s",  "borderLeft": "5px solid" }}>
                            {drawNotesTab()}
                        </div>
                        <div className="shim"/>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }
    

    if(stores.length > 1){
        return multiStoreDisplay();
    }
    return singleStoreDisplay();
}