import { CheckSquareFilled, CloseOutlined, CloseSquareFilled, StarFilled } from '@ant-design/icons';
import { Divider, Space, Tooltip } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

import { bestsellerColor, objectToPath, successColor } from "../utils/Utils";

export default function MagicFilters(props) {
    const history = useHistory();
    const { path = "", filter, getBrowse = () => { }, searchFilters = [], setSearchFilters = () => { } } = props;
    const { magic_filters } = filter;


    const deselectFilter = (_key, _filters, _ex = false) => {
        let _char = filter.routing_char;
        if (_ex) {
            _filters = _filters.map(item => {
                return "-" + item;
            })
        }
        if (searchFilters.hasOwnProperty(_char)) {
            let ret = [];
            searchFilters[_char].forEach(element => {
                if (!_filters.includes(element)) {
                    ret.push(element);
                }
            });
            let _update = { ...searchFilters, [_char]: ret };
            setSearchFilters(_update);
            getBrowse(false, _update);
            history.push({ pathname: path + objectToPath(_update) })
        }
    }

    const selectFilter = (_key, _filters, _ex = false) => {
        let _char = filter.routing_char;
        let _current = [];
        if (searchFilters.hasOwnProperty(_char)) {
            _current = [...searchFilters[_char]];
            console.log(searchFilters[_char])
        }
        // remove any existing inc or exc
        _current = _current.filter(item => !_filters.includes(item))
        _current = _current.filter(item => !_filters.includes("-" + item))
        if (_ex) {
            _filters = _filters.map(item => {
                return "-" + item;
            })
        }
        _current = _current.concat(_filters);
        // console.log(_current)
        let _update = { ...searchFilters, [_char]: _current }
        setSearchFilters(_update)
        getBrowse(false, _update);
        history.push({ pathname: path + objectToPath(_update) })

    }

    const drawFilter = (_item) => {
        const { filters = [], key = "", name = "", tooltip = "", exclude = false } = _item;
        let count = 12;
        let index = 1;
        let _char = filter.routing_char;
        let _set = true;
        if (searchFilters.hasOwnProperty(_char)) {
            filters.forEach(element => {
                if (!exclude) {
                    if (!searchFilters[_char].includes(element)) {
                        _set = false;
                    }
                }
                if (exclude) {
                    if (!searchFilters[_char].includes("-" + element)) {
                        _set = false;
                    }
                }
            });
        } else {
            _set = false;
        }
        return (
            <>
                <div key={index}>
                    <Tooltip mouseEnterDelay={0.5} title={<small>{tooltip}<span style={{ "color": "#999", "fontSize": "9px" }}></span></small>}>
                        <div onClick={() => {
                            if (_set) {
                                deselectFilter(key, filters, exclude);
                            } else {
                                selectFilter(key, filters, exclude)
                            }
                        }
                        } style={{ "overflow": "hidden", "textOverflow": "ellipsis", "maxWidth": "130px", "whiteSpace": "nowrap", "cursor": "pointer" }}>
                            <small><div style={{ "color": "#999", "fontSize": "8px", "width": "25px", "display": "inline-block" }}>&nbsp;&nbsp;<StarFilled /></div> <span className="c">{(_set) ? <strong>{name}</strong> : name}</span> </small>
                        </div>
                    </Tooltip>
                </div >
            </>
        )
    }

    if (!magic_filters.length) {
        return (<></>)
    }

    const checkForMultistore = () => {
        if(!filter.rows[0]){
            return false;
        }

        if(filter.rows[0].key === "--"){
            return true;
        }
        return false;
    }

    return (
        <div style={{  "marginLeft": "5px", "marginTop": "-5px" }}>
            {magic_filters.map(item => {
                return drawFilter(item);
            })}
            <div className="shim" />
            <div key={"label"} style={{ "marginRight": "15px" }}>
                {(!checkForMultistore() && <Divider style={{ "margin": "0px", "pointerEvents": "none", "marginTop": "0px" }} plain />)}
            </div>
            <div className="shim" />
        </div>
    )
}