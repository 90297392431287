import React from "react";
import { Layout, Typography, Divider, Button } from "antd";
import LayoutHeader from "../../layouts/partials/LayoutHeader";

export default function SiteContentPage() {

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    todo
                    title="Site Content"
                    description="Use this page to manage site content."
                />
                <Layout.Content style={{"padding": "0px" }} >
                    <div style={{ "height": "calc(100vh - 250px)" }}>

                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}