import React, { useEffect, useState } from "react";
import { Form, Checkbox, Typography, Divider, Input, Button, Radio, Select, Row, Col, Space } from "antd";
import WebstoreItem from "../WebstoreItem";
import { apiCall } from "../../../utils/Api";
import MapLocationPicker from "../MapLocationPicker";
export default function GeneralSection(props) {

    const {changed = false, setChanged = () => {}} = props; 
    const [pages, setPages] = useState([]);
    const primary = (window.sitesettings.stores[0]);
    const stores = (primary.is_primary) ? window.sitesettings.stores : [window.sitesettings.stores.find(item => item.is_primary)];
    const getPages = () => {
        apiCall("settings/getCustomPages", {}, (_status, _results) => {
            if (_status) {
                setPages(_results);
            }
        })
    }

    useEffect(getPages, []);


    const drawPages = () => {
        return pages.map((item, index) => {
            return (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
        })
    }

    const { ws, form } = props;


    const default_ws_login_required_message = "Ordering from our site is available only to preauthorized customers.  Please contact us by phone at @@phone@@ or email at @@email@@ to have us create or authorize your account.";

    return (
        <>

            <div id="general"></div>
            <div style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>General</Typography.Title>
                <Divider />

                {/* Private webstore */}
                <div id="ws_self_login_required"></div>
                <WebstoreItem
                    name="global:ws_self_login_required"
                    value={(ws["global:ws_self_login_required"]) ? true : false}
                    heading="Private webstore"
                    description={<div>When this box is checked, access to this site is only allowed by a site administrator or someone with the appropriate permissions. Some stores may set their site to Private when they are first customizing and configuring their Webstore until they are comfortable and ready to make it visible.</div>}
                />

                <Divider dashed />
                {/* Treat non-approved customers as anonymous users */}
                <div id="ws_use_whitelist"></div>
                <WebstoreItem
                    name="global:ws_use_whitelist"
                    value={(ws["global:ws_use_whitelist"]) ? true : false}
                    heading="Treat non-approved customers as anonymous users"
                    description={<div>When this box is checked, customers whom you have not approved on the customer accounts page will be given the same level of access to this webtore as those who have not created an account or logged in. This will have no effect unless other options are selected that affect anonymous users, such as hiding the cart or prices.</div>}
                />

                <Divider dashed />
                {/* Visible only when customer is logged in */}
                <div id="ws_login_required"></div>
                <WebstoreItem
                    name="global:ws_login_required"
                    value={(ws["global:ws_login_required"]) ? true : false}
                    heading="Visible only when customer is logged in"
                    description={<div>When this box is checked, access to this site is only granted to customers with accounts registered with Bookmanager. A "send invitation" option will appear in the webstore's menu bar to those with proper permissions. </div>}
                />

                <Divider dashed />
                {/* Visible only when customer is logged in Message */}
                <div id="ws_login_required_message"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Login Prompt</Typography.Title>
                        <Typography.Paragraph type="secondary">This message will show up when the user is prompted to log in. You can customize it; your store’s email address and phone number will be automatically filled in for the @@phone@@ and @@email@@ placeholders. Leave it empty to use the default message.</Typography.Paragraph>
                        <Form.Item initialValue={(ws["global:ws_login_required_message"]) ? ws["global:ws_login_required_message"] : default_ws_login_required_message} name="global:ws_login_required_message" >
                            <Input.TextArea showCount maxLength={300} style={{ "maxWidth": "500px" }} rows={4} >
                            </Input.TextArea>
                        </Form.Item>
                        <div className="shim"></div> <div className="shim"></div>
                        <Button size="small" onClick={() => form.setFieldsValue({ ws_login_required_message: default_ws_login_required_message })} type="primary"><small>Use default</small></Button>
                    </div>
                </Space>

                <Divider dashed />
                {/* Allow customers to view their lists at any Bookmanager site */}
                <div id="ws_allow_list_transfer"></div>
                <WebstoreItem
                    name="global:ws_allow_list_transfer"
                    value={(ws["global:ws_allow_list_transfer"]) ? true : false}
                    heading="Allow customers to view their lists at any Bookmanager site"
                    description={<div>When this box is unchecked, customers creating a list at your site will not see their list at another Bookmanager webstore, and likewise, lists they create at other stores will not be visible from your site. Tip: This feature is popular with librarians but booksellers might prefer to discourage shopping at other stores.</div>}
                />

                <Divider dashed />
                {/* Enable automatic browse redirection*/}
                <div id="ws_auto_browse_destination"></div>
                <WebstoreItem
                    name="global:ws_auto_browse_destination"
                    value={(ws["global:ws_auto_browse_destination"]) ? true : false}
                    heading="Enable automatic browse redirection"
                    description={<div>When this box is checked, you (or other staff/users with the Store login) will be forwarded to the TitleBrowser after logging in.</div>}
                />

                <Divider dashed />
                <div id="ws_homeurl"></div>
                {/* Alternate home page URL */}
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Alternate home page URL</Typography.Title>
                        <Typography.Paragraph type="secondary">If you want visitors to be taken to another site when they click Home, please enter in the address in the box. Otherwise, they will simply return to the main page of your Webstore.</Typography.Paragraph>
                        <Form.Item name="global:ws_homeurl" rules={[{ type: "url", message: "Must be a valid url." }]} initialValue={ws["global:ws_homeurl"]} >
                            <Input style={{ "maxWidth": "500px" }} placeholder="https://example.com/" />
                        </Form.Item>
                    </div>
                </Space>

                <Divider dashed />
                <div id="ws_landing"></div>
                {/* Landing page URL*/}
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Landing page URL</Typography.Title>
                        <Typography.Paragraph type="secondary">If you want visitors to be taken to another page when they initially navigate to your front page, enter the address in the box. Otherwise they will simply land on the front page of your Webstore.</Typography.Paragraph>
                        <Form.Item name="global:ws_landing" rules={[{ type: "url", message: "Must be a valid url." }]} initialValue={ws["global:ws_landing"]} >
                            <Input style={{ "maxWidth": "500px" }} placeholder="https://example.com/" />
                        </Form.Item>
                        <div className="shim"></div><div className="shim"></div>
                        <Form.Item valuePropName='checked' initialValue={(ws["global:ws_landing_logged_out"]) ? true : false} name="global:ws_landing_logged_out" noStyle>
                            <Checkbox>Only redirect users who are <strong>not</strong> logged in</Checkbox>
                        </Form.Item>
                    </div>
                </Space>

                <Divider dashed />
                {(window.sitesettings.is_v3 && <>
                    {/* Terms & Conditions Toggle */}
                    <div id="ws_terms_consent_enabled"></div>
                    <WebstoreItem
                        name="global:ws_terms_consent_enabled"
                        value={(ws["global:ws_terms_consent_enabled"]) ? true : false}
                        heading="Enable Terms & Conditions"
                        description={<div>Enable this to add a mandatory "I agree to the Terms & Conditions" checkbox during new account creation, editing existing account information, account based checkout, quote checkout, and guest checkout. Customers must check that option in order to proceed with creating/editing an account or checking out. "Terms & Conditions" will be a link to the Terms & Conditions Menu. <br /><br />Refer to your local customer data collection and use disclosure laws to ensure your Terms & Conditions page meets those requirements. Date-stamped customer consent records will be saved and available for audit should you require it.</div>}
                    />
                    <Divider dashed />
                </>)}

                {(!window.sitesettings.is_v3 && <>
                    <div id="ws_policy_page"></div>
                    {/* Terms & Conditions Page */}
                    <Space>
                        <div style={{ "width": "35px" }}></div>
                        <div>
                            <Typography.Title level={5}>Privacy Policy Page</Typography.Title>
                            <Typography.Paragraph type="secondary">If you have a Privacy Policy page, you can link to it in your page footer here.</Typography.Paragraph>
                            <Form.Item name="global:ws_policy_page" initialValue={ws["global:ws_policy_page"]}>
                                <Select dropdownMatchSelectWidth={false} >
                                    <Select.Option key="" value="">- Use standard page -</Select.Option>
                                    {drawPages()}
                                </Select>
                            </Form.Item>
                        </div>
                    </Space>
                    <Divider dashed />
                </>)}

                {(!window.sitesettings.is_v3 && <>

                    <div id="ws_custom_contact_page"></div>
                    {/* Contact Us Page */}
                    <Space>
                        <div style={{ "width": "35px" }}></div>
                        <div>
                            <Typography.Title level={5}>Contact Us Page</Typography.Title>
                            <Typography.Paragraph type="secondary">Choose whether to use the standard Contact Us page, or one of your custom pages.</Typography.Paragraph>
                            <Form.Item name="global:ws_custom_contact_page" initialValue={ws["global:ws_custom_contact_page"]}>
                                <Select dropdownMatchSelectWidth={false}>
                                    <Select.Option key="" value="">- Use standard page -</Select.Option>
                                    {drawPages()}
                                </Select>
                            </Form.Item>
                        </div>
                    </Space>
                    <Divider dashed />
                </>)}


                <div id="ws_hide_footer_address"></div>
                {/* Street address In footer: ws_hide_footer_address */}
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Street address In footer:</Typography.Title>
                        <Form.Item initialValue={(ws["global:ws_hide_footer_address"]) ? true : false} noStyle name="global:ws_hide_footer_address">
                            <Radio.Group>
                                <Radio value={false}>Show</Radio>
                                <div className="shim"></div>
                                <Radio value={true}>Hide</Radio>
                                <div className="shim"></div>
                            </Radio.Group>
                        </Form.Item>
                        <Typography.Paragraph type="secondary">You may wish to hide the street address if you have multiple store locations.</Typography.Paragraph>
                    </div>
                </Space>

                <Divider dashed />
                <div id="ws_facebookurl"></div>
                {/* Social media */}
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        {/* Social media */}
                        <Typography.Title level={5}>Social media</Typography.Title>
                        <Typography.Paragraph type="secondary">If you have any social media pages for your store you may enter the relative information in the boxes and an icon/link will appear down below in the footer of your Webstore.</Typography.Paragraph>
                        {/* <Form layout="vertical"> */}
                        <label>Facebook URL</label>
                        <Form.Item rules={[{ type: "url", message: "Must be a valid url." }]} name="global:ws_facebookurl" initialValue={ws["global:ws_facebookurl"]} label="">
                            <Input style={{ "maxWidth": "500px" }} placeholder="https://facebook.com/" />
                        </Form.Item>
                        <label>X (Twitter) Id</label>
                        <Form.Item help={<small className="c">Max 20 characters</small>} name="global:ws_twitter_username" initialValue={ws["global:ws_twitter_username"]} label="">
                            <Input maxLength={20} style={{ "maxWidth": "500px" }} placeholder="Twitter id" />
                        </Form.Item>
                        <label>Instagram Id</label>
                        <Form.Item help={<small className="c">Max 30 characters</small>} name="global:ws_instagram_username" initialValue={ws["global:ws_instagram_username"]} label="">
                            <Input maxLength={30} style={{ "maxWidth": "500px" }} placeholder="Instagram id" />
                        </Form.Item>
                        <label>Tiktok URL</label>
                        <Form.Item rules={[{ type: "url", message: "Must be a valid url." }]} name="global:ws_tiktok_url" initialValue={ws["global:ws_tiktok_url"]} label="">
                            <Input style={{ "maxWidth": "500px" }} placeholder="https://tiktok.com/" />
                        </Form.Item>
                        {/* </Form> */}
                    </div>
                </Space>

                <div id="ws_social_media_facebook_shareable"></div>
                <Divider dashed />
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Event sharing control</Typography.Title>
                        <Typography.Paragraph type="secondary">Determine on behalf of your customers which social media platforms are authorized for sharing events. </Typography.Paragraph>
                        <Space direction="vertical" >
                            <Form.Item valuePropName='checked' initialValue={(ws["global:ws_social_media_facebook_shareable"]) ? true : false} name="global:ws_social_media_facebook_shareable" noStyle>
                                <Checkbox>Facebook</Checkbox>
                            </Form.Item>
                            <Form.Item valuePropName='checked' initialValue={(ws["global:ws_social_media_twitter_shareable"]) ? true : false} name="global:ws_social_media_twitter_shareable" noStyle>
                                <Checkbox>X (Twitter)</Checkbox>
                            </Form.Item>
                        </Space>
                    </div>
                </Space>

                <Divider dashed />
                <div id="ws_libro_bookstore_name"></div>
                {/* Libro.fm Audiobook Integration */}
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Libro.fm Audiobook Integration</Typography.Title>
                        <Typography.Paragraph type="secondary">If you have registered as an audiobook retailer with Libro.fm, you can allow customers browsing your webstore the option to purchase Libro.fm audiobooks when they are available for a title. Audiobook purchases will be completed on the Libro.fm website, but your store will earn commision based on those sales.</Typography.Paragraph>
                        <Typography.Paragraph type="secondary">Enter your store's Libro.fm Storefront URL name in the field below to enable the integration.</Typography.Paragraph>
                        <Typography.Paragraph type="secondary"><strong>NOTE:</strong> Do not type your bookstore's full name in this field. Your Libro.fm <strong>Storefront URL</strong> name is what is required. For example, Mosaic Books' Libro.fm Storefront URL is https://libro.fm/mosaicbooks, so the Storefront URL name is the last bit, <strong>mosaicbooks</strong> (all one word). This URL can be found by logging into your Libro.fm account, clicking the Gear icon near the Bookseller Hub menu item at the top, then by clicking the Store Info link from the left menu.</Typography.Paragraph>
                        <Form.Item name="global:ws_libro_bookstore_name" initialValue={ws["global:ws_libro_bookstore_name"]} noStyle>
                            <Input maxLength={30} style={{ "maxWidth": "500px" }} placeholder="Libro.fm store name" />
                        </Form.Item>
                    </div>
                </Space>

                <Divider dashed />
                {/* Treat non-approved customers as anonymous users */}
                <div id="ws_display_carton_quantity"></div>
                <WebstoreItem
                    name="global:ws_display_carton_quantity"
                    value={(ws["global:ws_display_carton_quantity"]) ? true : false}
                    heading="Display carton quantity below Add to Cart"
                    description={<div>By default, if a title has a carton quantity from the publisher, that will be displayed under the title's Details tab. Enable this option if you would like to feature the carton quantity more predominantly, within the Add to Cart area.</div>}
                />

                <Divider dashed />
                {/* Show libro FM link on details screen. */}
                <div id="ws_prominent_librofm"></div>
                <WebstoreItem
                    name="global:ws_prominent_librofm"
                    value={(ws["global:ws_prominent_librofm"]) ? true : false}
                    heading="Show libro FM link on details screen."
                    description={<div>Enabling will show the libro FM link on the details screen for titles. Otherwise the libro FM link will be displayed with the title's bindings. </div>}
                />

                {/* <Divider dashed /> */}
                {/* Open titles in new Bookmanager */}
                {/* <div id="bm_use_biz_url"></div>
                <WebstoreItem
                    name="global:bm_use_biz_url"
                    value={(ws["global:bm_use_biz_url"]) ? true : false}
                    heading="View on Webstore sends to Biz"
                    description={<div>Enabling will display titles on the new Biz website when pressing <code>W</code> in Bookmanager. Disabled, pressing <code>W</code> will load the old admin website.</div>}
                /> */}

                <Divider dashed />
                <div style={{ "opacity": (window.sitesettings.is_canadian) ? "1" : "0.2", "pointerEvents": (window.sitesettings.is_canadian) ? "inherit" : "none" }}>
                    {/* Display Government of Canada funding acknowledgement */}
                    <div id="ws_government_of_canada_logo_enabled"></div>
                    <WebstoreItem
                        name="global:ws_government_of_canada_logo_enabled"
                        value={(ws["global:ws_government_of_canada_logo_enabled"]) ? true : false}
                        heading="Display Government of Canada funding acknowledgement"
                        description={<div>Displays a small acknowledgement with the Government of Canada logo in your consumer site's footer.
                            <br /><div className="shim" />
                            <Typography.Text>
                                <Form.Item valuePropName='checked' initialValue={(ws["global:ws_government_of_canada_bilingual"]) ? true : false} name="global:ws_government_of_canada_bilingual" noStyle>
                                    <Checkbox />
                                </Form.Item> Display Bilingual?</Typography.Text>
                            <br /><br />
                        </div>}
                    />
                </div>
                <div className="shim"></div><div className="shim"></div>

                <Divider dashed />
                <div id="ws_libro_bookstore_name"></div>
                {/* Libro.fm Audiobook Integration */}
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Store location marker</Typography.Title>
                        <Typography.Paragraph type="secondary">To display the store location marker, we use the address data provided for your store. Occasionally, this location data may be inaccurate or imprecise. Use the adjust button to fine-tune the marker's placement.</Typography.Paragraph>

                        {stores.map((item, index) => {
                            let _name = "ms" + item.store_number + ":ws_google_maps_override";
                            return (
                                <>
                                    <MapLocationPicker changed={changed} setChanged={setChanged} label={item.altname} form={form} value={ws[_name]} name={_name} />
                                </>
                            )
                        })}

                    </div>
                </Space>

            </div>
        </>
    )
}