import React, { useState } from "react";
import { Button, Input, Modal, Space, Typography, Tag, message, Divider } from "antd";
import { ShareAltOutlined, TwitterSquareFilled, FacebookFilled, TwitterOutlined, FacebookOutlined } from '@ant-design/icons'
import BroadcastMessage from "../BroadcastMessage";
import { XIcon } from "../icons/XIcon";
export default function TitleShare(props) {

    const { eisbn = "", title = "", isbn = "" } = props;


    let url = window.sitesettings.url + "/item/" + eisbn;
    let url2 = "https://biz.bookmanager.com/purchasing/browse/filter/t/" + isbn;
    let lastchar = window.sitesettings.url.substr(-1);
    // just in case it has a slash

    if (lastchar === "/") {
        url = window.sitesettings.url + "item/" + eisbn;
    }



    const CopyButton = (props) => {
        const { path = "" } = props
        const [copied, setCopied] = useState(false);

        const copyToClipboard = (_str) => {
            if (!navigator.clipboard) {
                message.error("Can't copy");
                return;
            }
            navigator.clipboard.writeText(path);
            setCopied(true);
            setTimeout(() => {
                setCopied(false)
            }, 1000);
        }

        return (<Button onClick={(e) => copyToClipboard(e)} type={(copied ? "default" : "primary")}>{(copied) ? "Copied" : "Copy"}</Button>);

    }

    const [visible, setVisible] = useState(false);
    const drawModal = () => {
        return (
            <>
                <Modal destroyOnClose footer={null} onCancel={() => setVisible(false)} visible={visible}>
                    <Typography.Title level={5}>Share</Typography.Title>
                    <Typography.Paragraph>To share this title with <strong>customers</strong>, copy this link (e.g. Ctrl-C) and then paste it into an email.</Typography.Paragraph>

                    <Space>
                        <Input style={{ "width": "400px" }} defaultValue={url} />
                        <CopyButton path={url} />
                    </Space>
                    <div className="shim" /><div className="shim" /><div className="shim" />
                    <Typography.Paragraph>To share this title with other <strong>bookmanager stores</strong>, copy this link (e.g. Ctrl-C) and then paste it into an email.</Typography.Paragraph>
                    <Space>
                        <Input style={{ "width": "400px" }} defaultValue={url2} />
                        <CopyButton path={url2} />
                    </Space>


                    <div className="shim" /><div className="shim" /><div className="shim" />
                    <BroadcastMessage title="Send this title to staff member screen" path={url2} />



                    <div className="shim" /><div className="shim" /><div className="shim" />
                    <Typography.Paragraph>Share on social media</Typography.Paragraph>

                    <Space size={5}>
                        <a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/share?url=" + url}><Button type="text" icon={<XIcon style={{ "fontSize": "32px", "color": "#000" }} />} /></a>
                        <a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + url}><Button type="text" icon={<FacebookFilled style={{ "fontSize": "32px", "color": "#4267B2" }} />} /></a>
                    </Space>
                    <Divider style={{ "margin": "15px 0px" }} />



                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => setVisible(false)}>Cancel</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Modal>
            </>
        )
    }


    return (
        <>
            {drawModal()}
            <Button onClick={() => setVisible(true)} style={{ "width": "26px" }} size="small" className="mini-btn" ><small><ShareAltOutlined /></small></Button>
        </>
    )
}