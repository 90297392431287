import { Button, Checkbox, Divider, Form, Input, InputNumber, Switch, Space, Spin, Table, Typography, message, Alert, Popconfirm, Select, Tooltip } from "antd";
import React, { useState } from "react";
import { EditOutlined, DeleteOutlined, CloseCircleFilled, CheckCircleFilled, FileExcelOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Overlay from "../Overlay";
import { apiCall, apiDownload } from "../../utils/Api";
import moment from "moment";
export default function RSVP(props) {


    const {
        date = "",
        email_enabled = false,
        waitlist_enabled = false,
        rsvp_label = "RSVP",
        rsvp_email_recipient = "",
        rsvp_limit = 0,
        event_id = "",
        auto_purge = false,
        rsvp = [],
        rrule = "",
        rsvp_per_day = false,
        recurrence_dates = [],
    } = props;


    const [data, setData] = useState(rsvp);
    const [wl, setWL] = useState(waitlist_enabled);
    const [rd, setRd] = useState(rsvp_per_day);
    const [oc, setOc] = useState(moment(date).format("YYYY-MM-DD"));

    const removeRSVP = (_id) => {

        let obj = {}
        obj.id = _id;
        if (rd) {
            obj.recurrence_date = oc;
        }


        apiCall("events/v2/deleteRsvp", obj, (_status, _result) => {
            if (_status) {
                setData(_result);
            } else {
                message.error(_result.error)
            }
        })
    }


    const downloadExcel = () => {

        let obj = {}
        obj.event_id = event_id;
        if (rd) {
            obj.recurrence_date = oc;
        }
        apiDownload(obj, "events/v2/listRsvpExcel", true);
    }

    const RSVPForm = (props) => {

        const { event_id = "", item = {}, add = false, close = () => { } } = props;
        const {
            id = "",
            name = "",
            email = "",
            phone = "",
            on_waitlist = false,
            cancelled = false,
        } = item;

        const [wl, setWL] = useState(on_waitlist);


        const saveRSVP = (_f) => {

            let obj = _f

            if (rd) {
                obj.recurrence_date = oc;
            }


            let _api = (add) ? "events/v2/addRsvp" : "events/v2/editRsvp";
            apiCall(_api, obj, (_status, _result) => {
                if (_status) {
                    setData(_result);
                    let _msg = (add) ? "Added successfully" : "Updated successfully";
                    message.success(_msg);
                    close();
                } else {
                    message.error(_result.error)
                }
            })
        }


        return (
            <div style={{ "padding": "20px" }}>
                <Form onFinish={(_f) => saveRSVP(_f)} layout="vertical">
                    <Typography.Title level={5}>Edit RSVP</Typography.Title>
                    <Divider dashed style={{ "margin": "15px 0px" }} />
                    {(!add &&

                        <Form.Item initialValue={id} hidden name="id" label="Name">
                            <Input placeholder="Name..." />
                        </Form.Item>
                    )}
                    <Form.Item initialValue={event_id} hidden name="event_id" label="Name">
                        <Input placeholder="Name..." />
                    </Form.Item>

                    <Form.Item initialValue={name} name="name" label="Name">
                        <Input placeholder="Name..." />
                    </Form.Item>
                    <Form.Item initialValue={email} name="email" label="Email">
                        <Input placeholder="Email..." />
                    </Form.Item>
                    <Form.Item initialValue={phone} name="phone" label="Phone">
                        <Input placeholder="Phone..." />
                    </Form.Item>
                    <Form.Item initialValue={on_waitlist} valuePropName="checked" name="on_waitlist" label="On waitlist?">
                        <Switch onChange={(e) => setWL(e)} style={{ "marginTop": "-20px" }} size="small" />
                    </Form.Item>

                    {((on_waitlist && !wl) &&
                        <div>
                            <div style={{ "marginTop": "-20px" }} ><Typography.Text><small>Send an automated email to the new attendee to inform them that they now have a spot and are no longer waitlisted.</small></Typography.Text></div>
                            <div className="shim" /><div className="shim" />
                            <Form.Item initialValue={false} valuePropName="checked" name="send_email_notification" label="Email notification?">
                                <Switch style={{ "marginTop": "-20px" }} size="small" />
                            </Form.Item>

                        </div>
                    )}

                    <Form.Item style={{ "marginTop": "-10px" }} initialValue={cancelled} valuePropName="checked" name="cancelled" label="Cancelled?">
                        <Switch style={{ "marginTop": "-20px" }} size="small" />
                    </Form.Item>

                    <Divider dashed />
                    <div className="float-flex">
                        <div>&nbsp;</div>
                        <Space>
                            <Button onClick={() => close()}>Cancel</Button>
                            <Button htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        )
    }

    const columns = [
        {
            title: "", ellipsis: true, width: 20, dataIndex: "cancelled", key: "cancelled", render: (e) => {
                return ((e) ? <CloseCircleFilled style={{ color: "#9e9e9e" }} /> : <CheckCircleFilled style={{ color: "#59bf51" }} />)
            }
        },
        { title: "", ellipsis: true, dataIndex: "name", key: "name" },
        {
            title: "", ellipsis: true, dataIndex: "email", key: "email", render: (e) => {
                const mailto = "mailto:" + e;
                return (<a href={mailto}>{e}</a>)
            }
        },
        { title: "", width: "100px", ellipsis: true, dataIndex: "phone", key: "phone" },
        {
            title: "", width: "80px", dataIndex: "waitlist", key: "waitlist", render: (e, f) => {

                return (
                    <Space>
                        <Overlay width={300} component={<RSVPForm event_id={event_id} item={f} />}><Button type="link" size="small"  ><small><EditOutlined /></small></Button></Overlay>
                        <Button onClick={(e) => removeRSVP(f.id)} type="link" size="small" danger  ><small><DeleteOutlined /></small></Button>
                    </Space>
                )

            }
        }
    ];

    const drawLegend = () => {
        return (
            <div style={{ "padding": "3px 8px" }}>
                <Space>
                    <div><small><CloseCircleFilled style={{ color: "#9e9e9e" }} />&nbsp; <span style={{ "opacity": ".6" }}>Cancelled</span></small></div>
                    <div><small><CheckCircleFilled style={{ color: "#59bf51" }} />&nbsp; <span style={{ "opacity": ".6" }}>Active</span></small></div>
                </Space>
            </div>
        )
    }

    const filterRSVP = (_waitlist = false) => {

        if (rd) {
            return data.filter(item => item.on_waitlist === _waitlist && item.recurrence_date === oc);
        } else {
            return data.filter(item => item.on_waitlist === _waitlist);
        }



    }

    return (
        <>

            {/* <Form.Item initialValue={auto_purge} valuePropName="checked" label="Auto-purge RSVP/Waitlist after each occurance" name="auto_purge">
                <Switch size="small" onChange={() => setWL(!wl)} />
            </Form.Item> */}

            {(rrule &&
                <>
                    <Alert closable style={{ "padding": "3px 8px", "marginBottom": "15px", "width": "100%" }} description={<div style={{ "fontSize": "12px", "lineHeight": "16px" }}>Heads up! Your event is reoccurring, and RSVPs will not be tracked on a per-occurrence basis. Instead, RSVPs will remain in place for all occurrences of this event. If you would like to reset your RSVP list after an occurrence, manually remove RSVPs and waitlisted attendees.</div>} />
                    <Divider dashed />
                </>
            )}


            <Space align="start" size={30}>
                <div>


                    {(rrule && <>

                        <div className="float-flex">
                            <Form.Item initialValue={rsvp_per_day} valuePropName="checked" label={<Tooltip title={<small>Enable this option if you would like to maintain a separate RSVP/Waitlist per event occurrence. When enabled, exporting attendees to XLS will only contain those who RSVP'd to the selected occurrence. When this option is disabled, all event occurrences will use the same RSVP list, and that list will not reset after each occurrence.</small>}>RSVP each occurrence? <small><InfoCircleOutlined /></small></Tooltip>} name="rsvp_per_day">
                                <Checkbox onChange={() => setRd(!rd)} />
                            </Form.Item>

                            <Form.Item initialValue={rsvp_per_day} valuePropName="checked" label="Occurrence?">
                                <Select onChange={(e) => setOc(e)} defaultValue={oc} disabled={!rd} style={{ "width": "150px" }}>
                                    {recurrence_dates.map(item => {
                                        return (<Select.Option key={item} value={item}>{moment(item).format("MMM Do, YYYY")}</Select.Option>)
                                    })}
                                </Select>
                            </Form.Item>
                        </div>

                    </>)}

                    <Overlay width={300} component={<RSVPForm add={true} event_id={event_id} />}><small><a>Add RSVP &nbsp;</a></small></Overlay>
                    <Table showHeader={false} locale={{ emptyText: "No customer RSVPs" }} style={{ "width": "420px" }} scroll={{ y: 255 }} className="tborder" pagination={false} columns={columns} dataSource={filterRSVP(false)} size="small"></Table>

                    <div className="shim" /> <div className="shim" /> <div className="shim" />
                    <Spin spinning={!wl} indicator={<></>}>
                        <div className="ant-col ant-form-item-label">Waitlist</div>
                        <Table showHeader={false} locale={{ emptyText: "No customers on waitlist" }} style={{ "width": "420px" }} scroll={{ y: 255 }} className="tborder" pagination={false} columns={columns} dataSource={filterRSVP(true)} size="small"></Table>
                    </Spin>
                    <div className="shim" /> <div className="shim" />
                    <div className="float-flex">
                        {drawLegend()}
                        <div>
                            <div className="shim" />
                            <Space>
                                <Button onClick={() => downloadExcel()} type="primary" size="small"><small><FileExcelOutlined />&nbsp;Export XLS</small></Button>
                                {/* <Popconfirm title="Are you sure?">
                                    <Button onClick={() => resetAll()} danger type="primary" size="small"><small><DeleteOutlined />&nbsp;Purge All</small></Button>
                                </Popconfirm> */}
                            </Space>
                        </div>

                    </div>
                </div>
                <div>
                    <Form.Item initialValue={rsvp_limit} label="Limit" name="rsvp_limit">
                        <InputNumber min={0} max={9999} />
                    </Form.Item>
                    <Form.Item initialValue={rsvp_label} label="RSVP Button Label" name="rsvp_label">
                        <Input placeholder="Label..." />
                    </Form.Item>
                    <div className="shim" />
                    <Form.Item initialValue={waitlist_enabled} valuePropName="checked" label="Enable Waitlist?" name="waitlist_enabled">
                        <Switch size="small" onChange={() => setWL(!wl)} />
                    </Form.Item>


                    <Form.Item initialValue={email_enabled} valuePropName="checked" label="Receive email alerts for signups and cancellations." name="email_enabled">
                        <Switch size="small" />
                    </Form.Item>
                    <Form.Item initialValue={rsvp_email_recipient} label="Notification Email" name="rsvp_email_recipient">
                        <Input placeholder="Email address..." />
                    </Form.Item>
                </div>
            </Space>
        </>
    )
}