import React, { useEffect, useState } from "react";
import moment from "moment";
import { apiCall } from "../../utils/Api";
import Peek from "../../components/Peek";
import Overlay from "../../components/Overlay";
import Title from "../../components/title/Title";
import { Table, Button, Tag, message, Space, Radio, Spin, Divider } from "antd";
import Order from "../../components/Order";
import { useSession } from "../../utils/Session";
import conditional from "../../tools/conditional";
import PeerSales from "../../components/PeerSales";
import { drawQuantity, peerColor, warningColor, successColor, bestsellerColor, futureColor, parseSubjectsWeird } from "../../utils/Utils";
import Renderer from "../../components/Renderer";
import { CloseOutlined, SelectOutlined, PicLeftOutlined, AppstoreOutlined, MenuOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import ItemLayouts from "../../components/ItemLayouts";
import TestOrderBox from "../../components/TestOrderBox";
import Paginate from "../../components/Paginate";
import LazyLoad from 'react-lazyload';
export default function CartIsbns(props) {

    const [session, setSession] = useSession();
    const [expanded, setExpanded] = useState([])
    const { record = {}, items = {}, pagination = false, border = false, drawComponent = () => { }, close = () => { } } = props;
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [titlesLoading, setTitlesLoading] = useState(false);
    const isbns = [... new Set(items.map(item => item.isbn))];
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 20, offset: 0 });
    const [titleInfo, setTitleInfo] = useState([])

    const [filters, setFilters] = useState("cart");
    const [titleResults, setTitleResults] = useState({ rows: [] });


    const expandCell = (_data) => {
        if (!expanded.includes(_data.isbn)) {
            setExpanded([_data.isbn]);
        } else {
            setExpanded([]);
        }
    }

    const drawChildTable = (_record) => {

        return (
            <div className="hideHover" style={{ "margin": "-8px -7px" }}>
                <div style={{ "padding": "0 20px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none", "marginBottom": "0px" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                </div>
                <div style={{ "padding": "20px" }}>
                    <Renderer close={true}><ItemLayouts display="details2" isbn={_record.isbn} /></Renderer>
                </div>
            </div>
        )
        //return <CartIsbns items={_record.items} />
    }



    const removeItem = (_record) => {

        let obj = {
            supplier: _record.location,
            order_group: _record.order_grp,
            user_id: _record.owner_id,
            isbn: _record.isbn
        };

        apiCall("cart/delete", obj, (_status, _result) => {
            if (_status) {
                message.success("Title removed successfully.");
                setSession({ ...session, "cart": _result });
            }
        })
    }

    const getPeers = (_isbn) => {
        let row = results.find(item => item.isbn === _isbn);
        if (row) {
            let peer = row.sales.find(item => item.code === "").sales.rows.find(itm => itm.class === "peer");
            if (peer) {
                return {
                    onhand: peer.onhand,
                    onhandlocs: peer.onhandlocs,
                    onorder: peer.onorder,
                    onorderlocs: peer.onorderlocs
                }
            } else {
                return false
            }
        }
        return false;
    }


    const getPeerFromSales = (_sales, _ohhand = true) => {
        let m = _sales.find((item => item.code === ""));
        if (m) {
            m = m.sales.rows.find(item => item.class === "peer");
            if (m) {
                if (_ohhand) {
                    return <small style={{ "color": peerColor, "whiteSpace": "nowrap" }}>{m.onhand || ""}<sup> {m.onhandlocs || ""}</sup>&nbsp;</small>;
                } else {
                    return <small style={{ "color": peerColor, "whiteSpace": "nowrap" }}>{m.onorder || ""}<sup> {m.onorderlocs || ""}</sup>&nbsp;</small>;
                }

            } else {
                return <small>&nbsp;</small>;
            }
        }
        return "";
    }



    // const drawInventory = (_isbn, _onhand = true) => {

    //     let f = titleInfo.find(item => item.isbn === _isbn);

    //     if (!f) {
    //         return (<></>);
    //     }

    //     let inv = "";
    //     if (_onhand) {

    //         inv = f.available.map((item, index) => {
    //             return <div key={index} style={{ "color": successColor, "fontWeight": "bold", "fontStyle": (item.code) ? "italic" : "normal" }}>{item.onhand || ""}&nbsp;</div>
    //         })
    //     } else {

    //         inv = f.available.map((item, index) => {
    //             return <div key={index} style={{ "color": warningColor, "fontWeight": "bold", "fontStyle": (item.code) ? "italic" : "normal" }}>{item.onorder || ""}&nbsp;</div>
    //         });

    //     }


    //     return (
    //         <>
    //             {inv}
    //             {getPeerFromSales(f.sales, _onhand)}
    //         </>
    //     )


    // }

    const drawPeer = (_isbn, _onhand = false) => {
        let peer = getPeers(_isbn);
        if (peer) {
            return (
                <Tag style={{ "lineHeight": "16px", "cursor": "pointer" }} color="#a52a2a">
                    <conditional.true value={_onhand}>
                        {peer.onhand}<sup>{peer.onhandlocs}</sup>
                    </conditional.true>
                    <conditional.true value={!_onhand}>
                        {peer.onorder}<sup>{peer.onorderlocs}</sup>
                    </conditional.true>
                </Tag>)
        } else {
            return "";
        }
    }


    const getExtra = (_isbn, field = "title") => {
        let _f = titleInfo.find(item => item.isbn === _isbn);

        if (_f) {

            switch (field) {
                case "title":
                    return <>{_f.title}</>
                    break;
                case "onhand":
                    return <>{(_f.onhand) ? _f.onhand : ""}</>
                    break;
                case "onorder":
                    return <>{(_f.onorder) ? _f.onorder : ""}</>
                    break;
                case "peer_onhand":
                    return <>{(_f.peer_onhand) ? _f.peer_onhand : ""}<sup>{(_f.peer_onhand_locs) ? _f.peer_onhand_locs : ""}</sup></>
                    break;
                case "peer_onorder":
                    return <>{(_f.peer_onorder) ? _f.peer_onorder : ""}<sup>{(_f.peer_onorder_locs) ? _f.peer_onorder_locs : ""}</sup></>
                    break;

                default:
                    break;
            }

        } else {
            return (<></>)
        }
    }



    const item_columns = [
        {},
        { title: <small>Supplier</small>, key: 'location', dataIndex: 'location' },
        { title: <small>Group</small>, key: 'order_grp', dataIndex: 'order_grp' },
        {
            title: <small>Price</small>, key: 'supplier_price', dataIndex: 'supplier_price', render: (e) => {
                return (e) ? "$" + e.toString() : "";
            }
        },
        {
            title: <small>Cart</small>, key: "cart", dataIndex: "cart", render: (e, f) => {
                
                return <Overlay width={750} component={<TestOrderBox wrap data={titleResults.rows.find(item => item.isbn === f.isbn)} isbn={f.isbn} />}>{drawQuantity(f.isbn, session.cart)}</Overlay>
            }
        },
        {
            title: <small>OH</small>, key: "oh", dataIndex: "oh", render: (e, f) => {

                return (
                    <>
                        <div style={{ "color": successColor, "fontWeight": "bold" }}>{getExtra(f.isbn, "onhand")}</div>
                        <div><small style={{ "color": peerColor, "whiteSpace": "nowrap" }}>{getExtra(f.isbn, "peer_onhand")}</small></div>
                    </>
                )
                //return drawInventory(f.isbn, true);
            }
        },
        {
            title: <small>OO</small>, key: "oo", dataIndex: "oo", render: (e, f) => {
                return (
                    <>
                        <div style={{ "color": successColor, "fontWeight": "bold" }}>{getExtra(f.isbn, "onorder")}</div>
                        <div><small style={{ "color": peerColor, "whiteSpace": "nowrap" }}>{getExtra(f.isbn, "peer_onorder")}</small></div>
                    </>
                )

                // return drawInventory(f.isbn, false);
            }
        },
        { title: <small>Discount</small>, key: 'discount', dataIndex: 'discount' },
        { title: <small>PM</small>, key: 'profit_margin', dataIndex: 'profit_margin' },
        {
            title: <small>Sell Price</small>, key: 'sell_price', dataIndex: 'sell_price', render: (e) => {
                return (e) ? "$" + e.toString() : "";
            }
        },
        {
            title: <small>Title</small>, width: "200px", key: "title", dataIndex: "title", render: (e, f) => {
                return <a onClick={() => expandCell(f)}>{getExtra(f.isbn, "title")}</a>;
                //return (results.find(item => item.isbn == f.isbn) ? <a onClick={() => expandCell(f)}>{results.find(item => item.isbn == f.isbn).title}</a> : "");
            }
        },
        { title: <small>ISBN</small>, key: 'isbn', dataIndex: 'isbn' },
        { title: <small>By</small>, key: 'owner_name', dataIndex: 'owner_name' },
        { title: <small>Age</small>, key: 'as_of', dataIndex: 'as_of', render: (e) => { return moment(e * 1000).fromNow() } },
        {
            title: <small></small>, width: "50px", key: 'actions', dataIndex: 'actions', render: (e, f) => {
                return <Button onClick={() => removeItem(f)} danger size="small"><small>Remove</small></Button>
            }
        }, {
            key: "blank", width: "10px"
        }
    ]

    const dsource = [...new Map(items.map(item => [item.isbn, item])).values()].slice(paginate.offset, (paginate.current * paginate.pagesize));
    const xsource = [...new Map(items.map(item => [item.isbn, item])).values()];

    const getIsbns = () => {


        setLoading(true);
        let _isbns = dsource.map(item => item.isbn);
        apiCall("cart/getTitleInfo", { isbns: JSON.stringify(_isbns) }, (_status, _result) => {
            if (_status) {
                setTitleInfo(_result);
            }
            setLoading(false);
        })
    }

    useEffect(getIsbns, [paginate.offset, paginate.pagesize]);


    const [cacheKey, setCacheKey] = useState("");
    const getTitles = () => {

        // skip if you have cache key
        if(cacheKey){
            getTitles2(cacheKey);
            return; 
        }

        setTitlesLoading(true)
        let _isbns = xsource.map(item => item.isbn);
        apiCall("browse/getCacheKeyForIsbns", { isbns: JSON.stringify(_isbns)}, (_status, _result) => {
            if(_status){
                //_result.id
                setCacheKey(_result.id);
                getTitles2(_result.id);
            }
        })
    }

    const getTitles2 = (_cache_key) => {
       
        apiCall("browse/get", { v:"sequence", x: _cache_key, l: paginate.pagesize, o: paginate.offset }, (_status, _result) => {
            if (_status) {
                setTitleResults(_result);
            }
            setTitlesLoading(false);
        })
    }

    useEffect(getTitles, [paginate.offset, paginate.pagesize]);


    const [browseLoad, setBrowseLoad] = useState(false);

    const browseCart = () => {
        setBrowseLoad(true);
        let _isbns = [...new Map(items.map(item => [item.isbn, item])).values()].map(item => item.isbn);

        apiCall("browse/getCacheKeyForIsbns", { isbns: JSON.stringify(_isbns) }, (_status, _result) => {
            if (_status) {
                window.open("/purchasing/browse/filter/v/sequence/x/" + _result.id);
            } else {
                message.error(_result.error)
            }
            setBrowseLoad(false)

        })
    }

    const colorDate = (_timestamp) => {
        let now = moment().unix();
        let color = "inherit";
        if (_timestamp > now) {
            // future
            color = futureColor;
        } else {
            // within the last two months
            if ((now - _timestamp) < 5274000) {
                // within the last two months
                color = successColor;
            }
        }
        return color;
    }

    const columns = [

        {},


        {
            title: <small>PubDate</small>, dataIndex: 'release_date', key: 'release_date', render: (e) => {
                return <span style={{ "color": colorDate(e) }}>{moment(e * 1000).format("MMMD-YY")}</span>;
            }
        },
        {
            title: <small>Rank</small>, dataIndex: 'rank', key: 'rank', render: (e) => {
                return (e.hasOwnProperty("bestseller") ? <span style={{ "color": bestsellerColor }}>{e.bestseller}</span> : <span style={{ "color": futureColor }}>{e.future}</span>);
            }
        },
        {
            title: <small>Cart</small>, dataIndex: 'cart', key: 'cart', render: (e, f) => {


                return (<Overlay width={750} component={<TestOrderBox wrap data={f} isbn={f.isbn} />}>{drawQuantity(f.isbn, session.cart)}</Overlay>)
            }
        },
        {
            title: <small>OH</small>, dataIndex: 'onhand', key: 'onhand', render: (e, f) => {
                return (
                    <>
                        {f.available.map(item => {
                            return <div style={{ "color": successColor, "fontWeight": "bold", "fontStyle": (item.code) ? "italic" : "normal" }}>{item.onhand}&nbsp;</div>
                        })}
                        {/* {getPeerFromSales(f.sales)} */}
                    </>
                );
            }
        },
        {
            title: <small>OO</small>, dataIndex: 'onorder', key: 'onorder', render: (e, f) => {
                return (
                    <>
                        {f.available.map(item => {
                            return <div style={{ "color": warningColor, "fontWeight": "bold", "fontStyle": (item.code) ? "italic" : "normal" }}>{item.onorder}&nbsp;</div>
                        })}

                        {/* {getPeerFromSales(f.sales, false)} */}
                    </>
                );
            }
        },
        {
            title: <small>Price</small>, dataIndex: 'price', key: 'price', render: (e, f) => {

                return (
                    <>
                        {f.available.map(item => {
                            return <div style={{ "fontWeight": "normal", "fontStyle": (item.code) ? "italic" : "normal", "color": (item.code) ? bestsellerColor : "normal" }}>${item.row_price}&nbsp;</div>
                        })}
                        <div>&nbsp;</div>
                    </>
                );

            }
        },
        { title: <small>Bd</small>, dataIndex: 'binding_code', key: 'binding_code' },
        {
            title: <small>Title</small>, className: "nowrap250", dataIndex: 'title', key: 'title', render: (e, f) => {

                return (<a onClick={() => expandCell(f)}>{e}{(f.subtitle) ? " : " + f.subtitle : ""}</a>)
            }
        },
        {
            title: <small>Author</small>, className: "nowrap", dataIndex: 'authors', key: 'authors', render: (e) => {
                return e.map(item => item.name).join(", ")
            }
        },
        { title: <small>Series</small>, className: "nowrap", dataIndex: 'series', key: 'series' },
        { title: <small>#</small>, dataIndex: 'series_num', key: 'series_num' },
        {
            title: <small>Subjects</small>, dataIndex: 'subjects', key: 'subjects', render: (e) => {
                return <span>
                    {parseSubjectsWeird(e, 15)}
                </span>;
            }
        },
        { title: <small>Publisher</small>, className: "nowrap", dataIndex: 'publisher', key: 'publisher' },
        { title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn' },

    ]





    return (
        <>
            <div style={{ "margin": "0 -20px", "marginBottom": "-1px" }}>

                <Paginate component={<div style={{ "display": "inline", "marginLeft": "10px" }}>

                    <Space>
                        <Radio.Group onChange={(e) => setFilters(e.target.value)} value={filters} size="small" buttonStyle="solid">
                            <Radio.Button value={"cart"}><small><ShoppingCartOutlined /></small></Radio.Button>
                            <Radio.Button value={"details2"}><small><PicLeftOutlined /></small></Radio.Button>
                            <Radio.Button value={"list"}><small><MenuOutlined /></small></Radio.Button>
                            <Radio.Button value={"cards"}><small><AppstoreOutlined /></small></Radio.Button>
                        </Radio.Group>
                        <Button loading={browseLoad} onClick={() => browseCart()} size="small" type="primary"><small>Filter &amp; Sort <SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "10px" }} /></small></Button>
                    </Space>

                </div>} hide_single={false} paginate={paginate} setPaginate={setPaginate} count={[...new Map(items.map(item => [item.isbn, item])).values()].length} />

                <conditional.true value={(filters === "cart")}>
                    <Table
                        size="small"
                        columns={item_columns}
                        loading={loading}
                        rowKey={"isbn"}
                        className={(border) ? "tableBorder" : "tableNoBorder"}
                        dataSource={dsource}
                        pagination={false}
                        expandable
                        expandedRowKeys={expanded}
                        expandIconColumnIndex={-1}
                        onExpand={(e) => { return false; }}
                        expandIcon={() => { return (<></>) }}
                        expandedRowRender={((record) => drawChildTable(record))}
                    />
                </conditional.true>

                <conditional.true value={(filters !== "cart")}>
                    <br />
                    <conditional.true value={(filters === "list")}>
                        <div style={{ "marginTop": "-20px" }}>
                            <Table
                                size="small"
                                pagination={false}
                                rowKey={"isbn"}
                                loading={titlesLoading}
                                columns={columns}
                                dataSource={titleResults.rows}
                                expandable
                                expandedRowKeys={expanded}
                                expandIconColumnIndex={-1}
                                onExpand={(e) => { return false; }}
                                expandIcon={() => { return (<></>) }}
                                expandedRowRender={((record) => drawChildTable(record))}
                            />
                        </div>
                    </conditional.true>

                    <Spin spinning={titlesLoading}>

                        {/* Split into chunks so renderer can draw placeholder */}
                        <conditional.true key={"cards"} value={(filters === "cards")}>
                            <div style={{ "padding": "0px 20px" }}>
                                {titleResults.rows.reduce((all, one, i) => {
                                    const ch = Math.floor(i / 5);
                                    all[ch] = [].concat((all[ch] || []), one);
                                    return all
                                }, []).map((item, index) => {
                                    return <div key={item[0].isbn}><Renderer><ItemLayouts data={item} index={index} display={filters} /></Renderer><Divider dashed /></div>
                                })}
                            </div>
                        </conditional.true>

                        <conditional.true value={(filters === "details2")}>
                            <div style={{ "padding": "0px 20px" }}>
                                {titleResults.rows.map((item) => {
                                    return <LazyLoad offset={500} height={360}><div key={item.isbn}><Renderer close={false}><ItemLayouts data={item} display={filters} /></Renderer> <Divider dashed /></div></LazyLoad>
                                })}
                            </div>
                        </conditional.true>
                    </Spin>
                </conditional.true>


                <Paginate hide_single={true} paginate={paginate} setPaginate={setPaginate} count={[...new Map(items.map(item => [item.isbn, item])).values()].length} />
            </div>
        </>
    )

}