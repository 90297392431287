import React, { useEffect, useState } from "react"
import LayoutHeader from "../../layouts/partials/LayoutHeader"
import { Button, DatePicker, Form, Layout, Space, message, Spin, Select, Divider, Checkbox, Affix } from "antd"
import { Column } from '@ant-design/charts';
import moment from "moment-timezone"
import { apiCall } from "../../utils/Api";
import { addCommas } from "../../utils/Utils";
import TimeOfDayFilters from "../analytics/TimeOfDayFilters";
import CalendarGrid from "../analytics/CalandarGrid";


export default function TimeOfDaySales() {

    const [results, setResults] = useState({ price_days: [], units_days: [], txn_days: []   });
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({ start_date: moment().add(-1, "year"), end_date: moment() })
    const [groupBy, setGroupBy] = useState({ cat: [] });
    const [unit, setUnit] = useState("price");
    const [dates, setDates] = useState([filters.start_date, filters.end_date]);
    const [value, setValue] = useState([filters.start_date, filters.end_date]);

    const [startDate, setStartDate] = useState(filters.start_date);
    const [endDate, setEndDate] = useState(filters.end_date);

    const disableRange = (current) => {

        const tooLate = startDate && current.diff(startDate, 'days') > 365;
        const tooEarly = endDate && endDate.diff(current, 'days') > 365;
        return !!tooEarly || !!tooLate;
    };

    const onOpenChange = (open) => {
        if (open) {
            setDates([null, null]);
        } else {
            setDates(null);
        }
    };

    const resetAllButton = () => {
        return (
            <Button
                onClick={() => {
                    setGroupBy({});
                    setStartDate(moment().add(-1, "year"));
                    setEndDate(moment())
                }} type="link" size="small"><small>Reset</small>
            </Button>
        )
    }

    const getGroupBy = () => {

        let obj = {}
        let gb = { ...groupBy };
        Object.keys(gb).map(_key => {

            if (gb[_key].length > 0 && gb[_key][0].substr(0, 1) === "-") {
                // exclude
                obj[_key] = {}
                obj[_key].inc = [];
                obj[_key].exc = gb[_key].map(item => item.replace("-", ""));
            }

            if (gb[_key].length > 0 && gb[_key][0].substr(0, 1) !== "-") {
                // exclude
                obj[_key] = {}
                obj[_key].inc = gb[_key];
                obj[_key].exc = [];
            }

        })

        return JSON.stringify(obj);

    }

    const getSales = () => {
        setLoading(true)

        let params = {
            start_date: startDate.tz('America/Los_Angeles').startOf("day").unix(),
            end_date: endDate.tz('America/Los_Angeles').endOf("day").unix(),
            group_by: getGroupBy()
        }

        apiCall("reports/analytics/salesByWeekdayHour", params, (_status, _results) => {
            if (_status) {
                setResults(_results);
            } else {
                message.error(_results.error)
            }
            setLoading(false)
        })
    }
    useEffect(getSales, [startDate, endDate, groupBy]);

    const daysToData = (_days = []) => {

        if (!Array.isArray(_days)) {
            return [];
        }

        let arr = [];
        _days.forEach(element => {
            const { name = "", min = 0, avg = 0, max = 0, min_label = "min", max_label = "max", avg_label = "avg" } = element;
            arr.push({ time: name, type: "High", value: max, label: max_label })
            arr.push({ time: name, type: "Average", value: avg, label: avg_label })
            arr.push({ time: name, type: "Low", value: min, label: min_label })
        });
        return arr;
    }

    function convertToAMPM(hour) {
        if (hour >= 0 && hour <= 23) {
            var ampm = hour >= 12 ? 'pm' : 'am';
            var convertedHour = hour % 12 || 12;
            return convertedHour + ampm;
        } else {
            return 'Invalid hour';
        }
    }

    const customSortOrder = { "High": 1, "Average": 2, "Low": 3 };

    function sortByTimeThenValue(a, b) {
        // First, compare by time numerically
        const timeA = parseInt(a.time, 10);
        const timeB = parseInt(b.time, 10);
        if (timeA !== timeB) {
            return timeA - timeB;
        }
        return customSortOrder[a.type] - customSortOrder[b.type]
    }

    const DayHistogram = (props) => {

        const [filters, setFilters] = useState({
            h: false,
            a: true,
            l: false
        });

        const { data = [], title = "Weekdays" } = props;

        const colors = ['#a6d5af', '#64a6da', '#b1d2ec'];
        const colorObj = { "Average": '#64a6da', "High": '#a6d5af', "Low": '#b1d2ec' };

        const filterData = () => {
            let _d = [...data];
            if (!filters.h) {
                _d = _d.filter(item => item.type !== "High")
            }
            if (!filters.a) {
                _d = _d.filter(item => item.type !== "Average")
            }
            if (!filters.l) {
                _d = _d.filter(item => item.type !== "Low")
            }

            return _d;

        }

        const getColors = () => {
            let _c = [...colors];
            if (!filters.h) {
                _c = _c.filter(item => item !== "#a6d5af")
            }
            if (!filters.a) {
                _c = _c.filter(item => item !== "#64a6da")
            }
            if (!filters.l) {
                _c = _c.filter(item => item !== "#b1d2ec")
            }
            return _c;
        }

        const config = {
            data: filterData(),
            // isGroup: true,
            animation: false,
            legend: false,
            color: getColors(),
            isStack: false,
            xField: 'time',
            yField: 'value',

            seriesField: 'type',
            yAxis: {
                label: {
                    formatter: (v) => (unit === "price") ? "$" + addCommas(v) : v,
                },
            },
            tooltip: {
                customContent: (title, items) => {
                    // Constructing the tooltip content

                    return (`
                    <div class="g2-tooltip-title" style="margin-bottom: 12px; margin-top: 12px;">${title}</div>
                        <ul class="g2-tooltip-list" style="margin: 0px; list-style-type: none; padding: 0px;">
                        
                        ${items
                            .map((item) => {
                                return `
                                    <li class="g2-tooltip-list-item" data-index="" style="list-style-type: none; padding: 0px; margin: 12px 0px;">
                                        <span class="g2-tooltip-marker" style="background: ${colorObj[item.data.type]}; width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-right: 8px;"></span>
                                        <span class="g2-tooltip-name">${item.data.label}</span>:
                                        <span class="g2-tooltip-value" style="display: inline-block; float: right; margin-left: 30px;">${(unit === "price") ? "$" + addCommas(item.value) : item.value}</span>
                                    </li>
                                `
                            })
                            .join('')}
                        </ul>
                  `)
                    return `<div>${title}</div>${items
                        .map((item) => {

                            return ` <li class="g2-tooltip-list-item" data-index="" style="list-style-type: none; padding: 0px; margin: 12px 0px;">
                            <span class="g2-tooltip-name">${item.data.label}</span>:
                        <span class="g2-tooltip-value" style="display: inline-block; float: right; margin-left: 30px;">${(unit === "price") ? "$" + addCommas(item.value) : item.value}</span>
                        </li>`
                            return `<div>${item.data.label}: ${item.value}</div>`;
                        })
                        .join('')}`;
                },
            },
            // tooltip: {
            //     formatter: (datum) => ({
            //         name: datum.type,
            //         value: (unit === "price") ? "$" + addCommas(datum.value) : datum.value,
            //     }),
            // }
        };

        return (
            <Spin spinning={loading}>
                <div className="float-flex">
                    <div className="c">{title}</div>
                    <div style={{"margin" : "-80px 0px"}}>
                        <Space size={20} style={{ "cursor": "pointer" }}>
                            <div onClick={() => setFilters({ ...filters, "h": !filters.h })}>
                                <Space>
                                    <Checkbox className="custom-checkbox1" checked={filters.h} />
                                    {/* <div style={{ "width": "10px", "height": "10px", "backgroundColor": (filters.h) ? colors[0] : "#ddd" }}></div> */}
                                    <div style={{ "opacity": "0.7", "fontSize": "12px" }}>High</div>
                                </Space>
                            </div>
                            <div onClick={() => setFilters({ ...filters, "a": !filters.a })}>
                                <Space>
                                    <Checkbox className="custom-checkbox2" checked={filters.a} />
                                    {/* <div style={{ "width": "10px", "height": "5px", "backgroundColor": (filters.a) ? colors[1] : "#ddd" }}></div> */}
                                    <div style={{ "opacity": "0.7", "fontSize": "12px" }}>Average</div>
                                </Space>
                            </div>
                            <div onClick={() => setFilters({ ...filters, "l": !filters.l })}>
                                <Space>
                                    <Checkbox className="custom-checkbox3" checked={filters.l} />
                                    {/* <div style={{ "width": "10px", "height": "5px", "backgroundColor": (filters.l) ? colors[2] : "#ddd" }}></div> */}
                                    <div style={{ "opacity": "0.7", "fontSize": "12px" }}>Low</div>
                                </Space>
                            </div>
                        </Space>
                    </div>
                </div>
                <div style={{ "padding": "10px" }}><Column {...config} /></div>
            </Spin>
        )
    };


    const drawPicker = () => {

        return (

            <Form>
                <Space wrap size={[5, 0]}>
                    <Form.Item initialValue={filters.start_date} label="Start Date" noStyle>
                        <label><small>Start date:</small></label>
                        <DatePicker
                            value={startDate}
                            defaultValue={value[0]}
                            // onCalendarChange={(val) => setDates(val)}
                            onChange={(val) => {

                                setStartDate(val);
                                if (endDate.diff(val, 'days') > 365) {

                                    setEndDate(moment(val.format("YYYY MM DD")).add(365, "days"))
                                }
                            }}
                            onOpenChange={onOpenChange}
                            allowClear={false}
                            className="inputsmall" size="small" style={{ "width": "100%" }} format="MMM Do YYYY" />
                    </Form.Item>

                    <Form.Item initialValue={filters.end_date} label="End Date" noStyle>
                        <label><small>End date:</small></label>
                        <DatePicker
                            value={endDate}
                            defaultValue={value[0]}
                            disabledDate={disableRange}
                            // onCalendarChange={(val) => setDates(val)}
                            onChange={(val) => {
                                setEndDate(val);

                            }}
                            onOpenChange={onOpenChange}
                            allowClear={false}
                            className="inputsmall" size="small" style={{ "width": "100%" }} format="MMM Do YYYY" />
                    </Form.Item>
                    <div>
                        <label><small>Metric:</small></label><div style={{ "height": "0px" }} className="shim" />
                        <Select value={unit} style={{ "width": "140px" }} onChange={(e) => setUnit(e)} size="small">
                            <Select.Option value={"price"} ><small>Dollars</small></Select.Option>
                            <Select.Option value={"units"}><small>Units</small></Select.Option>
                            <Select.Option value={"txn"}><small>Transactions</small></Select.Option>
                        </Select>
                    </div>
                </Space>
            </Form>
        )
    }

    return (
        <>

            <Layout className="layout">
                <LayoutHeader
                    title={<>Time of Day Sales {resetAllButton()}</>}
                    description="Use this page to review the average, maximum, and minimum sales metrics by date, day and hour within a specified date range of up to one year."
                    filters={<>
                        {drawPicker()}
                        <br />
                        <TimeOfDayFilters groupBy={groupBy} callback={(e) => setGroupBy(e)} headers={results} />
                        <div className="shim" /><div className="shim" />
                    </>}
                />

                <div style={{ "padding": "30px", "paddingBottom": "0px" }} key={"days"}>
                    {(!loading && <CalendarGrid loading={loading} unit={unit} heatmap={results[unit + "_heatmap"]} />)}
                    <Divider dashed />
                </div>


                <div style={{ "padding": "30px" }} key={"week"}>
                    <DayHistogram
                        data={daysToData(results[unit + "_days"])}
                        title="Weekdays"
                    />
                </div>

                {results[unit + "_days"].map((item, index) => {
                    return (
                        <div style={{ "padding": "30px" }} key={index}>

                            <div style={{ "margin": "20px -30px" }}><Divider /></div>
                            {drawPicker()}
                            <Divider dashed />
                            <DayHistogram
                                data={item.rows.sort(sortByTimeThenValue).map(item => {

                                    let _i = { ...item }
                                    _i.time = convertToAMPM(_i.time)
                                    return _i;
                                })}
                                title={item.name}
                            />
                        </div>
                    )
                })}
            </Layout>
        </>
    )
}

{/* <div class="g2-tooltip" style="position: absolute; visibility: visible; z-index: 8; transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s; background-color: rgb(255, 255, 255); box-shadow: rgb(174, 174, 174) 0px 0px 10px; border-radius: 3px; color: rgb(89, 89, 89); font-size: 12px; font-family: -apple-system, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, &quot;Noto Sans&quot;, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;, &quot;Noto Color Emoji&quot;; line-height: 12px; padding: 0px 12px; opacity: 0.95; pointer-events: none; left: 680.5px; top: 140.469px;"><div class="g2-tooltip-title" style="margin-bottom: 12px; margin-top: 12px;">1pm</div><ul class="g2-tooltip-list" style="margin: 0px; list-style-type: none; padding: 0px;">
    <li class="g2-tooltip-list-item" data-index="" style="list-style-type: none; padding: 0px; margin: 12px 0px;">
    <span class="g2-tooltip-marker" style="background: rgb(100, 166, 218); width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-right: 8px;"></span>
    <span class="g2-tooltip-name">Average</span>:
    <span class="g2-tooltip-value" style="display: inline-block; float: right; margin-left: 30px;">$2,103</span>
</li></ul></div> */}