import React from "react";
import { Row, Col, Pagination, Space } from "antd";
import { addCommas } from "../utils/Utils";
import conditional from "../utils/conditional";
import Cookies from "js-cookie";
export default function Paginate(props) {
    const {
        save = "",
        paginate = { current: 1, pagesize: 20, offset: 0 },
        setPaginate = () => { },
        show_pagination = true,
        pageSizeOptions = [10, 20, 50],
        precomponent = null,
        component = null,
        showSizeChanger = true,
        count = 0,
        hide_single = false,
        showing_right = false,
        loading = false,
        max_offset = 0
    } = props;

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a style={{"margin": "5px"}}>Previous</a>;
        }
        if (type === 'next') {
            return <a style={{"margin": "5px"}}>Next</a>;
        }
        return originalElement;
    };


    const drawShowing = () => {
        let end = paginate.current * paginate.pagesize;
        let start = (paginate.current * paginate.pagesize) - (paginate.pagesize - 1);
        if (end > count) {
            end = count;
        }
        if (count === 0) {
            return "0";
        }
        return addCommas(start) + " to " + addCommas(end);
    }

    const pageChange = (_page, _limit) => {
        let _offset = (_page - 1) * _limit;
        if (_limit !== paginate.pagesize) {
            _offset = 0;
            _page = 1;
            if (save) {
                Cookies.set(save, _limit, { expires: 365 });
            }
        }
        setPaginate({
            pagesize: _limit,
            offset: _offset,
            current: _page
        })
    }
    if ((hide_single && paginate.pagesize > count)) {
        return (<></>);
    }
    const drawShowingText = () => {
        return (<>Showing <em style={{ "fontWeight": "500" }}>{drawShowing()}</em> of <em style={{ "fontWeight": "500" }}>{addCommas(count)}</em> results</>)
    }

    return (
        <>
            <div className="paginate" style={{}}>
                <div className="float-flex">
                    <div style={{ "padding": "10px 20px" }}>
                        {(precomponent && precomponent)}
                        {(!showing_right && drawShowingText())}
                        {(component && component)}
                    </div>
                    <div>
                        <Space>
                            {(showing_right && drawShowingText())}
                            <div style={{ "paddingRight": "20px", "textAlign": "right" }}>
                                <div className="shim" /><div className="shim" />
                                <conditional.true value={(show_pagination)} >
                                    <Pagination 
                                        pageSizeOptions={pageSizeOptions} 
                                        size="small" 
                                        showSizeChanger={showSizeChanger} 
                                        itemRender={itemRender}
                                        current={paginate.current} 
                                        pageSize={paginate.pagesize} 
                                        total={(max_offset && max_offset < count) ? max_offset : count} 
                                        onChange={(_page, _limit) => pageChange(_page, _limit)} 
                                    />
                                </conditional.true>
                                <div className="shim" /><div className="shim" />
                            </div>
                        </Space>
                    </div>
                </div>
            </div>
        </>
    )
}