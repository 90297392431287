import { Popconfirm } from "antd";
import React from "react";
import { apiCall } from "../utils/Api";
export default function EventVisibilityToggle(props){

    const { event_id = 0, visibility = 0, updateRow = () => {}} = props; 
    
    const toggle = () =>{

        let _vis = (visibility) ? "hidden" : "visible";

        apiCall("events/edit", {event_id : event_id, visibility : _vis}, (_status, _result) => {
            if (_status) {
                updateRow(_result);
            }
        })
    }

    return(<Popconfirm onConfirm={() => toggle()} title={(visibility) ? "Make event hidden?" : "Make event visible?"}>{props.children}</Popconfirm>)


    
}