import React, { useEffect, useState } from "react";
import { Layout, Typography, Divider, Table, Button, Space, Badge, Popconfirm, DatePicker, Menu, Dropdown, Tabs, Col, Statistic, Card, Checkbox, Radio } from "antd";
import { apiCall, apiDownload } from "../../utils/Api";
import Html from "../../utils/Html";
import { Link, useParams } from "react-router-dom";
import PreviousOrder from "../../components/PreviousOrder";
import moment from "moment";
import { FileExcelOutlined, CloseOutlined } from '@ant-design/icons';
import Peek from "../../components/Peek";
import Overlay from "../Overlay";
import TitlesTable from "./TitlesTable";
import Notes from "./Notes";
import { pluralize, addCommas } from "../../utils/Utils";
import ResendOrder from "./ResendOrder";
import OrderStatus from "./OrderStatus";
import Paginate from "../Paginate";
export default function OrdersTable(props) {

    const { filters, setFilters = () => { }, border = true } = props;
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [results, setResults] = useState({ rows: [], row_count: 0 });
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 20, offset: 0 });



    useEffect(() => {
        setPaginate({ current: 1, pagesize: 20, offset: 0 });
    }, [filters])

    const expandCell = (_data) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }


    const drawChildTable = (_record, _another) => {

        let title_fltrs = { ...filters }
        title_fltrs["bookorder_id"] = _record.bookorder_id;
        title_fltrs["nesting"] = "bookorder_id";

        return (
            <div className="hideHover" style={{ "margin": "-7px" }}>
                <div style={{ "padding": "0 20px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none", "marginBottom": "0px" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                </div>
                <div style={{ "padding": "0px 0px" }}>
                    <TitlesTable border={false} pagination={false} record={_record} data={results} filters={title_fltrs} />

                </div>
            </div>
        )
    }


    const getSummary = () => {
        setLoading(true)

        let _filters = { ...filters }
        _filters.limit = paginate.pagesize;
        _filters.offset = paginate.offset;

        apiCall("orders/getBookorder", _filters, (_status, _result) => {
            if (_status) {

                addKeys(_result.rows)
                setResults(_result)
                setLoading(false)
            }
        })
    }
    useEffect(getSummary, [filters, paginate.pagesize, paginate.offset]);


    const ucfirst = (string) => {
        return (string) ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }

    const downloadExcel = (_include_title_details = false) => {
        apiDownload({ ...filters, include_title_details: _include_title_details }, "orders/getExcel");
    }

    const downloadSingle = (_order_id) => {
        apiDownload({ order_id: _order_id }, "order/getExcel");
    }

    const ExcelOptions = (props) => {

        const [excelOption, setExcelOption] = useState(false);
        const processExcel = () => {
            props.close();
            downloadExcel(excelOption);
        }


        return (
            <div style={{ "padding": "20px" }}>
                <strong>Excel options</strong>
                <Divider style={{ "margin": "15px 0px" }} dashed />
                <Radio.Group onChange={(e) => setExcelOption(e.target.value)} value={excelOption} >
                    <Space direction="vertical">
                        <Radio value={false}>&nbsp;Summary <br /><em style={{ "opacity": "0.7" }}><small>&nbsp;One line summary per online order</small></em></Radio>
                        <Radio value={true}>&nbsp;Detailed <br /><em style={{ "opacity": "0.7" }}><small>&nbsp;Includes title level details</small></em></Radio>
                    </Space>
                </Radio.Group>
                <Divider style={{ "margin": "15px 0px" }} dashed />
                <div className="float-flex">
                    <div>&nbsp;</div>
                    <Button onClick={() => processExcel()} type="primary"><FileExcelOutlined /> Download</Button>
                </div>
            </div>
        )
    }

    const bookorder_columns = [

        {
            "width": "10px", key: 'blank'
        },

        {
            title: <small>&nbsp;Ordered</small>, width: "160px", ellipsis: true, dataIndex: 'date_ordered', key: 'date_ordered',
            render: (e) => {
                return moment(e * 1000).format("MMM Do YYYY - h:mma");
            }
        },

        {
            title: <small>Action</small>, ellipsis: true, dataIndex: 'action', key: 'action', render: (e) => {
                return ucfirst(e);
            }
        },

        { title: <small>Qty</small>, ellipsis: true, dataIndex: 'quantity', key: 'quantity' },
        {
            title: <small>Value</small>, dataIndex: 'price', key: 'price',
            render: (e) => {
                return (e) ? "$" + addCommas(e) : "";
            },
        },
        {
            title: <small>Titles</small>, dataIndex: 'number_of_titles', key: 'number_of_titles', render: (e, f) => {
                return <a onClick={() => expandCell(f)}>{e} {pluralize(e, "Title", "s")}</a>;
            },
        },
        {
            title: <small>Ref#</small>, dataIndex: 'bookorder_id', key: 'bookorder_id', render: (e) => {
                return <Overlay component={<PreviousOrder bookorder_id={e} />}><a>{e}</a></Overlay>
            }
        },
        { title: <small>Cust PO#</small>, dataIndex: 'customer_purchase_order', key: 'customer_purchase_order' },

        {
            title: <small>Customer</small>, className: "cellBorder", ellipsis: true, dataIndex: 'name', key: 'name'
        },

        {
            title: <small>Status</small>, className: "cellBorder", dataIndex: 'ignore_status', "width": "50px", key: 'ignore_status', render: (e, f) => {
                return (
                    <OrderStatus show_x bookorder_id={f.bookorder_id} ignore_status={e} />
                )
            }
        },


        {
            title: <small>Notes</small>, className: "cellBorder", dataIndex: 'store_note', ellipsis: true, key: 'store_note', render: (e, f) => {
                return <Notes bookorder_id={f.bookorder_id} note={e} />
            },
        },

        {
            title: <Overlay width={250} component={<ExcelOptions />}><a><small>XLS</small></a></Overlay>, className: "cellBorder", dataIndex: 'store_note', width: "42px", key: 'store_note', render: (e, f) => {
                return <Button onClick={() => downloadSingle(f.bookorder_id)} icon={<FileExcelOutlined />} type="link" size="small"></Button>
            },
        },

        {
            dataIndex: 'actions', key: 'actions', width: "70px", title: <small></small>, dataIndex: 'billing', key: 'billing', render: (e, f) => {
                return (<ResendOrder bookorder_id={f.bookorder_id} />)
            }
        },


    ]

    const drawActions = () => {

        return (<Space><Button type="primary" size="small"><small>Resend</small></Button><Button icon={<FileExcelOutlined />} type="link" size="small"></Button> </Space>)
    }

    return (<>

        {((filters.auto_open && filters.customer_search) && <Overlay callback={() => setFilters({ ...filters, "auto_open": false })} auto_open component={<PreviousOrder bookorder_id={filters.customer_search} />}></Overlay>)}

        <Paginate paginate={paginate} setPaginate={setPaginate} count={results.row_count} />
        <Table
            expandable expandIcon={<></>} size="small"
            columns={bookorder_columns}
            className={"tableNoBorder"}
            dataSource={results.rows}
            loading={loading}
            pagination={false}
            expandIconColumnIndex={-1}
            expandedRowKeys={expanded}
            onExpand={(e) => { return false; }}
            expandedRowRender={((record) => drawChildTable(record))}
        />
        <Paginate paginate={paginate} setPaginate={setPaginate} count={results.row_count} />
    </>
    );
}