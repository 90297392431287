/*

Default Layout - Wraps sections with nav, footer etc

*/

import React, { useEffect } from "react";
import { Layout, Breadcrumb, BackTop, Button, Alert, Result, Modal } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import Nav2 from "../components/Nav2";
import { useHistory, useLocation } from "react-router-dom";
import { ucfirst } from "../utils/Utils";
import { ArrowUpOutlined } from '@ant-design/icons';
import { navigation } from "../data.js";
import { useSession } from "../utils/Session";
export default function Default(props) {

    const { drawDarkMode = () => { } } = props;

    const loc = useLocation();
    const history = useHistory();
    const [session, setSession] = useSession();

    const hasBanner = (window.sitesettings.banner_message.text || !window.sitesettings.is_v3) ? true: false;

    const checkResolution = () => {

        if (window.innerWidth < 500) {

            Modal.confirm({
                title: "Set scale",
                onOk: () => {
                    let scale = window.innerWidth / 1300;
                    document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale=' + 0.5 + ', maximum-scale=1.0, user-scalable=0');
                }
            })
        }

    }
    //useEffect(checkResolution, [])



    const checkPermission = () => {
        let has_permission = true;
        Object.keys(navigation).forEach(menu => {
            let { subnav = [] } = navigation[menu];
            subnav.forEach(item => {
                if (item.hasOwnProperty("permission")) {
                    if (loc.pathname.includes(item.slug)) {

                        if (session.hasOwnProperty(item.permission)) {
                            if (!session[item.permission]) has_permission = false;
                        }

                        if (session.permissions.hasOwnProperty(item.permission)) {
                            if (!session.permissions[item.permission]) has_permission = false;
                        }

                    }
                }
            })
        })
        return has_permission;
    }

    const drawPath = (_p) => {

        if (_p.includes("/filter")) {
            // cull filters from breadcrumbs
            _p = _p.split("/filter")[0];
        }

        let items = _p.split("/");
        let title = "";
        items.forEach((element, index) => {
            if (index === 1) {
                title += ucfirst(element) + " > ";
            } else {
                title += ucfirst(element) + " ";
            }
        });

        document.title = title;

        return items.map((itm, ind) => {
            if (itm === "") {
                return <Breadcrumb.Item key={ind}></Breadcrumb.Item>
            } else {
                return <Breadcrumb.Item key={ind}>{ucfirst(itm)}</Breadcrumb.Item>
            }
        })
    }


    const drawNoPermission = () => {

        let ret = <small>Your login does not have permission to access this area. Please login with a different account that has access.</small>
        if (session.permissions.edit) {
            ret = <small>Your login does not have permission to access this area. Please login with a different account that has access, or go to the <a onClick={() => history.push("/settings/permissions")}>Settings → Permissions</a> menu to modify which accounts have access to specific areas.</small>
        }

        return (
            <>
                <Result

                    icon={<LockOutlined />}
                    title={ret}
                    extra={
                        <Button onClick={() => history.push("/")} type="primary" key="console">
                            Home
                        </Button>
                    }
                />
            </>
        )
    }



    const drawMessage = () => {

        

        if (window.sitesettings?.banner_message) {
            if (window.sitesettings.banner_message.text) {

                let { type = "success", link = "", text = "" } = window.sitesettings.banner_message;

                let _color = "#f00";
                let _txt = "#fff";
                switch (type) {
                    case "warning":
                        _color = "#ffa200";
                        _txt = "#000";
                        break;
                    case "error":
                        _color = "#e00000";
                        break;
                    case "info":
                        _color = "#1465c8";
                        break;
                    default:
                        _color = "#2eb000";
                        break;
                }

                

                return (
                    <Alert
                        onClick={() => { if (link) { window.open(link) } }}
                        className="switchbanner"
                        message={<div style={{ "textAlign": "center" }}>
                            <small style={{ "color": _txt }}>{text}</small>
                        </div>}
                        banner
                        style={{ "cursor": (link) ? "pointer" : "default", "color": "#fff", "backgroundColor": _color, "borderLeft": "0px", "borderRight": "0px", "position": "fixed", "zIndex": "1000", "height": "30px", "width": "100%" }}
                        showIcon={false}

                        type={type}
                    />
                )
            }
        }

        return (
            <>
                <Alert
                    className="switchbanner"
                    message={<div style={{ "textAlign": "center" }}>
                        <small>The new Webstore Designer is here! Check it out under the Content menu, or <a onClick={() => window.open("/create", "_blank")}>click here</a>.</small>
                    </div>}
                    banner
                    style={{ "borderLeft": "0px", "borderRight": "0px", "position": "fixed", "zIndex": "1000", "height": "30px", "width": "100%", "backgroundColor" : "#EB91C5", "border-color" : "#EB0A8C" }}
                    showIcon={false}
                    type="success"
                />
            </>
        )

    }




    return (
        <>
            {drawDarkMode()}
            <BackTop style={{ "right": "0px", "bottom": "10px" }}>
                <Button size="small" shape="circle" icon={<ArrowUpOutlined />} />
            </BackTop>

            {(hasBanner && <>{drawMessage()}</>)}

            <div className="navWrapper" style={{top : (hasBanner) ? "30px" : "0px"}}>
                <div style={{ "width": "1240px", "margin": "0 auto" }}>

                    <Nav2 dark={props.dark} />
                </div>
            </div>
            <div style={{ "height": (hasBanner) ? "85px" : "55px", "display": "block" }} />
            <Layout>
                <Layout.Content>
                    <div style={{ "height": "calc(100vh - 85px)", "minWidth": "1310px", "maxWidth": "1310px", "margin": "0 auto", "padding": "0 35px" }}>
                        <div className="shim" /><div className="shim" />
                        <Breadcrumb style={{ "fontSize": "12px", "fontWeight": "500", "padding": "5px 15px" }}>
                            {drawPath(loc.pathname)}
                        </Breadcrumb>
                        <div className="shim" /><div className="shim" />
                        <div className={(props.noborder) ? "" : "layoutWrapper"} style={{ "marginBottom": "20px" }}>

                            {(checkPermission()) ? props.children : drawNoPermission()}

                        </div>
                        <div style={{ "fontSize": "12px", "fontWeight": "500", "padding": "5px 15px" }}></div>
                    </div>
                </Layout.Content>
            </Layout>

        </>
    );

}