import { Popconfirm } from "antd";
import React from "react";
import { apiCall } from "../../utils/Api";

export default function EventToggle(props) {

    const { event = {}, enabled = 0, updateRow = () => { } } = props;

    const toggle = () => {

        let _f = {...event};
        _f.enabled = !enabled; 

        _f["tickets"] = (_f["tickets"]) ? JSON.stringify(_f["tickets"]) : "";
        _f["rsvp"] = (_f["rsvp"]) ? JSON.stringify(_f["rsvp"]) : "";
        _f["books"] = (_f["books"]) ? JSON.stringify(_f["books"].map(item => item.isbn)) : "";
 
        apiCall("events/v2/edit", _f, (_status, _result) => {
            if (_status) {
                updateRow(_result);
            }
        })
    }

    return (<Popconfirm onConfirm={() => toggle()} title={(enabled) ? "Make event hidden?" : "Make event visible?"}>{props.children}</Popconfirm>)



}