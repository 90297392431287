import { Badge, Button, Divider, Form, Input, Modal, Popover, Select, Space, Table, Tag, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { apiCall } from "../../utils/Api";
import { EditOutlined, DownOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import ColorPicker from "rc-color-picker";
import 'rc-color-picker/assets/index.css';
import { useForm } from "antd/lib/form/Form";
export default function EventCategorySelector(props) {

    const { onChange = () => { }, categories = [], setCategories = () => { } } = props;

    const [visible, setVisible] = useState(false);
    const [tableVisibe, setTableVisible] = useState(false)
    const [category, setCategory] = useState(props.category);

    const [form] = Form.useForm();

    const colours = [
        { name: 'Grey', hex: '#333333' },
        { name: 'Blue', hex: '#2f54eb' },
        { name: 'Red', hex: '#FF0000' },
        { name: 'Green', hex: '#008000' },
        { name: 'Orange', hex: '#FFA500' },
        { name: 'Purple', hex: '#800080' },
        { name: 'Magenta', hex: '#FF00FF' },
        { name: 'Volcano', hex: '#D2691E' },
        { name: 'Gold', hex: '#FFD700' },
    ]

    const [c, setC] = useState("#2f54eb");
    const EditCat = (props) => {

        const { data = {} } = props;
        const { colour = "#000000", name = "" } = data;
        const [c, setC] = useState(colour)

        const updateCat = (_f) => {
            editCategory({ ...data, "name": _f.name, "colour": _f.colour })
        }

        const [f] = useForm(); 

        return (
            <>
                <Form form={f} onFinish={(_f) => updateCat(_f)}>
                    <Space>
                        <Form.Item hidden name="colour">
                            <Input />
                        </Form.Item>
                        <div style={{"marginTop" : "8px"}} className="cat-color-picker"><ColorPicker defaultColor={c} onChange={(e) => { setC(e.color); f.setFieldsValue({ colour: e.color }); }} /></div>
                        <Form.Item noStyle name="name" initialValue={name} >
                            <Input style={{ "width": "100%" }} />
                        </Form.Item>
                        <Button type="primary" htmlType="submit"><small>Save</small></Button>
                    </Space>
                </Form>
            </>
        )
    }


    const columns = [

        {
            title: <small></small>, width: 300, dataIndex: "colour", render: (e, f) => {
                return <EditCat data={f} />
            }
        },

        // { title : <small></small>, width : 30, dataIndex : "colour", render : (e) => {

        //     return(
        //         <Select defaultValue={e}>
        //             {colours.map(item => {
        //                 <Select.Option key={item.hex} value={item.hex}><Badge style={{ "backgroundColor": e, "boxShadow" : "none" }} dot /></Select.Option>
        //             })}
        //         </Select>
        //     )
        //     return <Badge style={{ "backgroundColor": e, "boxShadow" : "none" }} dot />



        // } },
        // { title : <small>Name</small>,  width : 40, dataIndex : "name", render : (e, f) => {
        //     return <Input.Search onSearch={(_v) => editCategory({...f, "name" : _v})} enterButton={<small>Save</small>} style={{"width" : "100%"}} defaultValue={e} />
        // } },

        {
            title: <small></small>, width: 40, dataIndex: "actions", render: (e, f) => {
                return <div style={{ "textAlign": "left" }}><Button onClick={() => removeCategory(f.id)} size="small" danger type="text"><DeleteOutlined /></Button></div>
            }
        },


    ];

    const listCategories = (_id = false) => {
        apiCall("events/v2/listCategory", {}, (_status, _result) => {
            if (_status) {
                setCategories(_result)
                if (_id) {
                    onChange(_id);
                    setCategory(_id);
                    setVisible(false)
                }
            } else {
                message.error(_result.error)
            }
        })
    }

    useEffect(() => {
        listCategories()
    }, [])


    const removeCategory = (_id) => {
        apiCall("events/v2/removeCategory", { id: _id }, (_status, _result) => {
            if (_status) {
                if (_id === category) {
                    onChange("");
                    setCategory("");
                }
                listCategories();
            } else {
                message.error(_result.error);
            }
        })
    }

    const editCategory = (_f) => {
        apiCall("events/v2/editCategory", _f, (_status, _result) => {
            if (_status) {
                message.success("Category updated!");
                listCategories();
            } else {
                message.error(_result.error)
            }
        })
    }

    const addCategory = (_f) => {
        apiCall("events/v2/addCategory", _f, (_status, _result) => {
            if (_status) {
                listCategories(_result.id);

            } else {
                message.error(_result.error)
            }
        })
    }

    return (
        <>
            <Select dropdownRender={menu => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                        <Button onClick={() => setVisible(true)} style={{ "width": "85px" }} size='small' block type="primary" >
                            <small>New...</small>
                        </Button>
                        <Button onClick={() => setTableVisible(true)} style={{ "width": "85px" }} size='small' block type="primary" >
                            <small>Manage...</small>
                        </Button>
                    </Space>
                </>
            )}
                value={category} style={{ width: 193 }} onChange={(e) => {
                    if (e === "custom") {
                        setVisible(true)
                    } else {
                        onChange(e);
                        setCategory(e);
                    }
                }} placeholder="Select Time">
                <Select.Option key={"default"} value="" ><Badge style={{ "backgroundColor": "#333", "boxShadow": "none" }} dot /> &nbsp; Uncategorized</Select.Option>
                {categories.map(item => {
                    return (<Select.Option key={item.key} value={item.id} ><Badge style={{ "backgroundColor": item.colour, "boxShadow": "none" }} dot />  &nbsp; {item.name} </Select.Option>)
                })}
            </Select>
            {/* MANAGE */}
            <Modal destroyOnClose width={550} footer={null} onCancel={() => setTableVisible(false)} visible={tableVisibe}>
                <Typography.Title level={5}>Manage Categories</Typography.Title>
                <Divider dashed />
                <Table showHeader={false} scroll={{ y: 322 }} style={{ "border": "1px solid" }} pagination={false} className="bc" columns={columns} dataSource={categories} />

                <Divider dashed />
                <div className="float-flex">
                    <div></div>
                    <Button onClick={() => setTableVisible(false)}>Close</Button>
                </div>
            </Modal>

            {/* CREATE */}
            <Modal destroyOnClose width={250} footer={null} onCancel={() => setVisible(false)} visible={visible}>

                <Typography.Title level={5}>Create category</Typography.Title>
                <Form form={form} onFinish={(_f) => addCategory(_f)} layout="vertical">
                    <Divider dashed style={{ "margin": "15px 0px" }} />
                    <Form.Item rules={[{ required: true, message: "Name required." }]} name="name" label="Category Name">
                        <Input placeholder="Name..." />
                    </Form.Item>
                    <Form.Item hidden name="colour" label={<div className="float-flex"><span>Colour</span><span><a><small>Custom</small></a></span></div>}>

                    </Form.Item>
                    <Space>
                        <Form.Item label="Colour">
                            <div className="cat-color-picker"><ColorPicker defaultColor={"#2f54eb"} onChange={(e) => { setC(e.color); form.setFieldsValue({ colour: e.color }); }} /></div>
                        </Form.Item>
                        {/* <Tag color={c}>Category</Tag> */}
                    </Space>
                    <Divider dashed />
                    <div className="float-flex">
                        <div>&nbsp;</div>
                        <Space>
                            <Button onClick={() => setVisible(false)}>Cancel</Button>
                            <Button htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                </Form>


            </Modal>




        </>
    )

}