import React, { useState } from "react";
import { Typography, Divider, Button, Layout, Upload, Spin, Table, Form, Space, message, Input, Modal, Popconfirm } from "antd";
import { UploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";
import FilterPresetPicker from "./FilterPresetPicker";

export default function ImpluseManager(props) {

    const { onChange = () => { }, allowPresets = false, presets = [], preset_key = "", title = "" } = props;
    const [pending, setPending] = useState("");
    const titles = (props.titles) ? props.titles : []
    const [filters, setFilters] = useState((props.filters) ? props.filters : []);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [edit, setEdit] = useState(false);

    const columns = [
        { title: <small></small>, dataIndex: 'eisbn', key: 'eisbn', width: 50, render: (e) => { return <img className="lightshadow" onError={(e) => e.target.src = "/no-cover.png"} style={{ "maxHeight": "34px", "maxWidth": "30px" }} src={"https://bookmanager.com/i/slider_23.php?b=" + e} /> } },
        { title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn', width: 150 },
        { title: <small>Title</small>, dataIndex: 'title', key: 'title', width: 150 },
        {
            title: <small>Description</small>, dataIndex: 'description', key: 'description', render: (e, f) => {
                return (e) ? e : <Button size="small" onClick={(e) => setEdit(f)} type="link"><small>Add description</small></Button>
            }
        },
        {
            title: <small>Remove</small>, width: "80px", dataIndex: 'success', key: 'success', render: (e, f) => {
                return (
                    <Space>
                        <Button onClick={(e) => setEdit(f)} type="link" size="small"><small><EditOutlined /></small></Button>
                        <Popconfirm title="Are you sure?" onConfirm={() => removeISBN(f.isbn)} >
                            <Button type="link" size="small" danger  ><small><DeleteOutlined /></small></Button>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ];

    const updateDescription = (_f) => {
        let _titles = [...titles];
        let _ti = _titles.findIndex(item => item.isbn === _f.isbn);
        if (_ti > -1) {
            _titles[_ti].description = _f.description;
            onChange(_titles);
        }
    }

    const drawEditForm = () => {

        const { isbn = "", description = "" } = edit;

        return (
            <div>
                <Typography.Title level={5}>Edit description</Typography.Title>
                <Divider style={{ "margin": "15px 0px" }} />
                <Form layout="vertical" onFinish={(_f) => updateDescription(_f)}>
                    <Form.Item max={50} initialValue={isbn} hidden name="isbn">
                        <Input.TextArea showCount maxLength={100} placeholder="Description...." />
                    </Form.Item>
                    <Form.Item max={50} initialValue={description} label="Description" name="description">
                        <Input.TextArea showCount maxLength={100} placeholder="Description...." />
                    </Form.Item>
                    <Divider dashed></Divider>
                    <div style={{ "float": "right" }} >
                        <Space>
                            <Button onClick={() => setEdit(false)}>Cancel</Button>
                            <Button type="primary" htmlType="submit" >Save</Button>
                        </Space>
                    </div>
                    <br clear="both" />
                </Form>
            </div>
        )
    }

    const removeISBN = (_isbn) => {
        let _titles = [...titles.filter(item => item.isbn !== _isbn)]
        onChange(_titles);
    }

    const addISBN = (_isbn) => {

        if (!_isbn) {
            return;
        }

        let _eisbn = "";
        let _title = "";
        apiCall("title/getItem", { isbn: _isbn }, (_s, _r) => {
            if (_r.hasOwnProperty("eisbn") && _r.hasOwnProperty("title")) {
                _eisbn = _r.eisbn;
                _title = _r.title;
            }
            let _titles = [...titles.filter(item => item.isbn !== _isbn)];
            _titles.push({
                isbn: _isbn,
                eisbn: _eisbn,
                title: _title
            });
            setPending("");
            onChange(_titles);
        })
    }

    const [loading, setLoading] = useState(false);
    const [uploaded, setUploaded] = useState([])

    const uploadFile = (_file) => {
        setLoading(true);
        let obj = {}
        obj.file = _file.file;
        apiCall("titlelist/parseFile", obj, (_status, _result) => {
            if (_status) {
                setUploaded(_result.titles);
                setFileUploaded(true);
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        });
    }

    const closeModal = () => {
        setUploaded([]);
        setModal(false);
        setFileUploaded(false);
    }

    const addImportToList = (_titles) => {

        let _current = [...titles];
        let _currentTotal = _current.length;
        _titles.forEach(element => {
            let _find = _current.find(item => item.isbn === element.isbn);
            if (!_find) {
                _current.push({
                    isbn: element.isbn,
                    title: element.title,
                    eisbn: element.eisbn,
                    description: ""
                })
            }
        });

        if (_currentTotal === _current.length) {
            setFileUploaded(false);
            setModal(false);
            setUploaded([]);
            message.error("No titles added");
        } else {
            onChange(_current);
            setFileUploaded(false);
            setModal(false);
            setUploaded([]);
            let _count = _current.length - _currentTotal;
            message.success(_count.toString() + " titles added");
        }

    }

    const [modal, setModal] = useState(false);

    const showInstructions = () => {
        return (
            <>
                <Layout className="layout">
                    <Layout.Content style={{ "padding": "0px", "width": "auto" }} >
                        <div style={{ "padding": "30px" }}>
                            <Typography.Title level={5}>Choose File... </Typography.Title>
                            <Divider style={{ "margin": "15px 0px" }} />
                            {(!fileUploaded && <>
                                <Upload.Dragger accept=".txt, .pdf, .xls, .xlsx, .html, .csv" showUploadList={false} customRequest={(e) => uploadFile(e, false)} style={{ "padding": "0px 20px", "height": "300px" }}>
                                    <div >
                                        {(loading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                                    </div>
                                    <p className="ant-upload-text">Click or drag to upload</p>
                                    <p className="ant-upload-hint">
                                        Upload an Excel file (.xls or .xlsx), Text file (.txt) <br />or a tab-delimited file (.txt).
                                    </p>
                                </Upload.Dragger>
                                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                                <div style={{ "float": "right" }}><Button onClick={() => setModal(false)}>Cancel</Button></div>
                                <br clear="all" />
                            </>)}
                            {(fileUploaded && <>
                                <div>
                                    <Table locale={{ emptyText: "No titles" }} scroll={{ y: 255 }} className="d" pagination={false} columns={columns.filter(item => item.key !== "success")} dataSource={uploaded} size="small"></Table>
                                    <div className="shim" /><div className="shim" />
                                    <div style={{ "float": "right" }}>
                                        <Space>
                                            <Button onClick={() => closeModal()}>Cancel</Button>
                                            <Button disabled={!(uploaded.length > 0)} type="primary" onClick={() => addImportToList(uploaded)}>Add</Button>
                                        </Space>
                                    </div>
                                    <br clear="all" />
                                </div>
                            </>)}
                        </div>
                    </Layout.Content>
                </Layout>
            </>)
    }

    const filterCallback = (_presets) => {
        setFilters(_presets);
    }

    return (
        <>
            <Modal footer={null} bodyStyle={{ "padding": "0px" }} visible={modal} onCancel={() => setModal(false)}>
                {showInstructions()}
            </Modal>
            <Modal footer={null} visible={edit} onCancel={() => setEdit(false)} >
                {drawEditForm()}
            </Modal>

            {(allowPresets && <>
                <Form.Item noStyle name={preset_key} initialValue={filters} />
                <label>{title} Filter Presets</label>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <FilterPresetPicker callback={(e) => filterCallback(e)} multiple={true} filters={filters} presets={presets} />
            </>)}

            <label>{title} ISBNS</label>
            <div className="shim" />
            <Input.Search onSearch={(e) => addISBN(e)} size="middle" enterButton={<small>Add ISBN</small>} placeholder="Add ISBN" onChange={(e) => setPending(e.target.value)} value={pending} />
            <div className="shim" /><div className="shim" /><div className="shim" />
            <Table locale={{ emptyText: "No titles" }} scroll={{ y: 255 }} className="tableBorder" pagination={false} columns={columns} dataSource={titles} size="small"></Table>
            <div className="shim" />
            <div style={{ "float": "right" }}><Button onClick={() => setModal(true)} size="small" type="primary" icon={<UploadOutlined />}><small> Upload</small></Button></div>
        </>
    );
}