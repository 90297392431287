import React, { useState, useEffect } from "react";
import { Layout, Typography, Divider, Table, Space, Button, DatePicker, Form, Card, Row, Col, Statistic, Tooltip, Alert } from "antd";
import { FilePdfOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import { apiCall, apiDownload } from "../utils/Api";
import { addCommas, bmDate } from "../utils/Utils";
import conditional from "../utils/conditional";
import LayoutHeader from "../layouts/partials/LayoutHeader";

export default function GiftcardActivityPage() {

    const [filters, setFilters] = useState({ start_date: moment().add(-1, "year"), end_date: moment() })
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState({
        years: [],
        totals: {
            "active": 0,
            "transactions": 0,
            "under_count": 0,
            "over_count": 0,
            "over_dollars": "0",
            "issued_dollars": "0",
            "redeemed_todate_dollars": "0",
            "redeemed_year_dollars": "0",
            "outstanding_dollars": "0",
            "avg_over_dollars": "0",
            "redeemed_todate_percent": "0",
            "outstanding_percent": "0",
            "redeemed_year_percent": "0"
        },
        liability: {
            "issued_to_date": "0",
            "redeemed_to_date": "0",
            "issued_in_range": "0",
            "redeemed_in_range": "0",
            "issued_before_range": 0,
            "outstanding_cards": 0,
            "redeemed_before_range": 0,
            "min_activity_ts": "",
            "max_redeem_ts": ""
        }
    });

    const getBillingReport = () => {
        apiDownload({ start_ts: filters.start_date.unix(), end_ts: filters.end_date.unix() }, "giftcard/getBillingReport");
    }

    const getLoadReport = () => {
        apiDownload({ start_ts: filters.start_date.unix(), end_ts: filters.end_date.unix() }, "giftcard/getLoadReport")
    }

    const columns = [
        {},
        { title: <small>Year</small>, dataIndex: 'year', key: 'year' },
        { title: <div className="ar"><small>Active cards</small></div>, dataIndex: 'active', key: 'active', render: (e) => (!e) ? "" : <div className="ar">{addCommas(e)}</div> },
        { title: <div className="ar"><small>Transactions</small></div>, dataIndex: 'transactions', key: 'transactions', render: (e) => (!e) ? "" : <div className="ar">{addCommas(e)}</div> },
        { title: <div className="ar"><small>Loads &lt;= $10</small></div>, dataIndex: 'under_count', key: 'under_count', render: (e) => (!e) ? "" : <div className="ar">{addCommas(e)}</div> },
        { title: <div className="ar"><small>Loads &gt; $10</small></div>, dataIndex: 'over_count', key: 'over_count', render: (e) => (!e) ? "" : <div className="ar">{addCommas(e)}</div> },
        { title: <div className="ar"><small>Avg $10+ loads</small></div>, dataIndex: 'avg_over_dollars', key: 'avg_over_dollars', render: (e) => (!e) ? "" : <div className="ar">${e}</div> },
        { title: <div className="ar"><small>Issued</small></div>, dataIndex: 'issued_dollars', key: 'issued_dollars', render: (e) => (!e) ? "" : <div className="ar"><strong>${addCommas(e)}</strong></div> },
        { title: <div className="ar"><small>Redeemed to date</small></div>, dataIndex: 'redeemed_todate_dollars', key: 'redeemed_todate_dollars', render: (e) => (!e) ? "" : <div style={{ "color": "#f12a46" }} className="ar">${addCommas(e)}</div> },
        { title: <div className="ar"><small>% of issued</small></div>, dataIndex: 'redeemed_todate_percent', key: 'redeemed_todate_percent', render: (e) => (!e) ? "" : <div className="ar">{e}%</div> },
        { title: <div className="ar"><small>Outstanding</small></div>, dataIndex: 'outstanding_dollars', key: 'outstanding_dollars', render: (e) => (!e) ? "" : <div className="ar">${addCommas(e)}</div> },
        { title: <div className="ar"><small>% of issued</small></div>, dataIndex: 'outstanding_percent', key: 'outstanding_percent', render: (e) => (!e) ? "" : <div className="ar">{e}%</div> },
        { title: <div className="ar"><small>Redeemed in past year</small></div>, dataIndex: 'redeemed_year_dollars', key: 'redeemed_year_dollars', render: (e) => (!e) ? "" : <div className="ar">${addCommas(e)}</div> },
        { title: <div className="ar"><small>% of issued</small></div>, dataIndex: 'redeemed_year_percent', key: 'redeemed_year_percent', render: (e) => (!e) ? "" : <div className="ar">{e}%</div> },
        {},
    ]

    const getActivity = () => {
        setLoading(true)
        apiCall("giftcard/getActivity", {}, (_status, _result) => {
            if (_status) {
                setResults(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getActivity, []);

    const getData = () => {

        let _data = [...results.years];
        _data.reverse();
        _data.push({});
        let _totals = { ...results.totals };
        _totals.year = "Totals:"
        _data.push(_totals);

        return _data;
    }

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Gift Card Activity"
                    filters={<><Form>
                        <Space wrap size={[5, 0]}>
                            <Form.Item initialValue={[filters.start_date, filters.end_date]} name="range" label="Date and Time" noStyle>
                                <DatePicker.RangePicker onChange={(e) => setFilters({ ...filters, "start_date": e[0], "end_date": e[1] })} className="inputsmall" size="small" style={{ "width": "100%" }} format="MMM Do YYYY" />
                            </Form.Item>
                            <Button onClick={() => getBillingReport()} size="small" type="primary"><small><FilePdfOutlined /> Billing report</small></Button>
                            <Button onClick={() => getLoadReport()} size="small" type="primary"><small><FilePdfOutlined /> Loaded cards</small></Button>
                        </Space>
                    </Form><br /></>}
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <Typography.Title level={5}>History</Typography.Title>
                        <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                            <Table
                                loading={loading}
                                size="small"
                                header={<>Header</>}
                                pagination={false}
                                columns={columns}
                                dataSource={getData()}
                            />
                        </Card>
                        <br />
                        <conditional.true value={(!loading && results.years.length > 0)}>

                            {(results.liability.issued_in_range === "0.00" &&
                                <><Alert message={"We need minimum three years worth of gift card activity to provide usage predictions. Please contact Bookmanager support if you need guidance on manually assessing the predicted redemptions for gift cards with balances."} /><br /></>
                            )}
                            <Space size={16} align="start" wrap>
                                <div style={{ "width": "398px" }}>
                                    <Typography.Title level={5}>Summary</Typography.Title>
                                    <Card className="bc c" bodyStyle={{ "padding": "10px 20px", "minHeight": "85px" }}>
                                        <div><span className="mid">${addCommas(results.totals.issued_dollars)}</span> loaded on <span className="mid">{addCommas(results.totals.active)}</span> cards since inception</div>
                                        <div><span className="mid">${addCommas(results.totals.outstanding_dollars)}</span> outstanding on <span className="mid">{addCommas(results.liability.outstanding_cards)}</span> cards (<span className="mid">${(results.totals.outstanding_dollars / results.liability.outstanding_cards || 0).toFixed(2)}</span> per card)</div>
                                    </Card>
                                </div>

                                {(results.liability.issued_in_range !== "0.00" &&
                                    <div style={{ "width": "768px" }}>
                                        <Typography.Title level={5}>Predictions </Typography.Title>
                                        <Card className="bc c" bodyStyle={{ "padding": "10px 20px", "minHeight": "85px" }}>
                                            <div>Between <span className="mid">{bmDate(results.liability.min_activity_ts * 1000)} </span><sup style={{ "color": "#aaa", "cursor": "pointer" }}><Tooltip title="Date of earliest card activity with Bookmanager"><InfoCircleOutlined /></Tooltip></sup> and <span className="mid">{bmDate(results.liability.max_redeem_ts * 1000)}</span>, <sup style={{ "color": "#aaa", "cursor": "pointer" }}><Tooltip title="3 years before today's date"><InfoCircleOutlined /></Tooltip></sup> <span className="mid">${addCommas(results.liability.issued_in_range)}</span> was issued and <span className="mid">${addCommas(results.liability.redeemed_in_range)}</span> of that was redeemed (<span className="mid">{Math.round((results.liability.redeemed_in_range / results.liability.issued_in_range) * 100) || 0}%</span>)</div>
                                            <div>It is expected that of the <span className="mid">${addCommas(results.liability.issued_to_date)}</span> issued since <span className="mid">{bmDate(results.liability.min_activity_ts * 1000)} </span>, <span className="mid">${addCommas(Math.round(results.liability.issued_to_date * (results.liability.redeemed_in_range / results.liability.issued_in_range)) || 0)}</span> or <span className="mid">{Math.round((results.liability.redeemed_in_range / results.liability.issued_in_range) * 100) || 0}%</span> of that will eventually be redeemed.</div>
                                            <div>For the cards issued after <span className="mid">{bmDate(results.liability.min_activity_ts * 1000)} </span>, <span className="mid">${addCommas(results.liability.redeemed_to_date)}</span> has been redeemed, leaving <span className="mid">${addCommas(Math.round(results.liability.issued_to_date * (results.liability.redeemed_in_range / results.liability.issued_in_range) - results.liability.redeemed_to_date) || 0)}</span> still expected.</div>
                                        </Card>
                                    </div>
                                )}

                                {(results.liability.issued_in_range !== "0.00" &&
                                    <div style={{ "width": "398px" }}>
                                        <Typography.Title level={5}>Prediction Summary</Typography.Title>
                                        <Card className="bc c" style={{ width: "400px" }} bodyStyle={{ "padding": "10px 20px", "minHeight": "135px" }}>
                                            <div><span className="mid">${addCommas(Math.round(results.liability.issued_to_date))}</span> (<span className="mid">100%</span>) has been issued since <span className="mid">{bmDate(results.liability.min_activity_ts * 1000)} </span></div>
                                            <div><span className="mid">${addCommas(Math.round(results.liability.redeemed_to_date))}</span> (<span className="mid">{Math.round((results.liability.redeemed_to_date / results.liability.issued_to_date || 0) * 100)}%</span>) of this has been redeemed</div>
                                            <div><span className="mid">${addCommas(Math.round(results.liability.issued_to_date * ((100 - Math.round((results.liability.redeemed_in_range / results.liability.issued_in_range || 0) * 100)) / 100)))}</span> (<span className="mid">{100 - Math.round((results.liability.redeemed_in_range / results.liability.issued_in_range || 0) * 100)}%</span>) of this is likely never to be redeemed</div>
                                            <div><span className="mid">${addCommas(Math.round(results.liability.issued_to_date * (results.liability.redeemed_in_range / results.liability.issued_in_range || 0) - results.liability.redeemed_to_date))}</span> (<span className="mid">{Math.round(((results.liability.issued_to_date * (results.liability.redeemed_in_range / results.liability.issued_in_range || 0) - results.liability.redeemed_to_date) / results.liability.issued_to_date || 0) * 100)}%</span>) of this is expected to be redeemed (this is your expected giftcard liability)</div>
                                        </Card>
                                    </div>
                                )}
                                <div style={{ "width": "768px" }}>
                                    <Typography.Title level={5}>Disclaimer</Typography.Title>
                                    <Card className="bc c" bodyStyle={{ "padding": "10px 20px", "minHeight": "135px" }}>
                                        <div>These figures are provided to assist in estimating your liability and profit from unredeemed cards. However, the formulas used are highly sensitive to extraordinary circumstances and changes in your business practices over time. As such, these predictions could be grossly inaccurate and unsuitable for business valuation. Use them at your own risk.</div>
                                    </Card>
                                </div>
                            </Space>

                        </conditional.true>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}