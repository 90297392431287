import { Button, Card, Modal, Space } from "antd";
import React, { useState } from "react";
import { useSession } from "../utils/Session";
import { SelectOutlined, DeleteOutlined } from '@ant-design/icons';
import FileBrowser from "./FileBrowser";
import conditional from "./conditional";
export default function ImageSelect(props) {

    const { wrap = false, onChange = () => { } } = props;
    const [visible, setVisible] = useState(false);
    const [path, setPath] = useState({ path: (props.path) ? props.path : "" });
    const [session, setSession] = useSession();


    const selectImage = (_image) => {

        if (_image) {
            setPath({ path: "/i/" + session.webstore_name + _image.path });
            onChange("/i/" + session.webstore_name + _image.path);
        } else {
            setPath({ path: "" });
            onChange("");
        }

        setVisible(false);
    }

    return (
        <>
            <Modal title="Media Gallery" footer={null} width={1000} visible={visible} onCancel={() => setVisible(false)}>
                <FileBrowser selectImage={selectImage} />
            </Modal>

            <conditional.true value={(wrap)}>
                <span onClick={() => setVisible(true)}>{props.children}</span>
            </conditional.true>

            <conditional.true value={!wrap}>
                <>
                    <Space>
                        <Card bodyStyle={{ "padding": "5px" }} className="img-pattern" >
                            <div style={{ "minHeight": "50px", "width": "190px", "height": "100px", "display": "flex", "alignContent": "center", "cursor": "pointer", "backgroundRepeat": "no-repeat", "backgroundPosition": "center", "backgroundSize": "contain", "backgroundImage": (path.path) ? "url('https://cdn1.bookmanager.com/" + path.path + "')" : "none" }}>
                                <div>
                                    <div className={(path.path) ? "img-select-hover" : ""} style={{ "position": "absolute", "width": "190px", "height": "100px", "textAlign": "center", "padding": "15px" }}>
                                        <div style={{ "marginTop": "20px", "color": "#aaa" }}>
                                            <Space size={20}>
                                                <div onClick={() => setVisible(true)}><SelectOutlined />&nbsp;Select</div>
                                                {(path.path && <div onClick={() => selectImage("")} style={{ "color": "#bb1717" }}>&nbsp;<DeleteOutlined /> Remove</div>)}
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Space>
                    <div className="shim" />
                </>
            </conditional.true>
        </>
    )
}