import React from 'react';
import Icon from '@ant-design/icons';

// const BMIconSVG = () => (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="M160-160q-33 0-56.5-23.5T80-240v-120h80v120h640v-480H160v120H80v-120q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm300-140-56-58 83-82H80v-80h407l-83-82 56-58 180 180-180 180Z"/></svg>);
// export const BMIconText = props => <Icon component={BMIconSVG} {...props} />

const BMIconDragSVG = () => (<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M360-200q-16.5 0-28.25-11.75T320-240q0-16.5 11.75-28.25T360-280q16.5 0 28.25 11.75T400-240q0 16.5-11.75 28.25T360-200Zm240 0q-16.5 0-28.25-11.75T560-240q0-16.5 11.75-28.25T600-280q16.5 0 28.25 11.75T640-240q0 16.5-11.75 28.25T600-200ZM360-440q-16.5 0-28.25-11.75T320-480q0-16.5 11.75-28.25T360-520q16.5 0 28.25 11.75T400-480q0 16.5-11.75 28.25T360-440Zm240 0q-16.5 0-28.25-11.75T560-480q0-16.5 11.75-28.25T600-520q16.5 0 28.25 11.75T640-480q0 16.5-11.75 28.25T600-440ZM360-680q-16.5 0-28.25-11.75T320-720q0-16.5 11.75-28.25T360-760q16.5 0 28.25 11.75T400-720q0 16.5-11.75 28.25T360-680Zm240 0q-16.5 0-28.25-11.75T560-720q0-16.5 11.75-28.25T600-760q16.5 0 28.25 11.75T640-720q0 16.5-11.75 28.25T600-680Z"/></svg>);
export const BMIconDrag = props => <Icon component={BMIconDragSVG} {...props} />

const BMIconTextSVG = () => (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M308.924-212.924v-480h-192v-54.152h438.152v54.152h-192v480h-54.152Zm360 0v-288h-120v-54.152h294.152v54.152h-120v288h-54.152Z"/></svg>);
export const BMIconText = props => <Icon component={BMIconTextSVG} {...props} />

const BMIconEmailSVG = () => (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M480.392-124.078q-73.62 0-138.459-27.825-64.839-27.824-113.568-76.595-48.729-48.77-76.508-113.513-27.779-64.744-27.779-138.356 0-73.693 27.825-138.107 27.824-64.414 76.595-113.152 48.77-48.738 113.513-76.517 64.744-27.779 138.356-27.779 73.693 0 138.107 27.83t113.152 76.609q48.738 48.78 76.517 113.108 27.779 64.329 27.779 138.449v49.049q0 48.949-34.83 83.085-34.829 34.137-83.939 34.137-34.077 0-61-19.462-26.923-19.462-43.121-49.462-23.648 30.616-58.744 49.77-35.096 19.154-74.145 19.154-69.63 0-118.059-48.495t-48.429-118.076q0-69.581 48.495-117.85 48.495-48.269 118.076-48.269 69.581 0 117.85 48.429 48.269 48.429 48.269 117.835v49.234q0 28.623 21.215 48.927 21.215 20.305 49.593 20.305 28.379 0 49.594-20.305 21.215-20.304 21.215-48.927V-480q0-127.903-90.026-217.932-90.026-90.03-217.923-90.03-127.898 0-217.936 90.026-90.039 90.026-90.039 217.923 0 127.898 90.026 217.936 90.025 90.039 217.921 90.039h189.592v47.96H480.392Zm-.487-237.537q49.287 0 83.883-34.502 34.597-34.501 34.597-83.788t-34.501-83.883q-34.502-34.597-83.789-34.597t-83.883 34.501q-34.597 34.502-34.597 83.789t34.502 83.883q34.501 34.597 83.788 34.597Z"/></svg>);
export const BMIconEmail = props => <Icon component={BMIconEmailSVG} {...props} />

const BMIconPhoneSVG = () => (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M278.648-76.078q-25.054 0-42.619-17.566-17.566-17.566-17.566-42.608v-687.496q0-25.042 17.566-42.608 17.565-17.566 42.619-17.566h402.704q25.054 0 42.619 17.566 17.566 17.566 17.566 42.608v687.496q0 25.042-17.566 42.608-17.565 17.566-42.619 17.566H278.648Zm-12.225-107.576v47.307q0 4.616 3.846 8.462 3.847 3.847 8.463 3.847h402.536q4.616 0 8.463-3.847 3.846-3.846 3.846-8.462v-47.307H266.423Zm0-47.961h427.154v-496.77H266.423v496.77Zm0-544.731h427.154v-47.307q0-4.616-3.846-8.462-3.847-3.847-8.463-3.847H278.732q-4.616 0-8.463 3.847-3.846 3.846-3.846 8.462v47.307Zm0 0V-835.962v59.616Zm0 592.692V-124.038v-59.616Z"/></svg>);
export const BMIconPhone = props => <Icon component={BMIconPhoneSVG} {...props} />

const BMIconDateSVG = () => (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M231.448-124.078q-25.046 0-42.612-17.916-17.565-17.916-17.565-42.262v-497.103q0-24.347 17.565-42.262 17.566-17.916 42.703-17.916h91.424v-100.616h49.499v100.616h216.614v-100.616h47.961v100.616h91.424q25.137 0 42.703 17.916 17.565 17.915 17.565 42.262v497.103q0 24.346-17.565 42.262-17.566 17.916-42.612 17.916H231.448Zm.091-47.96h496.922q4.615 0 8.462-3.847 3.846-3.846 3.846-8.462v-329.729H219.231v329.729q0 4.616 3.846 8.462 3.847 3.847 8.462 3.847Zm-12.308-389.998h521.538v-119.232q0-4.616-3.846-8.463-3.847-3.846-8.462-3.846H231.539q-4.615 0-8.462 3.846-3.846 3.847-3.846 8.463v119.232Zm0 0v-131.541 131.541Zm261.032 180.305q-12.647 0-21.744-8.832-9.096-8.833-9.096-21.481 0-12.648 8.833-21.744t21.481-9.096q12.647 0 21.744 8.833 9.096 8.833 9.096 21.481 0 12.647-8.833 21.743-8.833 9.096-21.481 9.096Zm-156 0q-12.647 0-21.744-8.832-9.096-8.833-9.096-21.481 0-12.648 8.833-21.744t21.481-9.096q12.647 0 21.744 8.833 9.096 8.833 9.096 21.481 0 12.647-8.833 21.743-8.833 9.096-21.481 9.096Zm312 0q-12.647 0-21.744-8.832-9.096-8.833-9.096-21.481 0-12.648 8.833-21.744t21.481-9.096q12.647 0 21.744 8.833 9.096 8.833 9.096 21.481 0 12.647-8.833 21.743-8.833 9.096-21.481 9.096Zm-156 140.923q-12.647 0-21.744-8.833-9.096-8.833-9.096-21.48 0-12.648 8.833-21.744t21.481-9.096q12.647 0 21.744 8.833 9.096 8.833 9.096 21.481 0 12.647-8.833 21.743-8.833 9.096-21.481 9.096Zm-156 0q-12.647 0-21.744-8.833-9.096-8.833-9.096-21.48 0-12.648 8.833-21.744t21.481-9.096q12.647 0 21.744 8.833 9.096 8.833 9.096 21.481 0 12.647-8.833 21.743-8.833 9.096-21.481 9.096Zm312 0q-12.647 0-21.744-8.833-9.096-8.833-9.096-21.48 0-12.648 8.833-21.744t21.481-9.096q12.647 0 21.744 8.833 9.096 8.833 9.096 21.481 0 12.647-8.833 21.743-8.833 9.096-21.481 9.096Z"/></svg>);
export const BMIconDate = props => <Icon component={BMIconDateSVG} {...props} />

const BMIconSelectSVG = () => (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M324.076-267.231h451.577q4.616 0 8.462-3.846 3.847-3.847 3.847-8.462v-92.577H324.076v104.885ZM172.038-588.076h104.078v-104.693h-91.769q-4.616 0-8.462 3.846-3.847 3.847-3.847 8.462v92.385Zm0 168h104.078v-120.04H172.038v120.04Zm12.309 152.845h91.769v-104.885H172.038v92.577q0 4.615 3.847 8.462 3.846 3.846 8.462 3.846Zm139.729-152.845h463.886v-120.04H324.076v120.04Zm0-168h463.886v-92.385q0-4.615-3.847-8.462-3.846-3.846-8.462-3.846H324.076v104.693ZM184.263-219.271q-25.053 0-42.619-17.577-17.566-17.578-17.566-42.656V-680.82q0-25.078 17.566-42.494 17.566-17.415 42.619-17.415h591.474q25.053 0 42.619 17.577 17.566 17.578 17.566 42.656v401.316q0 25.078-17.566 42.494-17.566 17.415-42.619 17.415H184.263Z"/></svg>);
export const BMIconSelect = props => <Icon component={BMIconSelectSVG} {...props} />

const BMIconCheckboxSVG = () => (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m429.808-352.77 219.268-218.269-34.73-34.729L429.808-422.23l-83.385-82.384-34.73 34.729L429.808-352.77Zm-198.36 181.499q-25.046 0-42.612-17.565-17.565-17.566-17.565-42.612v-497.104q0-25.046 17.565-42.612 17.566-17.565 42.612-17.565h497.104q25.046 0 42.612 17.565 17.565 17.566 17.565 42.612v497.104q0 25.046-17.565 42.612-17.566 17.565-42.612 17.565H231.448Zm.091-47.96h496.922q4.615 0 8.462-3.846 3.846-3.847 3.846-8.462v-496.922q0-4.615-3.846-8.462-3.847-3.846-8.462-3.846H231.539q-4.615 0-8.462 3.846-3.846 3.847-3.846 8.462v496.922q0 4.615 3.846 8.462 3.847 3.846 8.462 3.846Zm-12.308-521.538v521.538-521.538Z"/></svg>);
export const BMIconCheckbox = props => <Icon component={BMIconCheckboxSVG} {...props} />

const BMIconRadioSVG = () => (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M480.226-313.655q69.581 0 117.85-48.495 48.269-48.495 48.269-118.076 0-69.581-48.495-117.85-48.495-48.269-118.076-48.269-69.581 0-117.85 48.495-48.269 48.495-48.269 118.076 0 69.581 48.495 117.85 48.495 48.269 118.076 48.269Zm.141 189.577q-73.427 0-138.341-27.825-64.914-27.824-113.652-76.595-48.738-48.77-76.517-113.513-27.779-64.744-27.779-138.356 0-73.693 27.825-138.107 27.824-64.414 76.595-113.152 48.77-48.738 113.513-76.517 64.744-27.779 138.356-27.779 73.693 0 138.107 27.825 64.414 27.824 113.152 76.595 48.738 48.77 76.517 113.28 27.779 64.509 27.779 137.855 0 73.427-27.825 138.341-27.824 64.914-76.595 113.652-48.77 48.738-113.28 76.517-64.509 27.779-137.855 27.779Zm-.38-47.96q127.898 0 217.936-90.026 90.039-90.026 90.039-217.923 0-127.898-90.026-217.936-90.026-90.039-217.923-90.039-127.898 0-217.936 90.026-90.039 90.026-90.039 217.923 0 127.898 90.026 217.936 90.026 90.039 217.923 90.039ZM480-480Z"/></svg>);
export const BMIconRadio = props => <Icon component={BMIconRadioSVG} {...props} />







