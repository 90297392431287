import React, { useEffect, useState } from "react";
import { Typography, Divider, Form, Button, Input, Space, Select, Checkbox, message, AutoComplete, Spin, Result, Alert, Popconfirm, Tabs } from "antd";
import ListUpload from "./ListUpload";
import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";
import FilterPresetSelect from "./FilterPresetSelect";

export default function ListEdit(props) {

    const { callback = () => { }, create = false, data = {}, close = () => { }, results = {}, setResults = () => { } } = props;

    const [saving, setSaving] = useState(false);
    const [cats, setCats] = useState({ categories: [], subcategories: [] })
    const [canSave, setCanSave] = useState(true);
    const [form] = Form.useForm()

    const {
        list_id = "",
        is_dynamic = false,
        site_content = false,
        category = "Uncategorized",
        subcategory = "",
        sort_by = "",
        name = "",
        description = "",
        onhand_first = false,
        hide_non_oh_oo = false,
        sort_by_reversed = false,
        filter_preset_id = "",
    } = data;

    const [sort, setSort] = useState(sort_by);
    const [isPreset, setIsPreset] = useState((filter_preset_id));
    const [dynamic, setDynamic] = useState(is_dynamic);
    const [isbns, setIsbns] = useState([]);
    const [loading, setLoading] = useState(!create);

    const getISBNs = () => {
        if (create) { return; }
        apiCall("titlelist/get", { list_id: list_id }, (_status, _result) => {
            if (_status) {
                setIsbns(_result.isbns);
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }
    useEffect(getISBNs, []);

    const getCats = () => {
        apiCall("titlelist/getCurrentCategories", {}, (_status, _result) => {
            if (_status) {
                setCats(_result);
            }
        })
    }
    useEffect(getCats, [])

    const convertList = () => {
        let obj = { ...data }
        obj.is_dynamic = false;
        obj.isbns_json = JSON.stringify(isbns.map(item => { return item.isbn }));
        apiCall("titlelist/edit", obj, (_status, _result) => {
            if (_status) {
                setDynamic(_result.is_dynamic);
                message.success("List converted successfully.")
            } else {
                message.error(_result.error);
            }
        })
    }

    const onFinish = (_f) => {

        // Create and save preset
        if (isPreset) {
            apiCall((create) ? "titlelist/createDynamic" : "titlelist/editDynamic", _f, (_status, _result) => {
                if (_status) {
                    if (create) {
                        callback();
                        message.success("List created successfully.");
                    } else {
                        let newArr = { ...results };
                        let update = results.rows.find(item => item.list_id === _result.list_id);
                        Object.keys(update).map(key => {
                            update[key] = _result[key];
                        })
                        newArr.rows[results.rows.findIndex(item => item.list_id === _result.list_id)] = update;
                        setResults({ ...results, "rows": newArr.rows })
                        message.success("List saved successfully.");
                    }
                    close();
                } else {
                    message.error(_result.error)
                }
                setSaving(false);
            })

            return;
        }


        // Create and save list
        _f.sort_by = sort;
        //_f.filter_preset_id = 95;
        setSaving(true)
        if (!_f.hasOwnProperty("description")) {
            _f.description = description;
        }

        // Don't send description if
        if(site_content){
            delete _f["description"];
        }

        apiCall((create) ? "titlelist/create" : "titlelist/edit", _f, (_status, _result) => {
            if (_status) {
                if (create) {
                    callback();
                    message.success("List created successfully.");
                } else {
                    let newArr = { ...results };
                    let update = results.rows.find(item => item.list_id === _result.list_id);
                    Object.keys(update).map(key => {
                        update[key] = _result[key];
                    })
                    newArr.rows[results.rows.findIndex(item => item.list_id === _result.list_id)] = update;
                    setResults({ ...results, "rows": newArr.rows })
                    message.success("List saved successfully.");
                }
                close();
            } else {
                message.error(_result.error)
            }
            setSaving(false);
        })
    }

    const updateList = (_list) => {
        form.setFieldsValue({ "isbns_json": JSON.stringify(_list) })
    }

    const drawDynamicWarning = () => {
        return (
            <Result
                icon={<></>}
                title=""
                type="warning"
                extra={
                    <Alert style={{ "maxWidth": "300px", "margin": "0 auto" }} description={
                        <>
                            <div>
                                <strong>List is dynamic</strong>
                                <div>This list is generated from search filters<br /> You can convert to a static, editable list by clicking below</div>
                                <br />
                                <div>
                                    <Popconfirm onConfirm={() => convertList()} title={<>Are you sure? <br />This action is irreversible</>} ><Button type="primary" size="small"><small>Convert to static list</small></Button></Popconfirm>
                                </div>
                            </div>
                        </>} />
                }
            />
        )
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>{(create) ? "Create new" : "Edit"} List</Typography.Title>
                <Divider />
                <Spin spinning={loading}>
                    <Form form={form} onFinish={(e) => onFinish(e)} layout="vertical">

                        <Tabs className="itemTabs" activeKey={(isPreset) ? "preset" : "static"} onChange={(e) => setIsPreset(e !== "static")} type="card">
                            <Tabs.TabPane tab="Static List" key="static">
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="List from Filter Preset" key="preset">
                            </Tabs.TabPane>
                        </Tabs>

                        <Form.Item hidden initialValue={list_id} name="list_id" label="List name">
                            <Input />
                        </Form.Item>

                        <Space size={10} align="start">
                            <Form.Item initialValue={name} name="name" label="List name">
                                <Input placeholder="List name" style={{ "width": "300px" }} />
                            </Form.Item>

                            {(!isPreset &&
                                <Form.Item hidden noStyle value={sort_by} name="sort_by">
                                    <Input />
                                </Form.Item>
                            )}

                            {(isPreset &&
                                <Form.Item initialValue={filter_preset_id} rules={[{ required: true, message: "Preset Required" }]} name={"filter_preset_id"} label="Filter Preset">
                                    <FilterPresetSelect value={filter_preset_id} />
                                </Form.Item>
                            )}

                            {(!isPreset &&
                                <Space size={20}>
                                    <Form.Item label="Sort by">
                                        <Select value={sort} onChange={(e) => setSort(e)} style={{ "width": "200px" }}>
                                            <Select.Option value={""} >None</Select.Option>
                                            <Select.Option value={"rank"} >Rank</Select.Option>
                                            <Select.Option value={"alphabetical_title"} >Alphabetically</Select.Option>
                                            <Select.Option value={"alphabetical_author"} >Author</Select.Option>
                                            <Select.Option value={"pubdate"} >Pubdate</Select.Option>
                                            <Select.Option value={"bisac_subject_and_title"} >Subject</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <div>
                                        <div className="shim" /><div className="shim" /><div className="shim" />
                                        <Form.Item noStyle valuePropName="checked" initialValue={sort_by_reversed} name="sort_by_reversed" label="Sort by Reversed">
                                            <Checkbox>Reverse <br />Sort by</Checkbox>
                                        </Form.Item>
                                    </div>
                                </Space>
                            )}

                        </Space>
                        <Space size={20} align="start">
                            <Form.Item rules={[{ required: true, message: "Required. Alphanumeric only", pattern: new RegExp(/^[a-zA-Z 0-9]+$/i) }]} initialValue={category} name="category" label={<>Category &nbsp;<small>(25 char max)</small></>}>
                                <AutoComplete
                                    style={{ "width": "200px" }}
                                    maxLength={25}
                                    options={cats.categories.map(item => {
                                        return { value: item }
                                    })}
                                />
                            </Form.Item>
                            <Form.Item rules={[{ required: false, message: "Alphanumeric only", pattern: new RegExp(/^[a-zA-Z 0-9]+$/i) }]} initialValue={subcategory} name="subcategory" label={<>Subcategory &nbsp;<small>(25 char max)</small></>}>
                                <AutoComplete
                                    style={{ "width": "200px" }}
                                    maxLength={25}
                                    options={cats.subcategories.map(item => {
                                        return { value: item }
                                    })}
                                />
                            </Form.Item>

                            {(!isPreset &&
                                <div>
                                    <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                                    <Form.Item noStyle valuePropName="checked" initialValue={hide_non_oh_oo} name="hide_non_oh_oo" label="Hide non O/H O/O titles">
                                        <Checkbox>Hide non O/H O/O titles</Checkbox>
                                    </Form.Item>
                                    <div className="shim" />
                                    <Form.Item noStyle initialValue={onhand_first} valuePropName="checked" name="onhand_first" label="On hand first">
                                        <Checkbox>On hand first</Checkbox>
                                    </Form.Item>
                                </div>
                            )}

                        </Space>
                        <conditional.true value={(!site_content)}>
                            <Form.Item initialValue={description} label="Description" name={"description"}>
                                <Input.TextArea placeholder="Description..." onChange={(e) => console.log(e)} rows={3} showCount maxLength={2000}></Input.TextArea>
                            </Form.Item>
                        </conditional.true>
                        {((!loading && !isPreset) &&
                            <Spin indicator={<>{drawDynamicWarning()}</>} spinning={dynamic}>
                                <ListUpload candelete drag canSave={canSave} resetSort={() => {
                                    setSort("");
                                }} setCanSave={setCanSave} list_id={list_id} updateList={updateList} isbns={isbns} />
                                <Form.Item hidden initialValue={JSON.stringify(isbns.map(item => { return item.isbn }))} noStyle name={"isbns_json"}>
                                    <Input.TextArea rows={3} showCount maxLength={2000}></Input.TextArea>
                                </Form.Item>
                            </Spin>
                        )}
                        <Divider />
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={(e) => close()}>Cancel</Button>
                                <Button loading={saving} disabled={(!canSave)} htmlType="submit" type="primary">Save</Button>
                            </Space>
                        </div>
                        <br clear="all" />
                    </Form>
                </Spin>
            </div>
        </>
    );
}