import React from "react";
import { motion } from "framer-motion"
export default function Ani(props) {


    return (
        <motion.div>
            {props.children}
        </motion.div>
    )
}