import React from "react";
import { Form, Switch, Typography, Space } from "antd";
import conditional from "../../utils/conditional";
export default function WebstoreItem(props) {

    const {
        has_switch = true,
        disabled = false,
        disabledMsg = "",
        heading = "",
        description = "",
        value = false,
        name = "",
        warning = false,
        notice = "",
        onChange = null,
    } = props;


    const setOnChange = (e) =>{

        if(onChange){
            props.onChange(e);
        }
        
    }

    return (
        <>
            <Space align="start" size={15}>
                <Form.Item valuePropName='checked' initialValue={value} name={name} noStyle>
                    {(has_switch && <Switch disabled={disabled} onChange={(e) => setOnChange(e)} size="small" />)}
                    
                </Form.Item>
                {(!has_switch && <div style={{"width" : "28px"}}>&nbsp;</div>)}
                <div>
                    <Typography.Title level={5}>{heading}</Typography.Title>
                    <conditional.true value={(disabled && disabledMsg)}>
                    <Typography.Paragraph type="danger">{disabledMsg}</Typography.Paragraph>
                    </conditional.true>
                    <Typography.Paragraph type="secondary">
                        <conditional.true value={(notice)}>
                            <Typography.Paragraph type={(warning) ? "danger" : "secondary"} ><em><strong>{notice}</strong></em></Typography.Paragraph>
                        </conditional.true>
                        {description}</Typography.Paragraph>
                </div>
            </Space>
        </>
    )

}