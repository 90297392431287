import { Button, Typography, Spin, Empty } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../utils/Api";
import ItemLayouts from "../ItemLayouts";
import { CloseOutlined } from '@ant-design/icons'
import Renderer from "../Renderer";
export default function SeriesCount(props) {

    const { isbn = "", drawComponent = () => { }, next = false, series = "", series_num = ""  } = props;
    const [drawn, setDrawn] = useState(false);
    // Fetch next or previous series 
    const getSeries = (_next = false) => {
        drawComponent(<DrawTitles titles={{ rows: [] }} next={_next} loading={true} />);
        apiCall((_next) ? "title/getNextInSeries" : "title/getPreviousInSeries", { isbn: isbn }, (_status, _result) => {
            if (_status) {
                drawComponent(<DrawTitles titles={_result} next={_next} loading={false} />);
            }
        });
    }

    // Draw results + shift page down
    const DrawTitles = (props) => {
        const { titles = { rows: [] }, next = false, loading = true } = props;
        const myRef = useRef(null);

        // Shift down to the placeholder - no sense waiting for results to load...
        useEffect(() => {
            if (loading) { myRef.current.scrollIntoView({ behavior: "smooth", block: 'start', inline: 'nearest' }); }
        }, [])


   
        return (
            <>
                {/* Title and close UI */}
                <div style={{ "position": "relative", "top": "-174px" }} ref={myRef} ></div>
                <div className="closebar">
                    <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                    <div style={{ "padding": "8px 20px", "paddingBottom": "0px" }}>
                        <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                            <div>{(next) ? "Next in series:" : "Previous in series:"} {series}{series_num}</div>
                        </Typography.Title>
                    </div>
                </div>
                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />

                {/* Loading animation */}
                {(loading && <>
                    <Spin spinning={loading}>
                        <div className="shim" style={{ "height": "200px" }}></div>
                    </Spin>
                </>)}

                {/* Results */}
                <div>
                    {titles.rows.map((item, index) => {
                        return (
                            <div>
                                <Renderer><ItemLayouts display="details2" data={item} index={index} /></Renderer>
                                {(index !== (titles.rows.length - 1) && <div className="borderBottom shim" style={{ "margin": "25px -20px", "borderBottomWidth": "6px" }} />)}
                            </div>
                        )
                    })}
                </div>

                {/* Empty */}
                {((!loading && titles.rows.length === 0) && <>
                    <div>
                        <Empty description="No series found." image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                </>)}
            </>
        )
    }

    // Buttons
    return (

        <Button size="small" onClick={() => getSeries(next)} className="mini-btn" ><small>{(next) ? "Next" : "Previous"} in Series</small></Button>


    )





}