import { Col, Row, Select, Tooltip } from "antd";
import React, { useState } from "react";
import conditional from "../utils/conditional";
import { bmDate } from "../utils/Utils";
import BMStation from "./title/BMStation";
import { CaretDownOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import BMNoteCats from "./title/BMNoteCats";
import moment from "moment";
import SalesActivity from "./SalesActivity";
import PeerSales from "./PeerSales";
import { usePrefs } from "../utils/Prefs";

export default function Condition(props) {

    const [showActivity, setShowActivity] = useState("");
    const [prefs, setPrefs] = usePrefs();
    const { isbn, suggested_bm_data = { class_1: "", class_2: "" } } = props;

    const [openState, setOpenState] = useState("")


    const toggleActivity = (_view, _lbl = "") => {

        let reopen = (_lbl !== openState);
        setOpenState(_lbl)
        setShowActivity(((_view) && _view == showActivity) ? "" : _view)
        if (reopen) {
            setTimeout(() => {
                setShowActivity(_view)
            }, 1)
        }
    }

    const [data, setData] = useState(props.data);

    const suggestedCategory = suggested_bm_data.class_1 || "";
    const suggestedSubcategory = suggested_bm_data.class_2 || "";

    // destructure
    const {
        // condition
        code = "",
        code_text = "",
        code_used_desc = "",
        code_used_note = "",

        // extras
        total_onhand = 0,
        minimum_onhand = 0,
        backroom = 0,
        held = 0,
        available_onhand = 0,
        pending_return = 0,
        special_order = 0,
        available_onorder = 0,
        pending_onorder = 0,

        // from bookmanager backups
        primary_key = "",
        discount = "",
        title = "",
        pubdate = "",
        author = "",
        binding = "",
        price = "",
        sell_price = "",
        carton_quantity = 0,
        profit_cost_string = "",
        supplier_1 = "",
        supplier_2 = "",
        supplier_3 = "",
        section = "",
        out_of_stock_status = "",
        returnable = "",
        entered = "",
        original_publisher = "",
        row_status = "",

        // Sales
        sold = false,
        cart_qty = 0,
        received = false,
        adjusted = false,
        returns = false,
        peer = false

    } = data;


    const osstat_convert = {
        'A': 'AVAI',
        'B1': 'BOS',
        'B2': 'BNYP',
        'B3': 'BRP',
        'B4': 'BITO',
        'C1': 'COS',
        'C2': 'CNYP',
        'C3': 'CNOP',
        'C4': 'CNR',
        'C5': 'COP',
        'C6': 'CNL',
        'C7': 'CNW',
        'C8': 'COSI',
        'C9': 'CPXL',
        'CA': 'CRP',
        'CB': 'CITO',
        'CC': 'CBU',
        'CD': 'CBC'
    }

    const converted_out_of_stock_status = osstat_convert[out_of_stock_status] || out_of_stock_status;


    // This is gross, but it's to assign spots to put extra info - Any overflow will be added to the top right;

    const getExtras = () => {

        let _ex = [];
        let _spots = [];

        if (special_order) {
            _ex.push({
                label: "S/O",
                class: "extra-lbl-special_order",
                valueClass: "extra-special_order",
                value: special_order
            })
        }

        if (held) {
            _ex.push({
                label: "Held",
                class: "extra-lbl-held",
                valueClass: "extra-held",
                value: held
            })
        }

        // only show if a specific store is selected or is non multi-store
        if ((minimum_onhand && window.sitesettings.stores.length === 1) || (minimum_onhand && prefs.store)) {

            _ex.push({
                label: "mOH",
                class: "extra-lbl-minimum_onhand",
                valueClass: "extra-minimum_onhand",
                value: minimum_onhand
            })
        }
        // only show if a specific store is selected or is non multi-store
        if ((backroom && window.sitesettings.stores.length === 1) || (backroom && prefs.store)) {
            _ex.push({
                label: "Bkrm",
                class: "extra-lbl-backroom",
                valueClass: "extra-backroom",
                value: backroom
            })
        }

        if (pending_return) {
            _ex.push({
                label: "Pdrn",
                class: "extra-lbl-pending_return",
                valueClass: "extra-pending_return",
                value: pending_return
            })
        }


        // Add extra to returns row
        if (_ex.length > 0 && returns) {
            let _x = _ex[0];
            _x.spot = "Retn";
            _spots.push(_x);
            _ex.shift();
        }

        // Add extra to returns row
        if (_ex.length > 0 && adjusted) {
            let _x = _ex[0];
            _x.spot = "Adj";
            _spots.push(_x);
            _ex.shift();
        }


        // Add extra to date row
        if (window.sitesettings.stores.length === 1 && _ex.length > 0) {
            let _x = _ex[0];
            _x.spot = "Date";
            _spots.push(_x);
            _ex.shift();
        }



        return {
            spots: _spots,
            extra: _ex
        }


    }



    const extras = getExtras();


    const parseSold = (_msinfo = false) => {

        if (!_msinfo) {
            return [];
        }

        let _sold = [];
        _msinfo.forEach(element => {

            // // draw a sold row regardless...
            // if(element.hasOwnProperty("cart_qty") && element.cart_qty > 0){
            //     element.sold = {total : 0}
            // }



            if (element.hasOwnProperty("sold")) {
                element.sold.storenum = element.storenum;
                element.sold.available_onhand = element.hasOwnProperty("available_onhand") ? element.available_onhand : 0;
                element.sold.total_onhand = element.hasOwnProperty("total_onhand") ? element.total_onhand : 0;
                element.sold.available_onorder = element.hasOwnProperty("available_onorder") ? element.available_onorder : 0;
                element.sold.pending_onorder = element.hasOwnProperty("pending_onorder") ? element.pending_onorder : 0;
                element.sold.cart_qty = element.hasOwnProperty("cart_qty") ? element.cart_qty : 0;
                _sold.push(element.sold);
            } else {
                let x = {}
                x.storenum = element.storenum;
                x.available_onhand = element.hasOwnProperty("available_onhand") ? element.available_onhand : 0;
                x.total_onhand = element.hasOwnProperty("total_onhand") ? element.total_onhand : 0;
                x.available_onorder = element.hasOwnProperty("available_onorder") ? element.available_onorder : 0;
                x.pending_onorder = element.hasOwnProperty("pending_onorder") ? element.pending_onorder : 0;
                x.cart_qty = element.hasOwnProperty("cart_qty") ? element.cart_qty : 0;
                if ((x.available_onhand) || (x.total_onhand) || (x.available_onorder) || (x.pending_onorder)) {
                    _sold.push(x);
                }
            }
        });

        return _sold;
    }


    const drawExtra = (_obj, _row = false) => {





        if (!_obj) {
            return (<Col key={"none"} flex={"75px"} className="bc" style={{ "borderLeft": "1px solid" }}>&nbsp;</Col>)
        }

        if (_row) {
            return (
                <Row key={_obj.label} onClick={() => toggleActivity("sales", _obj.label)} className="bc" style={{ "fontSize": "11px", "color": "#aaa", "textAlign": "right", "borderBottom": "1px solid", "lineHeight": "22px" }}>
                    <Col flex={"auto"} className="bc">&nbsp;</Col>
                    <Col flex={"37px"} className={"bc " + _obj.class} style={{ "fontSize": "11px", "cursor": "pointer", "borderLeft": "1px solid", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                        <div>{_obj.label}</div>
                    </Col>
                    <Col flex={"38px"} className={"bc " + _obj.valueClass} style={{ "fontSize": "11px", "cursor": "pointer", "borderLeft": "1px solid", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                        <div>{_obj.value}</div>
                    </Col>
                </Row>
            )
        } else {
            return (
                <>
                    <Col onClick={() => toggleActivity("sales", _obj.label)} flex={"37px"} className={"bc " + _obj.class} style={{ "fontSize": "11px", "cursor": "pointer", "borderLeft": "1px solid", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                        <div>{_obj.label}</div>
                    </Col>
                    <Col onClick={() => toggleActivity("sales", _obj.label)} flex={"38px"} className={"bc " + _obj.valueClass} style={{ "fontSize": "11px", "cursor": "pointer", "borderLeft": "1px solid", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                        <div>{_obj.value}</div>
                    </Col>
                </>
            )
        }
    }

    const switchStore = (_store) => {



        setPrefs({ ...prefs, "store": _store });

        if (_store === "") {
            setData(props.data);
        }

        let obj = props.data.msinfo.find(item => item.storenum === _store);

        if (!obj) {
            return;
        }

        obj.code = props.data.code;
        obj.code_text = props.data.code_text;

        setData(obj)

    }

    const drawStoreSelector = () => {

        return (
            <Select onChange={(_store_num) => switchStore(_store_num)} className="minselect" style={{ "fontSize": "13px", "width": "74px" }} showArrow={<CaretDownOutlined />} bordered={false} value={prefs.store} size="small">
                <Select.Option value={""} key={"init"}><small>All Stores</small></Select.Option>
                {window.sitesettings.stores.map(item => {
                    let _enabled = false;
                    if (props.data.msinfo) {
                        if (props.data.msinfo.find(i => i.storenum === item.store_number)) {
                            _enabled = true;
                        }
                    }

                    return (<Select.Option key={item.store_number} disabled={(!_enabled)} value={item.store_number}><small>Store #{item.store_number}</small></Select.Option>)
                })}

            </Select>
        )

    }

    const drawHeadersRow = () => {

        let _months = ["Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"]
        _months = _months.map((item, index) => {
            return moment().startOf("month").add(-(_months.length - (index + 1)), "month").format("MMM");
        });

        return (
            <Row className="bc" style={{ "fontSize": "11px", "color": "#aaa", "textAlign": "right", "borderBottom": "0px solid", "lineHeight": "22px" }}>
                <Col flex={"30px"} className="bc3" style={{ "borderRight": "1px solid" }}>&nbsp;</Col>
                <Col flex={"30px"} className="bc3 c" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}>Ttl</Col>
                {_months.map((item, _index) => {
                    return (<Col onClick={() => toggleActivity("sales")} key={_index} style={{ "paddingRight": "2px", "cursor": "pointer" }} flex={"30px"}><span className="c">{item}</span></Col>)
                })}
                <Col flex={"28px"} className="bc3" style={{ "borderLeft": "1px solid", "borderRight": "1px solid", "paddingRight": "2px" }}><span className="c">Wk</span></Col>
                <conditional.true value={(window.sitesettings.stores.length === 1)}>
                    {drawExtra(extras.spots.find(item => item.spot === "Date"))}
                </conditional.true>
                <conditional.true value={(window.sitesettings.stores.length > 1)}>
                    <Col flex={"75px"} className="bc" style={{ "borderLeft": "1px solid" }}>
                        {drawStoreSelector()}
                    </Col>
                </conditional.true>
            </Row>
        )
    }



    const drawToggle = (_aoo = available_onorder, _poo = pending_onorder) => {

        if (!_aoo && !_poo && !prefs.pendingOO) {
            _aoo = "";
        }

        if (prefs.pendingOO) {
            return (
                <Col onClick={(e) => { e.preventDefault(); e.stopPropagation(); setPrefs({ ...prefs, "pendingOO": !prefs.pendingOO }); }} flex={"38px"} className="bc" style={{ "cursor": "pointer", "borderLeft": "1px solid", "backgroundColor": "#ffe8bf", "color": "#000", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                    <Tooltip title={"Total on order (aOO + PendOO). Click to toggle this to show aOO and PendOO separately."}><div>{(converted_out_of_stock_status === "BOS" && "Yes ")} {_aoo + _poo}</div></Tooltip>
                </Col>
            )
        } else {
            return (
                <Col onClick={(e) => { e.preventDefault(); e.stopPropagation(); setPrefs({ ...prefs, "pendingOO": !prefs.pendingOO }); }} flex={"38px"} className="bc" style={{ "cursor": "pointer", "borderLeft": "1px solid", "backgroundColor": "#ffe8bf", "color": "#000", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                    <Tooltip title={_aoo + " copies are on a sent PO for stock, plus " + _poo + " are in a pending stock order (not sent). These quantities do not include special orders (S/O). Click to toggle this to show total on order (aOO + PendOO)."}><div>{(converted_out_of_stock_status === "BOS" && "Yes ")}{_aoo}{(_poo) ? <sup style={{ "top": "-3px" }}>&nbsp;{_poo}</sup> : ""}</div></Tooltip>
                </Col>
            )
        }

    }


    const drawOnhand = (_oh, _toh) => {
        if (!_oh && !_toh) {
            _oh = ""
        }

        let _oh_label = _oh || "0";
        return (
            <Col flex={"37px"} className="bc" style={{ "cursor": "pointer", "borderLeft": "1px solid", "backgroundColor": "#ccf2cc", "color": "#000", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px" }}>
                <Tooltip title={_oh_label + " copies are available on hand, with " + _toh + " copies total on hand. When the 'total' and 'available' count differ, this likely indicates that some are reserved for customer (Held) or could be in a pending stock return (Pdrn)."}><div>{(converted_out_of_stock_status === "AVAI" && "Yes ")}{_oh}{(_toh && _toh !== _oh) ? <sup style={{ "top": "-3px" }}>{_toh}</sup> : ""}</div></Tooltip>
            </Col>
        )
    }


    const drawValues = (_data = {}, _type = "", _cart_qty = null) => {
        if (!_data && _type !== "Rcvd" && _type !== "Ttl") {
            return <></>
        }

        if (!_data) {
            _data = {};
        }

        let { total_stores = 0, total_stores_region } = _data;
        let { months = ["", "", "", "", "", "", "", "", "", "", "", "", "", ""], total = 0, week = 0, onhandlocs = 0, onorderlocs = 0, onhand = 0, onorder = 0, title = "" } = _data;
        let _color = "#000";
        let _class = "rowSold";

        let _ispeer = (_type === "Peer");

        let border = false;
        if (_type === "Ttl") {
            if (peer) {
                border = true;
            }
        }

        if (peer && _type === "Peer") {
            border = false;
        }

        if (_ispeer) {
            total = 0;
        }

        switch (_type) {

            case "Adj":
                border = true;
                _color = "#228B22";
                _class = "rowAdj";
                break;

            case "Retn":
                border = true;
                _color = "#228B22";
                _class = "rowRetn";
                break;

            case "Rcvd":
                border = true;
                _color = "#228B22";
                _class = "rowRcvd";
                break;

            case "Peer":
                _color = "#228B22";
                _class = "rowPeer";
                break;

        }

        // Change the Ttl label to Sold for non-multistores
        let _label = _type;
        if (window.sitesettings.stores.length === 1 && _label === "Ttl") {
            _label = "Sold"
        }

        return (
            <Row onClick={() => (_ispeer) ? toggleActivity("peer") : toggleActivity("sales", _type)} className={_class + " bc rowHover"} style={{ "fontSize": (_ispeer || _type.includes("#")) ? "10px" : "11px", "lineHeight": (_ispeer || _type.includes("#")) ? "22px" : "22px", "fontWeight": (_ispeer || _type.includes("#")) ? "normal" : "normal", "textAlign": "right", "borderBottom": (border) ? "0px solid" : "none" }}>

                <conditional.true value={(_type === "Peer")}>
                    <Col flex={"60px"} className="bc" style={{ "borderRight": "1px solid", "borderTop": "1px solid", "textAlign": "center", "paddingLeft": "2px", "fontWeight": "normal", "fontSize": "10px" }}>
                        <Tooltip title={"The 14-month peer sales data for this title has been populated from data submitted by " + total_stores + " stores."}>{total_stores}{total_stores_region} Peers&nbsp;</Tooltip>
                    </Col>
                </conditional.true>

                <conditional.true value={(_type !== "Peer")}>
                    <Col flex={"30px"} className="bc3" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingLeft": "1px", "fontWeight": "normal", "fontSize": "11px" }}>{_label}&nbsp;</Col>
                    <Col flex={"30px"} className="bc3" style={{ "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px" }}>{(total) ? total : ""}</Col>
                </conditional.true>

                {months.map((item, _index) => {
                    return (<Col key={_index} style={{ "textAlign": "right", "paddingRight": "2px" }} flex={"30px"}>{(item) ? item : ""}</Col>);
                })}

                <Col flex={"28px"} className="bc3" style={{ "borderLeft": "1px solid", "paddingRight": "2px", "borderRight": "1px solid #000" }}><em>{(week) ? week : ""}</em></Col>

                <conditional.true value={(_type === "Rcvd")}>
                    <Col flex={"37px"} className="bc" style={{ "borderLeft": "1px solid", "borderTop": "1px solid", "color": "#3C8617", "textAlign": "right", "paddingRight": "3px" }}><em>aOH</em></Col>
                    <Col flex={"37px"} className="bc" style={{ "borderLeft": "1px solid", "borderTop": "1px solid", "color": "#e77e21", "textAlign": "right", "paddingRight": "3px" }}><em>{(prefs.pendingOO) ? "tOO" : "aOO"}</em></Col>
                    <div style={{ "position": "absolute", "right": "-10px", "width": "18px", "padding": "0px 0px", "backgroundColor": "#000", "borderRadius": "10px", "textAlign": "center", "color": "#fff", "height": "18px" }} >
                        <Tooltip title={<small>Biz cart quantities</small>}><ShoppingCartOutlined style={{ "marginTop": "4px", "display": "block" }} /></Tooltip>
                    </div>
                </conditional.true>

                <conditional.true value={(_type === "Sold" || _type.includes("#"))}>
                    {drawOnhand(_data.available_onhand, _data.total_onhand)}
                    {drawToggle(_data.available_onorder, _data.pending_onorder)}
                </conditional.true>

                <conditional.true value={(_type === "Ttl" || _type === "Sold")}>
                    {drawOnhand(available_onhand, total_onhand)}
                    {drawToggle()}
                </conditional.true>

                <conditional.true value={(_type === "Peer")}>
                    <Col flex={"37px"} className="bc" style={{ "borderLeft": "1px solid", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px", "fontSize": "10px" }}><div style={{ "width": "33px", "overflow": "hidden", "fontSize": "9px" }}><nobr>{(onhand) ? onhand : ""}<sup>{(onhandlocs) ? onhandlocs : ""}</sup></nobr></div></Col>
                    <Col flex={"37px"} className="bc" style={{ "borderLeft": "1px solid", "fontWeight": "normal", "textAlign": "right", "paddingRight": "3px", "fontSize": "10px" }}><div style={{ "width": "34px", "overflow": "hidden", "fontSize": "9px" }}><nobr>{(onorder) ? onorder : ""}<sup>{(onorderlocs) ? onorderlocs : ""}</sup></nobr></div></Col>
                </conditional.true>

                <conditional.true value={(_type === "Retn" || _type === "Adj")}>
                    {drawExtra(extras.spots.find(item => item.spot === _type))}
                </conditional.true>

                <conditional.true value={(_cart_qty !== null)}>
                    <div style={{ "position": "absolute", "fontSize": "10px", "right": "-11px", "width": "20px", "height": "23px", "backgroundColor": "#abdeff", "padding": "0px 2px", "color": "#000" }} >
                        {(_cart_qty) ? _cart_qty : <>&nbsp;</>}
                    </div>
                </conditional.true>
            </Row >
        )
    }


    const drawPrice = (_prev = false) => {

        if (!price) {
            return "";
        }

        if (sell_price && _prev) {

            if (discount) {
                return <><span style={{ "color": "#f12a46" }}></span><div style={{ "width": "74px", "letterSpacing": "-0.5px", "overflow": "hidden", "marginTop": "-5px" }}><small><nobr>({discount}% off ${price})</nobr></small></div></>
            }
            return <><div style={{ "marginTop": "-2px" }}>(reg. ${price})</div></>
        }

        if (_prev) {
            return "";
        }

        return <div style={{ "fontSize": "13px", "color": (sell_price) ? "#f12a46" : "inherit", "fontWeight": "normal", "lineHeight": "22px" }}>{(sell_price) ? "$" + sell_price.toString() : "$" + price.toString()}</div>
    }



    const drawBM = () => {

        if (!prefs.bm_notes) {
            return (<></>);
        }

        return (
            <>
                <Row className="bc" style={{ "fontSize": "11px", "textAlign": "right", "borderTop": "1px solid" }}>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingRight": "3px" }} flex={"30px"}><span className="bmlbl">ISBN</span></Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingLeft": "3px", "textAlign": "left" }} flex={"100px"}>{primary_key}</Col>
                    <Col className="bc" style={{ "borderRight": '1px solid' }} flex={"auto"}></Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingRight": "3px" }} flex={"20px"}><span className="bmlbl">PC</span></Col>
                    <Col className="bc" style={{ "paddingLeft": "3px", "textAlign": "left" }} flex={"102px"}>{profit_cost_string}</Col>
                </Row>

                <Row className="bc" style={{ "fontSize": "11px", "textAlign": "right", "borderTop": "1px solid" }}>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingRight": "3px" }} flex={"30px"}><span className="bmlbl">Title</span></Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingLeft": "3px", "textAlign": "left" }} flex={"auto"}>{title}</Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingRight": "3px" }} flex={"20px"}><span className="bmlbl">Bd</span></Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingLeft": "3px", "textAlign": "left" }} flex={"20px"}>{binding}</Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingRight": "3px" }} flex={"45px"}><span className="bmlbl">Entered</span></Col>
                    <Col className="bc" style={{ "paddingLeft": "3px", "textAlign": "left" }} flex={"77px"}>{(entered) ? bmDate(entered) : ""}</Col>
                </Row>

                <Row className="bc" style={{ "fontSize": "11px", "textAlign": "right", "borderTop": "1px solid" }}>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingRight": "3px" }} flex={"30px"}><span className="bmlbl">Auth</span></Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingLeft": "3px", "textAlign": "left" }} flex={"auto"}>{author}</Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingRight": "3px" }} flex={"48px"}><span className="bmlbl">Pubdate</span></Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingLeft": "3px", "textAlign": "left" }} flex={"60px"}>{(pubdate) ? bmDate(pubdate) : ""}</Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "textAlign": "center" }} flex={"45px"}><span className="bmlbl">O/S Stat</span></Col>
                    <Col className="bc" style={{ "paddingLeft": "3px", "textAlign": "left" }} flex={"77px"}>{converted_out_of_stock_status}</Col>
                </Row>

                <Row className="bc" style={{ "fontSize": "11px", "textAlign": "right", "borderTop": "1px solid" }}>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingRight": "3px" }} flex={"30px"}><span className="bmlbl">Supl</span></Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingLeft": "3px", "textAlign": "left" }} flex={"auto"}>{supplier_1} {supplier_2} {supplier_3}</Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingRight": "3px" }} flex={"30px"}><span className="bmlbl">Publ</span></Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "paddingLeft": "3px", "textAlign": "left" }} flex={"108px"}>{original_publisher}</Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "textAlign": "center" }} flex={"32px"}><span className="bmlbl">Rtnbl</span></Col>
                    <Col className="bc" style={{ "paddingLeft": "3px", "textAlign": "left", "borderRight": '1px solid' }} flex={"13px"}>{returnable}</Col>
                    <Col className="bc" style={{ "borderRight": '1px solid', "textAlign": "center" }} flex={"52px"}><span className="bmlbl">Crtn Qty</span></Col>
                    <Col className="bc" style={{ "paddingLeft": "3px", "textAlign": "left" }} flex={"25px"}>{carton_quantity}</Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <div style={{ "position": "relative", "top": "1px" }}>
                <span className={(code_text === "New") ? "bcg2 bc" : "bcg4"} style={{ "fontSize": "11px", "fontWeight": "bold", "borderRadius": "2px 2px 0px 0px", "color": "#fff", "padding": "3px 8px", "paddingBottom": (code_text === "New") ? "2px" : "3px", "borderBottom": (code_text === "New") ? '1px solid' : "none", "backgroundColor": (code_text === "New") ? "none" : "#0e544d" }}>{(code_text === "New" ? <></> : code_text)}<small style={{ "opacity": "0.7" }}>{(code_used_desc) ? <> - {code_used_desc} </> : ""}</small></span> <small style={{ "opacity": "0.7", "fontSize": "9px" }}>{(code_used_note) ? <> &nbsp; {code_used_note} </> : ""}</small>
            </div>
            <div className="bc-light" style={{ border: "1px solid", borderLeft: (code_text === "New") ? "6px solid #189285" : "6px solid #0e544d" }}>
                <div>
                    <Row className="bc" style={{ "height": "29px", "borderBottom": "1px solid" }}>
                        <>
                            <BMStation isbn={isbn} />
                        </>
                        <Col className="bc bcg5" flex={"421px"} style={{ borderRight: "1px solid" }}>
                            <div style={{ "padding": "2px 4px" }}>
                                <Row gutter={4}>
                                    <div style={{ "width": "100%" }}>
                                        <BMNoteCats store={prefs.store} total_onhand={total_onhand} suggested_bm_data={{ class_1: suggestedCategory, class_2: suggestedSubcategory }} isbn={isbn} series={""} condition_code={code} condition={code_text} data={data} />
                                    </div>
                                </Row>
                            </div>
                        </Col>
                        <Col className="bc" style={{ borderRight: "1px solid" }} flex={"27px"}>
                            <div style={{ "textAlign": "center", "marginTop": "-2px", "height": "30px", "display": "table", "width": "27px" }}>
                                <div style={{ "display": "table-cell", "verticalAlign": "middle" }}>
                                    <small>{section}</small>
                                </div>
                            </div>
                        </Col>
                        <Col className="bc" flex={"auto"}>
                            <div style={{ "textAlign": "center", "marginTop": "-2px", "height": "30px", "display": "table", "width": "72px" }}>
                                <div style={{ "display": "table-cell", "verticalAlign": "middle" }}>
                                    <div style={{ 'fontSize': "13px", "lineHeight": "14px", "fontWeight": "normal" }}>{drawPrice()}</div>
                                    <div style={{ 'fontSize': "11px", "lineHeight": "11px" }}>{drawPrice(true)}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>


                    {extras.extra.map(item => {
                        return drawExtra(item, true);
                    })}
                    {drawHeadersRow()}
                    {drawValues(adjusted, "Adj")}
                    {drawValues(returns, "Retn")}
                    {drawValues(received, "Rcvd")}


                    <conditional.true value={(prefs.store === "")}>
                        {(parseSold(props.data.msinfo).length > 0 && <>
                            <div className="bc" style={{ "borderTop": '0px solid' }}>
                                {parseSold(props.data.msinfo).map((item, index) => {

                                    return drawValues(item, "#" + item.storenum, item.cart_qty || 0);
                                })}
                            </div>
                        </>)}
                    </conditional.true>


                    <conditional.true value={(prefs.store !== "")}>
                        {(parseSold(props.data.msinfo).length > 0 && <>
                            <div className="bc" style={{ "borderTop": '0px solid' }}>
                                {parseSold(props.data.msinfo).filter(i => i.storenum === prefs.store).map((item, index) => {

                                    return drawValues(item, "#" + item.storenum, item.cart_qty || 0);
                                })}
                            </div>
                        </>)}
                    </conditional.true>


                    <conditional.true value={(prefs.store === "")}>
                        <div className="bc" style={{ "borderTop": '0px solid' }}>
                            {drawValues(sold, "Ttl", cart_qty)}
                        </div>
                    </conditional.true>

                    {drawValues(peer, "Peer")}

                    <conditional.true value={(row_status !== "")}>
                        <div style={{"backgroundColor" : "#f12a46", "color" : "#fff", "textAlign" : "center"}}><small>&gt;&gt; {row_status} &lt;&lt; </small></div>
                    </conditional.true>
                    {drawBM()}

                </div>
            </div>

            <conditional.true value={(showActivity === "peer")}>
                <div style={{ "marginTop": "-5px" }}>
                    <PeerSales setShowActivity={setShowActivity} isbn={isbn} />
                </div>
            </conditional.true>

            <conditional.true value={(showActivity === "sales")}>
                <div style={{ "marginTop": "-5px" }}>
                    <div><SalesActivity openState={openState} store={prefs.store} condition={code} setShowActivity={setShowActivity} isbn={isbn} /></div>
                </div>
            </conditional.true>


        </>
    )


}