import React, { useEffect, useState } from "react";
import { Empty, Spin } from "antd";
import { apiCall } from "../../utils/Api";
import ItemLayouts from "../ItemLayouts";

export default function PreviousRender(props){

    const {isbn} = props;
    const [loading, setLoading] = useState(true);
    const [previous, setPrevious] = useState("")
 
    const getPreviousEdition = () => {

        apiCall("title/getRelatedEditions", {isbn : isbn}, (_status, _result) => {
            if(_result.previous_edition.isbns.length>0){
                setPrevious(_result.previous_edition.isbns[0]);
            } else {

            }
            setLoading(false);
        })
    }

    useEffect(getPreviousEdition, []);

    return(<>
        <Spin spinning={loading} >
        {(previous && <ItemLayouts isbn={previous} display="single" />)}
        {(!previous && <div style={{"height" : "200px", "display" : "flex", "flexDirection" : "column-reverse"}}><Empty description="No previous edition found." image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>)}
        </Spin></>)
}