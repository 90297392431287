import { Input, Popover, Button, Divider } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { getMemory, saveMemory } from "../utils/Utils";


export default function NumPicker(props) {

    

    const inputRef = useRef(null);
    const { disabled = false, value = 0, onChange = () => { }, autoFocus=false } = props;

    const [val, setVal] = useState((value) ? value : "");
    const [hover, setHover] = useState(false);

    const memory = getMemory("numpicker");
    const defaults = [10, 12, 16, 18, 24, 36];

    let mergedSet = new Set([...memory, ...defaults]);
    let presets = Array.from(mergedSet).slice(0, 6);
    // presets = presets.sort();

    useEffect(() => {
        setVal(value)
    }, [value]);

    const change = (_v) => {
        handleHover(false)
        addMemory(_v);
        onChange(_v);
    }

    const addMemory = (_val) => {
        _val = Number(_val);
        if (!isNaN(_val) && typeof _val === 'number' && _val >= 10 && _val <= 99) {
            saveMemory("numpicker", _val)
        }
    }

    const handleVal = (_e, _update = false, _blur = false) => {
        let v = _e.target.value.replace(/[^0-9]/g, '');

        if (_blur && (v == value)) {
            // if value matches on blur, don't update (already up to date)
            _update = false;
        }
        if (_update) {
            // onChange will set the local val pointless to set it twice
            addMemory(v);
            handleHover(false);
            change(v);
        } else {
            setVal(v);
        }
    };

    const handleKeyPress = (_e) => {
        if (_e.key === 'Enter') {
            handleVal(_e, true);
        }
    };

    const handleHover = (_v) => {
        if (_v !== hover) {
            setHover(_v);
        }
    }

    const NumBtn = (props) => {
        const { num = 0 } = props
        return (<Button onClick={() => change(num)} size="small">{num}</Button>)
    }

    const MemBtn = (props) => {
        const { index = 0 } = props;
        let _val = presets[index];
        return (<Button onClick={() => change(_val)} size="small"><small>{_val}</small></Button>)
    }

    const handleMouseLeave = () => {
        if (inputRef.current) {
          inputRef.current.blur();
        }
      };

    const content = (
        <>
            <div onMouseOver={() => handleMouseLeave()} style={{ "padding": "3px" }}>
                <div className="num-select-row">
                    <NumBtn num={1} />
                    <NumBtn num={2} />
                    <NumBtn num={3} />
                </div>
                <div className="num-select-row">
                    <NumBtn num={4} />
                    <NumBtn num={5} />
                    <NumBtn num={6} />
                </div>
                <div className="num-select-row">
                    <NumBtn num={7} />
                    <NumBtn num={8} />
                    <NumBtn num={9} />
                </div>

                <div className="num-select-row">
                    <div style={{"width" : "29px"}}></div>
                    <Button onClick={() => change(0)} style={{ "width": "29px" }} size="small"><small>0</small></Button>
                    <div></div>
                </div>

                <div style={{"margin" : "0px -3px", "marginBottom" : "4px"}} className="shim bottom-block-border"></div>
                
                <div className="num-select-sm">
                    <div className="num-select-row">
                        <MemBtn index={0} />
                        <MemBtn index={1} />
                        <MemBtn index={2} />
                    </div>
                    <div className="num-select-row">
                        <MemBtn index={3} />
                        <MemBtn index={4} />
                        <MemBtn index={5} />
                    </div>
                </div>
            </div>
        </>
    )

   

    return (
        <>
            <Popover placement="bottom" onVisibleChange={handleHover} visible={hover} overlayClassName="num-select" className="num-select" openClassName="num-select" content={content}>
                <Input
                    ref={inputRef}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    onKeyDown={(e) => handleKeyPress(e)}
                    mouseEnterDelay={0}
                    onBlur={(e) => handleVal(e, true, true)}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => handleVal(e)}
                    value={(val != false) ? val : ""}
                    style={{ "width": "30px", "fontSize": "11px", "height": "23px", "marginTop": "3px", "padding": "0px 5px" }}
                    className={(disabled) ? "tinyInput" : "tinyInput highlightQty"} />
            </Popover>
        </>
    )
}