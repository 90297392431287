import { Button, Modal } from "antd";
import React from "react";

export default function SalesCount(props) {

    const { data = [], title = "" } = props;
    if (data.length === 0) { return (<></>); }


    const drawDetails = () => {

        return (
            <>
                <em>"{title}"</em>
                <div className="shim" /><div className="shim" />
                {data.map(item => {
                    return (<div>{item}</div>)
                })}
            </>
        )

    }

    return (
        <>
            <Button onClick={() => Modal.info({ maskClosable : true, okText : "Close", okButtonProps : {type : "default"},  icon: null, title: "Sales & Marketing companies for:", content: <>{drawDetails()}</> })} size="small" className="mini-btn" ><small>Sales &amp; Marketing ({data.length}) </small></Button>
        </>
    );

}