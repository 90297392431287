import { Button, Tooltip, message } from "antd";
import React from "react";
import { FunnelPlotOutlined } from '@ant-design/icons';
import { apiCall } from "../utils/Api";
import { useHistory } from "react-router-dom";
import { objectToPath } from "../utils/Utils";
export default function Refine(props) {

    const { path = "", getBrowse = () => { }, searchFilters = {}, setSearchFilters = () => { } } = props;

    const history = useHistory();
    const fetchRefine = () => {

        let _filters = { ...searchFilters }

        if (_filters["t"]) {
            _filters["t"] = _filters["t"].replace("%25", "%");
            _filters["t"] = _filters["t"].replace("%2f", "/");
        }

        apiCall("browse/refine", _filters, (_status, _result) => {
            if (_status) {
                let _f = {x : _result.key}; 
                
                if(searchFilters.v){
                    _f.v = searchFilters.v; 
                }  

             
                history.push({ pathname: path + objectToPath(_f) })
                setSearchFilters(_f);
                getBrowse(false, _f);
            } else {
                message.error(_result.error);
            }
        })
    }


    return (<Tooltip title={<small>Consolidate current results for further refinement</small>}><Button type="primary" onClick={() => fetchRefine()} icon={<small><FunnelPlotOutlined /></small>} size="small"><small>&nbsp; Refine results</small></Button></Tooltip>)
}