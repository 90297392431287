import React, { useState } from "react";

import { Button, Divider, message, Modal, Popconfirm, Space, Typography, Spin } from "antd";
import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";
import { useSession } from "../../utils/Session";
export default function CartSubmitBtn(props) {

    
    const [session, setSession] = useSession();
    const [visible, setVisible] = useState(false);
    const [result, setResult] = useState({});
    const [error, setError] = useState({})
    const { filters = {}, group_bys = [], data = {}, skipConfirm = false, disabled=false, setExpanded = () => {} } = props;
    const [warn, setWarn] = useState(true);
    const [loading, setLoading] = useState(false); 
    

    const [checks, setChecks] = useState({
        weblink_confirmed: {
            required: true,
            checked: false,
        }

    });

    const [pregame, setPregame] = useState(
        {
            weblink_confirmed: false,
            split_skip: false,
            check_send_multistore: false,
        }
    )

    const checkSubmit = (_weblink_confirmed = false, _split_skip = false, _check_send_multistore = false, _send_multistore = false) => {

        setExpanded([]);
        let obj = {}
        // // supplier
        // if (group_bys.location) {
        //     obj.supplier = data.location;
        // }
        // // group
        // if (group_bys.order_grp) {
        //     obj.order_group = data.order_grp;
        // }
        // // user
        // if (group_bys.owner_id) {
        //     obj.user_id = data.owner_id;
        // }
        obj.supplier = filters.supplier || (filters.group_bys.location) ? data.location : false;
        if (!obj.supplier) { delete obj.supplier }
        obj.order_group = filters.group || (filters.group_bys.order_grp) ? data.order_grp : false;
        if (!obj.order_group && obj.order_group === false) { delete obj.order_group }
        obj.user_id = filters.user || (filters.group_bys.owner_id) ? data.owner_id : false;
        if (!obj.user_id) { delete obj.user_id }

        obj.split_skip = _split_skip;
        obj.weblink_confirmed = _weblink_confirmed;
        obj.check_send_multistore = _check_send_multistore;

        apiCall("cart/checkSubmit", obj, (_status, _result) => {
            setLoading(true);
            if (_status) {
                obj.send_multistore = _send_multistore;
                apiCall("cart/submit", obj, (_s, _r) => {
                    if (_s) {
                        setSession({ ...session, "cart": _r });
                        message.success("Order sent.");
                        setVisible(false);
                    } else {
                        setError(_r);
                        setVisible(true);
                        setVisible(false);
                    }
                    setLoading(false)
                })

            } else {
                setError(_result);
                setVisible(true);
                setLoading(false);
            }
        })
    }


    const splitOrder = () => {

        let obj = {}

        // supplier
        if (group_bys.location) {
            obj.supplier = data.location;
        }

        // group
        if (group_bys.order_grp) {
            obj.order_group = data.order_grp;
        }

        // user
        if (group_bys.owner_id) {
            obj.user_id = data.owner_id;
        }

        setLoading(true)

        apiCall("cart/splitMultistore", obj, (_status, _results) => {
           
            if (_status) {
                setSession({ ...session, "cart": _results });
                message.success("Order split successfully.");
            } else {
                message.error("Order split failed.")
            }
            setLoading(false)

            setVisible(false);

        });

    }





    const drawError = (_error) => {


        switch (_error) {


            case "Can send to multistores":
                return (
                    <>
                        <Typography.Title level={5}>Send to other store </Typography.Title>
                        <p>The order you are about to submit has a numerical order group that represents a remote store.
                            Choose to have this order sent to either the remote or main store</p>
                        <Divider />
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => setVisible(false)}>Cancel</Button>
                                <Button onClick={() => checkSubmit(true, true, true, false)} type="primary">Send to main store</Button>
                                <Button onClick={() => checkSubmit(true, true, true, true)} type="primary">Send to other store</Button>
                            </Space>
                        </div>
                        <br clear="all" />
                    </>
                )



            case "Weblink not running":
                return (
                    <>
                        <Typography.Title level={5}>Weblink is not running </Typography.Title>
                        <p>Your Bookmanager software appears to not have Weblink running for automatic file downloads into the system.</p>
                        <p>Typically, Weblink is configured to run on your main server station (station 0), but it can be set to run on any station. You can check where Weblink is running or supposed to run by looking in the upper right-hand corner of the Bookmanager software. A green <strong>W</strong> followed by a number (the station Weblink is running on) indicates where Weblink is running. For example, a green <strong>W4</strong> indicates Weblink is running on station 4. If the indicator is red, that means Weblink is not running. To start Weblink, simply ensure the Bookmanager station set to run Weblink is open, or exit that station and reopen Bookmanager to restart Weblink.</p>
                        <p>You can submit this online webcart order now, but it will remain in an online queue and not be downloaded into Bookmanager until Weblink is up and running.</p>
                        <Divider />
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => setVisible(false)}>Cancel</Button>
                                <Button onClick={() => checkSubmit(true, false, false)} type="primary">Send order</Button>
                            </Space>
                        </div>
                        <br clear="all" />
                    </>
                )

            case "Can split":
                return (
                    <>
                        <Spin spinning={loading} tip="Processing - Please wait...">
                        <Typography.Title level={5}>Split order </Typography.Title>
                        <p>The order you are about to submit has items ordered for other locations.</p>
                        <p>Choose to either submit the entire order to the main store or "Split Order" into separate pending orders. Split orders can then be optionally sent to each store's Bookmanager.</p>
                        <Divider />
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => setVisible(false)}>Cancel</Button>
                                <Button onClick={() => splitOrder()}>Split order</Button>
                                <Button onClick={() => checkSubmit(true, true, false)} type="primary">Submit as is</Button>
                            </Space>
                        </div>
                        <br clear="all" />
                        </Spin>
                    </>
                )

            default:

                return (
                    <>
                        <Typography.Title level={5}>Error</Typography.Title>
                        <p>{_error}</p>

                        <Divider />
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => setVisible(false)}>Close</Button>
                            </Space>
                        </div>
                        <br clear="all" />
                    </>
                )


        }

    }



    return (
        <>
            <Modal footer={false} onCancel={() => setVisible(false)} visible={visible}>
                {drawError(error.error)}
            </Modal>

            {(skipConfirm && <> <Button onClick={() => checkSubmit(false, false, false)} type="primary" size="small"><small>Submit</small></Button> </>)}

            {(!skipConfirm && <>
                <Popconfirm title={<>Submit these titles to your <br />Bookmanager software?</>} onConfirm={() => checkSubmit(false, false, false)}>
                    <Button disabled={disabled} type="primary" size="small"><small>Submit</small></Button>
                </Popconfirm>
            </>)}


        </>
    )
}