import React, { useState } from "react";
import { Form, Row, Col, Checkbox, DatePicker, Typography, Divider, Input, Button, Select, Table } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import FormatPicker from "./sections/FormatPicker";
export default function Hours(props) {

  const {setChanged = () => {}} = props; 

  const [data, setData] = useState(props.data);
  const [updated, setUpdated] = useState(false);
  const drawStores = (_stores) => {
    return _stores.map((item, index) => { return drawStore(item, index) })
  }

  

  const drawStore = (_store, _index) => {
    return (
      <div key={_index}>
        <Typography.Title level={5}>{_store.store}</Typography.Title>
        <Form.Item  name={["global:global:ws_hours", [_store.store_san], "title"]} initialValue={_store.title}>
          <Input maxLength={100} style={{ "maxWidth": "500px" }} />
        </Form.Item>

        {drawWeekdays(_store.hours, _store.store_san, _index)}
        <div style={{ "width": "500px" }}><Divider dashed /></div>
        {drawExceptions(_store.exceptions, _store.store_san, _index)}
        <div style={{ "width": "500px" }}><Divider dashed /></div>
      </div>
    )
  }

  const drawWeekdays = (_days, _ind, pos) => {
    let m = [];
    _days.forEach((_day, _index) => {
      m.push({
        key: _index,
        day: <div>{_day.weekday}</div>,
        open: <Form.Item noStyle valuePropName='checked' initialValue={(_day.open)} name={["global:ws_hours", [_ind], "hours", [_index], "open"]}><Checkbox onChange={(e) => { updateOpen(pos, "hours", [_index], e.target.checked)}} /></Form.Item>,
        start: <Form.Item noStyle valuePropName="value" name={["global:ws_hours", [_ind], "hours", [_index], "start"]} initialValue={_day.start}><FormatPicker disabled={!data[pos]["hours"][_index]["open"]} /></Form.Item>,
        end: <Form.Item noStyle name={["global:ws_hours", [_ind], "hours", [_index], "end"]} initialValue={_day.end}><FormatPicker disabled={!data[pos]["hours"][_index]["open"]} /></Form.Item>,
      });
    })

    const columns = [
      { title: 'Day', dataIndex: 'day', key: 'day' },
      { title: 'Open', dataIndex: 'open', key: 'open' },
      { title: 'Open at', dataIndex: 'start', key: 'start' },
      { title: 'Close at', dataIndex: 'end', key: 'end' },
    ];

    return (
      <>
        <Table style={{ "maxWidth": "500px" }} pagination={false} size="small" bordered dataSource={m} columns={columns} />
      </>
    );

  }

  const drawAddDate = (_ind, pos) => {
    return (
      <>
        <div style={{"cursor" : "pointer"}}>
          <DatePicker style={{ "cursor": "pointer"  }} onSelect={(e) => addException(_ind, e, pos)} suffixIcon={<Button style={{ "cursor": "pointer", "position": "relative", "left": "-110px" }} type="primary" size="small"><small>Add special date</small></Button>} placeholder="" allowClear={false} inputReadOnly bordered={false} ></DatePicker>
        </div>
      </>
    )
  }

  const addException = (_ind, e, pos) => {
    let obj = {
      "day": e.date(),
      "month": e.month() + 1,
      "open": true,
      "start": 0,
      "end": 0
    }
    let d = data;
    d[pos].exceptions.push(obj);
    setData(d);
    setUpdated(!updated);
    setChanged(true);

  }

  const removeException = (_ind, _index, pos) => {
    let d = data;
    delete d[pos].exceptions[_index];
    setData(d);
    setUpdated(!updated);
    setChanged(true);
  }

  const updateOpen = (p1, p2, p3, _val) => {

    let d = [...data]
    d[p1][p2][p3]["open"] = _val;
    setData(d);
    
    //hours

    //exceptions



  }

  const drawExceptions = (_days, _ind, pos) => {

    let m = [];

    _days.forEach((_day, _index) => {
      
      let d = _day.month + "/" + _day.day + "/" + moment().format("YYYY");
      var month = moment(d).format('MMMM');
      var date = moment(d).format('Do');
      console.log("EXCEPTION" + _day.start);
      m.push({
        key: _index,
        day: <div><Form.Item noStyle initialValue={_day.month} name={["global:ws_hours", [_ind], "exceptions", [_index], "month"]} hidden /><Form.Item initialValue={_day.day} name={["global:ws_hours", [_ind], "exceptions", [_index], "day"]} hidden />{month} {date}</div>,
        open: <Form.Item noStyle valuePropName='checked' initialValue={(_day.open)} name={["global:ws_hours", [_ind], "exceptions", [_index], "open"]}><Checkbox checked={_day.open} onChange={(e) => { updateOpen(pos, "exceptions", [_index], e.target.checked)}} /></Form.Item>,
        start: <Form.Item noStyle initialValue={(_day.start)} name={["global:ws_hours", [_ind], "exceptions", [_index], "start"]}><FormatPicker disabled={!data[pos]["exceptions"][_index]["open"]}/></Form.Item>,
        end: <Form.Item noStyle initialValue={(_day.end)} name={["global:ws_hours", [_ind], "exceptions", [_index], "end"]}><FormatPicker disabled={!data[pos]["exceptions"][_index]["open"]} /></Form.Item>,
        remove: <div style={{ "textAlign": "center" }}><Button onClick={() => removeException(_ind, _index, pos)} size="small" icon={<CloseOutlined style={{ "fontSize": "12px", "color": "#888" }} />} shape="circle" /></div>
      });
    })

    const columns = [
      { title: 'Day', dataIndex: 'day', key: 'day' },
      { title: 'Open', dataIndex: 'open', key: 'open' },
      { title: 'Open at', dataIndex: 'start', key: 'start' },
      { title: 'Close at', dataIndex: 'end', key: 'end' },
      { title: <></>, dataIndex: 'remove', key: 'remove' },
    ];

    return (
      <>

        <Table footer={() => drawAddDate(_ind, pos)} style={{ "maxWidth": "500px" }} pagination={false} size="small" bordered dataSource={m} columns={columns} />
      </>
    );

  }

  return (
    <>
      {drawStores(data)}
    </>
  );

}
