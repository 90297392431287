import { Button, Form, Input, Popconfirm, Space } from "antd";
import React from "react";
import { EditFilled, DeleteFilled, SettingFilled } from '@ant-design/icons';
import { Draggable } from "react-smooth-dnd";
import { BMIconDrag } from "../icons/FormIcons";
import TextInputField from "./TextInputField";
import TextAreaField from "./TextAreaField";
import TextEmailField from "./TextEmailField";
import TextPhoneField from "./TextPhoneField";
import TextDateField from "./TextDateField";
import SelectBoxField from "./SelectBoxField";
import CheckBoxField from "./CheckBoxField";
import RadioField from "./RadioField";
import TextDayMonthField from "./TextDayMonth";

export default function FormElement(props) {


    const { data = { id: "" }, selected = { id: "" }, setSelected = () => { }, update = () => { }, removeItem = () => { }, form_obj = {} } = props;


    const drawItem = () => {
        switch (data.type) {
            case "text":
                return (<TextInputField data={data} update={update} render />);
            case "textarea":
                return (<TextAreaField data={data} update={update} render />);
            case "email":
                return (<TextEmailField data={data} update={update} render />);
            case "phone":
                return (<TextPhoneField form_obj={form_obj} data={data} update={update} render />);
            case "date":
                return (<TextDateField form_obj={form_obj} data={data} update={update} render />);
            case "daymonth":
                return (<TextDayMonthField form_obj={form_obj} data={data} update={update} render />);
            case "select":
                return (<SelectBoxField form_obj={form_obj} data={data} update={update} render />);
            case "checkbox":
                return (<CheckBoxField form_obj={form_obj} data={data} update={update} render />);
            case "radio":
                return (<RadioField form_obj={form_obj} data={data} update={update} render />);

        }
    }

    const isActive = (selected.id === data.id) ? true : false;


    return (

        <Draggable key={data.id}>
            <div style={{ "position": (isActive) ? "relative" : "inherit", "zIndex": (isActive) ? "2" : "inherit", "width": "470px" }}>
                <div className="float-flex bm-form-element-display">
                    <div className="shim" />
                    <div className="dragHandle" style={{ "padding": "20px 12px", "cursor": "pointer" }}><BMIconDrag style={{ "fill": "#aaa" }} /></div>
                    <div style={{ "width": "100%" }}>
                        <div>
                            <div style={{ "paddingTop": "10px" }}>
                                <div style={{ "position": "absolute", "right": "10px", "zIndex": "1" }}>
                                    <Space size={0}>
                                        {/* <Button className="cnt-btn dragHandle" size="small"><DragOutlined style={{ "fontSize": "13px" }} /></Button> */}
                                        <Popconfirm title={"Are you sure?"} onConfirm={() => removeItem(props.data)}>
                                            <Button type="text" danger className="cnt-btn" size="small"><DeleteFilled /></Button>
                                        </Popconfirm>
                                        <Button type="link" onClick={() => setSelected(props.data)} className="cnt-btn" size="small"><SettingFilled /></Button>
                                    </Space>
                                </div>
                                <div className="shim" /><div className="shim" />
                                {drawItem()}
                            </div>
                        </div>
                    </div>

                    <div style={{ "padding": "20px 8px", "cursor": "pointer", "width": "30px" }}>
                        &nbsp;
                    </div>
                </div>
                <div className="shim" /><div className="shim" />
            </div>
        </Draggable>



    )
}