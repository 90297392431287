import React from "react";
import { Layout, Typography, Divider, Table, Button, Space, DatePicker, Menu, Dropdown, Input } from "antd";
import moment from "moment";

export default function WebstoreOrderingPage() {



    const billing_reports = (
        <Menu>
            <Menu.Item key="1">
                January Billing Report
          </Menu.Item>
            <Menu.Item key="2">
                July Billing Report
          </Menu.Item>
        </Menu>
    );

    const columns = [

        { title: <small>Status</small>, dataIndex: 'status', key: 'status' },
        { title: <small>Action</small>, dataIndex: 'action', key: 'action' },
        {
            title: <small>Ordered</small>, dataIndex: 'ordered', key: 'ordered',
            render: (e) => {
                return moment(e * 1000).format("MMM Do YYYY - h:mma");
            },
            sorter: {
                compare: (a, b) => a.ordered > b.ordered
            }
        },
        { title: <small>Qty</small>, dataIndex: 'qty', key: 'qty' },
        {
            title: <small>Value</small>, dataIndex: 'value', key: 'value',
            sorter: {
                compare: (a, b) => a.value > b.value
            },
            render: (e) => {
                return (e) ? "$" + e.toFixed(2) : "";
            },
        },
        {
            title: <small>Titles</small>, dataIndex: 'titles', key: 'titles', render: (e) => {
                return <a>{e}</a>;
            },
        },
        {
            title: <small>Ref#</small>, dataIndex: 'ref', key: 'ref', render: (e) => {
                return <a>{e}</a>;
            }
        },
        { title: <small>Cust PO#</small>, dataIndex: 'customer_po', key: 'customer_po' },
        {
            title: <small>Customer</small>, dataIndex: 'customer', key: 'customer',
            sorter: {
                compare: (a, b) => a.customer.localeCompare(b.customer)
            }
        },
        {
            title: <small>Store Notes</small>, dataIndex: 'store_notes', key: 'store_notes', render: (e) => {
                return e;
            },
        },
        {
            dataIndex: 'actions', key: 'actions', width: "100px", title: <small>Billing</small>, dataIndex: 'billing', key: 'billing', render: (e) => {
                return drawActions();
            }
        }

    ]

    const summary_columns = [
        { title: <small>Store</small>, dataIndex: 'store', key: 'store' },
        { title: <small>SAN</small>, dataIndex: 'san', key: 'san' },
        { title: <small>Orders</small>, dataIndex: 'orders', key: 'orders' },
        { title: <small>Customers</small>, dataIndex: 'customers', key: 'customers' },
        { title: <small>Unit Copies</small>, dataIndex: 'unit_copies', key: 'unit_copies' },
        { title: <small>Line Items</small>, dataIndex: 'line_items', key: 'line_items' },
        { title: <small>Unique ISBNs</small>, dataIndex: 'unique_isbns', key: 'unique_isbns' },
        {
            title: <small>Retail Value</small>, dataIndex: 'retail_value', key: 'retail_value',
            render: (e) => {
                return (e) ? "$" + e.toFixed(2) : "";
            },
        },
        {
            title: <small>Net for Billing</small>, dataIndex: 'net_for_billing', key: 'net_for_billing',
            render: (e) => {
                return (e) ? "$" + e.toFixed(2) : "";
            },
        },
        { title: <small>Billing</small>, dataIndex: 'billing', key: 'billing' }
    ]

    const summary_data = [

        {
            "store": "Test Multi Store #0",
            "san": "TEST000",
            "orders": 136,
            "customers": 6,
            "unit_copies": 158,
            "line_items": 150,
            "unique_isbns": 30,
            "retail_value": 3567.2,
            "net_for_billing": 3271.42,
            "billing": 0
        }
    ];


    const drawActions = () => {

        return (<Space><Button type="primary" size="small"><small>Resend</small></Button><Button type="primary" size="small"><small>XLS</small></Button><Button danger size="small"><small>Remove</small></Button></Space>)
    }


    const tempdata = [
        {
            "status": "Ordered",
            "action": "Pick-up",
            "ordered": 1609683000,
            "qty": "4",
            "value": 23.73,
            "titles": "4 Titles",
            "ref": "3895604",
            "customer_po": "",
            "customer": "Hunter, Michael",
            "store_notes": "ordered"
        },

        {
            "status": "Ordered",
            "action": "Pick-up",
            "ordered": 1609683101,
            "qty": "4",
            "value": 13.73,
            "titles": "4 Titles",
            "ref": "3895604",
            "customer_po": "",
            "customer": "Braun, Tobias",
            "store_notes": "ordered",
            "actions": <Space><Button type="primary" size="small"><small>Resend</small></Button><Button type="primary" size="small"><small>XLS</small></Button></Space>,
        },

        {
            "status": "Ordered",
            "action": "Pick-up",
            "ordered": null,
            "qty": "4",
            "value": 163.73,
            "titles": "4 Titles",
            "ref": "3895604",
            "customer_po": "",
            "customer": "Batchelor, Eric",
            "store_notes": "ordered",
            "actions": <Space><Button type="primary" size="small"><small>Resend</small></Button><Button type="primary" size="small"><small>XLS</small></Button></Space>,
        }
    ]

    return (
        <>
            <Layout style={{ "backgroundColor": "#fff" }}>
                <Layout.Content style={{ "borderLeft": "1px solid #eee", "padding": "0px" }} >
                    <div style={{ "padding": "30px" }}>
                        <Typography.Title level={4}>WebStore Ordering Activity</Typography.Title>
                        <Divider />

                        <div style={{ "float": "right" }}>
                            <Space>
                                <Dropdown.Button type="primary" overlay={billing_reports}>
                                    Generate PDF
                                </Dropdown.Button>
                            </Space>
                        </div>
                        <Typography.Title level={5}>Period: <DatePicker allowClear={false} format={"MMM Do, YYYY"} defaultValue={moment("January 01, 2021")} /> to <DatePicker allowClear={false} format={"MMM Do, YYYY"} defaultValue={moment("December 31, 2021")} /></Typography.Title>
                        <Divider dashed />
                        <Typography.Title level={5}>Summary:</Typography.Title>
                        <Table size="small" bordered pagination={false}
                            columns={summary_columns}
                            dataSource={summary_data}
                        />
                        <br />
                        <Typography.Title level={5}>Activity:</Typography.Title>
                        <Table expandable expandIcon={<></>} size="small" bordered pagination={false}
                            columns={columns}
                            dataSource={tempdata}
                        />
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}