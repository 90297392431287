import React from "react";
import { Typography, Divider, Button, Row, Col, Space } from "antd";
import Hours from "../Hours";
import FormItem from "antd/lib/form/FormItem";
export default function HoursSection(props) {

    
    const { ws, form, setChanged=() => {}} = props;

    return (
        <>
            <div id="hours" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Hours</Typography.Title>
                <Divider />
                        {/* Hours */}
                        <Space>
                            <div style={{ "width": "35px" }}></div>
                            <div>
                                <Typography.Paragraph type="secondary" >Enter an optional title of only a few words, which will appear above your store hours on the Contact / Hours page, and perhaps in other locations. (Example: "Summer Hours", or "Open 362 days a year!").</Typography.Paragraph>
                                <Typography.Paragraph type="secondary" >Enter opening and closing times for all regular days (Monday to Sunday). To enter the same time for multiple days, control + click the day names then enter the time in any one of the selected days.</Typography.Paragraph>
                                <Typography.Paragraph type="secondary" >Uncheck the 'Open' checkbox to indicate the store is closed that day.</Typography.Paragraph>
                                <Typography.Paragraph type="secondary" >To set closures or special hours on dates such as Christmas, click the Add Special Date button below the table. You can set dates once for the whole year, and should only need to revise them if they change year to year (example: Easter Sunday). On your Contact / Hours page, <strong>these will be displayed if they are upcoming in the next 30 days.</strong></Typography.Paragraph>
                                <Typography.Paragraph type="secondary" >(Note there are custom input areas on your Contact / Hours page where you can insert text, tables, and pictures.)</Typography.Paragraph>
                            
                                <Hours setChanged={setChanged} id="global:ws_hours" form={form} data={ws["global:ws_hours"]} />
                                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                                
                            </div>
                        </Space>
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>
            </div>
        </>
    )
}