import React from "react";
import { Form, Typography, Divider, Space, Button, Row, Col, Checkbox, Input, message } from "antd";
import WebstoreItem from "../WebstoreItem";
import conditional from "../../../utils/conditional";
import { apiCall } from "../../../utils/Api";
import { useState } from "react";
export default function MultistoreSection(props) {

    const { ws, form } = props;

    const [loading, setLoading] = useState(false);

    const verifyEmail = (_field) => {

        setLoading(true);
        apiCall("settings/verifyEmail", { email: form.getFieldValue("multistore_location_info")[_field].order_email }, (_status, _results) => {

            if (_status) {
                message.success("Email verfication successful.");
            } else {
                message.success("Email verfication failed.");
            }
            setLoading(false);
        })
    }

    const parseMessages = () => {

        let ret = window.sitesettings.stores.map(item => {
            return {
                san: item.san,
                message: ""
            }
        });

        if (!ws["global:ms_location_message"]) { return ret; }
        if (!Array.isArray(ws["global:ms_location_message"])) { return ret; }
        if (ws["global:ms_location_message"].length !== window.sitesettings.stores.length) {
            return ret;
        }
        return ws["global:ms_location_message"];
    }

    const [messages, setMessages] = useState(parseMessages());

    const drawPickerMessages = () => {

        //ms_location_message

        return messages.map((item, index) => {

            let _store = window.sitesettings.stores[index];
            let { altname = "" } = _store;

            return (
                <div>
                    <Form.Item noStyle initialValue={messages[index]["san"]} label="Location:" name={["global:ms_location_message", index, "san"]}>
                        <Input hidden maxLength={100} style={{ "width": "410px" }} />
                    </Form.Item>
                    <div><label style={{ "fontSize": "12px" }}>{altname}:</label></div>
                    <Form.Item noStyle initialValue={messages[index]["message"]} label="Message:" name={["global:ms_location_message", index, "message"]}>
                        <Input placeholder="Message..." maxLength={100} style={{ "width": "410px" }} />
                    </Form.Item>
                    <div className="shim" /><div className="shim" /><div className="shim" />
                </div>
            )
        })

    }

    const drawLocations = () => {

        let m = 0;

        let arr = Object.keys(ws["global:multistore_location_info"]).map(item => {
            return ws["global:multistore_location_info"][item];
        })

        return arr.sort((a, b) => a.chainnum - b.chainnum).map((obj) => {
            m++;
            return (
                <div key={obj.san}>
                    <Typography.Title level={5}>{obj.altname}</Typography.Title>
                    <Form.Item initialValue={obj.altname} label="Location name:" name={["global:multistore_location_info", obj.san, "altname"]}>
                        <Input maxLength={80} style={{ "width": "300px" }} />
                    </Form.Item>
                    <conditional.true value={(obj.addr1)}>
                        <div>{obj.addr1}</div>
                    </conditional.true>
                    <conditional.true value={(obj.addr2)}>
                        <div>{obj.addr2}</div>
                    </conditional.true>
                    <conditional.true value={(obj.addr3)}>
                        <div>{obj.addr3}</div>
                    </conditional.true>
                    <conditional.true value={(obj.addr4)}>
                        <div>{obj.addr4}</div>
                    </conditional.true>
                    <conditional.true value={(obj.city)}>
                        <div>{obj.city} {obj.prov}, {obj.country}</div>
                    </conditional.true>
                    <conditional.true value={(obj.postal)}>
                        <div>{obj.postal}</div>
                    </conditional.true>
                    {/* <conditional.true value={(obj.chainnum !== 0)}>
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                        <Form.Item rules={[{ type: "email", message: "Must be a valid email." }]} initialValue={obj.order_email} name={["global:multistore_location_info", obj.san, "order_email"]}>
                            <Input type="email" style={{ "maxWidth": "500px" }} suffix={<Button loading={loading} onClick={() => verifyEmail(obj.san)} type="primary">Verify Email</Button>} enterButton="Verify email" placeholder="name@email.com" />
                        </Form.Item>
                    </conditional.true> */}
                    <Divider dashed />
                </div>
            );

        })

    }

    return (
        <>

            <div id="multistore" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Multi-Store</Typography.Title>
                <Divider />

                {/* Require email verification from new users when they create a new account */}
                <div id="global:ws_nonconsolidated_multistore"></div>
                <WebstoreItem
                    name="global:ws_nonconsolidated_multistore"
                    value={(ws["global:ws_nonconsolidated_multistore"]) ? true : false}
                    heading="Each store location has an individual webstore"
                    description={<div>If you would like a single webstore for your customers to browse and shop, with the ability for your customers to choose their preferred shopping/pickup location, leave this option unchecked. A single domain name will be used.
                        <br /><br />
                        If checked, you will need to maintain a separate consumer webstore for each of your locations. Each of these locations will need unique domain names.
                    </div>}
                />

                <Divider dashed />
                {/* Require email verification from new users when they create a new account */}
                <div id="ms_location_popup_disabled"></div>
                <WebstoreItem
                    name="global:ms_location_popup_disabled"
                    value={(ws["global:ms_location_popup_disabled"]) ? true : false}
                    heading="Disable Location Picker Pop-Up"
                    description={<div>When customers visit your site, a location picker will automatically pop up, asking them to choose their preferred pick up or ship from location. This choice will be displayed within the top search / navigation bar. Customers can change this preference at any time, either by clicking the chosen location within the top navigation bar, or within their cart. <br /> <br />If you disable the automatic location picker pop-up, either your main location will be the default, or the last location the customer selected (if they were logged in). Customers will still be able to access the location picker from the cart or navigation bar even if the automatic pop-up is disabled.
                    </div>}
                />

                <Divider dashed />
                {/* Require email verification from new users when they create a new account */}
                <div id="contact_information"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Contact information for orders</Typography.Title>
                        <Typography.Paragraph type="secondary">If you have a multi-store setup, your customers can choose which location they would like to pick up their books from. Enter a different name for each store in the "Location name" field below (example: "Uptown" or "Downtown").</Typography.Paragraph>
                        <Typography.Paragraph type="danger">Note that unless you enter a name for a store, it will NOT be shown in the "Choose your store" dialog.</Typography.Paragraph>
                        <Typography.Paragraph type="secondary">You can also upload a logo image for each location if you desire.</Typography.Paragraph>
                    </div>
                </Space>

                <Divider dashed />
                {/* Require email verification from new users when they create a new account */}
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        {drawLocations()}
                    </div>
                </Space>

                <Divider dashed />
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Location picker message
                            <div><em><small style={{ "color": "#888", "fontSize": "12px" }}>Max 100 characters</small></em></div>
                        </Typography.Title>
                        {drawPickerMessages()}
                    </div>
                </Space>
                <div className="shim" /><div className="shim" /><div className="shim" />
            </div>
        </>
    )
}