import { Divider, Typography, Layout, Space, Table, Button, Form, Switch, Input, Row, Col, message, Card, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { apiCall } from "../../utils/Api";
import { FilePdfOutlined } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";
import moment from 'moment';
import LayoutHeader from "../../layouts/partials/LayoutHeader";

export default function BackupPage() {

    const [files, setFiles] = useState([]);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [range, setRange] = useState([]);

    const [form] = useForm();
    const verifyEmail = () => {
        apiCall("settings/verifyEmail", { email: form.getFieldValue("email") }, (_status, _results) => {
            if (_status) {
                message.success("Verification email sent.")
            } else {
                message.error("Verification email failed.")
            }
        })
    }


    const getBackups = () => {
        apiCall("backup/get", {}, (_status, _results) => {
            if (_status) {
                setResults(_results);
                setFiles(_results.files);
                setLoading(false);
            }

        })
    }

    const setBackups = (_v) => {
        apiCall("backup/set", _v, (_status, _results) => {
            if (_status) {
                setResults(_results);
                setFiles(_results.files);
                message.success("Saved successfully.");
            } else {
                message.error(_results);
            }
        })
    }


    const data = () => {
        return files.filter(item => {

            if(range.length === 2){

                if(moment(item.date * 1000).isBetween(moment(range[0],range[1]))){
                    return true;
                } else {
                    return false;
                }

            } else {
                return true; 
            }

        }).map((item, index) => {
            let _path = "/download/store/backup/download?store_id=" + window.store_id + "&filename=" + item.filename;

         

            return (

                

                { key: index, date: moment(item.date * 1000).format("MMMM Do YYYY, h:mma"), size: item.size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), filename: (<a target="_blank" href={_path} >{item.filename}</a>) }
            )
        });
    }

    useEffect(getBackups, []);


    if (loading) {
        return (<></>);
    }

    return (
        <>

            <Layout className="layout" >

                <LayoutHeader
                    title="Backups"
                    description="Use this page to download backups and configure notification options."
                />

                <div style={{ "padding": "20px", "paddingBottom": "0px" }}>
                    <div style={{ "float": "right" }}>
                        <Button size="small" onClick={() => form.submit()} type="primary" htmlType="submit"><small>Save settings</small></Button>
                    </div>
                    <Typography.Title level={5}>Notification Options</Typography.Title>
                    <Card>
                        <div className="shim"></div><div className="shim"></div>
                        <Form form={form} onFinish={(_f) => setBackups(_f)}>
                            <Row gutter={[30, 30]}>
                                <Col md={24} xl={8}>
                                    <Space align="start" size={15}>
                                        <Form.Item valuePropName='checked' initialValue={(results.email_new)} name={"email_new"} noStyle>
                                            <Switch size="small" />
                                        </Form.Item>
                                        <div>
                                            <Typography.Title level={5}>Email new backups</Typography.Title>
                                            <Typography.Paragraph type="secondary" level={5}>Email me each time a new backup is received</Typography.Paragraph>
                                        </div>
                                    </Space>
                                </Col>
                                <Col md={24} xl={8}>
                                    <Space align="start" size={15}>
                                        <Form.Item valuePropName='checked' initialValue={(results.email_warning)} name={"email_warning"} noStyle>
                                            <Switch size="small" />
                                        </Form.Item>
                                        <div>
                                            <Typography.Title level={5}>Warning for old backups</Typography.Title>
                                            <Typography.Paragraph type="secondary" level={5}>Email me when my last backup becomes over two days old</Typography.Paragraph>
                                        </div>
                                    </Space>
                                </Col>
                                <Col md={24} xl={8}>
                                    <div>
                                        <Typography.Title level={5}>Send emails to:</Typography.Title>
                                        <Form.Item rules={[{ type: "email", message: "Must be a valid email." }]} initialValue={results.email} name={"email"}>
                                            <Input type="email" style={{ "maxWidth": "400px" }} suffix={<Button style={{ "marginRight": "-6px" }} size="small" onClick={() => verifyEmail()} type="primary"><small>Verify Email</small></Button>} enterButton="Verify email" placeholder="name@email.com" />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </div>



                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <Typography.Title level={5}>Backups</Typography.Title>
                        <Typography.Paragraph type="secondary">
                            Below are the most recent backups that we have received from your Bookmanager system.<br />
                            Clicking a filename will allow you to download a backup (e.g. for recovery purposes).
                        </Typography.Paragraph>

                        <div className="shim"></div><div className="shim"></div>  <div className="shim"></div><div className="shim"></div>



                        <Card bodyStyle={{ "padding": "0px" }}>
                            <Table size="small" pagination={true}
                                // title={() => {

                                //     return (
                                //         <div>
                                //             <Space>
                                //                 <DatePicker.RangePicker value={range} onChange={(e) => {
                                //                     console.log(e);
                                //                     setRange(e);
                                //                 }} picker="month" ></DatePicker.RangePicker>
                                //                 <Button onClick={() => setRange([])} disabled={(range.length === 0)}>Clear</Button>
                                //             </Space>
                                //         </div>
                                //     )
                                // }}

                                columns={[
                                    { key: "blank", width: "10px" },
                                    { title: <small>Date</small>, dataIndex: 'date', key: 'date' },
                                    { title: <small>Size</small>, dataIndex: 'size', key: 'size' },
                                    { title: <small>Backup Filename</small>, dataIndex: 'filename', key: 'filename' },
                                ]}
                                dataSource={data()}
                            />
                        </Card>
                        <br />
                        <Typography.Paragraph type="secondary">
                            <em><small>
                                Although unlikely, it is possible that
                                our security measures are compromised in a way that exposes your data to others. It is also
                                possible that the online backup may not have usable data for recovery (due to any number
                                of technical or human issues.)</small>
                            </em>
                        </Typography.Paragraph>

                        <Divider dashed />
                        <Row gutter={[30, 30]}>
                            <Col md={24} xl={24}>
                                <Space align="start" size={15}>
                                    <FilePdfOutlined />
                                    <div>
                                        <Typography.Text level={5}>By using this service you are agreeing to our <a href="https://bookmanager.com/i/tbm/Backup_Terms_of_Use.pdf" target="_blank">terms &amp; conditions and disclaimer.</a> Please read it for your safety.</Typography.Text>
                                    </div>
                                </Space>
                            </Col>
                        </Row>

                    </div>
                </Layout.Content>
            </Layout>
        </>
    );
}