import React, { useState, useEffect } from "react";
import { Layout, Divider, Table } from "antd";
import DragTable from "../components/DragTable";
import RRule from "../components/events/RRule";
import EventItem from "../components/events/EventItem";

export default function TestPage() {

    return (
        <>
            <Layout className="layout" >
                <Layout.Content style={{"padding": "0px" }} >
                    <div style={{"padding" : "20px"}}>
                        <EventItem />
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}