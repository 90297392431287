import { Affix, Alert } from "antd";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

// import Order from "../components/Order";
import ItemHistory2 from "../components/ItemHistory2";
import ListCreatePdf from "../components/lists/ListCreatePdf";
import ListPrint from "../components/lists/ListPrint";
import PreviousOrder from "../components/PreviousOrder";
import ProcessPDF from "../components/ProcessPdf";
import Pubstock from "../components/Pubstock";
import Slider from "../components/Slider";
import Title from "../components/title/Title";
import TitleDetails from "../components/title/TitleDetails";
import Blank from "../layouts/Blank";
import Default from "../layouts/Default";
import Left from "../layouts/Left";
import LoginLayout from "../layouts/LoginLayout";
import Standard from "../layouts/Standard";
import Test from "../layouts/Test";
import Test2 from "../layouts/Test2";
import URLShortcuts from "../layouts/URLShortcuts";
import AnalyticsTableHtml from "../pages/analytics/AnalyticsTableHtml";
import BrowsePage from "../pages/BrowsePage";
import BrowsePage2 from "../pages/BrowsePage2";
import BrowsePage3 from "../pages/BrowsePage3";
import BrowsePage4 from "../pages/BrowsePage4";
import BusinessDirectoryPage from "../pages/BusinessDirectoryPage";
import CartPage from "../pages/CartPage";
import CataloguesPage from "../pages/CatalougesPage";
import AppearancePage from "../pages/content/AppearancePage";
import BrandingPage from "../pages/content/BrandingPage";
import EventsPage from "../pages/content/EventsPage";
import MyReps from "../pages/content/MyReps";
import SiteContentPage from "../pages/content/SiteContentPage";
import SitePagesPage from "../pages/content/SitePagesPage";
import GiftcardActivityPage from "../pages/GiftcardActivityPage";
import ManageCustomers from "../pages/ManageCustomers";
import ManageGiftRegistriesPage from "../pages/ManageGiftRegistriesPage";
// import MyListsPage from "../pages/content/MyListsPages";
import MyListsPage from "../pages/MyListsPage";
import PickListPage from "../pages/PicklistPage";
import PrintGiftRegistry from "../pages/PrintGiftRegistry";
import ReceiptLogoPage from "../pages/ReceiptLogoPage";
import RemoteSupportPage from "../pages/RemoteSupportPage";
import AnalyticsPage from "../pages/reports/AnalyticsPage";
import BackupPage from "../pages/reports/BackupsPage";
import CalandarReportsPage from "../pages/reports/CalandarReportsPage";
import EdiActivityPage from "../pages/reports/EdiActivityPage";
import LibraryInvestmentProjectPage from "../pages/reports/LibraryInvestmentProjectPage";
import ManageTitleComments from "../pages/reports/ManageTitleCommentsPage";
import PreviousOrdersPage from "../pages/reports/PreviousOrdersPage";
import SalesComparisonsPage from "../pages/reports/SalesComparisonsPage";
import SiteUsagePage from "../pages/reports/SiteUsagePage";
import UsCanPriceComparisonPage from "../pages/reports/UsCanPriceComparisonPage";
import WebstoreOrderingPage from "../pages/reports/WebstoreOrderingPage";
import GiftCardsPage from "../pages/settings/GiftCardsPage";
import PermissionsPage2 from "../pages/settings/PermissionsPage2";
import PermissionsPage3 from "../pages/settings/PermissionsPage3";
import PermissionsPage4 from "../pages/settings/PermissionsPage4";
import PromoCodesPage from "../pages/settings/PromoCodesPage";
import SalesPromotionsPage from "../pages/settings/SalesPromotionsPage";
import StoreStockSharing from "../pages/settings/StoreStockSharing";
import WebstoreSettingsPage from "../pages/settings/WebstoreSettingsPage";
import SiteUploadsPage from "../pages/SiteUploadsPage";
import StockCheckPage from "../pages/StockCheckPage";
import StockTitleUploadPage from "../pages/StockTitleUploadPage";
import SuggestedCartsPage from "../pages/SuggestedCartsPage";
import SwitchPage from "../pages/SwitchPage";
import TaxPreferencesPage from "../pages/TaxPreferencesPage";
import TestPage from "../pages/TestPage";
import TestPage2 from "../pages/TestPage2";
import ReloadStore from "../ReloadStore";
import Ani from "../utils/Ani";
import { apiCall } from "./Api";
import LiveToggle from "./LiveToggle";
import { useSession } from "./Session";
import Splitter from "./Splitter";
import { objectToPath } from "./Utils";
import CustomerSalesExport from "../pages/reports/CustomerSalesExport";
import ProcessReport from "../components/ProcessReport";
import TimeOfDaySales from "../pages/reports/TimeOfDaySales";
import EmailedGiftCards from "../pages/reports/EmailedGiftCards";
import CustomForms from "../pages/reports/CustomForms";
import FormBuilder from "../layouts/FormBuilder";
import EventsPage2 from "../pages/content/EventsPage2";
import QuickbooksIntegration from "../pages/settings/QuickbooksIntegration";

function SiteRouter(props) {

    const {dark_mode = ""} = props; 
    const [collapsed, setCollapsed] = useState(true);
    const [session, setSession] = useSession();
    const location = useLocation(); 
    const history = useHistory();
    const [redirect, setRedirect] = useState(history.location.pathname);

    

    let _defaultpath = "/purchasing/browse";

    if (session.hasOwnProperty("filter_preset_defaults")) {
        if (session.filter_preset_defaults.hasOwnProperty("status")) {
            _defaultpath = "/purchasing/browse" + objectToPath(session.filter_preset_defaults.status.selections);

            _defaultpath = _defaultpath.replace(/\(/g, '%28')  // Encode (
                .replace(/\)/g, '%29')  // Encode )
                .replace(/:/g, '%3A');  // Encode :
        }
    }



    // Log Errors

    const logError = (e, s, l, c, n) => {

        if (e === "Script error.") {
            return;
        }
        if (err.error_info == 'ResizeObserver loop limit exceeded') {
            return;
        }
        if (err.error_info == 'Uncaught session not open') {
            return;
        }
        let err = {};
        err.location = (location && location.pathname) ? location.pathname : "no location";
        err.user_agent = (navigator && navigator.userAgent) ? navigator.userAgent : "no agent";
        err.error_info = (e) ? e : "no error info";
        err.source = (s) ? s : "no source";
        err.lineno = (l) ? l : "no lineno";
        err.colno = (c) ? c : "no colno";
        err.error = (n) ? n : "no error";

        apiCall("log/error", err, () => { });
    }

    const errorListener = () => {
        window.onerror = (e, s, l, c, n) => {
            logError(e, s, l, c, n);
        }
    }

    useEffect(errorListener, []);


    const drawRoutes = () => {

        if (!session.logged_in) {

            return (
                <Switch>
                    {/* Available without login */}
                    <Route path="/switch/:ses" children={<Blank><SwitchPage /></Blank>} />
                    <Route path="/stockcheck/:isbn/:session/:store_id" children={<Blank><StockCheckPage /></Blank>} />
                    {/* Send all others to login screen */}
                    <Route exact path="/">
                        <LoginLayout setRedirect={setRedirect} dark={props.dark} redirect={redirect} />
                    </Route>
                    <Redirect from="*" to="/" />
                </Switch>
            )

            return <Route path="/" children={<LoginLayout />} />
        }

       


        const drawDarkMode = () => {

            if(location.pathname.includes("/stockcheck/")){
                return <></>
            } else {
                return dark_mode; 
            }

        }

    

  

        


        return (
            <>
            
                <Switch>
                    {/* Test */}

                    <Route path="/table/" children={<AnalyticsTableHtml />} />
                    <Route path="/picklist/:action/:ee_def_id" children={<Blank><PickListPage /></Blank>} />
                    <Route path="/previous_order/:order_id" children={<Blank><PreviousOrder /></Blank>} />
                    <Route path="/registry/:registry_id" children={<Blank><PrintGiftRegistry /></Blank>} />
                    <Route path="/stockcheck/:isbn/:session/:store_id" children={<Blank bg_color="#fff"><StockCheckPage /></Blank>} />

                    <Route path="/switch/:ses" children={<Blank><SwitchPage /></Blank>} />
                    <Route path="/item/:url_isbn" children={<Blank><Title /></Blank>} />

                    <Route path="/reload" children={<Blank><ReloadStore /></Blank>} />
                    <Route path="/pdf/:process_id" children={<Blank><ProcessPDF /></Blank>} />
                    <Route path="/report/:process_id" children={<Blank><ProcessReport /></Blank>} />

                    <Route path="/test" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><TestPage /></Default>} />
                   
                    {/* <Route path="/test2" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><TestPage2 /></Default>} /> */}

                    <Route path="/print/list/:list_id" children={<Blank><ListPrint /></Blank>} />

                    {/* <Route path="/test3" children={<Left><BrowsePage /></Left>} />
                    <Route path="/test2" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><BrowsePage3 /></Default>} />

                     */}

               


                    <Route path="/title" children={<Left collapsed={collapsed} setCollapsed={setCollapsed}><Ani>
                        <div style={{ "padding": "30px" }}>
                            <TitleDetails isbn="ATEST00000000" />
                            {/* <TitleDetails isbn="9780062060624" /> */}
                        </div>

                    </Ani></Left>} />

                    {/* Settings */}
                    <Route path="/settings/web-store-settings/:section" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><WebstoreSettingsPage /></Default>} />
                    <Route path="/settings/web-store-settings/:section/:search" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><WebstoreSettingsPage /></Default>} />
                    <Route path="/settings/web-store-settings" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><WebstoreSettingsPage /></Default>} />
                    <Route path="/settings/permissions" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><PermissionsPage4 /></Default>} />
                    {(window.sitesettings.is_bm && <Route path="/settings/gift-cards" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><GiftCardsPage /></Default>} />)}
                    <Route path="/settings/store-stock-sharing" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><StoreStockSharing /></Default>} />
                    <Route path="/settings/promo-codes" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><PromoCodesPage /></Default>} />
                    <Route path="/settings/sales-promotions" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SalesPromotionsPage /></Default>} />
                    <Route path="/settings/tax-preferences" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><TaxPreferencesPage /></Default>} />
                    <Route path="/settings/receipt-logo" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><ReceiptLogoPage /></Default>} />

                    {((session.permissions.interm_upload_access) && <Route path="/settings/stock-title-uploads" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><StockTitleUploadPage /></Default>} />)}

                    {((session.permissions.titlelist_onhand) && <Route path="/settings/stock-managment" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><StockTitleUploadPage management={true} /></Default>} />)}

                    {/* Content */}
                    <Route path="/content/appearance" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><AppearancePage /></Default>} />
                    <Route path="/content/branding" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><BrandingPage /></Default>} />
                    <Route exact path="/content/custom_forms" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><CustomForms /></Default>} />
                    <Route path="/content/custom_form/:form_id" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><FormBuilder /></Default>} />
                    
                    <Route path="/content/events/x/:event_id" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} >{(window.sitesettings.using_events_v2) ? <EventsPage2 /> : <EventsPage />}</Default>} />
                    <Route path="/content/events" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} >{(window.sitesettings.using_events_v2) ? <EventsPage2 /> : <EventsPage />}</Default>} />
                    
                    {/* <Route path="/content/events" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ></Default>} /> */}
                    <Route path="/content/site-content" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SiteContentPage /></Default>} />
                    <Route path="/content/site-uploads" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SiteUploadsPage /></Default>} />
                    <Route path="/content/site-pages" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SitePagesPage /></Default>} />
                    <Route path="/content/url-shortcuts" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><URLShortcuts /></Default>} />
                    {/* <Route path="/content/my-lists" children={ <Default dark={props.dark} ><MyListsPage /></Default>} /> */}

                    {/* Report */}
                    <Route path="/reports/analytics" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><AnalyticsPage /></Default>} />
                    {(window.sitesettings.is_bm && <Route path="/reports/time-of-day-sales" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><TimeOfDaySales /></Default>} />)}
                    {(window.sitesettings.is_bm && <Route path="/reports/backups" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><BackupPage /></Default>} />)}
                    <Route path="/reports/calandar-reports" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><CalandarReportsPage /></Default>} />
                    {(window.sitesettings.is_bm && <Route path="/reports/edi-activity" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><EdiActivityPage /></Default>} />)}
                    <Route path="/reports/library-investment-project" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><LibraryInvestmentProjectPage /></Default>} />
                    <Route path="/reports/manage-title-comments" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><ManageTitleComments /></Default>} />
                    <Route path="/reports/manage-gift-registries" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><ManageGiftRegistriesPage /></Default>} />
                    <Route path="/reports/manage-customers" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><ManageCustomers /></Default>} />
                    {/* <Route path="/reports/previous-orders/:search" children={ <Default dark={props.dark} ><PreviousOrdersPage /></Default>} /> */}
                    <Route path="/reports/previous-orders" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><PreviousOrdersPage /></Default>} />
                    {(window.sitesettings.is_bm && <Route path="/reports/industry-sales-comparisons" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SalesComparisonsPage /></Default>} />)}
                    <Route path="/reports/site-usage" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SiteUsagePage /></Default>} />
                    <Route path="/reports/uscan-price-comparison" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><UsCanPriceComparisonPage /></Default>} />
                    <Route path="/reports/webstore-ordering" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><WebstoreOrderingPage /></Default>} />
                    <Route path="/reports/emailed-gift-cards" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><EmailedGiftCards /></Default>} />
                    {(window.sitesettings.is_bm && <Route path="/reports/gift-card-activity" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><GiftcardActivityPage /></Default>} />)}
                    {(window.sitesettings.is_bm && <Route path="/reports/customer-sales-export" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><CustomerSalesExport /></Default>} />)}
                    

                    {/* Purchasing */}
                    <Route path="/purchasing/cart" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><CartPage /></Default>} />
                    <Route path="/purchasing/browse" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><BrowsePage4 /></Default>} />
                    <Route path="/purchasing/catalog" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><BrowsePage4 /></Default>} />
                    <Route path="/purchasing/my-lists" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><MyListsPage /></Default>} />
                    <Route path="/purchasing/catalogues" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><CataloguesPage collapsed={collapsed} /></Default>} />
                    <Route path="/purchasing/business-directory" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><BusinessDirectoryPage collapsed={collapsed} /></Default>} />
                    <Route path="/purchasing/my-reps" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><MyReps /></Default>} />
                    <Route path="/purchasing/suggested-carts" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SuggestedCartsPage /></Default>} />

                    {/* API Interations */}
                    <Route path="/purchasing/suggested-carts" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><SuggestedCartsPage /></Default>} />
                    {/* Support */}

                    <Route path="/callbacks/quickbooks" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><QuickbooksIntegration /></Default>} />
                    <Route path="/support/remote-support" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><RemoteSupportPage /></Default>} />

                    {/* <Route path="/test" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><FormBuilder /></Default>} /> */}
                    
                    <Route path="/webstore" children={<Default drawDarkMode={drawDarkMode} dark={props.dark} ><WebstoreSettingsPage /></Default>} />
                    {/* <Route path="/" children={<LoginLayout />} /> */}
                    <Redirect from="/" to={_defaultpath} />
                    <Redirect from="" to={_defaultpath} />
                    <Route path="*" children={<Standard />} />
                </Switch>
                <LiveToggle />
            </>
        )
    }

    return drawRoutes();

}

export default SiteRouter;
