import { Popover, Select, Button, Space, Divider, Input, message } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import AccountCard from "./AccountCard";
import BroadcastMessage from "./BroadcastMessage";
import LogOutLocations from "./LogOutLocations";
import { SelectOutlined } from '@ant-design/icons';
export default function AccountBox() {

    const history = useHistory(); 
    const [session, setSession] = useSession();
    const [trigger, setTrigger] = useState(false);

    const updateIdentity = (_identity) => {

        if(!_identity){
            return; 
        }
      
        apiCall("session/setPicklistIdentity", {identity : _identity }, (_status, _result) => {
            if(_status){
                setSession({...session, "picklist_identity" : _result.picklist_identity});
            } else {
                message.error(_result.error);
            }
        })
    }

    

    const drawCorps = () => {        
        return session.available_corps.filter(item => item.name !== "My Personal Account").map(item => {
            return (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
        })
    }

    const switchStore = (_id) =>{
        history.push("/reload", {id: _id, path : history.location.pathname});
    }
    
    const logout = () =>{
        apiCall("account/logout", {}, (_status, _result) =>{
                window.store_id = false; 
                setSession(_result);
        })
    }

    const content = (
        <Space direction="vertical">
            <div className="float-flex">
                <label>Account</label>
                <div><Button onClick={() => window.open(window.sitesettings.url, "_blank")} type="primary" size="small"><small><SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "10px" }} /> Customer Site</small></Button></div>
            </div>
            <div className="shim" />
            
            <Select onChange={(e) => switchStore(e)} defaultValue={session.store_id} style={{ width: 250 }}>
                {drawCorps()}
            </Select>
            <label>Your identity</label>
            <Input.Search placeholder="Create identity..." onSearch={(e) => updateIdentity(e)} enterButton={<small>Save</small>} defaultValue={session.picklist_identity}></Input.Search>
            
            <BroadcastMessage trigger={trigger} />
            <Divider dashed style={{ "margin": "5px 0px" }} />
            <LogOutLocations trigger={trigger} />
            <Button onClick={() => logout()} danger type="primary" block>Log out</Button>
        </Space>
    );

    return (
        <>
            <Popover onVisibleChange={(e) => { if(e) {
                setTrigger(!trigger);
            } }} placement="bottomRight" content={content} trigger="click">    
                <div style={{"cursor" : "pointer"}}><AccountCard store_name={session.picklist_identity} contact={session.contact} /></div>
            </Popover>
        </>
    );
}