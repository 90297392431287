import React, { useEffect, useState } from "react";
import { Space, Card, Layout, Typography, Divider, Input, Button, Table, message, Popconfirm } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import LayoutHeader from "./partials/LayoutHeader";
import { apiCall } from "../utils/Api";
import { CopyOutlined } from '@ant-design/icons';
export default function URLShortcuts() {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [value, setValue] = useState('');
    const [url, setURL] = useState('')

    const slugify = (text) => {
        return text
          .toString()
          .toLowerCase()
          .trim()
          .replace(/\s+/g, '-')          // Replace spaces with -
          .replace(/[^\w\-]+/g, '')      // Remove all non-word chars
          .replace(/\-\-+/g, '-')        // Replace multiple - with single -
          .replace(/^-+/, '')            // Trim - from start of text
          .replace(/-+$/, '');           // Trim - from end of text
      };
    
      const handleChange = (e) => {
        const slugifiedValue = slugify(e.target.value);
        setValue(slugifiedValue);
      };

 


    const fetchShortcuts = () => {
        apiCall("url_shortcut/getShortcuts", {}, (_status, _result) => {
            if (_status) {
                setResults(_result);
            } else {
                message.error(_result.error);
            }
        })
    }

    useEffect(fetchShortcuts, []);

    const isValidURL = (url) => {
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-zA-Z\\d_]*)?$', 'i'); // fragment locator
        return !!urlPattern.test(url);
    }


    const addShortcut = () => {

        if(!value){
            message.error("Must have a keyword");
            return; 
        }

        if(!url || !isValidURL(url)){
            message.error("Must have a valid URL");
            return; 
        }

        setLoading(true);
        apiCall("url_shortcut/addShortcut", {keyword : value, url : url}, (_status, _result) => {
            if(_status){
                message.success("Shortcut added successfully!");
                setValue("");
                setURL("");
                setResults(_result);
            } else {
                message.error(_result.error)
            }
            setLoading(false);
        })
    }

    const deleteShortcut = (_keyword) => {
        setLoading(true)
        apiCall("url_shortcut/deleteShortcut", {keyword : _keyword}, (_status, _result) => {
            if(_status){
                setResults(_result)
                message.success("Shortcut deleted successfully!")
            } else {
                message.error(_result.error)
            }
            setLoading(false)
        })
    }

    const CopyBtn = (props) => {

        const copyToClipboard = (_isbns) => {
            if (!navigator.clipboard) {
                message.error("Can't copy");
                return;
            }
    
            navigator.clipboard.writeText(_isbns);
            setCopied(true);
            setTimeout(() => {
                setCopied(false)
            }, 2000);
        }

        const {keyword = ""} = props; 
        const [copied, setCopied] = useState(false);
        const copyLabel = (copied) ? "Copied!" : "Copy";
        return(<Button size="small" block onClick={() => copyToClipboard(window.sitesettings.url + keyword)} type="primary"><small><CopyOutlined /> {copyLabel}</small></Button>)
    }


    const columns = [
        
        { title: <small>Keyword</small>, dataIndex: 'keyword', key: 'keyword', width: 140, ellipsis: true },
        { title: <small>Original URL</small>, dataIndex: 'path', key: 'path', ellipsis: true },
        {
            title: <small>New URL</small>, dataIndex: 'new_url', key: 'new_url', ellipsis: true, render: (e, f) => {
                return window.sitesettings.url + f.keyword;
            }
        },
        {
            title: <small>Share</small>, dataIndex: 'copy', key: 'copy', width: 90, render: (e, f) => {
                return (<CopyBtn keyword={f.keyword} />)
            }
        },
        { dataIndex: 'delete', title: <small>Remove</small>, key: 'delete', width: 80, render : (e, f) => {
            return(<Popconfirm title="Are you sure?" onConfirm={() => deleteShortcut(f.keyword)}><Button size="small" block danger type="primary"><small>Remove</small></Button></Popconfirm>)
        } },
    ];

    // const data = [
    //     { key: '1', delete: <Button type="primary" danger size="small"><small>Delete</small></Button>, keyword: 'urlshortcutpath', original_url: "https://staging.bookmanager.com/test000/?q=h.siteedit.urlshortcuts", new_url: "https://staging.bookmanager.com/test000/urlshortcutpath", copy: <Button type="primary" size="small"><small>Copy</small></Button> },
    //     { key: '1', delete: <Button type="primary" danger size="small"><small>Delete</small></Button>, keyword: 'urlshortcutpath', original_url: "https://staging.bookmanager.com/test000/?q=h.siteedit.urlshortcuts", new_url: "https://staging.bookmanager.com/test000/urlshortcutpath", copy: <Button type="primary" size="small"><small>Copy</small></Button> }
    // ];

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="URL Shortcuts"
                    description={<>
                        <div>Use this page to create and share more consumer friendly URLs for pages and links on your site.</div>
                    </>}
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <Typography.Paragraph type="secondary">Type your desired shortcut in the <strong>Keyword</strong> field, paste the URL you want that shortcut to link to in the <strong>Original URL field</strong>, then click <strong>Create</strong>. <br /> The <strong>New URL</strong> field will have a user friendly link that you can share. <br />Click the new URL and copy it, or simply click the <strong>Share</strong> button to copy the link to your clipboard.</Typography.Paragraph>
                        <Typography.Paragraph type="secondary"><strong>Note:</strong> Keywords should not contain spaces or symbols <strong>( @ # $ % ^ &amp; * )</strong></Typography.Paragraph>

                        <br />
                        <div style={{ "margin": "0 auto", "textAlign": "center", "width": "900px" }}>
                            <Card  >
                                <Typography.Title level={5}>Your Domain: {window.sitesettings.url}</Typography.Title>
                                <br />
                                <Space size="large">
                                    <Input onChange={handleChange} value={value} style={{ "width": "330px" }} placeholder="Keyword"></Input>
                                    <PlusOutlined />
                                    <Input value={url} onChange={(e) => setURL(e.target.value)} style={{ "width": "330px" }} placeholder="Original URL"></Input>
                                    <Button onClick={() => addShortcut()} type="primary">Create</Button>
                                </Space>
                            </Card>
                        </div>
                        <Divider />

                        <Table bordered size="small" pagination={{ defaultPageSize: 20 }}
                            columns={columns}
                            rowKey="id"
                            loading={loading}
                            dataSource={results}
                        />
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}