
import React, { useState } from "react";
import { Button, Divider, Typography, Table, Modal, message, Input, Space } from "antd";
import { apiCall } from "../utils/Api";
import { bmDate, objectToPath } from "../utils/Utils";
import { FunnelPlotOutlined } from '@ant-design/icons'
import { useHistory } from "react-router-dom";
export default function ListInclude(props) {

    const { path = "", getBrowse = () => { }, searchFilters = {}, setSearchFilters = () => { }} = props;
    const [res, setRes] = useState({rows : []})
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [search, setSearch] = useState("");
    const history = useHistory(); 

    const linkOut = (_id) => {
        window.open("/purchasing/browse/filter/x/" + _id + "/v/sequence", "_blank");
    }

    const openModal = () => {
        setVisible(true);
        fetchMyLists();
    }

    const fetchMyLists = (_search) => {
        setLoading(true);
        let obj = {
            g: "-Pick List" // Exclude picklists....
        }
        if(_search){
            obj.t = _search; 
        }
        apiCall("titlelist/getMyLists", obj, (_status, _result) => {
            if (_status) {
                setRes(_result);
            } else {
                message.error(_result.error);
            }
            setLoading(false);
        })
    }

    const close = () => {
        setVisible(false);
    }


    const setList = (_list_id = "", _include = true) => {
        let _sf = {...searchFilters};
        _sf.x = (_include) ? _list_id : "-" + _list_id; 
        history.push({ pathname: path + objectToPath(_sf) })
        setSearchFilters(_sf);
        getBrowse(false, _sf);
        close(); 
    }

    const drawMyLists = () => {
        return (
            <Modal destroyOnClose={true} onCancel={() => close(false)} footer={null} visible={visible}>
                <div style={{ "padding": "0px" }} >
                   
                    <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                        <div>My Lists</div>
                        <Divider dashed style={{ "marginTop": "10px", "marginBottom": "5px" }} />
                    </Typography.Title>
                    <div className="shim" />
                    <Input.Search allowClear={true} onBlur={(e) => {
                        if(e.target.value === ""){
                            fetchMyLists("");
                        }
                    }} onSearch={(_search) => fetchMyLists(_search)} onChange={(e) => setSearch(e.target.value)} placeholder="Search lists..." style={{"width" : "100%"}} enterButton />
                    <div className="shim" />
                    <div className="shim" />
                    <Table
                        className="bdr"
                        loading={loading}
                        rowKey="list_id"
                        size="small"
                        pagination={false}
                        columns={columns}
                        scroll={{ y: 285 }}
                        dataSource={res.rows}
                    />
                    <br />
                    <div style={{ "float": "right" }}>
                        <Button onClick={() => close(false)}>Close</Button>
                    </div>
                    <div><em>Showing 25 most recently updated lists. Use Search to find more.</em></div>
                    <br clear="all" />
                </div>
            </Modal>
        )
    }


    const columns = [
       
        {
            title: <small>Name</small>, dataIndex: 'name', key: 'name', render: (e, f) => {
                return (e) ? <a onClick={() => linkOut(f.list_id)} >{e}</a> : "";
            }
        },
        { title: <small>Titles</small>, width: 60, dataIndex: 'isbns_count', key: 'isbns_count' },
        {
            title: <small>Updated</small>, width: 80, dataIndex: 'date_updated', key: 'date_updated', render: (e) => {
                return (e) ? <nobr>{bmDate(e * 1000)}</nobr> : ""
            }
        },

        { title: <small></small>, width: 140, dataIndex: 'actions', key: 'actions', render: (e, f) => {

            return(
                <Space>
                    <Button onClick={() => setList(f.list_id, true)} size="small" type="primary"><small>Include</small></Button>
                    <Button onClick={() => setList(f.list_id, false)} size="small" danger type="primary"><small>Exclude</small></Button>
                </Space>
            )
            
        }},
    ]


    return (
        <>
            {drawMyLists()}
            <Button size="small" onClick={() => openModal()} type="primary"><small><FunnelPlotOutlined />&nbsp;Lists</small></Button>
        </>
    )
}
    