import React, { useState } from "react";
import { Typography, Divider, Button, Layout, Upload, Spin, Table, Form, Space, AutoComplete, Select, message, Input } from "antd";
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";
import Modal from "antd/lib/modal/Modal";
import FilterPresetPicker from "./FilterPresetPicker";
export default function TitleManager(props) {

    const { hide_header = false, allowPresets = false, presets = [], form, form_key = "", preset_key = "", title = "", subtitle = "", bottom = false, setChanged = () => { } } = props;
    const [pending, setPending] = useState("");
    const [titles, setTitles] = useState((props.titles) ? props.titles : []);
    const [filters, setFilters] = useState((props.filters) ? props.filters : []);
    const [fileUploaded, setFileUploaded] = useState(false);

    const [selectedPresets, setSelectedPresets] = useState([])

    const columns = [
        { title: <small></small>, dataIndex: 'eisbn', key: 'eisbn', width: 50, render: (e) => { return <img className="lightshadow" onError={(e) => e.target.src = "/no-cover.png"} style={{ "maxHeight": "34px", "maxWidth": "30px" }} src={"https://bookmanager.com/i/slider_23.php?b=" + e} /> } },
        { title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn', width: 150 },
        { title: <small>Title</small>, dataIndex: 'title', key: 'title', },
        {
            title: <small>Remove</small>, width: "60px", dataIndex: 'success', key: 'success', render: (e, f) => {
                return (<Button onClick={(e) => removeISBN(f.isbn)} type="link" size="small" danger  ><small><DeleteOutlined /></small></Button>)
            }
        },
    ];

    const removeISBN = (_isbn) => {

        setChanged(true);
        let _titles = [...titles.filter(item => item.isbn !== _isbn)]
        form.setFieldsValue({ [form_key]: _titles });
        setTitles(_titles);
    }

    const addISBN = (_isbn) => {

        if (!_isbn) {
            return;
        }

        setChanged(true);

        let _eisbn = "";
        let _title = "";
        apiCall("title/getItem", { isbn: _isbn }, (_s, _r) => {
            if (_r.hasOwnProperty("eisbn") && _r.hasOwnProperty("title")) {
                _eisbn = _r.eisbn;
                _title = _r.title;
            }
            let _titles = [...titles.filter(item => item.isbn !== _isbn)];
            _titles.push({
                isbn: _isbn,
                eisbn: _eisbn,
                title: _title
            });
            setPending("");
            form.setFieldsValue({ [form_key]: _titles });
            setTitles(_titles);
        })
    }


    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [uploaded, setUploaded] = useState([])

    const uploadFile = (_file) => {
        setFile(_file);
        setLoading(true);
        let obj = {}
        obj.file = _file.file;
        apiCall("titlelist/parseFile", obj, (_status, _result) => {
            if (_status) {
                setChanged(true);
                setUploaded(_result.titles);
                setFileUploaded(true);
            } else {

            }
            setVisible(true);
            setLoading(false);
        });
    }


    const closeModal = () => {
        setUploaded([]);
        setVisible(false)
        setModal(false);
        setFileUploaded(false);
    }

    const addImportToList = (_titles) => {

        let _current = [...titles];
        let _currentTotal = _current.length;
        _titles.forEach(element => {
            let _find = _current.find(item => item.isbn === element.isbn);
            if (!_find) {
                _current.push({
                    isbn: element.isbn,
                    title: element.title,
                    eisbn: element.eisbn
                })
            }
        });

        if (_currentTotal === _current.length) {

            setVisible(false);
            setFileUploaded(false);
            setModal(false);
            setUploaded([]);
            message.error("No titles added");
        } else {
            form.setFieldsValue({ [form_key]: _current });
            setChanged(true);
            setTitles(_current);
            setVisible(false);
            setFileUploaded(false);
            setModal(false);
            setUploaded([]);
            let _count = _current.length - _currentTotal;
            message.success(_count.toString() + " titles added");
        }

    }

    const [modal, setModal] = useState(false);

    const showInstructions = () => {
        return (<>

            <Layout className="layout">
                <Layout.Content style={{ "padding": "0px", "width": "auto" }} >
                    <div style={{ "padding": "30px" }}>
                        <Typography.Title level={5}>Choose File... </Typography.Title>
                        <Divider style={{ "margin": "15px 0px" }} />

                        {(!fileUploaded && <>
                            <Upload.Dragger accept=".txt, .pdf, .xls, .xlsx, .html, .csv" showUploadList={false} customRequest={(e) => uploadFile(e, false)} style={{ "padding": "0px 20px", "height": "300px" }}>
                                <div >
                                    {(loading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                                </div>
                                <p className="ant-upload-text">Click or drag to upload</p>
                                <p className="ant-upload-hint">
                                    Upload an Excel file (.xls or .xlsx), Text file (.txt) <br />or a tab-delimited file (.txt).
                                </p>
                            </Upload.Dragger>
                            <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                            <div style={{ "float": "right" }}><Button onClick={() => setModal(false)}>Cancel</Button></div>
                            <br clear="all" />
                        </>)}

                        {(fileUploaded && <>

                            <div>
                                <Table locale={{ emptyText: "No titles" }} scroll={{ y: 255 }} className="tborder" pagination={false} columns={columns.filter(item => item.key !== "success")} dataSource={uploaded} size="small"></Table>
                                <div className="shim" /><div className="shim" />
                                <div style={{ "float": "right" }}>
                                    <Space>
                                        <Button onClick={() => closeModal()}>Cancel</Button>
                                        <Button disabled={!(uploaded.length > 0)} type="primary" onClick={() => addImportToList(uploaded)}>Add</Button>
                                    </Space>
                                </div>
                                <br clear="all" />
                            </div>

                        </>)}





                    </div>
                </Layout.Content>
            </Layout>

        </>)
    }


    const filterCallback = (_presets) => {
        setChanged(true);
        setFilters(_presets);
        form.setFieldsValue({ [preset_key]: _presets });
    }

    return (
        <>
            <Modal footer={null} bodyStyle={{ "padding": "0px" }} visible={modal} onCancel={() => setModal(false)}>
                {showInstructions()}
            </Modal>
            <Form.Item noStyle name={form_key} initialValue={titles}></Form.Item>
            <Typography.Title level={5} >{title}</Typography.Title>

            <Typography.Paragraph type="secondary">{subtitle}</Typography.Paragraph>
            {(allowPresets && <>
                <Form.Item noStyle name={preset_key} initialValue={filters} />
                <label>{title} Filter Presets</label>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <FilterPresetPicker callback={(e) => filterCallback(e)} multiple={true} filters={filters} presets={presets} />
            </>)}
            {(!hide_header &&
                <>
                    <Divider dashed style={{ "margin": "15px 0px" }} />
                    <div className="shim" /><div className="shim" />
                    <label>{title} ISBNS</label>
                </>
            )}

            <div className="shim" />
            <Input.Search onSearch={(e) => addISBN(e)} size="small" enterButton={<small>Add ISBN</small>} placeholder="Add ISBN" onChange={(e) => setPending(e.target.value)} value={pending} />
            <div className="shim" /><div className="shim" /><div className="shim" />
            <Table locale={{ emptyText: "No titles" }} showHeader={false} scroll={{ y: 255 }} className="tableBorder" pagination={false} columns={columns} dataSource={titles} size="small"></Table>
            <div className="shim" />
            <div style={{ "float": "right" }}><Button onClick={() => setModal(true)} size="small" type="primary" icon={<UploadOutlined />}><small> Upload</small></Button></div>
        </>
    );
}