import { Divider, Space, Spin, Tooltip } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { addCommas } from "../../utils/Utils";
import MultiPointSlider from "./MultiPointSlider";

export default function CalandarGrid(props) {

    const { heatmap = {}, unit = "price", loading = false } = props;
    const { legend = [], rows = [], max = 0 } = heatmap;
    const size = 20;
    const space = 1;
    const colors = ["#55555533", "#17b87244", "#03ae9977", "#37abcdDD", "#0782c4CC", "#096ea3"]


    function calculateEqualSteps(maxValue) {

      
        const numberOfSteps = 6;
        const stepSize = Math.floor(maxValue / (numberOfSteps - 1));
        let _steps = [];
    
        for (let i = 0; i < numberOfSteps; i++) {
            _steps.push(stepSize * i);
        }
        
        
        return _steps;
    }

    const [steps, setSteps] = useState(calculateEqualSteps(max));


    useEffect(() => {

        setSteps(calculateEqualSteps(max))

    }, [unit])

    function getMaxIndex(arr = [], num) {
        let maxIndex = -1;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] < num) {
                maxIndex = i;
            }
        }

        return maxIndex + 1;
    }

    const drawRow = (_item, index) => {
        return rows.map(item => {
            let count = item.days[index];

            let color_index = getMaxIndex(steps, count)
            return (
                <Tooltip title={
                    <small>
                        <div style={{ "opacity": "0.6" }}>{moment(item.monday_epoch * 1000).add(index, "days").format("MMM Do, YYYY")}</div>
                        <div>{(unit === "price") ? "$" + addCommas(item.days[index]) : item.days[index]}</div>
                    </small>} >
                    <div style={{ "backgroundColor": colors[color_index], "width": size + "px", "lineHeight": "0", "height": size + "px" }}>
                    </div>
                </Tooltip>
            )
        })
    }

    const draw = () => {

        let _days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
        let _m = "";
        return (
            <Spin spinning={loading}>
               
               
                
                <div style={{"height" : "300px"}}>
                    <div className="float-flex">
                        <div className="c">Days</div>
                        <div style={{"textAlign" : "right"}}>
                        <Space size={20}>
                            {steps.map((item, index) => {
                                let _label = (unit === "price") ? "$0" : "0";
                                if (index !== 0) {
                                    if ((unit === "price")) {
                                        _label = "$" + addCommas(steps[index - 1]) + "-$" + addCommas(steps[index]);
                                    } else {
                                        _label = steps[index - 1] + "-" + steps[index];
                                    }
                                }
                                return (
                                    <>
                                        <Space>
                                            <div style={{ "width": "10px", "height": "5px", "backgroundColor": colors[index] }}></div>
                                            <div style={{ "opacity": "0.7", "fontSize": "12px" }}>{_label}</div>
                                        </Space>
                                    </>
                                )
                            })}
                        </Space>
                        {(max && <MultiPointSlider unit={unit} max={max} steps={steps} onChange={(_a) => setSteps(_a)} />)}
                        </div>
                        
                    </div>
                    <Divider dashed />
                    <div style={{ "width": "100%", "textAlign": "center", "padding": "20px" }}>
                        <Space size={space} style={{ "lineHeight": "0px" }} direction="vertical">
                            <div style={{ "display": "flex", "justifyContent": "space-between", "marginLeft": "32px" }} key={"labels"} size={[space]}>{rows.map((item) => {
                                let _d = moment(item.monday_epoch * 1000).format("MMM").toUpperCase();
                                if (_d === _m) {
                                    _d = ""
                                } else {
                                    _m = _d;
                                }
                                return (<div style={{ "fontSize": "10px", "opacity": ".5" }}>{_d}</div>)
                            })}</div>
                            <div className="shim" /><div className="shim" />
                            {_days.map((item, index) => {
                                return <Space key={item} size={[space]}><div style={{ "width": 30 + "px", "fontSize": "10px", "opacity": ".5", "textAlign": "left" }}><small>{(!loading && item)}</small></div>{drawRow(item, index)}</Space>
                            })}
                        </Space>
                    </div>
                </div>
            </Spin>
        )
    }


    return draw();
}