import React, { useState } from "react"
import LayoutHeader from "../../layouts/partials/LayoutHeader"
import { Button, Checkbox, DatePicker, Form, Layout, Space, Typography } from "antd"
import { FilePdfOutlined } from '@ant-design/icons';
import moment from "moment-timezone"
import { apiCall } from "../../utils/Api";

export default function CustomerSalesExport() {

    const [filters, setFilters] = useState({ start_date: moment().add(-1, "year"), end_date: moment(), only_customers : false })



    const generateFile = () => {

        let obj = {
            start_date : filters.start_date.unix(), 
            end_date : filters.end_date.unix(),
            only_customers: filters.only_customers
        }

        apiCall("sales/export/start", obj, (_status, _result) =>{
            if(_status){
                window.open("/report/" + _result.progress_report_id, "_blank")
            }
        })
    }
    
    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Customer Sales Export"
                    description={<>Use this page to configure and generate a customer sales report Excel document {(!window.sitesettings.allow_custsalesexport && <><br /> 
                    <span style={{"color" : "#f00"}}>* Using <em>Customer Sales Export</em> requires updating to the most recent version of bookmanager and sending an up-to-date backup. </span></>)}</>}
                    filters={<>
                        <Form>
                            <Space wrap size={[5, 0]}>
                                <Form.Item initialValue={[filters.start_date, filters.end_date]} name="range" label="Date and Time" noStyle>
                                    <DatePicker.RangePicker disabled={!window.sitesettings.allow_custsalesexport}  onChange={(e) => setFilters({ ...filters, "start_date": e[0], "end_date": e[1] })} className="inputsmall" size="small" style={{ "width": "100%" }} format="MMM Do YYYY" />
                                </Form.Item>
                                <Form.Item valuePropName="checked" initialValue={filters.only_customers} name="only_customers" label="Show only linked customer sales?" noStyle>
                                    <Checkbox onChange={(e) => setFilters({...filters, "only_customers" : e.target.checked})} ><small>Show only linked customer sales?</small></Checkbox>
                                    </Form.Item>
                                <Button disabled={!window.sitesettings.allow_custsalesexport} onClick={() => generateFile()} size="small" type="primary"><small><FilePdfOutlined /> Generate report</small></Button>
                            </Space>
                        </Form><br />
                    </>}
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <Typography.Title level={5}>Instructions</Typography.Title>
                        <Typography.Paragraph type="secondary">
                            Select a date range to export customer linked sales to an Excel spreadsheet. Sales are taken from your most recent Bookmanager software backup, and will only include historical (not current) sales that have a customer account attached and have been tendered off in the Bookmanager point-of-sale. Held sales and quotes are not included. Online orders that have been processed and tendered off in the Bookmanager POS will be included. Only prepaid items that have been picked up / finalized (PrpyRdmd status) will be included. Gift card sales and redemptions are not included.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            <strong>The customer account attached to sales is the main customer account, and may not reflect the ship to or bill to account. Therefore, this report should not be used to manage order fulfillment.</strong>
                        </Typography.Paragraph>
                    </div>
                </Layout.Content>
            </Layout>
        </>

    )
}