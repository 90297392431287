import React from "react";
import { Divider, Space, Tooltip } from "antd";
import { addCommas, findMedian, getSan } from "../../utils/Utils";
import { useSession } from "../../utils/Session";

export default function SalesRelativeChart(props) {

    const { sales = [], height = 100, title = "Sales relative to" } = props;
    // Mike N asked for median * 3 or 4, but too many are clipped
    const high = findMedian(sales.map(i => i.volume)) * 10;

    const [session, setSession] = useSession();
    let san = getSan(session.corp_id, window.sitesettings.stores);

    const scale = (x) => {
        return Math.max(0, x);
    }

    const drawCandle = (_item, _index) => {

        let per = (scale(_item.volume) / scale(high));
        let per_pixel = height * per;
        per_pixel += 1;
        let alt = (_index % 2);

        if (_item.me) {
            let _style = { position: "relative" }
            return (
                <Space size={0} direction="vertical">
                    <div style={{ "width": "2px", "height": ((height - per_pixel) + 1) + "px", "backgroundColor": (alt) ? "#ddd" : "#ddd" }}>&nbsp;</div>
                    <div style={{ "width": "2px", "height": per_pixel + "px", "backgroundColor": (alt) ? "#005191" : "#005191", "position": "relative", "bottom": "0px" }}>&nbsp;</div>
                    <div style={{ "height": "0px", "width": "2px", "position": "absolute", "zIndex": "1", "marginTop": "2px" }}> <div style={_style}><nobr><div><span className="grid-line"></span><strong style={{ ..._style, "display": "inline-block" }}>{san} (${addCommas(_item.volume)})</strong></div></nobr></div></div>
                </Space>
            )
        }
        return (<div style={{ "width": "2px", "height": per_pixel + "px", "backgroundColor": (alt) ? "#4fac60" : "#a6d5af" }}>&nbsp;</div>)
    }

    return (
        <div style={{ "maxWidth": "870px", "overflow": "hidden" }}>
            <strong style={{ "fontSize": "13px" }}>{title}</strong>
            <div>Sorted from largest to smallest, each bar represents one store, and your store is blue</div>
            <Divider dashed style={{ "margin": "10px 0px" }} />
            <div style={{ "width": "760px" }}>
                <div style={{ "cursor": "pointer" }}>
                    <Space>
                        <Tooltip title={<small>Your store</small>}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#3273a6" }}></div>
                                <div style={{ "opacity": "0.7" }}>You</div>
                            </Space>
                        </Tooltip>
                        <Tooltip title={<small>Other stores</small>}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#72bc7f" }}></div>
                                <div style={{ "opacity": "0.7" }}>Others</div>
                            </Space>
                        </Tooltip>
                    </Space>
                </div>
            </div>
            <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
            <div style={{ "display": "flex", "justifyContent": "flex-start", "fontSize": "10px", "opacity": "0.8", "alignItems": "end", "height": "100px", "overflow": 'hidden' }}>
                {sales.map((item, index) => {
                    return drawCandle(item, index);
                })}
            </div>
        </div>
    )
}