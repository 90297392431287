import React from "react";
import { Tooltip, Button } from "antd";
import { DownSquareFilled, DownSquareOutlined } from '@ant-design/icons';
import { disabledColor, highlightColor } from "../../utils/Utils";
import { apiCall } from "../../utils/Api";

export default function ListArchive(props) {

    const { list = {}, results = {}, setResults = () => { } } = props;
    const { list_id = "", is_archived = false } = list;

    const toggleListArchive = (is_picked) => {
        apiCall("titlelist/archive", { list_id: list_id, is_picked: is_picked }, (_status, _result) => {
            if (_status) {
                let newArr = { ...results };
                let update = results.rows.find(item => item.list_id === _result.list_id);
                update.is_archived = _result.is_archived;
                newArr.rows[results.rows.findIndex(item => item.list_id === _result.list_id)] = update;
                setResults({ ...results, "rows": newArr.rows })
            }
        });
    }

    return (
        <Tooltip title="Archive this List/Catalogue internally so it is no longer Featured or kept in any other folder.">
            <div onClick={() => toggleListArchive(!is_archived)}>
                <Button size="small" type="link" icon={(!is_archived) ? <DownSquareOutlined style={{ "color": disabledColor }} /> : <DownSquareFilled style={{ "color": highlightColor }} />} />
            </div>
        </Tooltip>
    );


}