import React, { useState, useEffect } from "react";
import { Collapse, Drawer, Form, Tooltip, Switch, Menu, Dropdown, Row, Col, Space, Card, Checkbox, Anchor, Affix, Pagination, Typography, Divider, Input, Button, Radio, Select, Table, Skeleton } from "antd";
import { apiCall } from "../utils/Api";
import { DownOutlined } from '@ant-design/icons';
import moment from "moment";
import conditional from "../tools/conditional";
import { CloseOutlined } from '@ant-design/icons';


export default function PeerSales(props) {


    const { drawComponent = () => { }, setShowActivity = () => { }, isbn = "" } = props;

    const [results, setResults] = useState({ rows: [] })
    const [summary, setSummary] = useState({});
    const [loading, setLoading] = useState(true);
    const [locationGroups, setLocationGroups] = useState([])

    const [filters, setFilters] = useState({
        period: "month",
        summarize_province: false,
        location_group: (window.sitesettings.is_canadian) ? "Canada" : "USA",
        isbn: isbn
    })

    const getPeerSales = () => {
        setLoading(true)
        apiCall("title/getPeerSalesBreakdown", filters, (_status, _result) => {
            if (_status) {
                // first row is summary data.. 
                let _summary = _result.rows.shift();
                setSummary(_summary);
                setResults(_result);
                setLoading(false)
            }
        })
    }


    const getLocations = () => {
        apiCall("title/getLocationPickerData", { isbn: filters.isbn }, (_status, _result) => {
            if (_status) {
                setLocationGroups(_result.sorted_keys.reverse());
            }
        })
    }

    useEffect(getLocations, []);



    useEffect(getPeerSales, [filters]);


    let columns = [];



    columns.push({ title: <small></small>, width: "5px", dataIndex: "blank", key: "blank" });


    if (filters.summarize_province) {

   

        columns.push({
            title: "", dataIndex: "flag", className: "cellBorder", width: "40px", key: "flag", render: (e, f) => { 
                let flag_path = "https://cdn1.bookmanager.com/i/flags/" + f.country + "_" + f.province + ".png";
                flag_path = flag_path.replace(" ", "");

                return (
                <Tooltip title={<small>{f.name}</small>}><img onError={e => e.target.style.display = 'none'} style={{ "maxWidth": "17px", "cursor": "pointer" }} src={flag_path} /> {f.province}</Tooltip>) }
        })

        // columns.push({
        //     title: <small>Prov / State</small>, className: "cellBorder", dataIndex: "name", key: "name", render : (e) => {
        //         return <div style={{"maxWidth" : "60px", "overflow" : "hidden", "whiteSpace" : "nowrap", "textOverflow" : "ellipsis"}}>
        //             <Tooltip title={<small>{e}</small>}>{e}</Tooltip>
        //             </div>
        //     }
        // })
    }

    // columns.push({ title: <small>Name</small>, className:"cellBorder", dataIndex: "name", key: "name" })

    // Add months procedurally



    for (let i = 12; i >= 0; i--) {
        let start = (filters.period === "week") ? moment().startOf("week").add(-1, "day") : moment();
        let month = start.add(-i, filters.period).format((filters.period === "month") ? "MMM" : "MMMD");

        columns.push({ title: <small>{month}</small>, className: (i === 0 && filters.period !== "week") ? "cellBorder" : "", dataIndex: filters.period + i.toString(), key: filters.period + i.toString(), render: (e) => e || "" });
    }

    // Add Week bs
    if (filters.period === "week") {
        columns.push({ title: <small style={{ "color": "#888" }}>{moment().format("MMMD")}</small>, className: "cellBorder", dataIndex: "week", key: "week", render: (e) => { return <span style={{ "color": "#888" }}>{(e) ? e : ""}</span> } })
    }

    // Add static columns
    columns.push({ title: <small><strong>Total</strong></small>, className: "cellBorder", dataIndex: "total", key: "total", render: (e) => { return <strong>{(e) ? e : ""}</strong> } })


    if (!filters.summarize_province) {
        columns.push({
            title: <small>Price</small>, className: "cellBorder", dataIndex: "price", key: "price", render: (e) => {
                return (e) ? <nobr>{e.toString()}</nobr> : "";
            }
        })
    }

    columns.push({
        title: <small><nobr>OH</nobr></small>, className: "cellBorder", dataIndex: "onhand", key: "onhand", render: (e) => {
            return <div style={{ "color": "#008000", "textAlign": "center" }}>{(e) ? e : ""}</div>
        }
    })
    columns.push({
        title: <small>OO</small>, className: "cellBorder", dataIndex: "onorder", key: "onorder", render: (e) => {
            return <div style={{ "color": "#FFA500", "textAlign": "center" }}>{(e) ? e : ""}</div>
        }
    })



    // Clone columns from summary
    let summary_columns = [...columns];
    // drop the first three columns()
    summary_columns.shift();
    summary_columns.shift();
    summary_columns.shift();
    if (filters.summarize_province) {
        summary_columns.shift();
    }

    const parseData = () => {

        if (loading) {
            return ([]);
        }

        return results.rows.map((item, index) => {

            let m = { ...item };

            for (let i = 12; i >= 0; i--) {
                m[filters.period + i.toString()] = m.values[i];
            }

            return m;


        })
    }


    const parseSummary = () => {

        if (loading) {
            return ([]);
        }

        let _summary = [{ ...summary }];
        return _summary.map((item, index) => {

            let m = { ...item };
            for (let i = 12; i >= 0; i--) {
                m[filters.period + i.toString()] = m.values[i];
            }

            return m;


        })

    }




    const drawHeader = () => {
        return (
            <>
                <div>
                    <div className="bc" style={{ "borderBottom": "1px solid", "height": "29px" }}>
                        <div style={{ "padding": "2px 5px", "marginTop": "0px" }} >
                            <span style={{ "fontSize": "13px" }}>
                                <Space>
                                    <Checkbox className="mini-checkbox" value={filters.summarize_province} onChange={(e) => setFilters({ ...filters, "summarize_province": e.target.checked })}><small>Summarize by province / state</small></Checkbox>

                                    <div style={{ "float": "right" }}>
                                        <Dropdown arrow placement="bottomRight" trigger={["click"]} overlay={menu}><a><small className="c" >Location: {filters.location_group}</small></a>
                                        </Dropdown>
                                    </div>

                                </Space>
                            </span>
                            <div style={{ "float": "right", "fontSize": "13px" }}>
                                <div style={{ "marginTop": "-10px" }}>
                                    <Radio.Group size="small" style={{ "marginTop": "10px" }} defaultValue={filters.period} onChange={(e) => setFilters({ ...filters, "period": e.target.value })} buttonStyle="solid">
                                        <Radio className="mini-radio" value="month"><span style={{ "fontSize": "13px" }}><small>Monthly</small></span></Radio>
                                        <Radio className="mini-radio" value="week"><span style={{ "fontSize": "13px" }}><small>Weekly</small></span></Radio>
                                        <Radio className="mini-radio" value="day"><span style={{ "fontSize": "13px" }}><small>Daily</small></span></Radio>
                                    </Radio.Group>
                                    <Button onClick={() => setShowActivity(false)} className="tiny-btn" size="small" type="text"><small style={{ "fontSize": "10px" }}><CloseOutlined /></small></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }




    const menu = () => {
        return (
            <Menu selectedKeys={[filters.location_group]} onClick={(e) => setFilters({ ...filters, "location_group": e.key })}>
                {locationGroups.map((item, index) => {
                    return (<Menu.Item key={item}>{item}</Menu.Item>)
                })}
            </Menu>
        )
    }



    return (
        <>

            <div className="shim" /><div className="shim" /><div className="shim" />
            <div className="bcg4" style={{ "fontWeight": "normal", "fontSize": "11px", "display": "inline", "padding": "5px 8px", "borderRadius": "2px 2px 0 0" }}>Peer Sales</div>

            {/* <Table
                size="small"
                loading={loading}
                columns={summary_columns}
                style={{ "border": "1px solid", "borderLeft": "6px solid" }}
                className={"peerTable bc"}
                title={() => drawHeader()}
                dataSource={parseSummary()}
                pagination={false}
                onExpand={(e) => { return false; }}
            /> */}

            <Table
                size="small"
                columns={columns}
                title={() => drawHeader()}
                loading={loading}
                className={"peerTable bc"}
                style={{ "border": "1px solid", "borderLeft": "6px solid" }}
                dataSource={parseSummary().concat(parseData())}
                scroll={{ y: 240 }}
                pagination={false}
                onExpand={(e) => { return false; }}

            />

        </>
    )


    return (<>



        <div className="closebar">

            <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
            <br clear="both" />
            <div style={{ "padding": "0px 20px", "marginTop": "-10px" }} >

                <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                    <div>Peer sales</div>
                    <Divider dashed style={{ "marginTop": "10px", "marginBottom": "15px" }} />
                </Typography.Title>

                {/* <Space>
                    <Switch style={{ "marginTop": "-10px" }} onChange={(e) => setFilters({ ...filters, "offset": 0, "recent": e })} size="small"></Switch>
                    <Typography.Title style={{ "marginTop": "0px" }} level={5}><small>Show last 60 days only</small></Typography.Title>
                </Space> */}

                <Space>
                    <Switch style={{ "marginTop": "-10px" }} onChange={(e) => setFilters({ ...filters, "summarize_province": e })} size="small"></Switch>
                    <Typography.Title style={{ "marginTop": "0px" }} level={5}><small>Summarize by province / state</small></Typography.Title>
                </Space>


                <div style={{ "float": "right", "marginTop": "-10px" }}>
                    <Radio.Group size="small" style={{ "marginTop": "10px" }} defaultValue={filters.period} onChange={(e) => setFilters({ ...filters, "period": e.target.value })} buttonStyle="solid">
                        <Radio.Button value="month"><small>Monthly</small></Radio.Button>
                        <Radio.Button value="week"><small>Weekly</small></Radio.Button>
                        <Radio.Button value="day"><small>Daily</small></Radio.Button>
                    </Radio.Group>
                </div>
                <br clear="both" />
            </div>
            <div className="shim" /><div className="shim" /><div className="shim" />








            <div style={{ "marginTop": "-10px" }}>
                {/* <br clear="all" /> */}



                <div className="bc" style={{ "padding": "0px 20px", "borderTop": "1px solid" }}>
                    <div className="shim" />
                    <div style={{ "float": "right" }}>
                        <Dropdown arrow placement="bottomRight" trigger={["click"]} overlay={menu}><Button type="link">{filters.location_group}<DownOutlined /></Button>
                        </Dropdown>
                    </div>

                    <Typography.Title level={5}><small>{(loading) ? "Total" : summary.name}</small></Typography.Title>
                </div>

                <div style={{ "margin": "0px 0px", "borderTop": "1px solid #eee" }}>
                    <Table
                        size="small"
                        loading={loading}
                        columns={summary_columns}
                        className={"tableNoBorder"}
                        dataSource={parseSummary()}
                        pagination={false}
                        onExpand={(e) => { return false; }}
                    />

                </div>


                <div className="shim" />
                <div style={{ "padding": "0px 20px" }}>

                    <Typography.Title level={5}><small>{"Stores"}</small></Typography.Title>
                </div>


                <div style={{ "margin": "0px 0px", "borderTop": "1px solid #eee" }}>
                    <Table
                        size="small"
                        columns={columns}
                        loading={loading}
                        className={"tableNoBorder"}
                        dataSource={parseData()}
                        pagination={props.pagination}
                        onExpand={(e) => { return false; }}

                    />
                </div>


                {/* <conditional.true value={(parseData() > 10)}>
                <div style={{ "float": "right" }}>
                    <Pagination size="small" current={((filters.offset / filters.limit) + 1)} onChange={(e, f) => setFilters({ ...filters, "offset": (e - 1) * f, "limit": f })} total={parseData().length} showSizeChanger />
                </div>
                <br clear="all" />
            </conditional.true> */}

                {/* <conditional.true value={(loading)}>
                    <Skeleton active />
                </conditional.true> */}
            </div>




        </div>



    </>)
}