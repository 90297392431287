import { Checkbox, Form, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { slugify } from "../../utils/Utils";

export default function TextPhoneField(props) {

    const { data = {}, setSelected = () => { }, render = false, update = () => { }, form_obj = null } = props;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [d, setD] = useState(data);

    const {
        id = "",
        label = "Text",
        type = "text",
        description = "",
        placeholder = "Enter text...",
        max_length = 50,
        required = false,
        required_message = "Phone number is required"
    } = d;

    const setValue = (_v) => {
        if (form_obj) {
            form_obj.setFieldsValue({ [id]: _v });
            form_obj.validateFields([id]);
        }
        return (_v);
    }

    const validatePhone = (rule, value) => {

        if(value === ""){
            return Promise.resolve();
        }
      
        if (!value && form_obj.getFieldValue([id])) {
            return Promise.reject('Phone number is required');
        }
        if (value.length !== 14) {
            return Promise.reject('Please enter a valid phone number');
        }
        return Promise.resolve();
    };


    const formatPhoneNumber = (_value) => {

    
        if (!_value) return _value;

        let value = _value.replace(/[^\d]/g, '');

        // remove international digit if it exists & trim the rest
        if (value.length > 9 && value.substr(0, 1) === "1") {
            value = value.substr(1, value.length - 1);
        }
        
        // first bit
        if (value.length < 4) {
            return setValue(value);
        }
        // middle bit
        if (value.length < 7) {
            return setValue(`(${value.slice(0, 3)}) ${value.slice(3)}`);
        };
        // full number

        return setValue(`(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`);

    };

    const handlePaste = (e) => {


    };


    useEffect(() => {
        if (!render) {
            update(d);
        }

    }, [d])



    if (render) {
        return (
            <>
                <Form.Item extra={props.data.description} rules={[{ required: props.data.required, message: props.data.required_message }, { validator: validatePhone }]} label={props.data.label} name={id} >
                    <Input onChange={(_v) => formatPhoneNumber(_v.target.value)} onPaste={handlePaste} maxLength={14} placeholder={props.data.placeholder} />
                </Form.Item>
            </>
        )
    }

    return (
        <>

            {/* LABEL */}
            <label >Label</label><div className="shim" />
            <Input value={label} onChange={(e) => setD({ ...d, "label": e.target.value })} placeholder="Label..." />
            <div className="shim" /><div className="shim" />

            {/* LABEL */}
            <label >Description / Instructions</label><div className="shim" />
            <Input value={description} onChange={(e) => setD({ ...d, "description": e.target.value })} placeholder="Description..." />
            <div className="shim" /><div className="shim" />

            {/* PLACEHOLDER */}
            <label >Placeholder</label><div className="shim" />
            <Input value={placeholder} onChange={(e) => setD({ ...d, "placeholder": e.target.value })} placeholder="Placeholder..." />
            <div className="shim" /><div className="shim" />

            {/* REQUIRED */}
            <div className="float-flex">
                <div>&nbsp;</div>
                <Checkbox onChange={(e) => setD({ ...d, "required": e.target.checked })} checked={required} >Required?</Checkbox>
            </div>

            {(required &&
                <>
                    {/* REQUIRED MESSAGE */}
                    <label>Required? Error Message</label ><div className="shim" />
                    <Input value={required_message} onChange={(e) => setD({ ...d, "required_message": e.target.value })} placeholder="Message..." />
                    <div className="shim" /><div className="shim" /> <div className="shim" /><div className="shim" />
                </>
            )}


        </>
    )
}