import React, { useState } from "react";
import { Row, Col, Form, Modal, Typography, Divider, Space, Button, Input, message } from "antd";
import moment from "moment";
import { EditOutlined } from '@ant-design/icons';
import { adjustColor } from "../utils/Utils";
import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";
import CommentOptions from "./CommentOptions";
import Expand from "../utils/Expand";

const colors = {
    "personal": "#d1b3e5",
    "webstore": "#f47ddc",
    "clients": "#fa9050",
    "booksellers": "#90EE90",
    "staff": "#ffef00",
    "visitors": "#70e2ff",
    "everyone": "#def1f6",
    "unknown": "#bad4e1",
}

const drawShareWith = (_key) => {
    let ret;
    switch (_key) {
        case "webstore":
            ret = "staff";
            break;
        case "clients":
            ret = "rep's clients";
            break;
        default:
            ret = _key;
    }
    return ret;
}


export default function Comment(props) {

    const {
        isbn = "",
        titlelist_id = "",
        opennew = false,
        callback = () => { }
    } = props;

    const [data, setData] = useState(props.data);
    const [isnew, setIsNew] = useState(opennew);
    const [editOptions, setEditOptions] = useState({});
    const [modalVisible, setModalVisible] = useState()
    
    const deleteComment = (_comment_id) => {
        apiCall("comment/delete", { comment_id: _comment_id }, (_status, _result) => {
            if (_status) {
                message.success("Comment deleted.");
                callback();
            } else {
                message.error(_result.error);
            }
            setModalVisible(false);
        })
    }

    const saveComment = (f) => {

        let fields = {
            comment: data.text,
            shared_with: editOptions.current_shared_with,
            expiry_type: editOptions.expiry_type,
            visible_anywhere: editOptions.visible_anywhere,
            as_author: editOptions.current_author,
            expiry_days: editOptions.expiry_date,
            titlelist_id: editOptions.titlelist_id
        }

        if (isbn) {
            fields.isbn = isbn;
        }

        // Add id for if not new
        if (!isnew) {
            fields.comment_id = data.id;
        }

        // drop days if not set
        if (!editOptions.expiry_date) {
            delete fields.expiry_days;
        }

        // drop days if expiry_type isn't days 
        if (editOptions.expiry_type !== 1) {
            delete fields.expiry_days;
        }

        if (!editOptions.titlelist_id) {
            delete fields.titlelist_id;
        }

       

        let method = (isnew) ? "comment/add" : "comment/edit";

        apiCall(method, fields, (_status, _result) => {
            if (_status) {
                callback();
                message.success("Comment saved successfully.");
                close();
            }
        })

    }

    const createNew = () => {
        setData({ ...data, "text": "" });
        setIsNew(true);
    }

    const convertHTML = (_html) =>{
         let _ret = _html.replace(/<strong>/g, '<b>');
         _ret = _html.replace(/<\/strong>/g, '</b>');
         _ret = _html.replace(/<em>/g, '<i>');
         _ret = _html.replace(/<\/em>/g, '</i>');

         console.log(_ret);
         return _ret;
    }

    const revertHTML = (_html) =>{
        let _ret = _html.replace(/<b>/g, '<strong>');
        _ret = _html.replace(/<\/b>/g, '</strong>');
        _ret = _html.replace(/<i>/g, '<em>');
        _ret = _html.replace(/<\/i>/g, '</em>');
        return _ret;
   }

    const drawEdit = () => {
        return (
            <>
                <Form onFinish={(f) => saveComment(f)} layout="vertical">
                    <Row gutter={30}>
                        <Col span={14}>
                            <Form.Item initialValue={revertHTML(data.text)} rules={[{ required: true, message: "Comment required" }]} name="text" label="Comment">
                                {/* <TextEditor allowColor={false} allowLink={true} allowUL={false} allowOL={false} allowImage={false} onChange={(e) => setData({ ...data, "text": convertHTML(e) })} /> */}
                                <Input.TextArea onChange={(e) => setData({ ...data, "text": e.target.value })} rows={8} showCount maxLength={2000}></Input.TextArea>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <CommentOptions titlelist_id={titlelist_id} isnew={isnew} comment_id={data.id} editOptions={editOptions} setEditOptions={setEditOptions} isbn={isbn} />
                        </Col>
                    </Row>
                    <Divider />
                    <conditional.true value={(!isnew)}>
                        <Button onClick={() => createNew()}>Create New Comment</Button>
                    </conditional.true>
                    <div style={{ "float": "right" }}>
                        <Space>

                            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                            {((!isnew) && <Button type="primary" danger onClick={() => deleteComment(data.id)}>Delete</Button>)}
                            <Button htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </>
        )
    }

    const open = () => {
        if (props.opennew) {
            setIsNew(true)
        }
        setData(props.data);
        setModalVisible(true);
    }

    const close = () => {
        setIsNew(false);
        setModalVisible(false);
    }

    return (
        <>
            <Modal destroyOnClose width={"100%"} style={{ "maxWidth": "700px" }} footer={null} onCancel={() => close()} visible={modalVisible}>
                <Typography.Title level={5}>{(isnew) ? "New" : "Edit"} Comment</Typography.Title>
                <Divider />
                {drawEdit()}
            </Modal>
            <span style={{ "cursor": "pointer" }} onClick={() => open()}>{props.children}</span>
        </>
    );
}


// When you have the data, and just want to display the card
Comment.Display = (props) => {

    const drawEditLink = () => {
        return (<><em style={{ "cursor": (data.editable) ? "pointer" : "default", "fontWeight": "500", "color": adjustColor(colors[data.shared_with], -50) }}> Visible to {drawShareWith(data.shared_with)} {(data.editable) ? <EditOutlined /> : ""}</em></>)
    }

    const { data, isbn, callback = () => { } } = props;
    return (
        <>
            <div style={{ "padding": "0px 10px", "borderLeft": "3px solid" + adjustColor(colors[data.shared_with], -20) }}>
                <div style={{ "color": "#888" }}><small><em>{data.author}</em></small></div>
                <div className="htmlcolor" style={{ "fontSize": "13px", "lineHeight": "16px", "maxWidth": "570px"}}><Expand color={adjustColor(colors[data.shared_with], -50)} expand={3} allowbreaks html={data.text} /></div>
                <div style={{ "color": "#888" }}><small>{moment(data.date * 1000).format("MMM Do, YYYY")} -
                    {(data.editable) ? <Comment callback={callback} data={data} isbn={isbn}>{drawEditLink()}</Comment> : drawEditLink()}
                </small></div>
            </div>
        </>
    )
}