import React, { useEffect, useState } from "react";
import { Area } from '@ant-design/charts';
import { Divider, message, Space } from "antd";
import moment from "moment-timezone";
import { apiCall } from "../../utils/Api";
export default function SiteTraffic(props) {

    const { filters = { start_date: moment(), end_date: moment() } } = props;
    const colors = ['#64a6da', '#4fac60'];
    const [results, setResults] = useState();

    const fetchSiteTraffic = () => {
        let obj = {
            table: "traffic",
            chart_type: "line",
            include_keys: JSON.stringify(["num_unique_visitors"]),
            start_ts: filters.start_date.unix(),
            end_ts: filters.end_date.unix()
        }

        apiCall("site_analytics/get", obj, (_status, _result) => {
            if (_status) {
                setResults(_result);
            } else {
                message.error(_result.error)
            }
        })
    }

    useEffect(fetchSiteTraffic, [filters]);

    const parseSiteTraffic = (_data = { labels: [], datasets: {} }) => {
        let arr = [];
        Object.keys(_data.datasets).map(key => {
            let name = _data.datasets[key].label;

        
            _data.datasets[key].data.forEach((_item, _index) => {
                arr.push({
                    x: _data.labels[_index],
                    y: parseInt(_item),
                    z: name,
                    inc: _index,
                })
            });
        })
        return arr;
    }

    const config = {
        data: parseSiteTraffic(results),
        xField: 'x',
        yField: 'y',
        seriesField: 'z',
        height: 250,
        legend: false,
        isStack: false,
        color: colors,
        xAxis: {
            range: [0, 1],
        },
    };

    return (
        <>
            <div style={{ "float": "right", "marginRight" : "30px" }}>
                <Space>
                    {/* <Space>
                        <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#64a6da" }}></div>
                        <div style={{ "opacity": "0.7" }}><small>Page views</small></div>
                    </Space> */}
                    <Space>
                        <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#64a6da" }}></div>
                        <div style={{ "opacity": "0.7" }}><small>Unique visitors</small></div>
                    </Space>
                </Space>
            </div>
            <strong className="c" style={{ "fontSize": "13px" }}>Site Traffic</strong>
            <div style={{"fontSize" : "12px"}}><small>Unique visitors to your website by day.</small></div>
            <Divider dashed style={{ "margin": "10px 0px" }} />
            <Area {...config} />
        </>
    )

}