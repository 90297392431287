import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiCall } from "../utils/Api";
import { Alert, Row, Col, Space, Divider, Typography, Button } from "antd";
import { parseAuthorsPlain } from "../utils/Utils";
import moment from "moment";
import { PrinterOutlined } from '@ant-design/icons';

export default function PrintGiftRegistry(props) {

    const { registry_id = "" } = useParams();
    const [results, setResults] = useState({
        items: [],
        owner: {
            name: "",
            email: "",
            phone: "",
            is_you: false
        },
        title: "",
        occasion: "",
        description: "",
        purchase_deadline: null,
    });

    const [error, setErrror] = useState(false);

    const fetchRegistry = () => {
        apiCall("giftregistry/get", { eid: registry_id }, (_status, _result) => {
            if (_status) {
                setResults(_result)
            } else {
                setErrror(_result.error);
            }
        })
    }

    useEffect(fetchRegistry, []);

    const drawRow = (_itm) => {

        const {
            qty_requested = 0,
            qty_purchased = 0,
        } = _itm;

        const {
            title = "",
            isbn = "",
            eisbn = "",
            binding = "",
            authors = [],
            conditions = [],
            available = []

        } = _itm.title;

        let category = "";
        let subcategory = "";
        let price = "";
        let onhand = "0";
        if(available.length > 0){
            if(available[0].hasOwnProperty("onhand")){
                onhand = available[0].onhand;
            } 
            if(available[0].hasOwnProperty("row_price")){
                price = available[0].row_price;
            }
        }   
        
        if(conditions.length > 0){
            if(conditions[0].hasOwnProperty("category")){
                category = conditions[0].category;
            }
            if(conditions[0].hasOwnProperty("subcategory")){
                subcategory = conditions[0].subcategory;
            }
           
        }
        



        return (
            <div style={{ "width": "295px", "height": "178px", "overflow": "hidden" }}>
              
                <Row gutter={15} key={isbn}>
                    <Col flex={"95px"}>
                        <Space direction="vertical">
                            <img className="shadow-large" style={{ "maxWidth": "95px" }} onError={(e) => e.target.src = "/no-cover.png"} src={"https://bookmanager.com/i/m?b=" + eisbn} />
                        </Space>
                    </Col>
                    <Col flex={"200px"}>
                        <div style={{ "lineHeight": "14px" }}><strong>{title}</strong></div>
                        <div style={{ "marginTop": "-5px" }}><small>{parseAuthorsPlain(authors)}</small></div>
                        <div style={{ "marginTop": "-5px" }}><small><em>Binding: </em>{binding}</small></div>
                        <div style={{ "marginTop": "-5px" }}><small><em>Class: </em> {category} {subcategory}</small></div>
                        <div style={{ "marginTop": "-5px" }}><small>Price: <em>{(price) ? "$" + price.toString() : "No Price"}</em> - {onhand.toString()} in stock</small></div>
                        
                        <Space>
                        <div><strong><small>Requested:</small> {qty_requested}</strong></div>
                        <div><strong><small>Purchased:</small> {qty_purchased}</strong></div>
                        </Space>
                       
                       
                    </Col>
                </Row>
                <Divider dashed />
            </div>
        )
    }

    if (error) {
        return (<div style={{ "margin": "0 auto", "width": "350px", "marginTop": "calc(20vh)" }}><Alert type="error" description={error} /></div>)
    }


    const printPage = () => {
        let printContents = document.getElementById('content').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }


    return (<>

        <div id="content">
            <div id="print" style={{ "padding": "0px 20px" }}>

                <div style={{ "height": "200px", "overflow": "hidden" }}>
                    <div className="shim" style={{"height" : "20px"}} />
                    <div style={{ "float": "right", "padding": "20px" }}><Button className="no-print" onClick={() => printPage()} icon={<PrinterOutlined />} type="primary">Print</Button></div>
                    <Typography.Title level={2}>{results.title}</Typography.Title>
                    <div style={{ "marginTop": "-10px" }}>
                        <div>Occasion : <strong>{results.occasion}</strong></div>
                        {(results.purchase_deadline && <><div>Purchase deadline : <strong>{moment(results.purchase_deadline * 1000).format("MMM Do, YYYY")}</strong></div></>)}
                        <div>As of : <em>{moment().format("MMM Do, YYYY")}</em></div>
                        <div>Description: <em>{results.description}</em></div>
                       
                    </div>
                    <Divider />
                </div>
                <div className="grgrid">
                    {results.items.map(item => { return drawRow(item) })}
                </div>
                <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
            </div>
        </div>
    </>
    )
}