import React from "react";
import { Layout, Typography, Divider } from "antd";

export default function UsCanPriceComparisonPage() {

    return (
        <>
            <Layout style={{ "backgroundColor": "#fff" }}>
                <Layout.Content style={{ "borderLeft": "1px solid #eee", "padding": "0px" }} >
                    <div style={{ "padding": "30px" }}>
                        <Typography.Title level={4}>US to Canadian Price Comparison</Typography.Title>
                        <Divider />
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}