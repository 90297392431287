import React, {useEffect} from "react";
import { BookOutlined, BookFilled } from '@ant-design/icons';
import { Tooltip, Popover, Space, Button } from "antd";
import { objectToPath } from "../utils/Utils";
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
export default function Bookmark(props) {

    const { link = false, searchFilters = {}, isbn = "", list_id = "" } = props;
    const [session, setSession] = useSession();

    const addBookmark = () => {
        
        let sf = { ...searchFilters };
        apiCall("titlelist/setBookmark", { list_id: list_id, isbn: isbn, url: objectToPath(sf) }, (_status, _result) => {
            if (_status) {
                setSession({ ...session, "titlelist_bookmarks": _result })
            }
        })
    }

    const removeBookmark = () => {
        apiCall("titlelist/removeBookmark", { list_id: list_id }, (_status, _result) => {
            if (_status) {
                setSession({ ...session, "titlelist_bookmarks": _result })
            }
        })
    }

    const linkOut = (_path) => {
        window.open("/purchasing/browse" + _path + "#bookmark", "_blank");
    }


    const scrollToMe = () => {
        if (session.titlelist_bookmarks.find(item => item.isbn === isbn)) {
            if(window.location.hash.includes("#bookmark")){
                setTimeout(() => {
                    const bookmarkElement = document.getElementById('bookmark');
                    if (bookmarkElement) {
                      bookmarkElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                }, 500)
            }
        }
    }

    useEffect(scrollToMe, [])

    const drawLink = () => {
        let _obj = session.titlelist_bookmarks.find(item => item.titlelist_id === list_id);
        if (!_obj) {
            return <div style={{ "width": "22px" }}>&nbsp;</div>;
        }
        return (
            <Popover overlayInnerStyle={{ "padding": "0px" }} placement="bottom" content={
                <Space direction="vertical">
                    <Button block type="primary" onClick={() => linkOut(_obj.url)} size="small"><small>Go to bookmark</small></Button>
                    <Button onClick={() => removeBookmark()} block danger size="small"><small>Remove this bookmark</small></Button>
                </Space>
            }>
                <div style={{ "padding": "0px 5px" }}>
                    <BookFilled style={{ "color": "#ff6633", "cursor": "pointer" }} />
                </div>
            </Popover>
        )
    }

    if (!list_id) {
        return (<></>)
    }

    if (link) {
        return drawLink();
    }

    return (
        <div onClick={() => {
            if (session.titlelist_bookmarks.find(item => item.isbn === isbn)) {
                removeBookmark();
            } else {
                addBookmark();
            }
        }} style={{ "position": "absolute", "marginLeft": "-46px", "color": "#aaa", "cursor": "pointer" }}>
            {(session.titlelist_bookmarks.find(item => item.isbn === isbn)) ? <Tooltip title="Remove bookmark"> <div id="bookmark" style={{"position" : "absolute", "top" : "-100px"}}></div><BookOutlined style={{ "color": "#ff6633", "fontSize": "12px" }} /></Tooltip> : <Tooltip title="Bookmark this title to remember your place in this list."><BookOutlined style={{ "color": "#aaa", "fontSize": "12px" }} /></Tooltip>}
        </div>)
}