import { Button, Space, Row, Col, Tooltip, Input, Divider } from "antd";
import React, { useEffect, useState } from "react";
import DropMenu from "../../components/DropMenu";
import { CheckSquareFilled, CloseSquareFilled, CheckOutlined } from '@ant-design/icons';
import { bestsellerColor, successColor } from "../../utils/Utils";
import Iscroller from "../../components/Iscroller";
export default function TimeOfDayFilters(props) {

    const { resetButton = () => { }, groupBy = { cat: [] }, grouping = "", callback = () => { }, headers = { groupby_labels: { "test": ["test"] } } } = props;
    const [searchFilters, setSearchFilters] = useState({});
    const [refine, setRefine] = useState("");
    const [changed, setChanged] = useState(false)
    const [vistoggle, setVistoggle] = useState("");
    const [opened, setOpened] = useState("");

    useEffect(() => {
        if(!groupBy.hasOwnProperty("cat") && !groupBy.hasOwnProperty("catcat2") && !groupBy.hasOwnProperty("section") && !groupBy.hasOwnProperty("shop")){
            setSearchFilters({});
        }
    }, [groupBy])
    
    const check = (_filter, _key, _excluded = false) => {


        if (_excluded) {
            _filter = "-" + _filter;
        }
        if (searchFilters.hasOwnProperty(_key) && searchFilters[_key].includes(_filter)) {
            return true;
        } else {
            return false;
        }
    }

    const checkAny = (_key, _exclude = false) => {
        if (searchFilters.hasOwnProperty(_key) && searchFilters[_key].length > 0) {
            if (searchFilters[_key][0].substr(0, 1) === "-") {
                if (_exclude) { return true; }
            } else {
                if (!_exclude) { return true; }
            }
        }
        return false;
    }

    const toggleCategory = (_key, _filter, _augment = false, _exclude = false, go = false) => {

        
      
        let value = (searchFilters.hasOwnProperty(_key)) ? [...searchFilters[_key]] : []
        if (_exclude) {
            if (value.includes("-" + _filter)) {
                value = [...searchFilters[_key]].filter(item => item !== "-" + _filter);
            } else {
                value.push("-" + _filter);
            }
        } else {
            if (value.includes(_filter)) {
                value = [...searchFilters[_key]].filter(item => item !== _filter);
            } else {
                value.push(_filter);
            }
        }

        if (!_augment) {
            value = [_filter];
        }

        
        setSearchFilters({ ...searchFilters, [_key]: value });
        setChanged(true);
        
        if (go) {
            setVistoggle("close");
            callback({ ...searchFilters, [_key]: [] });
        }
        return;

    }

    const resetFilter = (_key) => {
        setSearchFilters({ ...searchFilters, [_key]: [] });
        callback({ ...searchFilters, [_key]: [] });
        setVistoggle("close");
    }

    const drawOptions = (_key) => {

        return (
            <div style={{ "float": "right", "fontWeight": "normal", "color": "#ccc", "fontSize": "9px", "marginRight": "0px", "marginTop": "0px" }}>
                <Space align="center" size={3}>
                    {/* <div className="ant-radio-group-solid ant-radio-group-small">
                        <Button type={(grouping === _key) ? "primary" : "default"} onClick={() => { setGrouping(_key); setChanged(true); setVistoggle("close"); callback(searchFilters, _key); }} style={{ "borderRight": "none", "borderRadius": "2px 0 0 2px" }} className="tiny-radio" >
                            <Tooltip mouseEnterDelay={0.5} title={<>Separate rows</>}>
                                <small><MenuOutlined /></small>
                            </Tooltip>
                        </Button>
                        <Button type={(grouping !== _key) ? "primary" : "default"} onClick={() => { setGrouping(""); setChanged(true); setVistoggle("close"); callback(searchFilters, ""); }} style={{ "borderRadius": "0 2px 2px 0" }} className="tiny-radio" >
                            <Tooltip mouseEnterDelay={0.5} title={<>Amalgamate rows</>}>
                                <small><LineOutlined /></small>
                            </Tooltip>
                        </Button>
                    </div> */}
                    <Col flex={"25px"}>
                        <Button disabled={(!changed)} onClick={() => {
                            callback({ ...searchFilters });
                            setVistoggle("close");

                        }} style={{ "padding": "0px 7px", "marginTop": "0px" }} size="small" type="primary" className="tiny-btn"><small>Go!</small></Button>
                    </Col>
                    <Button style={{ "marginTop": "0px" }} onClick={() => resetFilter(_key)} disabled={!(searchFilters.hasOwnProperty(_key) && searchFilters[_key].length > 0)} size="small" className="tiny-btn" type="primary"><small>Reset</small></Button>
                </Space>
            </div>
        )
    }

    const drawFilter = (_filter, _key) => {

        let _included = check(_filter.v, _key);
        let _excluded = check(_filter.v, _key, true);
        let any_included = checkAny(_key);
        let any_excluded = checkAny(_key, true);
        // let count = (searchFilters.hasOwnProperty(_key)) ? searchFilters[_key].length : 0;
        let hasEnglish = (_filter.hasOwnProperty("e"));

        return (
            <Row key={_filter.v}>
                <Col flex={"auto"}><div style={{ "maxWidth": "160px", "overflow": "hidden", "whiteSpace": "nowrap", "textOverflow": "ellipsis", "cursor": "pointer" }} onClick={() => toggleCategory(_key, _filter.v, false, false, true)}><small>{(hasEnglish) ? _filter.e : _filter.v}</small></div></Col>
                <Col flex={"50px"} >
                    <div style={{ "float": "right", "marginRight": "12px" }}>
                        <Space size={2}>
                            <div><CloseSquareFilled onClick={() => toggleCategory(_key, _filter.v, true, true)} key={"exclude"} style={{ "color": (_excluded) ? bestsellerColor : "#bbb", "opacity": (!any_included) ? "1" : "0.2", "pointerEvents": (!any_included) ? "auto" : "none" }} /></div>
                            <div><CheckSquareFilled onClick={() => toggleCategory(_key, _filter.v, true, false)} key={"include"} style={{ "color": (_included) ? successColor : "#bbb", "opacity": (!any_excluded) ? "1" : "0.2", "pointerEvents": (!any_excluded) ? "auto" : "none" }} /></div>
                        </Space>
                    </div>
                </Col>
            </Row>);

    }

    const drawFilters = (_key) => {

        if (!headers.seen.hasOwnProperty(_key)) {
            return (<></>);
        }

        return (

            <Iscroller
                refine={refine}
                opened={opened}
                check={check}
                item_key={_key}
                data={headers.seen[_key]}
                interator={(item) => {
                    return drawFilter(item, _key)
                }}
            />
        )

        return

    }





    const findLabel = (_key, _value) => {

        // Remove the fist char if it's a dash - (excluded)
        if (_value[0] === '-') {
            _value = _value.slice(1);
        }

        let ret = headers.seen[_key].find(item => item.v === _value);
        if (ret) {

            if (ret.hasOwnProperty("e")) {
                return ret.e;
            } else {
                return _value;
            }
        } else {
            return _value;
        }
    }


    const drawSelectionToolTip = (_key) => {
        let ret = "";
        switch (_key) {
            case "rcvdmonth":
                ret = "Filter the results by adding and hiding selected items. Breakdown the results by the date the goods were initially received";
                break;
            case "specord":
                ret = "Filter the results by adding and hiding selected items. S/O are items that were both ordered for and sold to the same customer (i.e. a special order). ";
                break;
            default:
                ret = "Filter the results by adding and hiding selected items. ";
                break
        }
        return (<small>{ret}</small>)
    }

    const drawLabelToolTip = (_key, _open = false) => {
        let ret = "";
        switch (_key) {
            case "rcvdmonth":
                ret = "Filter the results by adding and hiding selected items. Breakdown the results by the date the goods were initially received";
                break;
            case "specord":
                ret = "Filter the results by adding and hiding selected items. S/O are items that were both ordered for and sold to the same customer (i.e. a special order). ";
                break;
            default:
                ret = (_open) ? "Group the results as one row" : "Separate the results into individual rows";
                break
        }
        return (<small>{ret}</small>)
    }


    const drawBrowseLabel = (_key) => {
        let ret = "";
        if (searchFilters.hasOwnProperty(_key) && searchFilters[_key].length > 0) {
            // check if included or excluded
            let inc = (searchFilters[_key][0][0] === '-') ? "excluded" : "included";
            if (searchFilters[_key].length > 1) {
                ret = searchFilters[_key].length + " " + inc;
            } else {
                ret = findLabel(_key, searchFilters[_key][0]) || "1 " + inc;
            }
        } else {
            ret = "Choose...";
        }
        return (<div style={{ "overflow": "hidden", "textOverflow": "ellipsis" }}><Tooltip title={drawSelectionToolTip(_key)}><nobr>{ret}</nobr></Tooltip></div>)
    }

    const drawGroupByLabels = () => {

        if (!headers.groupby_labels) {
            return;
        }


        let _filtered = Object.keys(headers.groupby_labels);



        return _filtered.map(key => {

            let label = headers.groupby_labels[key];

            if (label) {
                return (
                    <div className="bc" style={{ "border": '1px solid', "width": "100%" }}>
                        <div>
                            <div style={{ "textAlign": "center", "display": "block", "padding": "3px 10px" }}><small><nobr>{((grouping === key) && <><CheckOutlined /> &nbsp;</>)}{label}</nobr></small></div>
                            <div style={{ "backgroundColor": "#177ddc", "color": "#fff", "cursor": "pointer" }}>
                                <DropMenu onOpen={() => {
                                    setOpened(key);
                                }} custom_button selected={(key === grouping)} vistoggle={vistoggle} setVistoggle={setVistoggle} onClose={() => {
                                    if (changed) {
                                        if (!vistoggle) {
                                            callback({...searchFilters});
                                        }
                                    }
                                    setChanged(false);
                                    setOpened("");
                                    setRefine("");

                                }} label={<div style={{ "maxWidth": "100%", "overflow": "hidden" }} className="c"><Space align="center"><div style={{ "width": "190px" }}><nobr><strong>{label}</strong></nobr></div>{drawOptions(key)} </Space></div>} title={<div style={{ "padding": "5px 0px", "textAlign": "center" }}>{drawBrowseLabel(key)}</div>}>
                                    <Input autoFocus value={refine} onChange={(e) => setRefine(e.target.value)} size="small" placeholder="Keyword..." />

                                    <Divider dashed style={{ "margin": "10px 0px" }} />
                                    <div className="c">{drawFilters(key)}</div>
                                </DropMenu>
                            </div>
                        </div>
                    </div>

                )

            }

        })

    }



    return (
        <>
            <div style={{ "marginTop": "-10px" }}>
                <div style={{ "display": "flex", "justifyContent": "stretch", "gap": "2px" }}>
                    {drawGroupByLabels()}
                    
                </div>
                <div>{resetButton()}</div>
            </div>
            <div className="shim" />
        </>
    )

}