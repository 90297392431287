import { Alert, Input } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from '@ant-design/icons';

export default function Tickets(props) {

    const { onChange = () => { } } = props;
    const [tickets, setTickets] = useState(props.tickets || [])
    const [ticket, setTicket] = useState("");

    const addTicket = (_isbn) => {
        if (!tickets.includes(_isbn)) {
            let _arr = [...tickets];
            _arr.push(_isbn);
            setTickets(_arr);
        }
    }

    const removeTicket = (_isbn) => {
        let _arr = [...tickets];
        _arr = _arr.filter(itm => itm.toString() !== _isbn.toString())
        setTickets(_arr);
    }

    useEffect(() => {
        onChange(tickets)
    }, [tickets])


    return (<>


        <div className="ant-col ant-form-item-label">Ticket(s)</div>
        <div className="shim" />
        {tickets.map((item, index) => {
            return (<Alert afterClose={(e) => removeTicket(item)} key={item} closable className="ticket" message={<>{item}</>} />);
        })}
        {(tickets.length > 0 && <><div className="shim" /><div className="shim" /></>)}

        <Input.Search size="small" style={{ "width": "420px" }} onChange={(e) => setTicket(e.target.value)} value={ticket} onSearch={(e) => {
            if (e) {
                addTicket(e);
                setTicket("")
            }

        }} onKeyDown={(e) => { if (e.key === "Enter") { e.preventDefault(); e.stopPropagation(); } }} enterButton={<small style={{ "fontSize": "9px" }}><PlusOutlined /></small>} placeholder="Add ISBN" />

        <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />

    </>)
}