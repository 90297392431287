import { Select, Form } from "antd";
import React, { useEffect, useState } from "react";
import { apiCall } from "../utils/Api";

export default function ISOCodes(props) {

    const {freeship_isocodes = []} = props; 
    const [providers, setProviders] = useState([]);

    const parseProviders = (_arr) => {
        let _res = [];
        _arr.forEach(e => {
            e.providers.forEach(i => {
                _res.push({
                    key: e.region + "/" + i.label,
                    label: <><small>{e.region}</small> - {i.label}</>
                })
            })
        });
        return _res;
    }

    const fetchSettings = () => {
        apiCall("settings/get", {}, (_status, _result) => {
            if (_status) {
                setProviders(parseProviders(_result["global:ws_multiship_json"]));
            }
        })
    }

    useEffect(fetchSettings, []);

    return (

        <Form.Item initialValue={freeship_isocodes} name="freeship_isocodes" label="Shipping Provider(s)">
            <Select placeholder="Select providers..." style={{ "width": "300px" }} mode="multiple">
                {providers.map(item => {
                    return <Select.Option key={item.key} value={item.key}>{item.label}</Select.Option>
                })}
            </Select>
        </Form.Item>



    );

}