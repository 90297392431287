import React, { useEffect, useState } from "react";
import { Layout, Typography, Table, Button, Popconfirm, message, Card, Input, Space, Alert, Tooltip, Badge, Select } from "antd";
import { FormOutlined, DeleteOutlined, ShareAltOutlined, ScheduleOutlined } from '@ant-design/icons';
import moment from "moment";
import 'moment-timezone';
import { apiCall } from "../../utils/Api";
import Paginate from "../../components/Paginate";
import Overlay from "../../components/Overlay";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { capitalizeFirstLetter, trimTrailingSlash } from "../../utils/Utils";
import EventItem from "../../components/events/EventItem";
import { RRule, datetime, rrulestr } from 'rrule'
import EventToggle from "../../components/events/EventToggle";
import UpgradeEvents from "../../components/events/UpgradeEvents";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function EventsPage2() {

    const [results, setResults] = useState({ rows: [], row_count: 0 });
    const [filters, setFilters] = useState({ current: 1, start: 0, limit: 10 });
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState("date_created"); 
    const [categories, setCategories] = useState([])
    const history = useHistory(); 

    const {event_id = ""} = useParams(); 
    const getEvents = () => {
        setLoading(true);
        if(event_id){
            apiCall("events/v2/get", {id : event_id}, (_status, _result) => {
                if (_status) {
                    setResults({ rows: [_result], row_count: 1 });
                    setLoading(false);
                }
            })
        } else {

          
            apiCall("events/v2/list", {sort: sort, limit : filters.limit, offset : filters.limit * (filters.current-1)}, (_status, _result) => {
                if (_status) {
                    setResults(_result);
                    setLoading(false);
                }
            })
        }

    }

    useEffect(getEvents, [filters, event_id, sort]);


    const getCategories = (_id = false) => {
        apiCall("events/v2/listCategory", {}, (_status, _result) => {
            if (_status) {
                setCategories(_result) 
            } 
        })
    }

    useEffect(() => {
        getCategories()
    }, [])


    const drawCategory = (_id) => {
        let _f = categories.find(item => item.id === _id);
        if(_f){
            return <nobr><Badge style={{ "backgroundColor": _f.colour, "boxShadow" : "none" }} dot />  &nbsp; {_f.name} </nobr>
        } else {
            return ""
        }
    }


    const deleteEvent = (_id) => {
        setLoading(true);
        apiCall("events/v2/remove", { id: _id }, (_status, _result) => {
            if (_status) {
                message.success("Event deleted.");
                getEvents();
            }
        })
    }

    const updateRow = (_arg, _edit = true) => {
        let _events = [...results.rows]
        if (_edit) {
            let _index = _events.findIndex(item => item.id === _arg.id);
            if (_index > -1) {
                _events[_index] = _arg;
                setResults({ ...results, "rows": _events });
            }
            return;
        }
        return;
    }

    const prettyTime = (_str) => {
        if(!_str){
            return "";
        }
        const time = moment(_str, 'HH:mm:ss').format('h:mm A');
        return time;
    };

    const CopyButton = (props) => {
        const { path = "" } = props
        const [copied, setCopied] = useState(false);

        const copyToClipboard = (_str) => {
            if (!navigator.clipboard) {
                message.error("Can't copy");
                return;
            }
            navigator.clipboard.writeText(path);
            setCopied(true);
            setTimeout(() => {
                setCopied(false)
            }, 1000);
        }

        return (<Button style={{ "width": "80px" }} onClick={(e) => copyToClipboard(e)} type={(copied ? "default" : "primary")}>{(copied) ? "Copied" : "Copy"}</Button>);

    }


    const EventShare = (props) => {

        let url = trimTrailingSlash(window.sitesettings.url) + "/events/" + props.id + props.date;

        return (
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Share</Typography.Title>
                <Typography.Paragraph>To share this event with <strong>customers</strong>, copy this link (e.g. Ctrl-C) and then paste it into an email.</Typography.Paragraph>
                {(!props.enabled && <><Alert style={{ "padding": "3px 10px", "textAlign": "center" }} message={<small>This event isn't visible. Ensure the event is visible and active before sending a link.</small>} type="error" /><div className="shim" /><div className="shim" /></>)}
                <Space>
                    <Input onFocus={(e) => e.target.select()} style={{ "width": "400px" }} defaultValue={url} />
                    <CopyButton path={url} />
                </Space>
            </div>
        )
    }

    // Rrules need to be structured exactly...
    function removeLastSemicolon(str) {
        if (str.charAt(str.length - 1) === ';') {
            return str.slice(0, -1);
        }
        return str;
    }


    const columns = [

        {
            title: <small>Edit</small>, className: "cellBorder", width: "0px", dataIndex: 'id', key: 'id', render: (e, f) => {
                return (<Button size="small" type="link"><Overlay maskClosable={false} width={700} component={<EventItem setCategories={setCategories} categories={categories} onClone={() => {

                    if(sort === "date_created"){
                        getEvents(); 
                    } else {
                        setSort("date_created")
                    }

                }} updateRow={updateRow} event={f} />}><FormOutlined /></Overlay></Button>)
            }
        },

        {
            title: <small>Share</small>, className: "cellBorder", width: "0px", dataIndex: 'id', key: 'id', render: (e, f) => {
                return (<Overlay maskClosable={false} width={525} component={<EventShare enabled={f.enabled} date={f.date} id={f.id} />}><Button size="small" type="link"><ShareAltOutlined /></Button></Overlay>)
            }
        },
        {
            title: <small></small>, dataIndex: 'top', width: "10px", key: 'top'
        },
        {
            title: <small>Event Name</small>, width: "300px", dataIndex: 'title', key: 'title', render: (e, f) => {
                return e;
            }
        },
        {
            title: <small>Start Date</small>, dataIndex: 'date', key: 'date', render: (e) => {

                return moment(e).format("MMM Do YYYY");
            }
        },
        {
            title: <small>Start Time</small>, dataIndex: 'start_time', key: 'start_time', render: (e) => {
                return prettyTime(e)
            }
        },
        {
            title: <small>End Time</small>, dataIndex: 'end_time', key: 'end_time', render: (e, f) => {
                return prettyTime(e)
            }
        },

        {
            title: <small>Repeat</small>, dataIndex: 'rrule', key: 'rrule', render: (e, f) => {
                let _label = "";
                let _active = ""; 
                let _next_event = ""
                if (e) { 
                    let _dtstart = "DTSTART=" + moment(f.date).utc().format('YYYYMMDDTHHmmss');
                    const rule = RRule.fromString(removeLastSemicolon(_dtstart + ";" + e));
                    _active = rule.between(moment().toDate(), moment().add(1, "year").toDate());
                    if(_active && _active.length>0 && moment(_active[0]).unix() > moment().unix()){
                        _next_event = "Next Event: " +  moment(_active[0]).format("MMM Do, YYYY");
                    } else {
                        _active = false; 
                    }
                    _label = rrulestr(removeLastSemicolon(e)).toText(); }
                return (e) ? <Tooltip title={<small>{(_active) ? <>{_next_event}<div className="shim" /></>  : "Inactive: "}{capitalizeFirstLetter(_label)}</small>}><ScheduleOutlined className="c" style={{ "fontSize": "17px", "cursor": "pointer", "opacity":  (_active) ? "1" : "0.5", "color" : (_active) ? "#4dac13" : "inherit" }} /></Tooltip> : "";
            }
        },

        {
            title: <small>Visibility</small>, dataIndex: 'enabled', key: 'enabled', render: (e, f) => {
                return drawVisibility(e, f, f.enabled);
            }
        },
        { title: <small>Category</small>, dataIndex: 'category', key: 'category', render : (e, _f) => {
            return drawCategory(e);
        } },

        { title: <small>RSVP</small>,  width : 15, dataIndex: 'views', key: 'views', render : (e, f) => {
            if(f.attendance !== "request"){return ""}
            return(<div className="ar">{f.rsvp.filter(item => !item.on_waitlist).length} {(f.rsvp_limit && <>/ {f.rsvp_limit}</>)}</div>)
        } },

        { title: <small>WL</small>, width : 15, dataIndex: 'views', key: 'views', render : (e, f) => {
            if(f.attendance !== "request"){return ""}
            return(<div className="ar">{f.rsvp.filter(item => item.on_waitlist).length}</div>)
        } },


        {
            title: <small></small>, className: "cellBorder", dataIndex: 'space', width: "10px", key: 'space'
        },
        {
            title: <small>Delete</small>, width: "0px", dataIndex: 'id', key: 'id', render: (e, f) => {
                return (<div style={{"textAlign" : "center"}}><Popconfirm onConfirm={() => deleteEvent(e)} title="Delete event?" ><Button danger size="small" type="link"><DeleteOutlined /></Button></Popconfirm></div>)
            }
        },
    ]

    const drawVisibility = (_bool, _event, _enabled) => {

        if (_bool) {
            return (<EventToggle updateRow={updateRow} enabled={_enabled} event={_event}><div style={{ "color": "#4dac13" }}>Visible (Active)</div></EventToggle>);
        } else {
            return (<EventToggle updateRow={updateRow} enabled={_enabled} event={_event}><div style={{ "color": "#f12a46" }}>Hidden (Inactive)</div></EventToggle>);
        }
    }


    const getButton = () => {
        if(event_id){
            return(<Button onClick={() => history.push("/content/events")} size="small" type="link">↵<small> Back to Events</small></Button>)
        } else {
            return(
                <Space>
                    <Select style={{"width" : "120px"}} onChange={(e) => setSort(e)} value={sort} size="small">
                        <Select.Option value={"date_created"}><small>Date Created</small></Select.Option>
                        <Select.Option value={"date_updated"}><small>Date Updated</small></Select.Option>
                        <Select.Option value={"category"}><small>Category</small></Select.Option>
                        <Select.Option value={"chronological"}><small>Chronological</small></Select.Option>
                    </Select>
                    <UpgradeEvents upgrade={false} />
                    <Button size="small" type="primary">
                    <Overlay maskClosable={false} width={700} component={<EventItem is_new setCategories={setCategories} categories={categories} create={true} updateRow={() => {
                        if(sort === "date_created"){
                            getEvents(); 
                        } else {
                            setSort("date_created")
                        }
                    }} />}>
                        <small>Create new event</small>
                    </Overlay>
                </Button>
                </Space>
            )
        }
    }

    return (

        <Layout className="layout">
            <LayoutHeader
                title="Events"
                description="Use this page to add, remove and manage events."
                button={getButton()}
            />
            <Layout.Content style={{ "padding": "0px" }} >
                <div style={{ "padding": "20px" }}>
                    <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                        <Paginate setPaginate={(e) => setFilters({ current: e.current, start: e.offset, limit:e.pagesize })} count={results.row_count} paginate={{ current: filters.current, pagesize: filters.limit, offset: filters.start }} />
                        <Table
                            size="small"
                            pagination={false}
                            loading={loading}
                            rowKey="event_id"
                            className={"tableNoBorder"}
                            columns={columns}
                            dataSource={results.rows}
                        />
                        <Paginate setPaginate={(e) => setFilters({ current: e.current, start: e.offset, limit: e.pagesize })} count={results.row_count} paginate={{ current: filters.current, pagesize: filters.limit, offset: filters.start }} />
                    </Card>
                </div>
            </Layout.Content>
            <LayoutHeader
                divider={false}
                title=""
                description={<><UpgradeEvents show_revert upgrade={false} /></>}
              
            />
        </Layout>
    )

}