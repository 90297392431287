import React, { useState, useEffect } from "react";
import { apiCall } from "../utils/Api";
import { Row, Col, Typography, Image, Divider } from "antd";
import conditional from "../tools/conditional";
import Html from "../utils/Html";
export default function BusinessContactInfo(props) {

    const { id } = props;
    const [results, setResults] = useState({
        id: "",
        san: "",
        name: "",
        acct: "",
        addr1: "",
        addr2: "",
        city: "",
        prov: "",
        country: "",
        postal: "",
        phone: "",
        phone2: "",
        tollfree: "",
        fax: "",
        email: "",
        url: "",
        notes: "",
        categories: [],
        changes: [],
        webstore: "",
        edit: false,
        approvedText: "",
        logo_id: null
    })
    const getContact = () => {

        apiCall("business_directory/getContact", { contact_id: id }, (_status, _result) => {
            if (_status) {
                setResults(_result);
            }
        })
    }

    useEffect(getContact, [id])

    function lineBreakToBr(inputString) {
        if(typeof inputString !== 'string'){
            return "";
        }
        const lineBreakRegex = /(\r\n|\n)/g;
        // Replace line breaks with <br> tags
        const resultString = inputString.replace(lineBreakRegex, '<br>');
        return resultString;
      }



    const drawCard = () => {

        // handle bad data - websites without prefix
        let url = "";
        if (results.url) {
            if (results.url.substr(0, 4) !== "http") {
                url = "https://" + results.url;
            } else {
                url = results.url;
            }
        }

        return (
            <>
                <Row>
                    <Col flex={"auto"}>
                        <div style={{ "padding": "8px", "paddingBottom": "0px", "margin": "0px 0px" }}>
                            <div>
                                {(results.logo_id &&
                                <div className="float-flex">
                                    <img src={`https://bookmanager.com/tbm/?q=h.storeimg&id=${results.logo_id}&desc=logo`} style={{ "maxHeight": "100px", "maxWidth": "300px" }} onError={(event) => event.target.style.display = 'none'} />
                                    <img src={`https://bookmanager.com/tbm/?q=h.storeimg&id=${results.logo_id}&desc=store`} style={{ "maxHeight": "100px", "maxWidth": "300px" }} onError={(event) => event.target.style.display = 'none'} />
                                </div>
                                )}
                                 <Divider dashed style={{ "margin": "15px 0px" }} />  
                            </div>
                           
                            <div style={{ "float": "right", "textAlign": "right" }}>
                                <Typography.Paragraph style={{ "fontSize": "12px" }}>
                                    {(results.url && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Website: </strong><a target="_blank" href={url}>{url}</a></span>)}
                                    {(results.phone && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Phone: </strong>{results.phone}</span>)}
                                    {(results.phone2 && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Phone: </strong>{results.phone2}</span>)}
                                    {(results.tollfree && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Toll Free: </strong>{results.tollfree}</span>)}
                                    {(results.fax && <span style={{ "fontSize": "11px", "display": "block" }}><strong>Fax: </strong>{results.fax}</span>)}
                                </Typography.Paragraph>
                            </div>
                            <Typography.Paragraph style={{ "fontSize": "11px" }}>
                                <span style={{ "color": "#069", "fontSize": "12px", "display": "inline" }}><strong>{results.name}</strong>
                                </span>
                                <div style={{ "height": "0px" }} className="shim"></div>
                                <div style={{ "marginTop": "-2px" }}><a href={"mailto:"}>{results.email}</a></div>
                                <div className="shim"></div>
                                <conditional.true value={(results.addr1 || results.addr2)}>
                                    {results.addr1} {((results.addr2) && <div>{results.addr2}</div>)} <br />
                                </conditional.true>
                                <conditional.true value={(results.city)}>
                                    {results.city}, {results.prov} {results.country} <br />
                                </conditional.true>
                            </Typography.Paragraph>
                            <Divider dashed style={{ "margin": "15px 0px" }} />  
                            <Typography.Paragraph style={{ "fontSize": "11px" }}>
                                <Html html={lineBreakToBr(results.notes)} />
                            </Typography.Paragraph>
                           
                        </div>
                    </Col>
                </Row>



            </>
        )

    }

    return (
        <>
            <Row>
                <Col flex={100}>{drawCard()}</Col>
                {/* <Col>...</Col> */}
            </Row>
            {/* <pre>{JSON.stringify(result, null, 2)}</pre> */}
        </>
    );
}