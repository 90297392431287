import React, { useEffect, useState } from "react";
import { Layout, Form, Checkbox, Typography, Divider, Menu, Affix, Space, Button, Row, Col, Switch, Radio, Input, Card, Alert } from 'antd';
import conditional from "../tools/conditional";
import logosmall from "../media/logo_light.png";
import logodark from "../media/logo_dark.png";
import bg from "../media/bg.jpg";
import { useHistory } from "react-router";
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import { objectToPath } from "../utils/Utils";
import { CloseOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';

export default function LoginLayout(props) {

    const { redirect = "", setRedirect = () => { } } = props;
    const [session, setSession] = useSession();
    const [error, setError] = useState("");
    const [forgot, setForgot] = useState(false);
    const [forgotError, setForgotError] = useState("");
    const [forgotSuccess, setForgotSuccess] = useState("");
    const history = useHistory();



   

    const login = (_f) => {

        let obj = {
            password: _f.password,
        }

        if (_f.email_address.includes("@")) {
            obj.email_address = _f.email_address;
        } else {

            if (_f.email_address.length !== 7) {
                setError("Use your email address or a 7 digit SAN.");
                return;
            }
            obj.san = _f.email_address;
        }

        if (error) {
            setError("");
        }

        setRedirect((redirect && redirect !== "/") ? redirect : "")
        apiCall("account/login", obj, (_status, _result) => {
            if (_status) {
                window.store_id = _result.store_id;
                apiCall("store/getSettings", {}, (_s, _r) => {
                    if (_s) {
                        window.sitesettings = _r;
                        setSession(_result);
                        if(redirect.length > 2){
                            history.push(redirect)
                        }
                    }
                })
            } else {
                setError(_result.error);
            }
        })

    }


    const recoverPassword = (_f) => {

        apiCall("account/emailPasswordToken", { email_address: _f.email_address }, (_status, _result) => {
            if (_status) {
                setForgotSuccess(<>If you have an existing account you will receive an email confirmation with a link to reset your password.</>)
            } else {
                setForgotError(_result.error)
            }
        })

    }

    const resetForgot = () => {
        setForgotSuccess("");
        setForgotError("")
        setForgot(false);
    }

    const drawForgotPassword = () => {

        return (

            <>
                <div className="shim" style={{ "minHeight": "120px", "height": "calc(50vh - 250px)" }}></div>
                <Card headStyle={{ "borderBottom": (!props.dark) ? "1px solid #e6e6e6" : "1px solid #333333" }} title={<span className="c">{(forgotSuccess) ? "Success" : "Forgot password"}</span>} extra={<><CloseOutlined onClick={() => resetForgot(false)} /></>} hoverable style={{ "width": "310px", "margin": "0 auto", "border": "1px solid #e6e6e6", "cursor": "default" }}>
                    <div style={{ "width": "250px", "margin": "0 auto" }}>

                        <conditional.true value={(!forgotSuccess)}>
                            <div style={{ "width": "250px", "textAlign": "left" }}>
                                <span style={{ "paddingLeft": "0px" }}>
                                    <p>
                                        To retrieve the account's password, we need to send an email to the address associated with your account.
                                    </p>
                                </span>
                            </div>
                        </conditional.true>

                        <Form onFinish={(_f) => recoverPassword(_f)} layout="vertical">
                            <div style={{ "opacity": (forgotSuccess) ? "0.5" : "1" }}>
                                <Form.Item label="Email address" name="email_address" rules={[{ required: true, message: <small>Please input your email or san.</small> }]}>
                                    <Input disabled={(forgotSuccess)} placeholder="Email address..." />
                                </Form.Item>
                            </div>

                            <conditional.true value={(forgotError && !forgotSuccess)}>
                                <Alert
                                    style={{ "marginBottom": "20px", "padding": "10px 10px" }}
                                    description={<>{forgotError}</>}
                                    type="error"
                                />
                            </conditional.true>
                            <conditional.true value={(forgotSuccess)}>
                                <Alert
                                   
                                    style={{ "marginBottom": "20px", "padding": "10px 10px" }}
                                    description={<><strong>Success</strong><br /><div className="shim" />{forgotSuccess}</>}
                                    type="success"
                                />
                            </conditional.true>

                            <div style={{ "float": "right" }}>
                                {(forgotSuccess) ? <Button onClick={() => resetForgot()} type="primary" >Close</Button> : <Button type="primary" htmlType="submit">Retrieve</Button>}
                            </div>
                        </Form>
                    </div>
                </Card>


            </>)
    }

    const returnToV1 = () => {
        Cookies.remove("last_domain", { domain: ".bookmanager.com" });
        window.location.href = "https://bookmanager.com";
    }


    if (forgot) {
        return drawForgotPassword();
    }

    return (
        <>
            <div className="shim" style={{ "minHeight": "120px", "height": "calc(50vh - 250px)" }}></div>
            <Card hoverable style={{ "width": "310px", "margin": "0 auto", "border": "1px solid #e6e6e6", "cursor": "default" }}>
                <div style={{ "width": "250px", "margin": "0 auto" }}>
                    <div style={{ "width": "250px", "textAlign": "left" }}>
                        <img alt="bookmanager" style={{ "marginLeft": "28px", "height": "43px" }} width={180} src={(props.dark) ? logodark : logosmall} />
                        <span style={{ "paddingLeft": "0px" }}>

                            {/* <h4 style={{ "display": "inline-block", "fontSize": "15px", "color": "#111" }}>Bookmanager Admin</h4> */}
                            <Divider style={{ "margin": "15px 0px" }} dashed />

                        </span>
                    </div>

                    <Form onFinish={(_f) => login(_f)} layout="vertical">
                        <Form.Item label="Email address / SAN" name="email_address" rules={[{ required: true, message: <small>Please input your email or san.</small> }]}>
                            <Input placeholder="Email address..." />
                        </Form.Item>
                        <Form.Item label="Password" name="password" rules={[{ required: true, message: <small>Please input your password.</small> }]}>
                            <Input.Password placeholder="Password..." />
                        </Form.Item>
                        <Form.Item name="remember" valuePropName="checked">
                            <a onClick={() => setForgot(true)}>Forgot Password?</a>
                        </Form.Item>
                        <conditional.true value={(error)}>
                            <Alert
                                style={{ "marginBottom": "20px", "padding": "10px 10px" }}
                                description={<>{error} <div style={{ "lineHeight": "14px" }}><div className="shim" /><small>Having trouble? Please contact <a href="mailto:mail@bookmanager.com">mail@bookmanager.com</a>.</small></div></>}

                                closable
                                type="error"
                            />
                        </conditional.true>
                        <div style={{ "float": "right" }}>
                            <Button type="primary" htmlType="submit">Sign in</Button>
                        </div>
                    </Form>
                </div>
				<div style={{"width": "250px", "float": "right", "padding": "20px 0px 5px 0px"}}>
					If you don't have an account for biz.bookmanager, <a onClick={returnToV1}>click here to be taken to bookmanager.com.</a>
				</div>
            </Card>
        </>
    )

    

}