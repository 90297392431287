import React, { useState, useEffect } from "react";
import { Form, Space, Switch, Select, Input, Radio, Badge } from "antd";
import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";

export default function CommentOptions(props) {

    const {
        isbn = false,
        titlelist_id = false,
        comment_id = false,
        isnew = false,
        editOptions = () => { },
        setEditOptions = () => { }
    } = props;

    const colors = {
        "personal": "#d1b3e5",
        "webstore": "#f47ddc",
        "clients": "#fa9050",
        "booksellers": "#90EE90",
        "staff": "#ffef00",
        "visitors": "#70e2ff",
        "everyone": "#def1f6",
        "unknown": "#bad4e1",
    }

    const [loading, setLoading] = useState(true);

    const getEditOptions = (_new = false) => {

        let args = {}
        if (isbn) { args.isbn = isbn; }

        if(!isnew){
            if (comment_id) { args.comment_id = comment_id; }
        } else {
            if (editOptions.titlelist_id){
                args.titlelist_id = editOptions.titlelist_id;
            }
        }

        if (titlelist_id) { args.titlelist_id = titlelist_id; }
      

        apiCall("comment/getEditOptions", args, (_status, _result) => {
            if (_status) {
                setEditOptions(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getEditOptions, [isnew]);

    const drawAuthor = () => {
        return (
            <Form.Item label="Author">
                <Select onChange={(e) => setEditOptions({ ...editOptions, "current_author": e })} value={(editOptions.shared_with[editOptions.current_shared_with].matrix[editOptions.current_author]) ? editOptions.current_author : setEditOptions({ ...editOptions, "current_author": "personal" })}>
                    {
                        Object.keys(editOptions.shared_with[editOptions.current_shared_with].names).map((item, index) => {
                            
                            // hide any if it's empty. 
                            if (!editOptions.shared_with[editOptions.current_shared_with].matrix[item]) { return; }
                            return (<Select.Option disabled={(!editOptions.shared_with[editOptions.current_shared_with].matrix[item])} value={item}>{editOptions.shared_with[editOptions.current_shared_with].names[item]}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
        )
    }

    const drawSharedWith = () => {
        return (
            <Form.Item label="Visibility">
                <Select value={editOptions.current_shared_with.toString()} onChange={(e) => setEditOptions({ ...editOptions, "current_shared_with": e })}>
                    {
                        Object.keys(editOptions.shared_with).map((item, index) => {
                            return (<Select.Option value={item.toString()}><Badge dot color={colors[editOptions.shared_with[item].colour_code]} />{editOptions.shared_with[item].text}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
        );
    }

    const drawExpires = () => {
        return (
            <Form.Item label="Expires">
                <Radio.Group value={(!editOptions.visible_anywhere && (editOptions.titlelist_id)) ? 0 : editOptions.expiry_type} onChange={(e) => setEditOptions({ ...editOptions, "expiry_type": e.target.value })}>
                    <Space direction="vertical">
                        <conditional.true value={(editOptions.titlelist_id)}>
                            <Radio value={0}>With list</Radio>
                        </conditional.true>
                        <Radio disabled={(!editOptions.visible_anywhere && (editOptions.titlelist_id))} value={1}>In &nbsp;
                            <Form.Item noStyle>
                                <Input type="number" onChange={(e) => setEditOptions({ ...editOptions, "expiry_date": e.target.value })} value={(editOptions.expiry_date) ? editOptions.expiry_date : 90} disabled={(!editOptions.visible_anywhere && (editOptions.titlelist_id))} style={{ "width": "60px" }} size="small" />
                            </Form.Item>
                            &nbsp; day(s)</Radio>
                        <conditional.true value={(editOptions.allow_long_expiry)}>
                            <Radio disabled={(!editOptions.visible_anywhere && (editOptions.titlelist_id))} value={2}>No expiry</Radio>
                        </conditional.true>
                    </Space>
                </Radio.Group>
            </Form.Item>
        )
    }

    const drawListLimit = () => {
        return (
            <conditional.true value={(editOptions.titlelist_id)}>
                <Form.Item initialValue={(!editOptions.visible_anywhere)} valuePropName="checked" name="list_visible" label="Only visible from this list">
                    <Switch onChange={(e) => setEditOptions({ ...editOptions, "visible_anywhere": (!e) })} size="small" />
                </Form.Item>
            </conditional.true>
        )
    }

    if (loading) {
        return (<></>)
    }

    return (
        <div style={{ "maxWidth": "300px" }}>
            {drawAuthor()}
            {drawListLimit()}
            {drawSharedWith()}
            {drawExpires()}
        </div>
    )

}