import React from "react";
import { Layout, Typography, Divider } from "antd";
import LayoutHeader from "../../layouts/partials/LayoutHeader";

export default function SitePagesPage() {

    return (
        <>

            <Layout className="layout">
                <LayoutHeader
                    todo
                    title="Site Pages"
                    description="Use this page to manage site pages."
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "height": "calc(100vh - 250px)" }}>

                    </div>
                </Layout.Content>
            </Layout>

        </>
    )
}