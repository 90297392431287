import React, { useState, useEffect } from "react";
import { Input, Popconfirm } from "antd";
export default function InputConfirm(props) {

    const { disabled=false, defaultValue, style = {}, flag = "", row = {}, title = "Are you sure?", className = "", callback = () => { return "" } } = props;
    const [value, setValue] = useState(defaultValue);
    const [visible, setVisible] = useState(false);

    const resetValue = () =>{
        setValue(defaultValue);
    }

    useEffect(resetValue, [defaultValue]);

    const cancel = () => {
        setVisible(false);
        setValue(defaultValue);
    }

    const confirm = () => {
        setVisible(false);
        callback(flag, value, row);
    }



    return (<><Popconfirm disabled={disabled} title={title} onConfirm={(e) => confirm()} onCancel={() => cancel()} visible={visible}><Input disabled={disabled} size="small" onKeyDown={(e) => { if ((e.key === "Enter")) { setVisible((e.target.value !== defaultValue)) } }} className={className} style={style} value={value} onBlur={(e) => setVisible((e.target.value !== defaultValue))} onChange={(e) => setValue(e.target.value.toUpperCase())} /></Popconfirm></>)


}