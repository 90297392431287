import { Space, Tooltip } from "antd";
import moment from "moment-timezone";
import React from "react";
import { addCommas, adjustOpacity, getPercentageIncrease } from "../../utils/Utils";

export default function Candle(props) {

    const { bar = false, height = 170, color = "#64a6da", data = { now: 0, then: 0, date: "" }, high = 0 } = props;

    const per = (Math.max(0, data.now) / high) * 100;
    const diff = Math.max(0, data.now) - Math.max(0, data.then);
    const plus = (diff > 0) ? (diff / high) * 100 : 0;
    const minus = (diff <= 0) ? (diff / high) * -100 : 0;

    const per_pixel = height * (per / 100);
    const minus_pixel = (minus) ? height * (minus / 100) : 0;
    const plus_pixel = (plus) ? height * (plus / 100) : 0;


    const drawToolTip = () => {
        return (
            <Space size={0} direction="vertical">
                <div><small><strong>Week ending:</strong></small></div>
                <small><em style={{"opacity" : "0.8"}}>{moment(data.date).format("MMM Do, YYYY")}:</em> ${addCommas(data.now)}
                    {((data.now > data.then) && <div style={{ "color": "#7ded88", "display": "inline-block" }}><small>&nbsp; {getPercentageIncrease(data.now, data.then, 1)}%</small></div>)}
                    {((data.now < data.then) && <div style={{ "color": "#ea8282", "display": "inline-block" }}><small>&nbsp; {getPercentageIncrease(data.now, data.then, 1)}%</small></div>)}
                </small>
                <small><em style={{"opacity" : "0.8"}}>{moment(data.date).add(-52, "weeks").format("MMM Do, YYYY")}: </em> ${addCommas(data.then)}</small>
            </Space>
        )
    }

    const drawCandle = () => {
        // Horisontal Bar
        if (bar) {
            return (
                <div style={{ "lineHeight": "0px" }}>
                    <Tooltip title={drawToolTip()}>
                        <Space size={0}>
                            <div style={{ "backgroundColor": adjustOpacity(color, 50), "width": per_pixel + 1 + "px", "height": "10px" }}>
                                <div style={{ "float": "right", "backgroundColor": color, "width": plus_pixel + "px", "height": "10px" }}>&nbsp;</div>
                            </div>
                            <div style={{ "backgroundColor": "#ea8282", "width": minus_pixel + "px", "height": "10px" }}>&nbsp;</div>
                        </Space>
                    </Tooltip>
                </div>
            )
        }

        // Vertical Bar
        return (
            <div key={data.date} className="bc2" style={{ "borderRight": "1px solid" }}>
                <Tooltip title={drawToolTip()}>
                    <div className="lighten" style={{ "height": height + "px", "display": "flex", "width": "7px", "flexDirection": "column", "justifyContent": "end", "cursor": "default" }}>
                        <div style={{ "display": "block", "height": minus_pixel + "px", "width": "7px", "backgroundColor": "#ea8282", "margin": "0 auto", "opacity": "1" }}>&nbsp;</div>
                        <div style={{ "display": "block", "height": per_pixel + "px", "width": "7px", "backgroundColor": adjustOpacity(color, 50) }}>
                            <div style={{ "display": "block", "height": plus_pixel + "px", "width": "7px", "backgroundColor": color, "margin": "0 auto", "opacity": "1" }}>&nbsp;</div>
                        </div>
                    </div>
                </Tooltip>
            </div>
        )
    }

    return drawCandle();
}