
import React, { useEffect, useState } from "react";
import { Layout, Card, Row, Col, Button, Table, Space, Popconfirm, Modal, Form, Input, Divider, InputNumber, Typography, message, Select } from "antd";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional";

export default function TaxPreferencesPage() {

    const [results, setResults] = useState({ tax_groups: [], tax_types: [], has_edit_permission: false });

    const [changed, setChanged] = useState(false);
    const [groupVisible, setGroupVisible] = useState(false);
    const [rateVisible, setRateVisible] = useState(false);

    const [groupCurrent, setGroupCurrent] = useState({
        // _record: { name: "", code: "", tax_1: "", tax_2: "", tax_b: "", tax_3: "", id: "" },
        _record: { name: "", code: "", tax_1: "", id: "" },
        _index: 0,
        _new: true,
    })

    const [rateCurrent, setRateCurrent] = useState({
        _record: { name: "", code: "", rate: "", id: "" },
        _index: 0,
        _new: true,
    })

    const codes = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
    const getTaxes = () => {
        apiCall("taxes/get", {}, (_status, _result) => {
            if (_status) {
                setResults(_result);
            }
        })
    }
    useEffect(getTaxes, []);


    const save = () => {

        apiCall("taxes/set", { tax_types: JSON.stringify(results.tax_types), tax_groups: JSON.stringify(results.tax_groups) }, (_status, _result) => {
            if (_status) {
                setResults(_result);
                setChanged(false);
                message.success("Saved successfully.")
            } else {
                message.error(_result.error);
            }
        })

    }


    const getNextCode = () => {
        let _rates = results.tax_types.map(item => item.code);
        let _available = codes.filter(x => !_rates.includes(x));
        return _available[0];
    }


    const [rate_form] = Form.useForm();


    const removeGroup = (_index) => {
        const _update = [...results.tax_groups];
        _update.splice(_index, 1);
        setResults({ ...results, "tax_groups": _update });
        setChanged(true);
    }

    // Check to ensure the rate isn't used in the customer codes
    const removeRate = (_index) => {
        let _code = results.tax_types[_index].code;
  
        // let _used = results.tax_groups.filter(item => item.tax_1.includes(_code)).concat(results.tax_groups.filter(item => item.tax_2.includes(_code))).concat(results.tax_groups.filter(item => item.tax_b.includes(_code))).concat(results.tax_groups.filter(item => item.tax_3.includes(_code)))
        let _used = results.tax_groups.filter(item => item.tax_1.includes(_code));
        if (_used.length > 0) {
            Modal.warning({ title: "This rate is currently used in " + _used.length + " tax group(s). ", content: <div style={{ "opacity": "0.6" }}>Please first remove or re-assign the tax groups before removing the tax rate.</div> });
            return;
        }
        const _update = [...results.tax_types];
        _update.splice(_index, 1);
        setResults({ ...results, "tax_types": _update });
        setChanged(true);
    }

    const updateGroup = (_f, _index, _new) => {
        setGroupVisible(false);
        let _update = [...results.tax_groups]
        if (_new) {
            _update.push({
                "name": _f.name,
                "code": getNextCode(),
                "tax_1": _f.tax_1.join(""),
                // "tax_2": _f.tax_2.join(""),
                // "tax_b": _f.tax_b.join(""),
                // "tax_3": _f.tax_3.join(""),
                "id": Math.random() * 10000
            })
        } else {
            _update[_index] = {
                "id": _f.id,
                "name": _f.name,
                "code": _f.code,
                "tax_1": _f.tax_1.join(""),
                // "tax_2": _f.tax_2.join(""),
                // "tax_b": _f.tax_b.join(""),
                // "tax_3": _f.tax_3.join(""),
            }
        }
        setResults({ ...results, "tax_groups": _update });
        setChanged(true);

    }


    const updateRate = (_f, _index, _new) => {
        setRateVisible(false);
        let _update = [...results.tax_types]
        if (_new) {
            _update.push({
                "name": _f.name,
                "code": getNextCode(),
                "rate": _f.rate,
                "id": Math.random() * 10000
            })
        } else {
            _update[_index] = {
                "id": _f.id,
                "name": _f.name,
                "code": _f.code,
                "rate": _f.rate
            }
        }
        setResults({ ...results, "tax_types": _update });
        setChanged(true);
    }


    useEffect(() => {
        console.log("called");
    }, results)



    const drawGroupEdit = () => {

        return (
            <>
                <Typography.Title level={5}>{(groupCurrent._new) ? "New customer code" : "Edit customer code"}</Typography.Title>
                <Divider style={{ "margin": "15px 0px" }} dashed />
                <Form layout="vertical" onFinish={(_f) => updateGroup(_f, groupCurrent._index, groupCurrent._new)}>
                    <Form.Item initialValue={groupCurrent._record.id} hidden name="id">
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: "Code is required." }]} initialValue={groupCurrent._record.code} label="Code" name="code">
                        <Input maxLength={4} placeholder="Code..." />
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: "Description is required." }]} initialValue={groupCurrent._record.name} label="Description" name="name">
                        <Input maxLength={30} placeholder="Label..." />
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: "Minimum of 1 code is required." }]} initialValue={(groupCurrent._record.tax_1) ? groupCurrent._record.tax_1.split("") : []} label="Tax 1" name="tax_1">
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"

                        >
                            {results.tax_types.map(item => {
                                return <Select.Option value={item.code}>{item.code}<small> - <em>{item.name}</em></small></Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    {/* <Form.Item initialValue={(groupCurrent._record.tax_2) ? groupCurrent._record.tax_2.split("") : []} label="Tax 2" name="tax_2">
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"

                        >
                            {results.tax_types.map(item => {
                                return <Select.Option value={item.code}>{item.code}<small> - <em>{item.name}</em></small></Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item initialValue={(groupCurrent._record.tax_b) ? groupCurrent._record.tax_b.split("") : []} label="Tax B" name="tax_b">
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"

                        >
                            {results.tax_types.map(item => {
                                return <Select.Option value={item.code}>{item.code}<small> - <em>{item.name}</em></small></Select.Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item initialValue={(groupCurrent._record.tax_3) ? groupCurrent._record.tax_3.split("") : []} label="Tax 3" name="tax_3">
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"

                        >
                            {results.tax_types.map(item => {
                                return <Select.Option value={item.code}>{item.code}<small> - <em>{item.name}</em></small></Select.Option>
                            })}
                        </Select>
                    </Form.Item> */}

                    <Divider style={{ "margin": "13px 0px" }} dashed />
                    <div className="shim" /><div className="shim" />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => setGroupVisible(false)}>Cancel</Button>
                            <Button htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </>
        )


    }

    const drawRateEdit = () => {
        rate_form.resetFields();
        return (
            <>
                <Typography.Title level={5}>{(rateCurrent._new) ? "New tax rate" : "Edit tax rate"}</Typography.Title>
                <Divider style={{ "margin": "15px 0px" }} dashed />
                <Form layout="vertical" form={rate_form} onFinish={(_f) => updateRate(_f, rateCurrent._index, rateCurrent._new)}>
                    <Form.Item initialValue={rateCurrent._record.id} hidden name="id">
                        <Input />
                    </Form.Item>
                    <Form.Item initialValue={rateCurrent._record.code} hidden name="code">
                        <Input />
                    </Form.Item>

                    <Form.Item rules={[{ required: true, message: "Label is required." }]} initialValue={rateCurrent._record.name} label="Label" name="name">
                        <Input maxLength={6} placeholder="Label..." />
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: "Rate is required." }]} initialValue={rateCurrent._record.rate} label="Tax Rate" name="rate">
                        <InputNumber stringMode maxLength={5} step="0.001" min={0} max={100} style={{ "width": "100%" }} placeholder="Rate..." />
                    </Form.Item>
                    <Divider style={{ "margin": "13px 0px" }} dashed />
                    <div className="shim" /><div className="shim" />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => setRateVisible(false)}>Cancel</Button>
                            <Button htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </>
        )
    }


    const rate_columns = [
        { key: "space", width: "10px" },
        { title: <small>Code</small>, dataIndex: 'code', key: 'code' },
        { title: <small>Name</small>, dataIndex: 'name', key: 'name' },
        { title: <small>Rate</small>, dataIndex: 'rate', key: 'rate' },
        {
            title: <small></small>, width: 76, dataIndex: 'actions', key: 'actions', render: (e, f, i) => {
                return (results.has_edit_permission) ? <>
                    <Space>
                        <Button onClick={() => {
                            setRateCurrent(
                                {
                                    _record: { name: f.name, code: f.code, rate: f.rate, id: f.id },
                                    _index: i,
                                    _new: false,
                                }
                            )
                            setRateVisible(true);
                        }} size="small" type="primary"><small><EditOutlined /></small></Button>
                        <Popconfirm onConfirm={() => removeRate(i)} title="Are you sure?"><Button size="small" danger type="primary"><small><DeleteOutlined /></small></Button></Popconfirm>
                    </Space>
                </> : ""
            }
        },
    ]

    const code_columns = [
        { key: "space", width: "10px" },
        { title: <small>Description</small>, dataIndex: 'name', key: 'name' },
        { title: <small>Cust code</small>, dataIndex: 'code', key: 'code' },
        {
            title: <small>Tax 1</small>, dataIndex: 'tax_1', key: 'tax_1', render: (e) => {
                return (e) ? e.split("").join(",") : ""
            }
        },
        // {
        //     title: <small>Tax 2</small>, dataIndex: 'tax_2', key: 'tax_2', render: (e) => {
        //         return (e) ? e.split("").join(",") : ""
        //     }
        // },
        // {
        //     title: <small>Tax B</small>, dataIndex: 'tax_b', key: 'tax_b', render: (e) => {
        //         return (e) ? e.split("").join(",") : ""
        //     }
        // },
        // {
        //     title: <small>Tax 3</small>, dataIndex: 'tax_3', key: 'tax_3', render: (e) => {
        //         return (e) ? e.split("").join(",") : ""
        //     }
        // },
        {
            title: <small></small>, width: 76, dataIndex: 'actions', key: 'actions', render: (e, f, i) => {
                return (results.has_edit_permission) ? <>
                    <Space>
                        <Button onClick={() => {
                            setGroupCurrent(
                                {
                                    // _record: { name: f.name, code: f.code, tax_1: f.tax_1, tax_2: f.tax_2, tax_b: f.tax_b, tax_3: f.tax_3, id: f.id },
                                    _record: { name: f.name, code: f.code, tax_1: f.tax_1, id: f.id },
                                    _index: i,
                                    _new: false,
                                }
                            )
                            setGroupVisible(true);
                        }} size="small" type="primary"><small><EditOutlined /></small></Button>
                        <Popconfirm onConfirm={() => removeGroup(i)} title="Are you sure?"><Button size="small" danger type="primary"><small><DeleteOutlined /></small></Button></Popconfirm>
                    </Space>
                </> : ""
            }
        },
    ]

    const getData = () => {
        return results.tax_types;
    }

    return (
        <>
            <Modal destroyOnClose width={300} title={null} footer={null} onCancel={() => setRateVisible(false)} visible={rateVisible}>
                {drawRateEdit()}
            </Modal>

            <Modal destroyOnClose width={300} title={null} footer={null} onCancel={() => setGroupVisible(false)} visible={groupVisible}>
                {drawGroupEdit()}
            </Modal>


            <Layout className="layout">
                <LayoutHeader
                    title="Tax Preferences"
                    description={(results.has_edit_permission) ? "Use this page to set tax rates and customer codes." : "Use this page to view tax rates and customer codes"}
                    button={<>
                        <conditional.true value={(results.has_edit_permission)}>
                            <Button disabled={(!changed)} onClick={() => save()} size="small" type="primary"><small>Save changes</small></Button>
                        </conditional.true>
                    </>}
                />

                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>

                        <Row gutter={20}>
                            <Col span={12}>
                                <Card bodyStyle={{ "padding": "0px" }}>
                                    <div className="folders">

                                        <conditional.true value={(results.has_edit_permission)}>
                                            <div style={{ "float": "right" }}><Button disabled={(results.tax_types.length > 25)} onClick={() => {
                                                setRateCurrent(
                                                    {
                                                        _record: { name: "", code: "", rate: "", id: Math.random() * 99999 },
                                                        _index: 0,
                                                        _new: true,
                                                    }
                                                )
                                                setRateVisible(true);
                                            }
                                            } type="primary" size="small"><small>Create rate</small></Button></div>
                                        </conditional.true>
                                        <small>Tax Rates</small>
                                    </div>
                                    <div style={{ "padding": "0px" }}>
                                        <Table
                                            rowKey={"id"}
                                            pagination={false}
                                            style={{ "borderBottom": "none" }}
                                            rowClassName={"hideHover"}
                                            size="small"
                                            columns={rate_columns}
                                            dataSource={results.tax_types}
                                        />
                                    </div>
                                </Card>
                            </Col>

                            <Col span={12}>
                                <Card bodyStyle={{ "padding": "0px" }}>
                                    <div className="folders">

                                        <conditional.true value={(results.has_edit_permission)}>
                                            <div style={{ "float": "right" }}><Button disabled={(results.tax_types.length > 25)} onClick={() => {
                                                setGroupCurrent(
                                                    {
                                                        // _record: { name: "", code: "", tax_1: "",  tax_2: "",  tax_b: "",  tax_3: "", id: Math.random() * 99999 },
                                                        _record: { name: "", code: "", tax_1: "", id: Math.random() * 99999 },
                                                        _index: 0,
                                                        _new: true,
                                                    }
                                                )
                                                setGroupVisible(true);
                                            }
                                            } type="primary" size="small"><small>Create code</small></Button></div>
                                        </conditional.true>



                                        <small>Customer Codes</small>
                                    </div>
                                    <div style={{ "padding": "0px" }}>
                                        <Table
                                            pagination={false}
                                            rowKey={"id"}
                                            style={{ "borderBottom": "none" }}
                                            rowClassName={"hideHover"}
                                            size="small"
                                            columns={code_columns}
                                            dataSource={results.tax_groups}
                                        />
                                    </div>
                                </Card>
                            </Col>

                        </Row>

                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}