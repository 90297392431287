
import { Input, Space, Form, Modal, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { PushpinOutlined, PushpinFilled, QuestionCircleOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";
export default function Splitter(props) {

    const { record, updateOrder = () => { }, saveSplitter = () => {}, quantityInput = false } = props;
    const [form] = Form.useForm();


    const [stores, setStores] = useState(

        window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).map((item, index) => {
            

            if (!record.hasOwnProperty("stores") || (record.stores.length === 0)) {
                return {
                    store_index: index,
                    store_number : item.store_number,
                    qty: (item.is_primary) ? (record.quantity) ? record.quantity : 0 : 0,
                }
            } else {
             
            
                return {
                    store_index: index,
                    store_number : item.store_number,
                    qty: (record.stores[index][item.san]) ? record.stores[index][item.san] : 0,
                }
            }
        })
    );

    const mystore = window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).findIndex(item => item.is_primary)
    const [first, setFirst] = useState(true);


    const updateStores = (_uid, _key, _value) =>{

        let ret = []

        
        window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).forEach((itn, ind) => {
            ret.push(
                {
                    [itn.san]: (_value[ind]) ? _value[ind] : 0
                }
            )
        });


      


        updateOrder(_uid, _key, _value);

        
    }

    useEffect(() => {


        form.setFieldsValue({ splitter: "" });
        record.stores.forEach((item, index) => {

            let value; 
            if(isNaN(item)){
                value = Object.values(item)[0];
            } else {
                value = item; 
            }

            form.setFieldsValue({ ["store" + index]: (value) ? value : "" });
        })

       

    }, [record, props.rerender])


    useEffect(() => {


        if (first) {
            setFirst(false);
            return;
        }
       
        let total = stores.reduce(function (sum, current) { return sum + current.qty; }, 0)
        if (total != parseInt(record.quantity)) {
            let diff = parseInt(record.quantity) - total;
            if (diff > 0) {
                // add to main store
                let _store = stores.find(item => item.store_index === mystore);
                if(_store){
                    augment(_store.qty + diff, mystore, false);
                }
               
            } else {
                // split between stores
                decrement(-diff);
            }
        }

    }, [quantityInput])

    const distribute = (_qty) => {
        if(isNaN(_qty)){ _qty = 0; }
        let _total = 0;
        let update = stores.map(store => {
            _total += _qty;
            return { ...store, "qty": _qty }
        })
        setStores(update);
        updateValues(update);
        updateOrder(record.key, "quantity", _total);
        updateStores(record.key, "stores", update.map(item => { return item.qty }));
        saveSplitter(record.key, update.map(item => { return item.qty }), _total)
    }

    const divvy = (_qty) => {
        if(isNaN(_qty)){ _qty = 0; }
        let remainder = _qty % stores.length;
        let update = stores.map(store => {
            let equal_amt = (_qty - remainder) / stores.length;
            let add_amt = (store.store_index === mystore) ? remainder + equal_amt : equal_amt;
            return { ...store, "qty": add_amt }
        });
        setStores(update);
        updateValues(update);
        updateOrder(record.key, "quantity", _qty);
        updateStores(record.key, "stores", update.map(item => { return item.qty }));
        saveSplitter(record.key, update.map(item => { return item.qty }), _qty)
        
    }

    const decrement = (_qty) => {
        if(isNaN(_qty)){ _qty = 0; }
        let _stores = [...stores];
        for (let i = 0; i < _qty; i++) {
          
            let most = _stores.reduce((max, store) => max.qty > store.qty ? max : store);
            if (_stores[most.store_index].qty > 0) {
                _stores[most.store_index].qty = _stores[most.store_index].qty - 1;
            }
        }
        updateValues(_stores);
        let _final = 0;
        updateStores(record.key, "stores", _stores.map(item => { _final += item.qty; return item.qty }));
        saveSplitter(record.key, _stores.map(item => { return item.qty }), _final)
        setStores(_stores)
    }


    const increase = (_qty) => {
        let _extra = stores.filter(item => item.store_index !== mystore);
        let _count = _extra.reduce(function(sum, current) {
            return sum + current.qty;
          }, 0);

        let diff = _qty - _count; 
        augment(diff);
    }

    const reduce = (_qty) => {

        if(_qty<0){
            return;
        }
        let _count = stores.reduce(function(sum, current) {
            return sum + current.qty;
          }, 0);

        let _diff = _count - _qty;
        let _stores = [...stores];

        let i = 0;
        
        while(_diff > 0){
            let _index = i % _stores.length;
            if(_stores[_index].qty){
                _stores[_index].qty--;
                _diff--;     
            } 
            // just in case
            if(i>10000) break; 
            i++; 
        }
    
        updateValues(_stores);
        saveSplitter(record.key, _stores.map(item => { return item.qty }), _qty);
        updateStores(record.key, "stores", _stores.map(item => { return item.qty }));
        setStores(_stores)
        // for(let i = 0; i < _diff; i++){
        //     let _index = i % _stores.length;
        //     if(_stores[_index].qty){
        //         _stores[_index].qty--;    
        //     } 
        // }

    }

    const augment = (_qty, _store_index = mystore, _update = true) => {
        

        if(isNaN(_qty)){ _qty = 0; }

        let _rest_qty = 0;
        let _selected_qty = 0;
        let is_primary = false;
        let diff = 0;
        let _final = 0;

        for (let i = 0; i < stores.length; i++) {
            if (stores[i].store_index === _store_index) {
                is_primary = (_store_index === mystore);
                diff = _qty - stores[i].qty;
                _selected_qty += stores[i].qty
            } else {
                _rest_qty += stores[i].qty;
            }
        }

        let update = stores.map(store => {
            if (!is_primary && store.store_index === mystore) {
                if (store.qty >= diff) {
                    _final = parseInt(store.qty) - parseInt(diff);
                    return { ...store, "qty": parseInt(store.qty) - parseInt(diff) };
                } else {

                    let _store = stores.find(item => item.store_number === mystore);
                    if(_store){
                        _final = parseInt(_rest_qty) + parseInt(_qty) - parseInt(_store.qty);
                    }
                   
                    //updateOrder(record.key, "quantity", parseInt(_rest_qty) + parseInt(_qty) - parseInt(stores[mystore].qty));
                    return { ...store, "qty": 0 };
                }
            }
            if ((store.store_index === _store_index)) {
                if (is_primary) {
                    _final =  parseInt(_qty) + parseInt(_rest_qty);
                    //updateOrder(record.key, "quantity", parseInt(_qty) + parseInt(_rest_qty));
                }
                return { ...store, "qty": _qty };
            } else {
                return store;
            }
        });


        let qty = update.reduce(function (acc, obj) { return acc + parseInt(obj.qty); }, 0);

        updateOrder(record.key, "quantity", qty);

        setStores(update)
        updateStores(record.key, "stores", update.map(item => { return item.qty }));

        if(_update){
            saveSplitter(record.key, update.map(item => { return item.qty }), qty);
        }
       
        updateValues(update);
    }

    const updateValues = (_stores) => {
        form.setFieldsValue({ splitter: "" });
        _stores.forEach((item, index) => {
            form.setFieldsValue({ ["store" + index]: (item.qty) ? item.qty : "" });
        })
    }


    const [pin, setPin] = useState((Cookies.get("pin_splitter") === "yes"))

    const togglePin = () =>{
        if(Cookies.get("pin_splitter") === "yes"){
            Cookies.set("pin_splitter", "no");
            setPin(false)
        } else {
            setPin(true)
            Cookies.set("pin_splitter", "yes");
        }
    }


    const splitter = (_str) => {


       

        if (_str === "") {
            return;
        }

        if (_str === ".") {
            // split order total
            divvy(record.quantity);
            return;
        } else {
            if (!isNaN(_str) && !_str.includes(".")) {

               
                if(_str > record.quantity){
                    // stupid
                    increase(_str);
                } else {
                    // dumb
                    reduce(_str);
                }

                
                
                //distribute(parseInt(_str));
                return;
            }
        }

        if (_str.substr(0, 1) === ".") {
            let num = _str.substr(1, _str.length);
            if (!isNaN(num)) {
                distribute(parseInt(num));
                //divvy(parseInt(num));
                return;
            }
        }

        if (_str.includes(".")) {
            let split = _str.split(".");
            if (split.length === 2 && !isNaN(split[0]) && !isNaN(split[1])) {
              
                    let _storenum = -1;
                    for(let i = 0; i<window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).length; i++){
                        if(parseInt(window.sitesettings.stores.filter(item => item.cart_active || item.is_primary)[i].store_number) === parseInt(split[0])){
                            _storenum = i; 
                        }
                    }
                    
                    if(_storenum>-1){
                        augment(parseInt(split[1]), _storenum);
                    }
                   
            }
        }
    }

    const drawSplitterHelp = () => {
        Modal.info({
            icon : null, 
            title : "Splitter instructions",
            okText : "Close",
            content : <>
                <Divider dashed style={{"margin" : "15px 0px"}} />
                <kbd>.</kbd>&nbsp;<kbd>enter</kbd><br />Splits current quantity evenly amongst stores. <br /><br />
                <kbd>store number</kbd>&nbsp;<kbd>.</kbd>&nbsp;<kbd>quantity</kbd>&nbsp;<kbd>enter</kbd><br />Assign a quantity to a specific store.<br /><br />
                <kbd>.</kbd>&nbsp;<kbd>quantity</kbd>&nbsp;<kbd>enter</kbd><br />Assign quantity to all stores.<br /><br />
                For more info, visit the <a onClick={() => window.open("https://support.bookmanager.com/tutorials/webstore/biz-admin-site/using-the-multi-store-splitter-ordering-tool", "_blank")}>Help Center</a>
            </>
        })
    }
    
    return (
        <>
            <div className="bc" style={{ "margin": "-15px -10px", "borderBottom": "1px solid", "marginLeft": "-18px" }}>
                <Form form={form}>
                    <Space size={0}>
                        <div style={{ "width": "60px" }}>
                            <div className="bc bcg2" style={{ "borderRight": "1px solid", "borderTop": "1px solid", "fontWeight": "500", "padding": "2px 8px", "fontSize": "10px", "borderBottom" : "1px solid", "cursor" : "pointer" }}><nobr><a onClick={() => drawSplitterHelp()}>Splitter <QuestionCircleOutlined /></a></nobr></div>
                            <div className="bc bcg2" style={{ "borderRight": "1px solid", "fontWeight": "500", "padding": "5px 8px" }}>
                                <Form.Item noStyle name="splitter">
                                    <Input onFocus={(e) => e.target.select()} style={{ "fontSize": "10px", "height": "20px" }} size="small" onPressEnter={(e) => splitter(e.target.value, 1)} />
                                </Form.Item>
                            </div>
                        </div>
                        {
                            stores.map((item, index) => {
                                return (
                                    <div key={index} style={{ "width": "54px" }}>
                                        <div className="bc bcg2" style={{ "borderBottom" : "1px solid", "borderTop": "1px solid", "borderRight": ((index + 1) === stores.length && stores.length === 10) ? "none" : "1px solid", "fontWeight": "500", "padding": "2px 8px", "fontSize": "10px" }}>#{(item.store_number).toString()}</div>
                                        <div className="bc bcg2" style={{"borderRight": "1px solid", "fontWeight": "500", "padding": "5px 8px" }}>
                                            <Form.Item noStyle initialValue={(item.qty) ? item.qty : ""} name={"store" + index}>
                                                <Input onFocus={(e) => e.target.select()} style={{ "fontSize": "10px", "height": "20px" }} size="small" onBlur={(e) => augment(parseInt(e.target.value), item.store_index)} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Space>
                </Form>
            </div>
            <div className="shim" /><div className="shim" /><div style={{"height" : "4px"}} className="shim" />
        </>
    );

}
