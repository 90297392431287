import React, { useEffect, useState } from "react";
import { Popover, Button, Table, Badge, Input, Form, Select, Divider, DatePicker, AutoComplete, Tabs } from "antd";
import { ucfirst } from "../../utils/Utils";
import moment from "moment";
import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import BisacSelector from "./BisacSelector";
import Editor from "react-medium-editor";
import { countries } from "../../data";
export default function TitleRenderers(props) {

    const { value, setValue, field, edit, valid, can_use_pick, isbn } = props;

    const [picklist, setPicklist] = useState([]);
    const [searchResults, setSearchResults] = useState([])
    const getPicklist = (_field) => {


        if (!can_use_pick) {
            return false;
        }


        apiCall("title/getPickList", { isbn: isbn, field: field }, (_status, _result) => {
            if (_status) {
                setPicklist(_result);
                setSearchResults(_result.map((item => {
                    return { value: item.text, label: <>{item.text} <small>({item.count})</small></> }
                })))
            }
        })
    }

    useEffect(getPicklist, [])


    const onSearch = (value) => {
        setSearchResults(picklist.filter(item => (value.length > 0) ? item.text.toLowerCase().substr(0, value.length) === value.toLowerCase() : (item.text)).map((item => {
            return { value: item.text, label: <>{item.text} <small>({item.count})</small></> }
        })))
    };

    const drawRenderer = function () {

        switch (field) {

            case "title":
                return (
                    <>
                        <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                            <Form.Item help={<small className="c" style={{ "opacity": "0.5" }}>Add a colon ( : ) to separate the title from the subtitle</small>} label={ucfirst(field)}>
                                <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder={"Enter " + field} />
                            </Form.Item>
                        </Form>
                        <Divider style={{ "margin": "10px 0px" }} dashed />
                        <div className="shim"></div>
                    </>
                )


            case "author":
            case "author2":
            case "author3":
                return (
                    <>
                        <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                            <Form.Item label={"Author"}>
                                <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder={"Enter Author"} />
                            </Form.Item>
                        </Form>
                    </>
                )

            case "bisac_subjects":


                return (
                    <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                        <Form.Item label={ucfirst(field).replace("_", " ")}>
                            <BisacSelector strToArr={strToArr} value={value} setValue={setValue} />
                        </Form.Item>
                    </Form>
                );

            case "language":
                return (
                    <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                        <Form.Item help={<small className="c" style={{ "opacity": "0.5" }}>Select a country.</small>} label={ucfirst(field)}>
                            {/* <AutoComplete
                                onSearch={onSearch}
                                placeholder={"Select or type " + field}
                                options={countries}
                                onChange={(e) => setValue(e)} value={(value) ? value : undefined}>
                            </AutoComplete> */}

                            {languageSelect()}
                        </Form.Item>
                    </Form>

                )
            case "series":
                return (
                    <>
                        <conditional.true value={(can_use_pick)} >
                            <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                                <Form.Item help={<small className="c" style={{ "opacity": "0.5" }} >Select or type a series.</small>} label={ucfirst(field)}>
                                    <AutoComplete
                                        onSearch={onSearch}
                                        placeholder={"Select or type " + field}
                                        options={searchResults}
                                        onChange={(e) => setValue(e)} value={(value) ? value : undefined}>
                                    </AutoComplete>
                                </Form.Item>
                            </Form>
                        </conditional.true>

                        <conditional.true value={(!can_use_pick)} >
                            <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                                <Form.Item label={ucfirst(field)}>
                                    <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder={"Enter " + field} />
                                </Form.Item>
                            </Form>
                        </conditional.true>
                        <Divider style={{ "margin": "10px 0px" }} dashed />
                        <div className="shim"></div>
                    </>
                )

            case "publisher":
                return (
                    <>
                        <conditional.true value={(can_use_pick)} >
                            <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                                <Form.Item help={<small className="c" style={{ "opacity": "0.5" }} >Select or type a publisher.</small>} label={ucfirst(field)}>
                                    <AutoComplete
                                        onSearch={onSearch}
                                        placeholder={"Select or type " + field}
                                        options={searchResults}
                                        onChange={(e) => setValue(e)} value={(value) ? value : undefined}>
                                    </AutoComplete>
                                </Form.Item>
                            </Form>
                        </conditional.true>

                        <conditional.true value={(!can_use_pick)} >
                            <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                                <Form.Item label={ucfirst(field)}>
                                    <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder={"Enter " + field} />
                                </Form.Item>
                            </Form>
                        </conditional.true>
                        <Divider style={{ "margin": "10px 0px" }} dashed />
                        <div className="shim"></div>
                    </>
                )

            case "binding":
                return (
                    <>
                        <Form layout="vertical">
                            <Form.Item label={ucfirst(field)}>
                                {bindingsSelect()}
                            </Form.Item>
                        </Form>
                        <Divider style={{ "margin": "10px 0px" }} dashed />
                        <div className="shim"></div>
                    </>
                )
            case "stat":
                return (
                    <>
                        <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                            <Form.Item label={"Status"}>
                                {statusSelect()}
                            </Form.Item>
                        </Form>
                        <Divider style={{ "margin": "10px 0px" }} dashed />
                        <div className="shim"></div>
                    </>
                )

            case "audience_code":
                return (
                    <>
                        <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                            <Form.Item label={"Status"}>
                                {codeSelect()}
                            </Form.Item>
                        </Form>
                        <Divider style={{ "margin": "10px 0px" }} dashed />
                        <div className="shim"></div>
                    </>
                )

            case "edition_type":
                return (
                    <>
                        <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                            <Form.Item label={"Edition type"}>
                                {editionSelect()}
                            </Form.Item>
                        </Form>
                        <Divider style={{ "margin": "10px 0px" }} dashed />
                        <div className="shim"></div>
                    </>
                )

            case "audience_type":
                return (
                    <>
                        <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                            <Form.Item label={"Status"}>
                                {typeSelect()}
                            </Form.Item>
                        </Form>
                        <Divider style={{ "margin": "10px 0px" }} dashed />
                        <div className="shim"></div>
                    </>
                )

            case "pubdate":
            case "pubdate_us":
                return (
                    <>
                        <Form layout="vertical">
                            <Form.Item label={ucfirst(field)}>
                                <DatePicker style={{ "width": "200px" }}
                                    allowClear={false}
                                    format="MMMM Do, YYYY"
                                    value={(value) ? moment(value) : moment()}
                                    onChange={(e) => setValue(e.format("YYYY-MM-DD"))}
                                    placeholder={"Enter publish date"} />
                                {/* <Input value={value} onChange={(e) => setValue(e.target.value)}  /> */}
                            </Form.Item>
                        </Form>
                        <Divider style={{ "margin": "10px 0px" }} dashed />
                        <div className="shim"></div>
                    </>
                )

            case "weight":
                return (
                    <>
                        <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                            <Form.Item help={<small className="c" style={{ "opacity": "0.5" }}>in lbs. Grams will convert, e.g. 30g</small>} label={ucfirst(field)}>
                                <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder={"Enter " + field} />
                            </Form.Item>
                        </Form>
                    </>
                )

            case "depth":
            case "height":
            case "width":
                return (
                    <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                        <Form.Item help={<small className="c" style={{ "opacity": "0.5" }}>in inches</small>} label={ucfirst(field)}>
                            <Input type="number" value={value} onChange={(e) => setValue(e.target.value)} placeholder={"Enter " + field} />
                        </Form.Item>
                    </Form>
                )

            case "sales_rights_exclude":

                // Removed by kellynda's request #1131
                return(<></>)
                // return (
                //     <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                //         <Form.Item label={ucfirst(field)}>
                //             {statusSalesRights()}
                //         </Form.Item>
                //     </Form>
                // )


            case "pages":
            case "series_number":
            case "edition_number":
            case "audience_min":
            case "audience_max":
                return (
                    <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">
                        <Form.Item label={ucfirst(field)}>
                            <Input type="number" value={value} onChange={(e) => setValue(e.target.value)} placeholder={"Enter " + field} />
                        </Form.Item>
                    </Form>
                )


            default:
                return (
                    <>
                        <Form className={(!valid && (!value)) ? "ant-form-item-has-error" : ""} layout="vertical">

                            
                            <Tabs size="small" className="itemTabs" type="card">
                                <Tabs.TabPane tab={<small>Text</small>} key="item-1">
                                    <div className="ant-input" style={{ "padding": "10px", "borderRadius": "2px", "minHeight": "150px", "maxHeight": "150px", "overflow": "scroll" }}>
                                        <Editor
                                            tag="p"
                                            forcePlainText={true}
                                            text={value}
                                            onChange={(e) => setValue(e)}
                                            options={{ toolbar: { buttons: ['bold', 'italic', 'underline', 'anchor'] } }}
                                        />
                                        
                                    </div>
                                    <div className="shim" /><div className="shim" /><div className="shim" /><div style={{"height" : "3px"}} className="shim" />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<small>HTML</small>} key="item-2">
                                    <Form.Item>
                                        <Input.TextArea style={{"minHeight": "150px", "maxHeight": "150px", resize: 'none'}} value={value} rows={5} onChange={(e) => setValue(e.target.value)} placeholder={"Enter " + field} />
                                    </Form.Item>
                                </Tabs.TabPane>
                            </Tabs>






                        </Form>
                    </>
                )

        }

    }


    const strToArr = (_v) => {

        if (Array.isArray(_v)) {
            return _v;
        } else {
            if (_v) {
                return _v.split(",")
            } else {
                return _v;
            }

        }
    }



    const languageSelect = () => {

        return (
            <>
                <Select mode="multiple" placeholder="Select code" onChange={(e) => setValue(e)} value={(value) ? strToArr(value) : undefined} >
                    {countries.map((item, index) => {
                        return (<Select.Option key={index} value={item.value}>{item.label}</Select.Option>)
                    })}
                </Select>
            </>
        )
    }


    const codeSelect = () => {

        return (
            <>
                <Select mode="multiple" placeholder="Select code" onChange={(e) => setValue(e)} value={(value) ? strToArr(value) : undefined} >
                    <Select.Option value="02" >02 Children</Select.Option>
                    <Select.Option value="03" >03 Young Adult</Select.Option>
                </Select>
            </>
        )
    }


    const editionSelect = () => {

        return (
            <Select placeholder="Select type" onChange={(e) => setValue(e)} value={(value) ? value : undefined} >
                <Select.Option value="ABR" >ABR Abridged</Select.Option>
                <Select.Option value="ILL">ILL Illustrated</Select.Option>
                <Select.Option value="LTE">LTE Large type / large print</Select.Option>
                <Select.Option value="REV">REV Revised</Select.Option>
                <Select.Option value="SPE">SPE Special edition</Select.Option>
                <Select.Option value="UBR">UBR Unabridged</Select.Option>
                <Select.Option value="ULP">ULP Ultra large print</Select.Option>

            </Select>
        )
    }


    const typeSelect = () => {

        return (
            <Select placeholder="Select type" onChange={(e) => setValue(e)} value={(value) ? value : undefined} >
                <Select.Option value="A" >A Age</Select.Option>
                <Select.Option value="G" >G Grade</Select.Option>
            </Select>
        )
    }


    const statusSalesRights = () => {

        return (
            <Select mode="multiple" placeholder="Select" onChange={(e) => setValue(e)} value={(value) ? strToArr(value) : undefined} >
                <Select.Option value="US" >US United States</Select.Option>
                <Select.Option value="UK" >UK United Kingdom</Select.Option>
                <Select.Option value="CA" >CA Canada</Select.Option>
            </Select>
        )
    }



    const statusSelect = () => {

        return (
            <Select placeholder="Select status" onChange={(e) => setValue(e)} value={(value) ? value : undefined} >
                <Select.Option value="OP" >OP Out of Print</Select.Option>
                <Select.Option value="OSI" >OSI Out of Stock Indefinitely</Select.Option>
                <Select.Option value="PC" >PC Publication Cancelled</Select.Option>
                <Select.Option value="PP" >PP Postponed</Select.Option>
            </Select>
        )
    }

    const bindingsSelect = () => {

        return (
            <Select placeholder="Select binding" onChange={(e) => setValue(e)} value={(value) ? value : undefined}  >
                <Select.Option value="HC" >HC Hardcover</Select.Option>
                <Select.Option value="PB">PB Paperback</Select.Option>
                <Select.Option value="TP">TP Paperback</Select.Option>
                <Select.Option value="DS">DS 1.2M, 5.25 Disk</Select.Option>
                <Select.Option value="AU">AU Audio</Select.Option>
                <Select.Option value="AA">AA Audio</Select.Option>
                <Select.Option value="AC">AC Audio Cassette</Select.Option>
                <Select.Option value="CD">CD Audio CD</Select.Option>
                <Select.Option value="AD">AD Audio CD</Select.Option>
                <Select.Option value="CM">CM Audio CD (MP3)</Select.Option>
                <Select.Option value="BA">BA Bath Book</Select.Option>
                <Select.Option value="BR">BR Blu-ray</Select.Option>
                <Select.Option value="BG">BG Board Game</Select.Option>
                <Select.Option value="BB">BB Boardbook</Select.Option>
                <Select.Option value="BD">BD Boardbook</Select.Option>
                <Select.Option value="BK">BK Book</Select.Option>
                <Select.Option value="BM">BM Bookmark</Select.Option>
                <Select.Option value="BX">BX Box or Slipcased</Select.Option>
                <Select.Option value="CA">CA Calendar</Select.Option>
                <Select.Option value="CS">CS Cards</Select.Option>
                <Select.Option value="CR">CR CD-ROM</Select.Option>
                <Select.Option value="CL">CL Cloth Book</Select.Option>
                <Select.Option value="CG">CG Clothing</Select.Option>
                <Select.Option value="CB">CB Comb Bound</Select.Option>
                <Select.Option value="DY">DY Display</Select.Option>
                <Select.Option value="DO">DO Doll</Select.Option>
                <Select.Option value="DV">DV DVD</Select.Option>
                <Select.Option value="EB">EB E-Book</Select.Option>
                <Select.Option value="GM">GM Game</Select.Option>
                <Select.Option value="GC">GC Greeting Card</Select.Option>
                <Select.Option value="TC">TC Hardcover</Select.Option>
                <Select.Option value="CT">CT Hardcover</Select.Option>
                <Select.Option value="IL">IL Imitation Leather</Select.Option>
                <Select.Option value="LP">LP Large Print</Select.Option>
                <Select.Option value="LT">LT Leather/Fine Binding</Select.Option>
                <Select.Option value="LB">LB Library Binding</Select.Option>
                <Select.Option value="LL">LL Loose-Leaf</Select.Option>
                <Select.Option value="MU">MU Mug</Select.Option>
                <Select.Option value="OA">OA Otabind</Select.Option>
                <Select.Option value="OT">OT Other</Select.Option>
                <Select.Option value="PA">PA Paperback</Select.Option>
                <Select.Option value="MM">MM Paperback</Select.Option>
                <Select.Option value="SC">SC Paperback</Select.Option>
                <Select.Option value="PT">PT Paperback</Select.Option>
                <Select.Option value="PO">PO Pop Up</Select.Option>
                <Select.Option value="PU">PU Puzzle</Select.Option>
                <Select.Option value="RB">RB Ring Bound</Select.Option>
                <Select.Option value="SE">SE Set</Select.Option>
                <Select.Option value="MP">MP Sheetmap</Select.Option>
                <Select.Option value="SW">SW Software</Select.Option>
                <Select.Option value="SP">SP Spiral Bound</Select.Option>
                <Select.Option value="ST">ST Stapled</Select.Option>
                <Select.Option value="TA">TA Tableware</Select.Option>
                <Select.Option value="TO">TO Tote bag</Select.Option>
                <Select.Option value="TY">TY Toy</Select.Option>
                <Select.Option value="XA">XA Trade Material</Select.Option>
                <Select.Option value="TR">TR Transparencies</Select.Option>
                <Select.Option value="VH">VH VHS Video</Select.Option>
                <Select.Option value="VD">VD Video</Select.Option>
                <Select.Option value="VB">VB Vinyl Bound</Select.Option>
                <Select.Option value="VP">VP Vinyl Paperback</Select.Option>
            </Select>

        )



    }




    return drawRenderer();

}