import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal, Typography, Divider, Tag, Space, Button, Switch, Select, Input, Radio, Badge, DatePicker, message, Alert, InputNumber } from "antd";
import moment from "moment";

import conditional from "../utils/conditional";
import { apiCall } from "../utils/Api";
import ISOCodes from "./ISOCodes";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import FilterPresetSelect from "./lists/FilterPresetSelect";
export default function Promo(props) {

    const history = useHistory();
    const [error, setError] = useState("");
    const [form] = Form.useForm();

    const { close = () => { }, data = {}, promos, setPromos, create_new = false, promocodes = false, canFreeShip = false } = props;


    const {
        id = "",
        description = "",
        code = "",
        start = 0,
        end = 0,
        timezone = "UTC",
        automatic = true,
        use_once = false,
        use_once_per_cust = false,
        apply_to_cart = true,
        on_hand = true,
        on_order = true,
        item_limit = 0,
        min_value = 0.00,
        include_filter = {
            conditions: [],
            bisacs: [],
            bindings: [],
            lists: [],
            filter_preset: ""
        },
        exclude_filter = {
            conditions: [],
            bisacs: [],
            bindings: [],
            lists: []
        },
        applied_to = [],
        type = "percentage",
        discount = 0,
        amount = 0,
        bogo_buy = 1,
        bogo_free = 1,
        bogo_count = 1,
        freeship_isocodes = []
    } = data;

    const [uo, setUo] = useState(use_once);
    const [uoc, setUoc] = useState(use_once_per_cust);


    console.log(data);

    let m = "a";
    if (include_filter["lists"].length > 0 || exclude_filter["lists"].length > 0) {
        m = "b";
    }

    if (include_filter.hasOwnProperty("lists") &&  include_filter["lists"].length > 0 || exclude_filter["lists"].length > 0) {
        m = "b";
    }

    if (include_filter.hasOwnProperty("filter_preset") && (include_filter.filter_preset)) {
        m = "c";
    }

    // if (include_filter["conditions"].length > 0 || include_filter["bisacs"].length > 0 || include_filter["bindings"].length > 0 || exclude_filter["conditions"].length > 0 || exclude_filter["bisacs"].length > 0 || exclude_filter["bindings"].length > 0) {
    //     m = "c";
    // }


    const [lists, setLists] = useState([]);
    const [filters, setFilters] = useState({});
    const [promoType, setPromoType] = useState(type);
    const [apply, setApply] = useState(m);


    const resetAllCustomers = () => {
        apiCall("promos/deleteCustomersPerPromo", { code: data.code }, (_status, _result) => {
            if (_status) {
                message.success("Customers reset successfully.");
                close();
            } else {
                message.error(_result.error)
            }
        })
    }



    const notifyHasCustomers = (_count = "0") => {
        Modal.confirm({ title: "Warning", onOk: () => resetAllCustomers(), okText: "Unset all", content: "There are " + _count.toString() + " customer(s) who are using or have used this code." });
    }

    const ucfirst = (string) => {
        return (string) ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }


    // Fetch Filters
    const getFilters = () => {
        apiCall("promos/getFilterOptions", {}, (_status, _results) => {
            if (_status) { setFilters(_results); }
        });
    }
    useEffect(getFilters, []);



    // Fetch Lists
    const getLists = () => {
        apiCall("titlelist/getMyListNames", {}, (_status, _results) => {
            if (_status) { setLists(_results.rows); }
        });
    }
    useEffect(getLists, []);


    // Draw Lists
    const drawListSelect = () => {
        if (!(lists.length > 0)) {
            return (<></>)
        }
        return (
            <Form.Item initialValue={include_filter["lists"]} label="Custom Lists" name="list">
                <Select
                    style={{ width: '336px' }}
                    placeholder="Select lists"
                    optionLabelProp="label"
                    mode="multiple"
                >
                    {lists.map((item, index) => {
                        return (
                            <Select.Option key={index} label={item.name} value={item.eid} >
                                {item.name}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        )
    }


    const drawPresetSelect = () => {

        return (
            <Form.Item initialValue={include_filter.filter_preset} label="Filter Preset" name="filter_preset">
                <FilterPresetSelect width={336} />
            </Form.Item>
        )

    }


    const sendForm = (_f) => {



        setError("");


        // Need One time use for item limit...
        if (!(uo || uoc)) {
            if (_f["item_limit"]) {
                _f["item_limit"] = 0;
            }

        }


        if (_f["code"]) {
            _f["code"] = _f["code"].toUpperCase();
        }
        _f["automatic"] = !promocodes;

        switch (_f["type"]) {
            case "dollar":
                _f["discount"] = _f["dollar_amount"];
                delete _f["dollar_amount"];
                break;
            case "percentage":
                _f["discount"] = _f["percent_amount"];
                delete _f["percent_amount"]
                break;
            case "bogo":
                _f["bogo_buy"] = _f["bogo_buy"];
                _f["bogo_free"] = _f["bogo_free"];
                break;
        }


        // ship codes

        if (_f["freeship_isocodes"]) {
            _f["freeship_isocodes"] = JSON.stringify(_f["freeship_isocodes"]);
        }

        if (_f["duration"] && _f["duration"][0]) {
            _f["start"] = moment(_f["duration"][0]).startOf("minute").unix();
        } else {
            _f["start"] = moment().startOf("minute").unix()
        }

        if (_f["duration"] && _f["duration"][1]) {
            _f["end"] = moment(_f["duration"][1]).endOf("minute").unix();
        } else {
            _f["end"] = 0;
        }


        delete _f["duration"];
        // if (_f["duration"]) {

        //     if (_f["duration"][0]) {
        //         _f["start"] = moment(_f["duration"][0]).unix();
        //     }
        //     if (_f["duration"][1]) {
        //         _f["end"] = moment(_f["duration"][1]).unix();
        //     }

        //     delete _f["duration"];
        // }

        if (_f["include_filter"]) { _f["include_filter"] = JSON.stringify(_f["include_filter"]) } else {
            _f["include_filter"] = JSON.stringify({});
        }
        if (_f["exclude_filter"]) { _f["exclude_filter"] = JSON.stringify(_f["exclude_filter"]) } else {
            _f["exclude_filter"] = JSON.stringify({});
        }

        if (_f["list"]) {
            _f["include_filter"] = JSON.stringify({ "lists": _f["list"] });
            delete _f["list"];
        }

        if(_f["filter_preset"]){
            _f["include_filter"] = JSON.stringify({ "filter_preset": _f["filter_preset"] });
        }


        if (create_new) {

            apiCall("promos/create", _f, (_status, _results) => {
                if (_status) {
                    setPromos(_results.rows);
                    close();

                    if (_results.has_customers) {
                        notifyHasCustomers(_results.customer_count);
                    } else {
                        message.success("Promotion created successfully.");
                    }

                } else { setError(_results.error) }
            });

        } else {

            _f["id"] = id;

            apiCall("promos/modify", _f, (_status, _results) => {
                if (_status) {

                    setPromos(_results.rows);
                    close();

                    if (_results.has_customers) {
                        notifyHasCustomers(_results.customer_count);
                    } else {
                        message.success("Promotion saved successfully.");
                    }


                } else { setError(_results.error) }
            });
        }


    }

    const parseCodes = (_arr) => {
        return _arr.toString();
    }


    const check = (e, oh = true) => {


        if (!form.getFieldValue(on_hand) && !form.getFieldValue(on_order)) {
            if (!oh) {
                form.setFieldsValue({ "on_hand": true });
            } else {
                form.setFieldsValue({ "on_order": true });
            }

        }

    }

    const drawEdit = () => {


        return (
            <>
                <Form form={form} onFinish={(_f) => sendForm(_f)} layout="vertical">
                    <Row gutter={30}>
                        <Col span={15}>

                            <conditional.true value={(!canFreeShip)}>
                                <Alert style={{ "padding": "5px 15px" }} type="warning" description={<div>Free shipping promos require <a onClick={() => history.push("/settings/web-store-settings/shipping")}>Multi Courier Shipping</a></div>} />
                                <div className="shim" /><div className="shim" /><div className="shim" />
                            </conditional.true>

                            <conditional.true value={(promocodes)}>
                                {/* normalize={value => (value || '').toUpperCase().replace(/\s/g, '')} */}
                                <Form.Item rules={[{ required: true, message: "Code required." }]} initialValue={code} name="code" label="Code">
                                    <Input maxLength={10} placeholder="Code..." style={{ "fontFamily": "monospace", "fontWeight": "bold", "width": "200px", "textTransform": "uppercase" }} />
                                </Form.Item>
                            </conditional.true>

                            <Row gutter={10}>
                                <Col span={11}>
                                    <Form.Item rules={[{ required: true, message: "Type required." }]} initialValue={promoType} name="type" label="Type">
                                        <Select onChange={(e) => setPromoType(e)}>
                                            <Select.Option value="dollar">Fixed amount</Select.Option>
                                            <Select.Option value="percentage">Percentage</Select.Option>
                                            <Select.Option value="bogo">BOGO Free</Select.Option>
                                            <Select.Option value="bogo_percent">BOGO Percentage</Select.Option>
                                            <Select.Option disabled={(!canFreeShip)} value="freeship">Free shipping</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={13}>
                                    <conditional.true value={(promoType === "dollar")}>
                                        <Form.Item initialValue={(discount) ? discount : 0.01} rules={[{ required: true, message: "Dollar amount required." }]} name="dollar_amount" label="Dollar amount">
                                            <InputNumber min={0.01} step={0.01} precision={2} type="number" prefix="$" style={{ "width": "100px" }} />
                                        </Form.Item>
                                    </conditional.true>
                                    <conditional.true value={(promoType === "percentage")}>
                                        <Form.Item initialValue={(discount) ? discount : 1} rules={[{ required: true, message: "Percent discount required." }]} name="percent_amount" label="% off">
                                            {/* <Input maxLength={6} type="number" suffix="%" style={{ "width": "100px" }} /> */}
                                            <InputNumber style={{ "width": "65px" }} min={1} max={100} maxLength={3} precision={0} />
                                        </Form.Item>
                                    </conditional.true>

                                    <conditional.true value={(promoType === "bogo_percent")}>
                                        <Space>
                                            <Form.Item initialValue={bogo_buy} name="bogo_buy" label="Buy">
                                                <Select>
                                                    <Select.Option value={1}>1</Select.Option>
                                                    <Select.Option value={2}>2</Select.Option>
                                                    <Select.Option value={3}>3</Select.Option>
                                                    <Select.Option value={4}>4</Select.Option>
                                                    <Select.Option value={5}>5</Select.Option>
                                                    <Select.Option value={6}>6</Select.Option>
                                                    <Select.Option value={7}>7</Select.Option>
                                                    <Select.Option value={8}>8</Select.Option>
                                                    <Select.Option value={9}>9</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <div> - </div>
                                            <Form.Item initialValue={bogo_count} name="bogo_count" label="Get">
                                                <Select>
                                                    <Select.Option value={1}>1</Select.Option>
                                                    <Select.Option value={2}>2</Select.Option>
                                                    <Select.Option value={3}>3</Select.Option>
                                                    <Select.Option value={4}>4</Select.Option>
                                                    <Select.Option value={5}>5</Select.Option>
                                                    <Select.Option value={6}>6</Select.Option>
                                                    <Select.Option value={7}>7</Select.Option>
                                                    <Select.Option value={8}>8</Select.Option>
                                                    <Select.Option value={9}>9</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item initialValue={(discount) ? discount : 1} name="discount" label="% off">
                                                {/* <Input maxLength={6} type="number" suffix="%" style={{ "width": "90px" }} /> */}
                                                <InputNumber style={{ "width": "65px" }} min={1} max={100} maxLength={3} precision={0} />
                                            </Form.Item>
                                        </Space>
                                    </conditional.true>

                                    <conditional.true value={(promoType === "bogo")}>
                                        <Space>
                                            <Form.Item initialValue={bogo_buy} name="bogo_buy" label="Buy">
                                                <Select>
                                                    <Select.Option value={1}>1</Select.Option>
                                                    <Select.Option value={2}>2</Select.Option>
                                                    <Select.Option value={3}>3</Select.Option>
                                                    <Select.Option value={4}>4</Select.Option>
                                                    <Select.Option value={5}>5</Select.Option>
                                                    <Select.Option value={6}>6</Select.Option>
                                                    <Select.Option value={7}>7</Select.Option>
                                                    <Select.Option value={8}>8</Select.Option>
                                                    <Select.Option value={9}>9</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <div> - </div>
                                            <Form.Item initialValue={bogo_free} name="bogo_free" label="Get">
                                                <Select>
                                                    <Select.Option value={1}>1</Select.Option>
                                                    <Select.Option value={2}>2</Select.Option>
                                                    <Select.Option value={3}>3</Select.Option>
                                                    <Select.Option value={4}>4</Select.Option>
                                                    <Select.Option value={5}>5</Select.Option>
                                                    <Select.Option value={6}>6</Select.Option>
                                                    <Select.Option value={7}>7</Select.Option>
                                                    <Select.Option value={8}>8</Select.Option>
                                                    <Select.Option value={9}>9</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <div>Free</div>
                                        </Space>
                                    </conditional.true>
                                </Col>
                            </Row>
                            <conditional.true value={(promoType === "freeship")}>

                                <ISOCodes freeship_isocodes={freeship_isocodes} />


                            </conditional.true>
                            <Form.Item name="description" style={{ "maxWidth": "180px" }} initialValue={description} rules={[{ required: true, message: "Title required" }]} label="Title">
                                <Input maxLength={20} placeholder="Describe promotion..." />
                            </Form.Item>
                            <Form.Item initialValue={(start) ? [moment(start * 1000), (end) ? moment(end * 1000) : ""] : ""} name="duration" label="Duration">
                                <DatePicker.RangePicker format="MMM Do YYYY - h:mma" showTime />
                            </Form.Item>
                            <Form.Item initialValue={min_value} name="min_value" label="Minimum spend">
                                <Input type="number" prefix="$" style={{ "width": "100px" }} />
                            </Form.Item>
                            <Form.Item label="Apply promotion to:">
                                <Radio.Group onChange={(e) => setApply(e.target.value)} defaultValue={apply} buttonStyle="solid">
                                    <Radio.Button value="a">All products</Radio.Button>
                                    <Radio.Button value="b">Custom Lists</Radio.Button>
                                    <Radio.Button value="c">Filter preset</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            <conditional.true value={(apply === "b")}>
                                {drawListSelect()}
                            </conditional.true>
                            <conditional.true value={(apply === "c")}>
                                {drawPresetSelect()}
                            </conditional.true>


                        </Col>
                        <Col span={9}>
                            <Form.Item initialValue={(on_hand) ? on_hand : (on_order) ? false : true} valuePropName="checked" name="on_hand" label="Include on hand">
                                <Switch onChange={(e) => check(e, true)} size="small" />
                            </Form.Item>
                            <Form.Item initialValue={(on_order)} valuePropName="checked" name="on_order" label="Include on order">
                                <Switch onChange={(e) => check(e, false)} size="small" />
                            </Form.Item>
                            {/* <Form.Item initialValue={(apply_to_cart)} valuePropName="checked" name="apply_to_cart" label="Apply to cart">
                                <Switch size="small" />
                            </Form.Item> */}
                            <conditional.true value={(promocodes)}>
                                <>
                                    <Form.Item help={<small className="c" style={{ "opacity": "0.6" }}>The promo code can only be used by one customer, and immediately expires upon its first use.</small>} initialValue={(use_once)} valuePropName="checked" name="use_once" label="One time use (all time)">
                                        <Switch disabled={(uoc)} onChange={(e) => setUo(e)} size="small" />
                                    </Form.Item>
                                    <div className="shim" /><div className="shim" />
                                </>
                            </conditional.true>

                            <conditional.true value={(promocodes)}>
                                <Form.Item help={<small className="c" style={{ "opacity": "0.6" }}>The promo code can be used by multiple customers, but limited to one use per customer. Customer must be logged in.</small>} initialValue={(use_once_per_cust)} valuePropName="checked" name="use_once_per_cust" label="One time use (per customer)">
                                    <Switch disabled={(uo)} onChange={(e) => setUoc(e)} size="small" />
                                </Form.Item>
                                <div className="shim" /><div className="shim" />
                            </conditional.true>

                            <conditional.true value={(promocodes && promoType === "percentage")}>
                                <div style={{ "opacity": (uo || uoc) ? "1" : "0.6" }}>
                                    <div className="shim" />
                                    <Form.Item help={<small className="c" style={{ "opacity": "0.6" }}>The promo code can only be applied to this number of products, the most expensive of which will be discounted. A <strong>One time use</strong> option must be selected with this option. This option is only available for the <strong>Percentage</strong> type promo code.</small>} initialValue={item_limit} name="item_limit" label="Item limit">
                                        <InputNumber disabled={!(uo || uoc)} min={0} precision={0} />
                                    </Form.Item>
                                </div>
                            </conditional.true>


                        </Col>
                    </Row>







                    <Divider />
                    <conditional.true value={(error)}>
                        <Alert type="error" message="Error" description={error} />
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                    </conditional.true>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => close()}>Cancel</Button>
                            <Button htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </>
        )
    }


    return (
        <div style={{ "padding": "25px" }}>
            <Typography.Title level={5}>{(create_new) ? "Create new" : "Edit"} {(promocodes) ? "promo code" : "promotion"}</Typography.Title>
            <Divider />
            {drawEdit()}
        </div>
    )

}