import React, { useState } from "react";
import { Layout, Card, Form, DatePicker, Row, Col, Space } from "antd";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import moment from "moment-timezone";
import DropMenu from "../../components/DropMenu";
import UsageDevices from "./UsageDevices";
import SiteTraffic from "./SiteTraffic";
import Engagement from "./Engagement";
import SiteContent from "./SiteContent";

export default function SiteUsagePage() {

    const [filters, setFilters] = useState({
        start_date: moment().add(-60, "days").startOf("day"),
        end_date: moment().add(-1, "day").endOf("day"),
    })

    // Add an extra day to the count (startOf, endOf doesn't inc the day count)
    const range = moment(filters.end_date).endOf("day").diff(moment(filters.start_date).startOf("day"), "days") + 1;

    // Show yesterday, today in leau of date
    const customFormat = (value) => {
        let _diff = moment().startOf("day").diff(value.startOf("day"), "days");
        if (_diff === 1) { return "Yesterday"; }
        if (_diff === 1) { return "Today"; }
        return value.format("MMMD-YY ddd");
    }

    // Dropdown presets (pre calculated ranges)
    const presets = [
        { label: "1 Day", value: 1 },
        { label: "7 Days", value: 7 },
        { label: "30 Days", value: 30 },
        { label: "60 Days", value: 60 },
        { label: "90 Days", value: 90 },
        { label: "180 Days", value: 180 },
        { label: "365 Days", value: 365 },
        { label: "Month to date (Calendar month)", value: moment(filters.end_date).diff(moment(filters.end_date).startOf("month"), "days") },
        { label: "Quarter to date (Calendar quarter)", value: moment(filters.end_date).diff(moment(filters.end_date).startOf("quarter"), "days") },
        { label: "Year to date (Calendar year)", value: moment(filters.end_date).diff(moment(filters.end_date).startOf("year"), "days") },
    ]

    const setPreset = (_preset) => {
        setFilters({ ...filters, "start_date": moment(filters.end_date).add(-_preset.value, "days").startOf("day") })
    }

    const drawFilters = () => {
        return (
            <Form layout="vertical" size="small">
                <Space>
                    <Form.Item label={<small>Ending <span>({range.toString()} days)</span></small>}>
                        <DatePicker format={customFormat} onChange={(mom) => setFilters({ ...filters, "end_date": mom.startOf("day") })} value={moment(filters.end_date)} />
                    </Form.Item>
                    <Form.Item initialValue={moment(filters.start_date)} label={<small>Beginning</small>}>
                        <DropMenu custom hover title={
                            <DatePicker format={customFormat} onChange={(mom) => setFilters({ ...filters, "start_date": mom.endOf("day") })} value={moment(filters.start_date)} />
                        }>
                            <Card style={{ "width": "220px", "marginTop": "2px" }} className="custom-shadow">
                                {presets.map(item => {
                                    return (<div onClick={() => setPreset(item)}><a><small>{item.label}</small></a></div>)
                                })}
                            </Card>
                        </DropMenu>
                    </Form.Item>
                </Space>
            </Form>
        )
    }

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Site Usage"
                    filters={drawFilters()}
                    description={<>Use this page to gain insight on your customer website traffic.</>}
                />
                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <SiteTraffic filters={filters} />
                        <br /><br />
                        <Row gutter={30}>
                            <Col flex={"885px"}>
                                <Engagement filters={filters} />
                            </Col>
                            <Col flex={"auto"}>
                                <UsageDevices filters={filters} />
                            </Col>
                        </Row>

                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}