import React, {useState} from "react";
import { Button, message, Popconfirm } from "antd";
import { apiCall } from "../../utils/Api";

export default function ResendOrder(props) {

    const {bookorder_id} = props; 
    const [sent, setSent] = useState(false)
    const resendOrder = () =>{
        apiCall("orders/resend", {bookorder_id : bookorder_id}, (_status, _result) =>{
            if(_status){
                message.success("Order resent successfully.")
                setSent(true);
            } else {
                message.error("There was an error.")
            }
        });
    }

    return (
        <Popconfirm placement="left" title="Resend Order?" onConfirm={() => resendOrder()} okText="Yes" cancelText="No">
            <Button type="primary" size="small"><small>{(!sent) ? "Resend" : "Resent!"}</small></Button>
        </Popconfirm>
    )
}