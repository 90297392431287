import { Button, Space, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { objectToPath, pathToObject } from "../utils/Utils";
import { apiCall } from "../utils/Api";

export default function FilterPresetLabel(props) {

    const { setToolboxes = () => {}, searchFilters = {}, toolboxes = { store_specific: [], bookmanager: [] } } = props;
    const location = useLocation();
    const obj = pathToObject(location.pathname);
    const preset_id = (obj.hasOwnProperty("p")) ? obj.p : "";

    const [init, setInit] = useState(false);
    const [showSave, setShowSave] = useState(true);
    const [loading, setLoading] = useState(false)
    
    const updatePreset = (_preset) => {
        setLoading(true)

        let _sf = { ...searchFilters };

        delete _sf.l;
        delete _sf.o;
        if(_sf.hasOwnProperty("p")){
            delete _sf.p; 
        }

        let obj = {
            id: preset_id,
            name: _preset.name,
            header: _preset.header,
            version: "v3",
            selections_json: JSON.stringify(_sf)
        }


        apiCall("toolbox/set", obj, (_status, _result) => {
            if (_status) {
                message.success("Preset `" + _preset.name + "` Updated!");
                setToolboxes(_result);
                setShowSave(false);
            } else {
                message.error(_result.error);
            }
            setLoading(false)
        })
    }


    const preset_label = toolboxes.store_specific.find(item => item.id == preset_id) || toolboxes.bookmanager.find(item => item.id == preset_id);


    if (!preset_id) {
        return (<></>);
    }
    
    if (!preset_label) {
        return (<></>);
    }

    const { name = "", selections = {} } = preset_label;
    const path = objectToPath(selections);


    return (
        <Space>
            {/* <div style={{ "padding": "0px", "paddingRight": "5px", "color": "#007ef5" }} type="link"><em>Preset "{name}"</em> →</div> */}
            <div style={{ "padding": "0px", "paddingRight": "5px", "color": "#007ef5" }} type="link"><em>Preset "{name}"</em> &nbsp;&nbsp;<Tag color="#108ee9" style={{"cursor" : "pointer", "height" : "23px", "backgroundColor" : "#007ef5"}} loading={loading}  onClick={() => updatePreset(preset_label)} size="small" type="primary"><small>Save Preset</small></Tag>→&nbsp;</div>
        </Space>
    )
}