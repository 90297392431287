import React from "react";
import { Layout, Form, Anchor, Card, Typography, Divider, Input, Button, Space } from "antd";
import WebstoreItem from "../../layouts/Webstore/WebstoreItem";
import ColorPicker from "rc-color-picker";
import 'rc-color-picker/assets/index.css';
import LayoutHeader from "../../layouts/partials/LayoutHeader";

export default function AppearancePage() {


    const drawColor = (_label, _color) => {

        return (
            <>
                <Space>
                    <div style={{ width: "100px" }}>{_label}</div>
                    <Input style={{ "width": "70px", "fontSize": "12px" }} value={_color} size="small" />
                    <div style={{ "paddingTop": "5px" }}><ColorPicker color={_color} enableAlpha={false} /></div>
                </Space>
                <div className="shim" />
            </>
        )
    }

    return (
        <>


            <Layout className="layout">

                <LayoutHeader
                    todo
                    title="Appearance"
                    description="Use this page configure website graphics and colours."
                    button={<><Button size="small" type="primary"><small>Save settings</small></Button></>}
                />

                <Layout.Content style={{"padding": "0px" }} >
                    <div style={{ "height": "calc(100vh - 250px)" }}>

                    </div>
                </Layout.Content>


            </Layout>
        </>
    )
}