import { Card } from "antd";
import React from "react";

export default function MSWrap(props) {

    const { stores = [], padding = 22 } = props;

    if (stores.length > 1) {
        return (
            <Card bodyStyle={{"padding" : padding + "px"}} className="bcg3">
                {props.children}
            </Card>
        )
    } else {
        return props.children;
    }

}