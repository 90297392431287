import React, { useEffect, useState } from "react";
import { Layout, Typography, Divider, Table, Button, Popconfirm, message, Card, Modal, Input, Space, Alert } from "antd";
import { FormOutlined, DeleteOutlined, ShareAltOutlined } from '@ant-design/icons';
import moment from "moment";
import 'moment-timezone';
import Event from "../../components/Event";
import { apiCall } from "../../utils/Api";
import Paginate from "../../components/Paginate";
import Overlay from "../../components/Overlay";
import EventVisibilityToggle from "../../components/EventVisibilityToggle";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { trimTrailingSlash } from "../../utils/Utils";
import UpgradeEvents from "../../components/events/UpgradeEvents";

export default function EventsPage() {

    const [results, setResults] = useState({ events: [], total_rows: 0 });
    const [filters, setFilters] = useState({ current: 1, start: 0, limit: 20 });
    const [loading, setLoading] = useState(false);

    const getEvents = () => {
        setLoading(true);
        apiCall("events/get", filters, (_status, _result) => {
            if (_status) {
                setResults(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getEvents, [filters]);

    const deleteEvent = (_event_id) => {
        setLoading(true);
        apiCall("events/remove", { event_id: _event_id }, (_status, _result) => {
            if (_status) {
                let _rows = results.events.filter(item => item.event_id !== _event_id);
                setResults({ ...results, "events": _rows });
                setLoading(false);
                message.success("Event deleted.");
            }
        })
    }

    const updateRow = (_arg, _edit = true) => {
        let _events = [...results.events]
        if (_edit) {
            let _index = _events.findIndex(item => item.event_id === _arg.event_id);
            if (_index > -1) {
                _events[_index] = _arg;
                setResults({ ...results, "events": _events });
            }
            return;
        }
        return;
    }

    const CopyButton = (props) => {
        const { path = "" } = props
        const [copied, setCopied] = useState(false);

        const copyToClipboard = (_str) => {
            if (!navigator.clipboard) {
                message.error("Can't copy");
                return;
            }
            navigator.clipboard.writeText(path);
            setCopied(true);
            setTimeout(() => {
                setCopied(false)
            }, 1000);
        }

        return (<Button style={{"width" : "80px"}} onClick={(e) => copyToClipboard(e)} type={(copied ? "default" : "primary")}>{(copied) ? "Copied" : "Copy"}</Button>);

    }
    

    const EventShare = (props) => {

        let url = trimTrailingSlash(window.sitesettings.url) + "/events/" + props.event_id;

        return (
            <div style={{"padding" : "20px"}}>
                <Typography.Title level={5}>Share</Typography.Title>
                <Typography.Paragraph>To share this event with <strong>customers</strong>, copy this link (e.g. Ctrl-C) and then paste it into an email.</Typography.Paragraph>
                {(!props.visible && <><Alert style={{"padding" : "3px 10px", "textAlign" : "center"}} message={<small>This event isn't visible. Ensure the event is visible and active before sending a link.</small>} type="error" /><div className="shim" /><div className="shim" /></>)}
                <Space>
                    <Input onFocus={(e) => e.target.select()} style={{ "width": "400px" }} defaultValue={url} />
                    <CopyButton path={url} />
                </Space>
            </div>
        )
    }


    const columns = [

        {
            title: <small></small>, className: "cellBorder", width: "0px", dataIndex: 'id', key: 'id', render: (e, f) => {
                return (<Button size="small" type="link"><Overlay maskClosable={false} width={1000} component={<Event updateRow={updateRow} event_id={f.event_id} />}><FormOutlined /></Overlay></Button>)
            }
        },

        {
            title: <small></small>, className: "cellBorder", width: "0px", dataIndex: 'id', key: 'id', render: (e, f) => {
                return (<Overlay maskClosable={false} width={525} component={<EventShare visible={f.visibility} event_id={f.event_id} />}><Button size="small" type="link"><ShareAltOutlined /></Button></Overlay>)
            }
        },
        {
            title: <small></small>, dataIndex: 'top', width: "10px", key: 'top'
        },
        {
            title: <small>Event Name</small>, dataIndex: 'name', key: 'name', render: (e, f) => {
                return e;
            }
        },
        {
            title: <small>From</small>, dataIndex: 'event_start', key: 'event_start', render: (e) => {
                return moment(e).format("MMM Do YYYY - h:mma");
            }
        },
        {
            title: <small>To</small>, dataIndex: 'event_end', key: 'event_end', render: (e) => {
                return moment(e).format("MMM Do YYYY - h:mma");
            }
        },
        {
            title: <small>Visibility</small>, dataIndex: 'visibility', key: 'visibility', render: (e, f) => {
                return drawVisibility(e, f.event_id, f.visibility);
            }
        },
        { title: <small>Views</small>, dataIndex: 'views', key: 'views' },
        {
            title: <small>Updated</small>, dataIndex: 'updated', key: 'updated', render: (e) => {
                return moment(e).format("MMM Do YYYY - h:mma");
            }
        },
        {
            title: <small></small>, className: "cellBorder", dataIndex: 'space', width: "10px", key: 'space'
        },
        {
            title: <small></small>, width: "0px", dataIndex: 'id', key: 'id', render: (e, f) => {
                return (<Popconfirm onConfirm={() => deleteEvent(f.event_id)} title="Delete event?" ><Button danger size="small" type="link"><DeleteOutlined /></Button></Popconfirm>)
            }
        },
    ]

    const drawVisibility = (_bool, _event_id, _visibility) => {

        if (_bool) {
            return (<EventVisibilityToggle updateRow={updateRow} visibility={_visibility} event_id={_event_id}><div style={{ "color": "#4dac13" }}>Visible (Active)</div></EventVisibilityToggle>);
        } else {
            return (<EventVisibilityToggle updateRow={updateRow} visibility={_visibility} event_id={_event_id}><div style={{ "color": "#f12a46" }}>Hidden (Inactive)</div></EventVisibilityToggle>);
        }
    }




    return (

        <Layout className="layout">
            <LayoutHeader
                title="Events"
                description="Use this page to add, remove and manage events."
                button={<Space>
                        <UpgradeEvents show_upgrade />
                    <Button size="small" type="primary">

                    
                    <Overlay maskClosable={false} width={1000} component={<Event create={true} updateRow={getEvents} />}>
                        <small>Create new event</small>
                    </Overlay>

                </Button></Space>}
            />
            <Layout.Content style={{ "padding": "0px" }} >
                <div style={{ "padding": "20px" }}>
                    <Card bodyStyle={{ "padding": "0px", "borderBottom": "none" }}>
                        <Paginate setPaginate={(e) => setFilters({ current: e.current, start: e.offset, limit: e.pagesize })} count={results.total_rows} paginate={{ current: filters.current, pagesize: filters.limit, offset: filters.start }} />
                        <Table
                            size="small"
                            pagination={false}
                            loading={loading}
                            rowKey="event_id"
                            className={"tableNoBorder"}
                            columns={columns}
                            dataSource={results.events}
                        />
                        <Paginate setPaginate={(e) => setFilters({ current: e.current, start: e.offset, limit: e.pagesize })} count={results.total_rows} paginate={{ current: filters.current, pagesize: filters.limit, offset: filters.start }} />
                    </Card>
                </div>
            </Layout.Content>
        </Layout>
    )

}