import React, { useState, useEffect } from "react";
import { Pagination, Typography, Table, Button } from "antd";
import { apiCall } from "../../utils/Api";
import moment from "moment";
import conditional from "../../tools/conditional";
import { CloseOutlined } from '@ant-design/icons';
import Title from "../title/Title";
import { bmDate } from "../../utils/Utils";

export default function InvoiceActivity(props) {

    const { invoice, invoice_is_supplier, close = () => {} } = props;
    const [results, setResults] = useState({ header_info: { is_supplier: false }, total: 0, rows: [] });
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState([]);
    const [drawType, setDrawType] = useState("")
    const [filters, setFilters] = useState({
        invoice: invoice,
        invoice_is_supplier: invoice_is_supplier,
        limit: 100,
        offset: 0,
    });

    const getCustomerActivity = () => {
        apiCall("activity/getByInvoice", filters, (_status, _result) => {
            if (_status) {
                _result.rows = addKeys(_result.rows);
                setResults(_result);
                setLoading(false);
            }
        })
    }

    useEffect(getCustomerActivity, [filters]);

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const columns = [

        { title: <small>Status</small>, dataIndex: "status", key: "status" },
        { title: <small>Price</small>, dataIndex: "price", key: "price", render: (e) => { return (e) ? "$" + e.toString() : "" } },
        { title: <small>Discount</small>, dataIndex: "discount", key: "discount", render: (e) => { return (e) ? e.toString() + "%" : "" } },
        { title: <small>Profit</small>, dataIndex: "profit_margin", key: "profit_margin", render: (e) => { return (e) ? e.toString() + "%" : "" } },
        {
            title: <small>Title</small>, className : "nowrap", dataIndex: "title", key: "title"
        },
        { title: <small>ISBN</small>, dataIndex: "isbn", key: "isbn" },
        { title: <small>Cust PO</small>, dataIndex: "customer_purchase_order", key: "customer_purchase_order" },
    ]


    if (results.header_info.is_supplier) {
        // Supplier
        columns.unshift({ title: <small>Qty</small>, dataIndex: "quantity", key: "quantity" },)
        columns.push({ title: <small>Age</small>, dataIndex: "age", key: "age" })
        columns.push({ title: <small>Cancel</small>, dataIndex: "cancel", key: "cancel" })
        columns.push({ title: <small>Cashier</small>, dataIndex: "cashier", key: "cashier" })
        columns.push({ title: <small>Note</small>, dataIndex: "note", key: "note" })
    } else {
        // !Supplier
        columns.push({ title: <small>Class</small>, dataIndex: "class", key: "class" })
        columns.push({ title: <small>On hand</small>, dataIndex: "onhand", key: "onhand" })
    }


    const drawHeader = (_info) => {

        if (!_info) {
            _info = {}
        }

        const {
            customer_name = "",
            is_supplier = false,
            supplier_code = "",
            invoice = "",
            date = null
        } = _info;

        return (
            <div style={{"borderBottom" : "1px solid #eee"}}>
                <div style={{ "borderBottom": "1px solid #eee", "textAlign": "left", "fontSize": "12px", "padding": "0px 8px", "height": "22px", "lineHeight": "20px" }}>
                    <strong style={{ "color": "#1b1b1b" }}><small>Invoice</small></strong>
                    <div style={{ "float": "right" }}><Button onClick={() => close([])} className="tiny-btn" size="small" type="text"><small style={{ "fontSize": "10px" }}><CloseOutlined /></small></Button></div>
                </div>

                <div style={{ "padding": "8px", "paddingBottom": "0px", "marginTop": "-5px" }}>
                    <Typography.Paragraph style={{ "fontSize": "12px" }}>
                        <conditional.true value={(invoice)}>
                            <span style={{ "color": "#069", "fontSize": "12px", "display": "block" }}><strong>Invoice: {invoice}</strong></span>
                        </conditional.true>
                        <div className="shim" />
                        <conditional.true value={(supplier_code)}>
                            <span style={{ "fontSize": "11px", "display": "block" }}><strong>Supplier: </strong>{supplier_code}</span>
                        </conditional.true>
                        <conditional.true value={(date)}>
                            <span style={{ "fontSize": "11px", "display": "block" }}><strong>Date: </strong>{bmDate(date * 1000)}</span>
                        </conditional.true>
                        <conditional.true value={(customer_name)}>
                            <span style={{ "fontSize": "11px", "display": "block" }}><strong>Customer: </strong>{customer_name}</span>
                        </conditional.true>
                    </Typography.Paragraph>
                </div>
            </div>
        )
    }

    const drawChildTable = (_record) => {

        if (drawType === "title") {
            return <Title isbn={_record.isbn} />;
        }

        if (drawType === "purchase_order") {
            return (<></>);
        }

        return (<></>);
    }

    const expandCell = (_data, _type) => {
        setDrawType(_type);
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            if (_type === drawType) {
                setExpanded([]);
            } else {
                setExpanded([_data.key]);
            }
        }
    }

    if (loading) {
        return (<div style={{ "margin": "-8px" }}></div>);
    }

    return (
        <div style={{ "margin": "0px", backgroundColor: "#fff" }}>
            <Table
                title={() =>
                    <>
                        {drawHeader(results.header_info)}
                    </>
                }
                size="small"
                columns={columns}
                className={"tableNoBorder"}
                dataSource={results.rows}
                pagination={false}
                rowClassName={(record, index) => {
                    return "sales-"+record.status;
                }}
                expandedRowKeys={expanded}
                expandIconColumnIndex={-1}
                onExpand={(e) => { return false; }}
                expandIcon={() => { return (<></>) }}
                expandedRowRender={((record) => drawChildTable(record))}
                footer={
                    () => {
                        <>&nbsp;</>
                        // <conditional.true value={(results.total > 10)}>
                        //     <div style={{ "margin": "-8px", "backgroundColor": "#fff", "padding": "8px 8px", "textAlign": "right" }}>
                        //         <Pagination size="small" current={((filters.offset / filters.limit) + 1)} onChange={(e, f) => setFilters({ ...filters, "offset": (e - 1) * f, "limit": f })} total={results.total} showSizeChanger />
                        //     </div>
                        // </conditional.true>
                    }

                }
            />
        </div>
    )
}