import React, { useEffect, useState } from "react";
import { apiCall } from "../utils/Api";
import moment from "moment";
import { Button, Popconfirm, Table, Typography, Card, Divider, Space, message } from "antd";
export default function PromoManageCustomers(props) {

    const { close = () => { }, data = {} } = props;

    const [results, setResults] = useState([]);


    const fetchCustomers = () => {
        apiCall("promos/getCustomersPerPromo", { code: data.code }, (_status, _result) => {
            if(_status){
                setResults(_result);
            } else {
                message.error(_result.error);
            }
            
        })
    }

    useEffect(fetchCustomers, []);

    //     (10:33:31 AM) Tobias: https://tbraunapi.bookmanager.com/store/promos/getCustomersPerPromo?session_id=1Mft0NR7Fz0UNZc1tMjuEHAyS7xF&store_id=101069&code=1pc50
    // (10:33:36 AM) Tobias: https://tbraunapi.bookmanager.com/store/promos/deleteCustomersPerPromo?session_id=1Mft0NR7Fz0UNZc1tMjuEHAyS7xF&store_id=101069&code=1pc50


    const resetAllCustomers = () => {
        apiCall("promos/deleteCustomersPerPromo", { code: data.code }, (_status, _result) => {
            if(_status){
                message.success("Customers reset successfully.");
                close(); 
            } else {    
                message.error(_result.error)
            }
        })
    }


    const columns = [
        { title: <small>Customer</small>, dataIndex: 'customer_name', key: 'customer_name' },
        { title: <small>Email</small>, dataIndex: 'email', key: 'email' },
        { title: <small>Bookorder ID</small>, dataIndex: 'bookorder_id', key: 'bookorder_id' },
        {
            title: <small>Date</small>, dataIndex: 'date_ordered', key: 'date_ordered', render: (e) => {
                return (e) ? moment(e * 1000).format("MMM Do, YYYY") : "in cart.";
            }
        }
    ]



    return (
        <div style={{ "padding": "25px" }}>
            <Typography.Title level={5}>Manage customers</Typography.Title>
            <Divider />
            
                <Table bordered className="bc" style={{"height" : "185px"}} scroll={{y : 300}} size="small" columns={columns} dataSource={results} />
            
            <br />
            <div style={{"float" : "right"}}>
                <Space>
                    <Button onClick={() => close()}>Cancel</Button>
                    <Button onClick={() => resetAllCustomers()} type="primary">Unset All</Button>
                </Space>
            </div>
            <br clear="all" />
            {/* <pre>{JSON.stringify(results, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </div>
    )
}