import React, { useState } from "react";
import { Form, Col, Typography, Divider, Input, Button, Radio, Card } from "antd";
import conditional from "../../utils/conditional";
export default function Region(props) {

    const { data, form } = props;


    const [removed, setRemoved] = useState(false);


    if (removed) {
        return (<></>);
    }
    return (
        <Col key={data.region_code} md={24} xl={12}>
            <Card >
                <Form.Item noStyle name={['global:shipping_regions', [data.region_code]]} initialValue={data}></Form.Item>
                <Typography.Text>
                    <div style={{ "float": "left" }}><strong>{data.name} &nbsp;</strong></div>
                    <conditional.true value={(data.region_code !== "--")}>
                        <div style={{ "float": "right" }}><Button onClick={() => setRemoved(true)} danger size="small"><small>Remove</small></Button></div>
                    </conditional.true>
                    <br clear="all" />
                    <Divider dashed />
                </Typography.Text>
                <Form.Item initialValue={data.charge_type} name={['global:shipping_regions', [data.region_code], "charge_type"]}>
                    <Radio.Group>
                        <Radio key="0" value="0">Store to calculate shipping costs manually</Radio>
                        <div className="shim"></div>
                        <Radio key="1" value="1">Base rate <br /><div className="shim"></div>
                            {/* <conditional.true value={(values.charge_type === "1")}> */}
                                <Typography.Text type="secondary">Minimum shipping cost <Form.Item key={"base_rate"} noStyle initialValue={data.base_rate} name={['global:shipping_regions', [data.region_code], "base_rate"]}><Input size="small" prefix="$" style={{ "maxWidth": "100px" }}></Input></Form.Item></Typography.Text><br /><div className="shim"></div>
                                <Typography.Text type="secondary">Each additional item  <Form.Item key={"base_rate_add"}  noStyle initialValue={data.base_rate_add} name={['global:shipping_regions', [data.region_code], "base_rate_add"]}><Input size="small" prefix="$" style={{ "maxWidth": "100px" }}></Input></Form.Item></Typography.Text><br /><div className="shim"></div>
                                <Typography.Text type="secondary">Maximum shipping cost  <Form.Item key={"base_rate_max"} noStyle initialValue={data.base_rate_max} name={['global:shipping_regions', [data.region_code], "base_rate_max"]}><Input size="small" prefix="$" style={{ "maxWidth": "100px" }}></Input></Form.Item></Typography.Text><br /><div className="shim"></div>
                                <Typography.Text type="secondary">Free shipping after item total amount exceeds  <Form.Item key={"base_rate_threshold"} noStyle initialValue={data.base_rate_threshold} name={['global:shipping_regions', [data.region_code], "base_rate_threshold"]}><Input size="small" prefix="$" style={{ "maxWidth": "100px" }}></Input></Form.Item></Typography.Text>
                            {/* </conditional.true> */}
                        </Radio>
                        <div className="shim"></div>
                        <Radio key="2" value="2">Free shipping on all orders</Radio>
                        <div className="shim"></div>
                        <Radio key="3" value="3">Disallowed <br /></Radio>
                        <div className="shim"></div>
                    </Radio.Group>
                </Form.Item>
            </Card>
        </Col>
    )
}