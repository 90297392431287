import React from "react";
import { Typography, Divider, Row, Col, Space, Form, Input, Checkbox } from "antd";
export default function AdvancedSecion(props) {


    const { ws } = props;

    

    return (
        <>
            <div id="advanced" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Advanced</Typography.Title>
                <Divider />

                {/* Custom Header text */}
                <div id="ws_head_custom_text"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5} >Custom head and body additions</Typography.Title>
                        <Typography.Paragraph type="secondary">These fields allow you to add JavaScript, CSS, etc. before the closing HTML "head" and "body" tags on your Webstore.<br /> Note: These settings apply to both mobile and desktop versions of the site.</Typography.Paragraph>
                        <Typography.Text><strong>Head:</strong></Typography.Text>
                        <div className="shim"></div>
                        <Form.Item name="global:ws_head_custom_text" initialValue={ws["global:ws_head_custom_text"]} noStyle >
                            <Input.TextArea maxLength={1000} showCount style={{ "maxWidth": "500px" }} rows={3} ></Input.TextArea>
                        </Form.Item>
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                        <Typography.Text><strong>Body:</strong></Typography.Text>
                        <div className="shim"></div>
                        <Form.Item name="global:ws_body_custom_text" initialValue={ws["global:ws_body_custom_text"]} noStyle >
                            <Input.TextArea maxLength={1000} showCount style={{ "maxWidth": "500px" }} rows={3} ></Input.TextArea>
                        </Form.Item>
                    </div>
                </Space>


            </div>
        </>
    )
}