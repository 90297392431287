import { Dropdown, Input, Menu } from "antd";
import React, { useState } from "react";
import { getMemory, saveMemory } from "../utils/Utils";
import { CaretDownOutlined } from '@ant-design/icons';
export default function RememberInput(props) {


    const {record = {}, updateOrder = () => {}, saveOrder = () => {}} = props; 

    const [note, setNote] = useState(record.order_note);

    const drawRemembered = () => {
        let notes = getMemory("notes");
        if (!notes.length === 0) {
            return <></>;
        }

        return (
            <Dropdown placement="bottomRight" overlay={() => {
                return (
                    <Menu onClick={(e) => {
                        setNote(e.key);
                        updateOrder(record.key, "order_note", e.key)
                        saveOrder();
                    }}>
                        {notes.map((item, index) => {
                            return (<Menu.Item value={item} key={item}>{item}</Menu.Item>)
                        })}
                    </Menu>
                )
            }}>
                <a>
                    Last notes <CaretDownOutlined />
                </a>
            </Dropdown>

        )
    }


    return (<>



        <div>
            <div style={{ "textAlign": "left", "fontSize": "10px", "color": "#888", "marginLeft": "-6px" }}>
                <div className="float-flex">
                    <span>Order Note</span>
                    <span>{drawRemembered()}</span>
                </div>
            </div>
            <div style={{ "marginLeft": "-6px" }}>

               

                <Input.TextArea id={record.key + "notes"} onFocus={(e) => e.target.select()} onBlur={(e) => {
                    if (e.target.value) {
                        saveMemory("notes", e.target.value);
                        
                    }
                    saveOrder()
                }} value={note} onChange={(e) => {
                    setNote(e.target.value);
                    updateOrder(record.key, "order_note", e.target.value);
                    saveOrder();
                    }} placeholder="Order note..." style={{ "fontSize": "12px" }} rows={3}></Input.TextArea>


            </div>
        </div>


    </>)
}