import React, { useEffect, useState } from "react";
import { Layout, Typography, Divider, Button, Card, Tree, Row, Col, Table, Menu, Dropdown, Form, Modal, Upload, Input, message, Space, TreeSelect, Spin } from "antd";
import { SmileOutlined, DownOutlined, UploadOutlined } from '@ant-design/icons';
import { apiCall } from "../utils/Api";
import { bmDate, bytesToSize, ucfirst } from "../utils/Utils";
import { useHistory } from "react-router-dom";


export default function FileBrowser(props) {

    const { selectImage = () => { console.log("called") } } = props;
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [currentDirectory, setCurrentDirectory] = useState("/");
    const [currentTitle, setCurrentTitle] = useState("Root");
    const [filename, setFilename] = useState("");
    const [selectedFile, setSelectedFile] = useState({});
    const [loading, setLoading] = useState(false)

    const onRemove = (_file) => {
        setFileList([]);
    }

    // disables auto sending file. 
    const beforeUpload = (_file) => {
        setFileList([_file]);
        return false;
    }

    const handleUpload = (_f) => {
        let _name = fileList[0].name;
        let _ext = _name.split(".");
        _ext = _ext[_ext.length - 1];
        if (filename) { _name = filename + "." + _ext; }
        let _location = (currentDirectory === "/") ? currentDirectory + _name : currentDirectory + "/" + _name;
        setUploading(true);
        apiCall("files/upload", { file: fileList[0], location: _location }, (_status, _result) => {
            if (_status) {
                message.success("File uploaded successfully.")
            } else {
                message.error("Error: " + _result.error);
            }
            setFileList([]);
            setUploading(false);
            setUploadVisible(false);
            fetchList();
        })
    }


    const removeFolder = () => {
        let _location = currentDirectory;
        apiCall("files/rmdir", { location: _location }, (_status, _result) => {
            if (_status) {
                message.success("Folder removed successfully.")
            } else {
                message.error(_result.error);
            }
            setFolderVisible(false);
            fetchList();
        });
    }

    const createFolder = (_f) => {
        let _location = currentDirectory + "/" + _f.folder;
        apiCall("files/mkdir", { location: _location }, (_status, _result) => {
            if (_status) {
                message.success("Folder created successfully.")
            } else {
                message.error(_result.error);
            }
            setFolderVisible(false);
            fetchList();
        });
    }

    const moveFile = (_f) => {
        let _from = selectedFile.path;
        let _to = _f.folder + "/" + selectedFile.name;
        apiCall("files/mv", { from_location: _from, to_location: _to }, (_status, _result) => {
            if (_status) {
                message.success("File moved successfully.")
            } else {
                message.error(_result.error);
            }
            closeMove();
            fetchList();
        })
    }


    const [moveVisible, setMoveVisible] = useState(false);

    const drawMoveBox = () => {

        return (
            <Modal destroyOnClose width={400} onCancel={() => closeMove()} visible={moveVisible} centered footer={null}>
                <Typography.Title level={5}>Move file</Typography.Title>
                <div style={{ "marginTop": "-5px", "color": "#666" }}><small>Move <strong>{currentTitle}</strong> to another directory</small></div>
                <Divider style={{ "margin": "10px 0px" }} />
                <Form onFinish={(e) => moveFile(e)} layout="vertical">
                    <div className="shim" />
                    <Form.Item rules={[{ required: true, message: "Folder required." }]} label={<small>Folder name</small>} name="folder">
                        <TreeSelect treeDefaultExpandAll placeholder="Select directory..." treeData={parseTree(results.files.tree)} />
                    </Form.Item>
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => closeMove()}>Cancel</Button>
                            <Button htmlType="submit" type="primary">Move</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </Modal>
        )

    }


    const download = (_path) => {
        var el = document.createElement("a");
        el.setAttribute('download', "");
        el.target = "_blank"
        el.href = _path;
        document.body.appendChild(el);
        el.click();
    }




    const [folderVisible, setFolderVisible] = useState(false);
    const drawFolderBox = () => {

        return (
            <Modal destroyOnClose width={400} onCancel={() => setFolderVisible(false)} visible={folderVisible} centered footer={null}>
                <Typography.Title level={5}>New Folder</Typography.Title>
                <div style={{ "marginTop": "-5px", "color": "#666" }}><small>Create a new folder in the <strong>{currentTitle}</strong> directory</small></div>
                <Divider style={{ "margin": "10px 0px" }} />
                <Form onFinish={(e) => createFolder(e)} layout="vertical">
                    <div className="shim" />
                    <Form.Item rules={[{ required: true, message: "Folder name required.", pattern: new RegExp(/^[a-z0-9 ]+$/i) }]} label={<small>Folder name</small>} name="folder">
                        <Input placeholder="Folder name..." />
                    </Form.Item>
                    <div style={{ "float": "right" }}>
                        <Space>
                            {/* <Button danger onClick={() => removeFolder()}>Delete</Button> */}
                            <Button onClick={() => setFolderVisible(false)}>Cancel</Button>
                            <Button htmlType="submit" type="primary">Create</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </Modal>
        )
    }

    const [uploadVisible, setUploadVisible] = useState(false);


    const closeUploadBox = () => {
        setFileList([]);
        setFilename("");
        setUploadVisible(false)
    }

    const drawUploadBox = () => {

        return (
            <Modal destroyOnClose width={400} onCancel={() => closeUploadBox(false)} visible={uploadVisible} centered footer={null}>
                <Typography.Title level={5}>Upload file</Typography.Title>
                <div style={{ "marginTop": "-5px", "color": "#666" }}><small>Upload file to the <strong>{currentTitle}</strong> directory</small></div>
                <Divider style={{ "margin": "10px 0px" }} />
                <Form layout="vertical">
                    <div className="shim" />
                    <Form.Item help={<small className="c" style={{ "opacity": "0.5" }} >Renaming file is optional.</small>} label={<small>Rename File?</small>}>
                        <Input value={filename} onChange={(e) => setFilename(e.target.value.replace(/[^\w\s]/gi, ''))} placeholder="New name..." />
                    </Form.Item>
                    <br />
                    <div style={{ "float": "right" }}>
                        <Space align="start">
                            <Upload multiple={false} onRemove={(_file) => onRemove(_file)} beforeUpload={(_file) => beforeUpload(_file)}>
                                <Button type="primary" disabled={(fileList.length > 0)} icon={<UploadOutlined />}>Select file</Button>
                            </Upload>
                            <Button
                                type="primary"
                                onClick={handleUpload}
                                disabled={fileList.length === 0}
                                loading={uploading}
                            >
                                {uploading ? 'Uploading' : 'Start upload'}
                            </Button>
                        </Space>
                    </div>
                    <Button onClick={() => closeUploadBox()}>Cancel</Button>
                    <br clear="both" />
                </Form>
            </Modal>
        )
    }

    const [results, setResults] = useState({ files: { path: "", tree: [] } });
    const [selected, setSelected] = useState([]);

    const columns = [
        { width: 10 },
        {
            title: <small>File</small>, dataIndex: 'name', key: 'name', sorter: { compare: (a, b) => a.name.localeCompare(b.name) }, render: (e) => {

                return <div style={{ "maxWidth": "200px", "overflow": "hidden", "textOverflow": "ellipsis" }}>{e}</div>
            }
        },
        {
            title: <small></small>, dataIndex: 'path', key: 'path', render: (e, f) => {
                if (f.hasOwnProperty("image")) {
                    return <a alt={f.name} href={window.sitesettings.file_url + "/" + e} target="_blank"><img style={{ "maxWidth": "50px", "maxHeight": "50px" }} src={window.sitesettings.file_url + "/" + e} /></a>
                } else {
                    return (<></>)
                }
            }
        },
        {
            title: <small>File size</small>, dataIndex: 'size', key: 'size', sorter: (a, b) => a.size - b.size, render: (e) => {
                return (e) ? bytesToSize(e) : "";
            }
        },
        {
            title: <small>Modified date</small>, dataIndex: 'last_modified', key: 'last_modified', sorter: (a, b) => a.last_modified - b.last_modified, render: (e) => {
                return (e) ? bmDate(e * 1000) : ""
            }
        },
        {
            title: <small>Dimensions</small>, dataIndex: 'image', key: 'image', render: (e) => {
                if (e) {
                    return (e.width + "px x" + e.height + "px")
                }
            }
        },

        {
            title: <small><Button block onClick={() => setUploadVisible(true)} type="primary" size="small"><small>Upload</small></Button></small>, width: 68, dataIndex: 'actions', key: 'actions', render: (e, f) => {
                return <Button onClick={() => selectImage(f)} type="primary" block><small>Select</small></Button>
            }
        }
    ]


    const openMove = (_f) => {
        setSelectedFile(_f);
        setMoveVisible(true)
    }

    const closeMove = () => {
        setSelectedFile({});
        setMoveVisible(false);
    }

    const deleteFile = (_f) => {

        apiCall("files/rm", { location: _f.path }, (_status, _result) => {
            if (_status) {
                message.success("File deleted successfully.");
            } else {
                message.error(_result.error);
            }
            setSelectedFile({});
            fetchList();
        })
        console.log();
    }



    const openDelete = (_f) => {

        setSelectedFile(_f);
        Modal.confirm({ icon: <></>, onOk: () => deleteFile(_f), okText: "Delete", okButtonProps: { danger: true }, title: "Delete?", content: <>Are you sure you wish to delete <strong>{_f.name}</strong>?</> });
    }



    const drawMenu = (e, f) => {
        return (
            <Menu>
                {(f.hasOwnProperty("image") && <Menu.Item onClick={() => window.open(window.sitesettings.file_url + "/" + f.path, "_blank")}><small>View</small></Menu.Item>)}
                <Menu.Item onClick={() => download(window.sitesettings.file_url + f.path)}><small>Download</small></Menu.Item>
                <Menu.Item onClick={() => openMove(f)}><small>Move to...</small></Menu.Item>
                <Menu.Item onClick={() => openDelete(f)} danger ><small>Delete</small></Menu.Item>
            </Menu>
        )
    }


    const drawActions = (e, f) => {

        return (
            <>
                <Dropdown overlay={drawMenu(e, f)}>
                    <Button block size="small">
                        <small>Actions...</small>
                    </Button>
                </Dropdown>
            </>
        )
    }

    const fetchList = () => {
        setLoading(true);
        apiCall("files/ls", { location: "/" }, (_status, _result) => {
            setLoading(false)
            if (_status) {
                setResults(_result);
            }
        
        })
    }

    useEffect(fetchList, []);


    const parseChildren = (_arr) => {
        return _arr.map(item => {
            return {
                title: item.name,
                key: item.path,
                items: (item.hasOwnProperty("children")) ? item.children.filter(f => !f.is_directory) : [],
                children: (item.hasOwnProperty("children")) ? parseChildren(item.children.filter(f => f.is_directory)) : []
            }
        })
    }

    const parseTree = (_arr) => {
        let _ret = {
            title: "Root",
            key: "/",
            items: _arr.filter(f => !f.is_directory),
            children: parseChildren(_arr.filter(f => f.is_directory))
        }
        return [_ret];
    }

    const parseSelection = (_children = [], _item) => {
        setCurrentDirectory(_item.key);
        setCurrentTitle(_item.title);
        setSelected(_item.items);
    }


    const findInArray = (_arr, _key, _val) => {

        let found = false;
        _arr.forEach(item => {
            if (item.hasOwnProperty("children")) {

                let _search = findInArray(item.children, _key, _val);
                if (_search) {
                    found = _search;
                }
            }
            if (item[_key] === _val) {
                found = item;
            }
        })
        return found;

    }

    const parseSelected = () => {

        let ret = findInArray(results.files.tree, "path", currentDirectory)


        if (ret) {
            return ret.children.filter(f => !f.is_directory);
        } else {

            if (currentDirectory === "/") {
                return results.files.tree.filter(f => !f.is_directory);
            }

            return [];
        }


    }

    return (
        <>
            {drawUploadBox()}
            {drawFolderBox()}
            {drawMoveBox()}
            <Spin spinning={loading}>
                <Card bodyStyle={{ "padding": "0px" }}>
                    <Row>
                        <Col flex={"250px"}>
                            <div className="folders">
                                <div style={{ "float": "right" }}><Button onClick={() => setFolderVisible(true)} type="primary" size="small"><small>New Folder</small></Button></div>
                                <small>Folders</small>
                            </div>
                            <div style={{ "padding": "0px" }}>
                                <div className="shim" />
                                <Tree.DirectoryTree
                                    defaultExpandAll
                                    defaultSelectedKeys={["/"]}
                                    className="tree"
                                    onSelect={(e, f) => parseSelection(f.node.children, f.selectedNodes[0])}
                                    switcherIcon={<></>}
                                    treeData={parseTree(results.files.tree)}
                                />
                            </div>
                        </Col>
                        <Col className="bcg" flex={"1px"}>
                        </Col>
                        <Col flex={"auto"}>
                            <div style={{ "borderBottom": "0px" }}>
                                <Table
                                    rowKey={"path"}
                                    rowClassName={"hideHover"}
                                    size="small"
                                    columns={columns}
                                    dataSource={parseSelected()}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Spin>

        </>
    )
}