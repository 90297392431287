import React, { useEffect, useState } from "react";
import { Layout, Typography, Divider, Button, Space, DatePicker, Menu, Dropdown, Statistic, Input, Form, Select, Row, Col, Radio } from "antd";
import moment from "moment";
import { apiCall, apiDownload } from "../../utils/Api";
import { RollbackOutlined, FileExcelOutlined } from '@ant-design/icons';
import conditional from "../../utils/conditional";
import { FilterFilled, FilterOutlined, RedoOutlined } from '@ant-design/icons';
import CustomersTable from "../../components/previous_orders/CustomersTable";
import CustomerPOTable from "../../components/previous_orders/CustomerPOTable";
import OrdersTable from "../../components/previous_orders/OrdersTable";
import TitlesTable from "../../components/previous_orders/TitlesTable";
import { addCommas, objectToPath, objToPath, pathToObj } from "../../utils/Utils";
import { useHistory, useParams } from "react-router-dom";
import ActivityReport from "./ActivityReport";
import Overlay from "../../components/Overlay";

export default function PreviousOrdersPage() {


    const { search = "" } = useParams();
    const [isFiltering, setIsFiltering] = useState(false);
    const history = useHistory();
    const [summary, setSummary] = useState({
        quantity: "0",
        value: "0",
        titles: "0",
        customer_purchase_orders: "0",
        customers: "0",
    });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const map = {
        start_date: "s",
        end_date: "e",
        show_filter: "f",
        customer_search: "t",
        keyword_search: "k",
        sort: "v",
        condition_code: "c",
        auto_open: "o",
    }


    let _init = pathToObj(history.location.pathname, map);

    const initFilters = {
        start_date: (_init.start_date) ? moment(parseInt(_init.start_date * 1000)).unix() : moment().add(-1, 'week').startOf('day').unix(),
        end_date: (_init.end_date) ? moment(parseInt(_init.end_date * 1000)).unix() : moment().endOf('day').unix(),
        show_filter: (_init.show_filter) ? _init.show_filter : "customer",
        customer_search: (_init.customer_search) ? _init.customer_search : search,
        keyword_search: (_init.keyword_search) ? _init.keyword_search : "",
        sort: (_init.sort) ? _init.sort : "orders",
        condition_code: (_init.condition_code) ? _init.condition_code : "-1",
        auto_open: (_init.auto_open) ? _init.auto_open : false,
    }

    const [filters, setFilters] = useState(initFilters);
    const [start, setStart] = useState(initFilters);

    const downloadExcel = (_include_title_details = false) => {
        apiDownload({ ...filters, include_title_details: _include_title_details }, "orders/getExcel", true);
    }

    const downloadSingle = (_order_id) => {
        apiDownload({ order_id: _order_id }, "order/getExcel");
    }

    const ExcelOptions = (props) => {

        const [excelOption, setExcelOption] = useState(false);
        const processExcel = () => {
            props.close();
            downloadExcel(excelOption);
        }


        return (
            <div style={{ "padding": "20px" }}>
                <strong>Excel options</strong>
                <Divider style={{ "margin": "15px 0px" }} dashed />
                <Radio.Group onChange={(e) => setExcelOption(e.target.value)} value={excelOption} >
                    <Space direction="vertical">
                        <Radio value={false}>&nbsp;Summary <br /><em style={{ "opacity": "0.7" }}><small>&nbsp;One line summary per online order</small></em></Radio>
                        <Radio value={true}>&nbsp;Detailed <br /><em style={{ "opacity": "0.7" }}><small>&nbsp;Includes title level details</small></em></Radio>
                    </Space>
                </Radio.Group>
                <Divider style={{ "margin": "15px 0px" }} dashed />
                <div className="float-flex">
                    <div>&nbsp;</div>
                    <Button onClick={() => processExcel()} type="primary"><FileExcelOutlined /> Download</Button>
                </div>
            </div>
        )
    }

    const getSummary = () => {
        setLoading(true);
        apiCall("orders/getSummary", filters, (_status, _result) => {
            if (_status) {
                setSummary(_result);
                setLoading(false);
                history.push("/reports/previous-orders/" + objToPath(filters, map));
            }
        });
    }

    useEffect(getSummary, [filters]);



    const drawSummary = () => {

        return (
            <div className="bcg3 bc" style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "10px 20px", "borderBottom": "1px solid", "borderTop": "1px solid", "width": "100%" }}>
                <Row>
                    <Col span={2}><Statistic valueStyle={{ "fontSize": "16px", "fontWeight": "600" }} loading={loading} title={<small>Orders</small>} value={summary.orders} /></Col>
                    <Col span={3}><Statistic valueStyle={{ "fontSize": "16px", "fontWeight": "600" }} loading={loading} title={<small>Value</small>} value={(summary.value) ? "$" + addCommas(summary.value.toString()) : ""} /></Col>
                    <Col span={2}><Statistic valueStyle={{ "fontSize": "16px", "fontWeight": "600" }} loading={loading} title={<small>Quantity</small>} value={summary.quantity} /></Col>
                    <Col span={2}><Statistic valueStyle={{ "fontSize": "16px", "fontWeight": "600" }} loading={loading} title={<small>Titles</small>} value={summary.titles} /></Col>
                    <Col span={3}><Statistic valueStyle={{ "fontSize": "16px", "fontWeight": "600" }} loading={loading} title={<small>Purchase Orders</small>} value={summary.customer_purchase_orders} /></Col>
                    <Col span={2}><Statistic valueStyle={{ "fontSize": "16px", "fontWeight": "600" }} loading={loading} title={<small>Customers</small>} value={summary.customers} /></Col>
                    <Col span={1}>&nbsp;</Col>
                    <Col span={9}>
                        <ActivityReport start_date={filters.start_date} end_date={filters.end_date} />
                    </Col>
                </Row>
                <div className="shim" />
            </div>

        )
    }


    const drawFilters = () => {


        return (
            <div style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "20px 20px 0px 20px" }}>
                <Form size="small" layout="vertical">
                    <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                        <div>Previous Orders</div>
                    </Typography.Title>

                    <Divider dashed style={{ "marginTop": "10px", "marginBottom": "15px" }} />
                    <Space>
                        <Form.Item label={<small>Date Range</small>}>
                            <DatePicker.RangePicker allowClear={false} style={{ "fontSize": "10px", "width": "230px" }} value={[moment(filters.start_date * 1000).startOf("day"), (filters.end_date) ? moment(filters.end_date * 1000).endOf("day") : ""]} onChange={(e) => setFilters({ ...filters, "start_date": e[0].startOf("day").unix(), "end_date": e[1].endOf("day").unix() })} format="MMM D, YYYY" />
                        </Form.Item>

                        <Form.Item label={<small>Users</small>}>
                            <Select value={filters.show_filter} onChange={(e) => setFilters({ ...filters, "show_filter": e })} style={{ width: 100 }}>
                                <Select.Option value="all"><small>All</small></Select.Option>
                                <Select.Option value="customer"><small>Customers</small></Select.Option>
                                <Select.Option value="store" ><small>Store</small></Select.Option>
                                <Select.Option value="guest"><small>Guest</small></Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item initialValue={filters.keyword_search} label={<small>Product Search</small>} name="keyword_search">
                            <Input.Search allowClear enterButton onSearch={(e) => setFilters({ ...filters, "keyword_search": e })} placeholder="Keyword search" style={{ "width": "220px" }} />
                        </Form.Item>

                        <Form.Item label={<small>Condition</small>}>
                            <Select value={filters.condition_code} onChange={(e) => setFilters({ ...filters, "condition_code": e })} style={{ width: 80 }}>
                                <Select.Option key={"-1"} value={"-1"}><small>None</small></Select.Option>
                                {window.sitesettings.condition_codes.map(item => {
                                    return (<Select.Option key={item.code} value={item.code}><small>{item.name}</small></Select.Option>)
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item initialValue={filters.customer_search} label={<small>Order Search</small>} name="customer_search">
                            <Input.Search allowClear enterButton onSearch={(e) => setFilters({ ...filters, "customer_search": e })} placeholder="Customer, ref #, email, promo" style={{ "width": "250px" }} />
                        </Form.Item>

                        <Form.Item label={<small>Grouping</small>}>
                            <Select value={filters.sort} onChange={(e) => setFilters({ ...filters, "sort": e })} style={{ width: 120 }}>
                                <Select.Option value="orders"><small>Orders</small></Select.Option>
                                <Select.Option value="titles"><small>Titles</small></Select.Option>
                                <Select.Option value="customer_pos" ><small>Purchase Orders</small></Select.Option>
                                <Select.Option value="customers"><small>Customers</small></Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label={<small>&nbsp;</small>}>
                            <Button disabled={(JSON.stringify(start) === JSON.stringify(filters))} onClick={() => setFilters(start)} type="secondary"><small><RollbackOutlined />&nbsp;Reset</small></Button>
                        </Form.Item>

                        <Form.Item label={<small>&nbsp;</small>}>
                        <Overlay width={250} component={<ExcelOptions />}><Button size="small" type="primary"><small><FileExcelOutlined />&nbsp;Export XLS</small></Button></Overlay>
                        </Form.Item>




                    </Space>
                </Form>
            </div>
        )


    }


    return (
        <>

            <Layout className="layout">
                <Layout.Content className="layoutHeader" style={{ "padding": "0px" }} >

                    {drawFilters()}


                    {drawSummary()}



                    <conditional.true value={(filters.sort === "orders")}>
                        <OrdersTable pagination setFilters={setFilters} filters={filters} />
                    </conditional.true>

                    <conditional.true value={(filters.sort === "titles")}>
                        <TitlesTable filters={filters} data={data} />
                    </conditional.true>

                    <conditional.true value={(filters.sort === "customer_pos")}>
                        <CustomerPOTable filters={filters} />
                    </conditional.true>

                    <conditional.true value={(filters.sort === "customers")}>
                        <CustomersTable filters={filters} />
                    </conditional.true>


                </Layout.Content>


            </Layout>





        </>
    )



    return (
        <>

            <Layout style={{ "backgroundColor": "#fff" }}>
                <Layout.Content style={{ "borderLeft": "1px solid #eee", "padding": "0px" }} >
                    <div style={{ "padding": "30px" }}>

                        <Typography.Title level={4}>

                            <span style={{ "fontSize": "10px", "color": "#ccc", "letterSpacing": "2px" }}>PURCHASING</span><br />
                            <div style={{ "marginTop": "-5px" }}> Previous Orders <Button onClick={() => setIsFiltering(!isFiltering)} type="link">{(isFiltering) ? <FilterFilled /> : <FilterOutlined />}</Button> </div>

                        </Typography.Title>

                        <conditional.true value={(!isFiltering)}>
                            <Divider />
                        </conditional.true>
                        <conditional.true value={(isFiltering)}>

                        </conditional.true>

                        <Typography.Title level={5}>Summary <em><small style={{ "color": "#AAA", "fontWeight": "normal" }}> - {moment(filters.start_date * 1000).format("MMMM Do YYYY")} - {moment(filters.end_date * 1000).format("MMMM Do YYYY")}</small></em></Typography.Title>
                        <br />

                        <div className="shim"></div>

                        <div className="shim"></div><div className="shim"></div>
                        <Divider dashed />




                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}