import { Popover, Space } from "antd";
import React, { useEffect, useState } from "react";
import { CaretDownOutlined } from '@ant-design/icons';
import { getMemory, saveMemory } from "../../utils/Utils";




export default function TitleLinks(props) {

    const { isbn = "", authors = [] } = props;
    const [open, setOpen] = useState(false);

    let _index = 0;
    let _memories = getMemory("titlelink");
    if (_memories.length > 0) {

        if (isNaN(_memories[0])) {
            _index = 0
        } else {
            _index = _memories[0];
        }

    }


    const [index, setIndex] = useState(_index);


    const toIsbn10 = (isbn13) => {
        const isbn = isbn13.substring(3);

        let sum = 0;
        for (let i = 0; i < 9; i++) {
            const mul = 10 - i;
            sum += parseInt(isbn.charAt(i)) * mul;
        }

        let check = 11 - sum % 11;
        if (check == 10) check = "X";
        return `${isbn.substring(0, 9)}${check}`;
    }







    const sites = [
        {
            label: "Amazon",
            ico: "icon_amazon.png",
            get: (isbn) => {
                if (isbn.substr(0, 3).toString() === "979") {
                    return `https://www.amazon.com/s?k=${isbn}`;
                } else {
                    const isbn10 = toIsbn10(isbn);
                    return `https://amazon.com/exec/obidos/ASIN/${isbn10}`;
                }
            }
        },
        {
            label: "Amazon Canada",
            ico: "icon_amazoncdn.png",
            get: (isbn) => {
                return `https://www.amazon.ca/s?k=${isbn}`;
                // const isbn10 = toIsbn10(isbn);
                // return `https://amazon.ca/exec/obidos/ASIN/${isbn10}`;
            }

        },


        {
            label: "BNC Catalist",
            ico: "icon_bnccatalist2.png",
            get: (isbn) => `https://bnccatalist.ca/SearchResults.aspx?search==${isbn}`
        },
        {
            label: "BookFinder",
            ico: "icon_bookfinder.png",
            get: (isbn) => `https://bookfinder.com/search/?isbn=${isbn}&mode=basic&st=sr&ac=qr`
        },
        {
            label: "Christianbook",
            ico: "icon_christianbook.png",
            get: (isbn) => `https://christianbook.com/Christian/Books/easy_find?Ntt=${isbn}&Ntk=keywords&action=Search`
        },
        {
            label: "Edelweiss+",
            ico: "icon_edelweiss_plus.png",
            get: (isbn) => `https://www.edelweiss.plus/#sku=${isbn}`
        },
        {
            label: "Goodreads",
            ico: "icon_goodreads.png",
            get: (isbn) => `https://goodreads.com/search?query=${isbn}`
        },
        {
            label: "Google",
            ico: "icon_google-2015.png",
            get: (isbn) => `https://google.com/search?q=${isbn}`
        },
        {
            label: "Literature Map",
            ico: "icon_literaturemap.png",
            special: true,
            get: (first, last) => `https://literature-map.com/${first}+${last}`
        },


        {
            label: "BNC SalesData",
            ico: "icon_bncsalesdata.png",
            get: (isbn) => `https://www.salesdata.ca/TitleISBNResults.aspx?rng=lifetime&isbn=${isbn}`
        },

        {
            label: "BookDepot",
            ico: "icon_bookdepot.png",
            get: (isbn) => `https://bookdepot.ca/Store/Search?qf=All&q=${isbn}`
        },

        {
            label: "49th shelf",
            ico: "icon_49thshelf.png",
            get: (isbn) => `http://49thshelf.com/content/search?search_in=93&SearchButton=&SearchText=${isbn}`
        },

        {
            label: "iPage",
            ico: "icon_ipage.png",
            get: (isbn) => `https://ipage.ingramcontent.com/ipage/administration/home/home.action`
        },

        {
            label: "B&T TS360",
            ico: "icon_baker_taylor.png",
            get: (isbn) => `https://ts360.baker-taylor.com/_layouts/CommerceServer/QuickSearch.aspx?keyword=${isbn}`
        },

        {
            label: "Indigo",
            ico: "icon_chapters.png",
            get: (isbn) => `https://www.chapters.indigo.ca/home/search/?keywords=${isbn}`
        },

        {
            label: "Barnes & Noble",
            ico: "icon_bn.png",
            get: (isbn) => `http://barnesandnoble.com/s/${isbn}`
        },

        {
            label: "books.google.com",
            ico: "icon_google-2015.ico",
            get: (isbn) => `http://www.google.com/search?tbs=bks%3A1&tbo=1&btnG=Search+Books&q=${isbn}`
        },

        {
            label: "AbeBooks",
            ico: "icon_abebooks.png",
            get: (isbn) => `http://www.abebooks.com/servlet/SearchResults?sts=t&x=81&y=12&isbn=${isbn}`
        },

        {
            label: "Powell's",
            ico: "icon_powells.png",
            get: (isbn) => `http://www.powells.com/searchresults?keyword=${isbn}`
        },


        {
            label: "Alibris",
            ico: "icon_alibris.png",
            get: (isbn) => `http://www.alibris.com/booksearch?binding=&mtype=B&hs.x=0&hs.y=0&hs=Submit&keyword=${isbn}`
        },

        {
            label: "ebay.com",
            ico: "icon_ebay.png",
            get: (isbn) => `http://shop.ebay.com/${isbn}`
        },


        {
            label: "NetGalley",
            ico: "icon_netgalley.png",
            get: (isbn) => `https://www.netgalley.com/catalog/?text=${isbn}`
        },





    ]








    //     { 'icon': '/i/icon_netgalley.png', 'text': 'NetGalley', 'url': 'https://www.netgalley.com/catalog/?text=%s', 'format_type': 2, 'formatted_isbn': isbn13 }


    const linkOut = (_url, _index) => {

        saveMemory("titlelink", _index);
        setIndex(_index);
        setOpen(false);
        window.open(_url, "_blank");
    }

    const drawLink = (_itm, _index, _label = true) => {


        if (!_itm) {
            return "";
        }
        let icon = `https://cdn1.bookmanager.com/i/${_itm.ico}`;
        let url = "";
        if (_itm.hasOwnProperty("special")) {

            if (!authors.length > 0) {
                return "";
            }

            let a = authors[0].name;
            if (a.name) {
                let name = a.name.toLowerCase().split(" ");
                url = _itm.get(name[0], name[1]);
            }
        } else {
            url = _itm.get(isbn);
        }


        if (_label) {

            return (

                <a className="c" onClick={() => linkOut(url, _index)} key={_index} rel="noopener">
                    <Space>
                        <div style={{ "background": "url(" + icon + ") no-repeat #fff center", "height": "18px", "width": "18px", "padding": "2px", "borderRadius": "2px", "backgroundColor": "#fff" }}>
                            {/* <img width="16px" height="16px" alt={_itm.label + " Icon"} src={icon}></img> */}
                        </div>
                        <span style={{ "cursor": "pointer" }}><small>{(_label) ? _itm.label : ""}</small></span>
                    </Space>
                </a>


            )

        } else {

            return (

                <a style={{ "color": "#1b1b1b" }} onClick={() => linkOut(url, _index)} key={_index} rel="noopener">
                    <div className="shim" />
                    <div style={{ "background": "url(" + icon + ") no-repeat #fff center", "marginTop": "2px", "height": "17px", "width": "17px", "padding": "2px", "borderRadius": "2px", "backgroundColor": "#fff" }}>

                    </div>

                </a>


            )
        }


    }

    const drawLinks = () => {


        return (
            <>
                <div style={{ "width": "270px" }}>
                    <Space wrap>
                        {sites.map((item, _ind) => {
                            return (<div key={_ind} style={{ "width": "125px" }}>{drawLink(item, _ind)}</div>)
                        })}
                    </Space>
                </div>
            </>
        )

    }



    const draw = () => {

        return (
            <>
                <Space size={0}>
                    <div style={{ "marginTop": "1px" }}>{drawLink(sites[index], index, false)}</div>
                    <Popover visible={open} onVisibleChange={(e) => setOpen(e)} placement="bottomLeft" trigger={"click"} content={drawLinks()}><sup style={{ "cursor": "pointer" }}><CaretDownOutlined /></sup></Popover>
                </Space>

            </>
        )

    }


    return draw();


}