import React, { useState } from "react";
import { Layout, Typography, Divider, Table, Button, Tag, Badge, Popconfirm, DatePicker, Menu, Dropdown, Tabs, Col, Statistic, Card, message } from "antd";
import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";
import { CloseSquareFilled } from '@ant-design/icons';

export default function OrderStatus(props) {

    const { bookorder_id, show_x = false } = props;
    const [ignoreStatus, setIgnoreStatus] = useState(props.ignore_status);

    let status = ""
    let question = ""
    let color = ""

    switch (ignoreStatus) {

        case "none":
            status = "success";
            color = "#52c41a";
            question = "Request ignore?";
            break;

        case "store":
            status = "warning";
            color = "#faad14";
            question = "Cancel ignore request?";
            break;

        case "tbm":
            status = "default";
            color = "#888";
            break;

    }
    const changeStatus = () => {
        apiCall("orders/ignoreOrder", { bookorder_id: bookorder_id, ignore: (ignoreStatus === "none") ? true : false }, (_status, _results) => {
            if (_status) {
                message.success("Request sent.")
                if (ignoreStatus === "none") {
                    setIgnoreStatus("store");
                } else {
                    setIgnoreStatus("none");
                }
            }
        })
    }


    if (ignoreStatus === "tbm") {
        return (<Badge style={{ "paddingLeft": "7px" }} status={status} />);
    }

    return (
        <>
            <Popconfirm placement="right" title={question} onConfirm={() => changeStatus()} okText="Send" cancelText="Close">
                <div style={{ "display": "block", "cursor": "pointer", "textAlign" : "center" }}>
                    <conditional.true value={(show_x)}>
                        <CloseSquareFilled style={{"color" : color, "fontSize" : "20px"}} />
                    </conditional.true>
                    <conditional.true value={(!show_x)}>
                        <Badge style={{ "paddingLeft": "7px" }} status={status} />
                    </conditional.true>

                </div>
            </Popconfirm>
        </>
    );

}