import React, { useEffect, useState } from "react";
import { Descriptions, Checkbox, Badge, Row, Col, Table, Input, Select, Button, Tag, Dropdown, Menu } from "antd";
import Order2 from "./Order2";
import BMSummary from "./title/BMSummary";
import ItemSales from "./ItemSales";
import conditional from "../utils/conditional";
import ItemSales2 from "./ItemSales2";
import SalesActivity from "./SalesActivity";
import { apiCall } from "../utils/Api";


export default function ItemHistory4(props) {

    const { isbn = "", divider = true } = props;
    const [results, setResults] = useState((props.data) ? props.data : false)
    const [loading, setLoading] = useState(false);

    const getItem = () => {

        if (!results) {
            setLoading(true);
            apiCall("title/getItem", { isbn: isbn }, (_status, _result) => {
                if (_status) {
                    setResults(_result);
                    setLoading(false)
                }
            })
        }
    }

    useEffect(getItem, []);

 
    const getStoreDefault = () => {

        let _ret = []

        window.sitesettings.stores.map(store => {
            _ret.push({class : "sold", store_number: store.store_number.toString(), months : Array(14), week : 0, total: 0})
        })

        _ret.push({class : "total_sold", store_number:"0", months : Array(14), week : 0, total: 0})
        return _ret; 
    }

    const addCondition = (_code) => {
        let _orig = {...results}
        _orig.sales.push({
            bm_data : {},
            code : _code.code,
            code_text : _code.name,
            sales : {rows : getStoreDefault()}
        })
        setResults(_orig);
    }



    const drawConditionSelector = () => {


      
        let _current_codes = (results.hasOwnProperty("sales")) ? results.sales.map(item => item.code_text) : []

        return (
            <Dropdown overlay={<Menu size="small">
                {window.sitesettings.condition_codes.filter(item => !_current_codes.includes(item.name)).map(code => {
                    return <Menu.Item onClick={(e) => addCondition(code)} key={code.name}><small>{code.name}</small></Menu.Item>
                })}
            </Menu>}>
                <div style={{ "float": "right" }}><small><a>+ Add stock condition</a></small></div>
            </Dropdown>
        )
    }


    const drawRow = (_row, _index, _suggested, _series) => {



        return (
            <div key={_index}>

                <BMSummary series={_series} isbn={isbn} priority={true} condition_code={_row.code} condition={_row.code_text} suggested_bm_data={_suggested} data={_row.bm_data} />
                <ItemSales2 condition={_row.code} isbn={isbn} data={_row.sales} />
                <BMSummary series={_series} isbn={isbn} priority={false} suggested_bm_data={_suggested} condition={_row.code_text} data={_row.bm_data} />
                <div className="shim" /><div className="shim" />

                {((results.sales.length > (_index + 1)) && <>
                    <div className="shim" />
                </>)}
            </div>
        )
    }

    const drawRows = () => {

        let ret = (results.hasOwnProperty("sales")) ? results.sales : []

        return ret.map((item, index) => {
            return drawRow(item, index, results.suggested_bm_data, results.series);
        })
    }

    if (!results || loading) {
        return (<></>)
    }
    return (
        <>
            {drawConditionSelector()}
            {drawRows()}
        </>
    )





}