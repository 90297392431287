import { Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { apiCall } from "../utils/Api";
import { pluralize } from "../utils/Utils";

export default function LogOutLocations(props) {

    const { trigger = false } = props;
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchLocations = () => {
        apiCall("account/countOfEveryDevice", {}, (_status, _result) => {
            if (_status) {
                setCount(_result.count)
            }
        })
    }
    useEffect(fetchLocations, [trigger]);

    const logOutLocations = () => {

        Modal.confirm({
            title: "Are you sure?",
            content : "Please confirm you wish to log out all other sessions.",
            okText : "Confirm",
            onOk: () => {

                setLoading(true);
                apiCall("account/logoutEveryDevice", {}, (_status, _result) => {
                    if (_status) {
                        message.success("Successfully signed out at " + (count - 1) + " " + pluralize(count - 1, "session", "s"));
                        setCount(0);
                    }
                    setLoading(false);
                })

            }
        })

    }

    if (count <= 1) {
        return (<></>)
    }

    return (<><Button onClick={() => logOutLocations()} loading={loading} block type="dashed" ><><em>Sign out at <strong>{count - 1}</strong> other {pluralize(count - 1, "session", "s")}</em></></Button><div className="shim" /></>)
}