/*

Default Layout - Wraps sections with nav, footer etc

*/

import React, { useState } from "react";
import { Layout, Divider, Menu, Space, Button } from 'antd';
import logosmall from "../media/logo.png";
import AccountCard from "../components/AccountCard";
import { useHistory } from "react-router-dom";
import { navigation } from "../data";


import {
    SettingOutlined,
    EditOutlined,
    BarChartOutlined,
    DollarOutlined,
    ToolOutlined,
    CustomerServiceOutlined,
    MenuOutlined
} from '@ant-design/icons';


export default function Left(props) {

    const history = useHistory();

    const { collapsed = false, setCollapsed = () =>{} } = props;
    const [opened, setOpened] = useState("");
    const [, setSelected] = useState("");


    const getIcon = (_slug) => {
        switch (_slug) {
            case "settings":
                return (<SettingOutlined style={{ "color": "#999" }} />);
            case "content":
                return (<EditOutlined style={{ "color": "#999" }} />);
            case "reports":
                return (<BarChartOutlined style={{ "color": "#999" }} />);
            case "purchasing":
                return (<DollarOutlined style={{ "color": "#999" }} />);
            case "tools":
                return (<ToolOutlined style={{ "color": "#999" }} />);
            case "support":
                return (<CustomerServiceOutlined style={{ "color": "#999" }} />);
        }
    }

    const linkToPage = (_slug) => {

        setSelected(_slug);
        history.push("/" + _slug);
    }


    const openItem = (_slug) => {

        setCollapsed(false);
        if (opened !== _slug) {
            setOpened(_slug);
        } else {
            setOpened(false)
        }

    }

    const drawMenu = () => {
        return navigation.map((item) => {
            return (
                <Menu.SubMenu onTitleClick={() => openItem(item.slug)} key={item.slug} icon={getIcon(item.slug)} title={item.name}>
                    {item.subnav.map((_item) => {
                        return (<Menu.Item onClick={() => linkToPage(_item.slug)} key={_item.slug}>{_item.name}</Menu.Item>)
                    })}
                </Menu.SubMenu>
            )
        })
    }


    const navEnter = () => {
        setCollapsed(false);
    }

    const navOut = () => {
        setCollapsed(true);
    }




    // const setSize = () =>{
    //     if (window.innerWidth > 1700) {
    //         if (collapsed) {
    //             console.log(collapsed)

    //             setCollapsed(false);
    //         }
    //     } else {
    //         if (!collapsed) {
    //             console.log("close")
    //             setCollapsed(true);
    //         }
    //     }
    // }

    // const checkSize = () => {

    //     window.addEventListener('resize', setSize);

    // }
    // useEffect(checkSize, [])



    return (
        <>
            {/* <div className="flourish" style={{ "display": "block", "height": "5px" }}></div> */}

            <div className="show-block" style={{ "cursor": "pointer", "textAlign": "left", "backgroundColor": "#001529" }}>
                <Space>

                    <div>

                        <img alt="bookmanager" style={{ "margin": "16px 16px" }} width={171} src={logosmall} />

                    </div>


                    <Button style={{ "color": "#fff" }} icon={<MenuOutlined />} type="text"></Button>



                </Space>
                <div style={{ "float": "right", "paddingTop": "13px" }}>
                    <AccountCard />
                </div>
                <Divider style={{ "margin": "0px", "padding": "0px" }} />
            </div>

            <Layout hasSider style={{ "height": "calc(100vh)", "overflow": "hidden" }}>




                <Layout.Sider trigger={<AccountCard collapsed={collapsed} />} onMouseEnter={() => navEnter()} onMouseLeave={() => navOut()} collapsedWidth={60} width={235} onCollapse={(e) => setCollapsed(e)} collapsible collapsed={collapsed} defaultCollapsed className="hide" style={{ "backgroundColor": "#001529" }}>
                    <div style={{ "height": "100vh", "overflow": "auto" }}>


                        <div className="shim"></div>
                        <div style={{ "cursor": "pointer", "textAlign": "left", "padding": "3px 0px" }}>

                            <img onClick={() => history.push("/")} alt="bookmanager" style={{ "transition": "margin 300ms", "margin": (collapsed) ? "20px 14px" : "20px 21px" }} width={171} src={logosmall} />


                        </div>
                        <Divider style={{ "margin": "0px", "padding": "0px" }} />
                        <div className="shim"></div>

                        <Menu
                            triggerSubMenuAction={["click"]}
                            mode="inline"
                            theme="dark"
                            style={{ "backgroundColor": "#0d152b", "border": "none" }}
                            openKeys={[opened]}




                        >
                            {drawMenu()}

                        </Menu>

                    </div>
                </Layout.Sider>




                {/* <Anchor.Link href="#general" title="General" /><div className="shim"></div>
                                <Anchor.Link href="#accounts" title="Accounts" /><div className="shim"></div>
                                <Anchor.Link href="#ordering" title="Ordering" /><div className="shim"></div>
                                <Anchor.Link href="#shipping" title="Shipping" /><div className="shim"></div>
                                <Anchor.Link href="#searching" title="Searching" /><div className="shim"></div>
                                <Anchor.Link href="#pricing" title="Pricing" /><div className="shim"></div>
                                <Anchor.Link href="#payments" title="Payments" /><div className="shim"></div>
                                <Anchor.Link href="#hours" title="Hours" /><div className="shim"></div>
                                <Anchor.Link href="#multistore" title="Multi-Store" /><div className="shim"></div>
                                <Anchor.Link href="#seo" title="SEO" /><div className="shim"></div>
                                <Anchor.Link href="#advanced" title="Advanced" /><div className="shim"></div> */}




                <Layout.Content style={{ "backgroundColor": "#fff", "borderleft": "1px solid #eee", "height": "100vh", "overflow": "auto" }}>
                    <div style={{ "padding": "0px", "backgroundColor": "#fff" }}>

                        {props.children}

                    </div>
                </Layout.Content>
                {/* <Divider style={{ "margin": "0px", "padding": "0px" }} />
                    <Layout.Footer style={{ "backgroundColor": "#fff", "padding": "13px 50px" }}>Footer</Layout.Footer> */}

            </Layout>
        </>
    );

}