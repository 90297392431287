
import React, { useEffect, useState } from "react";
import { Table, Space, Button, Divider, Radio, message } from "antd";
import Paginate from "../../components/Paginate";
import { apiCall, apiDownload } from "../../utils/Api";
import { addCommas, fromUriHash, isNumeric } from "../../utils/Utils";
import Renderer from "../../components/Renderer";
import ItemLayouts from "../../components/ItemLayouts";
import { CloseOutlined, CloseCircleOutlined, PicLeftOutlined, MenuOutlined, AppstoreOutlined } from '@ant-design/icons';
import moment from "moment-timezone";
import Overlay from "../../components/Overlay";
import ListCreateExcel from "../../components/lists/ListCreateExcel";
import LazyLoad from "react-lazyload";
import conditional from "../../utils/conditional";

export default function AnalyticsTable(props) {

    const { grouptokens = [], parseSearchFilters = () => { }, grouping = "", searchFilters = {}, filters = {}, drilldown = "sales_total", setView = () => { }, item = {} } = props;
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 100, offset: 0 });
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [htmlUrl, setHtmlUrl] = useState("");

    const [data, setData] = useState({ rows: [], row_count: 0, temp_list_id: "" });
    const [expand, setExpand] = useState(false);

    const [display, setDisplay] = useState("list");

    
    // Some fields are synthetic - need to switch to actual calls

    const openInBrowse = () => {
        window.open("/purchasing/browse/filter/x/" + data.temp_list_id + "/v/sequence", "blank");
    }


    const drawGroupToken = (_token = grouptokens) => {


        if (grouptokens.length < 1) {
            return false;
        }

        let _g = grouptokens.find(item => item.group === grouping);
        if (_g) {
            return { [grouping]: { inc: [_g.grouping[0]], exc: [] } }
        }

        if (!grouptokens[0].group) {
            return false;
        }

        if (grouptokens[0].grouping.length < 1) {
            return false;
        }




    }






    const getFromSynthetic = (_column) => {
        let column = "";
        let start_date = item.slice_datelow;
        let end_date = item.slice_datehigh;

        switch (_column) {
            case "sandr_total":
                column = "sales_total";
                break;
            case "sales_prev_year_up":
                column = "sales_total";
                start_date = moment(start_date * 1000).add(-52, "weeks").unix();
                end_date = moment(end_date * 1000).add(-52, "weeks").unix();
                break;

            case "received_prev_year_up":
                column = "onhand_total";
                break;

            case "received_total":
                column = "rcvd";
                break;

            case "received_rtrn":
                column = "rtrn";
                break;

            default:
                column = _column;
                break;
        }
        return {
            column: column,
            start_date: start_date,
            end_date: end_date
        };
    }


    const getTitles = (_title) => {

        let synthetic = getFromSynthetic(drilldown);

        let obj = { ...filters }
        obj.drilldown_column = (_title) ? _title : synthetic.column;
        obj.start_date = moment(synthetic.start_date * 1000).format("YYYY-MM-DD");
        obj.end_date = moment(synthetic.end_date * 1000).format("YYYY-MM-DD");
        obj.group_by = parseSearchFilters(searchFilters);
        // NO VALUE FOR TABLE
        obj.grouping = JSON.stringify([]) // separate rows for field or amalgamate is empty array
        obj.limit = paginate.pagesize;
        obj.offset = paginate.offset;

        if (obj.hasOwnProperty("browse_filters")) {

            obj.browse_filters = fromUriHash(obj.browse_filters);
        }

        // Request full titles on rows
        //obj.full_titles = true;

       

        setLoading(true);

        let token = drawGroupToken();


        if (token) {

            obj.group_by = { ...obj.group_by, ...token }
        }

        setHtmlUrl(objectToURL(obj));

        obj.full_titles = true;

        // encode
        obj.group_by = JSON.stringify(obj.group_by);

        apiCall("reports/analytics/getTitles", obj, (_status, _result) => {
            if (_status) {
                setData(_result);
            } else {
                message.error(_result.error);
            }
            setLoading(false);
        })

    }

    useEffect(getTitles, [drilldown, paginate, display])

    const drilldowns = [

        'sales_total',
        'sales_new',
        'sales_mid',
        'sales_old',
        'sales_dead',
        'salesrtrn_total',
        'salesrtrn_new',
        'salesrtrn_mid',
        'salesrtrn_old',
        'salesrtrn_dead',
        'onhand_total',
        'onhand_new',
        'onhand_mid',
        'onhand_old',
        'onhand_dead',
        'onorder_total',
        'rcvd',
        'rtrn',

    ]


    const columns = [
        { title: <small></small>, key: "blank", dataIndex: "blank", width: "10px" },
        { title: <small>Sold</small>, key: "sold", dataIndex: "sold", width: "50px" },
        { title: <small>aOH</small>, key: "cur_oh", dataIndex: "cur_oh", width: "50px" },
        { title: <small>aOO</small>, key: "cur_oo", dataIndex: "cur_oo", width: "50px" },
        {
            title: <small>Price</small>, key: "price", dataIndex: "price", width: "50px", render: (e) => {
                return (e) ? e.toFixed(2) : ""
            }
        },
        {
            title: <small>Title</small>, key: "title", dataIndex: "title", render: (e, f) => {
                return <a onClick={() => expandCell(f)}>{e}</a>
            }
        },
        { title: <small>Author</small>, key: "author", dataIndex: "author" },
        { title: <small>Cat</small>, key: "cat", dataIndex: "cat", width: "100px" },
        { title: <small>Cat2</small>, key: "cat2", dataIndex: "cat2", width: "100px" },
        { title: <small>Supl</small>, key: "scode", dataIndex: "scode", width: "80px" },
        { title: <small>TtlRcv</small>, key: "ttlrcvd", dataIndex: "ttlrcvd", width: "60px" },
        { title: <small>Rtn</small>, key: "rtrn", dataIndex: "rtrn", width: "50px" },
        { title: <small>ISBN</small>, key: "isbn", dataIndex: "isbn", width: "100px" }

    ]


    const drawChildTable = (_record) => {


        return (
            <div className="hideHover bc" style={{ "margin": "0px 0px", "marginLeft": "-9px", "paddingLeft": "0px", "borderLeft": "1px solid", "marginTop": "0px", "overflowY": "hidden" }}>
                <div style={{ "padding": "0 20px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none", "marginBottom": "0px" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                    <div className="shim" /><div className="shim" />
                    <div style={{ "margin": "0px -12px", "padding" : "12px" }}>
                        <Renderer>
                            <ItemLayouts data={_record.title_obj} display={"details2"} />
                        </Renderer>
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </div>
        )
    }

    const expandCell = (_data) => {
        if (!expanded.includes(_data.isbn)) {
            setExpanded([_data.isbn]);
        } else {
            setExpanded([]);
        }
    }

    const objectToURL = (_obj) => {
        return Object.keys(_obj).map(key => {

            if (typeof _obj[key] === "object") {
                return key + '=' + JSON.stringify(_obj[key])
            } else {
                return key + '=' + _obj[key]
            }



        }).join('&');
    }







    const getTitle = (_key) => {

        let ret = ""
        switch (_key) {
            case "sales_total": ret = "ISBNs sold in total"; break;
            case "sandr_total": ret = "ISBNs sold in total"; break; // synthetic
            case "sales_prev_year_up": ret = "ISBNs sold in total (" + moment(item.slice_datehigh * 1000).add(-52, "weeks").format("YYYY") + ")"; break; // synthetic
            case "sales_new": ret = "ISBNs sold that are under 31 days old"; break;
            case "sales_mid": ret = "ISBNs sold that are 31-274 days old"; break;
            case "sales_old": ret = "ISBNs sold that are 275-365 days old"; break;
            case "sales_dead": ret = "ISBNs sold that are 365 days old"; break;
            case "salesrtrn_total": ret = "ISBNs returned by customers in total"; break;
            case "salesrtrn_new": ret = "ISBNs under 31 days old returned by customer"; break;
            case "salesrtrn_mid": ret = "ISBNs 31-274 days old returned by customers"; break;
            case "salesrtrn_old": ret = "ISBNs 275-365 days old returned by customers"; break;
            case "salesrtrn_dead": ret = "ISBNs over 365 days old returned by customers"; break;
            case "onhand_total": ret = "ISBNs onhand in total"; break;
            case "received_prev_year_up": ret = "ISBNs onhand in total (" + moment(item.slice_datehigh * 1000).add(-52, "weeks").format("YYYY") + ")"; break; // synthetic
            case "onhand_new": ret = "ISBNs onhand under 31 days old"; break;
            case "onhand_mid": ret = "ISBNs onhand 31-274 days old"; break;
            case "onhand_old": ret = "ISBNs onhand 275-365 days old"; break;
            case "onhand_dead": ret = "ISBNs onhand over 365 days old"; break;
            case "onorder_total": ret = "ISBNs on order in total"; break;
            case "rcvd": ret = "ISBNs received in total"; break;
            case "received_total": ret = "ISBNs received in total"; break; // synthetic
            case "rtrn": ret = "ISBNs total returned to suppliers"; break;
            case "received_rtrn": ret = "ISBNs total returned to suppliers"; break; // synthetic        

            default: ret = ""; break;
        }

        return ret;

    }


    const downloadExcel = (_list_id) => {
        apiDownload({ list_id: _list_id }, "reports/analytics/getDrilldownExcel");
    }

    const drawComponents = () => {


        return (


            <div style={{ "display": "inline-block", "marginLeft": "-10px" }}>


                <Space>

                    <Button shape="circle" onClick={() => setView("")} size="small" type="text" icon={<CloseCircleOutlined style={{ "fontSize": "12px", "opacity": "0.7" }} />} />
                    <div style={{ "fontWeight": "600" }}>{addCommas(data.row_count)} {getTitle(drilldown)}</div>
                    <div>|</div>
                    {drawDisplaySelector()}
                    <div>|</div>
                    <Button onClick={() => openInBrowse()} className="mini-btn" size="small"><small>Browse</small></Button>
                    <Button onClick={() => downloadExcel(data.temp_list_id)} className="mini-btn" size="small"><small>XLS</small></Button>
                    <Button onClick={() => window.open("/table/" + "?isbns=false&" + htmlUrl, "_blank")} className="mini-btn" size="small"><small>HTML</small></Button>
                    <Button onClick={() => window.open("/table/" + "?isbns=true&" + htmlUrl, "_blank")} className="mini-btn" size="small"><small>ISBNs</small></Button>
                    <div> &nbsp; </div>
                </Space>
            </div>
        )
    }


    const drawDisplaySelector = () => {

        return (
            <Space>
                <div><small>Display:</small></div>
                <Radio.Group buttonStyle="solid" value={display} onChange={(e) => setDisplay(e.target.value)} size="small">
                    <Radio.Button value={"details"}><small><PicLeftOutlined /></small></Radio.Button>
                    <Radio.Button value={"list"}><small><MenuOutlined /></small></Radio.Button>
                    <Radio.Button value={"cards"}><small><AppstoreOutlined /></small></Radio.Button>
                </Radio.Group>
            </Space>
        )

    }


    return (
        <>

           
            {/* <Space>
                <Select placeholder="Select..." onChange={(e) => getTitles(e)} style={{ "width": "150px" }}>
                    {drilldowns.map(item => {
                        return <Select.Option key={item} value={item}>{item}</Select.Option>
                    })}
                </Select>
            </Space>
            <br /><br /> */}
            <div className="shim" /><div className="shim" />
            <div className="bc" style={{ "border": "1px solid", "margin": "0px -21px", "marginBottom": "-26px" }}>
                <Paginate showing_right={true} precomponent={drawComponents()} showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={data.row_count} />


                {/* CARDS */}
                <conditional.true key={"cards"} value={(display === "cards")}>


                    {/* <pre>
                        {JSON.stringify(data.rows.map(item => item.title_obj), null, 2)}
                    </pre> */}



                    <div style={{ "padding": "20px" }}>
                        {data.rows.map(item => item.title_obj).reduce((all, one, i) => {
                            const ch = Math.floor(i / 5);
                            all[ch] = [].concat((all[ch] || []), one);
                            return all
                        }, []).map((item_set, index) => {


                            return (


                                // <pre>{JSON.stringify(item_set.map((i, index) => (i) ? i.isbn : index), null, 2)}</pre>
                                <div key={item_set[0].isbn + "cards"}>
                                    <Renderer>
                                        <ItemLayouts data={item_set} index={index} display={"cards"} />
                                    </Renderer>
                                    <Divider dashed />
                                </div>
                            )
                        })}
                    </div>
                </conditional.true>

                {/* DETAILS */}
                <conditional.true key={"notcards"} value={(display === "details")}>
                    <div style={{ "padding": "20px" }}>
                        {data.rows.map(item => item.title_obj).map((item, index) => {
                            return <div key={item.eisbn + "notcards"}>
                                <LazyLoad offset={500} once={true} height={360}>
                                    <div key={item.eisbn + "ll"}>
                                        <Renderer close={false}>
                                            <ItemLayouts data={item} display={"details2"} />
                                        </Renderer>
                                        <div className="borderBottom shim" style={{ "margin": "35px -20px", "borderBottomWidth": "6px" }} />
                                    </div>
                                </LazyLoad>
                            </div>
                        })}
                    </div>
                </conditional.true>

                {/* LIST */}
                <conditional.true key={"notcards"} value={(display === "list")}>
                    <Table
                        size="small"
                        className="analyiticsTable"
                        rowKey="isbn"
                        pagination={false}
                        columns={columns}
                        dataSource={data.rows}
                        loading={loading}
                        // scroll={{ y: 500 }}
                        expandable
                        expandedRowKeys={expanded}
                        expandIconColumnIndex={-1}
                        onExpand={(e) => { return false; }}
                        expandIcon={() => { return (<></>) }}
                        expandedRowRender={((record) => drawChildTable(record))}
                    />
                </conditional.true>
                <Paginate showSizeChanger={false} paginate={paginate} setPaginate={setPaginate} count={data.row_count} />
            </div>




        </>
    )
}