import { Tabs } from "antd";
import React, { useState } from "react";

import Pubstock from "./Pubstock";
import TestOrderBox from "./TestOrderBox";

export default function Extras(props) {

    const { data = {}, isbn = "", cart_update = false } = props;

    const [active, setActive] = useState("order");

    const toggleActive = (e) => {
        if (active === e) {
            setActive("order")
        } else {
            setActive(e);
        }
    }

    return (
        <>
            <Tabs className="extraTabs itemTabs" onChange={(e) => toggleActive(e)} tabBarStyle={{ "padding": "0px", "marginBottom": "0px", "borderBottom": "0px" }} size="small" type="card" activeKey={active}>
                <Tabs.TabPane tab={<small>Order</small>} key="order">
                    <div className="bc" style={{ "borderLeft": "5px solid", "marginTop": "-1px" }}>
                        <TestOrderBox cart_update={cart_update} data={(data) ? data : {}} isbn={(data) ? data.isbn : isbn} />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<small>Pubstock</small>} key="pubstock">
                    <div style={{ "marginLeft": "20px", "marginTop": "-16px" }}>
                        <Pubstock title_suppliers={data.suppliers} want_store={false} show_stores={false} isbn={isbn} />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<small>Stores</small>} key="stores">
                    <div style={{ "marginLeft": "20px", "marginTop": "-16px" }}><Pubstock show_stores={true} isbn={isbn} /></div>
                </Tabs.TabPane>
            </Tabs>
        </>
    );
}