import React, {useState} from "react";
import { Typography, Divider, Input, Space, Button, message } from "antd";

export default function GiftRegistryShare(props) {

    const { close = () => { }, gift_registry_id = "" } = props;

    const [copyText, setCopyText] = useState("Copy");
    const url = window.sitesettings.url + "gift-registry/" + gift_registry_id;
    const copyLink = () =>{
        navigator.clipboard.writeText(url);
        setCopyText("Copied")
    }

    return (
        <>
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Share <span style={{ "display": "block", "fontWeight": "normal" }}><small>Copy this link and paste it into a web browser.</small></span></Typography.Title>
                <Divider style={{ "margin": "15px 0px" }} />
                <Input.Search onSearch={() => copyLink()} enterButton={<>{copyText}</>} defaultValue={url} />
                <Divider style={{ "margin": "15px 0px" }} />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={(e) => close()}>Close</Button>
                        <Button type="primary" onClick={(e) => window.open(url, "_blank")}>View registry</Button>
                    </Space>
                </div>
                <br clear="all" />
            </div>
        </>
    );
}