import React, { useEffect, useState } from "react";
import { Layout, Typography, Divider, Table, Button, Space, DatePicker, Menu, Dropdown, Tabs, Col, Statistic, Card } from "antd";
import { apiCall } from "../../utils/Api";
import Html from "../../utils/Html";
import { Link } from "react-router-dom";
import moment from "moment";
import { motion } from "framer-motion"
import { CloseOutlined } from '@ant-design/icons';
import Peek from "../../components/Peek";
import TitleDetails from "../title/TitleDetails";
import Renderer from "../Renderer";
import ItemLayouts from "../ItemLayouts";
import Paginate from "../Paginate";
import { addKeys, addCommas } from "../../utils/Utils";
export default function TitlesTable(props) {


    const { filters, border = true, record = {} } = props;

    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [results, setResults] = useState({ rows: [], row_count: 0 });
    const [paginate, setPaginate] = useState({ current: 1, pagesize: 20, offset: 0 });


    // Fetch Data if not set
    // Fetch Data if not set
    const getSummary = () => {
        setLoading(true);

        let _filters = { ...filters }
        _filters.limit = paginate.pagesize;
        _filters.offset = paginate.offset;

        apiCall("orders/getTitle", _filters, (_status, _results) => {
            if (_status) {
                addKeys(_results.rows);
                setResults(_results);
                setLoading(false);
            }
        })
    }
    useEffect(getSummary, [paginate.current]);


    const expandCell = (_data) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }


    const drawChildTable = (_record, _another) => {


        return (
            <div className="hideHover" style={{ "margin": "-7px" }}>
                <div style={{ "padding": "0 20px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none", "marginBottom": "0px" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                </div>
                <div style={{ "padding": "20px 20px" }}>
                    <Renderer close={false}><ItemLayouts isbn={_record.isbn} display="details2" /></Renderer>
                </div>
            </div>
        )

    }


    const title_columns = [
        { key : "blank", width : "10px"},

        {
            title: <small></small>, dataIndex: 'image', width: "60px", key: 'image', render: (e, f) => {
                return (<Link to="/test"><img className="lightshadow" onError={(e) => e.target.src = "/no-cover.png"} style={{ "maxHeight": "60px", "maxWidth": "40px" }} src={"https://bookmanager.com/i/slider_23.php?b=" + f.eisbn} /></Link>);
            }
        },
        {
            title: <small>Title</small>, dataIndex: 'title', key: 'title', render: (e, f) => {
                return <>{f.quantity} x <a onClick={() => expandCell(f)}>{e}</a></>;
            }
        },
        { title: <small>Author</small>, dataIndex: 'author', key: 'author' },
        { title: <small>Customer</small>, dataIndex: 'name', key: 'name' },
        // { title: <small>action</small>, dataIndex: 'action', key: 'action' },
        // { title: <small>Date Ordered</small>, dataIndex: 'date_ordered', key: 'date_ordered', render: (e) => {
        //     return moment(e * 1000).format("MMM Do YYYY - h:mma");
        // }},
        {
            title: <small>Status</small>, dataIndex: 'stock_status', key: 'stock_status', render: (e) => {

                if(!e){
                    return "";
                }
                return (<Html html={"<div class='shim'></div><div class='shim'></div><div class='stock'>" + e + "</div>"} />)
            }
        },
        // { title: <small>quantity</small>, dataIndex: 'quantity', key: 'quantity' },
        {
            title: <small>Price</small>, dataIndex: 'price', key: 'price', render: (e) => {
                return (e) ? "$" + addCommas(e) : "";
            },
        },
        { title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn' },
        // { title: <small>bookorder_id</small>, dataIndex: 'bookorder_id', key: 'bookorder_id' },
        // { title: <small>customer_purchase_order</small>, dataIndex: 'customer_purchase_order', key: 'customer_purchase_order' },
        // { title: <small>ignore_status</small>, dataIndex: 'ignore_status', key: 'ignore_status' }

    ]




    return (
        <>
            
           <Paginate hide_single={true} paginate={paginate} setPaginate={setPaginate} count={results.row_count} />
            <Table
                size="small"
                columns={title_columns}
                loading={loading}
                className={"tableNoBorder"}
                dataSource={results.rows}
                pagination={false}
                expandIconColumnIndex={-1}
                expandedRowKeys={expanded}
                onExpand={(e) => { return false; }}
                expandIcon={() => { return (<></>) }}
                expandedRowRender={((record) => drawChildTable(record))}
            />
            <Paginate hide_single={true} paginate={paginate} setPaginate={setPaginate} count={results.row_count} />
        </>
    );
}