import { Select } from "antd";
import React from "react";

export default function FilterPresetPicker(props) {

    const { filters=[], presets = [], multiple = false, callback = () => {}, placeholder = "Select presets..." } = props



    const drawOptions = () => {
        
        return presets.map(item => {
            return(<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
        })
    }

    return(
        <Select defaultValue={filters} mode="multiple" style={{"width" : "100%"}} onChange={(e) => callback(e)} placeholder={placeholder}>
            {drawOptions()}
        </Select>
    )


}