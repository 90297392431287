import React, { useEffect, useState } from "react";
import { Button, Divider, message, Popconfirm, Skeleton, Space, Spin, Typography } from "antd";
import { ApiOutlined, setTwoToneColor } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";

import qb_btn from "../../media/quickbooks/C2QB_green_btn_med_default.png";
import qb_btn_hover from "../../media/quickbooks/C2QB_green_btn_med_hover.png";
import { useSession } from "../../utils/Session";

export default function AppIntegrations() {
    const [session, setSession] = useSession(); 
    const [qbHover, setQbHover] = useState(false);
    const [connected, setConnected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disconnecting, setDisconnecting] = useState(false);

    const getAuthPath = () => {

        apiCall("quickbooks/getAuthPath", {}, (_status, _result) => {
            if (_status) {
                window.location.href = _result.result;
            } else {
                message.error(_result.error)
            }
        });
    }

    const getKeys = () => {
        setLoading(true);
        apiCall("quickbooks/getKeys", {}, (_status, _result) => {
            if (_status) {
                if (_result.result.hasOwnProperty("realm_id")) {
                    setConnected(true);
                }
            } 
            setLoading(false)
        })
    }

    useEffect(getKeys, []);

    const disconnect = () => {
        setDisconnecting(true);
        apiCall("quickbooks/disconnect", {}, (_status, _result) => {
            if(_status){
                message.success(_result.result);
                setConnected(false);
            } else {
                message.error(_result.error);
            }
            setDisconnecting(false);
        })
    }



    const popUp = (_url) => {
        let parameters = "location=1,width=800,height=650";
        parameters += ",left=" + (window.screen.width - 800) / 2 + ",top=" + (window.screen.height - 650) / 2;
        let win = window.open(_url, 'connectPopup', parameters);
        let pollOAuth = window.setInterval(function () {
            try {
                if (win.document.URL.indexOf("code") != -1) {
                    window.clearInterval(pollOAuth);
                    win.close();
                    window.location.reload();
                }
            } catch (e) {
                console.log(e)
            }
        }, 100);
    }

    if(!window.sitesettings.has_quickbooks){
        return(<></>)
    }

    return (<>

        <div id="advanced" style={{ "padding": "10px 30px" }}>
            <Typography.Title level={4}>App Integrations</Typography.Title>
            <Divider />
            {(loading && <Skeleton />)}

            <Spin spinning={disconnecting}>
            {((!loading && connected) &&
                <Space>
                    <div>
                        <Typography.Title level={5}>Bookmanager is Now Connected to QuickBooks</Typography.Title>
                        <Typography.Paragraph type="secondary">
                            The connection between Bookmanager and QuickBooks has been successfully established. From now on, your journal entries, bills, and vendor credits will be seamlessly sent directly to your QuickBooks account.
                        </Typography.Paragraph>
                        <div className="shim"></div> <div className="shim"></div>
                        <Popconfirm onConfirm={() => disconnect()} title={<>Are you sure?</>}>
                            <a>Click here to disconnect Bookmanager from QuickBooks.</a>
                        </Popconfirm>

                    </div>
                </Space>
            )}
            {((!loading && !connected) &&
                <Space>
                    <div>
                        <Typography.Title level={5}>Connect Bookmanager with QuickBooks</Typography.Title>
                        <Typography.Paragraph type="secondary">Click this button to start sending journal entries, bills, and vendor credits directly to your QuickBooks account. You will be prompted to log in and activate the app to establish the connection.
                        </Typography.Paragraph>
                        <div className="shim"></div> <div className="shim"></div>
                        <img onClick={() => getAuthPath()} style={{ "cursor": "pointer" }} onMouseEnter={() => setQbHover(true)} onMouseLeave={() => setQbHover(false)} src={(qbHover) ? qb_btn_hover : qb_btn} />
                    </div>
                </Space>
            )}
            </Spin>
        </div>
    </>
    )
}