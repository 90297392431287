import { CloseOutlined, CopyOutlined, EditOutlined, EyeOutlined, FileImageOutlined, FilePdfOutlined, SelectOutlined, LinkOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Badge, Button, Card, Col, Divider, message, Rate, Row, Space, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import nocover from "../../media/no-cover.png";
import { apiCall } from "../../utils/Api";
import conditional from "../../utils/conditional";
import { usePrefs } from "../../utils/Prefs";
import Session, { useSession } from "../../utils/Session";
import { bestsellerColor, bmDate, containsISBN, drawQuantity, futureColor, getCanIcon, getFlag, getListPrice, handleTermSlash, isValidUPC, parseAuthors, parseRank, parseSubjects, parseSubjectsPlain, parseSubjectsWeird, ucfirst } from "../../utils/Utils";
import CatalogueCount from "../catalogues/CatalogueCount";
import Comments from "../Comments";
import EyeIcon from "../icons/EyeIcon";
import ItemHistory3 from "../ItemHistory3";
import ItemLayouts from "../ItemLayouts";
import ListCount from "../lists/ListCount";
import ListQuickPick from "../lists/ListQuickPick";
import Lists from "../lists/Lists";
import SalesCount from "../lists/SalesCount";
import SeriesCount from "../lists/SeriesCount";
import Order from "../Order";
import OrderBox from "../OrderBox";
import Overlay from "../Overlay";
import Peek from "../Peek";
import Pubstock from "../Pubstock";
import Renderer from "../Renderer";
import SupplementaryInfo from "../SupplementaryInfo";
import TestOrderBox from "../TestOrderBox";
import DigitalResources from "./DigitalResources"
import TitleDetails from "./TitleDetails";
import TitleExtras from "./TitleExtras";
import TitleImage2 from "./TitleImage2";
import TitleLinks from "./TitleLinks";
import TitleOhOo from "./TitleOhOo";
import TitleOrders from "./TitleOrders";
import TitlePDF from "./TitlePDF";
import TitleRank from "./TitleRank";
import TitleRelated from "./TitleRelated";
import TitleRelated2 from "./TitleRelated2";
import TitleResults from "./TitleResults";
import TitleShare from "./TitleShare";

// Variants

Title.Summary = (props) => {
    return (<Title display="summary" isbn={props.isbn} />)
}



export default function Title(props) {

    const {
        data = {},
        drawComponent = () => { },
        display = "summary",
        selected = "",
        setSelected = () => { },
        highlight = false,
        setHighlight = () => { },
    } = props;
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(false);
    const [session, setSession] = useSession();
    const [prefs, setPrefs] = usePrefs();
    //used to keep ListCount and ListQuickPick in sync for live updates
    const [myLists, setMyLists] = useState(props.data.my_lists);

    const parseExpand = () => {
        if (prefs) {
            let _ret = [];
            Object.keys(prefs).forEach(key => {
                if (prefs[key]) {
                    _ret.push(key);
                }
            })
            return _ret;
        } else {
            return [];
        }
    }


    const [opened, setOpened] = useState(parseExpand())

    const toggleOpened = (_key) => {
        if (opened.includes(_key)) {
            setOpened([])
        } else {
            setOpened([_key]);
        }
        // if(opened.includes(_key)){
        //     setOpened(opened.filter(item => item !== _key))
        // } else {
        //     let m = [...opened];
        //     m.push(_key);
        //     setOpened(m)
        // }
    }

    const recheck = () => {
        if (JSON.stringify(parseExpand()) !== JSON.stringify(opened)) {
            setOpened(parseExpand())
        }
    }

    useEffect(recheck, [prefs])

    const [showPubstock, setShowPubstock] = useState("");

    const { url_isbn } = useParams();

    const {
        authors = [],
        price = null,
        list_price_usa = null,
        list_price_can = null,
        rank = {
            bestseller: null,
        },
        binding = "",
        binding_code = "",
        release_date = null,
        conditions = [],
        is_forthcoming = false,
        available = [],
        canadian = false,
        title = "",
        subtitle = "",
        eisbn = "",
        isbn = (data.hasOwnProperty("isbn")) ? data.isbn : props.isbn.toString(),
        upc = "",
        height = null,
        width = null,
        pages = null,
        publisher = "",
        has_libro_audiobook = true,
        audience = null,
        edition = "",
        edition_type = "",
        has_interiors = null,
        print_status = null,
        imprint = "",
        series = "",
        series_num = "",
        weight = null,
        carton_qty = null,
        titlelist_id = null,
        sales_marketing = [],
        description = "",
        bio = [],
        comments = [],
        reviews = [],
        subjects = [],
        catalogues = [],
        illustrations = "",
        interiors = [],
        language = "",
        digital_resources = [],
        my_lists = false,
        catalogue_count = 0,
        sequence_num = 0,
        related_count,
        isbn_type = "ean",
        prefix_length = 7,
        can_edit = false,
        cover_image_cache = 0,
        interior_objects,
        cover_image_key = ""
    } = results;

    const [coversHighlight, setCoversHighlight] = useState(data.highlight)

    const getTitle = () => {

        if (data.hasOwnProperty("isbn")) {
            setResults(data)
            setLoading(false);
        } else {
            let _isbn = props.isbn;
            if (url_isbn) {
                _isbn = url_isbn;
            }

            apiCall("title/getItem", { isbn: _isbn }, (_status, _result) => {
                if (_status) {
                    setResults(_result);
                    setLoading(false)
                }
            })
        }
    }

    useEffect(getTitle, [isbn])


    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }

    const [copied, setCopied] = useState(false);
    const copyToClipboard = (e) => {
        if (!navigator.clipboard) {
            message.error("Can't copy");
            return;
        }
        navigator.clipboard.writeText(isbn);
        setCopied(true);
        setTimeout(() => {
            setCopied(false)
        }, 1000);
    }

    const [copied2, setCopied2] = useState(false);
    const copyToClipboard2 = (e) => {
        if (!navigator.clipboard) {
            message.error("Can't copy");
            return;
        }
        navigator.clipboard.writeText(upc);
        setCopied2(true);
        setTimeout(() => {
            setCopied2(false)
        }, 1000);
    }

    const drawUPC = (_upc) => {
        if (isValidUPC(_upc)) {
            return "UPC " + _upc;
        } else {
            return "VN " + _upc;
        }
    }

    const drawISBN = (_isbn) => {
        if (isbn_type !== "ean") {
            return isbn_type.toUpperCase() + " " + _isbn;
        }
        // if (!containsISBN(_isbn)) {
        //     // not an ISBN
        //     if (isValidUPC(_isbn)) {
        //         return "UPC " + _isbn;
        //     } else {
        //         return "VN " + _isbn;
        //     }
        // }
        let ret = _isbn;
        if (_isbn.length === 13) {
            //ret = _isbn.substr(0, 3) + "-" + _isbn.substr(3, 1) + "-" + _isbn.substr(4, 3) + "-" + _isbn.substr(7, 5) + "-" + _isbn.substr(12, 1);
        }
        let link = <><a onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

        }}>
            <Tooltip title="Click prefix to see titles with the same prefix">
                <span
                    style={{ "fontFamily": "ApercuMono" }}
                    onMouseDown={(e) => {
                        if (e.button > 0) {
                            window.open("/purchasing/browse/filter/t/" + ret.substr(0, prefix_length), "_blank");
                        }
                    }}
                    onClick={(e) => {

                        if (e.ctrlKey) {
                            drawComponent(<TitleResults term={ret.substr(0, prefix_length)} isbn={isbn} name={"ISBN prefix search: " + ret.substr(0, prefix_length)} drawComponent={drawComponent} />)
                        } else {
                            window.open("/purchasing/browse/filter/t/" + ret.substr(0, prefix_length), "_blank");
                        }
                    }}>
                    {ret.substr(0, prefix_length)}
                </span>
            </Tooltip>
        </a><span style={{ "fontFamily": "ApercuMono" }}>{ret.substr(prefix_length, ret.length)}</span></>
        return link;
    }


    const parseAuthorsLink = (_authors) => {
        if (!_authors || _authors.length === 0) { return "" }
        return _authors.map((_author, index) => {
            let flag = (_author.canadian && window.sitesettings.is_canadian);
            return (<nobr><Tooltip title="View other titles by this author in a new tab"><a onClick={(e) => {
                e.stopPropagation();
                window.open("/purchasing/browse/filter/k/author/t/" + _author.name)
            }} style={{ "color": "#007EF5", "fontSize": "14px" }} key={index}>{_author.name}{(flag && getCanIcon())} {(index !== _authors.length - 1) ? ", " : ""} </a></Tooltip><Tooltip title="View other titles by this author below"><Button style={{ "height": "20px", "padding": "0px", "paddingRight": "5px" }} size="small" onClick={(e) => {
                e.stopPropagation();
                drawComponent(<TitleResults term={_author.name} type={"author"} isbn={isbn} name={"Other titles for: " + _author.name} drawComponent={drawComponent} />)
            }} type="link"><EyeIcon /></Button></Tooltip></nobr>);
        });
    }


    const drawPrice = (_card = false) => {
        // Draw CA first if store is Canadian, US first if store is American
        return (
            <div className="c2" style={{ "fontSize": "14px", "fontWeight": "normal" }} >{binding}
                 {(edition && <span style={{ "fontSize": "14px", "display": "block", "fontWeight": "normal" }}><mark>&nbsp;{edition}&nbsp;</mark></span>)}
                {getListPrice(list_price_can, list_price_usa, window.sitesettings.is_canadian)}
            </div>
        )

    }

    const drawSummary = () => {
        //potential refactor to be more clean, but these are for determining if a product has a value, and if it doesn't 
        //it won't show anything related, like the pages string for the amount of pages when it's a bookmark.
        //Example: https://biz.bookmanager.com/purchasing/browse/filter/a/active/v/in_store/t/9780735354302
        const pagesValue = pages != null ? pages + " pages | " : null;
        const widthValue = width && height != null ? width + "\" x " : null;
        const heightValue = width && height != null ? height + "\" | " : null;
        const weightValue = weight != null ? weight + "lbs | " : null;
        const cartonValue = carton_qty != null ? "Crtn: " + carton_qty : null;

        return (
            <div style={{ "padding": "0px", "paddingBottom": "0px" }}>
                <Space size={13} align="start" style={{ "overflow": "hidden" }}>
                    <div style={{ "maxWidth": "140px" }}>
                        <TitleImage2

                            onMouseDown={(e) => {
                                if (e.button > 0) {
                                    e.stopPropagation();
                                    window.open("/purchasing/browse/filter/t/" + isbn);
                                }
                            }}


                            highlight={highlight} cover_image_cache={cover_image_cache} interior_objects={interior_objects} isbn={isbn} cover_image_key={cover_image_key} eisbn={eisbn} has_interiors={has_interiors} />
                        <div className="shim" />
                        <Space size={2}>
                            <Button style={{ "width": "140px" }} type={(copied ? "primary" : "default")} onClick={() => copyToClipboard(isbn)} size="small" className="mini-btn nofocus" ><small>&nbsp;{(copied) ? "Copied" : drawISBN(isbn)} &nbsp;<CopyOutlined style={{ "fontSize": "9px" }} /></small></Button>
                        </Space>
                        {(upc &&
                            <div><Button style={{ "width": "140px" }} type={(copied2 ? "primary" : "default")} onClick={() => copyToClipboard2(upc)} size="small" className="mini-btn nofocus" ><small>&nbsp;{(copied2) ? "Copied" : drawUPC(upc)} &nbsp;<CopyOutlined style={{ "fontSize": "9px" }} /></small></Button></div>
                        )}

                        <Space size={2}>
                            <Overlay component={<div style={{ "padding": "20px" }}><TitleDetails drawComponent={drawComponent} isbn={isbn} /></div>}>
                                <Button disabled={!can_edit} style={{ "width": "26px" }} size="small" className="mini-btn" ><small><EditOutlined /></small></Button>
                            </Overlay>
                            <TitlePDF isbn={isbn} />
                            <Tooltip title={<small>View on webstore</small>}>
                                <Button onClick={() => window.open(window.sitesettings.url + "item/" + eisbn)} style={{ "width": "26px" }} size="small" className="mini-btn" ><small><LinkOutlined /></small></Button>
                            </Tooltip>
                            <TitleShare title={title} isbn={isbn} eisbn={eisbn} />
                            <div style={{ "textAlign": "center", "width": "30px" }}>
                                <TitleLinks authors={authors} isbn={isbn} />
                            </div>
                        </Space>


                    </div>
                    <div>
                        <Typography.Title style={{ "lineHeight": "19px" }} level={5}>

                            <span className="tag-btn" >
                                <span onClick={(e) => {
                                    e.stopPropagation();
                                    let _term = title;
                                    if (authors.length > 0) { _term += " " + authors[0].name; }
                                    window.open("/purchasing/browse/filter/v/related:" + isbn + "/t/" + handleTermSlash(_term, true));
                                }
                                }>
                                    <Tooltip title="View other editions of this title in a new tab"><a>{title}</a>{(subtitle) ? <small>: {subtitle}</small> : ""} </Tooltip><Tooltip title="View other editions of this title below"><Button style={{ "padding": "0px 0px" }} size="small" onClick={(e) => {
                                        e.stopPropagation();
                                        let _term = title;

                                        if (authors.length > 0) { _term += " " + authors[0].name; }
                                        drawComponent(<TitleResults term={handleTermSlash(_term, true)} isbn={isbn} name={"Other Editions for: " + title} drawComponent={drawComponent} />)
                                    }} type="link"><EyeIcon /></Button></Tooltip>
                                </span>

                            </span>
                            {/* <span style={{ "color": "#666", "fontSize": "12px", "display": "block" }}>{(subtitle) ? subtitle + " | " : ""}{binding}</span> */}
                            <div style={{ "marginTop": "-2px" }}>{drawPrice()}</div>

                            <div className="shim" />
                            <Space split={<small className="c2"> </small>}>
                                <conditional.true value={(release_date)}>
                                    <span className={(is_forthcoming) ? "c2 futureColor" : "c2"} style={{ "fontSize": "14px", "display": "block", "fontWeight": "600" }}>{moment(release_date * 1000).format("MMM Do, YYYY")}</span>
                                </conditional.true>
                                <TitleRank rank={rank} />
                            </Space>

                            <small><span style={{ "color": "#069", "fontSize": "14px", "display": "block" }}>
                                <Space size={0} wrap>{parseAuthorsLink(authors)}</Space>
                            </span></small>

                            {(series && <div className="c2" style={{ "fontSize": "14px", "display": "block", "fontWeight": "normal", "marginTop": "-2px" }}>Series: <Tooltip title="View titles in this series in a new tab"><a onClick={(e) => {

                                window.open("/purchasing/browse/filter/z/" + series, "_blank");

                            }}>{series} {(series_num) ? ": (#" + series_num.toString() + ")" : ""}</a></Tooltip> <Tooltip title="View titles in this series below"><Button style={{ "padding": "0px 0px" }} size="small" onClick={(e) => {
                                e.stopPropagation();
                                drawComponent(<TitleResults type="series" series={series} term={series} isbn={isbn} name={"Other titles in series: " + series} drawComponent={drawComponent} />)
                            }} type="link"><EyeIcon /></Button></Tooltip>

                            </div>)}


                            <div className="c2" style={{ "fontSize": "14px", "display": "block", "fontWeight": "normal", "marginTop": "-2px" }}><span style={{ "fontWeight": "600" }}>{publisher}</span>{(imprint && (imprint !== publisher) && <em> | {imprint}</em>)}</div>
                            {(audience && <span style={{ "fontSize": "14px", "display": "block", "fontWeight": "normal" }}>Audience: {audience}</span>)}
                            <div className="shim" />
                            <div className="shim" />

                            <div style={{ "lineHeight": "12px" }}>
                                <div className="c2" style={{ "fontSize": "12px", "display": "block", "fontWeight": "normal", "lineHeight": "14px" }}>{parseSubjects(subjects)}</div>
                                <div className="shim" />

                                {(print_status && <span className="c2" style={{ "color": "#f12a46", "fontSize": "11px", "display": "block", "fontWeight": "normal" }}>{ucfirst(print_status)}</span>)}
                                <span
                                    className="c2"
                                    style={{ "fontSize": "11px", "display": "block", "fontWeight": "normal" }}>
                                    {pagesValue} {widthValue} {heightValue} {weightValue} {cartonValue}
                                </span>
                                {(language && <span className="c2" style={{ "fontSize": "11px", "display": "block", "fontWeight": "normal" }}>Language: {language}</span>)}
                            </div>
                        </Typography.Title>
                        <Space align="top">
                            <TitleOhOo data={data} />
                            <TitleOrders isbn={isbn} />
                            <Space wrap size={[3, 0]}>
                                <ListCount title={title} my_lists={myLists} isbn={isbn} updateMyListsInParent={setMyLists} />
                                <CatalogueCount count={catalogue_count} isbn={isbn} />
                                <SalesCount title={title} data={sales_marketing} />
                                <TitleRelated2 related_count={related_count} title={title} drawComponent={drawComponent} isbn={isbn} />
                                <DigitalResources data={digital_resources} />
                                {((series && series_num && binding) && <>
                                    <SeriesCount series_num={(series_num) ? ": (#" + series_num.toString() + ")" : ""} series={series} next={true} drawComponent={drawComponent} isbn={isbn} />
                                    <SeriesCount series_num={(series_num) ? ": (#" + series_num.toString() + ")" : ""} series={series} next={false} drawComponent={drawComponent} isbn={isbn} />
                                </>)}
                            </Space>
                        </Space>

                        {(my_lists && <div><ListQuickPick isbn={isbn} my_lists={myLists} updateMyListsInParent={setMyLists} /></div>)}

                    </div>
                </Space>
                <TitleExtras titlelist_id={titlelist_id} data={data} isbn={isbn} />
                <conditional.true value={(showPubstock)}>
                    <div style={{ "padding": "0px 20px" }}>
                        <Pubstock drawComponent={drawComponent} show_stores={(showPubstock === "stores")} isbn={isbn} />
                    </div>
                </conditional.true>

                <SupplementaryInfo opened={opened} data={data} isbn={data.isbn || isbn} />
            </div>
        )
    }

    const drawPrint = () => {
        let image_path = "https://cdn1.bookmanager.com/i/b?b=" + eisbn;
        if (cover_image_key) {
            image_path = image_path + "&b2b=" + cover_image_key;
        }
        return (
            <div style={{ "padding": "0px", "paddingBottom": "0px", backgroundColor: (selected === isbn) ? "#dcf4ff" : "#fff" }}>
                <Space align="start" style={{ "overflow": "hidden" }}>
                    <div style={{ "minWidth": "130px" }}>
                        <img className="shadow-large" onError={(e) => noCover(e)} style={{ "width": "130px", "marginRight": "10px" }} src={image_path} alt="" />
                        <div className="shim" />
                    </div>
                    <div style={{ "minWidth": "265px" }}>
                        <Typography.Title style={{ "lineHeight": "17px" }} level={5}>{title}
                            <span style={{ "color": "#333", "fontSize": "14px", "display": "block" }}>{subtitle}</span>
                            <span style={{ "color": "#069", "fontSize": "14px", "display": "block", "pointerEvents": "none" }}><small>{parseAuthors(authors)}</small></span>
                            <div className="shim" />
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>Subjects:</strong> {parseSubjectsPlain(subjects)}</span>
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>ISBN:</strong> {isbn}</span>
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>Publisher:</strong> {publisher}</span>
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>Binding:</strong> {binding}</span>
                            <span style={{ "color": "#333", "fontSize": "13px", "display": "block", "fontWeight": "normal" }}><strong>Date:</strong> {moment(release_date * 1000).format("MMMM Do, YYYY")}</span>
                        </Typography.Title>
                    </div>
                </Space>
            </div>
        )
    }

    const drawRank = (_rank) => {
        if (_rank.hasOwnProperty("bestseller")) {
            return <span style={{ "color": bestsellerColor }}>#{_rank.bestseller}</span>
        } else {
            return <span style={{ "color": futureColor }}>#{_rank.future}</span>
        }
    }

    const getPrices = () => {
        if (!Array.isArray(conditions)) {
            return;
        }
        //George (08Jun2023) Issue: web/issues#1892
        //Using values from both arrays to provide the right information when card/cover display is being used.
        return conditions.map((element, index) => {
            let { sell_price = 0, price = 0, code = "" } = element;
            let available_onhand, available_onorder;
            available.forEach((item, index) => {
                if (code === item.code) {
                    available_onhand = item.onhand;
                    available_onorder = item.onorder;
                }
            });

            return {
                // use sell price if exists
                price: (sell_price) ? sell_price : price,
                onhand: available_onhand,
                onorder: available_onorder,
                code: code,
                sale: (sell_price)
            }
        });
    }

    const getPeers = () => {
        if (conditions.length > 0) {
            if (conditions[0].hasOwnProperty("peer")) {
                return ({
                    onhand: conditions[0].peer.onhand,
                    onorder: conditions[0].peer.onorder,
                    onhandlocs: conditions[0].peer.onhandlocs,
                    onorderlocs: conditions[0].peer.onorderlocs
                })
            } else {
                return (<></>)
            }
        }
        else {
            return (<></>)
        }
    }

    const drawTable = () => {
        let peers = getPeers();
        let prices = getPrices();
        return (
            <>
                <div style={{ "border": "none", "0px": "0px" }}>
                    <Row align="middle" style={{ "textAlign": "right", "fontSize": "12px", "lineHeight": "16px" }}>
                        <Col span={6}>
                            <div className="bcg2 bc c" style={{ "borderRight": "1px solid", "borderBottom": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "11px", "fontStyle": "italic", "paddingRight": "2px" }}>Price</small></div>
                            {prices.map((item, index) => {
                                return <div className="bcg2 bc c" key={index} style={{ "fontStyle": (index) ? "italic" : "normal", "color": (index) ? "#189285" : "", "fontSize": "14px", "fontWeight": "bold", "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px", "height": "18px", "overflow": "hidden", "borderBottom": "1px solid" }}>
                                    <small style={{ color: (item.sale) ? "#f12a46" : "inherit" }}>{(item.price > 0) ? item.code + " $" + item.price : ""}</small>
                                </div>
                            })}
                            <div className="bcg2 bc c" style={{ "fontWeight": "bold", "borderRight": "1px solid", "cursor": "pointer", "paddingRight": "2px", "fontSize": "14px" }}><small>{drawRank(rank)}</small></div>
                        </Col>
                        <Col span={5}>
                            <div className="bcg2 bc c" style={{ "borderRight": "1px solid", "borderBottom": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "11px", "fontStyle": "italic", "paddingRight": "2px" }}>aOH</small></div>
                            {prices.map((item, index) => {
                                return <div className="bcg2 bc c" key={index} style={{ "fontStyle": (index) ? "italic" : "normal", "fontSize": "14px", "fontWeight": "bold", "borderRight": "1px solid", "textAlign": "right", "paddingRight": "2px", "height": "18px", "overflow": "hidden", "borderBottom": "1px solid", "color": (index) ? "#189285" : "", }}><small>{item.onhand || ""}&nbsp;</small></div>
                            })}
                            <div className="bcg2 bc c" style={{ "color": "#A52A2A", "fontWeight": "500", "borderRight": "1px solid", "cursor": "pointer", "paddingRight": "2px" }}><small>{peers.onhand || ""}<sup>{peers.onhandlocs || ""}</sup>&nbsp;</small></div>
                        </Col>
                        <Col span={5}>
                            <div className="bcg2 bc c" style={{ "borderBottom": "1px solid", "height": "18px", "overflow": "hidden" }}><small style={{ "fontSize": "11px", "fontStyle": "italic", "paddingRight": "2px" }}>aOO</small></div>
                            {prices.map((item, index) => {
                                return <div className="bcg2 bc c" key={index} style={{ "fontStyle": (index) ? "italic" : "normal", "fontSize": "14px", "fontWeight": "bold", "textAlign": "right", "paddingRight": "2px", "height": "18px", "overflow": "hidden", "borderBottom": "1px solid" }}><small>{item.onorder || ""}&nbsp;</small></div>
                            })}
                            <div className="bcg2 bc c" style={{ "fontWeight": "500", "color": "#A52A2A", "borderRight": "none", "cursor": "pointer", "paddingRight": "2px" }}><small>{peers.onorder || ""}<sup>{peers.onorderlocs || ""}&nbsp;</sup></small></div>
                        </Col>
                        <Col span={8}>
                            <div style={{ "float": "right" }}><Overlay width={750} component={<TestOrderBox setResults={setResults} wrap data={data} isbn={isbn} />}>{drawQuantity(isbn, session.cart)}</Overlay></div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    const [imgError, setImageError] = useState(false);

    const CardImage = (props) => {
        const ref = useRef();
        const scrollIntoViewWithOffset = (selector, offset) => {
            window.scrollTo({
                behavior: 'smooth',
                top:
                    selector.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    offset,
            })
        }

        useEffect(() => {
            scrollIntoViewWithOffset(ref.current, 280);
        }, [])

        return (
            <>
                <div style={{ "marginTop": "0px", "marginLeft": "0px" }}>
                    <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                        <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                        <br clear="both" />
                    </div>
                    <div className="shim" /><div className="shim" /><div className="shim" />
                    <div ref={ref}><Renderer close={true}><ItemLayouts cart_update={true} data={data} display="details2" /></Renderer></div>
                </div>
            </>
        )
    }

    function badgeText() {
        return "Highlighted"
    }

    function drawBorder() {
        if (highlight) {
            return {
                borderStyle: "solid",
                borderColor: "#ff0099",
                borderWidth: "3px",
                opacity: props.loading ? "0.5" : "1",
                pointerEvents: props.loading ? "none" : "inherit",
                marginTop: sequence_num ? "8px" : "0px"
            }
        }
        return {
            opacity: props.loading ? "0.5" : "1",
            pointerEvents: props.loading ? "none" : "inherit",
            marginTop: sequence_num ? "8px" : "0px"
        }
    }

    function drawCardBorder() {
        if (coversHighlight) {
            return {
                top: "-27px",
                left: "0px",
                lineHeight: "14px",
                position: "absolute",
                padding: "-5px",
                borderStyle: "solid",
                borderColor: "#ff0099",
                borderWidth: "3px",

                backgroundColor: "#ff0099",
            }
        }
        return {
            top: "-27px",
            left: "0px",
            lineHeight: "10px",
            position: "absolute",
            padding: "3px 4px"
        }
    }


    const drawImage = () => {
        let image_path = "https://cdn1.bookmanager.com/i/n?b=" + eisbn
        if (cover_image_key) {
            image_path = image_path + "&b2b=" + cover_image_key;
        }
        if (cover_image_cache) {
            image_path = image_path + "&cb=" + cover_image_cache;
        }
        if (imgError) {
            return (
                <div onClick={() => drawComponent(<CardImage />)} style={{ "backgroundImage": `url(${nocover})`, "backgroundSize": "contain" }}>
                    <div className="card-fallback">
                        <div><strong>{title}</strong></div>
                        <div className="shim" /><div className="shim" />
                        {(subtitle && <>
                            <div style={{ "lineHeight": "14px" }}><small>{subtitle}</small></div>
                            <div className="shim" /><div className="shim" />
                        </>)}
                        <div>{parseAuthors(authors, "#000")}</div>
                    </div>
                </div>
            )
        }
        return (
            <img alt="example" onClick={() => drawComponent(<CardImage />)} onError={(e) => setImageError(true)} src={image_path} />
        )
    }

    const drawCard = () => {
        return (
            <Card
                hoverable
                style={drawBorder()}
                key={isbn}
                size="small"
                className={(selected === isbn) ? "card-selected" : "card"}
                cover={
                    <>

                        {drawImage()}
                        <conditional.true value={sequence_num > 0}>
                            <div style={drawCardBorder()}>
                                <small className="c" style={coversHighlight ? { "color": "#FFFFFF" } : null}><strong>{"#" + sequence_num} {coversHighlight ? "Highlighted" : ""}</strong></small>
                            </div>
                        </conditional.true>
                    </>
                }
            >
                <Card.Meta description={
                    <>
                        <div style={{ "fontSize": "15px", "lineHeight": "15px" }} className="c"><strong>{title}</strong>
                            <div>{parseAuthors(authors, "inherit", "12px")}</div>
                        </div>
                        <div className="shim" />
                        <div className="c" style={{ "lineHeight": "16px", "fontWeight": "600", "display": "flex", "justifyContent": "space-between" }}>
                            <div>
                                <small>{binding} | <span className="c" style={{ "color": (is_forthcoming) ? futureColor : "" }}> {bmDate(release_date * 1000)}</span></small>
                            </div>
                            <small>{drawPrice(true)}</small>
                        </div>
                        <Divider style={{ "margin": "5px 0px" }} />
                        {/* <div className="c2" style={{ "lineHeight": "14px", "fontWeight": "600" }}><small>{publisher}</small></div>
                        <div className="c2" style={{ "lineHeight": "14px", "fontWeight": "600" }}><small>{parseSubjectsWeird(subjects)}</small></div> */}
                        <div className="shim" />
                        {/* <div style={{ "lineHeight": "14px" }}><small>Peer :  {drawPeers()}</small></div>
                        <div style={{ "lineHeight": "14px" }}>{drawPrices()}</div> */}
                        <div>{drawTable()}</div>
                    </>
                } />
            </Card>
        )
    }

    switch (display) {
        case "summary":
            return drawSummary();
        case "card":
            return drawCard();
        case "print":
            return drawPrint();
        default:
            return drawSummary();
    }
}