import { Row, Col, Tooltip, Space } from "antd";
import React, { useState } from "react";
import { addCommas } from "../../utils/Utils";
import conditional from "../../utils/conditional";
export default function AnalyticsSales(props) {



    const { range = 0, high = 0, filters = {}, data = {}, getSet = () => { }, resetScale = false, includes = {}, setView = () => { } } = props;


    // On hand total normalized for use in graph
    let oht = getSet(data, "onhand_total");
    oht = (oht > 0) ? oht / 20 : 0;
    const localHigh = Math.max(getSet(data, "sales_total"), getSet(data, "sandr_total"), oht) || 0;

    const scaleHigh = (resetScale) ? localHigh : high;




    const salesrtrn_total = getSet(data, "salesrtrn_total");




    // SALES 

    const sales_total = getSet(data, "sales_total");
    const sandr_total = getSet(data, "sandr_total");
    const sales_diff = getSet(data, "sandr_total", false) - getSet(data, "sandr_total", true);
    const sales_multiplier = (sales_diff > 0) ? 1 : -1;
    const sales_max_width = (sales_total / scaleHigh) * 100;


    const stock_total = getSet(data, "onhand_total", false);
    const stock_diff = getSet(data, "onhand_total", false) - getSet(data, "onhand_total", true);
    const stock_multiplier = (stock_diff > 0) ? 1 : -1;
    const stock_max_width = ((stock_total / (365 / range)) / scaleHigh) * 100;



    const onorder_total = getSet(data, "onorder_total", false);
    const onorder_diff = getSet(data, "onorder_total", false) / getSet(data, "onhand_total", true);
    const onorder_multiplier = (onorder_diff > 0) ? 1 : -1;
    const onorder_max_width = onorder_diff * stock_max_width;



    // const onorder_diff = getSet(data, "onorder_total", false) - getSet(data, "onhand_total", true);
    // const onorder_multiplier = (onorder_diff > 0) ? 1 : -1;
    // const onorder_max_width = ((onorder_total / (365 / range)) / scaleHigh) * 100;





    const received_total = getSet(data, "rcvd", false);
    const received_max_width = (received_total / scaleHigh) * 100;
    // const stock_diff = getSet(data, "onhand_total", false) - getSet(data, "onhand_total", true);
    // const stock_multiplier = (stock_diff > 0) ? 1 : -1;
    // const stock_max_width = ((stock_total / 15) / high) * 100;


    const pers = {
        // Sales
        sandr_total: getSet(data, "sandr_total", false) / sales_total,
        salesrtrn_total: getSet(data, "salesrtrn_total", false) / sales_total,
        profit: (filters.show_which === "dollars") ? data.profit / sales_total : 0,
        sales_new: getSet(data, "sales_new", false) / sales_total,
        salesrtrn_new: getSet(data, "salesrtrn_new", false) / sales_total,
        sales_mid: getSet(data, "sales_mid", false) / sales_total,
        salesrtrn_mid: getSet(data, "salesrtrn_mid", false) / sales_total,
        sales_old: getSet(data, "sales_old", false) / sales_total,
        sales_dead: getSet(data, "sales_dead", false) / sales_total,
        sales_prev_year: (sales_diff / sales_total) * 100 * -1,
        sales_space: (sales_diff / (sales_total - sales_diff)) * 100 * -1,
        sales_prev_diff: 100 - (sales_diff / sales_total) * 100 * -1,
        sales_prev_year_up: (sales_diff / sales_total) * 100 * sales_multiplier,
        sales_prev_diff_up: 100 - (sales_diff / sales_total) * 100 * sales_multiplier,

        // Stock
        onhand_scale: getSet(data, "sandr_total", false) / (getSet(data, "onhand_total", false) / getSet(data, "turns", false)) * 100,
        onhand_total: getSet(data, "onhand_total", false) / stock_total,
        onhand_new: getSet(data, "onhand_new", false) / stock_total,
        onhand_mid: getSet(data, "onhand_mid", false) / stock_total,
        onhand_old: getSet(data, "onhand_old", false) / stock_total,
        onhand_dead: getSet(data, "onhand_dead", false) / stock_total,
        onorder_total: getSet(data, "onorder_total", false) / stock_total,
        stock_prev_year : ((stock_total / (stock_total - stock_diff)) - 1) * 100 *-1 || 0,
        stock_prev_diff: 100 - (stock_diff / stock_total) * 100 * -1,
        stock_prev_year_up: (stock_diff / stock_total) * 100 * stock_multiplier,
        stock_prev_diff_up: 100 - (stock_diff / stock_total) * 100 * stock_multiplier,

        // Received
        received_total: getSet(data, "rcvd", false) / received_total,
        received_rtrn: getSet(data, "rtrn", false) / received_total,


    }


    const label = () => {
        let ret = "";
        switch (filters.show_which) {
            case "dollars": ret = "cost"; break
            case "unit_copies": ret = "units"; break
            case "unique_isbns": ret = "isbns"; break
        }
        return ret;
    }

    const getPer = (_lbl) => {
        if (pers.hasOwnProperty(_lbl)) {
            return pers[_lbl] * 100
        } else {
            return 0;
        }
    }

    const SBRow = (props) => {
        return (
            <div className="sb-row">
                <div class="sb-bar-container">
                    {props.children}
                </div>
            </div>
        )
    }

    const SBSeg = (props) => {
        const [visible, setVisible] = useState(false);
        const { field = "", color = "#fff", height = "10px", className = "", tooltip = false, rel = false } = props;
        const handleMouse = (e, _show = false) => {
            e.preventDefault();
            e.stopPropagation();
            setVisible(!visible)
        }

        let seg = <div onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setView(field);
        }} onMouseOver={(e) => handleMouse(e, true)} onMouseOut={(e) => handleMouse(e, true)} className={"sb-bar lighten " + className} style={{ "flex-basis": getPer(field) + "%", "backgroundColor": color, "height": height }}>{props.children}</div>

        if (tooltip === "hide") {
            return seg;
        }
        return (
            <Tooltip title={drawOverlay(field, tooltip)} visible={visible}>{seg}</Tooltip>
        )
    }


    const drawReceivedOverlay = () => {

        let _overlay = [
            { key: addCommas(getSet(data, "rcvd", false, "dollars")), value: "Received Total" },
            { key: addCommas(-getSet(data, "rtrn", false)), value: "Returns Total" },
            { key: addCommas(getSet(data, "rtrn_percent", false)) + "%", value: "Returns as a % of Received" },
        ]
        return (
            <>
                <Row gutter={5}>
                    <Col>
                        {_overlay.map(item => {
                            return (<div>{item.key}</div>)
                        })}
                    </Col>
                    <Col>
                        {_overlay.map(item => {
                            return (<div>{item.value}</div>)
                        })}
                    </Col>
                </Row>
            </>
        )
    }


    const drawPreviousSalesOverlay = () => {

        let _overlay = [
            { key: addCommas((getSet(data, "sandr_total", false, "dollars") - getSet(data, "sandr_total", true, "dollars"))), value: "Dollars" },
            { key: addCommas((getSet(data, "sandr_total", false, "unit_copies") - getSet(data, "sandr_total", true, "unit_copies"))), value: "Copies" },
            { key: addCommas((getSet(data, "sandr_total", false, "unique_isbns") - getSet(data, "sandr_total", true, "unique_isbns"))), value: "ISBNs" },

        ]
        return (
            <>
                <div><strong>Sales Change From Last Year</strong></div>
                <Row gutter={5}>
                    <Col>
                        {_overlay.map(item => {
                            return (<div>{item.key}</div>)
                        })}
                    </Col>
                    <Col>
                        {_overlay.map(item => {
                            return (<div>{item.value}</div>)
                        })}
                    </Col>
                </Row>
            </>
        )
    }

    const drawPreviousStockOverlay = () => {

        let _overlay = [
            { key: addCommas((getSet(data, "onhand_total", false, "dollars") - getSet(data, "onhand_total", true, "dollars"))), value: "Dollars" },
            { key: addCommas((getSet(data, "onhand_total", false, "unit_copies") - getSet(data, "onhand_total", true, "unit_copies"))), value: "Copies" },
            { key: addCommas((getSet(data, "onhand_total", false, "unique_isbns") - getSet(data, "onhand_total", true, "unique_isbns"))), value: "ISBNs" },

        ]
        return (
            <>
                <div><strong>Onhand change from last year</strong></div>
                <Row gutter={5}>
                    <Col>
                        {_overlay.map(item => {
                            return (<div>{item.key}</div>)
                        })}
                    </Col>
                    <Col>
                        {_overlay.map(item => {
                            return (<div>{item.value}</div>)
                        })}
                    </Col>
                </Row>
            </>
        )
    }


    const drawReceived = () => {
        return (
            <>
                <SBRow>
                    <SBSeg height="20px" tooltip={drawReceivedOverlay()} field="received_total" color="#808000">
                        <SBSeg rel field="received_rtrn" tooltip={drawReceivedOverlay()} color="#505000" height="10px" />
                    </SBSeg>
                </SBRow>
            </>
        )
    }

    const drawStock = () => {


        return (
            <>
                <SBRow>
                    <SBSeg field="onhand_total" color="#c25135" />
                </SBRow>
                <SBRow>
                    <SBSeg field="onhand_new" color="#facbb4" />
                    <SBSeg field="onhand_mid" color="#f8aa79" />
                    <SBSeg field="onhand_old" color="#e78c41" />
                    <SBSeg field="onhand_dead" color="#0c0703" />
                </SBRow>
            </>
        )
    }


    const drawSales = () => {

        return (
            <>

                <SBRow>
                    <SBSeg field="sandr_total" color="#4a7682">
                        <SBSeg field="profit" tooltip={<div><strong>Profit</strong><div>${addCommas(data.profit)}</div></div>} color="#000088" height="5px" />
                    </SBSeg>
                    <SBSeg field="salesrtrn_total" color="#007682" className="txtptrn" />
                </SBRow>
                <SBRow>
                    <SBSeg field="sales_new" color="#b8d4e0" />
                    <SBSeg field="salesrtrn_new" color="#00d4e0" className="txtptrn" />
                    <SBSeg field="sales_mid" color="#7cbbcf" />
                    <SBSeg field="salesrtrn_mid" color="#00bbcf" className="txtptrn" />
                    <SBSeg field="sales_old" color="#47a1b9" />
                    <SBSeg field="sales_dead" color="#000" />
                </SBRow>
            </>
        );
    }


    const fieldToTitle = (_field) => {
        switch (_field) {

            case "sandr_total": return "Sales Total";
            case "salesrtrn_total": return "Customer Returns Total";
            case "sales_new": return "Sales of Stock Under 31 Days Old";
            case "salesrtrn_new": return "Customer Returns of Stock Under 31 Days Old";
            case "sales_mid": return "Sales of Stock 31-274 Days Old";
            case "salesrtrn_mid": return "Customer Returns of Stock 31-274 Days Old";
            case "sales_old": return "Sales of Stock 275-365 Days Old";
            case "sales_dead": return "Sales of Stock Over 365 Days Old";
            case "onhand_total": return "Onhand Total";
            case "onhand_new": return "Onhands Under 31 Days Old";
            case "onhand_mid": return "Onhands 31-274 Days Old";
            case "onhand_old": return "Onhands 275-365 Days Old";
            case "onhand_dead": return "Onhands Over 365 Days Old";
            case "onorder_total": return "Onorder Total";


        }
    }

    const drawOverlay = (_field = "", _tooltip = false) => {


        if (_tooltip === "hide") {
            return false;
        }

        if (_tooltip) {
            return _tooltip;
        }


        let _overlay = [
            { key: addCommas(getSet(data, _field, false, "dollars") || 0), value: "Dollars" },
            { key: addCommas(getSet(data, _field, false, "unit_copies") || 0), value: "Copies" },
            { key: addCommas(getSet(data, _field, false, "unique_isbns") || 0), value: "ISBNs" }
        ]

        return (
            <>
                <div><strong>{fieldToTitle(_field)}</strong></div>
                <Row gutter={5}>
                    <Col>
                        {_overlay.map(item => {
                            return (<div>{item.key}</div>)
                        })}
                    </Col>
                    <Col>
                        {_overlay.map(item => {
                            return (<div>{item.value}</div>)
                        })}
                    </Col>
                </Row>
            </>
        )
    }


    return (
        <>
           
        

            {/* SALES */}
            <conditional.true value={includes.sales}>

                <>
                    <Row gutter={30}>
                        <Col flex={"200px"}>
                            <Row>
                                <Col flex={"auto"}>
                                    <div className="c">Sales</div></Col>
                                <Col flex={"auto"}>
                                    <div className="c" style={{ "textAlign": "right" }}>{(filters.show_which === "dollars") ? "$" : ""}{addCommas(sandr_total)}	<small>{(filters.show_which === "dollars") ? "retail" : label()}</small></div>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex={"auto"}>
                            <div style={{ "width": sales_max_width + "%" }}>
                                <Row align="middle">
                                    <Col flex={"auto"} style={{ "height": "20px" }}>
                                        {/* Previous Gain */}
                                        <div style={{ "backgroundColor": "#00ff00", "position": "absolute", "zIndex": "1", "right": "0px", "top": "7px", "height": "6px", "float": "flex", "width": (pers.sales_prev_year < 0) ? Math.max(-100, pers.sales_prev_year) * -1 + "%" : "0%" }}>
                                            <SBSeg field="sales_prev_year_up" color={(sales_diff > 0) ? "#00ff00" : "#d03333"} height="5px" tooltip={<>{drawPreviousSalesOverlay()}</>} />
                                        </div>
                                        {drawSales()}
                                    </Col>
                                    <Col flex={(pers.sales_space > 0) ? pers.sales_space + "%" : "0%"}>
                                        {/* Previous Loss */}
                                        <SBSeg field="sales_prev_year_up" color={(sales_diff > 0) ? "#00ff00" : "#d03333"} height="5px" tooltip={<>{drawPreviousSalesOverlay()}</>} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div className="shim" />
                </>
            </conditional.true>


            {/* STOCK */}
            <conditional.true value={includes.inventory}>
                <>
                    <Row gutter={30} >
                        <Col flex={"200px"}>
                            <Row>
                                <Col flex={"auto"}>
                                    <div className="c">Onhand</div></Col>
                                <Col flex={"auto"}>
                                    <div className="c" style={{ "textAlign": "right" }}>{(filters.show_which === "dollars") ? "$" : ""}{addCommas(stock_total)}	<small>{label()}</small></div>
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ "display": "flex" }} flex={"auto"}>
                            
                            <div style={{ "width": stock_max_width + "%", "display": "inline-block" }}>
                                <Row align="middle">
                                    <Col flex={"auto"} style={{ "height": "20px" }}>
                                        {/* Previous Gain */}
                                        <div style={{ "backgroundColor": "#00ff00", "position": "absolute", "zIndex": "1", "right": "0px", "top": "7px", "height": "6px", "float": "flex", "width": (pers.stock_prev_year < 0) ? Math.max(-100, pers.stock_prev_year) * -1 + "%" : "0%" }}>
                                            <SBSeg field="received_prev_year_up" color={"#00ff00"} height="5px" tooltip={<>{drawPreviousStockOverlay()}</>} />
                                        </div>
                                        {drawStock()}
                                    </Col>
                                    <Col flex={(pers.stock_prev_year > 0) ? pers.stock_prev_year + "%" : "0%"}>
                                        {/* Previous Loss */}
                                        <SBSeg field="received_prev_year_up" color={"#d03333"} height="5px" tooltip={<>{drawPreviousStockOverlay()}</>} />
                                    </Col>
                                </Row>

                            </div>
                            <div style={{ "width": onorder_max_width + "%", "display": "inline-block" }}>
                                <Tooltip title={drawOverlay("onorder_total")}><div className="lighten" onClick={() => setView("onorder_total")} style={{ "backgroundColor": "#ff9900", "position": "relative", "height": "20px" }}>&nbsp;</div></Tooltip>
                            </div>


                        </Col>
                    </Row>
                    <div className="shim" />
                </>
            </conditional.true>


            {/* RECEIVED */}
            <conditional.true value={includes.receiving_returns}>
                <>
                    <Row gutter={30} >
                        <Col flex={"200px"}>
                            <Row>
                                <Col flex={"auto"}>
                                    <div className="c">Received</div></Col>
                                <Col flex={"auto"}>
                                    <div className="c" style={{ "textAlign": "right" }}>{(filters.show_which === "dollars") ? "$" : ""}{addCommas(received_total)}	<small>{label()}</small></div>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex={"auto"}>
                            <div style={{ "width": Math.min(received_max_width, 100) + "%" }}>
                                <Row align="middle">
                                    <Col flex={"auto"} style={{ "height": "20px" }}>
                                        {/* Previous Gain */}
                                        {drawReceived()}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div className="shim" />
                </>
            </conditional.true>

        </>
    )
}