import React, { useState } from "react";
import { Upload, Table, Spin, Input, Tabs, Form, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { apiCall } from "../../utils/Api";
import { MenuOutlined } from '@ant-design/icons';
import DragTable from "../DragTable";

export default function ListUpload(props) {

    const { candelete = false, drag = false, resetSort = () => {}, setCanSave = () => {}, canSave=true, list_id, isbns, updateList = () => { } } = props;
    const [position, setPosition] = useState("list");
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState({});
    const [processed, setProcessed] = useState(isbns);
  

    

    const limit = 100;

    const [form] = Form.useForm(); 

    const uploadFile = (_file, show_list = true) => {
        setUploading(true);
        setFile(_file);
        apiCall("titlelist/importFile", { file: _file.file, list_id: list_id }, (_status, _result) => {
            if (_status) {
                setProcessed(_result.result.titles);
                form.setFieldsValue({txt : drawText(_result.result.titles)})
                if (show_list) {
                    setPosition("list");
                }
                updateList(_result.result.titles.map(item => { return item.isbn} ));
            } else {
                setPosition("error");
            }
            setUploading(false);
            setCanSave(true);

        }, "customer");
    }

    const placeholder = "Type or paste ISBNs, EANs, or UPCs here. By default the contents will be listed in the order they are inputed, unless otherwise specified in the 'sort by' section below. \n\nexample: \n9780062351357 \n9780062304087 \n9780062063625";


    const removeItem = (f) => {

        let m = [...processed];
        let _index = m.findIndex(item => item.isbn === f.isbn);
        if(_index > -1){
            // found
            m.splice(_index, 1);
            setProcessed(m);
            updateList(m.map(item => { return item.isbn} ));

        }

    }

    const columns = [
        {
            title: <small></small>,
            dataIndex: 'eisbn',
            key: 'eisbn',
            width: 50,
            render: (e) => {
                return <div style={{"height" : "34px"}}><img className="lightshadow" onError={(e) => e.target.src = "/no-cover.png"} style={{ "maxHeight": "34px", "maxWidth": "30px" }} src={"https://bookmanager.com/i/slider_23.php?b=" + e} /></div>
            }
        },
        {
            title: <small>ISBN</small>,
            dataIndex: 'isbn',
            key: 'isbn',
            width: 150
        },
        {
            title: <small>Title</small>,
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: <small></small>,
            dataIndex: 'success',
            key: 'success',
            render: (e) => {
                return (e === false) ? <span style={{"color" : "#f00"}}>* Title not found</span> : ""
            }
        },
    ];

    if(drag && (processed.length <=limit)){
        columns.unshift(
            {
                title: <small></small>,
                dataIndex: 'success',
                width : "30px",
                key: 'success',
                render: (e) => {
                    return <div>&nbsp;<MenuOutlined style={{"cursor" : "move"}} /></div>
                }
            }
        )
    }

    if(candelete){
        columns.push(
            {
                title: <small></small>,
                dataIndex: 'success',
                width : "80px",
                key: 'success',
                render: (e, f) => {
                    return <div><Button onClick={() => removeItem(f)} danger size="small"><small>Remove</small></Button></div>
                }
            }
        )
    }



    const drawText = (_arr) => {
        let _txt = "";
        _arr.map((item, index) => {
            return { key: index, title: item.title, eisbn: item.eisbn, isbn: item.isbn, status: item.success }
        }).forEach(itm => {
            let title = (itm.status !==false) ? itm.title : "* Title not found";
            _txt += itm.isbn + "       " + title + "\n";
        });
        return _txt;
    }

    const drawTable = () => {

        


        if(drag && (processed.length <=limit)){

           return <DragTable callback={(arr) => {
            
            resetSort(); 
            updateList(arr.map(item => { return item.isbn} ));
           }} rowKey={"isbn"} className="bc" scroll={{ y: 255 }} size="small" style={{ "border": "1px solid" }} data={processed} setData={setProcessed} columns={columns} />
            
        }

        return (
            <Table rowKey={"isbn"} pagination={(processed.length <=limit) ? false : {pageSize : limit, showSizeChanger : false}} className="bc" scroll={{ y: 255 }} style={{ "border": "1px solid" }} columns={columns} dataSource={processed} size="small"></Table>
        )
    }

    const generateFile = (_text) => {
        var filename = 'pasted.txt';
        let _file = new File([new Blob([_text])], filename)
        let f = {}
        f.file = _file;
        uploadFile(f, false);
    }

    const drawUpload = () => {
        return (
            <>
                <div style={{ "height": "296px" }}>
                    <Upload.Dragger accept=".txt, .pdf, .xls, .xlsx, .html, .csv" showUploadList={false} customRequest={(e) => uploadFile(e)} style={{ "padding": "0px 20px", "height": "300px" }}>
                        <div >
                            {(uploading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                        </div>
                        <p className="ant-upload-text">Click or drag to upload</p>
                        <p className="ant-upload-hint">
                            Upload an Excel file (.xls or .xlsx) or tab delimited (.txt) with the header "isbn"
                        </p>
                    </Upload.Dragger>
                </div>
            </>
        )
    }

    const drawProcess = () => {
        return (
            <>
                {drawTable()}
            </>
        )
    }

    return (
        <>
            
            <Tabs className="itemTabs"  onChange={(e) => setPosition(e)} size="small" activeKey={position} type="card" >
                <Tabs.TabPane tab="List" key="list">
                    {drawProcess()}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Text" key="text">
                    <Spin spinning={(uploading)}>
                        <Form form={form}>
                            <Form.Item name="txt" noStyle>
                                <Input.TextArea style={{"fontFamily" : "ApercuMono"}} onFocus={() => setCanSave(false)} onBlur={(e) => generateFile(e.target.value)} placeholder={placeholder} defaultValue={drawText(processed)} rows={13} />
                            </Form.Item>
                        </Form>
                        
                        <div className="shim" />
                        <div style={{"float" : "right"}}><Button disabled={(canSave)} type="primary" size="small"><small>Update</small></Button></div>
                        <br clear="all" />
                    </Spin>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Import" key="import">
                    {drawUpload()}
                </Tabs.TabPane>
            </Tabs>
        </>
    )

}