import React, { useEffect, useState } from "react";
import { Typography, Divider, Space, Input, message, Spin, Switch } from "antd";
import { apiCall } from "../../../utils/Api";
import ImpluseManager from "../../../pages/settings/ImpluseManager";

export default function ImpulseSection(props) {

    const { triggerImpulse = false, setTriggerImpulse = () => { }, changed = false, setChanged = () => { } } = props;
    const [loading, setLoading] = useState(true);

    const [pickup, setPickup] = useState({
        enabled: false,
        headline: "",
        description: "",
        isbns_info: []
    })

    const [ship, setShip] = useState({
        enabled: false,
        headline: "",
        description: "",
        isbns_info: []
    })

    const save = () => {

        const _pickup = {
            type: "pickup", 
            enabled: pickup.enabled,
            headline: (pickup.headline) ? pickup.headline : "",
            description: (pickup.description) ? pickup.description : "",
            isbns_info_json: JSON.stringify(pickup.isbns_info)
        }

        const _ship = {
            type: "ship", 
            enabled: ship.enabled,
            headline: (ship.headline) ? ship.headline : "",
            description: (ship.description) ? ship.description : "",
            isbns_info_json: JSON.stringify(ship.isbns_info)
        }

        setLoading(true);

        apiCall("impulse_buy/set", _pickup, (_status, _result) => {
            if (_status) {
                apiCall("impulse_buy/set", _ship, (_s, _r) => {
                    if(_s){
                        setShip(_r.ship);
                        setPickup(_r.pickup);
                    } else {
                        message.error(_result.error)
                    }
                    setLoading(false);
                    setTriggerImpulse(false);
                    setChanged(false)
                })
            } else {
                message.error(_result.error)
                setLoading(false);
                setTriggerImpulse(false);
                setChanged(false)
            }
        })
    }


    useEffect(() => {
        if (triggerImpulse) {
            save();
        }

    }, [triggerImpulse]);

   

    const getImpulse = () => {
        setLoading(true)
        apiCall("impulse_buy/getAll", {}, (_status, _result) => {
            if (_status) {
                setShip(_result.ship);
                setPickup(_result.pickup);
            } else {
                message.error(_result.error)
            }
            setLoading(false)
        })
    }

    useEffect(getImpulse, [])


    const u = {
        type: "pickup", // ship
        enabled: true,
        headline: "Pickup",
        description: "Extra stuff to get at pickup",
        isbns_info_json: JSON.stringify([
            {
                isbn: "9781338730920",
                description: "Hey Tobias!"
            }
        ])
    }



    const ImpulseOption = (props) => {

        const { option = {}, setOption = () => { }, type = "pickup", setChanged = () => { } } = props;

        const {
            enabled = false,
            headline = "",
            description = "",
            isbns_info = []
        } = option;

        const updateEnabled = (_f) => {
            setOption({ ...option, "enabled": _f });
            if(!changed) {setChanged(true)}
        }

        const updateHeadline = (_f) => {
            setOption({ ...option, "headline": _f })
            if(!changed) {setChanged(true)}
        }

        const updateDescription = (_f) => {
            setOption({ ...option, "description": _f })
            if(!changed) {setChanged(true)}
        }

        const updateTitles = (_f) => {
            setOption({ ...option, "isbns_info": _f })
            if(!changed) {setChanged(true)}
        }

        const label = (type === "pickup") ? "In-Store Pick Up / Guest Checkout" : "Ship / Local Delivery"

        return (
            <>
                <Divider dashed />
                <Space align="start" size={15}>
                    <div style={{ width: "28px" }}><Switch checked={enabled} onChange={(e) => updateEnabled(e)} size="small" /></div>
                    <div id={(type === "pickup") ? "enable_impulse_purchase_for_in_store_pick_up_guest_checkout" : "enable_impulse_purchase_for_ship_local_delivery"}>
                        <Typography.Title level={5}>Enable Impulse Purchase For {label}</Typography.Title>
                        <Divider dashed style={{ "margin": "15px 0px" }} />
                        <Spin indicator={<></>} spinning={!enabled}>

                            <Space size={15} align="start">
                                <div style={{ "width": "300px" }}>
                                    <div className="shim" />
                                    <label>Headline...</label>
                                    <Input key={"headline"} defaultValue={headline} onBlur={(e) => updateHeadline(e.target.value)} placeholder="Headline..." />
                                    <label>Description</label>
                                    <Input.TextArea key={"textarea"} defaultValue={description} onBlur={(e) => updateDescription(e.target.value)} rows={5}></Input.TextArea>
                                </div>
                                <div>
                                    <ImpluseManager onChange={(_f) => updateTitles(_f)} bottom={true} titles={isbns_info} title={<span style={{ "fontSize": "14px", "fontWeight": "normal" }}>{label}</span>} />
                                </div>
                            </Space>
                        </Spin>
                    </div>
                </Space>
                <div className="shim" /><div className="shim" />
            </>

        )
    }



    return (
        <>



            <Spin spinning={loading}>
                <div id="titles" style={{ "padding": "10px 30px" }}>
                    <Typography.Title level={4}>Impulse Purchase</Typography.Title>
                    <Divider />
                    <Space align="start" size={15}>
                        <div style={{ width: "28px" }}>&nbsp;</div>
                        <div id="impulse_purchase_pop_up">
                            <Typography.Title level={5}>Impulse Purchase Pop-Up</Typography.Title>
                            <Typography.Paragraph type="secondary">
                                Enable this option to display a pop-up purchase menu when customers go to the cart. This could be used to solicit donations, feature event tickets or signed copies, or if you are required to / want to charge for bags.<br /><br />
                                <em>Credit card processing fees should not be set up using this feature, so please contact Bookmanager support if that is something you would like to configure online.</em><br /><br />
                                Multiple products can be added to the impulse pop-up. ASANs can be used, but must be created in the Bookmanager software first. If ASANs are used, title info, class (and therefore taxation), price, and an image need to be added in full. The impulse pop-up can be tied to specific order fulfillment options ( In-store Pick Up / Guest Checkout, Ship / Local Delivery). You can specify the headline and overview descriptive text for the impulse pop up.<br /><br />
                            </Typography.Paragraph>
                        </div>
                    </Space>

                    <ImpulseOption setChanged={setChanged} type={"pickup"} option={pickup} setOption={setPickup} />

                    <ImpulseOption setChanged={setChanged} type={"ship"} option={ship} setOption={setShip} />

                </div>
            </Spin >
        </>
    )
}