import { Checkbox, message, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { apiCall } from "../../utils/Api";

export default function EnableSetting(props) {

    const {
        setting_key = "using_promo_codes",
        label = "",
        callback = () => {},
        setCanFreeShip = () => {}
    } = props;

    const [enabled, setEnabled] = useState(false); 

    const getSettings = () => {
        apiCall("settings/get", {}, (_status, _result) => {
            if (_status) {
                
                // One off, for promo codes
                setCanFreeShip(_result["global:multiship_enabled"])

                setEnabled(_result[setting_key]);
                callback(_result[setting_key]); 
                
            } else {
                message.error(JSON.stringify(_result, null, 2))
            }
        })
    }

    useEffect(getSettings, []);

    const updateSetting = (_value) => {
        let _s = {}
        _s[setting_key] = _value;
        _s = JSON.stringify(_s);
        apiCall("settings/set", { settings: _s }, (_status, _result) => {
            if (_status) {
                console.log(_result[setting_key])
                setEnabled(_result[setting_key])
                callback(_result[setting_key]); 
            } else {
                message.error(_result.error)
            }
        })

    }

    return (<><Checkbox checked={enabled} onChange={(e) => updateSetting(e.target.checked)}><p>{label}</p></Checkbox></>)



}