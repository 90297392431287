import { Checkbox, Form, Input, InputNumber, Space } from "antd";
import React, { useEffect, useState } from "react";
import { slugify } from "../../utils/Utils";

export default function TextDateField(props) {

    const { data = {}, setSelected = () => { }, render = false, update = () => { }, form_obj = null } = props;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [d, setD] = useState(data);

    const {
        id = "",
        label = "Text",
        type = "text",
        description = "",
        max_length = 50,
        required = false,
        required_message = "Phone number is required"
    } = d;


    const [init, setInit] = useState(true);

    const setValue = (_v) => {
        if (form_obj) {
            form_obj.setFieldsValue({ [id]: _v })
            form_obj.validateFields([id]);
        }
        return (_v);
    }

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');


    const updateHiddenField = () => {
        const fullDate = `${month}${day}${year}`;
    
        if(init){
            setInit(false);
            return; 
        }
       
        setValue(fullDate)
    };

    useEffect(() => {

        updateHiddenField(); 
    }, [day, month, year])


    const validateDate = (rule, value) => {

        
        if(value === ""){
            return Promise.resolve();
        }

        if (!value && form_obj.getFieldValue([id])) {
            return Promise.reject('Please enter a valid date');
        }
        if (value.length !== 8) {
          return Promise.reject('Please enter a valid date');
        }
        return Promise.resolve();
      };


    const handleDayChange = (e) => {
        const { value, keyCode } = e.target;

        if (value === '') {
            document.getElementById(id.toString()+'monthInput').focus();
            setDay(value);
            return;
          }

        if (value.length <= 2 && /^\d*$/.test(value)) {
            setDay(value);
            if (value.length === 2) {
                document.getElementById(id.toString()+'yearInput').focus();
            }
        }
    };

    const handleMonthChange = (e) => {
        const { value } = e.target;
        
        if (value.length <= 2 && /^\d*$/.test(value)) {
            setMonth(value);
            if (value.length === 2) {
                document.getElementById(id.toString()+'dayInput').focus();
            }
        }
    };

    const handleYearChange = (e) => {
        const { value } = e.target;
        if ( value === '') {
            document.getElementById(id.toString()+'dayInput').focus();
            setYear(value);
            return;
          }
        if (value.length <= 4 && /^\d*$/.test(value)) {
            setYear(value);
        }
    };




    useEffect(() => {
        if (!render) {
            update(d);
        }

    }, [d])



    if (render) {
        return (
            <>
                <Form.Item extra={props.data.description} rules={[{ required: props.data.required, message: props.data.required_message }, { validator: validateDate }]} label={props.data.label} name={id} >
                    <Input hidden maxLength={14} placeholder={props.data.placeholder} />
                    <Space>
                  
                    <Input
                        id={id.toString()+"monthInput"}
                        style={{ "width": "50px" }}
                        value={month}
                        onChange={handleMonthChange}
                        onKeyDown={(e) =>{
                            if((e.key==="Enter" || e.key==="Tab") && e.target.value.length === 1){
                               setMonth("0" + e.target.value.toString());
                               if(e.key==="Enter"){
                                document.getElementById(id.toString() + 'dayInput').focus();
                               }
                              
                            }
                        }}
                        maxLength={2}
                        placeholder="MM"
                        onFocus={(e) => e.target.select()}
                    />
                    <span> / </span>
                    <Input
                        id={id.toString()+"dayInput"}
                        style={{ "width": "50px" }}
                        value={day}
                        onChange={handleDayChange}
                        onKeyDown={(e) =>{
                            if((e.key==="Enter" || e.key==="Tab") && e.target.value.length === 1){
                               setDay("0" + e.target.value.toString());
                               if(e.key==="Enter"){
                                document.getElementById(id.toString() + 'yearInput').focus();
                               }
                              
                            }
                        }}
                        maxLength={2}
                        placeholder="DD"
                        onFocus={(e) => e.target.select()}
                    />
                    <span> / </span>
                    <Input
                        id={id.toString()+"yearInput"}
                        style={{ "width": "65px" }}
                        value={year}
                        onChange={handleYearChange}
                        maxLength={4}
                        placeholder="YYYY"
                        onFocus={(e) => e.target.select()}
                    />
                    </Space>
                </Form.Item>

                

            </>
        )
    }

    return (
        <>

            {/* LABEL */}
            <label >Label</label><div className="shim" />
            <Input value={label} onChange={(e) => setD({ ...d, "label": e.target.value })} placeholder="Label..." />
            <div className="shim" /><div className="shim" />

            {/* LABEL */}
            <label >Description / Instructions</label><div className="shim" />
            <Input value={description} onChange={(e) => setD({ ...d, "description": e.target.value })} placeholder="Description..." />
            <div className="shim" /><div className="shim" />

            {/* PLACEHOLDER */}
            {/* <label >Placeholder</label><div className="shim" />
            <Input value={placeholder} onChange={(e) => setD({ ...d, "placeholder": e.target.value })} placeholder="Placeholder..." />
            <div className="shim" /><div className="shim" /> */}

            {/* REQUIRED */}
            <div className="float-flex">
                <div>&nbsp;</div>
                <Checkbox onChange={(e) => setD({ ...d, "required": e.target.checked })} checked={required} >Required?</Checkbox>
            </div>

            {(required &&
                <>
                    {/* REQUIRED MESSAGE */}
                    <label>Required? Error Message</label ><div className="shim" />
                    <Input value={required_message} onChange={(e) => setD({ ...d, "required_message": e.target.value })} placeholder="Message..." />
                    <div className="shim" /><div className="shim" /> <div className="shim" /><div className="shim" />
                </>
            )}

        </>
    )
}