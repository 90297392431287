import React, { useEffect, useRef, useState } from "react";
import bm from "../../media/b.png";
import { Col, Input, Tooltip, message, Space } from "antd";
import { useSession } from "../../utils/Session";
import { apiCall } from "../../utils/Api";

import station_bg from "../../media/station.png";

export default function BMStation(props) {

    const stationRef = useRef(null); 
    const { isbn } = props;
    const [session, setSession] = useSession();
    const [station, setStation] = useState((session.station_id) ? session.station_id : "-");
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const changeStation = (_station) => {
        if (session.station_id.toString() === _station.toString()) { return; }
        setLoading(true);
        apiCall("bookmanager/setStationId", { station_id: (station && station !== "-") ? station : -1 }, (_status, _result) => {
            if (_status) {
                console.log("Station Set!")
                setLoading(false);
                openTitleInStation(isbn, "", _station, false);
                setSession(_result);
                
            }
        })
    }


    const updateStation = () =>{
        if(session.station_id != station){
            setStation(session.station_id);
        }
    }

    useEffect(updateStation, [session.station_id])

    const openTitleInStation = (_isbn, _condition = "", _station = session.station_id, _loading=loading) => {

       
        if (!_station && _station !== 0) {
            stationRef.current.focus();
            return;
        }

        if (isNaN(_station)) {
            return;
        }

        if (!_loading) {
            setLoading(true);
        }

        setSent(true);
        setTimeout(() => {
            setSent(false)
        }, 1000);

        apiCall("bookmanager/sendIsbn", { isbn: isbn }, (_status, _result) => {
            if (_status) {
                setLoading(false);
                message.success("Title sent!");
            }
        })

    }


    return (
        <div style={{ "width": "60px", "backgroundColor" : "#000" }}>
            <Space>

                <Tooltip title="View this title in Bookmanager at the specified station">
                    <div style={{ "padding": "0px 0px", "textAlign": "center", "width" : "20px" }}>
                        <img onClick={() => openTitleInStation()} alt="bookmanager" style={{ "filter": (session.station_id || session.station_id === 0) ? "grayscale(0%)" : "grayscale(100%)", "cursor": "pointer", "width": "22px", "position" : "relative", "left" : "4px", "top" : "-3px" }} src={bm} />
                    </div>
                </Tooltip>

                <div style={{ "padding": "1px 2px" }}>
                    <div className="bminput" style={{ "textAlign": "center", "fontWeight": "strong", "fontSize": "11px", "lineHeight": "20px" }}>
                        <Tooltip title="Bookmanager workstation # to show this title on">
                            <Input ref={stationRef} onFocus={(e) => e.target.select()} onBlur={(e) => changeStation(e.target.value)} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    changeStation(e.target.value);
                                }
                            }} value={(station !== "-") ? station : session.station_id} onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                    return;
                                } else {
                                    if (e.target.value.length > 2) {
                                        return;
                                    } else {
                                        setStation(e.target.value)
                                    }
                                }
                            }} size="small" style={{ "fontWeight": "bold", "fontSize": "10px", "lineHeight": "22px", "padding": "0px", "textAlign": "center", "marginTop": "1px" }} placeholder="?" />
                        </Tooltip>
                    </div>
                </div>

            </Space>



        </div>);

    return (
        <>
            <Col className="bc" flex={"30px"} style={{ borderRight: "1px solid" }}>
                <Tooltip title="View this title in Bookmanager at the specified station">
                    <div style={{ "padding": "2px 4px", "textAlign": "center" }}>
                        <img onClick={() => openTitleInStation()} alt="bookmanager" style={{ "filter": (session.station_id || session.station_id === 0) ? "grayscale(0%)" : "grayscale(100%)", "cursor": "pointer", "width": "19px" }} src={bm} />
                    </div>
                </Tooltip>
            </Col>
            <Col className="bc bcg5" flex={"30px"} style={{ borderRight: "1px solid" }}>
                <div style={{ "padding": "1px 2px" }}>
                    <div className="bminput" style={{ "textAlign": "center", "fontWeight": "strong", "fontSize": "11px", "lineHeight": "20px" }}>
                        <Tooltip title="Bookmanager workstation # to show this title on">
                            <Input onFocus={(e) => e.target.select()} onBlur={(e) => changeStation(e.target.value)} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.currentTarget.blur();
                                }
                            }} value={(station !== "-") ? station : session.station_id} onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                    return;
                                } else {
                                    if (e.target.value.length > 2) {
                                        return;
                                    } else {
                                        setStation(e.target.value)
                                    }
                                }
                            }} size="small" style={{ "fontWeight": "bold", "fontSize": "10px", "lineHeight": "22px", "padding": "0px", "textAlign": "center", "marginTop": "1px" }} placeholder="#" />
                        </Tooltip>
                    </div>
                </div>
            </Col>
        </>
    )




}