import React, { useEffect, useState } from "react";
import { Layout, message, Row, Col, Typography, Button, Radio, Divider, Upload } from "antd";
import LayoutHeader from "../layouts/partials/LayoutHeader";
import { apiCall, apiGetPath } from "../utils/Api";
import { cacheBuster } from "../utils/Utils";

export default function ReceiptLogoPage() {

    const [logoPath, setLogoPath] = useState(apiGetPath({}, "printer_logo/getLogo") + cacheBuster());
    const [ogPath, setOgPath] = useState("");
    const [uploading, setUploading] = useState(false);

    // calling resets cache so images update (image names don't change)
    const getOgPath = (_p) => {
        setOgPath("https://bookmanager.com/" + _p + "?cb=" + cacheBuster());
    }

    const getLogoPath = () => {
        setLogoPath(apiGetPath({}, "printer_logo/getLogo") + cacheBuster());
    }

    const [init, setInit] = useState(false);

    const [results, setResults] = useState({
        logo_path: "",
        printer_brand: "",
        dpi: "",
        resize_image: false,
        position: "",
        dither: false
    });


    const getSettings = () => {
        apiCall("printer_logo/getSettings", {}, (_status, _result) => {
            if (_status) {
                setResults(_result);
                setInit(true);
                getOgPath(_result.logo_path); 
            } else {
                message.error(_result.error);
            }
        })
    }

    useEffect(getSettings, []);


    const setSettings = () => {

        if (!init) {
            return;
        }

        apiCall("printer_logo/setSettings", results, (_status, _result) => {
            if (_status) {
                getLogoPath();
            } else {
                message.error(_result.error);
            }
        })
    }

    useEffect(setSettings, [results]);

    const uploadFile = (_file) => {
        setUploading(true);
        apiCall("printer_logo/upload", { file: _file.file, location: "/receipt_logo.png" }, (_status, _result) => {
            if (_status) {
                getLogoPath(); 
                getOgPath(results.logo_path); 
                message.success("File uploaded successfully.")
            } else {
                message.error("Error: " + _result.error);
            }
            setUploading(false);
        })
    }

    return (
        <>
            <Layout className="layout">
                <LayoutHeader
                    title="Receipt Printer Logo Converter"
                    description="Use this page to congfigure and generate logos for your receipt printer."
                />
                <Layout.Content>
                    <div style={{ "padding": "20px" }}>
                        <Typography.Title level={5}>Source image</Typography.Title>
                        <Divider style={{ "marginTop": "15px" }} dashed />
                        <Row gutter={70} align="middle">
                            <Col span={10}>
                                <div className="bc pattern" style={{ "backgroundImage": "url("+ogPath+")", "backgroundSize" : "contain", "backgroundRepeat": "no-repeat", "backgroundPosition": "center", "border": "1px solid", "width": "512px", "height": "250px", "backgroundColor": "#fdfdfd" }}></div>
                            </Col>
                            <Col span={14}>
                                <Typography.Title level={5}>Upload new image</Typography.Title>
                                <Typography.Paragraph type="secondary" >
                                    <div>Upload an image file, and use the converted image in your receipt printer to place your store's logo at the top of a receipt.</div>
                                    <div className="shim" /><div className="shim" />
                                    <div>Accepted: <strong>PNG, GIF, or JPG.</strong></div>
                                    <div>Recommended for best results: a pixel-perfect, two-colour black and white GIF or PNG image that is exactly 512 pixels wide.</div>
                                    <div className="shim" /><div className="shim" />
                                    <Upload className="hide-upload" customRequest={(e) => uploadFile(e)} accept=".png,.gif,.jpg"  >
                                        <Button loading={uploading} type="primary">Upload</Button>
                                    </Upload>
                                </Typography.Paragraph>

                            </Col>
                        </Row>
                        <br /><br />
                        <Typography.Title level={5}>Conversion Options</Typography.Title>
                        <Divider style={{ "margin": "15px 0px" }} dashed />
                        <Typography.Paragraph type="secondary" >
                            <div>These conversion options are provided as a convenience to show the store's logo on receipts in an aesthetically pleasing arrangement. <br />For best results, upload an image that meets the recommendations above, and choose 180dpi output quality.</div>
                        </Typography.Paragraph>
                        <Row gutter={10}>
                            <Col flex={"260px"}>
                                <div><strong>Receipt Printer Brand:</strong></div><div className="shim" />
                                <div><strong>Output quality:</strong></div><div className="shim" />
                                <div><strong>Resize logo image to fit receipt width:</strong></div><div className="shim" />
                                <div><strong>Image position if not resizing logo:</strong></div><div className="shim" />
                                <div><strong>Dither:</strong></div><div className="shim" />
                            </Col>
                            <Col flex={"300px"}>
                                <div><Radio checked>Bixolon</Radio></div><div className="shim" />
                                <div><Radio.Group value={results.dpi} onChange={(e) => setResults({ ...results, "dpi": e.target.value })} ><Radio style={{ "width": "80px" }} value={"90"}>90 dpi</Radio><Radio value={"180"}>180 dpi</Radio></Radio.Group></div><div className="shim" />
                                <div><Radio.Group value={results.resize_image} onChange={(e) => setResults({ ...results, "resize_image": e.target.value })}><Radio style={{ "width": "80px" }} value={true}>Yes</Radio><Radio value={false}>No</Radio></Radio.Group></div><div className="shim" />
                                <div><Radio.Group value={results.position} onChange={(e) => setResults({ ...results, "position": e.target.value })}><Radio style={{ "width": "80px" }} value={"left"}>Left</Radio><Radio value={"center"}>Center</Radio><Radio value={"right"}>Right</Radio></Radio.Group></div><div className="shim" />
                                <div><Radio.Group value={results.dither} onChange={(e) => setResults({ ...results, "dither": e.target.value })}><Radio style={{ "width": "80px" }} value={true}>Yes</Radio><Radio value={false}>No</Radio></Radio.Group></div><div className="shim" />
                            </Col>
                        </Row>
                        <br /><br />
                        <Typography.Title level={5}>Sample Image Output:</Typography.Title>
                        <Divider style={{ "margin": "15px 0px" }} dashed />
                        <div className="shim" /><div className="shim" />
                        <Row gutter={70} align="middle">
                            <Col span={10}>
                                <div className="bc pattern" style={{ "backgroundImage": "url(" + logoPath + ")", "backgroundSize" : "contain", "backgroundRepeat": "no-repeat", "backgroundPosition": "center", "border": "1px solid", "width": "512px", "height": "250px", "backgroundColor": "#fdfdfd" }}></div>
                            </Col>

                        </Row>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    );
}