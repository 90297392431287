import { Button, Checkbox, Form, Input, InputNumber, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { makeKey, slugify } from "../../utils/Utils";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

export default function CheckBoxField(props) {

    const { data = {}, setSelected = () => { }, render = false, update = () => { } } = props;

    const [d, setD] = useState(data);

    const {
        id = "",
        label = "Select",
        type = "select",
        description = "",
        required = false,
        required_message = "This field is required",
        checked = false,
    } = d;

    useEffect(() => {
        if (!render) {
            update(d);
        }
    }, [d])

    if (render) {
        return (
            <Space align="start">
                {(props.data.required && <span style={{"color" : "red"}}>*</span>)}
                <Form.Item rules={[{ required: props.data.required, message: props.data.required_message }]} valuePropName="checked" extra={props.data.description} name={id} >
                    <Checkbox >{props.data.label}</Checkbox>
                </Form.Item>
            </Space>
        )
    }

    return (
        <>
            {/* LABEL */}
            <label >Label</label><div className="shim" />
            <Input value={label} onChange={(e) => setD({ ...d, "label": e.target.value })} placeholder="Label..." />
            <div className="shim" /><div className="shim" />
            {/* LABEL */}
            <label >Description / Instructions</label><div className="shim" />
            <Input value={description} onChange={(e) => setD({ ...d, "description": e.target.value })} placeholder="Description..." />
            <div className="shim" /><div className="shim" />

            {/* REQUIRED */}
             <div className="float-flex">
                <div>&nbsp;</div>
                <Checkbox onChange={(e) => setD({ ...d, "required": e.target.checked })} checked={required} >Required?</Checkbox>
            </div>

            

            {(required &&
                <>
                    {/* REQUIRED MESSAGE */}
                    <label>Required? Error Message</label ><div className="shim" />
                    <Input value={required_message} onChange={(e) => setD({ ...d, "required_message": e.target.value })} placeholder="Message..." />
                    <div className="shim" /><div className="shim" /> <div className="shim" /><div className="shim" />
                </>
            )}
        </>
    )
}