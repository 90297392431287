import { Checkbox, Space, Tooltip } from "antd";
import React from "react";
import { apiCall } from "../../utils/Api";
import { useSession } from "../../utils/Session";
import { useEffect } from "react/cjs/react.production.min";

// Display a list of their quick picks - if a list_id is passed, only show the checkbox. 
export default function ListQuickPick(props) {

    const { list_id = false, my_lists = [], isbn = "", list = {}, updateMyListsInParent = () => {}} = props;
    const [session, setSession] = useSession();

    // https://tbraunapi.bookmanager.com/store/titlelist/toggleQuickPick?session_id=vW8vhiPdsd2a1sh4Mnm0e6FumFnC&store_id=101069&list_id=415328&is_picked=true
    const toggleQuickPick = (_checked) => {
        apiCall("titlelist/toggleQuickPick", { list_id: list_id, is_picked: _checked }, (_status, _result) => {
            if (_status) {
                setSession(_result);
            }
        })
    }

    //this function uses the callback to keep my_lists in sync with ListCount.js
    const updateMyLists = (_record) => {
        let _my_lists = [...my_lists];
        if(!_my_lists.includes(_record)){
            _my_lists.push(_record);
        }else{
            _my_lists.splice(_my_lists.indexOf(_record), 1);
        }
        updateMyListsInParent(_my_lists);
    }

    const toggleItem = (_add, _isbn, _list_id) => {        
        if (_add) {
            apiCall("titlelist/addItemToList", { isbn: _isbn, list_id: _list_id }, (_status, _result) => {
                updateMyLists(_result.actual_id);
            })
        } else {
            apiCall("titlelist/removeItemFromList", { isbn: _isbn, list_id: _list_id }, (_status, _result) => { 
                updateMyLists(_result.actual_id);
            })
        }
    }

    //this is called by ListCount when the QP button is clicked. 
    //checks to see if the list being selected already exists within the session variable
    if (list_id) {
        let found = session.quick_pick_lists.some(item => item.list_id === list_id); 
        return (
            <>
                <Checkbox checked={(found)} onChange={(e) => toggleQuickPick(e.target.checked)} disabled={(list.is_dynamic)} />
            </>
        )
    }

    //this is called by Title.js when the checkbox is clicked, calling toggleItem
    //to set it into a list or remove it from one
    return (
        <>
            <Space size={0} direction="vertical">
                {session.quick_pick_lists.map((item, index) => {
                    return (<div key={index}><Checkbox onChange={(e) => toggleItem(e.target.checked, isbn, item.list_id)} checked={(my_lists.includes(item.actual_id))} className="mini-checkbox" />&nbsp; <small>{item.name}</small></div>);
                })}
            </Space>
            <br />
        </>
    )

}