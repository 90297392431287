import { Col, Divider, Row, Space, Tooltip } from "antd";
import React from "react";
import { useSession } from "../../utils/Session";
import { clamp, getSan, sum } from "../../utils/Utils";

export default function YearOverYear(props) {

    const { title = "", overall_change = 0, volume = [], height = 120 } = props;
    const pers = ["100+", "75", "50", "25", "0", "-25", "-50", "-75", "-100"];

    const [session, setSession] = useSession();
    let san = getSan(session.corp_id, window.sitesettings.stores);

    const my_pos = volume.findIndex(item => item.me);
    const avg = sum(volume, "change") / volume.length;
    const avg_vol = sum(volume, "volume") / volume.length;



    const calculateChange = (_change, _buffer) => {
        let calculated = 120 * (_change / 100);
        calculated = 120 - calculated;
        calculated = calculated - _buffer;
        console.log(calculated)
        return calculated.toString() + "px";
    }

    const getWidth = (_vol) => {
        let w = 1;
        if (_vol > (.66 * avg_vol)) {
            w = 2
        }
        if (_vol > (1.33 * avg_vol)) {
            w = 5
        }
        return w;
    }

    let box_width = 0;

    volume.forEach(element => {
        box_width += getWidth(element.volume);
    });

    const drawRow = (item, index, flip) => {

        let alt = (index % 2);
        let pixel = 0;
        let negative = false;
        if (item.change < 0) {
            pixel = (item.change * -1) * height;
        } else {
            pixel = item.change * height;
        }

        let _style = {
            position: "relative",
        }

        if (my_pos > volume.length / 2) {
            _style.textAlign = "center";
            _style.transform = "scaleX(-1)";
        } else {

            _style.textAlign = "center";
        }


        let w = getWidth(item.volume);




        pixel = clamp(pixel, 0, height);

        if (item.me) {

            if (flip) {
                return (
                    <Space size={0} direction="vertical">
                        <div style={{ "width": w + "px", "height": pixel + "px", "backgroundColor": (alt) ? "#005191" : "#005191", "position": "relative", "bottom": "0px" }}>&nbsp;</div>
                        <div className="grid-line" style={{ "width": w + "px", "height": height - pixel + "px", "display": "flex", "alignItems": "end" }}>&nbsp;</div>
                        <div style={{ "height": "20px", "width": w + "px" }}>
                            <div style={_style}><nobr><span style={{ "color": "#ddd" }}></span><strong style={{ ..._style, "display": "inline-block" }}>{san} ({(item.change * 100).toFixed(2)}%)</strong></nobr></div>
                        </div>
                    </Space>
                )
            }
            return (
                <Space size={0} direction="vertical">
                    <div style={{ "height": "15px", "width": "2px" }}>
                        <div style={_style}><nobr><span style={{ "color": "#ddd" }}></span><strong style={{ ..._style, "display": "inline-block" }}>{san} ({(item.change * 100).toFixed(2)}%)</strong></nobr></div>
                    </div>
                    <div className="grid-line" style={{ "width": w + "px", "height": height - pixel + "px" }}>&nbsp;</div>
                    <div style={{ "width": w + "px", "height": pixel + "px", "backgroundColor": (alt) ? "#005191" : "#005191", "position": "relative", "bottom": "0px" }}>&nbsp;</div>
                </Space>
            )
        }

        return (
            <div style={{ "width": w + "px", "position": "relative", "top": "0px", "height": pixel + "px", "backgroundColor": (alt) ? "#4fac60" : "#a6d5af" }}>&nbsp;</div>
        )

    }

    return (
        <>
            <div style={{ "width": "800px" }}>
                <strong style={{ "fontSize": "13px" }}>{title}</strong>
                <div>Each vertical bar represents one store and the % increase or decrease over the same period in the previous year</div>
                <div>The width of each bar represents the store'e relative size (small, medium, large)</div>
                <div>Your store is represented by the blue bar</div>
                <Divider dashed style={{ "margin": "10px 0px" }} />
                <div>
                    <Space style={{ "cursor": "pointer" }}>
                        <Tooltip title={<small>Your sales change year over year.</small>}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#3273a6" }}></div>
                                <div style={{ "opacity": "0.7" }}>Your store</div>
                            </Space>
                        </Tooltip>
                        <Tooltip title={<small>Others sales change year over year.</small>}>
                            <Space>
                                <div style={{ "width": "10px", "height": "5px", "backgroundColor": "#72bc7f" }}></div>
                                <div style={{ "opacity": "0.7" }}>Other stores</div>
                            </Space>
                        </Tooltip>
                    </Space>
                </div>
                <br clear="all" />
                <Row>
                    <Col className="bc" flex={"50px"}>
                        {pers.map((item, index) => {
                            return (<div key={index} style={{ "height": "30px", "lineHeight": "30px" }}>{item}%</div>)
                        })}
                    </Col>
                    <Col flex={box_width + "px"}>
                        <div style={{ "position": "absolute", "zIndex": "1", "top": calculateChange(overall_change, 7.5) }}>
                            <Space>
                                <div style={{ "width": box_width + "px", "borderBottom": "1px solid #3273a6" }}>&nbsp;</div>
                                <div>
                                    <div style={{ "marginTop": "15px", "color": "#3273a6" }}>
                                        <nobr><strong>{overall_change.toFixed(1)}%</strong></nobr>
                                        <div style={{"position" : "absolute", "width" : "80px", "lineHeight" : "12px"}}>
                                        average change of all stores
                                        </div>
                                    </div>
                                </div>
                            </Space>
                        </div>
                        <div className="grid-lines" style={{ "height": "250px", "marginTop": "15px", width : (box_width + 1) + "px"}}>
                            <div>
                                <Row>
                                    <Col flex={"auto"}>
                                        <div style={{ "marginTop": "120px" }}>
                                            <div style={{ "height": "120px" }}>
                                                <Space align="start" size={0}>
                                                    {volume.filter(item => item.change < 0).map((item, index) => drawRow(item, index, true))}
                                                </Space>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col flex={"auto"}>
                                        <div style={{ "height": "120px", "display": "flex", "alignItems": "end" }}>
                                            <Space align="end" size={0}>
                                                {volume.filter(item => item.change >= 0).map((item, index) => drawRow(item, index, false))}
                                            </Space>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}