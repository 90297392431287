import React, { useEffect, useState } from "react";
import { useSession } from "../../utils/Session";

import barcode from "../../media/barcode.png";
import qrcode from "../../media/qr.png";
import { cacheBuster, determineArticle } from "../../utils/Utils";
import { apiCall } from "../../utils/Api";
export default function EmailPreview(props) {

    //https://bookmanager.com/i/qr?d=https://mosaicbooks.ca/giftcard/whatever
    const { results = {}, cards = [] } = props;

    const {
        emailed_label = "",
        emailed_rounded_corners = true,
        emailed_show_balance_link = true,
        emailed_show_store_name = true,
        emailed_disclaimer = "",
    } = results;

    const qr_url = "https://bookmanager.com/i/qr?d=" + window.sitesettings.url + "gift-cards";
    const card = cards[0]
    const card_path = "https://bookmanager.com/i/m.php?b=" + card.encrypted_code + "&cb=" + cacheBuster();
    const [session, setSession] = useSession();
    const store_obj = window.sitesettings.stores.find(item => item.cid === session.corp_id);
    const store_name = session.store_name;
    const disclaimer_text = "For use in-store and online.  This card is redeemable for merchandise only. Gift cards are not redeemable for cash and cannot be replaced if stolen or rdeemed by an unintended recipient. Returns for card purchases are credited back to a gift card. This card does not epire and no fees are charged fro redemption."



    const [storeInfo, setStoreInfo] = useState({
        name : "",
        phone : "",
        phone_2 : "",
        toll_free : "",
        fax : "",
        email : "",
        city : "",
        address : "",
        province : "",
        postal_code : "",
        country : "",
        twitter : "",
        facebook : "",
        instagram : "",
        tiktok : "",
        url : "",
        footer_message : "",
    }); 

    useEffect(() => {

        apiCall("store/getSettings", {webstore_name : session.webstore_name}, (_status, _result) => {
            if(_status){
                setStoreInfo(_result.store_info);
            }
        }, "customer")
    }, []);


    const _store = {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "24px",
        marginTop: "0px"
    }

    const _break = {
        borderBottom: "1px solid #eee",
        margin: "0px 25px"
    }

    const _heading = {
        textAlign: "center",
        fontSize: "26px",
        margin: "25px 50px"
    }

    const _message = {
        textAlign: "left",
        fontSize: "20px",
        background: "#f8f8f8",
        borderRadius: "10px",
        padding: "20px 30px",
        margin: "25px 50px"
    }

    const _amount = {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "70px",
        lineHeight: "80px"
    }

    const _text = {
        textAlign: "center",
        fontSize: "18px",
        padding: "0px 80px"
    }

    const _disclaimer = {
        textAlign: "center",
        fontSize: "14px",
        color : "#262626"
    }

    const _card = {
        maxWidth: "400px",
        borderRadius: (emailed_rounded_corners) ? "25px" : "0px",
        margin: "25px 50px"
    }

    const _barcode = {
        border: "2px solid #eee",
        borderRadius: "7px",
        width: "354px",
        margin: "0 auto",
        padding: "32px 36px",
        paddingBottom : "20px"
    }

    const _qrcode = {
        margin: "0 auto",
        width: "174px"
    }

    const drawQRCode = () => {
        return (
            <div style={_qrcode}>
                <img src={qr_url} />
            </div>
        )
    }

    const drawBarcode = () => {
        return (
            <div style={_barcode}>
                <img src={barcode} />
                <div className="shim" /><div className="shim" />
                <em style={{"color" : "#f12a46","fontSize" : "13px"}}><strong>PIN IS REQUIRED FOR ONLINE REDEMPTION</strong></em>
            </div>
        )
    }

    const drawCard = () => {
        return (
            <div>
                <img onError={(e) => {e.target.style.display = 'none'}} style={_card} src={card_path} />
            </div>
        )
    }

    const drawLogo = (_webstore_name = session.webstore_name) => {
        return (
            <div style={{ "textAlign": "center" }}>
    
                <img onError={(e) => {e.target.style.display = 'none'}} style={{ "maxWidth": "100%" }} src={"https://bookmanager.com/i/" + _webstore_name + "/letterhead_logo.jpg"} />
            </div>
        );
    }

    const stripProtocolAndSlashes = (url) => {
        let _remove_protocol = url.replace(/^https?:\/\//i, '');
        let _remove_slashes = _remove_protocol.replace(/\//g, '');
        return _remove_slashes;
      }

    return (
        <>
            <div style={{ "background": "#ededed", "padding": "40px", "fontFamily": "Poppins" }}>
                <div style={{ "width": "500px", "background": "#fff", "padding": "0px", "color" : "#262626" }}>
                    {drawLogo()}
                    {(emailed_show_store_name && <div style={_store} >{store_name}</div>)}
                    <div style={_break}>&nbsp;</div>
                    <div style={_heading}>Lucky you, <strong>Trevor</strong> has sent you {determineArticle(emailed_label)} {emailed_label}!</div>
                    <div style={_message}>
                        <div>Shauna,</div>
                        <div>Happy birthday! Don't spend it all on Elliott. Spend it all on ME!!</div>
                    </div>
                    {drawCard()}
                    <div style={_amount}>
                        $150.00
                    </div>
                    <div style={_text}>Current Balance</div>
                    <div style={_break} >&nbsp;</div>
                    <div style={_heading}>
                        <strong>{emailed_label}</strong>
                    </div>
                    {drawBarcode()}
                    <br /><br />
                    <div style={_text}>
                        Type the above GC number into the gift card redemption area during checkout at <strong><a href={window.sitesettings.url} target="_blank">{stripProtocolAndSlashes(window.sitesettings.url)}</a></strong>, or present the code or printed barcode in-store to redeem.
                    </div>
                    <br /><br />
                    {(emailed_show_balance_link && <>
                        <div style={_text} >
                            <a href={window.sitesettings.url + "gift-cards"} target="_blank"><strong>Click here</strong></a> to check your balance online, or use your phone's camera to scan the QR code below to go to the online balance checker.
                            <div><img /></div>
                        </div>
                        {drawQRCode()}
                        <br /><br />

                    </>)}


                </div>
                <br />
                <div style={_disclaimer}>
                    {emailed_disclaimer}
                </div>
                <br />
                <div style={_disclaimer}>
                    <div><strong>{storeInfo.name}</strong></div>
                    <div>{storeInfo.address}</div>
                    <div>{storeInfo.city}, {storeInfo.province} | {storeInfo.postal_code}</div>
                    <div>&nbsp;</div>
                    {(storeInfo.phone && <div><strong>{storeInfo.phone}</strong></div>)}
                    {(storeInfo.email && <div><strong><a href={"mailto:" + storeInfo.email}>{storeInfo.email}</a></strong></div>)}
                    {(storeInfo.url && <div><strong><a href={storeInfo.url}>{storeInfo.url}</a></strong></div>)}
                </div>
            </div>

        </>
    )
}