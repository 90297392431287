import React, { useState, useEffect } from "react";
import { Row, Col, Form, Upload, Typography, Divider, Space, Button, Switch, Input, DatePicker, message, Alert, Spin } from "antd";
import moment from "moment";
import 'moment-timezone';
import Editor from "react-medium-editor";
import TitleManager from "../pages/settings/TitleManager";
import { apiCall } from "../utils/Api";
import { PlusOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import ImageSelect from "../utils/ImageSelect"
import { useSession } from "../utils/Session";
import TextEditor from "../components/utilities/TextEditor";


export default function Event(props) {

    const { updateRow = () => { }, close = () => { }, create = false } = props;
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [flagRemoved, setFlagRemoved] = useState(false);
    const [imagePath, setImagePath] = useState("/temp");
    const [ticket, setTicket] = useState("");
    const [session, setSession] = useSession();

    const [event, setEvent] = useState(
        {
            event_created: 0,
            event_end: moment().add(1, "day").format("YYYY-MM-DD HH:mm:ss"),
            event_id: 0,
            event_start: moment().format("YYYY-MM-DD HH:mm:ss"),
            name: "",
            info: {
                description: "",
                img_path: "",
                isbns: [],
                tickets: [],
                length: 0,
                name: "",
            },
            on_expire: "",
            updated: 0,
            views: 0,
            visibility: 0,
            visible_after: moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
        }
    )

    const [form] = Form.useForm();


    const addTicket = (_isbn) => {
        if (!event.info.tickets.includes(_isbn)) {
            let _arr = [...event.info.tickets];
            _arr.push(_isbn);
            setEvent({ ...event, "info": { ...event.info, "tickets": _arr } })
        }
    }

    const removeTicket = (_isbn) => {
        let _arr = [...event.info.tickets];
        _arr = _arr.filter(itm => itm.toString() !== _isbn.toString())
        setEvent({ ...event, "info": { ...event.info, "tickets": _arr } })
    }


    const saveEvent = (_f) => {

        let obj = {
            name: _f.name,
            description: _f.description,
            on_expire: (_f.display_expired) ? "display" : "archive",
        }


        obj.start = _f.duration[0].format("YYYY-MM-DD HH:mm:ss");
        obj.end = _f.duration[1].format("YYYY-MM-DD HH:mm:ss");

        if (event.event_id) {
            obj.event_id = event.event_id;
        }

        obj.visible_after = _f.visible_after.startOf('day').format("YYYY-MM-DD HH:mm:ss");

        if (event.info.tickets.length > 0) {
            obj.tickets = event.info.tickets.join();
        } else {
            obj.tickets = "";
        }

        if (_f.visibility) {
            obj.visibility = "visible";
        } else {
            obj.visibility = "hidden";
        }

        if (_f.titles) {
            obj.isbns = _f.titles.map(item => item.isbn);
        } else {
            obj.isbns = [];
        }

        if (event.info.img_path) {
            obj.img_path = event.info.img_path;
        } else {
            obj.img_path = "";
        }

        if (flagRemoved) {
            obj.img_path = "";
        }

        console.log(event);

        apiCall((create) ? "events/add" : "events/edit", obj, (_status, _result) => {
            if (_status) {
                message.success("Event saved successfully.");
                if (flagRemoved) {
                    removeImage();
                }

                updateRow(_result);
                close();
            } else {
                message.error(_result.error)
            }
        })
    }


    const getEvent = () => {

        if (!props.event_id) {
            setLoading(false);
            return;
        }

        apiCall("events/getDetails", { event_id: props.event_id }, (_status, _result) => {
            if (_status) {
                // info has a variable object structure
                let _info = Object.assign({ ...event.info }, _result.info);
                _result.info = _info;
                setEvent(_result);
            }
            setLoading(false);
        })
    }

    useEffect(getEvent, [])

    const removeImage = () => {
        let _img = event.info.img_path.split("/");
        _img = _img[_img.length - 1];

        apiCall("files/rm", { location: "/events_images/" + _img }, (_status, _result) => {
            if (_status) {
                console.log(_result);
                setEvent({ ...event, "info": { ...event.info, "img_path": "" } })
            } else {
                message.error("There was an error.")
            }
        })
    }

    const uploadImage = (_file) => {
        setUploading(true);
        apiCall("files/upload", { location: "/events_images/" + _file.file.name, file: _file.file }, (_status, _result) => {
            if (_status) {
                setLoading(false);
                setEvent({ ...event, "info": { ...event.info, "img_path": _result.path } })
            } else {
                message.error("There was an error.")
            }
        })
    }

    const updateImage = (_imgpath) => {
        setEvent({ ...event, "info": { ...event.info, "img_path": _imgpath } })
    }


    const drawEdit = () => {

        return (
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>{(create) ? "Create new event" : "Edit event"}</Typography.Title>
                <Divider />
                <Form form={form} onFinish={(_f) => saveEvent(_f)} layout="vertical">


                    <Row gutter={30}>

                        <Col span={12}>
                            <Form.Item initialValue={event.name} rules={[{ required: true, message: "Event name required." }]} label="Event name" name="name">
                                <Input placeholder="Event name..." />
                            </Form.Item>

                            <Form.Item initialValue={[moment(event.event_start), moment(event.event_end)]} name="duration" label="Date and Time">
                                <DatePicker.RangePicker style={{ "width": "100%" }} format="MMM Do YYYY - h:mma" showTime />
                            </Form.Item>
                        </Col>

                        <Col span={5}>

                            <Form.Item initialValue={(event.visibility)} valuePropName="checked" name="visibility" label="Event visible?">
                                <Switch size="small" />
                            </Form.Item>

                            <Form.Item initialValue={(event.on_expire === "display")} valuePropName="checked" name="display_expired" label="Display upon expiry?">
                                <Switch size="small" />
                            </Form.Item>

                        </Col>

                        <Col span={5}>
                            <Form.Item initialValue={(event.visible_after) ? moment(event.visible_after) : ""} name="visible_after" label="Visible from">
                                <DatePicker format="MMM Do YYYY" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <label for="duration" class="" title="Date and Time">Description</label>
                    <div className="shim" />
                    <TextEditor value={event.info.description} onChange={(e) => { form.setFieldsValue({ description: e }) }} />
                    <br /><br />


                    <Row gutter={30}>





                        <Col span={12}>


                            {/* <label>Event description</label>
                            <div><p>Select text for formatting/linking options</p></div>
                            <div className="ant-input" style={{ "padding": "10px", "borderRadius": "2px", "minHeight": "150px", "maxHeight": "150px", "overflow": "scroll" }}>
                                <Editor
                                    tag="p"
                                    forcePlainText={true}
                                    text={event.info.description}
                                    onChange={(e) => {
                                        form.setFieldsValue({ description: e })
                                    }}
                                    options={{ toolbar: { buttons: ['bold', 'italic', 'underline', 'anchor'] } }}
                                />
                            </div>
 */}

                            <Form.Item noStyle initialValue={event.info.description} name="description" label="Event description">
                                <Input.TextArea hidden placeholder="Event description..." rows={5} />
                            </Form.Item>



                            <TitleManager bottom={true} form={form} form_key={"titles"} titles={event.info.isbns.map(item => {
                                return {
                                    isbn: item.isbn,
                                    eisbn: item.eisbn,
                                    title: item.title
                                }
                            })} title={<span style={{ "fontSize": "14px", "fontWeight": "normal" }}>Titles</span>} />

                        </Col>

                        <Col span={5}>


                            <div className="ant-col ant-form-item-label">Ticket(s)</div>

                            {event.info.tickets.map((item, index) => {
                                return (<Alert afterClose={(e) => removeTicket(item)} key={item} closable className="ticket" message={<>{item}</>} />);
                            })}
                            {(event.info.tickets.length > 0 && <><div className="shim" /><div className="shim" /></>)}

                            <Input.Search onChange={(e) => setTicket(e.target.value)} value={ticket} onSearch={(e) => {
                                if(e){
                                    addTicket(e);
                                    setTicket("")
                                }
                              
                            }} size="small" onKeyDown={(e) => { if (e.key === "Enter") { e.preventDefault(); e.stopPropagation(); } }} enterButton={<small style={{ "fontSize": "9px" }}><PlusOutlined /></small>} placeholder="Add ISBN" />

                            <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />

                        </Col>
                        <Col span={5}>
                            <label>Event Poster/Author Image</label>

                            <ImageSelect path={event.info.img_path} onChange={updateImage} />
                        </Col>
                    </Row>
                    <Divider />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={() => close()}>Cancel</Button>
                            <Button htmlType="submit" type="primary">Save</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </div>
        )
    }



    return (
        <>
            <Spin spinning={loading}>
                <div style={{ "minHeight": "700px" }}>
                    {((!loading) && drawEdit())}
                </div>
            </Spin>
        </>
    )

}