import React, { useState } from "react";
import { Layout, Typography, Row, Col, Select, Form, Switch, Divider, Table, Popconfirm, Button, Popover, Tag, Pagination, Spin, message, Skeleton, Modal, Upload, Space, Alert } from "antd";
import { CommentOutlined, ImportOutlined, UploadOutlined } from '@ant-design/icons';
import { apiCall } from "../utils/Api";
import conditional from "../tools/conditional";
import CommentOptions from "./CommentOptions";
import { OmitProps } from "antd/lib/transfer/ListBody";
export default function ImportComments(props) {



    const {isbn = false, comment_id = false} = props; 

    const { callback = () => { } } = props;

    const [modalVisible, setModalVisible] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [isbnCol, setIsbnCol] = useState("");
    const [commentCol, setCommentCol] = useState("");
    const [data, setData] = useState({ headers: [], rows: [] })
    const [dataLoaded, setDataLoaded] = useState(false);
    const [editOptions, setEditOptions] = useState({})
    const [complete, setComplete] = useState(false);
    const [completeRows, setCompleteRows] = useState([])

    const close = () => {
        setData({ headers: [], rows: [] });
        setIsbnCol("");
        setCompleteRows([]);
        setComplete(false);
        setCommentCol("");
        setDataLoaded(false);
        setModalVisible(false);
    }


    // Possible to have empty 

    const uploadFile = (_file) => {
        setUploading(true);
        apiCall("comment/importFile", { file: _file.file }, (_status, _result) => {
            if (_status) {
                setData(_result);
                setUploading(false);
                setDataLoaded(true);
            }
        });
    }


    const importComments = (_f) => {

        let fields = {};
        fields.remove_previous = _f.remove_previous;

        // grab the isbn/comment from the selected fields
        fields.values = data.rows.map((item, index) => {
            return { isbn: item[isbnCol], comment: item[commentCol] }
        });
        // encode
        fields.values = JSON.stringify(fields.values);

        if (editOptions.hasOwnProperty("current_shared_with")) { fields.shared_with = editOptions.current_shared_with }
        if (editOptions.hasOwnProperty("visible_anywhere")) { fields.visible_anywhere = editOptions.visible_anywhere }
        if (editOptions.hasOwnProperty("expiry_type")) { fields.expiry_type = editOptions.expiry_type }
        if (editOptions.hasOwnProperty("expiry_date") && (editOptions.expiry_date)) { fields.expiry_days = editOptions.expiry_date }
        if (editOptions.hasOwnProperty("current_author")) { fields.as_author = editOptions.current_author }
        if (editOptions.hasOwnProperty("titlelist_id") && (editOptions.titlelist_id)) { fields.titlelist_id = editOptions.titlelist_id }


        apiCall("comment/importComments", fields, (_status, _results) => {
            if (_status) {
                callback();
                setComplete(true)
                setCompleteRows(_results);
            }
        })



    }


    const drawComplete = () => {

        let cols = [
            { title: <small>ISBN</small>, dataIndex: "isbn", key: "isbn" },
            { title: <small>Comment</small>, dataIndex: "comment", key: "comment" },
            { title: <small>Status</small>, dataIndex: "status", key: "status", render : (e) =>{
                return (e) ? <span style={{"color" : "#228b22"}}>Passed</span> : <span style={{"color" : "#ff4d4f"}}>Failed</span>
            } }
        ]

        let msg = completeRows.filter(item => item.status).length.toString() + " comment(s) in " + completeRows.length.toString() + " row(s) added."

        return (
            <>
                <div style={{"width" : "500px"}}>
                <Alert message={msg} type="success" />
                <br />
                <Table
                    scroll={{ y: 300 }} size="small" pagination={false}
                    columns={cols}
                    dataSource={completeRows}
                    className={"tableBorder"}
                />

                <Divider />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button type="primary" onClick={() => close(false)}>Close</Button>
                    </Space>
                </div>
                <br clear="all" />
                </div>

            </>
        )
    }



    const getHeaders = () => {

        let ret = [];

        if (isbnCol) {
            ret.push({ title: <small>ISBN <small style={{ "fontWeight": "normal" }}>({isbnCol})</small></small>, dataIndex: isbnCol, key: isbnCol })
        }

        if (commentCol) {
            ret.push({ title: <small>Comment <small style={{ "fontWeight": "normal" }}>({commentCol})</small></small>, dataIndex: commentCol, key: commentCol })
        }

        return ret;

    }

    const drawResults = () => {
        return (<>
            <div className="shim"></div><div className="shim"></div><div className="shim"></div>
            <span>Import preview <small>({data.rows.length} rows)</small></span>
            <div className="shim"></div><div className="shim"></div>
            <Table
                style={{"maxWidth" : "450px"}}
                scroll={{ y: 300 }} size="small" pagination={false}
                columns={getHeaders(data.headers)}
                dataSource={data.rows}
                className={"tableBorder"}
            />
        </>
        )
    }

    const drawSelection = () => {

        return (
            <>

                <Space size="large">
                    <Form.Item label="ISBN">
                        <Select placeholder="Select ISBN Column" value={(isbnCol) ? isbnCol : undefined} onChange={(e) => setIsbnCol(e)} style={{ "width": "200px" }}>
                            {data.headers.filter(item => item !== commentCol).map((item, index) => {
                                return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Comment">
                        <Select placeholder="Select Comment Column" value={(commentCol) ? commentCol : undefined} onChange={(e) => setCommentCol(e)} style={{ "width": "200px" }}>
                            {data.headers.filter(item => item !== isbnCol).map((item, index) => {
                                return (<Select.Option key={index} value={item}>{item}</Select.Option>)
                            })}
                        </Select>
                    </Form.Item>
                </Space>
                <br />
                <span>Remove previous comments (if any)</span>
                <br />
                <Form.Item initialValue={false} name="remove_previous" noStyle>
                    <Switch size="small" />
                </Form.Item>

            </>
        )
    }

    const drawUpload = () => {

        return (
            <Upload.Dragger accept=".txt, .xls, .xlsx, .tsv, .doc, .docx" showUploadList={false} customRequest={(e) => uploadFile(e)} style={{ "padding": "0px 20px" }}>
                <div >
                    {(uploading) ? <Spin style={{ height: "35px" }} /> : <UploadOutlined style={{ "fontSize": "30px", height: "35px" }} />}
                </div>
                <p className="ant-upload-text">Click or drag to upload</p>
                <p className="ant-upload-hint">
                    Upload an Excel file (.xls or .xlsx) or tab delimited file to add or update comments for multiple titles at once. You will then be prompted to choose which columns map to ISBN and Comments.
                </p>
            </Upload.Dragger>

        )
    }

    return (
        <>

            <Modal
                visible={modalVisible}
                footer={false}
                width={"auto"}
                style={{ "maxWidth": "750px" }}
                centered
                title="Import comments"
                onCancel={() => close()}
            >


                <conditional.true value={(complete)}>
                    {drawComplete()}
                </conditional.true>

                <conditional.true value={(!complete)}>


                    <Form onFinish={(f) => importComments(f)} layout="vertical" >

                        <conditional.true value={(!dataLoaded)}>
                            {drawUpload()}
                        </conditional.true>
                        <conditional.true value={(dataLoaded)}>
                            <Row gutter={25}>
                                <Col flex="400px" >
                                    {drawSelection()}
                                    <br />
                                    {drawResults()}
                                    <br />
                                </Col>
                                <Col flex="auto" >
                                    <div style={{ "width": "250px" }}>
                                        <CommentOptions isbn={isbn} comment_id={comment_id} editOptions={editOptions} setEditOptions={setEditOptions} />
                                    </div>
                                </Col>
                            </Row>
                        </conditional.true>
                        <Divider />
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => close(false)}>Cancel</Button>
                                <Button disabled={(!isbnCol || !commentCol)} htmlType="submit" type="primary">Import</Button>
                            </Space>
                        </div>
                        <br clear="all" />

                    </Form>
                </conditional.true>
            </Modal>


            <div onClick={() => setModalVisible(true)} type="primary">{props.children}</div>
        </>
    )
}