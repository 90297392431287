/*

Blank Layout - Wrap for printing

*/
import React from "react";
import { Layout } from 'antd';




export default function Blank(props) {

    const {bg_color = ""} = props; 
    return (
        <>  

            <Layout style={{"backgroundColor" : (bg_color) ? bg_color : ""}}>
                <Layout.Content className="bcg bc" style={{"min-height": "100vh", "overflow": "auto", "backgroundColor" : (bg_color) ? bg_color : "" }}>
                    <div style={{ "padding": "0px"}}>
                        {props.children}
                    </div>
                </Layout.Content>
            </Layout>
        </>
    );

}